import React, {ChangeEvent, FC, useEffect, useMemo, useState} from 'react';
import {notification, Tabs, Typography} from 'antd';
import {Badge, Col, ConfigProvider, Input, Row, Skeleton} from '@pankod/refine-antd';
import Button from '../../../shared/components/ui/button';
import {ButtonsTypes} from '../../../shared/components/ui/button/filledButton.p';
import {ArrowRepeat} from '../../../shared/components/icons';
import CustomSelect from '../../../shared/components/ui/select';
import {GetListResponse, HttpError, QueryObserverResult, useTranslate,} from '@pankod/refine-core';
import {IBankTemp, ICurrencyTemp, IFiatTemp, IGetListAdData, IPaymentsType, IRegionTemp,} from './interface';
import {IFilterAd} from './index';
import useDebounce from '../../../shared/hooks/use-debounce';
import CustomCascader from '../../../shared/components/ui/select/cascader';
import * as _ from 'lodash';
import {useTranslation} from 'react-i18next';
import {useGetPayments} from '../../../shared/hooks/use-get-payments';
import {PaymentTypeEnum} from '../../../features/p2p/createOrder/types';
import styled from 'styled-components';

export interface IWantData {
    data: QueryObserverResult<GetListResponse<IGetListAdData>, HttpError>;
    regions?: IRegionTemp[];
    filter: IFilterAd;
    initFilter: IFilterAd;

    setFilter(value: IFilterAd): void;

    setAds?(value: IGetListAdData[]): void;

    fiats?: IFiatTemp[];
    banks?: IBankTemp[];
    currency?: ICurrencyTemp[];
    isLoading: boolean;
    iconsData?: IPaymentsType[];
    refetchOffers: () => any;
}

const Want: FC<IWantData> = ({
                                 data,
                                 regions = [],
                                 filter,
                                 setFilter,
                                 setAds,
                                 initFilter,
                                 fiats = [],
                                 banks = [],
                                 currency = [],
                                 isLoading,
                                 iconsData = [],
                                 refetchOffers,
                             }) => {
    const [bank, setBank] = useState<string[] | undefined>(['all']);
    const [bankBuy, setBankBuy] = useState<string[] | undefined>(['all']);
    const [summ, setSumm] = useState('');
    const debouncedValue = useDebounce(summ, 500);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        if (summ === '') {
            setFilter(_.omit(filter, 'summ'));
        } else {
            setFilter({
                ...filter,
                summ: {
                    key: 'sum',
                    operator: 'and',
                    value: [
                        {
                            field: 'min_sum_limit',
                            operator: 'lte',
                            value: +debouncedValue,
                        },
                        {
                            field: 'max_sum_limit',
                            operator: 'gte',
                            value: +debouncedValue,
                        },
                    ],
                },
            });
        }
    }, [debouncedValue]);
    const translate = useTranslate();

    const {refetch} = useGetPayments(banks);

    useEffect(() => {
        refetch();
    }, [i18n.language]);

    const handleClearFilter = () => {
        setAds && setAds([]);
        setBank(['all']);
        setBankBuy(['all']);
        setSumm('');
        setFilter(initFilter);
    };

    const getCountFilter = useMemo(() => {

        const excludedKeys = ['type', 'ccy', 'status'];

        const filteredKeys = Object.keys(filter).filter(
            (key) => !excludedKeys.includes(key)
        );

        let count = filteredKeys.length;

        // Проверяем условие для payment_info и buy_payment_info
        if (!!filter.payment_info && !!filter.buy_payment_info) {
            count -= 1;
        }

        // Если count больше 0, вычитаем 1
        if (count > 0) {
            count -= 1;
        }

        return count;
    }, [filter]);

    const handleChangeSumm = (e: ChangeEvent<HTMLInputElement>) => {
        setSumm(
            e.target.value === '' ? '' : e.target.value.replace(/[^\d.-]/g, '')
        );
    };

    const handleChangeCrypto = (value: string) => {
        setFilter({
            ...filter,
            ccy: {
                field: 'ccy',
                operator: 'eq',
                value: value?.split('-')[0],
            },
        });
    };

    const handleUpdateData = async () => {
        notification.info({
            message: t('messages.info.dataUpdate'),
        });
        await refetchOffers()
            .then(() => {
                notification.success({
                    message: t('messages.success.dataUpdated'),
                });
            })
            .catch((e: any) => {
                notification.error({
                    message: t('messages.error.dataUpdateError'),
                    description: e?.message,
                });
            });
    };

    const handleChangeRegion = (value: number) => {
        if (value === 1) {
            setFilter(_.omit(filter, 'region_id'));
        } else {
            setFilter({
                ...filter,
                region_id: {
                    field: 'region_id',
                    operator: 'eq',
                    value,
                },
            });
        }
    };

    const handleChangeFiat = (value: string) => {
        setFilter({
            ...filter,
            fiat: {
                field: 'fiat',
                operator: 'eq',
                value,
            },
        });
    };
    const handleChangeMethod = (values: any) => {
        if (values) {
            const field =
                filter.type?.value === 'buy' ? 'buy_payment_info' : 'payment_info';
            if (values.length === 1) {
                filter.type?.value === 'buy'
                    ? setBankBuy([values[0]])
                    : setBank([values[0]]);
                if (values[0] === 'all') {
                    filter.type?.value === 'buy'
                        ? setBankBuy(undefined)
                        : setBank(undefined);
                    setFilter(_.omit(filter, field));
                } else {
                    setFilter({
                        ...filter,
                        [field]: {
                            field,
                            operator: 'contains',
                            value: `\\"payment_type\\": \\"${values[0]}\\"`,
                        },
                    });
                }
            } else if (values.length === 2) {
                const [type, name] = values;
                const label = (type === 'bankCardPayment' || type === 'bankTransferPayment') ? t(`banks.${banks.find(el => el.id === name)!.name}`) : t(`banks.${name}`);
                filter.type?.value === 'buy'
                    ? setBankBuy([label])
                    : setBank([label]);
                setFilter({
                    ...filter,
                    [field]: {
                        key: 'payment',
                        operator: 'and',
                        value: [
                            {
                                field,
                                operator: 'contains',
                                value: `\\"payment_type\\": \\"${type}\\"`,
                            },
                            {
                                field,
                                operator: 'contains',
                                value: `\\"bank_id\\": ${name},`,
                            },
                        ],
                    },
                });
            }
        } else {
            filter.type?.value === 'buy' ? setBankBuy(undefined) : setBank(undefined);
            const field =
                filter.type?.value === 'buy' ? 'buy_payment_info' : 'payment_info';
            setFilter(_.omit(filter, field));
        }
    };

    const Icon = ({
                      payment_type,
                      name,
                      icon
                  }: {
        payment_type: PaymentTypeEnum;
        name: string;
        icon?: string;
    }) => (
        <img
            alt={'img'}
            src={`data:image/svg+xml;base64,${
                iconsData
                    ?.find((type) => type.payment_type === payment_type)
                    ?.items?.find((icon) => icon.name === name)?.icon ?? icon
            }`}
            style={{
                height: '16px',
            }}
        />
    );

    return (
        <ConfigProvider
            theme={{
                components: {
                    Tabs: {
                        colorBorderSecondary: 'inherit',
                        colorPrimary:
                            'linear-gradient(25.41deg, #6E27F8 2.1%, #20D9C0 71.42%)',
                        paddingSM: 8,
                    },
                    Input: {
                        colorBorder: '#474755',
                        colorPrimaryHover: '#FBFBFF',
                        colorError: '#E94D4D',
                        colorErrorBorderHover: '#E94D4D',
                    },
                },
            }}
        >
            {isLoading ? (
                <Skeleton active={true}/>
            ) : (
                <div>
                    <div>
                        <div style={{position: 'relative'}}>
                            <Tabs
                                activeKey={
                                    !!filter.ccy?.value ? filter.ccy?.value : initFilter.ccy?.value
                                }
                                onChange={handleChangeCrypto}
                                items={currency.map((item) => ({
                                    key: item.currency,
                                    label: (
                                        <span className={'currencyCrypto'}>{item.currency}</span>
                                    ),
                                }))}
                            />
                            <ButtonWrapper>
                                <Button
                                    width={'180px'}
                                    height={'40px'}
                                    onClick={handleUpdateData}
                                    type={ButtonsTypes.outlined}
                                >
                                    <ButtonIcon>
                                        <ArrowRepeat/>
                                        <span style={{marginLeft: '8px'}}>
                    {translate('dashboard.filters.update')}
                  </span>
                                    </ButtonIcon>
                                </Button>
                            </ButtonWrapper>
                        </div>
                    </div>
                    <RowStyled
                        gutter={[8, 0]}
                        justify={'space-between'}
                        align={'stretch'}
                    >
                        <Col span={2}>
                            <CustomSelect
                                value={
                                    !!filter.fiat?.value ? filter.fiat?.value : fiats[0]?.currency
                                }
                                setValue={handleChangeFiat}
                                data={fiats.map((fiat) => ({
                                    label: fiat.currency,
                                    value: fiat.currency,
                                }))}
                                colorBgContainer={'#2B2B36'}
                                showSearch
                            />
                        </Col>
                        <Col span={6}>
                            <Input
                                suffix={
                                    <span style={{color: '#FBFBFF'}}>
                  {filter.fiat?.value ?? fiats[0]?.currency}
                </span>
                                }
                                placeholder={translate('dashboard.filters.inputSumm')}
                                style={{backgroundColor: '#2B2B36'}}
                                value={summ}
                                onChange={handleChangeSumm}
                            />
                        </Col>
                        <Col span={6}>
                            <CustomCascader
                                value={filter.type?.value === 'buy' ? bankBuy : bank}
                                onChange={handleChangeMethod}
                                showSearch
                                displayRender={(label: string[], selectedOptions) => {
                                    if (
                                        selectedOptions?.length === 1 &&
                                        selectedOptions[0]?.labelC
                                    ) {
                                        return selectedOptions[0].labelC;
                                    }
                                    if (
                                        selectedOptions?.length === 2 &&
                                        selectedOptions[1]?.labelC
                                    ) {
                                        return `${selectedOptions[0].label} / ${selectedOptions[1].labelC}`;
                                    }
                                    return label.join(' / ');
                                }}
                                options={[
                                    {
                                        label: translate('dashboard.filters.method.all'),
                                        value: 'all',
                                    },
                                    {
                                        value: 'bankCardPayment',
                                        label: translate('dashboard.filters.method.bankCard'),
                                        children: banks.map((bank) => ({
                                            label: (
                                                <div
                                                    className={
                                                        'flex align-items-center gap-8 height-100-percent'
                                                    }
                                                >
                                                    <Icon
                                                        icon={bank.icon}
                                                        name={t(`banks.${bank.name}`)}
                                                        payment_type={PaymentTypeEnum.bankCardPayment}
                                                    />
                                                    <Typography>{t(`banks.${bank.name}`)}</Typography>
                                                </div>
                                            ),
                                            value: bank.id,
                                            labelC: t(`banks.${bank.name}`),
                                        })),
                                    },
                                    {
                                        value: 'Electron',
                                        label: translate('dashboard.filters.method.electron'),
                                        children: [
                                            {
                                                label: (
                                                    <div
                                                        className={
                                                            'flex align-items-center gap-8 height-100-percent'
                                                        }
                                                    >
                                                        <Icon
                                                            name={'YooMoney'}
                                                            payment_type={PaymentTypeEnum.webServicePayment}
                                                        />
                                                        <Typography>YooMoney</Typography>
                                                    </div>
                                                ),
                                                value: 'YooMoney',
                                                labelC: 'YooMoney',
                                            },
                                            {
                                                label: (
                                                    <div
                                                        className={
                                                            'flex align-items-center gap-8 height-100-percent'
                                                        }
                                                    >
                                                        <Icon
                                                            name={'QIWI'}
                                                            payment_type={PaymentTypeEnum.webServicePayment}
                                                        />
                                                        <Typography>QIWI</Typography>
                                                    </div>
                                                ),
                                                value: 'QIWI',
                                                labelC: 'QIWI',
                                            },
                                            {
                                                label: (
                                                    <div
                                                        className={
                                                            'flex align-items-center gap-8 height-100-percent'
                                                        }
                                                    >
                                                        <Icon
                                                            name={'Payeer'}
                                                            payment_type={PaymentTypeEnum.webServicePayment}
                                                        />
                                                        <Typography>Payeer</Typography>
                                                    </div>
                                                ),
                                                value: 'Payeer',
                                                labelC: 'Payeer',
                                            },
                                            {
                                                label: (
                                                    <div
                                                        className={
                                                            'flex align-items-center gap-8 height-100-percent'
                                                        }
                                                    >
                                                        <Icon
                                                            name={'AdvCash'}
                                                            payment_type={PaymentTypeEnum.webServicePayment}
                                                        />
                                                        <Typography>AdvCash</Typography>
                                                    </div>
                                                ),
                                                value: 'AdvCash',
                                                labelC: 'AdvCash',
                                            },
                                        ],
                                    },
                                    {
                                        label: translate(
                                            'dashboard.filters.method.other.methods.mobile'
                                        ),
                                        value: 'mobileBalancePayment',
                                        labelC: translate(
                                            'dashboard.filters.method.other.methods.mobile'
                                        ),
                                    },
                                    {
                                        value: 'bankTransferPayment',
                                        label: translate(
                                            'dashboard.filters.method.other.methods.bank'
                                        ),
                                        children: banks.map((bank) => ({
                                            label: (
                                                <div
                                                    className={
                                                        'flex align-items-center gap-8 height-100-percent'
                                                    }
                                                >
                                                    <Icon
                                                        icon={bank.icon}
                                                        name={t(`banks.${bank.name}`)}
                                                        payment_type={PaymentTypeEnum.bankCardPayment}
                                                    />
                                                    <Typography>{t(`banks.${bank.name}`)}</Typography>
                                                </div>
                                            ),
                                            value: bank.id,
                                            labelC: translate(
                                                'dashboard.filters.method.other.methods.bank'
                                            ),
                                        })),
                                    },
                                    {
                                        label: translate(
                                            'dashboard.filters.method.other.methods.fps'
                                        ),
                                        value: 'fastPaymentSystemPayment',
                                        labelC: translate(
                                            'dashboard.filters.method.other.methods.fps'
                                        ),
                                    },
                                ]}
                                placeholder={t('dashboard.want.placeholder')}
                            />
                        </Col>
                        <Col span={6}>
                            <CustomSelect
                                value={filter.region_id?.value ?? 1}
                                setValue={handleChangeRegion}
                                data={regions.map((region) => ({
                                    label: t(`p2p.regions.${region.name.trim()}`),
                                    value: region.id,
                                }))}
                                colorBgContainer={'#2B2B36'}
                                showSearch
                            />
                        </Col>
                        <ColStyled span={4}>
                            <Typography.Link
                                onClick={handleClearFilter}
                                style={{marginRight: '8px'}}
                            >
                                {translate('dashboard.filters.clear')}
                            </Typography.Link>
                            <Badge
                                count={getCountFilter}
                                showZero
                                color="linear-gradient(75.86deg, #088473 6.84%, #0EC6AD 107.11%)"
                            />
                        </ColStyled>
                    </RowStyled>
                </div>
            )}
        </ConfigProvider>
    );
};

interface IBankItem {
    bank: IBankTemp;
    iconsData?: IPaymentsType[];
}

const BankItem: FC<IBankItem> = ({bank, iconsData}) => {
    const {t, i18n} = useTranslation();

    const Icon = ({payment_type, name}: { payment_type: PaymentTypeEnum, name: string }) => (
        <img
            src={`data:image/svg+xml;base64,${
                iconsData
                    ?.find(type => type.payment_type === payment_type)
                    ?.items
                    ?.find(icon => icon.name === name)
                    ?.icon
            }`}
            style={{height: '16px'}}
        />
    )

    return (
        <div className={'flex align-items-center gap-8 height-100-percent'}>
            <Icon name={t(`banks.${bank.name}`)} payment_type={PaymentTypeEnum.bankCardPayment}/>
            <Typography>{t(`banks.${bank.name}`)}</Typography>
        </div>
    )
}

export default Want;

const RowStyled = styled(Row)`
    margin-bottom: 16px;
    margin-top: 35px;
`;

const ButtonWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

const ButtonIcon = styled.div`
    display: flex;
    align-items: center;
`;

const ColStyled = styled(Col)`
    text-align: end;
    display: flex;
    justify-content: end;
    align-items: center;
`;
