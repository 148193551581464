import React, {useContext} from 'react';
import {Typography} from 'antd';
import Icon from '@ant-design/icons';
import {ArrowThin} from '../../../shared/components/icons';
import {useTranslation} from 'react-i18next';
import {SpotMarketContext} from '../../../pages/spot/spotDashboard/spotDashboard';
import {SpotHeadingTwoLineItem} from '../heading/twoLineItem';

const SpotOrderListMarketListData: React.FC = () => {
    const {t} = useTranslation();
    const {
        tradeInfo: {
            mainCurrencyData,
            currencyInFiatData,
            marketVolumeInLast,
            marketVolumeInMain,
        },
    } = useContext(SpotMarketContext);

    return (
        <>
            <div className={'flex-row'} style={{gap: 38}}>
                <SpotHeadingTwoLineItem
                    firstLine={
                        <>
                            <div className={'flex-row gap-4 align-items-center'}>
                                <Typography.Text
                                    style={{
                                        color: '#58BF92',
                                        fontSize: 18,
                                        fontWeight: 500,
                                        lineHeight: '22px',
                                    }}
                                >
                                    {mainCurrencyData || 0}
                                </Typography.Text>
                                <Icon component={() => ArrowThin({})}/>
                            </div>
                        </>
                    }
                    // ts-ignore
                    secondLine={`≈ ${(+currencyInFiatData)?.toFixed(2) || 0} $`}
                    stylesSecondLine={{
                        fontSize: 12,
                        lineHeight: '15px',
                    }}
                    gap={4}
                    stylesFirstLine={{
                        lineHeight: '22px',
                    }}
                />
                <SpotHeadingTwoLineItem
                    firstLine={
                        <>
                            <div className={'flex-row gap-8 align-items-center'}>
                                <Typography.Text
                                    className={'light-bold-12px-text'}
                                    style={{
                                        color: '#767687',
                                    }}
                                >
                                    {t('spot.markerListData.volume')}
                                </Typography.Text>
                                <Typography.Text className={'light-bold-12px-text'}>
                                    {/*ts-ignore*/}
                                    {+(!!marketVolumeInMain ? +marketVolumeInMain : 0)?.toFixed(
                                        8
                                    ) || 0}
                                </Typography.Text>
                            </div>
                        </>
                    }
                    secondLine={
                        <>
                            <div
                                className={'flex-row gap-8 align-items-center'}
                                style={{
                                    borderTop: '1px solid rgba(255, 255, 255, 0.05)',
                                    paddingTop: 5,
                                }}
                            >
                                <Typography.Text
                                    className={'light-bold-12px-text'}
                                    style={{
                                        color: '#767687',
                                    }}
                                >
                                    {t('spot.volume')}
                                </Typography.Text>
                                <Typography.Text className={'light-bold-12px-text'}>
                                    {+(marketVolumeInLast ? +marketVolumeInLast : 0)?.toFixed(
                                        8
                                    ) || 0}
                                </Typography.Text>
                            </div>
                        </>
                    }
                    gap={4}
                />
            </div>
        </>
    );
};

export {SpotOrderListMarketListData};
