import { Button, Typography } from '@pankod/refine-antd';
import { BasicModal, BasicModalProps } from './basicModal';
import { ReactComponent as FileIcon } from '../assets/svg/fileIcon.svg';
import { FC } from 'react';
import './index.css';
import { useTranslate } from '@pankod/refine-core';

interface ICancelOrderModal
  extends Omit<BasicModalProps, 'footer' | 'title' | 'children'> {
  orderId?: string | number;
  commissionAmount: string;
}

export const CancelOrder: FC<ICancelOrderModal> = ({
  commissionAmount,
  orderId,
  onOk,
  onCancel,
  okText,
  cancelText,
  ...modalProps
}) => {
  const translate = useTranslate();
  return (
    <BasicModal
      footer={
        <div className="custom-modal-footer">
          <Button type="primary" onClick={onOk}>
            {okText}
          </Button>
          <Button type="dashed" onClick={onCancel}>
            {cancelText}
          </Button>
        </div>
      }
      {...modalProps}
      onCancel={onCancel}
      title={translate('cryptoExchange.modal.cancelOrder.title')}
    >
      <div className="flex-column gap-16">
        <div className="custom-modal-content">
          <FileIcon />
          <Typography.Text className="bold-16px-text">
            {`${translate(
              'cryptoExchange.modal.cancelOrder.orderNumber'
            )} №${orderId}`}
          </Typography.Text>
        </div>
        <div className="flex-column">
          <Typography.Text className="text-16px">
            {translate('cryptoExchange.modal.cancelOrder.notification')}
          </Typography.Text>
          <Typography.Text className="text-16px">
            {translate('cryptoExchange.modal.cancelOrder.bottomText')}
          </Typography.Text>
          <span style={{ marginTop: '32px' }}>
            <Typography.Text className="bold-16px-text">
              {translate('wallet.coin.deposit.commissionNetwork')}{' '}
            </Typography.Text>
            <Typography.Text className="text-16px">
              {commissionAmount}
            </Typography.Text>
          </span>
        </div>
      </div>
    </BasicModal>
  );
};
