import React, {useContext, useEffect, useState} from 'react';
import {Icons} from '@pankod/refine-antd';
import {useTranslation} from 'react-i18next';
import {styled} from 'styled-components';
import {useLocation} from '@pankod/refine-react-router-v6';
import {SpotMarketContext} from '../../pages/spot/spotDashboard/spotDashboard';
import {SpotHeadingCryptoPair} from "../../features/spot/heading/cryptoPair";
import {SpotHeadingTwoLineItem} from "../../features/spot/heading/twoLineItem";

const {ArrowUpOutlined} = Icons;

const SpotPairInfo: React.FC = () => {
    const {
        currPair: {
            currentPair,
            mainPriceLast,
            equivalentInFiat,
            changeValuePerDay,
            minValuePerDay,
            marketVolumePerDay,
            maxValuePerDay,
        },
    } = useContext(SpotMarketContext);
    const {t} = useTranslation();
    const location = useLocation();
    const [activePair, setActivePair] = useState(
        () => localStorage['activePair']
    );

    useEffect(() => {
        setActivePair(() => localStorage['activePair']);
    }, []);

    useEffect(() => {
        setActivePair(() => localStorage['activePair']);
    }, [location?.pathname]);

    return (
        <>
            <RowContainer className={'flex-row gap-32'}>
                <SpotHeadingCryptoPair currentPair={currentPair}/>
                <SpotHeadingTwoLineItem
                    className={'mob'}
                    firstLine={+(+mainPriceLast)?.toFixed(4) || 0}
                    secondLine={`≈ ${(+equivalentInFiat)?.toFixed(2) || 0} USD`}
                    stylesFirstLine={{
                        fontWeight: 700,
                        color: '#58BF92',
                        height: 17,
                    }}
                    stylesSecondLine={{
                        fontWeight: 500,
                        fontSize: 12,
                        height: 15,
                    }}
                />
                <SpotHeadingTwoLineItem
                    className={'mob-display-none'}
                    firstLine={
                        <div className={'flex-row gap-4'}>
                            {+(+changeValuePerDay)?.toFixed(4) || 0}
                            <ArrowUpOutlined style={{fontSize: 10}}/>
                        </div>
                    }
                    secondLine={t('pairInfo.changeIn24Hours')}
                    stylesFirstLine={{
                        fontWeight: 700,
                        color: changeValuePerDay > 0 ? '#58BF92' : '#F16063',
                        height: 17,
                    }}
                    stylesSecondLine={{
                        color: '#767687',
                        fontWeight: 500,
                        height: 15,
                    }}
                />
                <SpotHeadingTwoLineItem
                    className={'mob-display-none'}
                    gap={8}
                    firstLine={+(+maxValuePerDay)?.toFixed(4) || 0}
                    secondLine={t('pairInfo.maxIn24Hours')}
                    stylesFirstLine={{
                        fontWeight: 600,
                        fontSize: 12,
                        height: 15,
                    }}
                    stylesSecondLine={{
                        color: '#767687',
                        fontSize: 12,
                        height: 15,
                    }}
                />
                <SpotHeadingTwoLineItem
                    className={'mob-display-none'}
                    gap={8}
                    firstLine={+(+minValuePerDay)?.toFixed(4) || 0}
                    secondLine={t('pairInfo.minIn24Hours')}
                    stylesFirstLine={{
                        fontWeight: 600,
                        fontSize: 12,
                        height: 15,
                    }}
                    stylesSecondLine={{
                        color: '#767687',
                        fontSize: 12,
                        height: 15,
                    }}
                />
                <SpotHeadingTwoLineItem
                    className={'mob-display-none'}
                    gap={8}
                    firstLine={`${+(+marketVolumePerDay)?.toFixed(4) || 0} ${
                        !!activePair ? activePair?.split('-')[0] : ''
                    }`}
                    secondLine={t('pairInfo.volumeIn24Hours')}
                    stylesFirstLine={{
                        fontWeight: 600,
                        fontSize: 12,
                        height: 15,
                    }}
                    stylesSecondLine={{
                        color: '#767687',
                        fontSize: 12,
                        height: 15,
                    }}
                />
            </RowContainer>
        </>
    );
};

const RowContainer = styled.div`
  @media (max-width: 767px) {
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;
    & .mob {
      justify-content: center;
      align-items: flex-end;
      font-size: 12px;
      font-weight: 500;
    }

    & .mob-display-none {
      display: none;
    }
  }
`;

export {SpotPairInfo};
