import styled from "styled-components";

const InfoItems = ({items}: { items: { title: string, subtitle: string }[] }) => {
  return (
    <>
      {
        items.map((item, i) => (
          <ItemContainer key={i}>
            <Item>
              <TitleItem>{item.title}</TitleItem>
              <SubTitleItem>
                {item.subtitle}
              </SubTitleItem>
            </Item>
          </ItemContainer>
        ))
      }
    </>
  )
}

export default InfoItems;

const ItemContainer = styled.div`
  width: 25%;
  height: 44px;
  display: flex;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TitleItem = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: rgba(118, 118, 135, 1);
`;

const SubTitleItem = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  color: rgba(250, 250, 252, 1);
`;
