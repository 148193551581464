import React, { CSSProperties } from 'react';
import Check from './image/check.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  nowStep: number;
}

const Stepper = ({ nowStep }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <div style={ContainerStepStyle}>
        <div
          style={{
            background:
              nowStep === 1
                ? 'linear-gradient(80.91deg, #088473 0%, #12BFA8 100%)'
                : 0,
            border: '1px solid #088473',
            ...stepStyle,
          }}
        >
          {nowStep === 1 ? (
            <span
              style={{
                ...stepTextStyle,
                color: '#FBFBFF',
              }}
            >
              1
            </span>
          ) : (
            <img
              alt={'success'}
              src={Check}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
          )}
        </div>

        <div
          style={{
            ...dividerStyle,
            background:
              nowStep === 2
                ? 'linear-gradient(80.91deg, #088473 0%, #12BFA8 100%)'
                : '#767687',
          }}
        />
        <div
          style={{
            ...stepStyle,
            background:
              nowStep === 2
                ? 'linear-gradient(80.91deg, #088473 0%, #12BFA8 100%)'
                : 'none',
            border: nowStep === 1 ? '1px solid #767687' : 'none',
          }}
        >
          <span
            style={{
              ...stepTextStyle,
              color: nowStep === 1 ? '#767687' : '#FBFBFF',
            }}
          >
            2
          </span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 40,
          marginTop: 10,
        }}
      >
        <p
          style={{
            ...stepTextSecondStyle,
            color: '#FBFBFF',
          }}
        >
          {t('payments.choosePaymentMethod')}
        </p>
        <p
          style={{
            ...stepTextSecondStyle,
            textAlign: 'right',
            color: nowStep === 1 ? '#767687' : '#FBFBFF',
          }}
        >
          {t('payments.enterPaymentData')}
        </p>
      </div>
    </>
  );
};

const stepTextSecondStyle: CSSProperties = {
  whiteSpace: 'pre-wrap',
  fontFamily: 'Inter,sans-serif',
  fontStyle: 'normal',
  fontSize: 12,
  fontWeight: 500,
  transition: '0.2s',
};

const ContainerStepStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '5%',
};

const dividerStyle: CSSProperties = {
  width: '77%',
  height: 1,
  background: '#767687',
  transition: '0.2s',
};
const stepTextStyle: CSSProperties = {
  fontFamily: 'Inter,sans-serif',
  fontStyle: 'normal',
  fontSize: 16,
  fontWeight: 400,
  lineHeight: 19,
  transition: '0.2s',
};

const stepStyle: CSSProperties = {
  borderRadius: '50%',
  width: 32,
  height: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export default Stepper;
