import React from 'react'
import OrderDeals from './orderDeals/index';
import {IGetListData} from '../../../pages/p2p/myDeals/cards/myTrades.p'
import {IPaymentInfo} from '../../../pages/p2p/dashboard/interface'

interface ICurrentP2POrder {
  activeTab: number;
  isSeller: boolean;
  currentOrder: IGetListData;
  currTimerValue: number;
  isTimerActive: boolean;
  currCommission: number;
  isMyAppeal: boolean;
  updateP2POrderStatus: (status: string, useNotification?: boolean) => void;
  handleOnOpenAppealModal: () => void;
  handleOnOpenAppealCloseModal: () => void;
  openConfirmModalBuyer: () => void;
  openConfirmModalSellerSeller: () => void;
  paymentInfo: IPaymentInfo | undefined;
  updateP2PAppealStatus: (status: string) => void;
  adId?: number;
}

const CurrentP2POrder: React.FC<ICurrentP2POrder> = ({...rest}) => <OrderDeals {...rest}
/>;

export default CurrentP2POrder;
