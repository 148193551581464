export const getFullNameCoin = (name: string) => {
  switch (name.toLowerCase()) {
    case 'btc':
      return 'Bitcoin';
    case 'usdt':
      return 'Tether';
    case 'matic':
      return 'Polygon';
    case 'eth':
      return 'Ethereum';
    case 'usdc':
      return 'USD Coin';
    case 'bnb':
      return 'Binance Coin';
    case 'ltc':
      return 'Litecoin';
    default:
      return 'coin';
  }
};
