import { Button, Form, FormInstance, Input, Typography } from "@pankod/refine-antd"
import { BasicModal, BasicModalProps } from "./basicModal"
import { ReactComponent as FileIcon } from '../assets/svg/fileIcon.svg'
import { FC, useEffect, useState } from "react"
import './index.css'
import { useTranslate } from "@pankod/refine-core"

interface ICancelReplenishedOrderModal extends Omit<BasicModalProps, 'footer' | 'children' | 'title'> {
    orderId?: string | number,
    commissionAmount: string,
    form: FormInstance,
}

export const CancelReplenishedOrder: FC<ICancelReplenishedOrderModal> = ({ orderId, onOk, onCancel, form, commissionAmount, okText, cancelText, ...modalProps }) => {

    const translate = useTranslate()
    const regex = /^[a-zA-Z0-9]{1,40}$/
    const cancelWalletAddress = Form.useWatch<string>('cancelWallet', form)
    const validationError = cancelWalletAddress ? cancelWalletAddress.match(regex) : undefined

    return <BasicModal
        {...modalProps}
        title={translate("cryptoExchange.modal.cancelReplenishedOrder.title")}
        onCancel={onCancel}
        footer={
            <div className="custom-modal-footer">
                <Button
                    type="primary"
                    onClick={onOk}
                    disabled={!Boolean(validationError)}
                >{okText}</Button>
                <Button type="dashed" onClick={onCancel}>{cancelText}</Button>
            </div>
        }
    >
        <div className="flex-column gap-16">
            <div className="custom-modal-content">
                <FileIcon />
                <Typography.Text className="bold-16px-text">
                    {`${translate("cryptoExchange.modal.cancelReplenishedOrder.orderNumber")} №${orderId}`}
                </Typography.Text>
            </div>
            <Typography.Text className="text-16px">
                {translate("cryptoExchange.modal.cancelReplenishedOrder.notification")}
            </Typography.Text>
            <Form layout="vertical" form={form}>
                <Form.Item
                    shouldUpdate
                    name="cancelWallet"
                    label={translate("cryptoExchange.modal.cancelReplenishedOrder.inputLabel")}
                    rules={[
                        { required: true, message: translate("cryptoExchange.modal.cancelReplenishedOrder.errors.empty") },
                        { pattern: regex, message: translate("cryptoExchange.modal.cancelReplenishedOrder.errors.incorrect") }
                    ]}
                >
                    <Input type='text' placeholder={translate("cryptoExchange.modal.cancelReplenishedOrder.inputPlaceholder")} />
                </Form.Item>
            </Form>
            <Typography.Text className="text-16px cancel-order-modal-network-commission">
                {translate("cryptoExchange.modal.cancelReplenishedOrder.networkFee")}:
                <Typography.Text className="bold-16px-text">
                    {commissionAmount}
                </Typography.Text>
            </Typography.Text>
        </div >
    </BasicModal>
}