import React, { CSSProperties, Dispatch, ReactNode } from "react";
import Card from "./image/credit_card.svg";
import Service from "./image/service.svg";
import Phone from "./image/phone.svg";
import Transfer from "./image/arrow-down-up.svg";
import Cash from "./image/monetization_on.svg";
import SBP from "./image/SBP.svg";
import { TypePaymentVariant } from "../modalContent";
import {useTranslate} from "@pankod/refine-core";
import {Col, Row} from "@pankod/refine-antd";

interface Props {
  controlsVariant: [TypePaymentVariant | null, Dispatch<TypePaymentVariant | null>];
}

const FirstStep = ({controlsVariant:[variantPay, setVariantPay]}:Props) => {
  const translate = useTranslate()
  return (
    <div style={{ marginBottom: '239px',}}>
      <Row gutter={[8, 8]}>
        {paymentVariant.map((item, index) => (
          <Col span={12} key={'col' + index}>
            <button
              onClick={() => setVariantPay(item.type)}
              className={`button-card ${variantPay === item.type && "button-card-selected"}`}
              key={'btn' + index}
            >
              {item.icon}
              <p style={cardTextStyle}>
                {translate(item.name)}
              </p>
            </button>
          </Col>
        ))}
      </Row>
    </div>
  );
};

const cardTextStyle: CSSProperties = {
  fontFamily: "Inter, sans-serif",
  fontStyle: "normal",
  fontWeight: 400,
  height: 40,
  textAlign: "left",
  display: "flex",
  alignItems: "center",
  margin: 0,
  fontSize: 14,
  color: "#FBFBFF"
};

const paymentVariant: { type: TypePaymentVariant, name: string, icon: ReactNode }[] = [
  {
    type: "cards",
    name: "payments.bankCard",
    icon: <img alt={"card"} src={Card} />
  },
  {
    type: "services",
    name: "payments.electronServices",
    icon: <img alt={"service"} src={Service} />
  },
  {
    type: "mobiles",
    name: "payments.mobileBalanceReplenishment",
    icon: <img alt={"mobile"} src={Phone} />
  },
  {
    type: "banks",
    name: "payments.bankTransfer",
    icon: <img alt={"transfer"} src={Transfer} />
  },
  // {
  //   type: "cashes",
  //   name: "payments.cashes",
  //   icon: <img alt={"cash"} src={Cash} />
  // },
  {
    type: "sbp",
    name: "payments.sbp",
    icon: <img alt={"sbp"} src={SBP} />
  }
];

export default FirstStep;
