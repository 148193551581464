import {IP2PCollapseInputData, IP2PCollapseOpen} from "./interface"
import {CollapseOpenLeftContent} from "./collapseOpenLeftContents"
import styled from "styled-components"
import {CollapseOpenInputData} from "./collapseOpenInputData"
import {FC} from "react";

export const CollapseOpen: FC<IP2PCollapseOpen & IP2PCollapseInputData> = ({
                                                                                   dataAd,
                                                                                   countDeals,
                                                                                   methodArray,
                                                                                   regions,
                                                                                   typeText,
                                                                                   setIsOpen,
                                                                                   setFromCard,
                                                                                   setOpenModal,
                                                                                   item,
                                                                                   user
                                                                                 }) => {

  return (
    <CollapseOpenContainer>
      <CollapseOpenLeftContent
        dataAd={dataAd}
        countDeals={countDeals}
        methodArray={methodArray}
        regions={regions}
      />
      <CollapseOpenInputData
        dataAd={dataAd}
        typeText={typeText}
        setIsOpen={setIsOpen}
        methodArray={methodArray}
        item={item}
        setFromCard={setFromCard}
        setOpenModal={setOpenModal}
        user={user}
      />
    </CollapseOpenContainer>
  )
}

const CollapseOpenContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
