import {Typography} from '@pankod/refine-antd';
import Tabs from '../../../shared/components/ui/Tabs';
import {LogicalFilter, useNavigation, useTranslate,} from '@pankod/refine-core';
import {useGetAnnouncementsTranslatedItems} from 'shared/hooks/use-get-myAnnouncements-translated-items';
import React, {useEffect, useState} from 'react';
import {RangeValue} from 'pages/coin-account/show';
import {useGetAnnTables} from 'shared/hooks/use-get-myAnnouncements-table';
import styled from 'styled-components';
import EmptyComponent from '../../../shared/components/ui/emptyComponent/EmptyComponent';
import {AnnFilters, Announcements,} from '../../../features/announcements';
import {ErrorBoundary} from '../../../app/ErrorBoundary';
import Modal from '../../../shared/components/ui/modal';
import {useUserNickname} from '../../../shared/hooks/use-user-nickname';
import {useNavigate} from '@pankod/refine-react-router-v6';
import {notification} from 'antd';
import {CreateAdButton} from '../../../shared/components/ui/button/createAdButton';
import {useAuthState} from '../../../useAuthState';
import {useCookiesCustom} from '../../../shared/hooks/useCookieCustom';

export interface ICoinForRender {
    label: string;
    value: string;
    icon: JSX.Element;
}

const {Title} = Typography;
const currentDate = new Date();
const startMonthDate = new Date(
    new Date().setMonth(currentDate.getMonth() - 1)
);
const Index = () => {
    const {allTable} = useGetAnnTables();
    const userAPI = useAuthState((state) => state.userAPI);
    const t = useTranslate();
    const [dates, setDates] = useState<RangeValue>(null);
    const [value, setValue] = useState<RangeValue>(null);
    const [coinKey, setCoinKey] = useState('all');
    const [currentTab, setCurrentTab] = useState('all');
    const [statusKey, setStatusKey] = useState('all');
    const [isOpenNicknameModal, setIsOpenNicknameModal] = useState(false);
    const [validationError, setValidationError] = useState<string>();
    const {push: pushRoute} = useNavigation();
    const navigate = useNavigate();
    const {cookie, removeCookie} = useCookiesCustom();
    const uid = useAuthState((state) => state?.userAPI?.uid) || cookie['uid'];

    const onSuccessUpdateCallback = () => {
        pushRoute('/p2p/create-offer');
        setIsOpenNicknameModal(false);
    };

    const onServerValidationError = (errorMessage: string) => {
        setValidationError(errorMessage);
    };

    const {
        isLoading: isLoadingAccount,
        userData,
        submitUserNickname,
    } = useUserNickname({
        onSuccessUpdateCallback,
        setValidationError: onServerValidationError,
    });

    const setNewTab = (e: string) => {
        let newFilterValue = e;
        if (e === 'all') newFilterValue = '';
        setFilter({
            field: 'type',
            operator: 'eq',
            value: newFilterValue,
        });
        setCurrentTab(e);
    };

    const setFilter = (filter: LogicalFilter) => {
        if (allTable.filters) {
            let updateFilters = allTable.filters.filter(
                (item) => (item as LogicalFilter).field !== filter.field
            );
            if (filter.value === '') {
                allTable.setFilters(
                    [
                        ...updateFilters,
                        {field: 'status', operator: 'ne', value: `deleted`},
                    ],
                    'replace'
                );
            } else {
                allTable.setFilters([
                    ...updateFilters,
                    {field: 'status', operator: 'ne', value: `deleted`},
                    filter,
                ]);
            }
        } else {
            allTable.setFilters([filter]);
        }
    };

    useEffect(() => {
        if (statusKey !== 'all') {
            setFilter({
                field: 'status',
                operator: 'eq',
                value: statusKey,
            });
        } else {
            setClearFilters()
        }
    }, [statusKey]);

    useEffect(() => {
        if (coinKey === 'all') {
            setFilter({
                field: 'ccy',
                operator: 'contains',
                value: '',
            });
        } else {
            setFilter({
                field: 'ccy',
                operator: 'eq',
                value: coinKey,
            });
        }
    }, [coinKey]);

    useEffect(() => {
        if (uid) {
            setFilter({
                field: 'owner_id',
                operator: 'eq',
                value: `${uid}`,
            });
        }
    }, [uid]);

    const {coinsForRender, statusItems} = useGetAnnouncementsTranslatedItems();

    const setClearFilters = () => {
        allTable.setFilters(
            [
                {
                    field: 'status',
                    operator: 'ne',
                    value: 'deleted',
                },
                ...(allTable.filters?.filter(
                    (item) => (item as LogicalFilter).field === 'owner_id'
                ) ?? []),
            ],
            'replace'
        );
    };

    return (
        <ErrorBoundary
            logout={() => {
            }}
            navigate={navigate}
            removeCookie={removeCookie}
        >
            <MyAnnouncementsStyled className="ad-container">
                <div className="flex-column">
                    <Title>{t('announcements.title')}</Title>
                </div>
                <div className="flex-row">
                    <div
                        style={{
                            position: 'relative',
                            marginTop: 20,
                            width: '100%',
                        }}
                    >
                        <Tabs
                            activeKey={statusKey}
                            defaultActiveKey={statusKey}
                            onChange={(e) => setStatusKey(e)}
                            items={statusItems.map((item) => ({
                                key: item?.key,
                                label: item.label,
                            }))}
                        />
                        <CreateAdButton
                            onClick={() => {
                                if (!!userData) {
                                    if (!userData.nickname?.length) {
                                        setIsOpenNicknameModal(true);
                                    } else if (!!userData.nickname?.length)
                                        navigate('/p2p/create-offer');
                                } else {
                                    notification.info({
                                        message: t('messages.info.pleaseLogin'),
                                    });
                                }
                            }}
                        >
                            {t('announcements.create')}
                        </CreateAdButton>
                    </div>
                </div>
                <FiltersWrappers>
                    <AnnFilters
                        setCurrentCoin={setCoinKey}
                        setCurrentStatus={setNewTab}
                        currentCoin={coinKey}
                        statusItemsMenu={{items: statusItems}}
                        coinsItemsMenu={coinsForRender}
                        currentStatus={currentTab}
                        startDate={startMonthDate}
                        endDate={currentDate}
                        dates={dates}
                        setDates={setDates}
                        isLoading={!!allTable.tableRes.loading}
                        value={value}
                        setValue={setValue}
                        setFilter={setFilter}
                        tab={statusKey}
                        setTab={setStatusKey}
                        setClearFilters={setClearFilters}
                    />
                </FiltersWrappers>
            </MyAnnouncementsStyled>
            {!allTable.tableRes?.dataSource?.length && (
                <EmptyComponent text={t('emptyComponent.noAdFound')}/>
            )}
            <Announcements tableProps={allTable.tableRes}/>
            {/*<AnnSkeleton loading={!!allTable.tableRes.loading}/>*/}
            {userData ? (
                <Modal.NicknameModal
                    validationError={validationError}
                    isLoading={isLoadingAccount}
                    userData={userData}
                    onSubmit={submitUserNickname}
                    onCancel={() => setIsOpenNicknameModal(false)}
                    open={isOpenNicknameModal}
                    onNicknameChange={() => setValidationError(undefined)}
                />
            ) : null}
        </ErrorBoundary>
    );
};
export default Index;

const FiltersWrappers = styled.div`
    margin-top: 8px;
`;

const MyAnnouncementsStyled = styled.div`
    background: rgb(20, 20, 31);
`;
