import React, {useEffect, useState} from 'react';
import './styles.css';
import {coins} from './header/data';
import {StatSpot} from './types';
import {API_URL} from '../../../packages/keycloak-client/constants';
import {CentrifugeClient} from '../../../packages/centrifuge-client/centrifuge-client';
import {axios} from '../../../shared/exios';
import {useLocation} from '@pankod/refine-react-router-v6';
import Header from './header/Header';
import Content from './content/Content';

const Pair = () => {
    const location = useLocation();
    const [activeSortPair, setActiveSortPair] = useState(coins[0]);
    const [filterStar, setFilterStar] = useState(false);
    const [stars, setStars] = useState<string[]>([]);
    const [dataSocket, setDataSocket] = useState<StatSpot[]>([]);
    const [search, setSearch] = useState('');

    async function getDataFromSpotStat() {
        try {
            const res = await axios.get(
                `${API_URL}/${localStorage['activePair']
                    .replace('-', '')
                    .toLowerCase()}-spot/api/custom-api/spot-stat`,
                {}
            );
            const pairsObj = await res.data;
            const pairArr: any = [];
            for (let [key, value] of Object.entries(pairsObj)) {
                pairArr.push({content: value, name: key});
            }
            // Список пар с API
            setDataSocket((prevState) => {
                const commonArr = [...prevState];
                for (const pairData of pairArr) {
                    commonArr.push(pairData);
                }
                return [
                    // @ts-ignore
                    ...new Map(commonArr.map((item) => [item['name'], item])).values(),
                ];
            });
        } catch (e) {
            console.error(e);
        }
    }

    async function getPairs() {
        try {
            const res = await axios.get(`${API_URL}/config/api/pair`);
            const pairsArr = await res.data?.data;
            if (!dataSocket?.length) {
                // Список пар с API
                setDataSocket((prevState) => {
                    const arr = [...prevState];
                    if (!!pairsArr?.length) {
                        for (const pair of pairsArr) {
                            if (pair?.is_available_spot) {
                                arr.push({
                                    name: pair?.pair,
                                    content: {
                                        last_price: 0,
                                        last_price_usd: 0,
                                        change_price_24: 0,
                                        volume_24: 0,
                                        main: pair?.ccy_main_name,
                                        last: pair?.ccy_last_name,
                                    },
                                });
                            }
                        }
                    }

                    return arr;
                });
            }
        } catch (e) {
            console.error('getPairs error');
        }
    }

    function subscribeToSpotStat() {
        // Список пар с сокетов
        const subs = CentrifugeClient.subscriptions();
        if (!subs['spotStat']) CentrifugeClient.subscribeToChannel('spotStat');
        if (!!subs['spotStat']) {
            const spotStatSubscription = CentrifugeClient.getSubscription('spotStat');
            spotStatSubscription
                ?.on('publication', (ctx) => {
                    const values = Object.values(ctx.data);
                    const result: any = Object.keys(ctx.data).map((item, index) => {
                        return {
                            name: item,
                            content: values[index],
                        };
                    });
                    if (!!result) {
                        setDataSocket((prevState) => {
                            const commonArr = [...prevState];
                            for (const pairData of result) {
                                commonArr.push(pairData);
                            }
                            return [
                                // @ts-ignore
                                ...new Map(
                                    commonArr.map((item) => [item['name'], item])
                                ).values(),
                            ];
                        });
                    }
                })
                .subscribe();
            CentrifugeClient.connect();
        }
    }

    useEffect(() => {
        getPairs().then(() =>
            getDataFromSpotStat().then(() => subscribeToSpotStat())
        );
    }, [location.pathname]);

    return (
        <div className={'pair-container'}>
            {/* Pair filtration */}
            <Header
                dataSocket={dataSocket}
                controlsSearch={[search, setSearch]}
                /*@ts-ignore*/
                controlsActiveSortPair={[activeSortPair, setActiveSortPair]}
                controlsFilterStar={[filterStar, setFilterStar]}
                controlsStars={[stars, setStars]}
            />
            {/* Pair table*/}
            <Content
                /*@ts-ignore*/
                activeSortPair={activeSortPair}
                dataSocket={dataSocket}
                controlsStars={[stars, setStars]}
                filterStar={filterStar}
            />
        </div>
    );
};
export default Pair;
