import React from 'react';
import './style.css';
import Icon from '@ant-design/icons';
import {Logo} from './icon';
import Button from '../../shared/components/ui/button';
import {useTranslation} from 'react-i18next';
import {useAuthState} from '../../useAuthState';
import {ErrorBoundary} from '../../app/ErrorBoundary';
import {useNavigate} from '@pankod/refine-react-router-v6';
import {useCookiesCustom} from '../../shared/hooks/useCookieCustom';

const PaymentLogout = () => {
    const {t} = useTranslation();
    const login = useAuthState((state) => state.login);
    const navigate = useNavigate();
    const {removeCookie} = useCookiesCustom();

    return (
        <ErrorBoundary
            logout={() => {
            }}
            navigate={navigate}
            removeCookie={removeCookie}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '180px',
                    height: '70vh',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Icon component={() => Logo('#FAFAFC')}/>
                </div>
                <div className={'card-logout'}>
                    <h5 className={'card-logout-h5'}>{t('paymentLogout.title')}</h5>
                    <div className={'card-logout-text'}>{t('paymentLogout.text')}</div>
                    <Button style={{height: 40}} onClick={() => login()}>
                        {t('buttons.toTheMainPage')}
                    </Button>
                    <div style={{display: 'flex', gap: 16}}>
            <span className={'card-logout-span'}>
              {t('paymentLogout.forgotASecretPhrase')}
            </span>
                        <button className={'card-logout-button'}>
                            {t('buttons.help')}
                        </button>
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );
};

export default PaymentLogout;
