import {IGetListData} from "../../../../pages/p2p/myDeals/cards/myTrades.p";
import React from "react";
import Icon from "@ant-design/icons";
import {getMoneyIcon} from "../../../../shared/helpers/getMoneyIcon";
import {Typography} from "antd";
import {ArrowGradientIcon} from "../../../../shared/components/icons";
import styled from "styled-components";

interface IBuySellOrder {
  currentOrder: IGetListData;
  type: string;
}

const BuySellOrderCard: React.FC<IBuySellOrder> = ({currentOrder, type}) => {
  return (
    <>
      <div className={'flex-row gap-4'}>
        <Icon
          component={getMoneyIcon({
            name: type === 'buy' ? currentOrder.fiat : currentOrder.ccy,
            size: 24
          })}
        />
        <Typography.Text className={'bold-18px-text'}>
          {
            type === 'buy'
              ? currentOrder.fiat?.toUpperCase()
              : currentOrder.ccy?.toUpperCase()
          }
        </Typography.Text>
      </div>
      <ArrowGradient component={ArrowGradientIcon}/>
      <div className={'flex-row gap-4'}>
        <Icon
          component={getMoneyIcon({
            name: type === 'buy' ? currentOrder.ccy : currentOrder.fiat,
            size: 24
          })}
        />
        <Typography.Text className={'bold-18px-text'}>
          {
            type === 'buy'
              ? currentOrder.ccy?.toUpperCase()
              : currentOrder.fiat?.toUpperCase()
          }
        </Typography.Text>
      </div>
    </>
  );
}

export default BuySellOrderCard;

const ArrowGradient = styled(Icon)`
  margin: 0 4px;
`;
