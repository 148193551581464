import React from 'react';
import {Menu, Typography} from 'antd';
import './styles.css';
import Icon from '@ant-design/icons';
import {useGetSideBarElements} from './useGetSideBarElements';
import {SideBarLogo} from '../../icons';
import Sider from 'antd/es/layout/Sider';
import {NavLink, useLocation, useParams,} from '@pankod/refine-react-router-v6';
import {DropdownIconSimpleColor} from '../../../../pages/paymentLogout/icon';

import getItem from './Item';
import styled from 'styled-components';
import menuItem from './menuItem';

const Sidebar = (): JSX.Element => {
    let loc = useLocation();
    const params = useParams();

    const {
        DrawSideBarMenuElement,
        BOTTOM_SIDEBAR_ELEMENTS,
        SIDEBAR_CATEGORIES,
    } = useGetSideBarElements({path: loc.pathname, params});

    return (
        <SiderStyled width={240}>
            <ContainerSiderStyled>
                <ContainerNavLinkStyled>
                    <NavLink to="/main">
                        <Icon component={SideBarLogo}/>
                    </NavLink>
                </ContainerNavLinkStyled>
                <ContainerSidebarCategoriesStyled>
                    {SIDEBAR_CATEGORIES.map((item, index) => {
                        return (
                            <ContainerItemCategory key={index}>
                                <TextCategoryStyled>{item.name}</TextCategoryStyled>
                                <Menu
                                    mode="inline"
                                    selectedKeys={[loc.pathname]}
                                    defaultOpenKeys={[loc.pathname]}
                                    style={{borderInlineEnd: 'unset'}}
                                    expandIcon={() => DropdownIconSimpleColor()}
                                    items={item.elements.map((item) =>
                                        getItem({item, DrawSideBarMenuElement})
                                    )}
                                />
                            </ContainerItemCategory>
                        );
                    })}

                    <ContainerMenuStyled>
                        <Menu
                            mode="inline"
                            selectedKeys={[loc.pathname]}
                            defaultOpenKeys={[loc.pathname]}
                            style={{borderInlineEnd: 'unset'}}
                            expandIcon={() => DropdownIconSimpleColor()}
                            items={BOTTOM_SIDEBAR_ELEMENTS.map((item) =>
                                menuItem({item, DrawSideBarMenuElement})
                            )}
                        />
                    </ContainerMenuStyled>
                </ContainerSidebarCategoriesStyled>
            </ContainerSiderStyled>
        </SiderStyled>
    );
};

const SiderStyled = styled(Sider)`
    transition: opacity 0.25s ease;
`;

const ContainerSiderStyled = styled.div`
    padding-top: 32px;
    background: #14141f;
    box-shadow: 0 0 1px rgba(12, 26, 75, 0.24),
    0 3px 8px -1px rgba(50, 50, 71, 0.05);
    border-right: 1px solid #413d5080;
    display: flex;
    flex-direction: column;
    gap: 36px;
    height: 100vh;
    position: sticky;
    top: 0;
`;

const ContainerNavLinkStyled = styled.div`
    padding-bottom: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
`;

const ContainerSidebarCategoriesStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const ContainerItemCategory = styled.div`
    display: flex;
    flex-direction: column;
    gap: 11px;
`;

const TextCategoryStyled = styled(Typography.Text)`
    padding-left: 26px;
    font-size: 12px;
    line-height: 15px;
`;

const ContainerMenuStyled = styled.div`
    border-top: 1px solid #413d5080;
    display: flex;
    flex-direction: column;
    margin-bottom: 44px;
    justify-items: flex-end;
    justify-content: flex-end;
    padding-top: 28px;
`;

export {Sidebar};
