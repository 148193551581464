import {Icon, Radio, RadioChangeEvent, Tooltip, Typography,} from '@pankod/refine-antd';
import {FC, useMemo} from 'react';
import {AdPriceType, AdTypesEnum, IFiat} from '../../types';
import {ICryptoCurrencyConstraintItem} from 'widgets/order/types';
import {ReactComponent as ArrowForwardIcon} from '../../../../../shared/components/ui/assets/svg/arrowForward.svg';
import '../../index.css';
import {getMoneyIcon} from 'shared/helpers/getMoneyIcon';
import {
  ReactComponent as QuestionOutlinedIcon
} from '../../../../../shared/components/ui/assets/svg/questionOutlined.svg';
import {useTranslation} from 'react-i18next';
import {AccountBalanceWallet, CashIcon, ExclamationCircle,} from '../../../../../shared/components/icons';
import styled from 'styled-components';
import {ChooseAdTypeRadio} from './chooseAdTypeRadio';
import {CurrencySelects} from './currencySelects';
import {ChoosePriceTypeForm} from './ChoosePriceTypeForm';

interface IChooseAdTypeProps {
    adType: AdTypesEnum;

    handleChangeAdType(type: AdTypesEnum): void;

    fiatList?: Array<IFiat>;
    currencyList?: Array<ICryptoCurrencyConstraintItem>;
    chosenCurrency?: ICryptoCurrencyConstraintItem;
    chosenFiat?: IFiat;

    handleChangeCurrency(currency?: ICryptoCurrencyConstraintItem): void;

    handleChangeFiat(fiat?: IFiat): void;

    isCurrencyListLoading: boolean;
    isFiatListLoading: boolean;
    activePrice: number;

    handleChangeAdCurrencyPrice(value: number): void;

    adCurrencyPrice: number;
    isNextBtnClick?: boolean;
    editAd?: boolean;
}

const ChooseAdType: FC<IChooseAdTypeProps> = ({
                                                  adCurrencyPrice,
                                                  adType,
                                                  activePrice,
                                                  handleChangeAdType,
                                                  handleChangeAdCurrencyPrice,
                                                  fiatList,
                                                  currencyList,
                                                  handleChangeCurrency,
                                                  isCurrencyListLoading,
                                                  chosenCurrency,
                                                  isFiatListLoading,
                                                  handleChangeFiat,
                                                  chosenFiat,
                                                  isNextBtnClick,
                                                  editAd,
                                              }) => {
    const {t} = useTranslation();
    const handleChangeAdTypeButtonClick = () => {
        if (!editAd)
            return (e: RadioChangeEvent) => handleChangeAdType(e.target.value);
        else return () => null;
    };
    const getBgColor = (type: AdTypesEnum.SELL | AdTypesEnum.BUY) =>
        adType === type ? '#474755' : '#2B2B36';
    const getChangeAdTypeStyles = (type: AdTypesEnum.SELL | AdTypesEnum.BUY) => {
        return {
            width: '100%',
            backgroundColor: getBgColor(type),
            border: 'none',
        };
    };
    const selectPrefixIcon = {
        currency: chosenCurrency?.currency === undefined && (
            <SelectPrefixIconContainer>
                <CashIcon color={'#C3C3CE'}/>
            </SelectPrefixIconContainer>
        ),
        fiat: chosenFiat?.currency === undefined && (
            <SelectPrefixIconContainer>
                <AccountBalanceWallet color={'#C3C3CE'}/>
            </SelectPrefixIconContainer>
        ),
    };
    const selectStatus = (
        currency: ICryptoCurrencyConstraintItem | IFiat | undefined
    ) => (isNextBtnClick === false || currency ? '' : 'error');
    const setCustomSelectValue = {
        currency: (value: any) => {
            currencyList &&
            handleChangeCurrency(currencyList.find((item) => item.id === +value));
        },
        fiat: (value: any) => {
            fiatList && handleChangeFiat(fiatList.find((item) => item.id === +value));
        },
    };
    const selectCustomSelectData = (
        isLoading: boolean,
        array: ICryptoCurrencyConstraintItem[] | IFiat[] | undefined
    ) => {
        return isLoading || !array
            ? []
            : array.map((item) => ({
                label: (
                    <SelectInnerIcon>
                        <MoneyIcon
                            component={getMoneyIcon({name: item.currency, size: 16})}
                        />
                        {item.currency}
                    </SelectInnerIcon>
                ),
                value: item.id,
                valueC: item.currency,
            }));
    };
    const customSelectPlaceholder = {
        currency: chosenCurrency ? (
            chosenCurrency.currency
        ) : (
            <CustomSelectPlaceholder>
                {t('p2p.chooseAdType.chooseActive').toString()}
            </CustomSelectPlaceholder>
        ),
        fiat: chosenFiat ? (
            chosenFiat.currency
        ) : (
            <CustomSelectPlaceholder>
                {t('p2p.chooseAdType.chooseFiat').toString()}
            </CustomSelectPlaceholder>
        ),
    };
    const isValidationError =
        isNextBtnClick === true &&
        !(chosenCurrency && chosenFiat && activePrice !== 0);
    const BottomIcons = () => {
        const iconComponent = (chosen: string) =>
            getMoneyIcon({
                name: chosen,
                size: 16,
            });
        return adType === AdTypesEnum.BUY ? (
            chosenFiat ? (
                <Icon component={iconComponent(chosenFiat.currency)}/>
            ) : (
                <AccountBalanceWallet color={'#FBFBFF'}/>
            )
        ) : chosenCurrency ? (
            <Icon component={iconComponent(chosenCurrency.currency)}/>
        ) : (
            <CashIcon color={'#FBFBFF'}/>
        );
    };
    const BottomText = () => {
        return (
            <>
                {adType === AdTypesEnum.SELL
                    ? `${
                        chosenCurrency
                            ? chosenCurrency.currency
                            : t('p2p.chooseAdType.active').toUpperCase()
                    }`
                    : `${
                        chosenFiat
                            ? chosenFiat.currency
                            : t('p2p.chooseAdType.fiat').toUpperCase()
                    }`}
            </>
        );
    };

    const price = useMemo(
        () =>
            `${chosenCurrency && chosenFiat ? adCurrencyPrice.toFixed(2) : '0.00'} ${
                chosenFiat?.currency
            }`,
        [adCurrencyPrice, chosenCurrency, chosenFiat]
    );

    const adActive = useMemo(
        () =>
            `1 ${
                chosenCurrency
                    ? chosenCurrency.currency
                    : t('p2p.chooseAdType.active').toUpperCase()
            }`,
        [chosenCurrency, t]
    );
    return (
        <div className="create-ad-cards-wrapper">
            <div className="choose-p2p-ad-type-card">
                <div className="p2p-ad-card-content">
                    <Typography className="create-p2p-ad-card-title">
                        {t('p2p.chooseAdType.title')}
                    </Typography>
                    <div className="p2p-ad-card-content">
                        <ChooseAdTypeRadio
                            adType={adType}
                            handleChangeAdTypeButtonClick={handleChangeAdTypeButtonClick}
                            getChangeAdTypeStyles={getChangeAdTypeStyles}
                        />
                        <CurrencySelects
                            adType={adType}
                            editAd={editAd}
                            selectStatus={selectStatus}
                            chosenFiat={chosenFiat}
                            chosenCurrency={chosenCurrency}
                            selectCustomSelectData={selectCustomSelectData}
                            selectPrefixIcon={selectPrefixIcon}
                            setCustomSelectValue={setCustomSelectValue}
                            customSelectPlaceholder={customSelectPlaceholder}
                            isCurrencyListLoading={isCurrencyListLoading}
                            isFiatListLoading={isFiatListLoading}
                            fiatList={fiatList}
                            currencyList={currencyList}
                            handleChangeCurrency={handleChangeCurrency}
                            handleChangeFiat={handleChangeFiat}
                        />
                    </div>

                    {isValidationError && (
                        <ValidationErrorContainer>
                            <ExclamationCircle/>
                            <ValidationError>
                                {t('p2p.chooseAdType.validationError')}
                            </ValidationError>
                        </ValidationErrorContainer>
                    )}
                </div>
                <div className="choose-p2p-ad-type-bottom-block">
                    <div className="centered-block">
                        <div className="row-item">
                            <BottomIcons/>
                            <Typography.Text className="create-p2p-ad-medium-text">
                                <BottomText/>
                            </Typography.Text>
                        </div>
                        <ArrowForwardIcon/>
                        <div className="row-item">
                            <BottomIcons/>
                            <Typography.Text className="create-p2p-ad-medium-text">
                                <BottomText/>
                            </Typography.Text>
                        </div>
                    </div>
                </div>
            </div>

            <div className="choose-p2p-ad-type-card">
                <div className="p2p-ad-card-content">
                    <Typography className="create-p2p-ad-card-title">
                        {t('p2p.chooseAdType.typeAndSizeOfPrice')}
                    </Typography>
                    <div className="p2p-ad-card-content">
                        <FlexDiv $flexDirection="column" $gap="16px" className="price-type">
                            <Typography>{t('p2p.chooseAdType.chooseTypeOfPrice')}</Typography>
                            <Radio.Group value={AdPriceType.FIXED}>
                                <Radio style={{color: 'white'}} value={AdPriceType.FIXED}>
                                    {t('p2p.chooseAdType.radio.fixType')}
                                </Radio>
                                <Radio
                                    style={{color: 'white'}}
                                    value={AdPriceType.FLOAT}
                                    disabled
                                >
                                    {t('p2p.chooseAdType.radio.floatingType')}
                                </Radio>
                            </Radio.Group>
                        </FlexDiv>
                        <div className="price-slider-slider-wrapper">
                            <div className="price-slider-slider-wrapper">
                                <Typography>
                                    {t('p2p.chooseAdType.costOfOneUnitInFiat')}
                                </Typography>
                                <ChoosePriceTypeForm
                                    activePrice={activePrice}
                                    adCurrencyPrice={adCurrencyPrice}
                                    chosenCurrency={chosenCurrency}
                                    chosenFiat={chosenFiat}
                                    isNextBtnClick={isNextBtnClick}
                                    handleChangeAdCurrencyPrice={handleChangeAdCurrencyPrice}
                                />
                                <div className="choose-p2p-ad-type-price-type-wrapper">
                                    <div className="item">
                                        <Typography.Text className="create-p2p-ad-medium-text">
                                            {activePrice ? activePrice : '0.00'}{' '}
                                            {chosenFiat?.currency}
                                        </Typography.Text>
                                        <Tooltip
                                            trigger={'hover'}
                                            placement="bottom"
                                            title={t('p2p.chooseAdType.recommendPrice')}
                                            color={'#2B2B36'}
                                            arrow={false}
                                            overlayInnerStyle={{
                                                color: '#FBFBFF',
                                                backgroundColor: '#2B2B36',
                                                padding: '6px 16px',
                                                maxWidth: 238,
                                                border: '1px solid #767687',
                                            }}
                                        >
                                            <DivPointer className="centered-block">
                                                <QuestionOutlinedIcon/>

                                                <Typography className="choose-p2p-ad-type-helper-text">
                                                    {t('p2p.chooseAdType.marketPrice')}
                                                </Typography>
                                            </DivPointer>
                                        </Tooltip>
                                    </div>
                                    <div className="item">
                                        <Typography.Text className="create-p2p-ad-medium-text">
                                            {price}
                                        </Typography.Text>
                                        <FlexDiv $gap="8px">
                                            <Typography className="choose-p2p-ad-type-helper-text">
                                                {t('p2p.chooseAdType.priceAd')}
                                            </Typography>
                                        </FlexDiv>
                                    </div>
                                </div>
                                <AdText $fontSize="12px">
                                    {t('p2p.chooseAdType.description')}
                                </AdText>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="choose-p2p-ad-type-price-bottom-block">
                    <AdText
                        $textAlign="end"
                        $width="100%"
                        className="create-p2p-ad-medium-text"
                    >
                        {adActive}
                    </AdText>
                    <AdText $textAlign="center" className="create-p2p-ad-medium-text">
                        =
                    </AdText>
                    <AdText
                        $textAlign="start"
                        $width="100%"
                        className="create-p2p-ad-medium-text"
                    >
                        {` ${
                            chosenFiat
                                ? `${
                                    adCurrencyPrice ? adCurrencyPrice.toFixed(2) : undefined
                                } ${chosenFiat.currency}`
                                : t('p2p.chooseAdType.fiat').toUpperCase()
                        }`}
                    </AdText>
                </div>
            </div>
        </div>
    );
};
type TTypography = {
    $textAlign?: string;
    $width?: string;
    $fontSize?: string;
};

interface TDiv {
}

interface TFlexDiv extends TDiv {
    $flexDirection?: string;
    $gap?: string;
}

const FlexDiv = styled.div<TFlexDiv>`
    display: flex;
    gap: ${({$gap}) => ($gap ? $gap : '0')};
    flex-direction: ${({$flexDirection}) =>
            $flexDirection ? $flexDirection : 'row'};
`;
const AdText = styled(Typography)<TTypography>`
    text-align: ${({$textAlign}) => ($textAlign ? $textAlign : 'start')};
    width: ${({$width}) => ($width ? $width : 'auto')};
    font-size: ${({$fontSize}) => ($fontSize ? $fontSize : '14px')};
`;
const CustomSelectPlaceholder = styled.div`
    display: flex;
    alignitems: center;
    gap: 8px;
`;
const SelectPrefixIconContainer = styled.div`
    margin: 0 8px 0 16px;
`;
const SelectInnerIcon = styled(FlexDiv)`
    align-items: center;
`;
const ValidationErrorContainer = styled(FlexDiv)`
    height: 16px;
    align-items: center;
    gap: 8px;
`;
const ValidationError = styled(Typography)`
    marginbottom: 12px;
    color: #f16063;
    margin: 0;
`;
const MoneyIcon = styled(Icon)`
    margin-right: 8px;
`;
const DivPointer = styled.div`
    cursor: pointer;
`;
export default ChooseAdType;
