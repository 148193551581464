const Trash = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M5.30769 5.86667C5.60508 5.86667 5.84615 6.10545 5.84615 6.4V12.8C5.84615 13.0946 5.60508 13.3333 5.30769 13.3333C5.01031 13.3333 4.76923 13.0946 4.76923 12.8V6.4C4.76923 6.10545 5.01031 5.86667 5.30769 5.86667Z"
      fill="#FBFBFF"
    />
    <path
      d="M8 5.86667C8.29738 5.86667 8.53846 6.10545 8.53846 6.4V12.8C8.53846 13.0946 8.29738 13.3333 8 13.3333C7.70262 13.3333 7.46154 13.0946 7.46154 12.8V6.4C7.46154 6.10545 7.70262 5.86667 8 5.86667Z"
      fill="#FBFBFF"
    />
    <path
      d="M11.2308 6.4C11.2308 6.10545 10.9897 5.86667 10.6923 5.86667C10.3949 5.86667 10.1538 6.10545 10.1538 6.4V12.8C10.1538 13.0946 10.3949 13.3333 10.6923 13.3333C10.9897 13.3333 11.2308 13.0946 11.2308 12.8V6.4Z"
      fill="#FBFBFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15 3.2C15 3.7891 14.5178 4.26667 13.9231 4.26667H13.3846V13.8667C13.3846 15.0449 12.4203 16 11.2308 16H4.76923C3.57969 16 2.61538 15.0449 2.61538 13.8667V4.26667H2.07692C1.48215 4.26667 1 3.7891 1 3.2V2.13333C1 1.54423 1.48215 1.06667 2.07692 1.06667H5.84615C5.84615 0.477563 6.32831 0 6.92308 0H9.07692C9.67169 0 10.1538 0.477563 10.1538 1.06667H13.9231C14.5178 1.06667 15 1.54423 15 2.13333V3.2ZM3.81942 4.26667L3.69231 4.32962V13.8667C3.69231 14.4558 4.17446 14.9333 4.76923 14.9333H11.2308C11.8255 14.9333 12.3077 14.4558 12.3077 13.8667V4.32962L12.1806 4.26667H3.81942ZM2.07692 3.2V2.13333H13.9231V3.2H2.07692Z"
      fill="#FBFBFF"
    />
  </svg>
);

export { Trash };
