
import React, {useEffect, useState} from 'react'
import { useTranslate } from '@pankod/refine-core'
import {API_URL} from "../../packages/keycloak-client/constants";
import {
  AllCoin,
  BitcoinIcon,
  CoinIcon,
  EthereumIcon, LtcCoin,
  MaticIcon,
  TetherIcon,
  WhiteBnbIcon,
  WhiteUsdcIcon
} from "../components/icons";
import {ICoinForRender} from "../../pages/p2p/myAnnouncements";
import {Icon} from "@pankod/refine-antd";

interface IGetListData {
  currency: string,
  name: string,
  is_available_p2p: boolean
}
export const useGetAnnouncementsTranslatedItems = () => {
  const t = useTranslate();

  const [coins,setCoins] = useState<IGetListData[]>([]);

  const getCoins = async () => {
    const res = await fetch(
      `${API_URL}/config/api/currency-constraint/search?limit=10&page=1`,
      {
        method: 'POST',
      }
    );
    if (res.ok) {
      const json = await res.json();
      setCoins([
        ...json.data
      ]);
    }
  }

  useEffect(() => {
    getCoins().then()
  },[])


  const iconRender = (name:string) => {
    switch (name.toLowerCase()) {
      case 'btc':
        return <BitcoinIcon />;
      case 'eth':
        return <EthereumIcon />;
      case 'usdt':
        return <TetherIcon />;
      case "bnb":
        return <CoinIcon />
      case "matic":
        return <MaticIcon/>
      case "usdc":
        return <WhiteUsdcIcon/>
      case "all":
        return <Icon component={() => AllCoin("#fff",24)}/>
      case "ltc":
        return <LtcCoin/>
      default:
        return <CoinIcon />;
    }
  }

  const coinsForRender:ICoinForRender[] = coins.filter(item => item.is_available_p2p).map((item) => {
    return {
      label: item.name,
      value: item.currency,
      icon: iconRender(item.currency)
    }
  })

  const statusItems = [
    {
      key: 'all',
      label: t('announcements.filters.status.all'),
    },
    {
      key: 'executed',
      label: t('announcements.filters.status.executed'),
    },
    {
      key: 'template',
      label: t('announcements.filters.status.templates'),
    },
    {
      key: 'created',
      label: t('announcements.filters.status.created'),
    },
  ];

  return {
    coinsForRender,
    statusItems
  }
}
