interface IButton {
  text: string;
  disable?: boolean;
  hidden?: boolean;
}

export interface IOrderDataCard {
  filledButton: IButton;
  outlinedButton: IButton;
}

export enum OrderStatus {
  inProgress,
  isCanceled,
  isTimeout,
}

export const defaultData: IOrderDataCard = {
  filledButton: {
    text: '',
    disable: false,
  },
  outlinedButton: {
    text: '',
    disable: false,
  },
};

export interface IUpdateOrder {
  orderId: number;
  user_confirmation_status?: string;
  status?: string;
  inputValue?: string;
  outputValue?: string;
  cancelWallet?: string;
}

export interface IOrderDataData {
  id: number;
  //expired_at: string
  user_id: string;
  status: string;
  notification_email: string;
  type: string;
  input_currency: string;
  output_currency: string;
  recipient_wallet: string;
  order_wallet: string;
  input_sum: string;
  input_price: string;
  cummulative_quote_qty: number | null;
  executed_qty: number | null;
  external_service_id: string;
  cancel_wallet: any;
  balance: number | string;
  commission: string;
  output_wallet?: string;
  created_at: string;
}

export interface IOrderData {
  data: IOrderDataData;
  //'updated_at': '2023-02-22T10:18:59.000000Z'
}

export const DEFAULT_ORDER_DATA: IOrderDataData = {
  id: 0,
  //sexpired_at: '',
  user_id: '',
  status: '',
  notification_email: '',
  type: '',
  input_currency: '',
  output_currency: '',
  recipient_wallet: '',
  order_wallet: '',
  input_price: '',
  input_sum: '',
  cummulative_quote_qty: null,
  executed_qty: null,
  external_service_id: '',
  cancel_wallet: null,
  balance: 0,
  // comission: 0,
  commission: '',
  created_at: '2021-02-22T09:40:04.000000Z',
};

export interface IExchangeOrderDataCard {
  currentOrderState: OrderStatusEnum;
  onFilledButtonClick: () => void;
  onOutlinedButtonClick: () => void;
  orderNumber: number;
  inputPrice: number;
  inputCurrency: string;
  outputPrice: number;
  outputCurrency: string;
  cryptoCourse: number;
  recipientWallet?: string;
  outputCommission?: string;
  networkCommission?: string;
  minimalTradeAmount?: string;
}

export interface IExchangeOrderTimeCard {
  orderStatus: OrderStatusEnum;
  handleTimerFinish: () => void;
  startTimeInSeconds: number;
  createData: string;
  orderNumber: number;
}

export interface IExchangeOrderTransferCard {
  currentStep: number;
  userBalance: number;
  addressWallet: string;
  inputCurrency: string;
  minimalTradeAmount?: string;
}

export enum OrderStatusEnum {
  NEW = 'NEW',
  WALLET_CREATED = 'WALLET_CREATED',
  WALLET_REPLENISHED = 'WALLET_REPLENISHED',
  CANCELED = 'CANCELED',
  CANCELED_REPLENISHED = 'CANCELED_REPLENISHED',
  CANCELED_REPLENISHED_EXECUTED = 'CANCELED_REPLENISHED_EXECUTED',
  TRANSACTION_WAITING = 'TRANSACTION_WAITING',
  EXCHANGE_PROCESSING = 'EXCHANGE_PROCESSING',
  WITHDRAW_WAITING = 'WITHDRAW_WAITING',
  CONFIRMATION_WAITING = 'CONFIRMATION_WAITING',
  CONFIRMATION_COMPLETED = 'CONFIRMATION_COMPLETED',
  EXCHANGE_CREATE_SPOT_ORDER = 'EXCHANGE_CREATE_SPOT_ORDER',
  EXCHANGE_COMPLETED = 'EXCHANGE_COMPLETED',
  WITHDRAW_COMPLETED = 'WITHDRAW_COMPLETED',
  SYSTEM_CANCELLED = 'SYSTEM_CANCELLED',
  USER_CANCELLED = 'USER_CANCELLED',
  EXECUTED = 'EXECUTED',
}
