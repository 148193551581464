import {IGetListData} from '../../pages/p2p/myDeals/cards/myTrades.p'

export interface IGetCurrentStep {
    currentStatus: string;
    isSeller?: boolean;
    isMyAppeal: boolean;
    appealsAmount: number;
    dealType: string;
    currentOrder: IGetListData;
    userId: string
}

interface IButton {
    isDisabled: boolean;
    text: string;
}

export interface ICurrentStepP2P {
    currentStep: number;
    leftButton: IButton;
    rightButton: IButton;
}

/**
 * Checks if the given time has reached 30 minutes after the current time.
 *
 * @param {string} time - The time to be checked.
 * @return {boolean} Returns true if the given time has reached 30 minutes after the current time, otherwise returns false.
 */
export const isTimerTimeEnd = (time: string): boolean => {
    // Получаем текущую дату и время с часовым поясом 00
    const now = new Date()
    const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

    // Парсим переданную дату в формате 2023-09-13 07:29:59
    const targetDateTime = new Date(time)


    // Добавляем 30 минут к переданной дате
    const targetDateTimePlus30Mins = new Date(targetDateTime.getTime() + 30 * 60000);

    // Сравниваем текущую дату и время с targetDateTimePlus30Mins
    return utc.getTime() > targetDateTimePlus30Mins.getTime()
};
