import React from 'react';
import { styled } from 'styled-components';
import { useTranslate } from '@pankod/refine-core';

type MobileFiltersDrawerProps = {
  children?: React.ReactElement;
};

const MobileDrawer = ({ children }: MobileFiltersDrawerProps) => {
  return (
    <>
      <Drawer
        style={{
          height: '100vh',
          padding: '15px',
          overflow: 'scroll',
        }}
      >
        <div>{children}</div>
      </Drawer>
    </>
  );
};

export default MobileDrawer;

const Drawer = styled.div`
  display: flex;
  position: fixed !important;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  background: #14141f;
  transition: all 0.4s ease-in-out;
  align-items: center;
  flex-direction: column;

  & form {
    width: 100%;
  }

  & > div {
    width: 100%;
    height: 100%;
    position: relative;
  }

  @media (min-width: 768px) {
    display: none !important;
  }
`;
