import React, { useEffect, useState } from 'react';
import { Col, Row } from '@pankod/refine-antd';
import { Dropdown, Typography } from 'antd';
import { ChevronDown, DownLoadIcon } from '../../../shared/components/icons';
import * as _ from 'lodash';
import { IFilterHistory, ISortHistory } from '../ordersTable';
import { useTranslate } from '@pankod/refine-core';
import { IGetHistoryOrder } from '../../../shared/interfaces/spot';
import OrdersTableHistoryRow from './ordersTableHistoryRow';
import VisibilityObserver from '../../../shared/hooks/on-visible/observer';
import DateFilterSort from './dateFilterSort';
import { API_URL } from '../../../packages/keycloak-client/constants';
import { axios } from '../../../shared/exios';
import { useLocation } from '@pankod/refine-react-router-v6';
import SpotTableLogin from '../spotTableLogin';
import { useCookiesCustom } from '../../../shared/hooks/useCookieCustom';

const OrdersTableHistory: React.FC<{
  tab: 'actual' | 'history';
}> = ({ tab }) => {
  const location = useLocation();
  const {cookie} = useCookiesCustom();
  const translate = useTranslate();
  const [histories, setHistories] = useState<IGetHistoryOrder[]>([]);
  const [total, setTotal] = useState(0);

  const [openStatus, setOpenStatus] = useState(false);
  const [openMarket, setOpenMarket] = useState(false);

  const [sort, setSort] = useState<ISortHistory>({
    date: {
      field: 'created_at',
      order: 'desc',
    },
  });
  const [pageHistory, setPageHistory] = useState<number>(1);
  const [filter, setFilter] = useState<IFilterHistory>({});

  async function getOrderHistory() {
    if (!!cookie['token']) {
      try {
        const res = await axios.post(
          `${API_URL}/${localStorage['activePair']
            ?.replace('-', '')
            ?.toLowerCase()}-spot/api/orders/search?limit=${15}&page=${pageHistory}`,
          {
            includes: [],
            sort: [...Object.values(sort)],
            filters: [...Object.values(filter)],
          });
        setTotal(res?.data?.meta?.total);
        if (pageHistory === 1) {
          setHistories([...res?.data?.data]);
        } else setHistories([...histories, ...res?.data?.data]);
      } catch (e) {
        console.log('getOrderHistory error', e);
      }
    } else console.log('getOrderHistory not auth');
  }

  const handleChangeMarket = (value: boolean | string) => {
    setOpenMarket(false);
    if (value === 'all') {
      setFilter(_.omit(filter, 'market'));
    } else {
      setFilter({
        ...filter,
        market: {
          field: 'market',
          operator: '=',
          value,
        },
      });
    }
  };

  const handleNextPage = (visibility: boolean) => {
    if (visibility && pageHistory < total) {
      setPageHistory((prevState) => prevState + 1);
    }
  };

  const handleChangeStatus = (value: string) => {
    setOpenStatus(false);
    if (value === 'all') {
      setFilter(_.omit(filter, 'status'));
    } else {
      setFilter({
        ...filter,
        status: {
          field: 'status',
          operator: '=',
          value,
        },
      });
    }
  };

  useEffect(() => {
    const updateDataInterval = setInterval(() => {
      getOrderHistory().then();
    }, 35000);
    return () => {
      clearInterval(updateDataInterval);
    };
  }, []);

  useEffect(() => {}, [location?.pathname]);

  useEffect(() => {
    getOrderHistory().then();
  }, [tab, filter, sort, pageHistory]);

  useEffect(() => {
    setPageHistory(1);
  }, [filter, tab]);

  return (
    <div>
      <DateFilterSort
        setFilter={setFilter}
        filter={filter}
        sort={sort}
        setSort={setSort}
      />
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{ marginBottom: '16px' }}
      >
        <Col span={3}>
          <Typography.Text>
            {translate(`spotOrders.columns.header.date`)}
          </Typography.Text>
        </Col>
        <Col span={2}>
          <Typography.Text>
            {translate(`spotOrders.columns.header.pair`)}
          </Typography.Text>
        </Col>
        <Col span={2}>
          <Typography.Text>
            {translate(`spotOrders.columns.header.type.type`)}
          </Typography.Text>
        </Col>
        <Col span={2}>
          <Dropdown
            onOpenChange={setOpenMarket}
            menu={{
              items: [
                {
                  key: 'all',
                  label: translate(`spotOrders.columns.header.market.all`),
                  onClick: (info) => handleChangeMarket('all'),
                },
                {
                  key: 1,
                  label: translate(`spotOrders.columns.header.market.true`),
                  onClick: (info) => handleChangeMarket(true),
                },
                {
                  key: 0,
                  label: translate(`spotOrders.columns.header.market.false`),
                  onClick: (info) => handleChangeMarket(false),
                },
              ],
              selectable: true,
              defaultSelectedKeys: ['all'],
            }}
          >
            <div className={'flex-row align-items-center gap-8'}>
              <Typography.Text style={{ fontSize: 12 }}>
                {translate(
                  `spotOrders.columns.header.market.${
                    filter.market?.value ?? 'market'
                  }`
                )}
              </Typography.Text>
              <div
                className={'cursor-pointer flex align-items-center'}
                style={{ rotate: openMarket ? '180deg' : '0deg' }}
              >
                {ChevronDown('#FBFBFF', 12)}
              </div>
            </div>
          </Dropdown>
        </Col>
        <Col span={3}>
          <Typography.Text>
            {translate(`spotOrders.columns.header.price`)}
          </Typography.Text>
        </Col>
        <Col span={2}>
          <Typography.Text>
            {translate(`spotOrders.columns.header.percent`)}
          </Typography.Text>
        </Col>
        <Col span={3}>
          <Typography.Text>
            {translate(`spotOrders.columns.header.count`)}
          </Typography.Text>
        </Col>
        <Col span={4}>
          <Typography.Text>
            {translate(`spotOrders.columns.header.all`)}
          </Typography.Text>
        </Col>
        <Col span={3}>
          <Dropdown
            onOpenChange={setOpenStatus}
            menu={{
              items: [
                {
                  key: 'all',
                  label: translate(`spotOrders.columns.header.statusType.all`),
                  onClick: (info) => handleChangeStatus(info.key),
                },
                {
                  key: 'active',
                  label: translate(
                    `spotOrders.columns.header.statusType.active`
                  ),
                  onClick: (info) => handleChangeStatus(info.key),
                },
                {
                  key: 'complete',
                  label: translate(
                    `spotOrders.columns.header.statusType.complete`
                  ),
                  onClick: (info) => handleChangeStatus(info.key),
                },
                {
                  key: 'canceled',
                  label: translate(
                    `spotOrders.columns.header.statusType.canceled`
                  ),
                  onClick: (info) => handleChangeStatus(info.key),
                },
              ],
              selectable: true,
              defaultSelectedKeys: ['all'],
            }}
          >
            <div className={'flex-row align-items-center gap-8'}>
              <Typography.Text style={{ fontSize: 12 }}>
                {filter.status?.value
                  ? translate(
                      `spotOrders.columns.header.statusType.${filter.status?.value}`
                    )
                  : translate(`spotOrders.columns.header.status`)}
              </Typography.Text>
              <div
                className={'cursor-pointer flex align-items-center'}
                style={{ rotate: openStatus ? '180deg' : '0deg' }}
              >
                {ChevronDown('#FBFBFF', 12)}
              </div>
            </div>
          </Dropdown>
        </Col>
      </Row>
      <div
        style={{
          height: '129.316px',
          overflow: 'overlay',
        }}
      >
        {!!cookie['token'] ? (
          histories.map((history, index) =>
            index === histories.length - 1 ? (
              <VisibilityObserver
                onChangeVisibility={handleNextPage}
                options={{ rootMargin: '100px' }}
                key={`order-history-${history.id}`}
              >
                <OrdersTableHistoryRow history={history} />
              </VisibilityObserver>
            ) : (
              <OrdersTableHistoryRow
                history={history}
                key={`order-history-${history.id}`}
              />
            )
          )
        ) : (
          <SpotTableLogin />
        )}
      </div>
      <div style={{ position: 'absolute', top: 24, right: 16 }}>
        {/*TODO: Посмотреть по дизайну*/}
        <div
          className={'flex-row align-items-center gap-8 cursor-pointer'}
          // onClick={handleSave}
        >
          {DownLoadIcon('#767687', 16)}
          <Typography.Text style={{ color: '#767687' }}>
            {translate(`spotOrders.tabs.export`)}
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};

export default OrdersTableHistory;
