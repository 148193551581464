import {default as baseAxios} from 'axios';
import {API_URL} from '../packages/keycloak-client/constants';
import {getCookieByName} from './helpers/controlCookies';
import {useAuthState} from '../useAuthState';

export const axios = baseAxios.create({baseURL: API_URL});

axios.interceptors.response.use(
    (res) => {
        return res;
    },
    async (error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403 || error?.response?.status === 500) {
            if (error.response.data.message === "TokenException: [Keycloak Guard] Expired token" ||
                error.response.data.error === "TokenException: [Keycloak Guard] Expired token" ||
                error.response.data.message === "[Keycloak Guard] Expired token" ||
                error.response.data.error === "[Keycloak Guard] Expired token"
            ) {
                const originalRequest = error.config;
                return await useAuthState.getState().refreshToken(originalRequest);
            }
        }

        return Promise.reject(error);
    }
);

axios.interceptors.request.use((config) => {
    // @ts-ignore
    config.headers['Content-Type'] = 'application/json';
    if (!config.url?.includes('refresh-token')) {
        // @ts-ignore
        config.headers['Authorization'] = getCookieByName('token') ? `Bearer ${getCookieByName('token')}` : '';
    }
    return config;
});
