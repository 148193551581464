import React from "react";
import {Button, Popconfirm} from "@pankod/refine-antd";
import {IGetListData} from "../../../../pages/p2p/myDeals/cards/myTrades.p";
import CustomButton from '../../../../shared/components/ui/button';
import {useTranslation} from "react-i18next";
import {useUpdate} from "@pankod/refine-core";
import {notification} from "antd";
import CancelNotificationDescription from "../components/orderDeals/CancelNotificationDescription";
import Icon from "@ant-design/icons";
import {CloseNotification} from "../../../../shared/components/icons";
import styled from "styled-components";
import {ButtonsTypes} from "../../../../shared/components/ui/button/filledButton.p";
import {API_URL} from "../../../../packages/keycloak-client/constants";
import {axios} from "../../../../shared/exios";

interface ICurrentStepP2P {
  currentStep: number;
  leftButton: IButton;
  rightButton: IButton;
}

type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface IButton {
  isDisabled: boolean;
  text: string;
}

export const OrderButtons = ({
                               leftOnClick,
                               rightOnClick,
                               leftDisabled,
                               rightDisabled,
                               currentStepStatusButtons,
                               isSeller,
                               updateP2POrderStatus,
                               currentOrder,
                               isMyAppeal,
                             }: {
  leftOnClick: () => void,
  rightOnClick: () => void,
  leftDisabled: boolean,
  rightDisabled: boolean,
  currentStepStatusButtons: ICurrentStepP2P,
  isSeller: boolean,
  updateP2POrderStatus: (status: string, useNotification?: boolean) => void,
  currentOrder: IGetListData,
  isMyAppeal: boolean,
}) => {
  const {t} = useTranslation();
  const {mutate} = useUpdate<{ data: IGetListData }>();
  const [api, contextHolder] = notification.useNotification();


  const cancelDealNotification = (type: NotificationType) => {
    api[type]({
      message: t('p2p.orderDeals.dealCancel'),
      description: <CancelNotificationDescription currentOrder={currentOrder}/>,
      duration: 10,
      closeIcon: <Icon component={CloseNotification} style={{fontSize: 24}}/>,
    });
  };

  const isTimerTimeEnd = (time: string): boolean => {
    // Получаем текущую дату и время с часовым поясом 00
    const now = new Date()
    const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

    // Парсим переданную дату в формате 2023-09-13 07:29:59
    const targetDateTime = new Date(time)

    // Добавляем 30 минут к переданной дате
    const targetDateTimePlus30Mins = new Date(targetDateTime.getTime() + 30 * 60000);

    // Сравниваем текущую дату и время с targetDateTimePlus30Mins
    return utc.getTime() > targetDateTimePlus30Mins.getTime()
  };

  const handleUpdateStatusCancel = () => {
      if (!isSeller) {
          axios.patch(`${API_URL}/p2p/api/order/${currentOrder.id}`, {
              status: 'refused',
          }).then(() => {
              updateP2POrderStatus('refused');
              cancelDealNotification('error')
          }).catch((e) => {
              console.log('handleUpdateStatusCancel error', e)
          })
      } else if (isSeller) {
          updateP2POrderStatus('refused');
      }
  };

  return (
    <>
      {contextHolder}
      {currentStepStatusButtons.leftButton.text !== '' && (
        <div>
          <CustomButton
            onClick={() => leftOnClick()}
            disabled={leftDisabled}
          >
            {currentStepStatusButtons.leftButton.text}
          </CustomButton>
        </div>
      )}
      {currentStepStatusButtons.rightButton.text !== '' && (
        <div>
          {
            currentOrder.status === 'respond'
              ? <Popconfirm
                onConfirm={handleUpdateStatusCancel}
                okText={t('payments.popConfirm.Yes')}
                cancelText={t('payments.popConfirm.No')}
                okButtonProps={{
                  size: 'large',
                  style: {paddingInline: 32},
                }}
                cancelButtonProps={{
                  size: 'large',
                  style: {
                    paddingInline: 32,
                    background: 'none',
                  },
                }}
                title={t('p2p.orderDeals.popconfirmInfo')}
                disabled={currentStepStatusButtons.rightButton.isDisabled}
              >
                {
                  isSeller
                    ? <ButtonStyled type={'primary'} className={'buttonOutline'}>
                      {currentStepStatusButtons.rightButton.text}
                    </ButtonStyled>
                    : <>
                      {
                        isTimerTimeEnd(currentOrder.timer_start)
                          ? <ButtonStyled type={'primary'} className={'buttonOutline'}>
                            {currentStepStatusButtons.rightButton.text}
                          </ButtonStyled>
                          : <FakeCancelButton disabled={currentStepStatusButtons.rightButton.isDisabled}>
                            {currentStepStatusButtons.rightButton.text}
                          </FakeCancelButton>
                      }
                    </>
                }
              </Popconfirm>

              : <CustomButton
                onClick={() => rightOnClick()}
                type={ButtonsTypes.outlined}
                disabled={rightDisabled}
              >
                {currentStepStatusButtons.rightButton.text}
              </CustomButton>
          }
        </div>
      )}
    </>
  )
}

const ButtonStyled = styled(Button)`
  min-width: 100%;
  height: 100%;
`;

const FakeCancelButton = styled(Button)`
  border-radius: 8px;
  position: relative;
  background: transparent !important;
  border: 1px solid #2B2B36 !important;
  min-width: 100%;
  height: 100%;

  :disabled {
    border-color: #2B2B36 !important;
  }
`
