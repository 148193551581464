import { Button } from '@pankod/refine-antd';
import STYLES from './index.module.css';
import { useTranslation } from 'react-i18next';

interface IAppealModalProps {
  onClose: () => void;
  onSuccess: () => void;
}

const AppealModalFooter = (props: IAppealModalProps) => {
  const { t } = useTranslation();

  const { onClose: handleOnClose, onSuccess: handleOnSuccess } = props;

  return (
    <div className={STYLES.footer}>
      <Button type="primary" onClick={handleOnSuccess}>
        {t('p2p.getCurrentStep.submitAppeal')}
      </Button>
      <Button type="dashed" onClick={handleOnClose}>
        {t('buttons.cancel')}
      </Button>
    </div>
  );
};

export { AppealModalFooter };
