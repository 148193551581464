import {Typography} from "@pankod/refine-antd";
import {BaseRecord, useTranslate} from "@pankod/refine-core";
import {styled} from "styled-components";

export const DealTerms = ({record}: { record: BaseRecord }) => {
  const t = useTranslate();
  return (
    <Container>
      <div>
        <GreyText>{t('announcements.params.dealTerms')}</GreyText>
        <WhiteText>{record.deal_terms}</WhiteText>
      </div>
      <div>
        <GreyText>{t('announcements.params.autoResponse')}</GreyText>
        <WhiteText>{record.auto_response}</WhiteText>
      </div>

    </Container>
  )
}


const GreyText = styled(Typography.Text)`
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #767687;
`
const WhiteText = styled(Typography.Text)`
  font-family: Inter, sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  font-weight: 500;
  color: #FBFBFF;
`
const Container = styled.div`
  padding: 16px 24px;
  display: flex;
  gap: 30px;
  border-top: 1px solid #474755;

  div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 45%;
  }
`
