import React, {useEffect, useState} from 'react';
import {ISpotRowHistory} from '../../../shared/interfaces/spot';
import {Typography} from 'antd';
import Icon from '@ant-design/icons';
import {FullScreenIcon} from '../../../shared/components/icons';
import {Link, useLocation} from '@pankod/refine-react-router-v6';
import {useTranslation} from 'react-i18next';
import {axios} from '../../../shared/exios';
import {API_URL} from '../../../packages/keycloak-client/constants';
import {createDateAsUTC} from '../../../shared/helpers/convertTime';
import {useCookiesCustom} from '../../../shared/hooks/useCookieCustom';
import {useAuthState} from "../../../useAuthState";

const SpotMyTradeDeals: React.FC = () => {
    const {cookie} = useCookiesCustom();
    const uid = useAuthState((state) => state?.userAPI?.uid) || cookie['uid'];
    const {t} = useTranslation();
    const location = useLocation();

    const [hoveredItem, setHoveredItem] = useState(-1);
    const [myDealsData, setMyDealsData] = useState<ISpotRowHistory[]>([]);
    const [activePair, setActivePair] = useState(
        () => localStorage['activePair']
    );

    async function getMyDealsSpot() {
        // TODO: Добавить пагинацию
        if (!!cookie['token']) {
            try {
                const res = await axios.post(
                    `${API_URL}/${activePair
                        ?.replace('-', '')
                        ?.toLowerCase()}-spot/api/orders/search`,
                    {
                        sort: [{field: 'created_at', direction: 'desc'}],
                        filter: [
                            {
                                field: 'uid',
                                operator: '=',
                                value: uid,
                            },
                        ],
                    }
                );

                const data = res?.data?.data;

                if (!!data?.length) {
                    const arr: {
                        isBuy: boolean;
                        price: number;
                        amount: number;
                        date: string;
                    }[] = [];

                    setMyDealsData(() => {
                        for (const item of data) {
                            arr.push({
                                isBuy: item.type === 'buy',
                                price: +item.price,
                                amount: +item.sum,
                                date: createDateAsUTC(new Date(item.created_at)).toLocaleString(
                                    undefined,
                                    {
                                        month: 'numeric',
                                        day: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                    }
                                ),
                            });
                        }
                        return arr;
                    });
                }
            } catch (e) {
                console.log('getMyDealsSpot error', e);
            }
        }
    }

    useEffect(() => {
        setActivePair(() => localStorage['activePair']);
        setMyDealsData([]);
        getMyDealsSpot().then();
        const updateDataInterval = setInterval(() => {
            setMyDealsData([]);
            getMyDealsSpot().then();
        }, 35000);

        return () => {
            clearInterval(updateDataInterval);
        };
    }, []);

    useEffect(() => {
        setActivePair(() => localStorage['activePair']);
        setMyDealsData([]);
        getMyDealsSpot().then();
    }, [location?.pathname]);

    return (
        <>
            <div className={'flex-column gap-16'}>
                <div
                    style={{
                        width: '100%',
                        justifyContent: 'flex-end',
                        cursor: 'pointer',
                    }}
                    className={'flex-row'}
                >
                    <Link to={`/spot/dashboard/my-deals/${activePair}`}>
                        <Icon component={() => FullScreenIcon({size: 16})}/>
                    </Link>
                </div>
                <div className={'flex-column gap-8'}>
                    <div
                        style={{
                            width: '100%',
                            display: 'grid',
                            gap: 8,
                            gridTemplateColumns: '1fr 1fr 1fr',
                        }}
                    >
                        <Typography.Text
                            className={'light-bold-12px-text'}
                            style={{color: '#767687', textAlign: 'left'}}
                        >
                            {`${t('spot.price')} (${activePair?.split('-')[1]})`}
                        </Typography.Text>
                        <Typography.Text
                            className={'light-bold-12px-text'}
                            style={{color: '#767687', textAlign: 'right'}}
                        >
                            {`${t('spot.amount')} (${activePair?.split('-')[0]})`}
                        </Typography.Text>
                        <Typography.Text
                            className={'light-bold-12px-text'}
                            style={{color: '#767687', textAlign: 'right'}}
                        >
                            {t('spot.datatime')}
                        </Typography.Text>
                    </div>
                    <div
                        style={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            height: 560,
                            marginLeft: -16,
                            marginRight: -16,
                        }}
                    >
                        {!!myDealsData?.length &&
                            myDealsData.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div
                                            className={'flex-row gap-8 align-items-center'}
                                            onMouseEnter={() => setHoveredItem(index)}
                                            onMouseLeave={() => setHoveredItem(-1)}
                                            style={{
                                                cursor: 'pointer',
                                                height: 24,
                                                paddingLeft: 16,
                                                paddingRight: 16,
                                                backgroundColor:
                                                    hoveredItem === index
                                                        ? 'rgba(255, 255, 255, 0.05)'
                                                        : 'initial',
                                            }}
                                        >
                                            <Typography.Text
                                                className={'light-bold-12px-text'}
                                                style={{
                                                    lineHeight: '24px',
                                                    width: 90,
                                                    color: item.isBuy ? '#58BF92' : '#F16063',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                {item?.price || 0}
                                            </Typography.Text>
                                            <Typography.Text
                                                className={'light-bold-12px-text'}
                                                style={{
                                                    width: 90,
                                                    textAlign: 'right',
                                                    lineHeight: '24px',
                                                }}
                                            >
                                                {item?.amount?.toFixed(8) || 0}
                                            </Typography.Text>
                                            <Typography.Text
                                                className={'light-bold-12px-text'}
                                                style={{
                                                    width: 90,
                                                    textAlign: 'right',
                                                    lineHeight: '24px',
                                                }}
                                            >
                                                {item?.date}
                                            </Typography.Text>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </>
    );
};

export {SpotMyTradeDeals};
