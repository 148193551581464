import React, { FC, PropsWithChildren } from 'react';
import { Modal, ModalProps } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as CloseIcon } from '../assets/svg/modal-close-icon.svg';

interface IBasicModalProps extends ModalProps {
  title: string | React.ReactNode;
  open: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  width?: number;
  children: React.ReactNode;
  cancelText?: React.ReactNode;
  okText?: React.ReactNode;
  footer?: React.ReactNode;
}

export type BasicModalProps = PropsWithChildren<IBasicModalProps>;

const BasicModal: FC<BasicModalProps> = ({
  title,
  open,
  children,
  width,
  cancelText,
  okText,
  onCancel,
  onOk,
  footer,
  ...props
}) => {
  return (
    <Modal
      {...props}
      title={title}
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      width={width ? width + 80 : undefined}
      cancelText={cancelText}
      okText={okText}
      centered
      closeIcon={<Icon component={CloseIcon} style={{ fontSize: '10.5px' }} />}
      maskStyle={{
        backdropFilter: 'blur(9px)',
      }}
      style={{
        padding: '40px',
      }}
      footer={footer}
    >
      {children}
    </Modal>
  );
};

export { BasicModal };
