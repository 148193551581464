import { ArrowLeftOutlined } from "@ant-design/icons"
import { Icon, Typography } from "@pankod/refine-antd"
import { useTranslate } from "@pankod/refine-core"
import { NavLink, useLocation } from "react-router-dom"

export const BackLink = ({isSeller, adId} : {isSeller: boolean, adId: number | undefined}) => {
    const t = useTranslate();
    const getLinkTo = () => !loc.pathname.includes('my-announcements') ? '/p2p/my-deals' : `/p2p/my-announcements/${adId}`;
    const loc = useLocation();
    return (
        <>{ isSeller && (
            <NavLink
                to={getLinkTo()}
                className={'flex-row gap-8'}
                style={{ cursor: 'pointer' }}
            >
                <Icon
                    component={() => (
                        <ArrowLeftOutlined style={{ color: '#FBFBFF' }} />
                    )}
                />
                <Typography.Text>{t('p2p.orderDeals.toDealsList')}</Typography.Text>
            </NavLink>
        )}</>
    )
}