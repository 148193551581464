import {ImgParam} from "../../../../../interfaces";

export const InfoCircle = ({size, color}: ImgParam) => (
  <svg width={size ? size : 24} height={size ? size : 24} viewBox="0 0 24 24" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
          fill={color ? color : '#767687'}/>
    <path
      d="M14.1461 9.13184L10.7095 9.5625L10.5865 10.1338L11.2632 10.2568C11.7027 10.3623 11.7906 10.5205 11.6939 10.96L10.5865 16.1631C10.2964 17.5078 10.7447 18.1406 11.7994 18.1406C12.6168 18.1406 13.566 17.7627 13.9966 17.2441L14.1285 16.6201C13.8296 16.8838 13.3902 16.9893 13.1002 16.9893C12.6871 16.9893 12.5377 16.6992 12.6431 16.1895L14.1461 9.13184Z"
      fill={color ? color : '#767687'}/>
    <circle cx="12.75" cy="6" r="1.5" fill={color ? color : '#767687'}/>
  </svg>

)

export const SupportIcon = ({size, color}: ImgParam) => (
  <svg width={size ? size : 24} height={size ? size : 24} viewBox="0 0 24 24" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M12 1.5C7.85786 1.5 4.5 4.85786 4.5 9V15.75H3V9C3 4.02944 7.02944 0 12 0C16.9706 0 21 4.02944 21 9V15.75H19.5V9C19.5 4.85786 16.1421 1.5 12 1.5Z"
          fill={color ? color : '#767687'}/>
    <path
      d="M16.5 12C16.5 11.1716 17.1716 10.5 18 10.5H21V16.5C21 17.3284 20.3284 18 19.5 18H18C17.1716 18 16.5 17.3284 16.5 16.5V12Z"
      fill={color ? color : '#767687'}/>
    <path
      d="M7.5 12C7.5 11.1716 6.82843 10.5 6 10.5H3V16.5C3 17.3284 3.67157 18 4.5 18H6C6.82843 18 7.5 17.3284 7.5 16.5V12Z"
      fill="#767687"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M20.25 12.75C20.6642 12.75 21 13.0858 21 13.5V18C21 20.0711 19.3211 21.75 17.25 21.75H12C11.5858 21.75 11.25 21.4142 11.25 21C11.25 20.5858 11.5858 20.25 12 20.25H17.25C18.4926 20.25 19.5 19.2426 19.5 18V13.5C19.5 13.0858 19.8358 12.75 20.25 12.75Z"
          fill={color ? color : '#767687'}/>
    <path
      d="M9.75 21C9.75 20.1716 10.4216 19.5 11.25 19.5H12.75C13.5784 19.5 14.25 20.1716 14.25 21C14.25 21.8284 13.5784 22.5 12.75 22.5H11.25C10.4216 22.5 9.75 21.8284 9.75 21Z"
      fill={color ? color : '#767687'}/>
  </svg>
)

export const CopyCustomIcon = ({
                                 size,
                                 color
                               }: ImgParam) => (
  <svg width={size ? size : 16} height={size ? size : 16} viewBox="0 0 16 16" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 0H9.5V1H4C3.44772 1 3 1.44772 3 2V14C3 14.5523 3.44772 15 4 15H12C12.5523 15 13 14.5523 13 14V4.5H14V14C14 15.1046 13.1046 16 12 16H4C2.89543 16 2 15.1046 2 14V2C2 0.89543 2.89543 0 4 0Z"
      fill={color ? color : '#FBFBFF'}/>
    <path d="M9.5 3V0L14 4.5H11C10.1716 4.5 9.5 3.82843 9.5 3Z" fill="#FBFBFF"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M5 11.5C5 11.2239 5.22386 11 5.5 11H7.5C7.77614 11 8 11.2239 8 11.5C8 11.7761 7.77614 12 7.5 12H5.5C5.22386 12 5 11.7761 5 11.5Z"
          fill={color ? color : '#FBFBFF'}/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M5 9.5C5 9.22386 5.22386 9 5.5 9H10.5C10.7761 9 11 9.22386 11 9.5C11 9.77614 10.7761 10 10.5 10H5.5C5.22386 10 5 9.77614 5 9.5Z"
          fill={color ? color : '#FBFBFF'}/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M5 7.5C5 7.22386 5.22386 7 5.5 7H10.5C10.7761 7 11 7.22386 11 7.5C11 7.77614 10.7761 8 10.5 8H5.5C5.22386 8 5 7.77614 5 7.5Z"
          fill={color ? color : '#FBFBFF'}/>
  </svg>

)

export interface IGradientImg extends Omit<ImgParam, 'color'> {
  startColor?: string
  stopColor?: string
}

export const TradeChangeIcon = ({startColor, stopColor, size}: IGradientImg) => (
  <svg width={size ? size : 16} height={size ? size : 16} viewBox="0 0 16 16" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M16 4.5V7.125H0L6.05405 1V4.5H16Z" fill="url(#paint0_linear_8692_846)"/>
    <path d="M0 11.5V8.875H16L9.94595 15V11.5H0Z" fill="url(#paint1_linear_8692_846)"/>
    <defs>
      <linearGradient id="paint0_linear_8692_846" x1="0.971963" y1="6.51923" x2="14.9869" y2="-2.70284"
                      gradientUnits="userSpaceOnUse">
        <stop stopColor={startColor ? stopColor : "#7D50FF"}/>
        <stop offset="1" stopColor={startColor ? stopColor : "#7785FF"}/>
      </linearGradient>
      <linearGradient id="paint1_linear_8692_846" x1="15.028" y1="9.48077" x2="1.01307" y2="18.7028"
                      gradientUnits="userSpaceOnUse">
        <stop stopColor={startColor ? stopColor : "#7D50FF"}/>
        <stop offset="1" stopColor={startColor ? stopColor : "#7785FF"}/>
      </linearGradient>
    </defs>
  </svg>
)


export const ShevronIcon = ({size, color}: ImgParam) => (
  <svg width={size ? size : 16} height={size ? size : 16} viewBox="0 0 16 16" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M4.64645 1.64645C4.84171 1.45118 5.15829 1.45118 5.35355 1.64645L11.3536 7.64645C11.5488 7.84171 11.5488 8.15829 11.3536 8.35355L5.35355 14.3536C5.15829 14.5488 4.84171 14.5488 4.64645 14.3536C4.45118 14.1583 4.45118 13.8417 4.64645 13.6464L10.2929 8L4.64645 2.35355C4.45118 2.15829 4.45118 1.84171 4.64645 1.64645Z"
          fill={color ? color : "#C3C3CE"}/>
  </svg>

)
