const ExclamationCircleIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#FBFBFF"
    />
    <path
      d="M7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11Z"
      fill="#FBFBFF"
    />
    <path
      d="M7.09796 4.99504C7.04469 4.46228 7.46305 4 7.99846 4C8.53387 4 8.95223 4.46228 8.89896 4.99504L8.54821 8.50248C8.51997 8.78492 8.2823 9 7.99846 9C7.71461 9 7.47695 8.78492 7.44871 8.50248L7.09796 4.99504Z"
      fill="#FBFBFF"
    />
  </svg>
);

export { ExclamationCircleIcon };
