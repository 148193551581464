import {IPaymentInfo} from 'pages/p2p/dashboard/interface';
import React from 'react';
import Icon from '@ant-design/icons';
import {
    AdvCache,
    AkBars,
    AlfaBank,
    BCS,
    BSPD,
    CentrInvest,
    CitiBank,
    CreditEurope,
    DefaultBank,
    FPS,
    GaspromBank,
    HomeCreditBank,
    MobileIcon,
    MTS,
    OpenBank,
    OTP,
    Payeer,
    PochtaBank,
    QIWI,
    Raiffeisen,
    RenCredit,
    Rosbank,
    RSB,
    SberBank,
    SovcomBank,
    Tinkoff,
    Tochka,
    UBRR,
    UniCreditBank,
    UralSib,
    VTB,
    YooMoney
} from '../components/icons'
import {useTranslate} from '@pankod/refine-core';

export interface IBankTransfer {
    bank_name?: any;
    account_office?: string;
    bank_account_number?: string;
    bank_identification_code?: string;
}

interface IWebService {
    web_service_name?: string;
    web_service_account_id?: string;
    email?: string;
}

interface IBankCard {
    bank_name?: string;
    card_identifier?: string;
}

export interface IUseGetPaymentInfoIconData {
    icon: JSX.Element;
    text: string;
    phone?: string
    sbp?: string
    bankTransfer?: IBankTransfer
    webService?: IWebService
    bankCard?: IBankCard
}

export const useGetPaymentInfoIconData = (
    paymentInfo: IPaymentInfo | undefined
): { paymentData: IUseGetPaymentInfoIconData } => {
    const translate = useTranslate();
    const getData = (): IUseGetPaymentInfoIconData => {
        if (paymentInfo) {
            switch (paymentInfo.payment_type) {
                case 'bankCardPayment': {
                    const text = translate('payments.transferCard');
                    let icon: JSX.Element;
                    switch (paymentInfo?.payment_details[0]?.bank_name) {
                        case 'Ренессанс Банк':
                            icon = <Icon component={() => RenCredit('', 20)}/>;
                            break;
                        case 'Т-Банк':
                            icon = <Icon component={() => Tinkoff('', 20)}/>;
                            break;
                        case 'Росбанк':
                            icon = <Icon component={() => Rosbank('', 20)}/>;
                            break;
                        case 'Райффайзенбанк':
                            icon = <Icon component={() => Raiffeisen('', 20)}/>;
                            break;
                        case 'МТС - Банк':
                            icon = <Icon component={() => MTS('', 20)}/>;
                            break;
                        case 'Почта Банк':
                            icon = <Icon component={() => PochtaBank('', 20)}/>;
                            break;
                        case 'Хоум Кредит Банк':
                            icon = <Icon component={() => HomeCreditBank('', 20)}/>;
                            break;
                        case 'Альфа - банк':
                            icon = <Icon component={() => AlfaBank('', 20)}/>;
                            break;
                        case 'Ак Барс Банк':
                            icon = <Icon component={() => AkBars('', 20)}/>;
                            break;
                        case 'Уралсиб Банк':
                            icon = <Icon component={() => UralSib('', 20)}/>;
                            break;
                        case 'Русский Стандарт':
                            icon = <Icon component={() => RSB('', 20)}/>;
                            break;
                        case 'БКС Банк':
                            icon = <Icon component={() => BCS('', 20)}/>;
                            break;
                        case 'Банк Санкт - Петербург':
                            icon = <Icon component={() => BSPD('', 20)}/>;
                            break;
                        case 'ОТП Банк':
                            icon = <Icon component={() => OTP('', 20)}/>;
                            break;
                        case 'Кредит Европа Банк(Россия)':
                            icon = <Icon component={() => CreditEurope('', 20)}/>;
                            break;
                        case 'Ситибанк':
                            icon = <Icon component={() => CitiBank('', 20)}/>;
                            break;
                        case 'Сбербанк':
                            icon = <Icon component={() => SberBank('', 20)}/>;
                            break;
                        case 'ВТБ':
                            icon = <Icon component={() => VTB('', 20)}/>;
                            break;
                        case 'Банк Точка':
                            icon = <Icon component={() => Tochka('', 20)}/>;
                            break;
                        case 'Банк Центр - инвест':
                            icon = <Icon component={() => CentrInvest('', 20)}/>;
                            break;
                        case 'Совкомбанк':
                            icon = <Icon component={() => SovcomBank('', 20)}/>;
                            break;
                        case 'Уральский Банк реконструкции и развития':
                            icon = <Icon component={() => UBRR('', 20)}/>;
                            break;
                        case 'Газпромбанк':
                            icon = <Icon component={() => GaspromBank('', 20)}/>;
                            break;
                        case 'Банк ФК Открытие':
                            icon = <Icon component={() => OpenBank('', 20)}/>;
                            break;
                        case 'ЮниКредит Банк':
                            icon = <Icon component={() => UniCreditBank('', 20)}/>;
                            break;
                        case 'YooMoney':
                            icon = <Icon component={() => YooMoney('', 20)}/>;
                            break;
                        case 'QIWI':
                            icon = <Icon component={() => QIWI('', 20)}/>;
                            break;
                        case 'Payeer':
                            icon = <Icon component={() => Payeer('#FBFBFF', 20)}/>;
                            break;
                        case 'AdvCash':
                            icon = <Icon component={() => AdvCache('', 20)}/>;
                            break;
                        default:
                            return {
                                icon: <Icon component={() => DefaultBank('#FBFBFF', 20)}/>,
                                text,
                                bankCard: paymentInfo.payment_details[0]
                            };
                    }
                    return {
                        icon,
                        text,
                        bankCard: paymentInfo.payment_details[0]
                    };
                }
                case 'bankTransferPayment': {
                    const text = translate('payments.transferCard');
                    let icon: JSX.Element;
                    switch (paymentInfo?.payment_details[0]?.bank_name) {
                        case 'Ренессанс Банк':
                            icon = <Icon component={() => RenCredit('', 20)}/>;
                            break;
                        case 'Т-Банк':
                            icon = <Icon component={() => Tinkoff('', 20)}/>;
                            break;
                        case 'Росбанк':
                            icon = <Icon component={() => Rosbank('', 20)}/>;
                            break;
                        case 'Райффайзенбанк':
                            icon = <Icon component={() => Raiffeisen('', 20)}/>;
                            break;
                        case 'МТС - Банк':
                            icon = <Icon component={() => MTS('', 20)}/>;
                            break;
                        case 'Почта Банк':
                            icon = <Icon component={() => PochtaBank('', 20)}/>;
                            break;
                        case 'Хоум Кредит Банк':
                            icon = <Icon component={() => HomeCreditBank('', 20)}/>;
                            break;
                        case 'Альфа - банк':
                            icon = <Icon component={() => AlfaBank('', 20)}/>;
                            break;
                        case 'Ак Барс Банк':
                            icon = <Icon component={() => AkBars('', 20)}/>;
                            break;
                        case 'Уралсиб Банк':
                            icon = <Icon component={() => UralSib('', 20)}/>;
                            break;
                        case 'Русский Стандарт':
                            icon = <Icon component={() => RSB('', 20)}/>;
                            break;
                        case 'БКС Банк':
                            icon = <Icon component={() => BCS('', 20)}/>;
                            break;
                        case 'Банк Санкт - Петербург':
                            icon = <Icon component={() => BSPD('', 20)}/>;
                            break;
                        case 'ОТП Банк':
                            icon = <Icon component={() => OTP('', 20)}/>;
                            break;
                        case 'Кредит Европа Банк(Россия)':
                            icon = <Icon component={() => CreditEurope('', 20)}/>;
                            break;
                        case 'Ситибанк':
                            icon = <Icon component={() => CitiBank('', 20)}/>;
                            break;
                        case 'Сбербанк':
                            icon = <Icon component={() => SberBank('', 20)}/>;
                            break;
                        case 'ВТБ':
                            icon = <Icon component={() => VTB('', 20)}/>;
                            break;
                        case 'Банк Точка':
                            icon = <Icon component={() => Tochka('', 20)}/>;
                            break;
                        case 'Банк Центр - инвест':
                            icon = <Icon component={() => CentrInvest('', 20)}/>;
                            break;
                        case 'Совкомбанк':
                            icon = <Icon component={() => SovcomBank('', 20)}/>;
                            break;
                        case 'Уральский Банк реконструкции и развития':
                            icon = <Icon component={() => UBRR('', 20)}/>;
                            break;
                        case 'Газпромбанк':
                            icon = <Icon component={() => GaspromBank('', 20)}/>;
                            break;
                        case 'Банк ФК Открытие':
                            icon = <Icon component={() => OpenBank('', 20)}/>;
                            break;
                        case 'ЮниКредит Банк':
                            icon = <Icon component={() => UniCreditBank('', 20)}/>;
                            break;
                        case 'YooMoney':
                            icon = <Icon component={() => YooMoney('', 20)}/>;
                            break;
                        case 'QIWI':
                            icon = <Icon component={() => QIWI('', 20)}/>;
                            break;
                        case 'Payeer':
                            icon = <Icon component={() => Payeer('#FBFBFF', 20)}/>;
                            break;
                        case 'AdvCash':
                            icon = <Icon component={() => AdvCache('', 20)}/>;
                            break;
                        default:
                            return {
                                icon: <Icon component={() => DefaultBank('#FBFBFF', 20)}/>,
                                text: '',
                                bankTransfer: paymentInfo?.payment_details[0]
                            };
                    }
                    return {
                        icon,
                        text,
                        bankTransfer: paymentInfo?.payment_details[0]
                    };
                }
                case 'webServicePayment': {
                    let icon: JSX.Element;
                    let text = '';
                    switch (paymentInfo?.payment_details[0]?.web_service_name) {
                        case 'YooMoney':
                            icon = <Icon component={() => YooMoney('', 20)}/>;
                            text = 'YooMoney'
                            break;
                        case 'QIWI':
                            icon = <Icon component={() => QIWI('', 20)}/>;
                            text = 'QIWI'
                            break;
                        case 'Payeer':
                            icon = <Icon component={() => Payeer('#FBFBFF', 20)}/>;
                            text = 'Payeer'
                            break;
                        case 'AdvCash':
                            icon = <Icon component={() => AdvCache('', 20)}/>;
                            text = 'AdvCash'
                            break;
                        default:
                            icon = <Icon component={() => DefaultBank('#FBFBFF', 20)}/>
                    }
                    return {
                        icon,
                        text: text,
                        webService: paymentInfo?.payment_details[0],
                    };
                }
                case 'mobileBalancePayment': {
                    return {
                        icon: <Icon component={() => MobileIcon('#FBFBFF', 20)}/>,
                        text: '',
                        phone: paymentInfo?.payment_details[0]?.phone,
                    };
                }
                case 'fastPaymentSystemPayment': {
                    return {
                        icon: <Icon component={() => FPS('#FBFBFF', 20)}/>,
                        text: 'Перевод по системе быстрых платежей',
                        sbp: paymentInfo?.payment_details[0]?.phone,
                    };
                }
            }
        }
        return {
            icon: <Icon component={() => DefaultBank('#FBFBFF', 20)}/>,
            text: '',
        };
    };

    return {
        paymentData: getData(),
    };
};
