import React, { useEffect, useState } from 'react';
import {useTimerState} from "./model/useTimerState";

interface ITimerProps {
  initSeconds?: number;
  digits?: number;
  styles?: object;
  handleFinish: () => void;
}

const Index: React.FC<ITimerProps> = ({
  styles,
  initSeconds = 60,
  digits = 4,
  handleFinish,
}) => {
  const {seconds,decrementSeconds,setSeconds} = useTimerState();
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (seconds > 0 && isActive) {
      setInterval(() => decrementSeconds(), 1000);
    } else if (isActive) {
      setIsActive(false);
      handleFinish();
    }
  }, [seconds, isActive]);
  useEffect(() => {
    setSeconds(initSeconds)
  },[initSeconds])


  const timerString = new Date(seconds * 1000)
    .toISOString()
    .substring(19 - digits - 1, 19);

  return <div style={styles}>{timerString}</div>;
};

export default Index;
