import { PropsWithChildren } from 'react'
import Styles from './index.module.css'

interface IChatContentProps extends PropsWithChildren {}

const ChatContent = (props: IChatContentProps): JSX.Element => {
    return (
        <div className={Styles.container}>
            {props.children}
        </div>
    )
}

export { ChatContent }