import React, { MouseEventHandler } from 'react';
import { Divider, Icons, Tooltip, Typography } from '@pankod/refine-antd';
import Button from '../../shared/components/ui/button';
import { ButtonsTypes } from '../../shared/components/ui/button/filledButton.p';
import { useTranslation } from 'react-i18next';
import { Button as AntdButton, Modal } from 'antd';
import styled from 'styled-components';
import { TOutputConfirmInfo } from './models';
import { copyText } from '../../shared/helpers/copyText';
import { CopyWallet } from '../../shared/components/ui/assets/svg/CopyWallet';

const { CopyOutlined } = Icons;

interface IOutputMainBloc {
  outputValues: TOutputConfirmInfo;
  onClickConfirm: () => void;
  onClickCancel: () => void;
}

const OutputMainBloc: React.FC<IOutputMainBloc> = ({
  outputValues,
  onClickCancel,
  onClickConfirm,
}) => {
  const { t } = useTranslation();

  const handleCopy: MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (outputValues.address) {
      copyText(outputValues.address);
    }
  };

  console.log(outputValues);

  const Footer = (
    <ButtonWrapper>
      <Button onClick={onClickConfirm} height={40} width="65%">
        {t('outputOrder.confirm')}
      </Button>
      <Button
        onClick={onClickCancel}
        type={ButtonsTypes.outlined}
        height={40}
        width="35%"
      >
        {t('outputOrder.cancel')}
      </Button>
    </ButtonWrapper>
  );

  return (
    <Modal
      open={true}
      title={t('outputOrder.withdrawConfirm')}
      closeIcon={null}
      closable={false}
      centered
      mask={false}
      footer={Footer}
    >
      <OutputTextWrapper>
        <OutputText>{t('outputOrder.cryptocurrency')}:</OutputText>
        <OutputText $primary>
          {`${outputValues.ccy_name}(${outputValues.ccy})`}
        </OutputText>
      </OutputTextWrapper>
      {outputValues.network && (
        <OutputTextWrapper>
          <OutputText>{t('outputOrder.network')}:</OutputText>
          <OutputText $primary>{outputValues.network}</OutputText>
        </OutputTextWrapper>
      )}
      <OutputTextWrapper>
        <OutputText>{t('outputOrder.outputAddress')}:</OutputText>
        <AddressWrapper>
          <OutputText $primary>{outputValues.address}</OutputText>
          <Tooltip
            trigger="click"
            title={t('wallet.tooltips.copied')}
            placement="bottom"
            overlayInnerStyle={{ width: 'auto' }}
          >
            <AntdButton
              icon={<CopyWallet />}
              type="ghost"
              size="small"
              onClick={handleCopy}
            />
          </Tooltip>
        </AddressWrapper>
      </OutputTextWrapper>
      <OutputTextWrapper>
        <OutputText>{t('outputOrder.withdrawalAmount')}:</OutputText>
        <OutputText $primary>
          {(+outputValues?.amount)?.toFixed(8)} {outputValues.ccy}
        </OutputText>
      </OutputTextWrapper>
      <OutputTextWrapper>
        <OutputText>{t('outputOrder.commission')}:</OutputText>
        <OutputText $primary>
          {outputValues.commission} {outputValues.ccy}
        </OutputText>
      </OutputTextWrapper>
      <Divider />
      <OutputTextWrapper>
        <OutputText style={{ fontSize: 16 }}>
          {t('wallet.coin.withdraw.resultSum')}
        </OutputText>
        <MoneyText>
          {(+outputValues?.amount)?.toFixed(8)} {outputValues.ccy}
        </MoneyText>
      </OutputTextWrapper>
    </Modal>
  );
};

const OutputTextWrapper = styled.div`
  margin-bottom: 16px;
`;

const OutputText = styled(Typography.Text)<{ $primary?: boolean }>`
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => (props.$primary ? '#FBFBFF' : '#767687')};
`;

const MoneyText = styled(Typography.Text)`
  font-size: 18px;
  font-weight: 70;
  color: #fbfbff;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const CopyAddrIcon = styled(CopyOutlined)`
  font-size: 12px !important;
  color: #767687 !important;
  transition: 0.2s;
  &:hover {
    color: white !important;
  }
`;

const AddressWrapper = styled.div`
  display: flex;
`;

export default OutputMainBloc;
