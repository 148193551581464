import { PdfFile } from '../../../../../../shared/components/ui/assets/svg/pdfFile';
import React from 'react';
import s from './styles.module.css';

interface IPdfFileBlock {
  name: string;
  size: number;
}

const PdfFileBlock = ({ name, size }: IPdfFileBlock) => {
  const conversionSize = +size * Math.pow(10, -6);
  return (
    <div className={s.pdfFileWrapper}>
      <div>
        <PdfFile />
      </div>
      <div className={s.pdfFileInfo}>
        <div>{name.length > 30 ? name.substring(0, 30) + '...' : name}</div>
        <div>
          {(conversionSize > 1
            ? conversionSize
            : conversionSize * 1000
          ).toFixed(1)}{' '}
          {+conversionSize > 1 ? 'MB' : 'KB'}
        </div>
      </div>
    </div>
  );
};

export { PdfFileBlock };
