import {Typography} from "@pankod/refine-antd"
import {useTranslate} from "@pankod/refine-core"
import Phone from '../../phone.svg';
import SBP from '../../SBP.svg';
import styled from "styled-components";

type userPaymentBuy = {
    title: string;
    payment_type: string;
    id: string;
    name: string;
    icon: string;
}
type TUserPaymentListBuy = userPaymentBuy[]

const UserPaymentListBuy = ({userPaymentListBuy}: { userPaymentListBuy: TUserPaymentListBuy }) => {
    const t = useTranslate();

    const getPaymentTypeTitle = (type: string): string => {
        switch (type) {
            case "bankCardPayment":
                return t('payments.types.banksCard')
            case "mobileBalancePayment":
                return t('payments.types.mobiles')
            case "bankTransferPayment":
                return t('p2p.chooseLimitsAndPaymentsType.paymentMethods.bankAccount')
            case "fastPaymentSystemPayment":
                return t('payments.types.sbp')
            default:
                return t('payments.types.webService');
        }
    }

    const getPaymentTypeName = (item: userPaymentBuy) =>
        item.payment_type === 'mobileBalancePayment'
            ? '+7 (ХХХ) ХХХ-ХХ-ХХ'
            : item.payment_type === 'fastPaymentSystemPayment'
                ? '+7 (ХХХ) ХХХ-ХХ-ХХ'
                : item.name

    return (
        <>{userPaymentListBuy.map((item) => (
            <div
                key={item.id}
                className="payment-buy-card-fourth"
            >
                <CardInfoWrapper>
                    <div className="payment-buy-card-info">
                        {item.payment_type === 'mobileBalancePayment' ? (
                            <PaymentIco
                                alt={`mobile_icon`}
                                src={Phone}
                            />
                        ) : item.payment_type === 'fastPaymentSystemPayment' ? (
                            <PaymentIco
                                alt={`sbp_icon`}
                                src={SBP}
                            />
                        ) : (
                            <PaymentIco
                                alt={item.title}
                                src={`data:image/svg+xml;base64,${item.icon}`}
                            />
                        )}
                    </div>
                    <div className="payment-buy-card-text-third">
                        <TitleStyled>
                            {getPaymentTypeName(item)}
                        </TitleStyled>
                        <LightText className={'dotted-line'}>
              <span>
                 {getPaymentTypeTitle(item.payment_type)}
              </span>
                        </LightText>
                    </div>
                </CardInfoWrapper>
            </div>
        ))}
        </>
    )
}

export default UserPaymentListBuy;

const TitleStyled = styled(Typography.Text)`
    font-size: 14px;
`;

const CardInfoWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`
const PaymentIco = styled.img`
    width: 24px;
    height: 24px;
`
const LightText = styled(Typography)`
    font-size: 12px;
    line-height: 14.52px;
    color: rgba(118, 118, 135, 1);
`
