import {ImgParam} from "../../../../interfaces";

export const SmallLogo = ({color, size}: ImgParam) => (
  <svg width={size ? size : 24} height={size ? size : 24} viewBox="0 0 24 24" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path id="Vector" fillRule="evenodd" clipRule="evenodd"
          d="M13.1218 1.46445C12.5869 1.46445 12.1533 1.8986 12.1533 2.43413V9.46259C12.1533 10.6164 12.6124 11.7226 13.4291 12.5366L15.7623 14.8622C16.5727 15.6699 17.9019 15.6026 18.6268 14.717L20.2538 12.7294H20.2336L22.0015 10.5765C22.3158 10.2193 22.5061 9.75059 22.5061 9.23728V3.49215C22.5061 2.37227 21.5993 1.46445 20.4806 1.46445H13.1218ZM9.76436 12.7294C10.92 12.7294 12.0282 13.189 12.8455 14.0071L15.1015 16.2653C15.8058 16.9703 15.8614 18.0952 15.23 18.8663L15.1366 18.9804C13.2971 21.2273 10.5485 22.53 7.64693 22.53H3.48847C2.36974 22.53 1.4629 21.6221 1.4629 20.5023V13.6991C1.4629 13.1636 1.89649 12.7294 2.43133 12.7294H9.76436ZM23.1976 11.4437L18.8925 16.7025C18.5639 17.104 18.5928 17.6895 18.9594 18.0565L23.345 22.4467C23.6306 22.7326 23.6307 23.1963 23.345 23.4823C23.0594 23.7682 22.5964 23.7682 22.3108 23.4824L18.1354 19.3023C17.7319 18.8983 17.0683 18.9311 16.7065 19.3729L15.827 20.4467C13.9875 22.6925 11.2395 23.9944 8.33876 23.9944H3.48847C1.5618 23.9944 0 22.4309 0 20.5023V12.7294V11.265V3.49215C0 1.56348 1.5618 0 3.48847 0H10.6904H12.1533H20.4806C22.4072 0 23.9691 1.56348 23.9691 3.49215V9.23728C23.9691 10.0712 23.6772 10.8368 23.1897 11.4373L23.1976 11.4437ZM9.47986 11.265C10.1484 11.265 10.6904 10.7223 10.6904 10.0529V2.43413C10.6904 1.8986 10.2568 1.46445 9.72197 1.46445H3.48847C2.36974 1.46445 1.4629 2.37227 1.4629 3.49215V10.2953C1.4629 10.8308 1.89649 11.265 2.43136 11.265H9.47986Z"
          fill={color ? color : "#FAFAFC"}/>
  </svg>

)
