import React, {FC, useEffect, useState} from 'react';
import {BasicModal, BasicModalProps} from '../basicModal';
import {Button, ConfigProvider, Typography} from '@pankod/refine-antd';
import {useTranslation} from 'react-i18next';
import {FilterSearchIcon} from '../../../icons';
import {Input} from 'antd';
import STYLES from './index.module.css';
import {styled} from 'styled-components';
import {IPaymentsTypeBuyModified} from '../../../../../pages/p2p/dashboard/interface';
import './index.css';
import Phone from '../../../../../features/paymentComponents/modalContent/secondStep/Service/image/phone.svg';
import SBP from '../../../../../features/paymentComponents/modalContent/secondStep/Service/image/sbp.svg';

interface IAddPaymentTypeModalProps
    extends Omit<BasicModalProps, 'children' | 'footer' | 'width' | 'onCancel'> {
    userPaymentListBuy: Array<IPaymentsTypeBuyModified>;
    paymentTypesBuyData: Array<IPaymentsTypeBuyModified>;

    handleClickAddNew(): void;

    handleClickAddChosenPaymentTypesBuy(
        payments: Array<IPaymentsTypeBuyModified>
    ): void;

    onCancel(): void;
}

const MAX_PAYMENTS_ARRAY_LENGTH = 5;

const AddPaymentTypeBuy: FC<IAddPaymentTypeModalProps> = ({
                                                              handleClickAddNew,
                                                              handleClickAddChosenPaymentTypesBuy,
                                                              userPaymentListBuy,
                                                              onCancel,
                                                              paymentTypesBuyData,
                                                              ...modalProps
                                                          }) => {
    const {t} = useTranslation();

    const [chosenPayments, setChosenPayments] = useState<
        Array<IPaymentsTypeBuyModified>
    >([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [displayPanelBanks, setDisplayPanelBanks] = useState('');
    const [displayPanelWebService, setDisplayPanelWebService] = useState('');
    const [displayPanelMobile, setDisplayPanelMobile] = useState('');
    const [displayPanelBankTransfer, setDisplayPanelBankTransfer] = useState('');
    const [displayPanelFastPayment, setDisplayPanelFastPayment] = useState('');

    const banksSorted = paymentTypesBuyData[4]?.items?.sort(function (a, b) {
        return a.name?.toLowerCase().localeCompare(b.name?.toLowerCase());
    });
    const webServiceSorted = paymentTypesBuyData[1]?.items?.sort(function (a, b) {
        return a.name?.toLowerCase().localeCompare(b.name?.toLowerCase());
    });
    const banksTransferSorted = paymentTypesBuyData[6]?.items?.sort(function (
        a,
        b
    ) {
        return a.name?.toLowerCase().localeCompare(b.name?.toLowerCase());
    });

    const mobileArr = [
        {
            id: 1001,
            uniq_id: paymentTypesBuyData[0]?.uniq_id,
            name: t('payments.types.mobiles'),
            icon: 'test',
            payment_type: paymentTypesBuyData[0]?.payment_type,
        },
    ];
    const sbpArr = [
        {
            id: 1002,
            uniq_id: paymentTypesBuyData[5]?.uniq_id,
            name: t('payments.types.sbp'),
            icon: 'test',
            payment_type: paymentTypesBuyData[5]?.payment_type,
        },
    ];

    const onClose = () => {
        setChosenPayments(userPaymentListBuy);
        onCancel();
        setSearchValue('');
        handleDisplayBlocks();
    };

    useEffect(() => {
        if (chosenPayments.length === 0) {
            setChosenPayments(userPaymentListBuy);
        } else {
            setChosenPayments((state) =>
                state.filter((item) => userPaymentListBuy.includes(item) && item)
            );
        }
    }, [userPaymentListBuy]);

    const handleSearchDataBanks = (value: string) => {
        if (value !== '') {
            const match = banksSorted?.find((bank) =>
                bank.name?.toLowerCase().includes(value.toLowerCase())
            );
            setDisplayPanelBanks(match ? '' : 'none');
        } else {
            setDisplayPanelBanks('');
        }
    };

    const handleSearchDataWebService = (value: string) => {
        if (value !== '') {
            const match = webServiceSorted?.find((webService) =>
                webService.name?.toLowerCase().includes(value.toLowerCase())
            );
            setDisplayPanelWebService(match ? '' : 'none');
        } else {
            setDisplayPanelWebService('');
        }
    };

    const handleSearchDataMobile = (value: string) => {
        if (value !== '') {
            const match = mobileArr?.find((mobile) =>
                mobile.name?.toLowerCase().includes(value.toLowerCase())
            );
            setDisplayPanelMobile(match ? '' : 'none');
        } else {
            setDisplayPanelMobile('');
        }
    };

    const handleSearchDataBanksTransfer = (value: string) => {
        if (value !== '') {
            const match = banksTransferSorted?.find((bankTransfer) =>
                bankTransfer.name?.toLowerCase().includes(value.toLowerCase())
            );
            setDisplayPanelBankTransfer(match ? '' : 'none');
        } else {
            setDisplayPanelBankTransfer('');
        }
    };

    const handleSearchDataFastPayment = (value: string) => {
        if (value !== '') {
            const match = sbpArr?.find((fastPayment) =>
                fastPayment.name?.toLowerCase().includes(value.toLowerCase())
            );
            setDisplayPanelFastPayment(match ? '' : 'none');
        } else {
            setDisplayPanelFastPayment('');
        }
    };

    const handleDisplayBlocks = () => {
        setDisplayPanelBanks('');
        setDisplayPanelWebService('');
        setDisplayPanelMobile('');
        setDisplayPanelBankTransfer('');
        setDisplayPanelFastPayment('');
    };

    const handleSearch = (value: string) => {
        setSearchValue(value);
        handleSearchDataBanks(searchValue);
        handleSearchDataWebService(searchValue);
        handleSearchDataMobile(searchValue);
        handleSearchDataBanksTransfer(searchValue);
        handleSearchDataFastPayment(searchValue);
    };

    const filteredCardsBank = [
        {
            label: `${t('payments.bankCard')}`,
            value: 'bankCardPayment',
            content: (
                <div style={{height: '100%', display: displayPanelBanks}}>
                    <div style={{marginBottom: '16px'}}>
                        <Typography.Text
                            style={{lineHeight: '16.94px', marginBottom: '16px'}}
                        >
                            {t('p2p.chooseLimitsAndPaymentsType.bankCards')}
                        </Typography.Text>
                    </div>
                    <>
                        {banksSorted
                            ?.filter((bank) =>
                                bank.name?.toLowerCase().includes(searchValue.toLowerCase())
                            )
                            .map((bank) => (
                                <div
                                    key={bank.uniq_id}
                                    className={`p2p-ad-payment-modal-buy-payment-card
                      ${
                                        chosenPayments
                                            .map((i) => i.uniq_id)
                                            .includes(bank.uniq_id)
                                            ? 'selected-item'
                                            : ''
                                    }
                      ${
                                        chosenPayments.length === MAX_PAYMENTS_ARRAY_LENGTH &&
                                        !chosenPayments
                                            .map((i) => i.uniq_id)
                                            .includes(bank.uniq_id)
                                            ? 'disabled-item'
                                            : ''
                                    }`}
                                    onClick={() => {
                                        if (
                                            chosenPayments.length === MAX_PAYMENTS_ARRAY_LENGTH &&
                                            !chosenPayments
                                                .map((i) => i.uniq_id)
                                                .includes(bank.uniq_id)
                                        ) {
                                            return;
                                        }
                                        setChosenPayments((state) =>
                                            state.map((i) => i.uniq_id).includes(bank.uniq_id)
                                                ? state.filter((p) => p.uniq_id !== bank.uniq_id)
                                                : [...state, bank]
                                        );
                                    }}
                                >
                                    <StyledImg
                                        alt={`bank-${bank.id}`}
                                        src={`data:image/svg+xml;base64,${bank.icon}`}
                                    />
                                    {t(`banks.${bank?.name}`)}
                                </div>
                            ))}
                        <div className={'modal-payment-line'}></div>
                    </>
                </div>
            ),
        },
    ];

    const filteredCardsWebService = [
        {
            label: `${t('payments.electronServices')}`,
            value: 'webServicePayment',
            content: (
                <div style={{height: '100%', display: displayPanelWebService}}>
                    <div style={{marginBottom: '16px', marginTop: '16px'}}>
                        <Typography.Text style={{lineHeight: '16.94px'}}>
                            {t('payments.electronServices')}
                        </Typography.Text>
                    </div>
                    <>
                        {webServiceSorted
                            ?.filter((webService) =>
                                webService.name
                                    ?.toLowerCase()
                                    .includes(searchValue.toLowerCase())
                            )
                            .map((webService) => (
                                <div
                                    key={webService.uniq_id}
                                    className={`p2p-ad-payment-modal-buy-payment-card
                      ${
                                        chosenPayments
                                            .map((i) => i.uniq_id)
                                            .includes(webService.uniq_id)
                                            ? 'selected-item'
                                            : ''
                                    }
                      ${
                                        chosenPayments.length === MAX_PAYMENTS_ARRAY_LENGTH &&
                                        !chosenPayments
                                            .map((i) => i.uniq_id)
                                            .includes(webService.uniq_id)
                                            ? 'disabled-item'
                                            : ''
                                    }`}
                                    onClick={() => {
                                        if (
                                            chosenPayments.length === MAX_PAYMENTS_ARRAY_LENGTH &&
                                            !chosenPayments
                                                .map((i) => i.uniq_id)
                                                .includes(webService.uniq_id)
                                        ) {
                                            return;
                                        }
                                        setChosenPayments((state) =>
                                            state.map((i) => i.uniq_id).includes(webService.uniq_id)
                                                ? state.filter((p) => p.uniq_id !== webService.uniq_id)
                                                : [...state, webService]
                                        );
                                    }}
                                >
                                    <img
                                        alt={`webService-${webService.id}`}
                                        src={`data:image/svg+xml;base64,${webService.icon}`}
                                        style={{
                                            width: '24px',
                                            height: '24px',
                                        }}
                                    />
                                    {webService.name}
                                </div>
                            ))}
                        <div className={'modal-payment-line'}></div>
                    </>
                </div>
            ),
        },
    ];

    const filteredCardsMobile = [
        {
            label: `${t('payments.types.mobiles')}`,
            value: 'mobileBalancePayment',
            content: (
                <div style={{height: '100%', display: displayPanelMobile}}>
                    <>
                        {mobileArr
                            ?.filter((mobile) =>
                                mobile.name?.toLowerCase().includes(searchValue.toLowerCase())
                            )
                            .map((mobile) => (
                                <div
                                    key={`mobile-${paymentTypesBuyData[0]?.uniq_id}`}
                                    className={`p2p-ad-payment-modal-buy-payment-card
                      ${
                                        chosenPayments
                                            .map((i) => i.uniq_id)
                                            .includes(paymentTypesBuyData[0]?.uniq_id)
                                            ? 'selected-item'
                                            : ''
                                    }
                      ${
                                        chosenPayments.length === MAX_PAYMENTS_ARRAY_LENGTH &&
                                        !chosenPayments
                                            .map((i) => i.uniq_id)
                                            .includes(paymentTypesBuyData[0]?.uniq_id)
                                            ? 'disabled-item'
                                            : ''
                                    }`}
                                    onClick={() => {
                                        if (
                                            chosenPayments.length === MAX_PAYMENTS_ARRAY_LENGTH &&
                                            !chosenPayments
                                                .map((i) => i.uniq_id)
                                                .includes(paymentTypesBuyData[0]?.uniq_id)
                                        ) {
                                            return;
                                        }
                                        setChosenPayments((state) =>
                                            state
                                                .map((i) => i.uniq_id)
                                                .includes(paymentTypesBuyData[0]?.uniq_id)
                                                ? state.filter(
                                                    (p) => p.uniq_id !== paymentTypesBuyData[0]?.uniq_id
                                                )
                                                : [...state, paymentTypesBuyData[0]]
                                        );
                                    }}
                                >
                                    <StyledImg alt={`mobile-${mobile.id}`} src={Phone}/>
                                    {t('payments.types.mobiles')}
                                </div>
                            ))}
                        <div className={'modal-payment-line'}></div>
                    </>
                </div>
            ),
        },
    ];

    const filteredCardsBankTransfer = [
        {
            label: `${t('payments.types.banks')}`,
            value: 'bankTransferPayment',
            content: (
                <div style={{height: '100%', display: displayPanelBankTransfer}}>
                    <div style={{marginBottom: '16px', marginTop: '16px'}}>
                        <Typography.Text style={{lineHeight: '16.94px'}}>
                            {t('payments.types.banks')}
                        </Typography.Text>
                    </div>
                    <>
                        {banksTransferSorted
                            ?.filter((bankTransfer) =>
                                bankTransfer.name
                                    ?.toLowerCase()
                                    .includes(searchValue.toLowerCase())
                            )
                            .map((bankTransfer) => (
                                <div
                                    key={bankTransfer.uniq_id}
                                    className={`p2p-ad-payment-modal-buy-payment-card
                      ${
                                        chosenPayments
                                            .map((i) => i.uniq_id)
                                            .includes(bankTransfer.uniq_id)
                                            ? 'selected-item'
                                            : ''
                                    }
                      ${
                                        chosenPayments.length === MAX_PAYMENTS_ARRAY_LENGTH &&
                                        !chosenPayments
                                            .map((i) => i.uniq_id)
                                            .includes(bankTransfer.uniq_id)
                                            ? 'disabled-item'
                                            : ''
                                    }`}
                                    onClick={() => {
                                        if (
                                            chosenPayments.length === MAX_PAYMENTS_ARRAY_LENGTH &&
                                            !chosenPayments
                                                .map((i) => i.uniq_id)
                                                .includes(bankTransfer.uniq_id)
                                        ) {
                                            return;
                                        }
                                        setChosenPayments((state) =>
                                            state.map((i) => i.uniq_id).includes(bankTransfer.uniq_id)
                                                ? state.filter(
                                                    (p) => p.uniq_id !== bankTransfer.uniq_id
                                                )
                                                : [...state, bankTransfer]
                                        );
                                    }}
                                >
                                    <StyledImg
                                        alt={`webService-${bankTransfer.id}`}
                                        src={`data:image/svg+xml;base64,${bankTransfer.icon}`}
                                    />
                                    {t(`banks.${bankTransfer.name}`)}
                                </div>
                            ))}
                        <div className={'modal-payment-line'}></div>
                    </>
                </div>
            ),
        },
    ];

    const filteredCardsFastPaymentSystem = [
        {
            label: `${t('payments.types.sbp')}`,
            value: 'fastPaymentSystemPayment',
            content: (
                <div style={{height: '100%', display: displayPanelFastPayment}}>
                    <>
                        {sbpArr
                            ?.filter((fastPayment) =>
                                fastPayment.name
                                    ?.toLowerCase()
                                    .includes(searchValue.toLowerCase())
                            )
                            .map((fastPayment) => (
                                <div
                                    key={`fastPayment-${paymentTypesBuyData[5]?.uniq_id}`}
                                    className={`p2p-ad-payment-modal-buy-payment-card
                      ${
                                        chosenPayments
                                            .map((i) => i.uniq_id)
                                            .includes(paymentTypesBuyData[5]?.uniq_id)
                                            ? 'selected-item'
                                            : ''
                                    }
                      ${
                                        chosenPayments.length === MAX_PAYMENTS_ARRAY_LENGTH &&
                                        !chosenPayments
                                            .map((i) => i.uniq_id)
                                            .includes(paymentTypesBuyData[5]?.uniq_id)
                                            ? 'disabled-item'
                                            : ''
                                    }`}
                                    onClick={() => {
                                        if (
                                            chosenPayments.length === MAX_PAYMENTS_ARRAY_LENGTH &&
                                            !chosenPayments
                                                .map((i) => i.uniq_id)
                                                .includes(paymentTypesBuyData[5]?.uniq_id)
                                        ) {
                                            return;
                                        }
                                        setChosenPayments((state) =>
                                            state
                                                .map((i) => i.uniq_id)
                                                .includes(paymentTypesBuyData[5]?.uniq_id)
                                                ? state.filter(
                                                    (p) => p.uniq_id !== paymentTypesBuyData[5]?.uniq_id
                                                )
                                                : [...state, paymentTypesBuyData[5]]
                                        );
                                    }}
                                >
                                    <StyledImg
                                        alt={`sbp-${fastPayment.id}`}
                                        src={SBP}
                                        // src={`data:image/svg+xml;base64,${fastPayment.icon}`}
                                    />
                                    {t('payments.types.sbp')}
                                </div>
                            ))}
                        <div className={'modal-payment-line-end'}></div>
                    </>
                </div>
            ),
        },
    ];

    const filteredCards = [
        ...filteredCardsBank,
        ...filteredCardsWebService,
        ...filteredCardsMobile,
        ...filteredCardsBankTransfer,
        ...filteredCardsFastPaymentSystem,
    ];

    return (
        <StyledModal
            width={560}
            {...modalProps}
            onCancel={onClose}
            title={
                modalProps.title +
                ` (${chosenPayments.length}` +
                `/${MAX_PAYMENTS_ARRAY_LENGTH})`
            }
            footer={
                <div className="custom-modal-footer">
                    <Button
                        type="primary"
                        disabled={
                            chosenPayments.length > MAX_PAYMENTS_ARRAY_LENGTH ||
                            paymentTypesBuyData.length === 0
                        }
                        onClick={() => {
                            handleClickAddChosenPaymentTypesBuy(chosenPayments);
                            onCancel();
                            setSearchValue('');
                            handleDisplayBlocks();
                        }}
                    >
                        {t('buttons.add')}
                    </Button>
                    <Button
                        type="dashed"
                        onClick={() => {
                            onCancel();
                            setSearchValue('');
                            handleDisplayBlocks();
                        }}
                    >
                        {t('buttons.cancel2')}
                    </Button>
                </div>
            }
        >
            <div style={{height: '521px'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <ConfigProvider
                            theme={{
                                components: {
                                    Input: {
                                        colorPrimaryHover: '#FBFBFF',
                                        colorPrimaryActive: '#FBFBFF',
                                        colorIconHover: '#FBFBFF',
                                        colorIcon: '#767687',
                                    },
                                },
                            }}
                        >
                            <Input.Search
                                value={searchValue}
                                className={STYLES.custom_search}
                                prefix={<FilterSearchIcon/>}
                                placeholder={
                                    t('p2p.chooseLimitsAndPaymentsType.inputSearch') ?? ''
                                }
                                onSearch={handleSearch}
                                allowClear={false}
                                onChange={(e) => handleSearch(e.target.value)}
                                style={{marginBottom: '8px'}}
                            />
                        </ConfigProvider>
                        <Typography style={{marginBottom: '24px', color: '#767687'}}>
                            {t('p2p.chooseLimitsAndPaymentsType.chooseFivePaymentMethod')}
                        </Typography>

                        <div
                            style={{
                                maxHeight: '450px',
                                overflowY: 'auto',
                            }}
                        >
                            {displayPanelBanks === 'none' &&
                                displayPanelWebService === 'none' &&
                                displayPanelMobile === 'none' &&
                                displayPanelBankTransfer === 'none' &&
                                displayPanelFastPayment === 'none' && (
                                    <div>{t('p2p.chooseLimitsAndPaymentsType.nothingFound')}</div>
                                )}
                            {filteredCards.map((card) => {
                                return (
                                    <React.Fragment key={card.value}>
                                        {card.content}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </StyledModal>
    );
};

export default AddPaymentTypeBuy;

const StyledModal = styled(BasicModal)`
    .ant-modal-title {
        margin-bottom: 16px !important;
    }

    .ant-modal-footer {
        margin-top: 8px;
    }
`;

const StyledImg = styled.img`
    width: 24px;
    height: 24px;
`;
