import React from 'react';
import {Skeleton} from 'antd';
import styled from "styled-components";

const SkeletonMyDeals = () => {
    return (
        <SkeletonMyDealsStyled>
            <Skeleton.Button
                block
                style={{height: '48px', marginBottom: '24px'}}
                active
            />
            <Skeleton.Button
                block
                style={{height: '54px', marginBottom: '24px'}}
                active
            />
            <Skeleton.Button
                block
                style={{height: '40px', marginBottom: '16px'}}
                active
            />
            <Skeleton.Button
                block
                style={{height: '40px', marginBottom: '24px'}}
                active
            />
            {Array(5)
                .fill(false)
                .map((_, index) => (
                    <Skeleton.Button
                        block
                        style={{height: '80px', marginBottom: '24px'}}
                        key={index}
                        active
                    />
                ))}
        </SkeletonMyDealsStyled>
    );
};

const SkeletonMyDealsStyled = styled.div`
    background: rgb(20, 20, 31)
`;

export default SkeletonMyDeals;
