import React, { FC } from 'react';
import { Layout } from 'shared/components/layout';
import CryptoCurrencyExchange from './cryptoCurrencyExchange/cryptoCurrencyExchange';

export const Login: FC = () => {
  return (
    <Layout>
      <CryptoCurrencyExchange />
    </Layout>
  );
};
