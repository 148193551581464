import React from 'react';
import {Typography} from "antd";

interface IDotItem {
  text: string,
  width?: number
  marginLeft?: number
}

interface IAdaptiveDotStepper {
  items: IDotItem[]
  currentStep: number
}

const AdaptiveDotStepper: React.FC<IAdaptiveDotStepper> = ({
                                                             items,
                                                             currentStep
                                                           }) => {
  return (
    <>
      <div
        className={'flex-column'}
        style={{
          gap: 12,
          width: '100%'
        }}>
        <div className={'flex-row'}>
          {
            items.map((item, index) => {
              return (
                <div
                  key={item.text + index}
                  className={'flex-row'}
                  style={{
                    width: index === items.length - 1 ? 'fit-content ' : '100%'
                  }}
                >
                  <div
                    className={'flex-column'}
                    style={{
                      width: index === items.length - 1 ? 'fit-content ' : '100%'
                    }}
                  >
                    <div
                      className={'flex-row align-items-center gap-4'}
                      style={{
                        paddingRight: index === items.length - 1 ? 0 : 4,
                        position: "relative"
                      }}
                    >
                      <div
                        className={'flex-row align-items-center justify-content-center'}
                        style={{
                          position: "relative"
                        }}
                      >
                        {
                          currentStep === index && <div
                            style={{
                              position: "absolute",
                              borderRadius: 40,
                              opacity: 0.5,
                              background: 'var(--border-colors-vertical-stroke, linear-gradient(360deg, #6E27F8 0%, #20D9C0 100%))',
                              filter: 'blur(18.5px)',
                              width: 48,
                              height: 48,
                            }}/>
                        }
                        <div
                          style={{
                            boxSizing: "border-box",
                            borderRadius: '50%',
                            background: currentStep < index ? '#767687' : 'var(--theme-gradient-tetriary, linear-gradient(270deg, #6E27F8 0%, #20D9C0 100%))',
                            width: 12,
                            height: 12
                          }}
                        />
                      </div>
                      {
                        index !== items.length - 1 && <div
                          style={{
                            height: 1,
                            background: '#C3C3CE',
                            width: '100%'
                          }}
                        />
                      }
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className={'flex-row justify-content-space-between'}>
          {
            items.map((item, index) => {
              return (
                <div
                  key={item.text + index}
                  className={'flex-row'}
                  style={{
                    marginLeft: item.marginLeft,
                    width: item.width
                  }}>
                  <Typography.Text
                    style={{
                      color: currentStep < index ? '#767687': '#FBFBFF',
                      fontSize: 10,
                      fontWeight: 700,
                      width: item.width,
                      textAlign: index === items.length - 1 ? 'right' : index !== 0 ? 'center' : 'left'
                    }}>
                    {
                      item.text
                    }
                  </Typography.Text>
                </div>
              )
            })
          }
        </div>
      </div>
    </>
  );
};

export default AdaptiveDotStepper;
