import {Form, FormInstance, Typography} from '@pankod/refine-antd';
import {FC, Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {FullScreenLoader} from '../../../../shared/components/full-screen-loader';
import styled from 'styled-components';
import {AdditionalParamsInputCard} from './additionalParamsInputCard';

interface ISetAdditionalParamsProps {
    form: FormInstance;
    deal: string;
    handleChangeDeal: (value: string) => void;
    response: string;
    handleChangeResponse: (value: string) => void;
}

const SetEditAdditionalParams: FC<ISetAdditionalParamsProps> = ({
                                                                    form,
                                                                    handleChangeDeal,
                                                                    handleChangeResponse,
                                                                    deal,
                                                                    response,
                                                                }) => {
    const {t} = useTranslation();
    const dealTermsWatch = Form.useWatch('deal-terms', form);
    const autoResponseWatch = Form.useWatch('auto-response', form);
    return (
        <Suspense fallback={<FullScreenLoader/>}>
            <InputCards>
                <AdditionalParamsInputCard
                    extra={<LightText>{t('p2p.setAdditionallyParams.inputs.notRequiredInput')}</LightText>}
                    initValue={deal}
                    placeholder={t('p2p.setAdditionallyParams.inputs.enterConditionsDeals')}
                    name='deal-terms'
                    handleChange={handleChangeDeal}
                    watch={dealTermsWatch}
                    title={t('p2p.setAdditionallyParams.conditionsDeals')}
                />
                <AdditionalParamsInputCard
                    extra={<LightText>{t('p2p.setAdditionallyParams.inputs.notRequiredInput')}</LightText>}
                    initValue={response}
                    placeholder={t('p2p.setAdditionallyParams.inputs.enterAutoAnswer')}
                    name='auto-response'
                    handleChange={handleChangeResponse}
                    watch={autoResponseWatch}
                    title={t('p2p.setAdditionallyParams.inputs.autoAnswer')}

                />
            </InputCards>
        </Suspense>
    );
};

export default SetEditAdditionalParams;

const InputCards = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 24px;
    flex-shrink: 0;
    flex-wrap: nowrap;
`
const LightText = styled(Typography.Text)`
    font-weight: 400;
    font-size: 12px;
`
