import { PropsWithChildren } from 'react';
import Styles from './index.module.css';
import { ChatProvider } from '../context';
import { IChatRepository, IChatSocket } from 'features/chat-module/interfaces';

interface IChatContainerProps extends PropsWithChildren {}

const ChatContainer = (props: IChatContainerProps) => {
  return (
    <ChatProvider socket={{} as IChatSocket} repository={{} as IChatRepository}>
      <div className={Styles.container}>{props.children}</div>
    </ChatProvider>
  );
};

export { ChatContainer };
