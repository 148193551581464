import { Icon, Typography } from '@pankod/refine-antd';
import { FC } from 'react';
import { ReactComponent as CloseIcon } from '../../../shared/components/ui/assets/svg/modal-close-icon.svg';
import { useTranslation } from 'react-i18next';
import { useParams } from '@pankod/refine-react-router-v6';
import styled from 'styled-components';
interface IPageHeadingProps {
    onClose?(): void;
}

const EditPageHeading: FC<IPageHeadingProps> = ({ onClose }) => {
    const params = useParams();
    const { t } = useTranslation();
    return (
        <HeadWrapper>
            <Title>
                {params.type === 'sell' ? t('p2p.editSellOrder') : t('p2p.editBuyOrder')}
            </Title>
            <Icon
                onClick={onClose}
                component={CloseIcon}
                style={{ fontSize: '14px', cursor: 'pointer' }}
            />
        </HeadWrapper>
    );
};
const HeadWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`
const Title = styled(Typography)`
    font-size: 40px;
    font-weight: 700;
`
export default EditPageHeading;
