import React, {useEffect, useState} from 'react';

import {Col, Popover, Row, Typography} from '@pankod/refine-antd';
import {IGetActualOrder} from '../../../shared/interfaces/spot';
import {ChevronDown, CloseSymbol} from '../../../shared/components/icons';
import {CrudFilter} from '@pankod/refine-core';
import OrdersTableActualRow from './ordersTableActualRow';
import {Dropdown} from 'antd';
import * as _ from 'lodash';
import Button from '../../../shared/components/ui/button';
import {ButtonsTypes} from '../../../shared/components/ui/button/filledButton.p';
import VisibilityObserver from '../../../shared/hooks/on-visible/observer';
import {API_URL} from '../../../packages/keycloak-client/constants';
import {axios} from '../../../shared/exios';
import {useLocation} from '@pankod/refine-react-router-v6';
import {CentrifugeClient} from '../../../packages/centrifuge-client/centrifuge-client';
import {useTranslation} from 'react-i18next';
import {useCookiesCustom} from '../../../shared/hooks/useCookieCustom';
import {useAuthState} from "../../../useAuthState";
import SpotTableLogin from "../spotTableLogin";

export interface IFilterActual {
    type?: CrudFilter;
    market?: CrudFilter;
}

const OrdersTableActual: React.FC<{
    tab: 'actual' | 'history';
    setOpenCount(value: number): void;
}> = ({tab, setOpenCount}) => {
    const {cookie} = useCookiesCustom()
    const uid = useAuthState((state) => state?.userAPI?.uid) || cookie['uid'];
    const {t} = useTranslation();
    const [total, setTotal] = useState(0);
    const [actuals, setActuals] = useState<IGetActualOrder[]>([]);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState<IFilterActual>({});
    const [openType, setOpenType] = useState(false);
    const [openMarker, setOpenMarker] = useState(false);
    const [openPop, setOpenPop] = useState(false);
    const [activePair, setActivePair] = useState(
        () => localStorage['activePair']
    );
    const location = useLocation();
    // TODO: Заменить useGetIdentity на useAuthState переменную с профилем

    const handleChangeType = (value: string) => {
        setOpenType(false);
        if (value === 'all') {
            setFilter(_.omit(filter, 'type'));
        } else {
            setFilter({
                ...filter,
                type: {
                    field: 'type',
                    operator: 'eq',
                    value,
                },
            });
        }
    };
    const handleChangeMarket = (value: string | boolean) => {
        setOpenMarker(false);
        if (value === 'all') {
            setFilter(_.omit(filter, 'market'));
        } else {
            setFilter({
                ...filter,
                market: {
                    field: 'market',
                    operator: 'eq',
                    value: !!value,
                },
            });
        }
    };

    const handleCancelAll = async () => {
        await axios.post(
            `${API_URL}/${activePair
                .replace('-', '')
                .toLowerCase()}-spot/api/orders/cancel-all`,
            {
                status: 'canceled',
            }
        );
        if (!!cookie['token']) {
            await getOrderActual();
        }
    };

    const handleNextPage = (visibility: boolean) => {
        if (visibility && page < total) {
            setPage((prevState) => prevState + 1);
        }
    };

    function subToUser() {
        const subs = CentrifugeClient.subscriptions();

        if (!!subs) {
            if (!subs[`user.${uid}`]) {
                CentrifugeClient.subscribeToChannel(
                    `user.${uid}`
                );
            }

            const userUidSubscription = CentrifugeClient.getSubscription(
                `user.${uid}`
            );

            userUidSubscription
                ?.on('publication', (ctx) => {
                    const response = ctx.data as {
                        action: string;
                        data?: Partial<IGetActualOrder>;
                    };
                    if (response.data?.id && response.action === 'updateSpotOrder') {
                        const index = _.findIndex(actuals, {id: response.data.id});
                        if (index !== -1) {
                            console.log('userUidSubscription response.data', response.data);
                            // TODO: Подключить сокет
                            // setActuals(
                            // @ts-ignore
                            // response.data
                            // actuals.splice(index, 1, {
                            //   ...actuals[index],
                            //   ...response.data,
                            // })
                            // );

                            // [
                            //   // @ts-ignore
                            //   ...new map(arr.map((item) => [item['price'], item])).values(),
                            // ]
                        }
                    }
                })
                .subscribe();

            CentrifugeClient.connect();
        }
    }

    async function getOrderActual() {
        if (!!cookie['token']) {
            try {
                const res = await axios.post(
                    `${API_URL}/${localStorage['activePair']
                        .replace('-', '')
                        ?.toLowerCase()}-spot/api/actual-orders/search?limit=${15}&page=${page}`,
                    {filters: [...Object.values(filter)]}, {
                        headers: {
                            // @ts-ignore
                            Authorization: cookie['token'] ? `Bearer ${cookie['token']}` : '',
                        },
                    }
                );
                setTotal(res?.data?.meta?.total);
                setOpenCount(res?.data?.meta?.total);
                if (page === 1) {
                    setActuals([...res?.data?.data]);
                } else setActuals((prevState) => [...prevState, ...res?.data?.data]);
            } catch (e) {
                console.log('getOrderActual error', e);
            }
        } else console.log('getOrderActual not auth');
    }

    useEffect(() => {
        subToUser();
        getOrderActual().then();

        const updateDataInterval = setInterval(() => {
            getOrderActual().then();
        }, 35000);

        return () => {
            clearInterval(updateDataInterval);
        };
    }, []);

    useEffect(() => {
        setActivePair(() => localStorage['activePair']);
        getOrderActual().then();
    }, [location.pathname]);

    useEffect(() => {
        getOrderActual().then();
    }, [tab, filter, page]);

    return (
        <div>
            <Row
                justify={'space-between'}
                align={'middle'}
                style={{marginBottom: '16px'}}
            >
                <Col span={3}>
                    <Typography.Text style={{fontSize: 12}}>
                        {t('spotOrders.columns.header.date')}
                    </Typography.Text>
                </Col>
                <Col span={3}>
                    <Typography.Text>
                        {t(`spotOrders.columns.header.pair`)}
                    </Typography.Text>
                </Col>
                <Col span={2}>
                    <Dropdown
                        overlayStyle={{width: '120px'}}
                        onOpenChange={setOpenType}
                        menu={{
                            items: [
                                {
                                    key: 'all',
                                    label: t(`spotOrders.columns.header.type.all`),
                                    onClick: (info: any) => handleChangeType(info.key),
                                },
                                {
                                    key: 'buy',
                                    label: t(`spotOrders.columns.header.type.buy`),
                                    onClick: (info: any) => handleChangeType(info.key),
                                },
                                {
                                    key: 'sell',
                                    label: t(`spotOrders.columns.header.type.sell`),
                                    onClick: (info: any) => handleChangeType(info.key),
                                },
                            ],
                            selectable: true,
                            defaultSelectedKeys: ['all'],
                        }}
                    >
                        <div className={'flex-row align-items-center gap-8'}>
                            <Typography.Text style={{fontSize: 12}}>
                                {t(
                                    `spotOrders.columns.header.type.${
                                        filter.type?.value ? filter.type?.value : 'type'
                                    }`
                                )}
                            </Typography.Text>
                            <div
                                className={'cursor-pointer flex align-items-center'}
                                style={{rotate: openType ? '180deg' : '0deg'}}
                            >
                                {ChevronDown('#FBFBFF', 12)}
                            </div>
                        </div>
                    </Dropdown>
                </Col>
                <Col span={2}>
                    <Dropdown
                        onOpenChange={setOpenMarker}
                        menu={{
                            items: [
                                {
                                    key: 'all',
                                    label: t(`spotOrders.columns.header.market.all`),
                                    onClick: (info: any) => handleChangeMarket(info.key),
                                },
                                {
                                    key: 1,
                                    label: t(`spotOrders.columns.header.market.true`),
                                    onClick: (info: any) => handleChangeMarket(true),
                                },
                                {
                                    key: 0,
                                    label: t(`spotOrders.columns.header.market.false`),
                                    onClick: (info: any) => handleChangeMarket(false),
                                },
                            ],
                            selectable: true,
                            defaultSelectedKeys: ['all'],
                        }}
                    >
                        <div className={'flex-row align-items-center gap-8'}>
                            <Typography.Text style={{fontSize: 12}}>
                                {t(
                                    `spotOrders.columns.header.market.${
                                        filter.market?.value ?? 'market'
                                    }`
                                )}
                            </Typography.Text>
                            <div
                                className={'cursor-pointer flex align-items-center'}
                                style={{rotate: openMarker ? '180deg' : '0deg'}}
                            >
                                {ChevronDown('#FBFBFF', 12)}
                            </div>
                        </div>
                    </Dropdown>
                </Col>
                <Col span={3}>
                    <Typography.Text>
                        {t(`spotOrders.columns.header.price`)}
                    </Typography.Text>
                </Col>
                <Col span={2}>
                    <Typography.Text>
                        {t(`spotOrders.columns.header.percent`)}
                    </Typography.Text>
                </Col>
                <Col span={3}>
                    <Typography.Text>
                        {t(`spotOrders.columns.header.count`)}
                    </Typography.Text>
                </Col>
                <Col span={3}>
                    <Typography.Text>
                        {t(`spotOrders.columns.header.all`)}
                    </Typography.Text>
                </Col>
                <Col span={3}>
                    <Popover
                        open={openPop}
                        onOpenChange={setOpenPop}
                        content={
                            <div style={{width: '200px'}}>
                                <div
                                    className={
                                        'flex-row justify-content-space-between align-items-flex-start gap-16'
                                    }
                                >
                                    <div>
                                        <svg
                                            width="16"
                                            height="16"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                                                fill="#E8E264"
                                            />
                                            <path
                                                d="M7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11Z"
                                                fill="#E8E264"
                                            />
                                            <path
                                                d="M7.09796 4.99504C7.04469 4.46228 7.46305 4 7.99846 4C8.53387 4 8.95223 4.46228 8.89896 4.99504L8.54821 8.50248C8.51997 8.78492 8.2823 9 7.99846 9C7.71461 9 7.47695 8.78492 7.44871 8.50248L7.09796 4.99504Z"
                                                fill="#E8E264"
                                            />
                                        </svg>
                                    </div>
                                    <Typography.Text>
                                        {t(`spotOrders.columns.header.cancelText`)}
                                    </Typography.Text>
                                    <div onClick={() => setOpenPop(false)}>
                                        {CloseSymbol('#FBFBFF', 7)}
                                    </div>
                                </div>
                                <div
                                    className={'flex-row justify-content-flex-end gap-8'}
                                    style={{marginTop: '16px'}}
                                >
                                    <Button
                                        type={ButtonsTypes.outlined}
                                        onClick={() => setOpenPop(false)}
                                        width={'80px'}
                                        height={'32px'}
                                    >
                                        {t(`spotOrders.columns.header.cancelNo`)}
                                    </Button>
                                    <Button
                                        onClick={handleCancelAll}
                                        width={'80px'}
                                        height={'32px'}
                                    >
                                        {t(`spotOrders.columns.header.cancelYes`)}
                                    </Button>
                                </div>
                            </div>
                        }
                        trigger={'click'}
                        color={'#2B2B36'}
                    >
                        <div
                            className={
                                'flex-row align-items-center gap-8 cursor-pointer order-actual-cancel-all'
                            }
                        >
                            <Typography.Text style={{fontSize: 12}}>
                                {t(`spotOrders.columns.header.cancelAll`)}
                            </Typography.Text>
                            {CloseSymbol('#767687', 7)}
                        </div>
                    </Popover>
                </Col>
            </Row>
            <div
                style={{
                    height: '180.316px',
                    overflow: 'overlay',
                }}
            >
                {!!cookie['token'] &&
                    actuals.map((actual, index) =>
                        index === actuals.length - 1 ? (
                            <VisibilityObserver
                                onChangeVisibility={handleNextPage}
                                options={{rootMargin: '150px', threshold: 1}}
                                key={`order-table-${actual.id}`}
                            >
                                <OrdersTableActualRow
                                    actual={actual}
                                    getActuals={() => getOrderActual()}
                                />
                            </VisibilityObserver>
                        ) : (
                            <OrdersTableActualRow
                                getActuals={() => getOrderActual()}
                                actual={actual}
                                key={`order-table-${actual.id}`}
                            />
                        )
                    )}

                {!cookie['token'] && <SpotTableLogin/>}
            </div>
        </div>
    );
};

export default OrdersTableActual;
