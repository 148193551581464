import React, {CSSProperties, useState} from 'react';
import Transfer from './image/arrow-down-up.svg';
import Phone from './image/phone.svg';
import Cash from './image/monetization_on.svg';
import SBP from './image/SBP.svg';
import Delete from './image/x.svg';
import {Payments, PayService,} from '../../../../pages/p2p/settings/payment/payment';
import {GetIcon} from '../../modalContent/secondStep/Card/getIcon';
import {useCustom} from '@pankod/refine-core';
import {BanksResponse} from '../../../../pages/p2p/settings/payment/types';
import {useTranslation} from 'react-i18next';
import {Popconfirm} from '@pankod/refine-antd';
import './styles.css';
import {ReactComponent as Warning} from './image/warning.svg';
import {IPaymentsType} from '../../../../pages/p2p/dashboard/interface';
import {PaymentTypeEnum} from '../../../p2p/createOrder/types';

interface Props {
    payment: PayService[];
    type: keyof Payments;
    deletePayment: (id: string, type: keyof Payments) => void;
    iconsData?: IPaymentsType[];
}

const Card = ({payment, type, deletePayment, iconsData = []}: Props) => {
    const {t} = useTranslation();
    const {data: dataTransfer} = useCustom<BanksResponse>({
        url: `/p2p/api/banks?limit=25`,
        method: 'get',
    });
    const [hover, setHover] = useState('');
    const getImg = (secondType: string) => {
        if (type === 'cards') {
            return GetIcon(parseInt(secondType, 10), iconsData);
        }
        if (type === 'services') {
            return `data:image/svg+xml;base64,${
                iconsData
                    .find(
                        (item) => item.payment_type === PaymentTypeEnum.webServicePayment
                    )
                    ?.items?.find((payment) =>
                    [secondType, 'YooMoney'].includes(payment.name)
                )?.icon
            }`;
        }
        if (type === 'banks') return Transfer;
        if (type === 'cashes') return Cash;
        if (type === 'sbp') return SBP;
        if (type === 'mobiles') return Phone;
        return Cash;
    };

    const getTitle = (secondType: string) => {
        if (type === 'cards') {
            return getBanks(secondType).name ? t(`banks.${getBanks(secondType).name}`) : '';
        }
        if (type === 'banks') {
            return (
                <>
                    <div
                        style={{fontSize: '18px', color: '#FBFBFF', fontWeight: '700'}}
                    >
                        {getBanks(secondType).name ? t(`banks.${getBanks(secondType).name}`) : ''}
                    </div>
                    <div
                        style={{fontSize: '12px', color: '#FBFBFF', fontWeight: '400'}}
                    >
                        {t(`payments.types.${type}`)}
                    </div>
                </>
            );
        }
        if (type === 'services') {
            switch (secondType) {
                case 'ЮMoney':
                case 'YooMoney':
                    return 'ЮMoney';
                case 'QIWI':
                    return 'Qiwi';
                case 'Payeer':
                    return 'Payeer';
                case 'AdvCash':
                    return 'AdvCash';
                default:
                    return '';
            }
        }

        return t(`payments.types.${type}`);
    };

    if (type === 'banks') console.log(payment);

    const parseNum = (item: string) => {
        if (type === 'cards' || type === 'banks') {
            let value = item?.split('');
            value.forEach((item, index) => {
                if (item === ' ') {
                    value.splice(index, 1);
                }
            });
            const result = value.map((item, index) => {
                if ((index + 1) % 4 === 0) {
                    item = item + ' ';
                }
                return item;
            });
            return result.join('');
        }
        if (type === 'sbp' || type === 'mobiles') {
            let result = item?.split('');
            const offset = result.length - 12;
            result.splice(2 + offset, 0, ' (');
            result.splice(6 + offset, 0, ') ');
            result.splice(10 + offset, 0, '-');
            result.splice(13 + offset, 0, '-');
            return result.join('');
        }
        return item;
    };

    const getBanks = (id: string) => {
        return (
            dataTransfer?.data.data.find((item) => item.id === parseInt(id, 10)) || {
                id: '',
                name: '',
            }
        );
    };
    return (
        <>
            {payment.map((item) => (
                <div
                    onMouseEnter={() => setHover(item.id)}
                    onMouseLeave={() => setHover('')}
                    key={item.id + type}
                    style={{marginBottom: 20}}
                    className={'border-gradient1'}
                >
                    <div style={containerStyle}>
                        <div style={headerStyle}>
                            <img
                                alt={'logo'}
                                style={{width: 24, height: 24}}
                                src={getImg(item.service ? item.service : 'null')}
                            />
                            <div style={containerHeaderStyle}>
                                <div style={headerTextStyle}>
                                    {getTitle(item.service ? item.service : 'null')}
                                </div>
                                <div
                                    style={{
                                        ...containerIconsStyle,
                                        opacity: item.id === hover ? 1 : 0,
                                    }}
                                >
                                    {/*<button disabled={!hover} style={iconBtnStyle}>*/}
                                    {/*  <img alt={"edit"} src={Edit}/>*/}
                                    {/*</button>*/}
                                    <Popconfirm
                                        onConfirm={() => deletePayment(item.id, type)}
                                        cancelText={t('payments.popConfirm.No')}
                                        okText={t('payments.popConfirm.Yes')}
                                        icon={<Warning/>}
                                        okButtonProps={{
                                            size: 'large',
                                            style: {paddingInline: 32},
                                        }}
                                        cancelButtonProps={{
                                            size: 'large',
                                            style: {
                                                paddingInline: 32,
                                                background: 'none',
                                                color: 'white',
                                                borderColor: '#7B59FD',
                                            },
                                        }}
                                        title={t('payments.popConfirm.description')}
                                    >
                                        <button disabled={!hover} style={iconBtnStyle}>
                                            <img alt={'delete'} src={Delete}/>
                                        </button>
                                    </Popconfirm>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{...textCardStyle, opacity: type === 'cashes' ? 0 : 1}}
                        >
                            {parseNum(item.num ? item.num : '')}
                        </div>
                        {item?.comment && item?.comment !== 'null' && (
                            <div style={descCardStyle}>{item.comment}</div>
                        )}
                    </div>
                </div>
            ))}
        </>
    );
};

const containerHeaderStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
};

const containerIconsStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    transition: '0.2s',
};

const iconBtnStyle: CSSProperties = {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
};

const descCardStyle: CSSProperties = {
    display: 'flex',
    fontFamily: 'Inter,sans-serif',
    fontStyle: 'normal',
    fontSize: 14,
    fontWeight: 500,
    color: '#767687',
};

const textCardStyle: CSSProperties = {
    fontFamily: 'Inter,sans-serif',
    fontStyle: 'normal',
    fontSize: 24,
    fontWeight: 700,
    color: '#FBFBFF',
    marginBottom: 16,
};

const containerStyle: CSSProperties = {
    height: '100%',
    padding: 32,
};

const headerStyle: CSSProperties = {
    display: 'flex',
    gap: 10,
    marginBottom: 53,
};

const headerTextStyle: CSSProperties = {
    fontFamily: 'Inter,sans-serif',
    fontStyle: 'normal',
    fontSize: 18,
    fontWeight: 700,
    color: '#FBFBFF',
};

export default Card;
