import { BitcoinIcon, CoinIcon, EthereumIcon } from '../components/icons';
import { IGetCoinData } from '../hooks/use-coin-account';
// import { TetherCoinIcon } from '../components/ui/assets/svg/coinsIcon';
import { TetherIcon } from '../components/icons';
import BTC from './image/BTC.svg';
import USDT from './image/USDT.svg';
import MATIC from './image/MATIC.svg';
import BNB from './image/BNB.svg';
import LTC from './image/LTC.svg';
import USDC from './image/USDC.svg';
import ETH from './image/ETH.svg';

interface IGetCoinInfoByName extends Omit<IGetCoinData, 'amount'> {
  coinFullName: string;
}

export const getCoinInfoByName = (
  coin: string,
  isMobile?: boolean
): IGetCoinInfoByName => {
  const icon = (() => {
    if (!!isMobile) {
      switch (coin.toLowerCase()) {
        case 'btc':
          return BTC;
        case 'usdt':
          return USDT;
        case 'matic':
          return MATIC;
        case 'eth':
          return ETH;
        case 'usdc':
          return USDC;
        case 'bnb':
          return BNB;
        case 'ltc':
          return LTC;
        default:
          return BTC;
      }
    } else
      switch (coin.toLowerCase()) {
        case 'btc':
          return <BitcoinIcon />;
        case 'eth':
          return <EthereumIcon />;
        case 'usdt':
          return <TetherIcon />;
        default:
          return <CoinIcon />;
      }
  })();

  const key = coin.toUpperCase();

  const coinFullName = (() => {
    switch (coin.toLowerCase()) {
      case 'btc':
        return `Bitcoin`;
      case 'eth':
        return `Ethereum`;
      case 'usdt':
        return `Tether`;
      case 'matic':
        return `Polygon`;
      case 'usdc':
        return `USD Coin`;
      case 'ltc':
        return `Litecoin`;
      case 'bnb':
        return 'Binance Coin';
      default:
        return key;
    }
  })();

  const name = (() => {
    switch (coin.toLowerCase()) {
      case 'btc':
        return `Bitcoin / ${key}`;
      case 'eth':
        return `Ethereum / ${key}`;
      default:
        return key;
    }
  })();

  return {
    icon,
    name,
    key,
    coinFullName,
  };
};
