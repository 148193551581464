import React from 'react';
import { Typography } from 'antd';

interface ISpotHeadingTwoLines {
  firstLine: React.ReactNode;
  secondLine: React.ReactNode;
  stylesFirstLine?: React.CSSProperties;
  stylesSecondLine?: React.CSSProperties;
  gap?: number;
  className?: string
}

const SpotHeadingTwoLineItem: React.FC<ISpotHeadingTwoLines> = ({
  firstLine,
  secondLine,
  stylesSecondLine,
  stylesFirstLine,
  gap = 4,
  className
}) => {
  return (
    <>
      <div className={'flex-column ' + className} style={{ gap }}>
        <Typography.Text style={stylesFirstLine}>{firstLine}</Typography.Text>
        <Typography.Text style={stylesSecondLine}>{secondLine}</Typography.Text>
      </div>
    </>
  );
};

export { SpotHeadingTwoLineItem };
