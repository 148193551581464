import {ExclamationCircleFilled} from "@ant-design/icons";
import {Icon, Typography} from "@pankod/refine-antd";
import {useTranslate} from "@pankod/refine-core";
import {IGetListData} from "pages/p2p/myDeals/cards/myTrades.p"
import DotStepper from "shared/components/ui/stepper/dotStepper";
import styled from "styled-components";

type TStepper = {
  currentOrder: IGetListData;
  currentStep: number;
}

export const DotStepperBlock = ({currentOrder, currentStep}: TStepper) => {
  const t = useTranslate();
  const dotItems = [
    {
      firstRow: t('p2p.orderDeals.dotStepper.firstDot.firstRow'),
      secondRow: t('p2p.orderDeals.dotStepper.firstDot.secondRow'),
    },
    {
      firstRow: t('p2p.orderDeals.dotStepper.secondDot.firstRow'),
      secondRow: t('p2p.orderDeals.dotStepper.secondDot.secondRow'),
      marginLeft: -25,
    },
    {
      firstRow: t('p2p.orderDeals.dotStepper.thirdDot.firstRow'),
      secondRow: t('p2p.orderDeals.dotStepper.thirdDot.secondRow'),
      marginLeft: -55,
    },
    {
      firstRow: t('p2p.orderDeals.dotStepper.forthDot.firstRow'),
      secondRow: t('p2p.orderDeals.dotStepper.forthDot.secondRow'),
      marginLeft: -60,
    },
  ]
  return (
    <>
      {currentOrder.status === 'appealed' ? (
        <Wrapper>
          <Icon component={() => <Exclamation/>}/>
          <Text>
            {t('p2p.orderDeals.appellationOpen')}
          </Text>
        </Wrapper>
      ) : (
        <DotStepper
          current={currentStep}
          dotItems={dotItems}
        />
      )}
    </>
  )
}

const Text = styled(Typography.Text)`
  line-height: 20px;
`
const Exclamation = styled(ExclamationCircleFilled)`
  font-size: 16px;
  color: #E8E264;
`
const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 8px;
`
