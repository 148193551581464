import {Progress, Row} from "@pankod/refine-antd";
import {BaseRecord, useTranslate} from "@pankod/refine-core";
import {ExchangePair} from "./pair";
import {styled} from "styled-components";
import {Comission} from "./customItemContainer";
import {SimpleTextContainer} from "./simpleTextContainer";

type ContentProps = {
  record: BaseRecord,
  data: BaseRecord,
  comissionData?: BaseRecord,
  regionName?: string,
  reactionTime?: string | number
}

export const AdContent = ({record, data, comissionData, regionName, reactionTime = '30'}: ContentProps) => {
  return (
    <Row>
      <LeftPart
        reactionTime={reactionTime}
        regionName={regionName}
        record={record}
        data={data}
        comissionData={comissionData}
      />
      <RightPart data={data} record={record}/>
    </Row>
  )
}

const LeftPart = ({record, data, comissionData, regionName, reactionTime}: ContentProps) => {
  const t = useTranslate();
  return (
    <LeftPartContainer>
      <ExPairWrapper>
        <ExchangePair ccy={record.ccy} fiat={record.fiat}/>
      </ExPairWrapper>
      <SimpleTextContainer
        align="center"
        width={170}
        greyText={t('announcements.params.priceCoin')}
        condition={!!data.dataSource}
        whiteText={<>1 {record.ccy} = {record.price} {record.fiat}</>}
      />
      <SimpleTextContainer
        align="flex-end"
        width={112}
        greyText={t('announcements.params.priceType')}
        condition={!!data.dataSource}
        whiteText={<>{t(`announcements.params.priceTypes.${record.price_type}`)}</>}
      />
      <ItemWrapper align="flex-start">
        <Comission record={record} comissionData={comissionData} data={data}/>
      </ItemWrapper>
      <SimpleTextContainer
        align="center"
        width={170}
        greyText={t('announcements.params.timePayment')}
        condition={!!data.dataSource}
        whiteText={<>{reactionTime}{' '}{t('announcements.params.min')}</>}
      />
      <SimpleTextContainer
        align="flex-end"
        width={112}
        greyText={t('announcements.countryPayment')}
        condition={!!data.dataSource && !!regionName}
        whiteText={t(`p2p.regions.${regionName && regionName.trim()}`)}
      />
    </LeftPartContainer>
  )
}

const RightPart = ({record, data}: ContentProps) => {
  const t = useTranslate();
  const rec = {
    totalSum: Number(record.total_sum).toFixed(8),
    availableSum: Number(record.sum_left).toFixed(8),
    sumLeft: Number(record.total_sum - record.sum_left).toFixed(8),
    limits: `${+record.min_sum_limit} - ${+record.max_sum_limit}`,
  }
  const progress = {
    percent: (+record.total_sum - +record.sum_left) / (+record.total_sum / 100),
    stroke: {
      direction: '80.91deg',
      from: '#088473',
      to: '#12BFA8',
    },
  }
  return (
    <RightPartContainer>
      <ContentBetween>
        <SimpleTextContainer
          greyText={t('announcements.totalSum')}
          condition={!!data.dataSource}
          whiteText={<>Σ {rec.totalSum} {record.ccy}</>}
        />
        <SimpleTextContainer
          greyText={t('announcements.limits')}
          condition={!!data.dataSource}
          whiteText={<>{rec.limits} {record.fiat}</>}
        />
      </ContentBetween>
      <ProgressWrapper>
        <Progress
          percent={progress.percent}
          trailColor={'#474755'}
          strokeColor={progress.stroke}
          showInfo={false}
        />
      </ProgressWrapper>
      <ContentBetween>
        <SimpleTextContainer
          greyText={t('announcements.sumLeft')}
          condition={!!data.dataSource}
          whiteText={<>{rec.sumLeft} {record.ccy}</>}
        />
        <SimpleTextContainer
          greyText={t('announcements.available')}
          condition={!!data.dataSource && !!record.sum_left && !!record.ccy}
          whiteText={<>{rec.availableSum} {record.ccy}</>}
        />
      </ContentBetween>
    </RightPartContainer>
  )
}

const LeftPartContainer = styled.div`
  display: grid;
  width: 55%;
  row-gap: 37px;
  column-gap: 52px;
  padding: 24px;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  border-bottom: 1px solid #474755;
`
const RightPartContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  column-gap: unset;
  padding: 24px;
  border-left: 1px solid #474755;
  border-bottom: 1px solid #474755;
  border-sizing: padding-box;
`
const ExPairWrapper = styled.div`
  display: flex;
`
const ItemWrapper = styled.div<{ align: string, width?: number }>`
  display: flex;
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.align};
`
const ContentBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
const ProgressWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 24px;
`
