import { useCookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';

export const configProd: CookieSetOptions = {
  path: '/',
  domain: '.edenex.com',
  secure: false,
  httpOnly: false,
  sameSite: 'lax'
};

export const configLocal: CookieSetOptions = {
  path: '/',
  domain: 'localhost',
  secure: false,
  httpOnly: false,
  sameSite: 'lax'
};

export const configDev: CookieSetOptions = {
  path: '/',
  domain: '.extract-sweet.ru',
  secure: false,
  httpOnly: false,
  sameSite: 'lax'
};

export const defaultkeysCookies = [
  'refreshTokenTimestamp',
  'currentServerTime',
  'remainingTime',
  'oidc.user',
  'inviteToken',
  'email',
  'uid',
  'token',
  'refresh_token',
  'isExitUser',
  'fromPortal',
  'fromFinms',
  'fromEdenex',
  'currentPointId',
  'partnerPointId',
  'favorites',
  'toLogin'
];

export const useCookiesCustom = (keysCookies?: string[]) => {
  const [cookie, setCookie, removeCookie] = useCookies(keysCookies ?? defaultkeysCookies);

  const setCookieCustom = (key: string, value: any, config?: CookieSetOptions) => {
    setCookie(key, value, config ?? configDev);
    setCookie(key, value, config ?? configProd);
    setCookie(key, value, config ?? configLocal);
  };

  const removeCookieCustom = (key: string, config?: CookieSetOptions) => {
    removeCookie(key, config ?? configDev);
    removeCookie(key, config ?? configProd);
    removeCookie(key, config ?? configLocal);
  };

  return {
    cookie,
    setCookie: setCookieCustom,
    removeCookie: removeCookieCustom
  };
};
