import { PropsWithChildren } from 'react';
import Styles from '../index.module.css';
import { Button, Typography } from '@pankod/refine-antd';
import { CloseIcon } from 'shared/components/ui/assets/svg/close';
import styled from 'styled-components';

interface IChatMessageAlertProps extends PropsWithChildren {
  header: string;
}

const ChatMessageAlert = (props: IChatMessageAlertProps): JSX.Element => {
  return (
    <ChatMessageStyled
      className={Styles.message__container}
      style={{ justifyContent: 'center' }}
    >
      <div className={Styles.message_alert}>
        <div className={Styles.message_alert__header}>
          <Typography.Text>{props.header}</Typography.Text>
        </div>
        <div className={Styles.message_alert__content}>
          <Typography.Text>{props.children}</Typography.Text>
        </div>
      </div>
    </ChatMessageStyled>
  );
};

const ChatMessageStyled = styled.div`
  //position: absolute;
  //top: 20px;
  //left: 50%;
  //z-index: 2;
  //transform: translateX(-50%);
`;

export { ChatMessageAlert };
