import { Typography } from "antd";
import { ReactNode } from "react";
import styled from "styled-components"

type TText = {
    width?: number;
    greyText: string | ReactNode;
    whiteText?: string | ReactNode;
    align?: string;
    condition?: boolean;
}

export const SimpleTextContainer = ({greyText, whiteText, width, align, condition = true} : TText) => {
    return (
        <ItemWrapper align={align ?? 'flex-start'}>
            <ItemContainer width={width}>
                <GreyText>
                    {greyText}
                </GreyText>
                {condition && <WhiteText>
                    {whiteText}
                </WhiteText>}
            </ItemContainer>
        </ItemWrapper>
    )
}

const ItemWrapper = styled.div<{ align: string, width?: number }>`
    display: flex;
    align-items: ${(props) => props.align};
    justify-content: ${(props) => props.align};
`
const ItemContainer = styled.div<{ width?: number }>`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    width: ${(props) => props.width}px;
`
const GreyText = styled(Typography.Text)`
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
color: #767687;
`
const WhiteText = styled(Typography.Text)`
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
color: #FBFBFF
`