import React, {Dispatch, FC, SetStateAction, useState} from 'react';
import './styles.css';
import {
    FieldData,
    ICryptoPair,
    ICurrencyOption,
    IExchangeContent,
} from '../../../features/cryptocurrencyExchange/cryptoCurrencyExchange.p';
import {useTranslate} from '@pankod/refine-core';
import Button from '../../../shared/components/ui/button';
import {ICryptoCurrencyConstraintItem} from '../../../widgets/order/types';
import FirstStep from './firstStep';
import SecondStep from './secondStep';
import {ChoiceCurrency} from './Card/choiceCurrency';

interface PropsExchange {
    exchangeData: IExchangeContent;
    changePrice: (input: string, output: string) => void;
    currentPair: ICryptoPair;
    minimumExchangeAmount: number;
    email: string | undefined;
    transferData: FieldData[];
    onFormChange: (value: FieldData[]) => void;
    onChangeStart: () => void;
    isOrderCreateLoading: boolean;
    listCrypto: ICurrencyOption[];
    listTarget: ICurrencyOption[];
    setExchangeData: Dispatch<SetStateAction<IExchangeContent>>;
    currencyConstraintList?: ICryptoCurrencyConstraintItem[];
    network?: {
        id: string;
        description: string;
        name: string;
        network: string;
    }[];
}

const CryptoCurrencyExchangeMobile: FC<PropsExchange> = ({
                                                             exchangeData,
                                                             changePrice,
                                                             currentPair,
                                                             minimumExchangeAmount,
                                                             email,
                                                             transferData,
                                                             onChangeStart,
                                                             onFormChange,
                                                             isOrderCreateLoading,
                                                             listCrypto,
                                                             listTarget,
                                                             setExchangeData,
                                                             currencyConstraintList = [],
                                                             network = [],
                                                         }) => {
    const translate = useTranslate();
    const [stepState, setStepState] = useState(1);

    const [openChoice, setOpenChoice] = useState(false);
    const [type, setType] = useState<'send' | 'get'>();
    const handleOpenChoice = (value?: boolean) => {
        if (value !== undefined) {
            setOpenChoice(value);
        } else setOpenChoice(!openChoice);
    };

    if (openChoice) {
        return (
            <ChoiceCurrency
                handleOpenChoice={handleOpenChoice}
                type={type!}
                list={type! === 'send' ? listCrypto : listTarget}
                setExchangeData={setExchangeData}
                currencyConstraintList={currencyConstraintList}
                network={network}
            />
        );
    }
    return (
        <div className={'container'}>
            {/*<span className={'title'}>Обмен криптовалют</span>*/}
            {stepState === 1 ? (
                <FirstStep
                    exchangeData={exchangeData}
                    changePrice={changePrice}
                    currentPair={currentPair}
                    minimumExchangeAmount={minimumExchangeAmount}
                    handleOpenChoice={handleOpenChoice}
                    setType={setType}
                />
            ) : (
                <SecondStep
                    onBackStep={() => setStepState(1)}
                    onChangeStart={onChangeStart}
                    onFormChange={onFormChange}
                    transferData={transferData}
                    currentPair={currentPair}
                    email={email}
                    isOrderCreateLoading={isOrderCreateLoading}
                    exchangeData={exchangeData}
                />
            )}
            {stepState === 1 && (
                <div className={'container-button'}>
                    <Button
                        style={{flex: 1, border: '1px solid #2B2B36'}}
                        onClick={() => {
                            setStepState(1);
                        }}
                        disabled={stepState === 1}
                    >
                        {`${translate('buttons.back')}`}
                    </Button>
                    <Button
                        style={{flex: 2}}
                        onClick={() => {
                            setStepState(2);
                        }}
                    >
                        {`${translate('buttons.next')}`}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default CryptoCurrencyExchangeMobile;
