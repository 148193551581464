import { Select } from '@pankod/refine-antd';
import { useEffect, useState } from 'react';
import { useTranslate } from '@pankod/refine-core';

interface ISelectAppealReasonProps {
  list: { key: string; placeholder: string }[];
  value?: '';
  onChange?: (value: string) => void;
}

const SelectAppealReason = (props: ISelectAppealReasonProps) => {
  const [state, setState] = useState<string>(
    props?.value ?? props?.list[0]?.key
  );

  useEffect(() => {
    if (props.onChange) props.onChange(props?.list[0]?.key);
  }, []);

  const handleOnChange = (item: string) => {
    if (props.onChange) props.onChange(item);
    setState(item);
  };
  const translate = useTranslate();
  return (
    <Select value={state} onChange={handleOnChange}>
      {props.list.map(({ key, placeholder }) => (
        <Select.Option value={key}>{translate(placeholder)}</Select.Option>
      ))}
    </Select>
  );
};

export { SelectAppealReason };
