import React, {FC, useEffect, useMemo, useState} from 'react';
import {Cascader, CascaderProps} from '@pankod/refine-antd';
import {CollapseArrow, SearchIcon} from '../../../icons';
import '../index.css';

interface Option {
    value: string;
    label: string;
    children?: Option[];
}

type Props = CascaderProps & {
    // options: Option[];
};

const CustomCascader: FC<Props> = ({style, ...props}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const handleOpen = (open: boolean) => {
        setIsOpen(open);
    };
    const handleOnEnter = () => {
        setIsHover(true);
    };

    const handleOnLeave = () => {
        setIsHover(false);
    };
    const arrow = useMemo(
        () =>
            isOpen ? (
                <SearchIcon/>
            ) : (
                <div style={{rotate: isOpen ? '0deg' : '180deg'}}>
                    <CollapseArrow color={isHover ? '#FBFBFF' : '#767687'}/>
                </div>
            ),
        [isOpen, isHover]
    );

    useEffect(() => {
        // setProps(props);
    }, [props]);

    return (
        <Cascader
            onDropdownVisibleChange={handleOpen}
            style={{width: '100%', ...style}}
            suffixIcon={arrow}
            className={'cascader-custom'}
            onMouseEnter={handleOnEnter}
            onMouseLeave={handleOnLeave}
            allowClear={false}
            {...props}
        />
    );
};

export default CustomCascader;
