import { FC } from 'react';
import { Button, ButtonProps } from '@pankod/refine-antd';
import { ReactComponent as TradeExchangeIcon } from '../../assets/svg/tradeImg.svg';
import './index.css';
import styled from 'styled-components';

export const ExchangeTradeButton: FC<ButtonProps> = (props) => {
  return (
    <ButtonStyled
      {...props}
      className="reverse-exchange-btn"
      icon={<TradeExchangeIcon />}
    />
  );
};

const ButtonStyled = styled(Button)`
  &:hover,&:disabled,&:not(:disabled):hover {
    background: none;
  }
  &:disabled {
    background: none;
  }
  &:not(:disabled):hover  svg {
    filter: drop-shadow(1px 5px 20px rgba(82, 151, 255, 0.58));
    background: transparent;
  }
`;
