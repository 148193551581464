import React, {Dispatch, FC, SetStateAction} from 'react';
import {Button, Form, FormInstance, Input, Tooltip, Typography,} from '@pankod/refine-antd';
import {ICryptoCurrencyConstraintItem} from 'widgets/order/types';
import {ReactComponent as ArrowForwardIcon} from '../../../../../shared/components/ui/assets/svg/arrowForward.svg';
import {ReactComponent as WalletIcon} from '../../../../../shared/components/ui/assets/svg/walletIcon.svg';
import {ReactComponent as TimerIcon} from '../../../../../shared/components/ui/assets/svg/timerIcon.svg';
import {
  ReactComponent as DeletePaymentCardIcon
} from '../../../../../shared/components/ui/assets/svg/deletePaymentCard.svg';
import {getPaymentInfoData} from 'shared/helpers/getPaymentInfoData';
import {useTranslation} from 'react-i18next';
import '.././styles.css';
import Phone from '../../../../paymentComponents/modalContent/secondStep/Service/image/phone.svg';
import SBP from '../../../../paymentComponents/modalContent/secondStep/Service/image/sbp.svg';
import styled from 'styled-components';
import {IPaymentLimitsErrors} from '../../../../../pages/p2p/createAd';
import {IPaymentsType, IPaymentsTypeBuyModified,} from '../../../../../pages/p2p/dashboard/interface';
import CustomSelect from '../../../../../shared/components/ui/select';
import {validateP2PTotalSumNumber} from '../../../../../shared/helpers/valideteP2PTotalSumNumber';
import {AdTypesEnum, IFiat, IPaymentType, IRegion} from '../../types';

interface IChooseLimitsAndPaymentTypeProps {
    errors: IPaymentLimitsErrors;
    setErrors: Dispatch<SetStateAction<IPaymentLimitsErrors>>;
    regionList: Array<IRegion>;
    chosenRegion?: IRegion;

    setUserPaymentListBuy: Dispatch<IPaymentsTypeBuyModified[]>;
    userPaymentListBuy: Array<IPaymentsTypeBuyModified>;

    onSelectRegion(region: IRegion): void;

    chosenFiat?: IFiat;
    chosenCurrency?: ICryptoCurrencyConstraintItem;
    currencyBalance: number;
    form: FormInstance;
    activePrice: number;
    userPaymentList: IPaymentType[];

    deletePaymentType(deletePaymentDetailId: number): void;

    deletePaymentTypeBuy(deletePaymentCardId: string): void;

    adCurrencyPrice: number;

    handleOpenChoosePaymentTypeSellModal(): void;

    handleOpenChoosePaymentTypeBuyModal(): void;

    createAdType: AdTypesEnum;

    iconsData?: IPaymentsType[];

    totalSum: string;
    handleChangeTotalSum: (value: string) => void;
    minLimit: string;
    handleChangeMinLimitMain: (value: string) => void;
    maxLimit: string;
    handleChangeMaxLimitMain: (value: string) => void;
}

const ChooseLimitsAndPaymentType: FC<IChooseLimitsAndPaymentTypeProps> = ({
                                                                              deletePaymentType,
                                                                              deletePaymentTypeBuy,
                                                                              userPaymentListBuy,
                                                                              setUserPaymentListBuy,
                                                                              handleOpenChoosePaymentTypeSellModal,
                                                                              handleOpenChoosePaymentTypeBuyModal,
                                                                              adCurrencyPrice,
                                                                              userPaymentList,
                                                                              form,
                                                                              currencyBalance,
                                                                              chosenCurrency,
                                                                              chosenFiat,
                                                                              regionList,
                                                                              chosenRegion,
                                                                              onSelectRegion,
                                                                              createAdType,
                                                                              errors,
                                                                              setErrors,
                                                                              iconsData = [],
                                                                              totalSum,
                                                                              handleChangeTotalSum,
                                                                              minLimit,
                                                                              maxLimit,
                                                                              handleChangeMinLimitMain,
                                                                              handleChangeMaxLimitMain,
                                                                          }) => {
    const {t} = useTranslation();

    const sumForAmountOfActive =
        adCurrencyPrice *
        ((totalSum ? +totalSum : +form.getFieldValue('total-sum')) ?? 0);

    const handleChangeMaxLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value: inputValue} = e.target;
        if (!isNaN(parseFloat(inputValue)) && isFinite(Number(inputValue))) {
            form.setFieldValue('max-limit', inputValue);
            handleChangeMaxLimitMain(inputValue);
        }
    };
    const handleBlurMaxLimit = () => {
        if (!isNaN(parseFloat(maxLimit)) && isFinite(Number(maxLimit))) {
            const newValue = Number(maxLimit)?.toFixed(2);
            form.setFieldValue('max-limit', newValue);
            handleChangeMaxLimitMain(newValue);
        }
    };

    const handleChangeMinLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value: inputValue} = e.target;
        if (!isNaN(parseFloat(inputValue)) && isFinite(Number(inputValue))) {
            handleChangeMinLimitMain(inputValue);
        }
    };
    const handleBlurMinLimit = () => {
        if (!isNaN(parseFloat(minLimit)) && isFinite(Number(minLimit))) {
            const newValue = Number(minLimit)?.toFixed(2);
            form.setFieldValue('min-limit', newValue);
            handleChangeMinLimitMain(newValue);
        }
    };

    const getAdType = (adType: AdTypesEnum): string =>
        adType === AdTypesEnum.SELL
            ? t('p2p.chooseLimitsAndPaymentsType.sales')
            : t('p2p.chooseLimitsAndPaymentsType.purchase');

    // TODO: `возможно в будущем будет реализован функционал с максимальным лимитом
    // const getLimit = (currency?: ICryptoCurrencyConstraintItem): number => {
    //   return currency?.dollar_exchange
    //     ?
    //       Math.trunc(+(50000 / +currency?.dollar_exchange) * Math.pow(10, 8)) /
    //         Math.pow(10, 8)
    //     : 0;
    // };

    const handleBlurTotalSum = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (!isNaN(parseFloat(value)) && isFinite(Number(value))) {
            const newValue = Number(value).toFixed(8);
            form.setFieldValue('total-sum', newValue);
            handleChangeTotalSum(newValue);
        }
    };

    const totalSumNormalize = (value: any) => {
        if (value < 0) {
            value = Math.abs(value);
        }
        return validateP2PTotalSumNumber(value);
    };
    const limitNormalize = (value: any) => {
        return value < 0 ? Math.abs(value) : value;
    };

    type keyText =
        | 'mobileBalancePayment'
        | 'bankTransferPayment'
        | 'fastPaymentSystemPayment'
        | 'bankCardPayment'
        | 'webServicePayment';

    const getPaymentText = (key: keyText) => {
        const keyObject = {
            mobileBalancePayment: t(
                'p2p.chooseLimitsAndPaymentsType.paymentMethods.mobilePhone'
            ),
            fastPaymentSystemPayment: t(
                'p2p.chooseLimitsAndPaymentsType.paymentMethods.fastPaymentSystem'
            ),
            bankTransferPayment: t(
                'p2p.chooseLimitsAndPaymentsType.paymentMethods.bankAccount'
            ),
            bankCardPayment: t(
                'p2p.chooseLimitsAndPaymentsType.paymentMethods.bankcard'
            ),
            webServicePayment: t(
                'p2p.chooseLimitsAndPaymentsType.paymentMethods.electronicPaymentService'
            ),
        };
        return keyObject[key];
    };

    const placeholderCard = (listItem: IPaymentsTypeBuyModified) => {
        return listItem.payment_type === 'mobileBalancePayment'
            ? '+7 (ХХХ) ХХХ-ХХ-ХХ'
            : listItem.payment_type === 'fastPaymentSystemPayment'
                ? '+7 (ХХХ) ХХХ-ХХ-ХХ'
                : listItem.name;
    };

    const totalSumValidator = (value: any, callback: any) => {
        createAdType === AdTypesEnum.SELL &&
        +value > currencyBalance
            ? callback(
                t(
                    'p2p.chooseLimitsAndPaymentsType.exceededAvailableBalance'
                ).toString()
            )
            : callback();
    }

    const minLimitValidator = (value: any, callback: any) => {
        if (chosenFiat) {
            if (+value === null || +value === 0) {
                callback(
                    t(
                        'p2p.chooseLimitsAndPaymentsType.inputs.enterMinimumLimit'
                    ).toString()
                );
                return;
            } else if (+value < +chosenFiat.dollar_exchange * 5) {
                callback(
                    `${t(
                        'p2p.chooseLimitsAndPaymentsType.inputs.minimumLimitExtra'
                    )} ${(chosenFiat.dollar_exchange * 5)?.toFixed(
                        2
                    )} ${chosenFiat.currency}`
                );
                return;
            } else if (
                +value > +form.getFieldValue('max-limit') ||
                (sumForAmountOfActive &&
                    +value > sumForAmountOfActive)
            ) {
                callback(
                    t(
                        'p2p.chooseLimitsAndPaymentsType.inputs.exceedMaxLimit'
                    ).toString()
                );
                return;
            }
        }
        callback();
    }

    const maxLimitValidator = (value: any, callback: any) => {
        if (chosenFiat) {
            if (+value === null || +value < 0) {
                callback(
                    t(
                        'p2p.chooseLimitsAndPaymentsType.inputs.enterMinimumLimit'
                    ).toString()
                );
                return;
            } else if (!(+value <= +(+sumForAmountOfActive).toFixed(2))) {
                callback(
                    t(
                        'p2p.chooseLimitsAndPaymentsType.inputs.exceedMaxLimit'
                    ).toString()
                );
                return;
            }
        }
        callback();
    }

    return (
        <div className="create-ad-cards-wrapper">
            <div className="choose-limits-and-payment-type-card">
                <div className="p2p-ad-card-content">
                    <Typography className="create-p2p-ad-card-title">
                        {t('p2p.chooseLimitsAndPaymentsType.title')}{' '}
                        {getAdType(createAdType)}
                    </Typography>
                    <Form
                        validateTrigger={['onChange', 'onBlur']}
                        name="choose-limits-form"
                        form={form}
                        layout="vertical"
                        initialValues={{
                            'min-limit': minLimit,
                        }}
                    >
                        <div className="limits-form-content-wrapper">
                            <div className="limits-form-content-wrapper-content">
                                <div className="listing">
                                    <div className="list-item">
                                        <Typography.Text>
                                            {t('p2p.chooseLimitsAndPaymentsType.sumTotal')}{' '}
                                            {getAdType(createAdType)}{' '}
                                            {t('p2p.chooseLimitsAndPaymentsType.inActive')}
                                        </Typography.Text>
                                        <Typography.Text>
                                            = {sumForAmountOfActive?.toFixed(2)}{' '}
                                            {chosenFiat?.currency}
                                        </Typography.Text>
                                    </div>
                                    <Form.Item
                                        initialValue={totalSum}
                                        required={false}
                                        name={'total-sum'}
                                        shouldUpdate
                                        normalize={totalSumNormalize}
                                        rules={[
                                            {
                                                message: `${t(
                                                    'p2p.chooseLimitsAndPaymentsType.enterSumTotal'
                                                )} ${getAdType(createAdType)}`,
                                                required: true,
                                            },
                                            {
                                                validator(rule, value, callback) {
                                                    totalSumValidator(value, callback)
                                                },
                                            },
                                        ]}
                                    >
                                        <Input
                                            type="number"
                                            placeholder={`0.000000000 ${chosenCurrency?.currency}`}
                                            min={0}
                                            onChange={(e) => handleChangeTotalSum(e.target.value)}
                                            onBlur={handleBlurTotalSum}
                                        />
                                    </Form.Item>
                                </div>
                                {createAdType === AdTypesEnum.SELL && (
                                    <Tooltip
                                        trigger={'hover'}
                                        placement="bottom"
                                        title={t(
                                            'p2p.chooseLimitsAndPaymentsType.availableBalance'
                                        )}
                                        color="#C3C3CE"
                                        overlayInnerStyle={{color: '#2B2B36'}}
                                    >
                                        <div className="wallet-data-wrapper">
                                            <WalletIcon/>
                                            <Typography.Text>
                                                {currencyBalance} {chosenCurrency?.currency}
                                            </Typography.Text>
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                            <div className="coin-limits" lang="en-US">
                                <FormItemStyled
                                    initialValue={minLimit}
                                    required={false}
                                    style={{width: '100%'}}
                                    normalize={limitNormalize}
                                    rules={[
                                        {
                                            validator(rule, value, callback) {
                                                minLimitValidator(value, callback)
                                            },
                                        },
                                    ]}
                                    dependencies={['max-limit']}
                                    name={'min-limit'}
                                    label={t(
                                        'p2p.chooseLimitsAndPaymentsType.inputs.minimumLimit'
                                    )}
                                    extra={`${t(
                                        'p2p.chooseLimitsAndPaymentsType.inputs.minimumLimitExtra'
                                    )} ${
                                        chosenFiat
                                            ? (chosenFiat?.dollar_exchange * 5)?.toFixed(2)
                                            : 0
                                    } ${chosenFiat?.currency}`}
                                >
                                    <Input
                                        type="number"
                                        placeholder={`0.00 ${chosenFiat?.currency}`}
                                        min={0}
                                        onChange={handleChangeMinLimit}
                                        onBlur={handleBlurMinLimit}
                                    />
                                </FormItemStyled>
                                <div className="arrow-icon-wrapper">
                                    <ArrowForwardIcon/>
                                </div>
                                <FormItemStyled
                                    initialValue={maxLimit}
                                    required={false}
                                    style={{width: '100%'}}
                                    normalize={limitNormalize}
                                    rules={[
                                        {
                                            message: t(
                                                'p2p.chooseLimitsAndPaymentsType.inputs.enterMaximumLimit'
                                            ).toString(),
                                            required: true,
                                        },
                                        {
                                            validator(rule, value, callback) {
                                                maxLimitValidator(value, callback)
                                            },
                                        },
                                    ]}
                                    // shouldUpdate
                                    name={'max-limit'}
                                    label={t(
                                        'p2p.chooseLimitsAndPaymentsType.inputs.maximumLimit'
                                    )}
                                    extra={`${t(
                                        'p2p.chooseLimitsAndPaymentsType.inputs.maximumLimitExtra'
                                    )} ${sumForAmountOfActive?.toFixed(2)} ${
                                        chosenFiat?.currency
                                    }`}
                                >
                                    <Input
                                        type="number"
                                        placeholder={`0.00 ${chosenFiat?.currency}`}
                                        min={0}
                                        onChange={handleChangeMaxLimit}
                                        onBlur={handleBlurMaxLimit}
                                    />
                                </FormItemStyled>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className="time-icon-wrapper">
                    <TimerIcon/>
                    <TypographyTextStyled>
                        {t('p2p.chooseLimitsAndPaymentsType.buyerPaymentTime')}
                    </TypographyTextStyled>
                    <Typography.Text className="small-bold-text">
                        30 {t('min')}
                    </Typography.Text>
                </div>
                <div className="limits-bottom-block">
                    <div>
                        <Typography className="create-p2p-ad-medium-text">
                            Σ {totalSum ? totalSum : '0.00'} {chosenCurrency?.currency}
                        </Typography>
                        <TypographyStyled $color={'#C3C3CE'} $fontSize={12}>
                            {t('p2p.chooseLimitsAndPaymentsType.sumTotal')}
                        </TypographyStyled>
                    </div>
                    <div>
                        <Typography className="create-p2p-ad-medium-text">
                            {form.getFieldValue('min-limit')
                                ? form.getFieldValue('min-limit')
                                : '0.00'}{' '}
                            {chosenFiat?.currency} -{' '}
                            {form.getFieldValue('max-limit')
                                ? form.getFieldValue('max-limit')
                                : '0.00'}{' '}
                            {chosenFiat?.currency}
                        </Typography>
                        <TypographyStyled $color={'#C3C3CE'} $fontSize={12}>
                            {t('p2p.chooseLimitsAndPaymentsType.dealLimits')}
                        </TypographyStyled>
                    </div>
                </div>
            </div>

            <div className="choose-limits-and-payment-type-card">
                <div className="p2p-ad-card-content">
                    <Typography className="create-p2p-ad-card-title">
                        {t('p2p.chooseLimitsAndPaymentsType.methodAndTimeOfPayment')}
                    </Typography>
                    <div className="card-content-wrapper">
                        <PaymentRegionWrapper>
                            <div className="choose-payment-region">
                                <Typography.Text>
                                    {t('p2p.chooseLimitsAndPaymentsType.countryPayment')}
                                </Typography.Text>
                                <CustomSelect
                                    status={
                                        errors.region !== null &&
                                        typeof errors.region !== 'undefined'
                                            ? 'error'
                                            : ''
                                    }
                                    key={'region-select'}
                                    value={chosenRegion?.id}
                                    showSearch={true}
                                    setValue={(value) => {
                                        const selectedRegion = regionList.find(
                                            (reg) => reg.id === +value
                                        );
                                        setErrors((prevState) => ({...prevState, region: null}));
                                        selectedRegion && onSelectRegion(selectedRegion);
                                    }}
                                    data={regionList.map((region) => ({
                                        label: t(`p2p.regions.${region.name?.trim()}`),
                                        value: region.id,
                                    }))}
                                    placeholder={
                                        t('p2p.chooseLimitsAndPaymentsType.chooseCountry') ?? ''
                                    }
                                />
                            </div>
                            {errors.region && <Error>{errors.region}</Error>}
                        </PaymentRegionWrapper>

                        {/* Карточки со способами оплаты*/}
                        <div className={'choose-payment-type-wrapper'}>
                            <div className={'choose-payment-type-header'}>
                                <ContainerErrorStyled>
                                    <Typography>
                                        {t('p2p.chooseLimitsAndPaymentsType.paymentMethod')} (
                                        {createAdType === AdTypesEnum.SELL
                                            ? userPaymentList.length
                                            : userPaymentListBuy.length}
                                        /5)
                                    </Typography>
                                    {errors.payment ? (
                                        <ErrorTypographyStyled>
                                            {t(
                                                'p2p.chooseLimitsAndPaymentsType.addFivePaymentMethod'
                                            )}
                                        </ErrorTypographyStyled>
                                    ) : (
                                        <DefaultTypographyStyled>
                                            {t(
                                                'p2p.chooseLimitsAndPaymentsType.addFivePaymentMethod'
                                            )}
                                        </DefaultTypographyStyled>
                                    )}
                                </ContainerErrorStyled>

                                {/*TODO кнопка добавления*/}
                                <ButtonDashedStyled
                                    type="dashed"
                                    style={{width: 235}}
                                    onClick={
                                        createAdType === AdTypesEnum.SELL
                                            ? handleOpenChoosePaymentTypeSellModal
                                            : handleOpenChoosePaymentTypeBuyModal
                                    }
                                >
                                    {(createAdType === AdTypesEnum.SELL &&
                                        userPaymentList.length === 0) ||
                                    (createAdType === AdTypesEnum.BUY &&
                                        userPaymentListBuy.length === 0)
                                        ? t('p2p.chooseLimitsAndPaymentsType.addPaymentMethod')
                                        : t('p2p.chooseLimitsAndPaymentsType.changePaymentMethod')}
                                </ButtonDashedStyled>
                            </div>
                            {createAdType === AdTypesEnum.SELL ? (
                                <PaymentListWrapper
                                    iconsData={iconsData}
                                    deletePaymentType={deletePaymentType}
                                    userPaymentList={userPaymentList}
                                />
                            ) : (
                                <>
                                    <div className="payments-buy-type-list-wrapper">
                                        {userPaymentListBuy.map((listItem) => (
                                            <div key={listItem.uniq_id}>
                                                <div className="payment-buy-card">
                                                    <div className="payment-buy-card-data-wrapper">
                                                        <div className="payment-buy-card-info">
                                                            {listItem.payment_type ===
                                                            'mobileBalancePayment' ? (
                                                                <ImageStyled
                                                                    alt={`mobile-${listItem.id}`}
                                                                    src={Phone}
                                                                />
                                                            ) : listItem.payment_type ===
                                                            'fastPaymentSystemPayment' ? (
                                                                <ImageStyled
                                                                    alt={`sbp-${listItem.id}`}
                                                                    src={SBP}
                                                                />
                                                            ) : (
                                                                <ImageStyled
                                                                    alt={'icon-' + listItem.id}
                                                                    src={`data:image/svg+xml;base64,${listItem.icon}`}
                                                                />
                                                            )}
                                                            <div className="payment-buy-card-text">
                                                                <Typography.Text
                                                                    className="payment-buy-card-title dotted-line">
                                                                    {placeholderCard(listItem)}
                                                                </Typography.Text>

                                                                <TypographyStyled
                                                                    className={'dotted-line'}
                                                                    $color={'#767687'}
                                                                    $fontSize={12}
                                                                    $lineHeight={14.52}
                                                                >
                                  <span>
                                    {getPaymentText(
                                        listItem.payment_type as keyText
                                    )}
                                  </span>
                                                                </TypographyStyled>
                                                            </div>
                                                        </div>

                                                        <div className="payment-buy-card-icon-wrapper">
                                                            <DeletePaymentCardIcon
                                                                onClick={() =>
                                                                    deletePaymentTypeBuy(listItem?.uniq_id)
                                                                }
                                                                className="delete-payment-type-icon"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface IPaymentListWrapperProps {
    deletePaymentType(deletePaymentDetailId: number): void;

    iconsData?: IPaymentsType[];
    userPaymentList: IPaymentType[];
}

const PaymentListWrapper: FC<IPaymentListWrapperProps> = ({
                                                              iconsData = [],
                                                              deletePaymentType,
                                                              userPaymentList,
                                                          }) => {
    const {t} = useTranslation();

    return (
        <PaymentsListWrapper>
            {userPaymentList.map((listItem) => {
                return (
                    <div key={listItem.id}>
                        {getPaymentInfoData(listItem, t, iconsData).map((item, index) => (
                            <PaymentListItem
                                item={item}
                                listItem={listItem}
                                deletePaymentType={deletePaymentType}
                            />
                        ))}
                    </div>
                );
            })}
        </PaymentsListWrapper>
    )
}

interface IPaymentListItemProps {
    deletePaymentType(deletePaymentDetailId: number): void;

    listItem: any;
    item: any;
}

const PaymentListItem: FC<IPaymentListItemProps> = ({
                                                        item,
                                                        listItem,
                                                        deletePaymentType,
                                                    }) => {
    const {t} = useTranslation();

    const getPaymentType = (type: string): string => {
        switch (type) {
            case 'bankCardPayment':
                return t('p2p.chooseLimitsAndPaymentsType.paymentMethods.bankcard');
            case 'mobileBalancePayment':
                return t('p2p.chooseLimitsAndPaymentsType.paymentMethods.mobilePhone');
            case 'bankTransferPayment':
                return t('p2p.chooseLimitsAndPaymentsType.paymentMethods.bankAccount');
            case 'fastPaymentSystemPayment':
                return t(
                    'p2p.chooseLimitsAndPaymentsType.paymentMethods.fastPaymentSystem'
                );
            default:
                return t(
                    'p2p.chooseLimitsAndPaymentsType.paymentMethods.electronicPaymentService'
                );
        }
    };

    return (
        <PaymentCard>
            <HeaderCardWrapper>
                <DefaultTypographyStyled>
                    <span>{getPaymentType(listItem.payment_type)}</span>
                </DefaultTypographyStyled>

                <IconContainer className="icon-container" onClick={() => deletePaymentType(listItem.id)}>
                    <DeletePaymentCardIconStyled className="delete-payment-type-icon"/>
                </IconContainer>
            </HeaderCardWrapper>

            <CardInfo>
                <ImgWrapper>
                    <ImgStyled alt={item.title} src={item.icon}/>
                </ImgWrapper>
                <div className="data-wrapper">
                    <div className="data-content">
                        {
                            (listItem.payment_type === 'mobileBalancePayment' ||
                                listItem.payment_type === 'fastPaymentSystemPayment')
                                ? <></>
                                : <Typography.Text>
                                    {item.title}
                                </Typography.Text>
                        }
                        {
                            (listItem.payment_type === 'mobileBalancePayment' ||
                                listItem.payment_type === 'fastPaymentSystemPayment')
                                ? <Typography.Text>
                                    {item.value}
                                </Typography.Text>
                                : <LightGreyText className="payment-type-value">
                                    {item.value}
                                </LightGreyText>
                        }
                    </div>
                </div>
            </CardInfo>

            <SubtitleWrapper>
                <LightGreyText className="subtitle">
                    {item.subtext}
                </LightGreyText>
            </SubtitleWrapper>
        </PaymentCard>
    )
}
export default ChooseLimitsAndPaymentType;

const TypographyStyled = styled(Typography)<{
    $color?: string;
    $fontSize?: number;
    $lineHeight?: number;
    $fontWeight?: number;
    $display?: string;
    $maxWidth?: number;
}>`
    color: ${(props) => props.$color};
    font-size: ${(props) => props.$color}px;
    line-height: ${(props) => props.$color}px;
    font-weight: ${(props) => props.$fontWeight};
    display: ${(props) => props.$display};
    max-width: ${(props) => props.$maxWidth}px;
`;


const Error = styled.span`
    color: #c15156;
    margin-top: 4px;
    font-weight: 100;
    font-size: 12px;
    line-height: 14.52px;
`;

const IconContainer = styled.div`
    height: 16px;
    width: 16px;
    padding: 0;
`;

const CardInfo = styled.div`
    display: flex;
    align-items: center;
`;

const ImgWrapper = styled.div`
    width: 32px;
`;

const ImgStyled = styled.img`
    width: 24px;
    height: 24px;
`;

const PaymentRegionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const DeletePaymentCardIconStyled = styled(DeletePaymentCardIcon)`
    width: 16px;
    height: 16px;
`;

const FormItemStyled = styled(Form.Item)`
    width: 100%;
`;

const TypographyTextStyled = styled(Typography.Text)`
    font-size: 14px;
`;

const ContainerErrorStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 209px;
`;

const ErrorTypographyStyled = styled(Typography)`
    color: #c15156;
    font-size: 12px;
    line-height: 15px;
`;

const DefaultTypographyStyled = styled(Typography)`
    color: #767687;
    font-size: 12px;
    line-height: 14.52px;
`;

const LightGreyText = styled(DefaultTypographyStyled)`
    color: rgba(195, 195, 206, 1);
`

const ButtonDashedStyled = styled(Button)`
    width: 235px;
`;

const ImageStyled = styled.img`
    width: 24px;
    height: 24px;
`;

const PaymentCard = styled.div`
    position: relative;
    padding: 8px;
    align-items: center;
    gap: 8px;
    flex-grow: 1;
    background-color: #2B2B36;
    border-radius: 4px;
`

const SubtitleWrapper = styled.div`
    margin-top: 4px;
`;

const HeaderCardWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

const PaymentsListWrapper = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: column;
    max-height: 240px;
    overflow-y: auto;
    margin-top: 18px;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: none;
    }

    &::-webkit-scrollbar-thumb {
        background: #F3F3F8;
        border-radius: 4px;
    }

`
