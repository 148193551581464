import styled from "styled-components";

interface ISwitch {
  onClick: () => void
  isFirstActive: boolean
  firstItem: string
  secondItem: string
  isDisabled: boolean
}

export const SwitchTwoElem: React.FC<ISwitch> = ({
                                                   onClick,
                                                   isFirstActive,
                                                   firstItem,
                                                   secondItem,
                                                   isDisabled
                                                 }) => {

  const handleClick = () => {
    if (isDisabled) return;
    onClick()
  }

  const getColorItemFirst = () => {
    return isFirstActive
      ? isDisabled ? '#C3C3CE' : '#FBFBFF'
      : '#C3C3CE'
  }

  const getBackgroundColorItemFirst = () => {
    return isFirstActive
      ? isDisabled ? '#767687' : '#703DF2'
      : 'transparent'
  }

  const getCursorItem = () => {
    return isDisabled ? 'not-allowed' : "pointer"
  }

  const getColorItemSecond = () => {
    return !isFirstActive
      ? '#FBFBFF'
      : isDisabled ? '#767687' : '#C3C3CE'
  }

  const getBackgroundColorItemSecond = () => {
    return !isFirstActive
      ? isDisabled ? '#767687' : '#703DF2'
      : 'transparent'
  }

  return (
    <Wrapper onClick={handleClick}>
      <Item
        $color={getColorItemFirst()}
        $backgroundColor={getBackgroundColorItemFirst()}
        $cursor={getCursorItem()}
      >
        {firstItem}
      </Item>

      <Item
        $color={getColorItemSecond()}
        $backgroundColor={getBackgroundColorItemSecond()}
        $cursor={getCursorItem()}
      >
        {secondItem}
      </Item>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  background-color: #2B2B36;
  border: 1px solid #474755;
  flex-direction: row;
  border-radius: 8px;
  padding: 4px;
  width: min-content;
`

const Item = styled.div<{
  $color?: string,
  $backgroundColor?: string,
  $cursor?: string,
}>`
  color: ${(props) => props.$color};
  background-color: ${(props) => props.$backgroundColor};
  border-radius: 5px;
  padding: 6px 12px;
  cursor: ${(props) => props.$cursor};
`
