const PdfFile = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
  >
    <path
      d="M12 0H28.5V3H12C10.3431 3 9 4.34315 9 6V42C9 43.6569 10.3431 45 12 45H36C37.6569 45 39 43.6569 39 42V13.5H42V42C42 45.3137 39.3137 48 36 48H12C8.68629 48 6 45.3137 6 42V6C6 2.68629 8.68629 0 12 0Z"
      fill="#FBFBFF"
    />
    <path
      d="M28.5 9V0L42 13.5H33C30.5147 13.5 28.5 11.4853 28.5 9Z"
      fill="#FBFBFF"
    />
    <path
      d="M12.7585 29V20.2727H16.2017C16.8636 20.2727 17.4276 20.3991 17.8935 20.652C18.3594 20.902 18.7145 21.25 18.9588 21.696C19.206 22.1392 19.3295 22.6506 19.3295 23.2301C19.3295 23.8097 19.2045 24.321 18.9545 24.7642C18.7045 25.2074 18.3423 25.5526 17.8679 25.7997C17.3963 26.0469 16.8253 26.1705 16.1548 26.1705H13.9602V24.6918H15.8565C16.2116 24.6918 16.5043 24.6307 16.7344 24.5085C16.9673 24.3835 17.1406 24.2116 17.2543 23.9929C17.3707 23.7713 17.429 23.517 17.429 23.2301C17.429 22.9403 17.3707 22.6875 17.2543 22.4716C17.1406 22.2528 16.9673 22.0838 16.7344 21.9645C16.5014 21.8423 16.206 21.7812 15.848 21.7812H14.6037V29H12.7585ZM23.6218 29H20.5281V20.2727H23.6474C24.5252 20.2727 25.2809 20.4474 25.9144 20.7969C26.5479 21.1435 27.0352 21.642 27.3761 22.2926C27.7198 22.9432 27.8917 23.7216 27.8917 24.6278C27.8917 25.5369 27.7198 26.3182 27.3761 26.9716C27.0352 27.625 26.5451 28.1264 25.9059 28.4759C25.2695 28.8253 24.5082 29 23.6218 29ZM22.3732 27.419H23.5451C24.0906 27.419 24.5494 27.3224 24.9215 27.1293C25.2965 26.9332 25.5778 26.6307 25.7653 26.2216C25.9556 25.8097 26.0508 25.2784 26.0508 24.6278C26.0508 23.983 25.9556 23.456 25.7653 23.0469C25.5778 22.6378 25.2979 22.3366 24.9258 22.1435C24.5536 21.9503 24.0948 21.8537 23.5494 21.8537H22.3732V27.419ZM29.2585 29V20.2727H35.0369V21.794H31.1037V23.8736H34.6534V25.3949H31.1037V29H29.2585Z"
      fill="white"
    />
  </svg>
);

export { PdfFile };
