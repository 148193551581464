import React, { FC, useState } from 'react';
import './styles.css';
import { ReactComponent as Down } from './image/Down.svg';
import { useTranslate } from '@pankod/refine-core';
import { getFullNameCoin } from '../../../../../shared/helpers/getFullNameCoin';
import { ICryptoPair } from '../../../../../features/cryptocurrencyExchange/cryptoCurrencyExchange.p';
import CardInput from '../../../../../shared/components/ui/input/cardInput';

interface Props {
  isGetting: boolean;
  coinName: string;
  coinIcon: string;
  inputAmount: string;
  inputCoin: string;
  changePrice: (input: string, output: string) => void;
  currentPair: ICryptoPair | undefined;
  minimumExchangeAmount: number;
  handleOpenChoice(value?: boolean): void;
}

const Card: FC<Props> = ({
  isGetting,
  currentPair,
  changePrice,
  minimumExchangeAmount,
  inputAmount,
  coinIcon,
  coinName,
  handleOpenChoice,
}) => {
  const [inputAfterDotSymbolsCount, setInputAfterDotSymbolsCount] = useState(9);
  const translate = useTranslate();

  return (
    <div className={'card'}>
      <div className={'card-header'}>
        <img
          alt={'coinIcon'}
          src={coinIcon}
          style={{ width: 40, height: 40 }}
        />
        <div className={'card-header-icon-container'} />
        <div className={'card-header-text-container'}>
          <span className={'card-title-mobile'}>
            {isGetting
              ? translate(
                  'cryptoExchange.changer.rightCard.title'
                ).toUpperCase()
              : translate(
                  'cryptoExchange.changer.leftCard.title'
                ).toUpperCase()}
          </span>
          <span className={'card-header-text-coin'}>
            {getFullNameCoin(coinName)} ({coinName})
          </span>
        </div>
      </div>
      <div className={'card-content'}>
        <CardInput
          smallText={true}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: '#2B2B36',
            width: '100%',
          }}
          value={inputAmount || '0.0000'}
          min={minimumExchangeAmount}
          step={
            '0' +
            `${inputAfterDotSymbolsCount !== 0 ? '.' : ''}` +
            Array.from({ length: inputAfterDotSymbolsCount }, () => '0').join(
              ''
            )
          }
          onChange={(value) => {
            if (!currentPair) return;
            if (value === null) {
              changePrice(
                minimumExchangeAmount.toString(),
                (minimumExchangeAmount * +currentPair.price).toString()
              );
              return;
            }
            if (value.toString().includes('.')) {
              setInputAfterDotSymbolsCount(
                value.toString()?.split('.')[1].length
              );
            } else {
              setInputAfterDotSymbolsCount(0);
            }
            if (value.toString().length > 18) return; //Ограничение до 18 символов при вводе учитывая точку
            changePrice(value + '', +currentPair.price * +value + '');
          }}
        />
        <button
          className={'card-content-button'}
          onClick={() => handleOpenChoice()}
        >
          <span className={'card-content-button-text'}>{coinName}</span>
          <Down />
        </button>
      </div>
    </div>
  );
};

export default Card;
