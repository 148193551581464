import {Popconfirm, Tooltip, Typography, useSimpleList,} from '@pankod/refine-antd';
import {BaseRecord, HttpError, useCustomMutation, useList, useUpdate} from '@pankod/refine-core';
import {useEffect, useState} from 'react';
import {useNavigate} from '@pankod/refine-react-router-v6';
import {IConfigCurrencyPair, IPaymentType} from 'features/p2p/createOrder/types';
import {useTranslation} from 'react-i18next';
import {axios} from '../../../../shared/exios';
import {styled} from 'styled-components';
import {AdHeading} from './header';
import {AdContent} from './content';
import {AdPaymentTypes} from './paymentTypes';
import {DealTerms} from './dealTerms';
import {IPaymentsType} from 'pages/p2p/dashboard/interface';
import {getPaymentInfoData} from "../../../../shared/helpers/getPaymentInfoData";
import {notification} from "antd";

const getRegionById = async (id: string, dispatch: (arg: string) => void) => {
    if (!!id) {
        await axios
            .get(`/p2p/api/regions/${id}`)
            .then((value) => {
                dispatch(value.data.data.name);
            });
    }
};

enum PaymentEnum {
    bankCard = 'bankCardPayment',
    webService = 'webServicePayment',
    bankTransfer = 'bankTransferPayment',
    mobile = 'mobileBalancePayment',
    sbp = 'fastPaymentSystemPayment',
}

const getPaymentType = (type: string, t: (key: string, options?: any, defaultMessage?: string | undefined) => string) => {
    switch (type) {
        case PaymentEnum.bankCard:
            return t('payments.types.banksCard');
        case PaymentEnum.bankTransfer:
            return t('payments.types.banks');
        case PaymentEnum.sbp:
            return t('payments.types.sbp');
        case PaymentEnum.webService:
            return t('payments.types.webService');
        default:
            return t('payments.types.mobiles');
    }
};

const Params = ({data}: { data: BaseRecord }) => {
    const {t} = useTranslation();
    const {mutate} = useUpdate();
    const {mutate: customPut} = useCustomMutation();

    const {data: currencyPairsList} = useList<IConfigCurrencyPair, HttpError>({
        resource: 'currency-pair',
        dataProviderName: 'config',
        config: {
            pagination: {
                current: 1,
                pageSize: 30,
            },
            filters: [
                {
                    field: 'is_available_p2p',
                    operator: 'eq',
                    value: true,
                },
            ],
        },
        queryOptions: {
            refetchInterval: 60000,
            onSuccess: (data) => {
                const pairName = `${record.ccy}${record.fiat}`;
                const latestPrice = data.data.find((item) => item.pair == pairName)?.latest_price;
                latestPrice && setLatestPriceRange({
                    min: +latestPrice * (80 / 100),
                    max: +latestPrice * (130 / 100)
                })
            }
        }
    });


    const iconsData = useList<IPaymentsType, HttpError>({
        dataProviderName: `p2p`,
        resource: `payment-types`,
    });
    const {listProps: comissionData} = useSimpleList({
        dataProviderName: 'config',
        resource: 'currency-constraint',
        initialFilter: [
            {
                field: 'currency',
                operator: 'eq',
                value: `${data?.dataSource[0]?.ccy}`,
            },
        ],
    });

    const {listProps: reactionTime} = useSimpleList({
        dataProviderName: 'config',
        resource: 'config',
        initialFilter: [
            {field: 'key', operator: 'eq', value: 'respond_reaction_time'},
        ],
    });
    const navigate = useNavigate();
    const [record, setRecord] = useState(data);
    const [latestPriceRange, setLatestPriceRange] = useState<{ min: string | number, max: string | number }>({
        min: '0',
        max: '50'
    })
    const [regionName, setRegionName] = useState('');

    useEffect(() => {
        data.dataSource && setRecord(data?.dataSource[0]);
        data.dataSource &&
        getRegionById(data?.dataSource[0]?.region_id, setRegionName);
    }, [data]);

    const changeStatus = (deleteAnn: boolean) => {
        let newStatus = '';
        deleteAnn
            ? (newStatus = 'deleted')
            : record.status === 'template'
                ? (newStatus = 'created')
                : (newStatus = 'template');
        mutate({
            resource: 'offer',
            dataProviderName: 'p2p',
            id: `${record.id}`,
            values: {
                status: `${newStatus}`,
            },
        },{
          onError: (error) => {
            if(error.message === 'Unable to publish Ad, you already have one') {
              notification.error({
                message: t('messages.error.adCreateError'),
                description: t('messages.error.maxAdLimit'),
              })
            }
          }
        });
        deleteAnn && navigate('/p2p/my-announcements');
    };
    const PaymentTypesBlock = (record: BaseRecord, type: string) => {
        let arr = record.payment_info ?? [];
        if (type !== 'sell') {
            arr = record.buy_payment_info;
        }
        const getPaymentDetails = (item: IPaymentType, i: number) => {
            if (type === 'sell') {
                return {...item, payment_details: item.payment_details}
            } else {
                return {...item, payment_details: [arr[i]]}
            }
        }
        return arr?.map(
            (item: IPaymentType, i: number) =>
                !item.is_deleted && (
                    <div key={i} className={'payment-card'}>
                        <FCol style={{gap: '4px'}}>
                            {type === 'sell' &&
                                <FRow>
                                    <GreyThinText>
                                        {getPaymentType(item.payment_type, t)}
                                    </GreyThinText>
                                </FRow>}
                            <FRow style={{gap: '8px'}}>
                                <FCol className="payment-ico-container">
                                    <img
                                        src={getPaymentInfoData(getPaymentDetails(item, i), t, iconsData.data?.data)[0].icon}
                                        alt={`img-${i}`}/>
                                </FCol>
                                <FCol>
                                    <FCol>
                                        <Typography.Text className="text-regular text-white ellipsis-240">
                                            {getPaymentInfoData(getPaymentDetails(item, i), t, iconsData.data?.data)[0].title}
                                        </Typography.Text>
                                        {getPaymentInfoData(getPaymentDetails(item, i), t)[0].value && (
                                            <LightGreyText className="ellipsis-240">
                                                {getPaymentInfoData(getPaymentDetails(item, i), t, iconsData.data?.data)[0].value}
                                            </LightGreyText>
                                        )}
                                        {type !== 'sell' && (!!getPaymentInfoData(getPaymentDetails(item, i), t)[0].subtext ? (
                                            <LightGreyText className="ellipsis-240">
                                                {getPaymentInfoData(getPaymentDetails(item, i), t, iconsData.data?.data)[0].subtext}
                                            </LightGreyText>
                                        ) : <LightGreyText className="ellipsis-240">
                                            {getPaymentType(item.payment_type, t)}
                                        </LightGreyText>)}
                                    </FCol>
                                </FCol>
                            </FRow>
                            {type === 'sell' &&
                                <FRow>
                                    {!!getPaymentInfoData(getPaymentDetails(item, i), t)[0].subtext ? (
                                        <LightGreyText className="ellipsis-240">
                                            {getPaymentInfoData(getPaymentDetails(item, i), t, iconsData.data?.data)[0].subtext}
                                        </LightGreyText>
                                    ) : <></>
                                    }
                                </FRow>}
                        </FCol>
                    </div>
                )
        );
    };
    const isEnabled = data.dataSource[0].status !== 'executed' &&
      data.dataSource[0].orders_active_count == 0;
    const onConfirm = () => navigate(`/p2p/edit-offer/${record.type}/${record.id}/${record.ccy}${record.fiat}`);

    const getConfirmTitle = () => (record.price < latestPriceRange.min || record.price > latestPriceRange.max)
        ? t('announcements.buttons.editAdWithUnaccaptablePrice')
        : t('announcements.buttons.editAd') + '?';

    const popConfirmProps = {
        okButtonProps: {
            style: {paddingInline: 32},
            size: "large" as const,
        },
        cancelButtonProps: {
            style: {
                paddingInline: 32,
                background: 'none',
                color: 'white',
                borderColor: '#7B59FD',
            },
            size: "large" as const,
        },
    }
    return (
        <>
            <CardContainer>
                <AdCard>
                    <AdHeading record={record}/>
                    <AdContent
                        reactionTime={reactionTime.dataSource && reactionTime.dataSource[0].respond_reaction_time}
                        regionName={regionName} record={record} data={data} comissionData={comissionData}/>
                    <AdPaymentTypes record={record} PaymentTypesBlock={PaymentTypesBlock}/>
                    <DealTerms record={record}/>
                </AdCard>
            </CardContainer>
            <FRow style={{gap: '16px', marginTop: '10px'}}>

                            <Popconfirm
                              disabled={!isEnabled}
                                onConfirm={() => changeStatus(false)}
                                cancelText={t('payments.popConfirm.No')}
                                okText={t('payments.popConfirm.Yes')}
                                title={
                                    record.status === 'template'
                                        ? t('announcements.public.draft')
                                        : t('announcements.public.back')
                                }
                                {...popConfirmProps}
                            overlayInnerStyle={{
                                    maxWidth: (record.price < latestPriceRange.min || record.price > latestPriceRange.max)
                                        ? '376px'
                                        : '260px'
                                }}>
                              {!isEnabled ? <Tooltip
                                trigger="hover"
                                title={<div>{t('announcements.status.haveOpenDeals') + '\n' + t('announcements.status.cannotEditAd')}</div>}
                                placement="top"
                                overlayInnerStyle={{ width: 'auto' }}
                              >
                                <ActionButtonStyled $disabled={!isEnabled}>
                                  {record.status !== 'template'
                                    ? t('announcements.buttons.params.moveToDrafts')
                                    : t('announcements.buttons.params.postAd')}
                                </ActionButtonStyled>
                              </Tooltip> : <ActionButtonStyled $disabled={!isEnabled}>
                                {record.status !== 'template'
                                  ? t('announcements.buttons.params.moveToDrafts')
                                  : t('announcements.buttons.params.postAd')}
                              </ActionButtonStyled>}
                            </Popconfirm>

                            <Popconfirm
                              disabled={!isEnabled}
                                onConfirm={onConfirm}
                                cancelText={t('payments.popConfirm.No')}
                                okText={t('payments.popConfirm.Yes')}
                                title={getConfirmTitle}
                                {...popConfirmProps}
                                overlayInnerStyle={{
                                    maxWidth: (record.price < latestPriceRange.min || record.price > latestPriceRange.max)
                                        ? '376px'
                                        : '260px'
                                }}
                            >
                              {!isEnabled ? <Tooltip
                                trigger="hover"
                                title={<div>{t('announcements.status.haveOpenDeals') + '\n' + t('announcements.status.cannotEditAd')}</div>}
                                placement="top"
                                overlayInnerStyle={{ width: 'auto' }}
                              >
                                <ActionButtonStyled $disabled={!isEnabled}>
                                  {t('announcements.buttons.params.edit')}
                                </ActionButtonStyled>
                              </Tooltip> : <ActionButtonStyled $disabled={!isEnabled}>
                                {t('announcements.buttons.params.edit')}
                              </ActionButtonStyled>}
                            </Popconfirm>
                <Popconfirm
                  disabled={!isEnabled}
                    onConfirm={() => changeStatus(true)}
                    cancelText={t('payments.popConfirm.No')}
                    okText={t('payments.popConfirm.Yes')}
                    title={t('announcements.buttons.deleteAd1') + '?'}
                    {...popConfirmProps}
                    overlayInnerStyle={{
                        maxWidth: (record.price < latestPriceRange.min || record.price > latestPriceRange.max)
                            ? '376px'
                            : '260px'
                    }}
                >
                  {!isEnabled ? <Tooltip
                    trigger="hover"
                    title={<div>{t('announcements.status.haveOpenDeals') + '\n' + t('announcements.status.cannotDeleteAd')}</div>}
                    placement="top"
                    overlayInnerStyle={{ width: 'auto' }}
                  >
                    <ActionButtonStyled $disabled={!isEnabled}>
                      {t('announcements.buttons.params.deleteAd')}
                    </ActionButtonStyled>
                  </Tooltip> : <ActionButtonStyled $disabled={!isEnabled}>
                    {t('announcements.buttons.params.deleteAd')}
                  </ActionButtonStyled>}
                </Popconfirm>
            </FRow>
        </>
    );
};

export default Params;


const AdCard = styled.div`
    width: 100%;
    background: #1C1C27;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
`
const CardContainer = styled.div`
    width: 100%;
    display: flex;
    max-height: 600px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
`
const FRow = styled.div`
    display: flex;
`
const FCol = styled(FRow)`
    flex-direction: column;
`

const GreyThinText = styled(Typography.Text)`
    font-weight: 400;
    color: #767687;
    line-height: 14.52px;
    font-size: 12px;
`

const LightGreyText = styled(GreyThinText)`
    color: #C3C3CE;
`
const ActionButtonStyled = styled.div<{$disabled: boolean}>`
  padding: 8px 16px;
  border-radius: 8px;
  transition: all .2s ease;
  cursor: pointer;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: ${({$disabled}) => $disabled ? '#474755' : '#FFF'};
  &:hover {
    background: ${({$disabled}) => $disabled ? 'transparent' : '#474755'};
  }
`
