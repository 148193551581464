import {Tabs, Typography} from '@pankod/refine-antd';
import {useTranslate} from '@pankod/refine-core';
import React from 'react';
import {styled} from 'styled-components';
import {useAuthState} from '../../../useAuthState';
import {SpotOrderBookList} from 'features/spot/orderList/orderBook';
import {SpotChart} from 'widgets/spot/chart';
import {SpotPairInfo} from 'widgets/spot/pairInfo';
import {SpotPairSelector} from 'widgets/spot/pairSelector';
import {PlusCircle} from 'shared/components/icons';

export const DashboardMobile = () => {
    const total = useAuthState((state) => state.total);
    const balanceBTCAmount = useAuthState((state) => state?.balance?.BTC?.amount);

    const balance = !!total ? total : balanceBTCAmount;

    const t = useTranslate();

    return (
        <DashboardContainer>
            <StyledTabs
                tabBarStyle={{width: '100%'}}
                tabBarExtraContent={
                    <div
                        style={{flexDirection: 'column'}}
                        className={'flex-row gap-4 align-items-flex-end'}
                    >
                        <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                            <Typography.Text style={{lineHeight: '15px'}}>
                                {' '}
                                {balance} {'BTC'}{' '}
                            </Typography.Text>
                            <PlusCircle color={'#FBFBFF'} size={12}/>
                        </div>
                        <Typography.Text
                            style={{lineHeight: '15px', textAlign: 'right'}}
                            className={'text-gray'}
                        >
                            {t('spotPart.balance')}
                        </Typography.Text>
                    </div>
                }
                items={[
                    {
                        label: t('spot.trade'),
                        key: 'trade',
                        children: (
                            <TabContainer>
                                <TitleRowMobile>
                                    <SpotPairInfo/>
                                </TitleRowMobile>
                                <TabContent>
                                    <SpotPairWrapper>
                                        <SpotPairSelector/>
                                    </SpotPairWrapper>
                                    <SpotOrderBookWrapper>
                                        <SpotOrderBookList/>
                                    </SpotOrderBookWrapper>
                                </TabContent>
                            </TabContainer>
                        ),
                    },
                    {
                        label: '|',
                        key: 'line',
                        disabled: true,
                    },
                    {
                        label: t('spot.chart'),
                        key: 'chart',
                        children: (
                            <TabContainer>
                                <TitleRowMobile>
                                    <SpotPairInfo/>
                                </TitleRowMobile>
                                <TabContent>
                                    <SpotChart/>
                                </TabContent>
                            </TabContainer>
                        ),
                    },
                ]}
            />
        </DashboardContainer>
    );
};
const StyledTabs = styled(Tabs)`
  .ant-tabs-ink-bar {
    background-color: transparent;
  }

  .ant-tabs-tab {
    font-size: 16px;
    font-weight: 700;

    &:nth-child(2) {
      margin: 0 24px 0 24px;
      color: #474755;
    }

    &:nth-child(3) {
      margin: 0;
    }
  }

  .ant-tabs-nav::before {
    border-bottom: 0 solid transparent;
  }
`;

const TabContent = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
`;
const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  height: 70px;
  @media (max-width: 767px) {
    display: none;
  }
`;
const TitleRowMobile = styled(TitleRow)`
  display: none;
  @media (max-width: 767px) {
    display: flex;
  }
`;
const DashboardContainer = styled.div`
  @media (max-width: 767px) {
    display: flex;
    width: 100%;
    .ant-tabs-mobile {
      width: 100%;
    }

    .mob-display-none {
      display: none !important;
    }

    .mob-content-start {
      justify-content: flex-start !important;
    }

    .mob-content-end {
      justify-content: flex-end !important;
    }

    .mob-row-reverse {
      flex-direction: row-reverse;
    }

    .mob-row-column {
      flex-direction: column-reverse;
    }
  }
  @media (min-width: 768px) {
    display: none;
  }
`;
const TabContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: column;
`;
const SpotPairWrapper = styled.div`
  display: flex;
  width: 55%;
  flex-shrink: 0;
`;
const SpotOrderBookWrapper = styled.div`
  display: flex;
  width: 45%;
`;
