import Icon from '@ant-design/icons';
import { ClockIcon, RoundCloseIcon } from '../components/icons';
import { Icons } from '@pankod/refine-antd';
import { t } from 'i18next';
const { ExclamationCircleOutlined, CheckCircleOutlined } = Icons;
export const getP2POrderInfo = (status: string) => {
  switch (status) {
    case 'respond':
      return {
        icon: <Icon component={() => ClockIcon()} />,
        text: t('p2p.getOrderInfo.awaitingConfirmation'),
      };
    case 'respond_accepted':
      return {
        icon: (
          <Icon
            component={() => (
              <ExclamationCircleOutlined
                style={{ fontSize: 16, color: '#E8E264' }}
              />
            )}
          />
        ),
        text: t('p2p.getOrderInfo.transactionUnpaid'),
      };
    case 'fiat_transferred':
      return {
        icon: <Icon component={() => ClockIcon('#58BF92')} />,
        text: t('p2p.getOrderInfo.transactionPaid'),
      };
    case 'success':
      return {
        icon: (
          <Icon
            component={() => (
              <CheckCircleOutlined style={{ fontSize: 16, color: '#58BF92' }} />
            )}
          />
        ),
        text: t('p2p.getOrderInfo.transactionCompleted'),
      };
    case 'appealed':
      return {
        icon: (
          <Icon
            component={() => (
              <ExclamationCircleOutlined
                style={{ fontSize: 16, color: '#F16063' }}
              />
            )}
          />
        ),
        text: t('p2p.getOrderInfo.appeal'),
      };
    case 'refused':
      return {
        icon: <Icon component={() => RoundCloseIcon()} />,
        text: t('p2p.getOrderInfo.transactionCanceled'),
      };
    default:
      return {
        icon: <Icon component={() => ClockIcon()} />,
        text: t('p2p.getOrderInfo.awaitingConfirmation'),
      };
  }
};
