import React from 'react';
import Output from './index';
import { ReactComponent as MoneyIcon } from '../../shared/components/ui/assets/svg/money_modal_icon.svg';
import { useTranslation } from 'react-i18next';

const OnConfirm = () => {
  const { t } = useTranslation();

  return (
    <>
      <Output.FullscreenRingWithTextAndIcon
        centerIcon={MoneyIcon}
        text={t('outputOrder.withdrawOver')}
      />
    </>
  );
};

export default OnConfirm;
