import React from 'react';
import { BasicModal } from '../basicModal';
import {
  Button,
  Col,
  ConfigProvider,
  Row,
  Space,
  Typography,
} from '@pankod/refine-antd';
import {
  ICurrencyOption,
  IExchangeContent,
} from '../../../../../features/cryptocurrencyExchange/cryptoCurrencyExchange.p';
import '../index.css';
import { getCoinInfoByName } from 'shared/helpers/getCoinInfoByName';
import { useTranslate } from '@pankod/refine-core';
import { ICryptoCurrencyConstraintItem } from '../../../../../widgets/order/types';
import { Dropdown, MenuProps } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import styled from 'styled-components';

interface IChoseCurrency {
  onChoseCurrency: (coin: string, network: string | null) => void;
  listCurrencies: ICurrencyOption[];
  isShow: boolean;
  setIsShow: (isShow: boolean) => void;
  title: string;
  subTitle: string;
  inputData: 'enabled' | 'disabled';
  outputData: 'enabled' | 'disabled';
  exchangeData: IExchangeContent;
  availableCoins: string[];
  currencyConstraintList?: ICryptoCurrencyConstraintItem[];
}

const Index: React.FC<IChoseCurrency> = ({
  listCurrencies,
  onChoseCurrency,
  isShow,
  setIsShow,
  title,
  subTitle,
  inputData,
  outputData,
  exchangeData,
  availableCoins,
  currencyConstraintList,
}) => {
  const translate = useTranslate();

  const getNetworksSelect = (
    currencyItem: ICurrencyOption,
    currencyList: ICryptoCurrencyConstraintItem[]
  ): MenuProps['items'] | undefined => {
    const networks =
      currencyList?.find((item) => item.currency === currencyItem.value)
        ?.networks || [];

    const nowNetwork =
      inputData === 'enabled'
        ? exchangeData?.input_network ?? ''
        : exchangeData?.output_network ?? '';

    return [
      {
        key: 1,
        type: 'group',
        label: (
          <LabelStyled className="group-label">
            <Typography.Text>
              {translate('wallet.coin.chooseNetwork')}
            </Typography.Text>
          </LabelStyled>
        ),
        children: networks.map((item) => ({
          label: (
            <ContainerSecondLabelStyled>
              {nowNetwork === item.network && <Border />}
              <ContainerNameStyled className="choose-currency-select-item">
                {item.name}
              </ContainerNameStyled>
              <TextNameStyled className="choose-currency-select-item-subtext">
                {item.description}
              </TextNameStyled>
            </ContainerSecondLabelStyled>
          ),
          value: item.network,
          key: item.network,
          disabled: !item.is_available,
        })),
      },
    ];
  };
  const hasNetworks = (
    currencyItem: ICurrencyOption,
    currencyList: ICryptoCurrencyConstraintItem[]
  ) => {
    return !!currencyList?.find((item) => item.currency === currencyItem.value)
      ?.networks.length;
  };
  const getCurrencyName = (
    currency: string,
    currencyList: ICryptoCurrencyConstraintItem[]
  ) => {
    return (
      currencyList?.find((item) => item.currency === currency)?.name || currency
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimaryHover: '#FBFBFF',
          },
          Dropdown: {
            marginXS: 0,
            colorBgElevated: '#2B2B36',
          },
        },
      }}
    >
      <BasicModal
        title={title}
        open={isShow}
        onCancel={() => setIsShow(false)}
        width={560}
        footer={null}
      >
        <div className={'flex-column gap-24 modal-wrapper'}>
          <Typography.Text className={'text-16px'}>{subTitle}</Typography.Text>
          <Row gutter={[8, 8]}>
            {currencyConstraintList &&
              listCurrencies.map((item) => {
                if (hasNetworks(item, currencyConstraintList)) {
                  return (
                    <Col span={12} key={item.value}>
                      <Dropdown
                        menu={{
                          items: getNetworksSelect(
                            item,
                            currencyConstraintList
                          ),
                          selectable: true,
                          defaultSelectedKeys: [
                            inputData === 'enabled'
                              ? exchangeData?.input_network ?? ''
                              : exchangeData?.output_network ?? '',
                          ],
                          onClick: ({ item: i }) =>
                            //@ts-ignore
                            onChoseCurrency(item.value, i.props.value),
                        }}
                        disabled={
                          (outputData === 'enabled' &&
                            item.value === exchangeData.inputCoin) ||
                          (outputData === 'enabled' &&
                            !availableCoins.includes(item.value))
                        }
                      >
                        <Button>
                          <Space size={8}>
                            <div className="coin-image-wrapper">
                              {
                                getCoinInfoByName(item.label.toLowerCase())
                                  .icon as JSX.Element
                              }
                            </div>
                            <Space size={0} direction="vertical">
                              {getCurrencyName(
                                item.value,
                                currencyConstraintList
                              )}
                              <span className="currency-shadow-text">
                                {item.label}
                              </span>
                            </Space>
                          </Space>
                          <CaretDownOutlined />
                        </Button>
                      </Dropdown>
                    </Col>
                  );
                } else {
                  return (
                    <Col span={12} key={item.value}>
                      <Button
                        disabled={
                          (inputData === 'enabled' &&
                            item.value === exchangeData.inputCoin) ||
                          (outputData === 'enabled' &&
                            item.value === exchangeData.inputCoin) ||
                          (outputData === 'enabled' &&
                            item.value === exchangeData.outputCoin) ||
                          (outputData === 'enabled' &&
                            !availableCoins.includes(item.value))
                        }
                        onClick={() => onChoseCurrency(item.value, null)}
                      >
                        <Space size={8}>
                          <div className="coin-image-wrapper">
                            {
                              getCoinInfoByName(item.label.toLowerCase())
                                .icon as JSX.Element
                            }
                          </div>
                          <Space size={0} direction="vertical">
                            {getCurrencyName(
                              item.value,
                              currencyConstraintList
                            )}
                            <span className="currency-shadow-text">
                              {item.label}
                            </span>
                          </Space>
                        </Space>
                      </Button>
                    </Col>
                  );
                }
              })}
          </Row>
        </div>
      </BasicModal>
    </ConfigProvider>
  );
};

export default Index;

const Border = styled.div`
  background: linear-gradient(25.41deg, #6e27f8 2.1%, #20d9c0 71.42%);
  width: 3px;
  height: 100%;
  position: absolute;
  left: -4px;
  top: 0;
`;

const LabelStyled = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
`;

const ContainerNameStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TextNameStyled = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 400;
`;

const ContainerSecondLabelStyled = styled.div`
  height: 54px;
`;
