import React, { Dispatch, FC, useEffect, useRef, useState } from 'react';

import Down from '../image/chevronDown.svg';
import { ReactComponent as StarFill } from '../image/starFill.svg';
import { ReactComponent as StarActive } from '../image/starActive.svg';
import { ReactComponent as Search } from '../image/search.svg';
import { Input } from 'antd';
import { StatSpot } from '../types';
import useOutsideAlerter from '../useOutsideAlerter';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from '@pankod/refine-react-router-v6';
import { HttpError, useList } from '@pankod/refine-core';
import { ICryptoCurrencyConstraintItem } from '../../../../widgets/order/types';
import { CentrifugeClient } from '../../../../packages/centrifuge-client/centrifuge-client';

interface Props {
  controlsActiveSortPair: [string, Dispatch<string>];
  controlsFilterStar: [boolean, Dispatch<boolean>];
  controlsSearch: [string, Dispatch<string>];
  controlsStars: [string[], Dispatch<string[]>];
  dataSocket: StatSpot[];
}

const Header: FC<Props> = ({
  controlsActiveSortPair: [activeSortPair, setActiveSortPair],
  controlsFilterStar: [filterStar, setFilterStar],
  controlsSearch: [search, setSearch],
  controlsStars: [stars, setStars],
  dataSocket,
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  const [openFullPair, setOpenFullPair] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const wrapperRef = useRef(null);
  const navigate = useNavigate();

  const [activePair, setActivePair] = useState(
    () => localStorage['activePair']
  );

  useOutsideAlerter(wrapperRef, () => {
    setOpenSearch(false);
  });

  function changeActivePair(name: string) {
    const spotSubscription = CentrifugeClient.getSubscription(
      `spot.${activePair}`
    );
    const spotStatSubscription = CentrifugeClient.getSubscription(
      `spotStat.${activePair}`
    );
    const spotChartSubscription = CentrifugeClient.getSubscription(
      `chart.${activePair}`
    );

    if (!!spotSubscription) {
      spotSubscription.unsubscribe();
      spotSubscription.removeAllListeners();
    }

    if (!!spotStatSubscription) {
      spotStatSubscription.unsubscribe();
      spotStatSubscription.removeAllListeners();
    }

    if (!!spotChartSubscription) {
      spotChartSubscription.unsubscribe();
      spotChartSubscription.removeAllListeners();
    }

    const pair = name.replace(new RegExp('/', 'g'), '-');
    setActiveSortPair(name);
    localStorage.setItem('activePair', name);
    // navigate(`/spot/dashboard/${pair}`);
    navigate('/p2p/dashboard');

  }

  const { data: coins, isLoading } = useList<
    ICryptoCurrencyConstraintItem,
    HttpError
  >({
    resource: 'currency-constraint',
    dataProviderName: 'config',
    config: {
      filters: [
        {
          field: 'is_available_spot',
          operator: 'eq',
          value: true,
        },
      ],
    },
  });

  useEffect(() => {
    setActivePair(() => localStorage['activePair']);
  }, [location?.pathname]);

  return (
    <>
      <div className={'pair-container-header'}>
        <button
          style={{ width: 16, height: 16 }}
          onClick={() => setFilterStar(!filterStar)}
          className={'pair-container-header-button'}
        >
          {filterStar ? <StarActive /> : <StarFill />}
        </button>
        {!openSearch ? (
          <button
            onClick={() => {
              setOpenFullPair(false);
              setOpenSearch(!openSearch);
            }}
            style={{ width: 16, height: 16 }}
            className={'pair-container-header-button'}
          >
            <Search />
          </button>
        ) : (
          <div
            id="button-select"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Input
              id="button-select"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{
                backgroundColor: '#2B2B36',
                height: 32,
                border: 'none',
                width: 290,
              }}
              prefix={
                <button
                  onClick={() => setOpenSearch(!openSearch)}
                  style={{ width: 16, height: 16 }}
                  className={'pair-container-header-button'}
                >
                  <Search />
                </button>
              }
            />
          </div>
        )}
        {!openSearch && (
          <div className={'pair-container-header-list-unshow'}>
            {!isLoading &&
              coins?.data &&
              !!coins?.data.length &&
              coins.data.map((item) => (
                <button
                  onClick={() => {
                    setActiveSortPair(item.currency);
                  }}
                  key={item.id}
                  className={'pair-container-header-button'}
                >
                  <span
                    style={
                      activeSortPair === item.currency
                        ? { color: '#FBFBFF' }
                        : undefined
                    }
                    className={'pair-container-header-button-text'}
                  >
                    {item.currency}
                  </span>
                </button>
              ))}
          </div>
        )}
        <button
          onClick={() => {
            setOpenFullPair(!openFullPair);
            setOpenSearch(false);
          }}
          className={'pair-container-header-button'}
        >
          <img
            className={'pair-container-header-image'}
            style={{
              transform: `rotate(${openFullPair ? 180 : 0}deg)`,
              transition: '0.2s',
            }}
            alt={'down'}
            src={Down}
          />
        </button>
      </div>
      {openFullPair && (
        <div className={'pair-container-header-fullPair-container'}>
          {!isLoading &&
            coins?.data &&
            !!coins?.data.length &&
            coins.data.map((item: ICryptoCurrencyConstraintItem) => (
              <button
                onClick={() => setActiveSortPair(item.currency)}
                key={item.id}
                className={'pair-container-header-button'}
              >
                <span
                  style={
                    activeSortPair === item.currency
                      ? { color: '#FBFBFF' }
                      : undefined
                  }
                  className={'pair-container-header-button-text'}
                >
                  {item.currency}
                </span>
              </button>
            ))}
        </div>
      )}
      {openSearch && (
        <div
          ref={wrapperRef}
          style={
            search.length
              ? { alignItems: 'flex-start', justifyContent: 'flex-start' }
              : undefined
          }
          className={'pair-container-header-search'}
        >
          {!search.length ? (
            <span className={'pair-container-header-search-text'}>
              {t('spot.headers.enterCurrencyPair')}
            </span>
          ) : (
            dataSocket
              .filter((itemFind) =>
                itemFind.name.includes(search.toUpperCase())
              )
              .map((item, index) => (
                <div
                  key={index}
                  className={
                    activePair === item.name
                      ? 'pair-content-item-active'
                      : 'pair-content-item'
                  }
                >
                  <div
                    style={{ display: 'flex', gap: 8, alignItems: 'center' }}
                  >
                    <button
                      style={{ width: 16, height: 16 }}
                      onClick={() => {
                        setStars(
                          !stars.includes(item.name)
                            ? stars.concat([item.name])
                            : stars.filter(
                                (itemFilter) => itemFilter !== item.name
                              )
                        );
                      }}
                      className={'pair-container-header-button'}
                    >
                      {stars.includes(item.name) ? (
                        <StarActive />
                      ) : (
                        <StarFill />
                      )}
                    </button>
                  </div>
                  <button
                    onClick={() => changeActivePair(item?.name)}
                    style={{
                      display: 'flex',
                      paddingLeft: 8,
                      paddingRight: 0,
                      justifyContent: 'space-between',
                      width: '100%',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    <span className={'pair-content-item-text-money'}>
                      {item.name}
                    </span>
                    <span className={'pair-content-item-text'}>
                      {item.content.last_price}
                    </span>
                    <span
                      style={{ color: '#58BF92' }}
                      className={'pair-content-item-text'}
                    >
                      {item.content.change_price_24 >= 0 ? '+' : '-'}
                      {item.content.change_price_24} %
                    </span>
                  </button>
                </div>
              ))
          )}
        </div>
      )}
    </>
  );
};

export default Header;
