import React, { useEffect, useState } from 'react';
import { coins } from '../pair/header/data';
import { ReactComponent as Sort } from '../pair/content/image/Sort.svg';
import { ReactComponent as SortUp } from '../pair/content/image/SortUp.svg';
import { ReactComponent as SortDown } from '../pair/content/image/SortDown.svg';
import { enData, ruData } from '../pair/content/data';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from '@pankod/refine-react-router-v6';
import { StatSpot } from '../pair/types';
import { axios } from '../../../shared/exios';
import { API_URL } from '../../../packages/keycloak-client/constants';
import { CentrifugeClient } from '../../../packages/centrifuge-client/centrifuge-client';
import { ReactComponent as StarFill } from '../pair/image/starFill.svg';
import { ReactComponent as StarActive } from '../pair/image/starActive.svg';
import { ConfigProvider, Input } from '@pankod/refine-antd';
import { SearchIcon } from '../../../shared/components/icons';

const SpotDrawerPair = () => {
  // TODO: НУЖНО ФИКСИТЬ
  const { i18n } = useTranslation();
  const location = useLocation();
  const navidate = useNavigate();
  const [activeSortPair, setActiveSortPair] = useState(coins[0]);
  const [pairNames, setPairNames] = useState<string[]>([]);
  const [sort, setSort] = useState<{
    sortName: string;
    up: boolean;
    down: boolean;
  } | null>(null);
  const [activePair, setActivePair] = useState(
    () => localStorage['activePair']
  );
  const [dataSocket, setDataSocket] = useState<StatSpot[]>([]);
  const [filterStar, setFilterStar] = useState(false);
  const [stars, setStars] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  async function getPairs() {
    try {
      const res = await axios.get(`${API_URL}/config/api/pair`);
      const pairs = await res.data;
      const pairsArr = pairs?.data;
      if (!dataSocket?.length) {
        // Список пар с API
        setDataSocket((prevState) => {
          const prev = prevState;
          const arr = [...prev];
          for (const pair of pairsArr) {
            if (pair?.is_available_spot) {
              arr.push({
                name: pair?.pair,
                content: {
                  last_price: 0,
                  last_price_usd: 0,
                  change_price_24: 0,
                  volume_24: 0,
                  main: pair?.ccy_main_name,
                  last: pair?.ccy_last_name,
                },
              });
            }
          }
          return arr;
        });
      }
    } catch (e) {
      console.log('getPairs error');
    }
  }

  function subscribeToSpotStat() {
    // Список пар с сокетов
    const subs = CentrifugeClient.subscriptions();
    if (!subs['spotStat']) CentrifugeClient.subscribeToChannel('spotStat');
    if (!!subs['spotStat']) {
      const spotStatSubscription = CentrifugeClient.getSubscription('spotStat');
      spotStatSubscription
        ?.on('publication', (ctx) => {
          const values = Object.values(ctx.data);
          const result: any = Object.keys(ctx.data).map((item, index) => {
            return {
              name: item,
              content: values[index],
            };
          });
          if (!!result) {
            setDataSocket((prevState) => {
              const commonArr = [...prevState, ...result];
              return [
                // @ts-ignore
                ...new Map(
                  commonArr.map((item) => [item['name'], item])
                ).values(),
              ];
            });
          }
        })
        .subscribe();
      CentrifugeClient.connect();
    }
  }

  async function getDataFromSpotStat() {
    try {
      const res = await axios.get(
        `${API_URL}/${localStorage['activePair']
          .replace('-', '')
          .toLowerCase()}-spot/api/custom-api/spot-stat`,
        {}
      );
      const pairsObj = await res.data;
      const pairArr: any = [];
      for (let [key, value] of Object.entries(pairsObj)) {
        pairArr.push({ content: value, name: key });
      }
      // Список пар с API
      setDataSocket((prevState) => {
        const commonArr = [...prevState];
        for (const pairData of pairArr) {
          commonArr.push(pairData);
        }
        return [
          // @ts-ignore
          ...new Map(commonArr.map((item) => [item['name'], item])).values(),
        ];
      });
      console.log('pairArr', pairArr);
    } catch (e) {
      console.log('getPairs error', e);
    }
  }

  useEffect(() => {
    getPairs().then(() =>
      getDataFromSpotStat().then(() => subscribeToSpotStat())
    );
  }, [activePair]);

  const dataSpot = dataSocket?.filter((itemFind) =>
    itemFind.name.includes(activeSortPair)
  );

  function changeActivePair(name: string) {
    const spotSubscription = CentrifugeClient.getSubscription(
      `spot.${activePair}`
    );
    const spotStatSubscription = CentrifugeClient.getSubscription(
      `spotStat.${activePair}`
    );
    const spotChartSubscription = CentrifugeClient.getSubscription(
      `chart.${activePair}`
    );

    if (!!spotSubscription) {
      spotSubscription.unsubscribe();
      spotSubscription.removeAllListeners();
    }

    if (!!spotStatSubscription) {
      spotStatSubscription.unsubscribe();
      spotStatSubscription.removeAllListeners();
    }

    if (!!spotChartSubscription) {
      spotChartSubscription.unsubscribe();
      spotChartSubscription.removeAllListeners();
    }

    const pair = name.replace(new RegExp('/', 'g'), '-');
    localStorage.setItem('activePair', pair);
    setActivePair(pair);
    navidate(`/spot/dashboard/${pair}`);
  }

  useEffect(() => {
    setActivePair(() => localStorage['activePair']);
    if (i18n.language === 'ru') setPairNames(ruData);
    else setPairNames(enData);
  }, []);

  useEffect(() => {
    setActivePair(() => localStorage['activePair']);
  }, [location?.pathname]);
  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorTextPlaceholder: '#FBFBFF',
          },
        },
      }}
    >
      <div style={{ marginBottom: '24px' }}>
        <Input
          placeholder={'Поиск'}
          prefix={<SearchIcon />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div
        style={{
          borderBottom: '0.50px rgba(64.69, 60.76, 79.69, 0.50) solid',
          width: '100%',
          gap: 8,
          display: 'flex',
          overflowY: 'scroll',
          scrollbarWidth: 'none',
        }}
        className={'mobileSpotPair'}
      >
        {['Избранное', ...coins].map((item) => (
          <button
            onClick={() => setActiveSortPair(item)}
            key={item}
            className={'pair-container-header-button'}
            style={{
              padding: '8px',
              ...(activeSortPair === item
                ? {
                    borderBottom: '2px solid transparent',
                    borderImage:
                      'linear-gradient(25.41deg, #6E27F8 2.1%, #20D9C0 71.42%)',
                    borderImageSlice: 1,
                  }
                : {}),
            }}
          >
            <span
              style={
                activeSortPair === item
                  ? {
                      color: '#FBFBFF',
                    }
                  : undefined
              }
              className={'pair-container-header-button-text'}
            >
              {item}
            </span>
          </button>
        ))}
      </div>
      <div
        style={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {!!pairNames?.length &&
          pairNames.map((item, index) =>
            index === 2 ? (
              <span className={'pair-content-name-text'}>/</span>
            ) : index === 3 ? (
              <button
                key={pairNames[2]}
                onClick={() => {
                  pairNames[2] === sort?.sortName
                    ? setSort({
                        ...sort,
                        up: !sort?.down ? false : !sort?.up || false,
                        down: !!sort?.up ? false : !sort?.down,
                      })
                    : setSort({
                        sortName: pairNames[2],
                        up: false,
                        down: true,
                      });
                }}
                className={'pair-content-name-container-text'}
              >
                <span
                  className={'pair-content-name-text'}
                  style={{ color: '#C3C3CE' }}
                >
                  {pairNames[2]}
                </span>
                {sort?.sortName === pairNames[2] && sort.down ? (
                  <SortDown />
                ) : sort?.sortName === pairNames[2] && sort.up ? (
                  <SortUp />
                ) : (
                  <Sort />
                )}
              </button>
            ) : (
              <button
                style={index === 0 ? { width: '55%' } : undefined}
                key={item}
                onClick={() => {
                  item === sort?.sortName
                    ? setSort({
                        ...sort,
                        up: !sort?.down ? false : !sort?.up || false,
                        down: !!sort?.up ? false : !sort?.down,
                      })
                    : setSort({
                        sortName: item,
                        up: false,
                        down: true,
                      });
                }}
                className={'pair-content-name-container-text'}
              >
                <span
                  className={'pair-content-name-text'}
                  style={{ color: '#C3C3CE' }}
                >
                  {item}
                </span>
                {sort?.sortName === item && sort.down ? (
                  <SortDown />
                ) : sort?.sortName === item && sort.up ? (
                  <SortUp />
                ) : (
                  <Sort />
                )}
              </button>
            )
          )}
      </div>
      <div style={{ marginTop: '16px' }}>
        {!!dataSpot?.length &&
          dataSpot
            .filter((item) =>
              item.name.toLowerCase().includes(search.toLowerCase())
            )
            .map((item, index) =>
              (filterStar && stars.includes(item.name)) || !filterStar ? (
                <div
                  key={index}
                  className={
                    activePair === item.name
                      ? 'pair-content-item-active'
                      : 'pair-content-item'
                  }
                  onClick={() => {
                    // Событие на изменение активной пары
                    changeActivePair(item.name);
                  }}
                  style={{
                    borderBottom: '1px solid #413D5080',
                    height: 'auto',
                    padding: '14.5px 0',
                  }}
                >
                  <div
                    style={{ display: 'flex', gap: 8, alignItems: 'center' }}
                  >
                    <button
                      style={{ width: 16, height: 16 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setStars(
                          !stars.includes(item.name)
                            ? stars.concat([item.name])
                            : stars.filter(
                                (itemFilter) => itemFilter !== item.name
                              )
                        );
                      }}
                      className={'pair-container-header-button'}
                    >
                      {stars.includes(item.name) ? (
                        <StarActive />
                      ) : (
                        <StarFill />
                      )}
                    </button>
                  </div>
                  <button
                    style={{
                      display: 'flex',
                      paddingLeft: 8,
                      paddingRight: 0,
                      justifyContent: 'space-between',
                      width: '100%',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      alignItems: 'center',
                    }}
                  >
                    <span
                      style={{ marginRight: 35, fontSize: '16px' }}
                      className={'pair-content-item-text-money'}
                    >
                      {item?.name?.split('/')[0]}
                      <span
                        style={{
                          color: '#767687',
                          fontSize: 10,
                          fontWeight: '500',
                        }}
                      >
                        /{item?.name?.split('/')[1]}
                      </span>
                    </span>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'end',
                        alignItems: 'end',
                      }}
                    >
                      <span className={'pair-content-item-text'}>
                        {item.content.last_price}
                      </span>
                      <span
                        style={{ color: '#58BF92' }}
                        className={'pair-content-item-text'}
                      >
                        {item.content.change_price_24 >= 0 ? '+' : '-'}
                        {item.content.change_price_24} %
                      </span>
                    </div>
                  </button>
                </div>
              ) : null
            )}
      </div>
    </ConfigProvider>
  );
};

export default SpotDrawerPair;
