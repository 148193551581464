import React from 'react';
import Drawer from '../../../../ui/Drawer';
import Icon from '@ant-design/icons';
import { ShevronIcon } from '../../../../ui/assets/svg/infoIcons';
import { Typography } from 'antd';
import DrawerMenuItem from '../drawer-menu-item';
import { IDrawerMenuItem } from '../../../../../interfaces';

interface IInnerDrawer {
  isOpen: boolean;
  closeThisDrawer: () => void;
  closeAll: () => void;
  title: string;
  drawerItems: IDrawerMenuItem[];
}

const Index: React.FC<IInnerDrawer> = ({
  isOpen,
  closeThisDrawer,
  drawerItems,
  closeAll,
  title,
}) => {
  return (
    <>
      <Drawer
        open={isOpen}
        width={'100%'}
        onClose={closeThisDrawer}
        title={
          <>
            <div className={'flex-row justify-content-space-between'}>
              <Icon
                style={{
                  transform: 'rotate(180deg)',
                }}
                component={() => ShevronIcon({})}
                onClick={() => closeThisDrawer()}
              />
              <Typography.Text
                className={'bold-18px-text'}
                style={{ fontWeight: 500 }}
              >
                {title}
              </Typography.Text>
              <div />
            </div>
          </>
        }
      >
        {drawerItems.map((item, index) => {
          return <DrawerMenuItem {...item} closeAction={closeAll} />;
        })}
      </Drawer>
    </>
  );
};

export default Index;
