import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DatePicker, Tag } from '@pankod/refine-antd';
import Icon from '@ant-design/icons';
import { CaretIcon } from '../../../shared/components/ui/assets/svg/svgIcons';
import { Dropdown, Typography } from 'antd';
import { ChevronDown } from '../../../shared/components/icons';
import { RangeValue } from '../../../pages/p2p/myDeals/cards/myTrades.p';
import dayjs from 'dayjs';
import { IFilterHistory, ISortHistory } from '../ordersTable';
import { useTranslate } from '@pankod/refine-core';


const DateFilterSort: React.FC<{
  sort: ISortHistory;
  setSort(value: ISortHistory): void;
  filter: IFilterHistory;
  setFilter(value: IFilterHistory): void;
}> = ({ sort, setSort, filter, setFilter }) => {
  const translate = useTranslate();
  const [openSortDate, setOpenSortDate] = useState(false);
  const [sortType, setSortType] = useState<'created_at' | 'updated_at'>(
    'created_at'
  );
  const [openCalendar, setOpenCalendar] = useState(false);
  const [rangeValue, setRangeValue] = useState<RangeValue>();
  const [range, setRange] = useState<
    'day' | 'week' | 'month' | '3month' | '6month' | null
  >();
  const handleChangeRange = (tag: typeof range) => {
    setRange(tag);
    if (tag !== undefined) {
      let dates: dayjs.Dayjs[] = [];
      setRangeValue(null);
      switch (tag) {
        case 'day':
          dates = [dayjs(new Date()).subtract(1, 'day'), dayjs(new Date())];
          break;
        case 'week':
          dates = [dayjs(new Date()).subtract(1, 'week'), dayjs(new Date())];
          break;
        case 'month':
          dates = [dayjs(new Date()).subtract(1, 'month'), dayjs(new Date())];
          break;
        case '3month':
          dates = [dayjs(new Date()).subtract(3, 'month'), dayjs(new Date())];
          break;
        case '6month':
          dates = [dayjs(new Date()).subtract(6, 'month'), dayjs(new Date())];
          break;
      }
      const newDates = [
        dates[0]?.format('YYYY-MM-DD'),
        dates[1]?.format('YYYY-MM-DD'),
      ];
      setFilter({
        ...filter,
        created_at: {
          field: 'created_at',
          operator: '>=',
          value: newDates[0],
        },
        created: {
          field: 'created_at',
          operator: '<=',
          value: newDates[1],
        },
      });
    }
  };
  const handleChangeDateSort = (value: 'created_at' | 'updated_at') => {
    setOpenSortDate(false);
    setSortType(value);
    setSort({
      date: {
        field: value,
        order: 'desc',
      },
    });
  };
  const handleRangeDate = (value: RangeValue) => {
    if (value !== null && value[1] !== null) {
      const newDates = [
        value[0]?.format('YYYY-MM-DD'),
        value[1]?.format('YYYY-MM-DD'),
      ];
      setRangeValue(value);
      setRange(null);
      setFilter({
        ...filter,
        created_at: {
          field: 'created_at',
          operator: '>=',
          value: newDates[0],
        },
        created_a: {
          field: 'created_at',
          operator: '<=',
          value: newDates[1],
        },
      });
    }
  };
  return (
    <div
      className={'flex-row flex-start gap-24 align-items-center'}
      style={{ marginBottom: '24px' }}
    >
      <div className={'flex-row gap-16 history-orders-tags'}>
        <Tag.CheckableTag
          checked={'day' === range}
          onChange={() => handleChangeRange('day')}
        >
          {translate('spotOrders.historyFilterSort.day')}
        </Tag.CheckableTag>
        <Tag.CheckableTag
          checked={'week' === range}
          onChange={() => handleChangeRange('week')}
        >
          {translate('spotOrders.historyFilterSort.week')}
        </Tag.CheckableTag>
        <Tag.CheckableTag
          checked={'month' === range}
          onChange={() => handleChangeRange('month')}
        >
          {translate('spotOrders.historyFilterSort.month')}
        </Tag.CheckableTag>
        <Tag.CheckableTag
          checked={'3month' === range}
          onChange={() => handleChangeRange('3month')}
        >
          {translate('spotOrders.historyFilterSort.3month')}
        </Tag.CheckableTag>
        <Tag.CheckableTag
          checked={'6month' === range}
          onChange={() => handleChangeRange('6month')}
        >
          {translate('spotOrders.historyFilterSort.6month')}
        </Tag.CheckableTag>
      </div>
      <div className={'container-range'}>
        <DatePicker.RangePicker
          className={`cursor-pointer range-orders-history ${
            rangeValue === null || rangeValue === undefined
              ? ''
              : 'active-range'
          }`}
          suffixIcon={<Icon component={() => CaretIcon()} />}
          separator={''}
          onOpenChange={setOpenCalendar}
          open={openCalendar}
          value={rangeValue}
          onCalendarChange={handleRangeDate}
        />
        <div
          className={'absolute-range'}
          onClick={() => setOpenCalendar((prevState) => !prevState)}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.5 0C3.77614 0 4 0.223858 4 0.5V1H12V0.5C12 0.223858 12.2239 0 12.5 0C12.7761 0 13 0.223858 13 0.5V1H14C15.1046 1 16 1.89543 16 3V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V3C0 1.89543 0.895431 1 2 1H3V0.5C3 0.223858 3.22386 0 3.5 0ZM2 2C1.44772 2 1 2.44772 1 3V4H15V3C15 2.44772 14.5523 2 14 2H2ZM15 5H1V14C1 14.5523 1.44772 15 2 15H14C14.5523 15 15 14.5523 15 14V5Z"
                fill="#767687"
              />
            </svg>
            <Typography.Text style={{ color: '#767687' }}>
              {translate('spotOrders.historyFilterSort.choiceDate')}
            </Typography.Text>
          </div>
        </div>
      </div>
      <Dropdown
        onOpenChange={setOpenSortDate}
        menu={{
          items: [
            {
              key: 'created_at',
              label: `${translate(
                'spotOrders.historyFilterSort.sort.sort'
              )} ${translate('spotOrders.historyFilterSort.sort.created_at')}`,
              onClick: (info) =>
                handleChangeDateSort(info.key as 'created_at' | 'updated_at'),
            },
            {
              key: 'updated_at',
              label: `${translate(
                'spotOrders.historyFilterSort.sort.sort'
              )} ${translate('spotOrders.historyFilterSort.sort.updated_at')}`,
              onClick: (info) =>
                handleChangeDateSort(info.key as 'created_at' | 'updated_at'),
            },
          ],
          selectable: true,
          defaultSelectedKeys: ['created_at'],
        }}
      >
        <div
          className={'flex-row align-items-center gap-8 spot-sort-date-history'}
        >
          <Typography.Text style={{ color: '#767687' }}>
            {translate('spotOrders.historyFilterSort.sort.sort')}{' '}
            {translate(`spotOrders.historyFilterSort.sort.${sortType}`)}
          </Typography.Text>
          <div
            className={'cursor-pointer flex align-items-center'}
            style={{ rotate: openSortDate ? '180deg' : '0deg' }}
          >
            {ChevronDown('#767687', 12)}
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

export default DateFilterSort;
