import { ICryptoCurrencyConstraintItem } from "widgets/order/types";
import { IFiat } from "../../types";
import { ConfigProvider, Form, Icon, InputNumber, Slider } from "@pankod/refine-antd";
import { ChevronDown } from "shared/components/icons";
import styled from "styled-components";

type TPriceTypeForm = {
    activePrice: number;
    adCurrencyPrice: number;
    chosenCurrency: ICryptoCurrencyConstraintItem | undefined;
    chosenFiat: IFiat | undefined;
    handleChangeAdCurrencyPrice: (value: number) => void;
    isNextBtnClick: boolean | undefined;
}

export const ChoosePriceTypeForm = ({
    activePrice,
    adCurrencyPrice,
    chosenCurrency,
    chosenFiat,
    handleChangeAdCurrencyPrice,
    isNextBtnClick
}: TPriceTypeForm) => {
    const inputStyles = {
        width: '100%',
        backgroundColor: 'transparent',
        color: 'white !important',
        borderRadius: 8,
        height: 32,
        fontSize: 14,
    }
    return (
        <div className="choose-p2p-ad-type-slider-form-wrapper">
            <Slider
                step={Number(((activePrice * 1.3 - activePrice * 0.8) / 100)?.toFixed(2))}
                min={Number(
                    (activePrice ? activePrice * 0.8 : 0)?.toFixed(2)
                )}
                max={Number(
                    (activePrice ? activePrice * 1.3 : 50)?.toFixed(2)
                )}
                value={
                    activePrice ? Number(adCurrencyPrice?.toFixed(2)) : 0
                }
                onChange={(value) =>
                    handleChangeAdCurrencyPrice(Number(value?.toFixed(2)))
                }
                disabled={
                    !(chosenCurrency && chosenFiat && activePrice !== 0)
                }
                tooltip={{ formatter: null }}
            />
            <Form.Item>
                <DivRelative
                    className={'input-number-in-create-ad'}
                >
                    <ConfigProvider
                        theme={{
                            components: {
                                InputNumber: {
                                    colorErrorHover: '#F16063',
                                },
                            },
                        }}
                    >
                        <InputNumber
                            formatter={(value) => {
                                if (value) {
                                    return `${(Math.round(value * 100) / 100).toFixed(2).toString()} ${chosenFiat?.currency ?? ''}`;
                                }
                                return '';
                            }}
                            style={inputStyles}
                            status={(!(chosenCurrency && chosenFiat && activePrice !== 0) && isNextBtnClick === true) ? 'error' : ''}
                            min={activePrice * 0.8}
                            max={activePrice * 1.3}
                            value={
                                chosenCurrency && chosenFiat && activePrice !== 0
                                    ? adCurrencyPrice
                                    : undefined
                            }
                            placeholder={'0.00'}
                            onChange={(value) =>
                                value &&
                                handleChangeAdCurrencyPrice(
                                    parseFloat(value?.toFixed(2))
                                )
                            }
                            parser={(value) => {
                                if (!!chosenFiat?.currency) {
                                    return +value!.replace(chosenFiat.currency, '');
                                }
                                return +value!.replace('', '');
                            }}
                        />
                    </ConfigProvider>
                    <IconsContainer
                        className={
                            'flex justify-content-space-between flex-column input-number-arrows'
                        }
                    >
                        <RotateIcon
                            component={() => ChevronDown('#767687', 10)}
                            onClick={() =>
                                handleChangeAdCurrencyPrice(adCurrencyPrice + 1)
                            }
                        />
                        <BlockIcon
                            component={() => ChevronDown('#767687', 10)}
                            onClick={() =>
                                handleChangeAdCurrencyPrice(adCurrencyPrice - 1)
                            }
                        />
                    </IconsContainer>
                </DivRelative>
            </Form.Item>
        </div>
    )
}

const DivRelative = styled.div`
    position: relative;
`
const BlockIcon = styled(Icon)`
    display: block;
`
const RotateIcon = styled(BlockIcon)`
    rotate: 180deg;
`
const IconsContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 5px;
`