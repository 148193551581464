import React, {useEffect, useMemo, useState} from 'react';
import {
  Button,
  Form,
  Icons,
  Skeleton,
  Tag,
  Typography,
} from '@pankod/refine-antd';
import { ICryptoPair } from './cryptoCurrencyExchange.p';
import CardInput from '../../shared/components/ui/input/cardInput';
import { getCoinInfoByName } from '../../shared/helpers/getCoinInfoByName';
import { useTranslate } from '@pankod/refine-core';
import GradientCard from 'shared/components/ui/gradient-card';
import { ExchangeTradeButton } from 'shared/components/ui/button/exchangeTradeButton';
import './index.css';
import { removeExponenta } from '../../shared/helpers/removeExponenta';
import { ICryptoCurrencyConstraintItem } from '../../widgets/order/types';

const { CaretDownFilled } = Icons;

interface IExchangeCardData {
  inputCoin: string;
  outputCoin: string;
  inputAmount: string;
  outputAmount: string;
  changePrice: (input: string, output: string, type: 'send' | 'give') => void;
  currentPair: ICryptoPair | undefined;
  onClickInputCoins: () => void;
  onClickOutputCoins: () => void;
  onReverseClick: () => void;
  minimumExchangeAmount: number;
  listCurrencies?: ICryptoCurrencyConstraintItem[];
  pairData: ICryptoPair[];
}

const ExchangeCardsContainer: React.FC<IExchangeCardData> = ({
  inputCoin,
  outputCoin,
  currentPair,
  inputAmount,
  outputAmount,
  minimumExchangeAmount,
  changePrice,
  onClickInputCoins,
  onClickOutputCoins,
  onReverseClick,
  listCurrencies,
                                                               pairData
}) => {
  const translate = useTranslate();
  const [form] = Form.useForm();

  const formatValue = (value: string, rounding: number = 8) => {
    value = value.replace(',', '.');
    let intPart = value.split('.')[0];
    let fractionalPart =
      value.split('.').length === 2 ? value.split('.')[1] : '';
    intPart = intPart.slice(0, 20);
    if (fractionalPart && fractionalPart.length > rounding) {
      fractionalPart = fractionalPart.slice(0, rounding);
    }
    value = intPart + (fractionalPart ? '.' : '') + fractionalPart;
    return value === 'null' ? '' : value;
  };

  const handleOnChangeInput = (value: any): void => {
    changePrice(formatValue(String(value)), outputAmount, 'send');
  };
  const handleBlurInput = (e: React.FocusEvent<HTMLInputElement>) => {
    if (+e.target.value < minimumExchangeAmount) {
      changePrice(minimumExchangeAmount.toString(), outputAmount, 'send');
    }
  };
  const handleBlurOutput = (e: React.FocusEvent<HTMLInputElement>) => {
    if (+inputAmount < minimumExchangeAmount) {
      changePrice(minimumExchangeAmount.toString(), outputAmount, 'send');
    }
  };
  const handleOnChangeOutput = (value: any): void => {
    changePrice(inputAmount, formatValue(String(value)), 'give');
  };

  const getCurrencyName = (
    currency: string,
    currencyList: ICryptoCurrencyConstraintItem[]
  ) => {
    return (
      currencyList?.find((item) => item.currency === currency)?.name || currency
    );
  };

  const generalInputProps = () => ({
    formatter: (value: any) => formatValue(value),
    onPressEnter: (event: any) => event.target.blur(),
  });

  useEffect(() => {
    form.setFieldValue('inputAmount', inputAmount);
  }, [form, inputAmount]);

  useEffect(() => {
    form.setFieldValue('outputAmount', outputAmount);
  }, [form, outputAmount]);
  const [parData,setPatData ] = useState<ICryptoPair[]>([])
  const isAvailableReverse = useMemo(() =>{
    return !pairData.find((item) => item.pair === `${outputCoin}/${inputCoin}`)?.hasOwnProperty('pair');
  },[inputCoin,outputCoin,pairData]);

  return (
    <Form form={form}>
      <div className={'exchange-card-container'}>
        <GradientCard enableShadow leftBorderWidth={9} gradientBorder>
          <Typography.Title className="card-title">
            {translate('cryptoExchange.changer.leftCard.title').toUpperCase()}
          </Typography.Title>
          <Skeleton
            loading={
              !minimumExchangeAmount &&
              !inputCoin &&
              !minimumExchangeAmount &&
              !inputCoin
            }
            active={
              !minimumExchangeAmount &&
              !inputCoin &&
              !minimumExchangeAmount &&
              !inputCoin
            }
            style={{ minWidth: '388px', minHeight: '215px' }}
          >
            <Button
              className="choose-currency-card-btn"
              onClick={onClickInputCoins}
            >
              <div className={'white-icon icon currency-icon'}>
                {
                  getCoinInfoByName(inputCoin?.toLowerCase())
                    .icon as JSX.Element
                }
              </div>
              <Typography.Text className="bold-18px-text">
                {`${getCurrencyName(
                  inputCoin,
                  listCurrencies || []
                )} (${inputCoin})`}
              </Typography.Text>
              <CaretDownFilled className="select-caret-icon" />
            </Button>
            <Form.Item name="inputAmount">
              <CardInput
                style={{ display: 'flex', alignItems: 'center', width: '100%' }}
                onBlur={handleBlurInput}
                onChange={handleOnChangeInput}
                {...generalInputProps()}
              />
            </Form.Item>

            <div className="choose-currency-card-tags-container">
              {!!minimumExchangeAmount && !!inputCoin && (
                <>
                  <Tag className="choose-currency-card-tag">
                    <Typography.Text>
                      {`${translate(
                        'cryptoExchange.changer.leftCard.bottomText'
                      )}:`}
                    </Typography.Text>
                  </Tag>
                  <Tag className="choose-currency-card-tag">
                    <Typography.Text>
                      {`${minimumExchangeAmount} ${inputCoin}`}
                    </Typography.Text>
                  </Tag>
                </>
              )}
            </div>
          </Skeleton>
        </GradientCard>
        <ExchangeTradeButton onClick={onReverseClick} disabled={isAvailableReverse}/>
        <GradientCard enableShadow leftBorderWidth={9} gradientBorder>
          <Typography.Title className="card-title">
            {translate('cryptoExchange.changer.rightCard.title').toUpperCase()}
          </Typography.Title>
          <Skeleton
            loading={!outputCoin && !+outputAmount && !currentPair?.price}
            active={!outputCoin && !+outputAmount && !currentPair?.price}
            style={{ minWidth: '388px', minHeight: '215px' }}
          >
            <Button
              className="choose-currency-card-btn"
              onClick={onClickOutputCoins}
              loading={!outputCoin}
            >
              <div className={'white-icon icon currency-icon'}>
                {
                  getCoinInfoByName(outputCoin?.toLowerCase())
                    .icon as JSX.Element
                }
              </div>
              <Typography.Text className="bold-18px-text">
                {`${getCurrencyName(
                  outputCoin,
                  listCurrencies || []
                )} (${outputCoin})`}
              </Typography.Text>
              <CaretDownFilled className="select-caret-icon" />
            </Button>
            <Form.Item name="outputAmount">
              <CardInput
                style={{ width: '100%' }}
                onBlur={handleBlurOutput}
                onChange={handleOnChangeOutput}
                {...generalInputProps()}
              />
            </Form.Item>
            <div className="choose-currency-card-tags-container">
              <Tag className="choose-currency-card-tag">
                <Typography.Text>
                  {`${translate(
                    'cryptoExchange.changer.rightCard.bottomText'
                  )}:`}
                </Typography.Text>
              </Tag>
              <Tag className="choose-currency-card-tag">
                <Typography.Text>
                  {`1 ${inputCoin} = ${removeExponenta(
                    currentPair ? +(+currentPair?.price)?.toFixed(8) : 0
                  )} ${outputCoin}`}
                </Typography.Text>
              </Tag>
            </div>
          </Skeleton>
        </GradientCard>
      </div>
    </Form>
  );
};

export default ExchangeCardsContainer;
