import { NavLink, useLocation } from '@pankod/refine-react-router-v6';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { ACCOUNT_URL } from '../../../../packages/keycloak-client/constants';
import React from 'react';
import { IconComponent, ISidebarElement } from './useGetSideBarElements';
import styled from 'styled-components';

interface Props {
  item: ISidebarElement;
  DrawSideBarMenuElement: (
    title: string,
    icon: IconComponent,
    isSelected: boolean,
    isSubmenu?: boolean | undefined,
    hasChild?: boolean | undefined
  ) => JSX.Element;
}

const MenuItem = ({ item, DrawSideBarMenuElement }: Props) => {
  let loc = useLocation();

  return {
    key: item.pathKey,
    label: (
      <>
        {!!item.onClick ? (
          <ContainerLAbelStyled
            onClick={() => !!item.onClick && item.onClick()}
          >
            {DrawSideBarMenuElement(
              item.title,
              () => item.iconComponent('#767687'),
              false
            )}
          </ContainerLAbelStyled>
        ) : (
          <NavLink to={item.pathKey}>
            <ContainerContentStyled>
              {DrawSideBarMenuElement(
                item.title,
                () => item.iconComponent('#767687'),
                false
              )}
            </ContainerContentStyled>
          </NavLink>
        )}
      </>
    ),
    children: item.childerns
      ? item.childerns.map((item): ItemType => {
          const isSelected = loc.pathname.includes(item.pathKey);
          return {
            //TODO Actual key === child key + item key
            key: item.pathKey,
            onClick: () => {
              const referrerUrl = encodeURIComponent(
                window.location.origin + loc.pathname
              );
              window.location.replace(
                `${item.pathKey}?referrer_url=${referrerUrl}`
              );
            },
            label: (
              <>
                <NavLink
                  to={item.pathKey}
                  replace={
                    item.pathKey.includes(ACCOUNT_URL) && !!ACCOUNT_URL
                  }
                >
                  <ContainerIconStyled>
                    {DrawSideBarMenuElement(
                      item.title,
                      () => item.iconComponent(isSelected ? '' : '#767687'),
                      isSelected,
                      true
                    )}
                  </ContainerIconStyled>
                </NavLink>
              </>
            ),
          };
        })
      : undefined,
  };
};

const ContainerLAbelStyled = styled.div`
  padding-left: 10px;
  height: 40px;
  display: flex;
  cursor: pointer;
`;

const ContainerContentStyled = styled.div`
  padding-left: 10px;
  height: 40px;
  display: flex;
`;

const ContainerIconStyled = styled.div`
  padding-left: 10px;
`;

export default MenuItem;
