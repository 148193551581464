import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { ISpotRowHistory } from '../../../shared/interfaces/spot';
import { useTranslation } from 'react-i18next';
import { axios } from '../../../shared/exios';
import { API_URL } from '../../../packages/keycloak-client/constants';
import { createDateAsUTC } from '../../../shared/helpers/convertTime';
import { useLocation } from '@pankod/refine-react-router-v6';

const SpotTradeHistory: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [activePair, setActivePair] = useState(
    () => localStorage['activePair']
  );

  const [tradeHistory, setTradeHistory] = useState<ISpotRowHistory[]>([]);

  async function getHistorySpot() {
    // TODO: Добавить пагинацию
    try {
      const res = await axios.post(
        `${API_URL}/${localStorage['activePair']
          ?.replace('-', '')
          ?.toLowerCase()}-spot/api/history/search?limit=100&page=1`,
        {
          sort: [{ field: 'created_at', direction: 'desc' }],
        });
      if (!!res?.data?.data?.length) {
        setTradeHistory(
          res?.data?.data?.map(
            (
              item: {
                type: string;
                price: string | number;
                sum: string | number;
                created_at: string | number | Date;
              },
              index: any
            ): ISpotRowHistory => {
              return {
                isBuy: item.type === 'buy',
                price: +item.price,
                amount: +item.sum,
                date: createDateAsUTC(new Date(item.created_at)).toLocaleString(
                  undefined,
                  {
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  }
                ),
              };
            }
          )
        );
      }
    } catch (e) {
      console.log('getHistorySpot error', e);
    }
  }

  useEffect(() => {
    setActivePair(() => localStorage['activePair']);
    setTradeHistory([]);

    getHistorySpot().then();

    const updateDataInterval = setInterval(() => {
      getHistorySpot().then();
    }, 35000);

    return () => {
      clearInterval(updateDataInterval);
    };
  }, []);

  useEffect(() => {
    setActivePair(() => localStorage['activePair']);
    setTradeHistory([]);
    getHistorySpot().then();
  }, [location?.pathname]);

  return (
    <>
      <div className={'flex-column gap-8'}>
        <div
          style={{
            width: '100%',
            display: 'grid',
            gap: 8,
            gridTemplateColumns: '1fr 1fr 1fr',
          }}
        >
          <Typography.Text
            className={'light-bold-12px-text'}
            style={{ color: '#767687', textAlign: 'left' }}
          >
            {`${t('spot.price')} (${activePair?.split('-')[1] || 0})`}
          </Typography.Text>
          <Typography.Text
            className={'light-bold-12px-text'}
            style={{ color: '#767687', textAlign: 'right' }}
          >
            {`${t('spot.volume')} (${activePair?.split('-')[0] || 0})`}
          </Typography.Text>
          <Typography.Text
            className={'light-bold-12px-text'}
            style={{ color: '#767687', textAlign: 'right' }}
          >
            {t('spot.datatime')}
          </Typography.Text>
        </div>
        <div
          style={{
            overflowY: 'auto',
            maxHeight: '600px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {!!tradeHistory?.length &&
            tradeHistory.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    className={'flex-row gap-8 align-items-center'}
                    style={{
                      cursor: 'pointer',
                      height: 24,
                    }}
                  >
                    <Typography.Text
                      className={'light-bold-12px-text'}
                      style={{
                        width: 90,
                        color: item.isBuy ? '#58BF92' : '#F16063',
                        textAlign: 'left',
                      }}
                    >
                      {item?.price || 0}
                    </Typography.Text>
                    <Typography.Text
                      className={'light-bold-12px-text'}
                      style={{ width: 90, textAlign: 'right' }}
                    >
                      {item?.amount?.toFixed(8) || 0}
                    </Typography.Text>
                    <Typography.Text
                      className={'light-bold-12px-text'}
                      style={{ width: 90, textAlign: 'right' }}
                    >
                      {item?.date}
                    </Typography.Text>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export { SpotTradeHistory };
