import React, {FC, ReactNode, useMemo, useState} from 'react';
import {ConfigProvider, Select, SelectProps} from '@pankod/refine-antd';
import {CollapseArrow, SearchIcon} from '../../icons';
import './index.css';
import {DefaultOptionType} from 'rc-select/lib/Select';
import styled from 'styled-components';
import fb from "../../../../packages/fb/fb";

interface Props extends SelectProps {
  data?: DefaultOptionType[];
  setValue?: (value: any) => void;
  onChange?: (value: any) => void;
  onSelect?: (value: any) => void;
  controlHeight?: number;
  value?: any;
  placeholder?: string | React.ReactNode;
  colorBgContainer?: string;
  colorBgElevated?: string;
  style?: React.CSSProperties;
  type?: 'non-border';
  status?: 'error' | 'warning' | '';
  prefixIcon?: ReactNode;
  eventName?: string;
}

const CustomSelect: FC<Props> = ({
                                   data,
                                   setValue,
                                   onChange,
                                   value,
                                   placeholder,
                                   colorBgContainer = 'inherit',
                                   colorBgElevated = '#2B2B36',
                                   style,
                                   type,
                                   controlHeight,
                                   suffixIcon,
                                   status,
                                   prefixIcon,
                                   disabled,
                                   onSelect,
                                   eventName,
                                   ...props
                                 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const handleOpen = (open: boolean) => {
    setIsOpen(open);
  };

  const handleSelect = (value: string) => {
    setValue && setValue(value);
    fb.emit(eventName, value);
  };

  const handleOnEnter = () => {
    setIsHover(true);
  };

  const handleOnLeave = () => {
    setIsHover(false);
  };

  const arrow = useMemo(
    () =>
      isOpen && !type ? (
        <SearchIcon/>
      ) : (
        <div style={{rotate: isOpen ? '0deg' : '180deg'}}>
          <CollapseArrow
            color={!!type ? '#FBFBFF' : isHover ? disabled ? '#767687' : '#FBFBFF' : '#767687'}
          />
        </div>
      ),
    [isOpen, isHover]
  );

  const getSuffixIconRotate = () => {
    return !isOpen ? '0deg' : '180deg'
  }

  return (
    <>
      {
        prefixIcon
          ?
          <SelectWrapper>
            {prefixIcon && <div className="prefix-icon-wrapper">{prefixIcon}</div>}
            <ConfigProvider
              theme={{
                components: {
                  Select: {
                    borderRadius: 8,
                    colorPrimaryHover: !!type ? '#474755' : '#FBFBFF',
                    colorBgElevated,
                    borderRadiusLG: 8,
                    controlItemBgHover: '#474755',
                    controlItemBgActive: '#0E0E13',
                    paddingXXS: 0,
                    colorTextPlaceholder: isHover ? '#FBFBFF' : '#767687',
                    colorTextDisabled: '#767687',
                    borderRadiusSM: 0,
                    borderRadiusXS: 0,
                    controlHeight: controlHeight ? controlHeight : 40,
                    colorBorder: '#474755',
                    colorBgContainer,
                    colorText: '#FBFBFF',
                    colorErrorHover: '#F16063',
                    paddingSM: 16,
                  },
                },
              }}
            >
              <Select
                className={type}
                style={{width: '100%', ...style}}
                onDropdownVisibleChange={handleOpen}
                onChange={onChange ? onChange : handleSelect}
                onSelect={onSelect ? onSelect : handleSelect}
                suffixIcon={
                  suffixIcon
                    ? <SuffixIconRotate $rotate={getSuffixIconRotate()}>
                      {suffixIcon}
                    </SuffixIconRotate>
                    : arrow
                }
                defaultActiveFirstOption={!placeholder}
                value={value === '' ? null : value}
                options={data}
                placeholder={placeholder}
                onMouseEnter={handleOnEnter}
                onMouseLeave={handleOnLeave}
                status={status}
                disabled={disabled}
                filterOption={(input, option) => {
                  if (!!option?.valueC) return new RegExp(input, 'gi').test(option?.valueC as string)
                  return new RegExp(input, 'gi').test(option?.label as string)
                }
                }
                {...(!!type && {dropdownAlign: {offset: [0, -2]}})}
                {...props}
              />
            </ConfigProvider>
          </SelectWrapper>
          : <ConfigProvider
            theme={{
              components: {
                Select: {
                  borderRadius: 8,
                  colorPrimaryHover: !!type ? '#474755' : '#FBFBFF',
                  colorBgElevated,
                  borderRadiusLG: 8,
                  controlItemBgHover: '#474755',
                  controlItemBgActive: '#0E0E13',
                  paddingXXS: 0,
                  colorTextPlaceholder: isHover ? disabled ? '#767687' : '#FBFBFF' : '#767687',
                  colorTextDisabled: '#767687',
                  borderRadiusSM: 0,
                  borderRadiusXS: 0,
                  controlHeight: controlHeight ? controlHeight : 40,
                  colorBorder: '#474755',
                  colorBgContainer,
                  colorText: '#FBFBFF',
                  colorErrorHover: '#F16063',
                  paddingSM: 16,
                },
              },
            }}
          >
            <Select
              className={type}
              style={{width: '100%', ...style}}
              onDropdownVisibleChange={handleOpen}
              onChange={onChange ? onChange : handleSelect}
              onSelect={onSelect ? onSelect : handleSelect}
              suffixIcon={
                suffixIcon
                  ? <SuffixIconRotate $rotate={getSuffixIconRotate()}>
                    {suffixIcon}
                  </SuffixIconRotate>
                  : arrow
              }
              defaultActiveFirstOption={!placeholder}
              value={value === '' ? null : value}
              options={data}
              placeholder={placeholder}
              onMouseEnter={handleOnEnter}
              onMouseLeave={handleOnLeave}
              status={status}
              filterOption={(input, option) => {
                if (!!option?.valueC) return new RegExp(input, 'gi').test(option?.valueC as string)
                return new RegExp(input, 'gi').test(option?.label as string)
              }
              }
              {...(!!type && {dropdownAlign: {offset: [0, -2]}})}
              {...props}
            />
          </ConfigProvider>
      }
    </>
  );
};

export default CustomSelect;

const SelectWrapper = styled.div`
  position: relative;

  .prefix-icon-wrapper {
    position: absolute;
    z-index: 1;
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  && .ant-select .ant-select-selector {
    padding-left: calc(3rem - 8px);
  }

  && .ant-select .ant-select-selection-search {
    margin-left: 26px;
  }
`;

const SuffixIconRotate = styled.div<{ $rotate?: string }>`
  rotate: ${(props) => props.$rotate};
`
