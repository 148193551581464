import React, {Suspense, useEffect} from 'react';
import {Typography} from 'antd';
import {IUseGetPaymentInfoIconData} from '../../../shared/hooks/use-get-payment-info-icon-data';
import {useTranslation} from 'react-i18next';
import styled from "styled-components";
import BankTransferInfo from "./components/BankTransferInfo";
import BankCardInfo from "./components/BankCardInfo";
import WebServiceInfo from "./components/WebServiceInfo";
import MobileInfo from "./components/MobileInfo";
import FastPaymentSystemInfo from "./components/FastPaymentSystemInfo";
import {Spin} from "@pankod/refine-antd";

interface ITransferInfo {
  paymentData: IUseGetPaymentInfoIconData | undefined;
  hideTransferData?: boolean;
  isDealSuccess?: boolean;
  isColumnDisplay: boolean;
  inlineVersion?: boolean;
  isCopy: boolean;
}

const TransferInfo: React.FC<ITransferInfo> = ({
                                                 paymentData,
                                                 hideTransferData,
                                                 isDealSuccess,
                                                 isColumnDisplay,
                                                 isCopy,
                                               }) => {
  const {t} = useTranslation();

  const hideTransferInfo = () => {
    return (
      hideTransferData
        ? <Typography.Text style={{color: '#C3C3CE'}}>
          {t('p2p.transferInfo.sellerDetailsTitle')}
        </Typography.Text>
        : <Typography.Text style={{color: '#C3C3CE'}}>
          {t('p2p.transferInfo.sendToSellerWallet')}
        </Typography.Text>
    )
  }

  const getCardInfoBlur = () => {
    return !!(hideTransferData || isDealSuccess)
  }

  const getCardInfoStyles = () => {
    return (hideTransferData || isDealSuccess) ? "noselect" : undefined
  }

  useEffect(() => {
      console.log('paymentData transferInfo', paymentData)
  }, [paymentData])

  return (
    <TransferInfoCard>
      <CardTitle>
        {hideTransferInfo()}
      </CardTitle>

      <CardInfo
        $isBlur={getCardInfoBlur()}
        className={getCardInfoStyles()}
      >
        {
          paymentData?.bankTransfer &&
          <Suspense fallback={<Spin/>}>
            <BankTransferInfo
              paymentData={paymentData}
              hideTransferData={hideTransferData}
              isDealSuccess={isDealSuccess}
              isColumnDisplay={isColumnDisplay}
              isCopy={isCopy}
            />
          </Suspense>

        }
        {
          paymentData?.bankCard &&
          <Suspense fallback={<Spin/>}>
            <BankCardInfo
              paymentData={paymentData}
              hideTransferData={hideTransferData}
              isDealSuccess={isDealSuccess}
              isColumnDisplay={isColumnDisplay}
              isCopy={isCopy}
            />
          </Suspense>
        }
        {
          paymentData?.webService &&
          <Suspense fallback={<Spin/>}>
            <WebServiceInfo
              paymentData={paymentData}
              hideTransferData={hideTransferData}
              isDealSuccess={isDealSuccess}
              isColumnDisplay={isColumnDisplay}
              isCopy={isCopy}
            />
          </Suspense>
        }
        {
          paymentData?.phone &&
          <Suspense fallback={<Spin/>}>
            <MobileInfo
              paymentData={paymentData}
              hideTransferData={hideTransferData}
              isDealSuccess={isDealSuccess}
              isCopy={isCopy}
            />
          </Suspense>
        }
        {
          paymentData?.sbp &&
          <Suspense fallback={<Spin/>}>
            <FastPaymentSystemInfo
              paymentData={paymentData}
              hideTransferData={hideTransferData}
              isDealSuccess={isDealSuccess}
              isCopy={isCopy}
            />
          </Suspense>
        }
      </CardInfo>
    </TransferInfoCard>
  );
};

export default TransferInfo;

const TransferInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: #2B2B36;
  border-radius: 6px;
  padding: 16px 12px 12px 12px;
  width: 100%;
`;

const CardTitle = styled.div`
  height: 17px;
`;

const CardInfo = styled.div <{ $isBlur: boolean }>`
  filter: ${({$isBlur}) => ($isBlur ? 'blur(4px)' : 'none')};
  display: flex;
  flex-direction: column;
`;
