import React, { ReactNode } from "react";
import {  Modal, ModalProps } from 'antd';

interface Props extends ModalProps {
  children:ReactNode
}
const modal = (props:Props) => {

  return (
    <Modal {...props}>
      {props.children}
    </Modal>
  );
};

export default modal;
