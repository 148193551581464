import React, {useState} from 'react';
import Modal from '../index';
import Button from '../../button';
import {Typography} from 'antd';
import {ButtonsTypes} from '../../button/filledButton.p';
import {Radio} from '@pankod/refine-antd';
import {IPaymentInfo} from '../../../../../pages/p2p/dashboard/interface';
import {useGetPaymentInfoIconData} from '../../../../hooks/use-get-payment-info-icon-data';
import TransferInfo from '../../../../../features/p2p/buyOrder/transferInfo';
import {useTranslation} from 'react-i18next';
import styled from "styled-components";

interface IConfirmPayModal {
  isOpen: boolean;
  onConfirm: () => void;
  closeModal: () => void;
  paymentInfo: IPaymentInfo;
}

const Index: React.FC<IConfirmPayModal> = ({
                                             isOpen,
                                             onConfirm,
                                             closeModal,
                                             paymentInfo,
                                           }) => {
  const {t} = useTranslation();
  const [isClicked, setIsClicked] = useState(false);
  const {paymentData} = useGetPaymentInfoIconData(paymentInfo);

  return (
    <Modal.BasicModal
      title={t('payments.paymentConfirmation')}
      open={isOpen}
      width={560}
      onCancel={closeModal}
      footer={
        <>
          <div className={'flex-row gap-16'}>
            <div style={{width: 284}}>
              <Button
                onClick={() => {
                  onConfirm();
                  closeModal();
                }}
                height={40}
                disabled={!isClicked}
              >
                <Typography.Text>{t('buttons.paymentConfirm')}</Typography.Text>
              </Button>
            </div>
            <div style={{width: 180}}>
              <Button
                onClick={() => {
                  closeModal();
                }}
                type={ButtonsTypes.outlined}
                height={40}
              >
                <Typography.Text>{t('buttons.cancel2')}</Typography.Text>
              </Button>
            </div>
          </div>
        </>
      }
    >
      <>
        <div className={'flex-column gap-24'}>
          <StyledTypography>{t('payments.paymentConfirmDescription')}</StyledTypography>

          <TransferInfo
            isCopy={false}
            paymentData={paymentData}
            isColumnDisplay={true}
          />

          <Radio onClick={() => setIsClicked(true)}>
            <TypographyRadio>
              {t('payments.completedPayment')}
            </TypographyRadio>
          </Radio>
        </div>
      </>
    </Modal.BasicModal>
  );
};

export default Index;

const StyledTypography = styled(Typography)`
  line-height: 19.36px;
  font-weight: 400;
  width: 75%;
`

const TypographyRadio = styled(Typography)`
  line-height: 20px;
  font-weight: 400;
`
