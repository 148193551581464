import React, { useEffect, useState } from 'react';
import Icon from '@ant-design/icons';
import { getIconByCoin } from '../../forms/coin-form/coinForm';
import { Grid, Typography } from 'antd';
import { SpotDivider } from 'shared/components/icons';
import Drawer from '../../../shared/components/ui/Drawer';
import SpotDrawerPair from './spotDrawerPair';
import { useLocation } from '@pankod/refine-react-router-v6';
import { useTranslation } from 'react-i18next';

interface ISpotHeadingPair {
  currentPair: string;
}
const { useBreakpoint } = Grid;

const SpotHeadingCryptoPair: React.FC<ISpotHeadingPair> = ({ currentPair }) => {
  const screens = useBreakpoint();
  const location = useLocation();
  const [activePair, setActivePair] = useState(
    () => localStorage['activePair']
  );
  const { t } = useTranslation();

  useEffect(() => {
    setActivePair(() => localStorage['activePair']);
  }, []);

  useEffect(() => {
    setActivePair(() => localStorage['activePair']);
  }, [location?.pathname]);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (!screens.md) setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setActivePair(() => localStorage['activePair']);
  }, [location?.pathname]);

  return (
    <>
      <div className={'flex-row gap-4 align-items-center'} onClick={handleOpen}>
        <div>
          <div
            style={{
              height: 32,
              width: 32,
              position: 'absolute',
              zIndex: 2,
              background:
                'linear-gradient(25.41deg, #6E27F8 2.1%, #20D9C0 71.42%)',
              opacity: 0.8,
              filter: 'blur(18.5px)',
              borderRadius: 40,
            }}
          ></div>
          <Icon
            className={'mob-display-none'}
            component={() =>
              getIconByCoin(activePair?.split('-')[0]?.toLowerCase(), 32)
            }
          />
          <Icon
            className={'desktop-display-none'}
            style={{ marginRight: '10px' }}
            component={SpotDivider}
          />
        </div>
        <Typography.Text className={'bold-18px-text'}>
          {activePair?.replace(new RegExp('-', 'g'), '/')?.toUpperCase()}
        </Typography.Text>
      </div>
      {!screens.md && (
        <Drawer
          open={open}
          placement={'left'}
          onClose={handleClose}
          title={
            <Typography.Text className={'bold-24px-text'}>
              {t('cryptoPair.tradingPairs')}
            </Typography.Text>
          }
        >
          <SpotDrawerPair />
        </Drawer>
      )}
    </>
  );
};

export { SpotHeadingCryptoPair };
