import React from 'react';
import {styled} from 'styled-components';
import Pair from '../../features/spot/pair';
import SpotPart from './SpotPart';

const SpotPairSelector = () => {
    return (
        <>
            <PairWrapper style={{height: 219}}>
                <Pair/>
            </PairWrapper>
            <SpotWrapper>
                {/*Блок спот*/}
                <SpotPart/>
            </SpotWrapper>
        </>
    );
};

const PairWrapper = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;
const SpotWrapper = styled.div`
  @media (max-width: 767px) {
    border-top: none;
    height: fit-content;
    width: 100%;
    overflow: hidden;
  }
  //&>div{
  //  padding-left: 0!important;
  //}
`;

export {SpotPairSelector};
