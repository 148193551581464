import Styles from './index.module.css';
import { Typography } from '@pankod/refine-antd';
import { TimeMark } from '../time-mark';
import { useTranslation } from 'react-i18next';

interface IChatMessageBadge {
  content?: string;
  date?: Date;
  status: string;
}

const ChatMessageBadge = (props: IChatMessageBadge): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={Styles.message_badge_wrapper}>
      {props.date && (
        <TimeMark
          date={props.date}
          onlyTime={!(props.status === 'respond')}
          bigSize
        />
      )}
      <div className={Styles.message_badge}>
        <Typography.Text className={Styles.message_badge__content}>
          {props.content}
        </Typography.Text>
      </div>
    </div>
  );
};

export { ChatMessageBadge };
