import {
  BitcoinSmallIcon,
  EtherCoinIcon,
  LtcCoinIcon,
  UsdtCoinIcon,
  BnbCoinIcon,
  UsdcCoinIcon,
  MaticCoinIcon,
} from '../components/icons';

interface IGetMoneyIcon {
  name: string;
  color?: string;
  size?: number;
}

const TextCoinWrapper = ({ color, size, name }: IGetMoneyIcon) => <div style={{ color, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: size, lineHeight: `${size}px`, width: size, height: size }}>{name}</div>
export const getMoneyIcon = ({ name, color = '#FBFBFF', size }: IGetMoneyIcon) => {
  switch (name?.toLowerCase()) {
    case 'eur':
      return () => <TextCoinWrapper color={color} size={size} name={'€'}/>;
    case 'usd':
      return () => <TextCoinWrapper color={color} size={size} name={'$'}/>;
    case 'btc':
      return () => BitcoinSmallIcon(color, size);
    case 'usdt':
      return () => UsdtCoinIcon(color, size);
    case 'usdc':
      return () => UsdcCoinIcon(color, size);
    case 'ltc':
      return () => LtcCoinIcon(color, size);
    case 'eth':
      return () => EtherCoinIcon(color, size);
    case 'bnb':
      return () => BnbCoinIcon(color, size);
    case 'matic':
      return () => MaticCoinIcon(color, size);
    case 'rub':
      return () => <TextCoinWrapper color={color} size={size} name={'₽'}/>
    default:
      return () => <></>;
  }
};
