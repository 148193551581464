export interface ICryptoPair {
  id: string;
  pair: string;
  price: string;
  maker_commission: string;
  taker_commission: string;
  external_service_id: string;
}

export interface FieldData {
  name: string | number | (string | number)[];
  value?: string;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

export enum EXCHANGE_FORM_FIELDS_NAMES {
  email = 'email',
  walletAddress = 'walletAddress',
}

export interface IListData {
  title: string;
  value: string;
}

export interface ICurrencyOption {
  label: string;
  value: string;
}

export interface IExchangeContent {
  inputCoin: string;
  outputCoin: string;
  inputAmount: string;
  outputAmount: string;
  input_network: string | null;
  output_network: string | null;
}
