import React, { useEffect, useState } from 'react';
import Select from '../../../shared/components/ui/select';
import { Icons } from '@pankod/refine-antd';
import Icon from '@ant-design/icons';
import { useLocation, useNavigate } from '@pankod/refine-react-router-v6';
import { FullScreenIcon } from '../../../shared/components/icons';
import fb from '../../../packages/fb/fb';

const { DownOutlined } = Icons;

interface ISpotOrderBookControlPanel {
  selectorValue: number;
  currentFilter: number;
  setSelectorValue: (data: number) => void;
  setCurrentFilter: (data: number) => void;
}

const SpotOrderBookControlPanel: React.FC<ISpotOrderBookControlPanel> = ({
  selectorValue,
  currentFilter,
  setSelectorValue,
  setCurrentFilter,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activePair, setActivePair] = useState(
    () => localStorage['activePair']
  );

  useEffect(() => {
    setActivePair(() => localStorage['activePair']);
  }, []);

  useEffect(() => {
    setActivePair(() => localStorage['activePair']);
  }, [location?.pathname]);

  return (
    <>
      <div>
        <div
          className={
            'flex-row justify-content-space-between align-items-center mob-row-reverse mob-content-start'
          }
        >
          <div className={'flex-row gap-16'}>
            <div
              onClick={() => setCurrentFilter(0)}
              style={{
                cursor: 'pointer',
              }}
            >
              <div
                style={{
                  background: currentFilter === 0 ? '#F16063' : '#823A41',
                  width: 16,
                  height: 5,
                  borderRadius: 5,
                  marginBottom: 2,
                }}
              />
              <div
                style={{
                  background: currentFilter === 0 ? '#58BF92' : '#366958',
                  width: 16,
                  height: 5,
                  borderRadius: 5,
                }}
              />
            </div>
            <div
              onClick={() => setCurrentFilter(1)}
              style={{
                cursor: 'pointer',
              }}
            >
              <div
                style={{
                  background: currentFilter === 1 ? '#C3C3CE' : '#767687',
                  width: 16,
                  height: 5,
                  borderRadius: 5,
                  marginBottom: 2,
                }}
              />
              <div
                style={{
                  background: currentFilter === 1 ? '#58BF92' : '#366958',
                  width: 16,
                  height: 5,
                  borderRadius: 5,
                }}
              />
            </div>
            <div
              onClick={() => setCurrentFilter(2)}
              style={{
                cursor: 'pointer',
              }}
            >
              <div
                style={{
                  background: currentFilter === 2 ? '#F16063' : '#823A41',
                  width: 16,
                  height: 5,
                  borderRadius: 5,
                  marginBottom: 2,
                }}
              />
              <div
                style={{
                  background: currentFilter === 2 ? '#C3C3CE' : '#767687',
                  width: 16,
                  height: 5,
                  borderRadius: 5,
                }}
              />
            </div>
          </div>
          <div className={'flex-row gap-4'}>
            <Select
              dropdownStyle={{
                minWidth: 70,
              }}
              controlHeight={32}
              style={{
                paddingInlineEnd: 2,
                height: 15,
                lineHeight: '10px !important',
                paddingBottom: 6,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              bordered={false}
              value={selectorValue}
              suffixIcon={
                <DownOutlined style={{ color: '#767687', fontSize: 12 }} />
              }
              setValue={(e) => {
                setSelectorValue(e);
                fb.emit('changeOrderBookRound', e);
              }}
              data={[
                { label: '0.01', value: 2 },
                { label: '0.1', value: 1 },
                { label: '1', value: 0 },
              ]}
            />
            <Icon
              className="mob-display-none"
              onClick={() => {
                const pair = activePair.replace(new RegExp('/', 'g'), '-');
                navigate(`/spot/dashboard/order-book-expanded/${pair}`);
              }}
              style={{ cursor: 'pointer' }}
              component={() => <FullScreenIcon size={16} />}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { SpotOrderBookControlPanel };
