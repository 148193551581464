import React from 'react';
import {IListData} from '../cryptocurrencyExchange/cryptoCurrencyExchange.p';
import {Tooltip, Typography} from '@pankod/refine-antd';
import {ReactComponent as SquareQuestionIcon} from '../../shared/components/ui/assets/svg/squareQuestionIcon.svg';
import {useTranslate} from '@pankod/refine-core';
import {IExchangeOrderDataCard} from './order.p';

interface IList {
  items: IListData[];
  commissionData: Pick<IExchangeOrderDataCard, 'outputCommission' | 'networkCommission'>,
  inputPrice: number,
  inputCurrency: string;
}

const ExchangeDataCardList: React.FC<IList> = ({items, inputCurrency, commissionData, inputPrice}) => {
  const translate = useTranslate()
  const makerCommissionPercent = commissionData.outputCommission ? +commissionData.outputCommission / inputPrice : 0
  return (
    <ul
      className={'flex-column ul-exchange'}
      style={{marginBottom: 0, paddingLeft: 1, gap: '16px'}}
    >
      {items.map((item, index) => {
        return (
          <li key={index} className={'round-list-type'}>
            <div style={{alignItems: 'center'}} className={'flex-row gap-8'}>
              <Typography.Text className={'bold-14px-text'}>
                {item.title}
              </Typography.Text>
              <div style={{color: '#FBFBFF'}}>&#10142;</div>
              <Typography.Text className={'bold-16px-text'}>
                {item.value}
              </Typography.Text>
              {!index &&
                <Tooltip overlayInnerStyle={{minWidth: 'max-content'}} placement='bottomRight'
                         title={
                           <div>
                             <div style={{display: 'flex'}}>
                               {translate('cryptoExchange.changer.formData.helperText.serviceCom')} {`${makerCommissionPercent.toFixed(2)}%`} &#10142; &nbsp;
                               <b>{commissionData.outputCommission ?? 0} {inputCurrency}</b>
                             </div>
                             <div style={{display: 'flex'}}>
                               {translate('cryptoExchange.changer.formData.helperText.networkCom')} &#10142; &nbsp;
                               <b>{commissionData.networkCommission ?? 0} {inputCurrency}</b>
                             </div>
                           </div>
                         }>
                  <SquareQuestionIcon style={{cursor: 'help', fill: '#474755'}}/>
                </Tooltip>}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default ExchangeDataCardList;
