import React from 'react';
import {createRoot} from 'react-dom/client';
import {ConfigProvider} from '@pankod/refine-antd';
import './app/i18n';
import './shared/fonts/index.css';
import {mainTheme} from './shared/components/ui/themes';
import './shared/components/ui/styles.css';
import App from './app/App';
import {FullScreenLoader} from './shared/components/full-screen-loader';

if (!localStorage['activePair']) {
    localStorage.setItem('activePair', 'ETH-BTC');
}

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
    <React.Suspense fallback={<FullScreenLoader/>}>
        <ConfigProvider theme={mainTheme}>
            <App/>
        </ConfigProvider>
    </React.Suspense>
);
