import { Centrifuge } from 'centrifuge';
import { WS_URL } from './../keycloak-client/constants';

const centrifuge = new Centrifuge(WS_URL);

const _disconnect = () => centrifuge.disconnect();

const _connect = () => centrifuge.connect();

const subscribeToChannel = (channel) => {
  centrifuge.newSubscription(channel);
};

const subscriptions = () => {
  console.log('subscriptions:', centrifuge.subscriptions());
  return centrifuge.subscriptions();
};

const getSubscription = (channel) => {
  return centrifuge.getSubscription(channel);
};

const removeSubscription = (channel) => {
  return centrifuge.removeSubscription(channel);
};

export const CentrifugeClient = {
  disconnect: _disconnect,
  connect: _connect,
  centrifuge,
  subscriptions,
  subscribeToChannel,
  getSubscription,
  removeSubscription,
};
