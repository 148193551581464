import {Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {BasicModal, BasicModalProps} from '../basicModal';
import {Button, Typography} from '@pankod/refine-antd';
import {IPaymentType} from 'features/p2p/createOrder/types';
import {getPaymentInfoData} from 'shared/helpers/getPaymentInfoData';
import {ReactComponent as EmptyPaymentTypesIcon} from '../../../ui/assets/svg/emptyPaymentTypes.svg';
import './index.css';
import {useTranslation} from 'react-i18next';
import {IPaymentsType} from '../../../../../pages/p2p/dashboard/interface';
import {styled} from 'styled-components';
import VisibilityObserver from '../../../../hooks/on-visible/observer';
import {GetListResponse} from '@pankod/refine-core';

interface IAddPaymentTypeModalProps
    extends Omit<BasicModalProps, 'children' | 'footer' | 'width' | 'onCancel'> {
    userPaymentTypes: Array<IPaymentType>;

    handleClickAddNew(): void;

    handleClickAddChosenPaymentTypes(payments: Array<IPaymentType>): void;

    userPaymentList: Array<IPaymentType>;

    onCancel(): void;

    iconsData?: IPaymentsType[];

    setPage: Dispatch<SetStateAction<number>>;

    userPaymentListAPI?: GetListResponse<IPaymentType>;
}

const MAX_PAYMENTS_ARRAY_LENGTH = 5;

const AddPaymentTypeSell: FC<IAddPaymentTypeModalProps> = ({
                                                               userPaymentTypes,
                                                               userPaymentList,
                                                               handleClickAddNew,
                                                               handleClickAddChosenPaymentTypes,
                                                               onCancel,
                                                               iconsData = [],
                                                               setPage,
                                                               userPaymentListAPI,
                                                               ...modalProps
                                                           }) => {
    const {t} = useTranslation();

    const [chosenPayments, setChosenPayments] = useState<Array<IPaymentType>>([]);
    const onClose = () => {
        setChosenPayments(userPaymentList);
        onCancel();
    };
    useEffect(() => {
        if (chosenPayments.length === 0) {
            setChosenPayments(userPaymentList);
        } else {
            setChosenPayments((state) =>
                state.filter((item) => userPaymentList.includes(item) && item)
            );
        }
    }, [userPaymentList]);

    const cardPayment = (payment: IPaymentType, listItem: any) => (
        <div
            key={payment.id}
            className={`p2p-create-ad-choose-payment-type-item ${
                chosenPayments.map((i) => i.id).includes(payment.id)
                    ? 'selected-item'
                    : ''
            } ${
                chosenPayments.length === MAX_PAYMENTS_ARRAY_LENGTH &&
                !chosenPayments.map((i) => i.id).includes(payment.id)
                    ? 'disabled-item '
                    : ''
            }`}
            onClick={() => {
                if (
                    chosenPayments.length === MAX_PAYMENTS_ARRAY_LENGTH &&
                    !chosenPayments.map((i) => i.id).includes(payment.id)
                ) {
                    return;
                }
                setChosenPayments((state) =>
                    state.map((i) => i.id).includes(payment.id)
                        ? state.filter((p) => p.id !== payment.id)
                        : [...state, payment]
                );
            }}
        >
            <div className="icon-container">
                <img
                    alt={listItem.title}
                    style={{width: 24, height: 24}}
                    src={listItem.icon}
                />
            </div>
            <div className="right-part">
                <Typography
                    style={{fontSize: 14}}>{!t(`banks.${listItem.title}`).includes('.') ? t(`banks.${listItem.title}`) : listItem.title}</Typography>
                <Typography style={{fontSize: 14}}>{listItem.value}</Typography>
                <Typography style={{fontSize: 14, color: '#767687'}}>
                    {listItem.subtext}
                </Typography>
            </div>
        </div>
    );

    const handleNextPage = (visibility: boolean) => {
        if (visibility && userPaymentListAPI?.total !== userPaymentTypes.length) {
            setPage((prevState) => prevState + 1);
        }
    };

    return (
        <StyledModal
            width={560}
            {...modalProps}
            onCancel={onClose}
            title={
                modalProps.title + ` (${chosenPayments.length}` +
                `/${MAX_PAYMENTS_ARRAY_LENGTH})`
            }
            footer={
                <div className="custom-modal-footer">
                    <Button
                        type="primary"
                        disabled={
                            chosenPayments.length > MAX_PAYMENTS_ARRAY_LENGTH ||
                            userPaymentTypes.length === 0
                        }
                        onClick={() => {
                            handleClickAddChosenPaymentTypes(chosenPayments);
                            onCancel();
                        }}
                    >
                        {t('payments.saveChoose')}
                    </Button>
                    <Button
                        type="dashed"
                        onClick={() => {
                            handleClickAddNew();
                        }}
                    >
                        {t('payments.addNew')}
                    </Button>
                </div>
            }
        >
            {userPaymentTypes.length ? (
                <div style={{display: 'flex', gap: 32, flexDirection: 'column'}}>
                    <Typography style={{marginBottom: '8px'}}>
                        {t('p2p.chooseLimitsAndPaymentsType.selectPaymentMethods')}
                    </Typography>
                    <PaymentCardWrapper>
                        {userPaymentTypes.map((payment, index) =>
                            getPaymentInfoData(payment, t, iconsData).map((listItem) =>
                                index === userPaymentTypes.length - 1 ? (
                                    <VisibilityObserver
                                        onChangeVisibility={handleNextPage}
                                        options={{rootMargin: '100px'}}
                                    >
                                        {cardPayment(payment, listItem)}
                                    </VisibilityObserver>
                                ) : (
                                    <>{cardPayment(payment, listItem)}</>
                                )
                            )
                        )}
                    </PaymentCardWrapper>
                </div>
            ) : (
                <div className="p2p-create-ad-empty-payment-types-wrapper">
                    <EmptyPaymentTypesIcon/>
                    <Typography.Text style={{color: '#767687'}}>
                        {t('payments.nonePaymentMethod')}
                    </Typography.Text>
                </div>
            )}
        </StyledModal>
    );
};

export default AddPaymentTypeSell;

const StyledModal = styled(BasicModal)`
    max-height: 711px !important;

    .ant-modal-title {
        margin-bottom: 16px !important;
    }
`;

const PaymentCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    width: 100%;
    max-height: 434px;
    overflow-y: auto;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: none;
    }

    &::-webkit-scrollbar-thumb {
        background: #F3F3F8;
        border-radius: 4px;
    }
`
