import ConfirmWithdrawModal from './confirmWithdrawModal';
import { BasicModal } from './basicModal';
import ChoseCurrency from './choseCurrency';
import { CancelOrder } from './cancelOrder';
import { CancelReplenishedOrder } from './cancelReplenishedOrderModal';
import { NicknameModal } from './userNickname';
import { AppealModal } from './appealModal';
import ConfirmPay from './confirmPay';
import ConfirmAcceptPay from './confirmAcceptPay';
import AddPaymentTypeBuy from "./addPaymentTypeBuy";
import AddPaymentTypeSell from "./addPaymentTypeSell";
import ConfirmCloseAppealModal from './confirmCloseAppealModal'
import { ConfirmSetDefaultAdPrice } from './confirmSetDefaultAdPrice';


export default {
  ConfirmAcceptPay,
  ConfirmPay,
  ConfirmWithdrawModal,
  BasicModal,
  ChoseCurrency,
  CancelOrder,
  CancelReplenishedOrder,
  NicknameModal,
  AppealModal,
  AddPaymentTypeSell,
  AddPaymentTypeBuy,
  ConfirmCloseAppealModal,
  ConfirmSetDefaultAdPrice
};
