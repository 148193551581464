import React, {Dispatch, ReactNode, useEffect, useState} from 'react';
import './styles.css';
import {Input} from 'antd';
import Caret from './image/caret.svg';
import {PayService} from '../../../../../pages/p2p/settings/payment/payment';
import {useCustom} from '@pankod/refine-core';
import {BanksResponse} from '../../../../../pages/p2p/settings/payment/types';
import CustomSelect from '../../../../../shared/components/ui/select';
import {useTranslation} from 'react-i18next';
import Comment from '../Comment';
import CardSvg from './image/Card.svg';
import {IPaymentsType} from '../../../../../pages/p2p/dashboard/interface';
import {PaymentTypeEnum} from '../../../../p2p/createOrder/types';
import fb from "../../../../../packages/fb/fb";

interface Props {
    controlsTemplatePay: [PayService, Dispatch<PayService>];
    setIsValid: Dispatch<boolean>;
    clickSubmit: boolean;
    errorHasPayment: boolean;
    iconsData?: IPaymentsType[];
}

const Card = ({
                  controlsTemplatePay: [templatePay, setTemplatePay],
                  setIsValid,
                  clickSubmit,
                  errorHasPayment,
                  iconsData = [],
              }: Props) => {
    const {t} = useTranslation();

    const {data} = useCustom<BanksResponse>({
        url: '/p2p/api/banks/search?limit=25',
        method: 'post',
        config: {
            payload: {
                sort: [
                    {
                        field: 'name',
                        order: 'asc',
                    },
                ],
            },
        },
    });

    const [dataItems, setDataItems] = useState<
        { value: string; label: ReactNode }[]
    >([]);
    const [openDrop, setOpenDrop] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const selectListener = fb.addListener('selectCardBank', (data: any) => {
        })
        return () => {
            selectListener.remove()
        }
    }, []);
    const onChange = (value: string, key: keyof PayService) => {
        setTemplatePay({
            ...templatePay,
            [key]: value,
        });
    };

    useEffect(() => {
        setError(
            (() => {
                if (templatePay.num && templatePay.num?.length < 19) return 'invalid';
                return templatePay.service === ''
                    ? 'bank'
                    : !templatePay.num ||
                    (templatePay.num &&
                        (templatePay.num?.length <= 12 ||
                            templatePay.num.length > 20 ||
                            isNaN(parseInt(templatePay.num, 10))))
                        ? 'num'
                        : templatePay.comment && templatePay.comment.length > 40
                            ? 'comment'
                            : '';
            })()
        );
    }, [templatePay, clickSubmit]);

    useEffect(() => {
        setIsValid(error === '');
    }, [error]);

    useEffect(() => {
        let result: { value: string; label: ReactNode }[] = [];
        data?.data.data.forEach((item) => {
            result.push({
                value: item.id.toString(),
                label: (
                    <div className={'bank-selector-item'}>
                        <img
                            style={{width: 16, height: 16}}
                            src={`data:image/svg+xml;base64,${
                                iconsData
                                    .find(
                                        (item) =>
                                            item.payment_type === PaymentTypeEnum.bankCardPayment
                                    )
                                    ?.items?.find((payment) => payment.name === item.name)?.icon
                            }`}
                        />
                        {t(`banks.${item.name}`)}
                    </div>
                ),
            });
        });
        setDataItems(result);
    }, [data]);

    useEffect(() => {
        setTemplatePay({
            email: '',
            comment: '',
            service: '',
            num: '',
            bik: '',
            id: '',
        });
    }, []);

    return (
        <div>
            <span className={'bank-title'}>{t('payments.bankCard')}</span>
            {errorHasPayment && (
                <span style={{marginTop: 4}} className={'bank-input-label-error'}>
          {t('payments.errorAddPayment')}
        </span>
            )}
            <div className={'bank-form'}>
                <div className={'bank-container-input'}>
                    <span className={'bank-input-label'}>{t('payments.chooseBank')}</span>
                    <CustomSelect
                        data={dataItems}
                        status={error === 'bank' && clickSubmit ? 'error' : undefined}
                        onSelect={(value: any) => onChange(value, 'service')}
                        onDropdownVisibleChange={() => setOpenDrop(!openDrop)}
                        placeholder={t('payments.bank').toString()}
                        eventName={'selectCardBank'}
                        style={{borderRadius: 8}}
                        suffixIcon={
                            <img
                                style={{
                                    transform: `rotate(${openDrop ? 0 : 180}deg)`,
                                    transition: '0.2s',
                                }}
                                alt={'caret'}
                                src={Caret}
                            />
                        }
                        dropdownStyle={{
                            background: '#474755',
                            border: '1px solid #474755',
                            borderRadius: 8,
                        }}
                    />
                    {error === 'bank' && clickSubmit && (
                        <span className={'bank-input-label-error'}>
              {t('payments.chooseBank')}
            </span>
                    )}
                </div>
                <div className={'bank-container-input'}>
          <span className={'bank-input-label'}>
            {t('payments.enterBankCardNumber').toString()}
          </span>
          <Input
            status={error === 'num' && clickSubmit ? 'error' : undefined}
            style={{paddingLeft: 38}}
            value={templatePay.num}
            onChange={(e) => {
              let value = e.target.value;
              value = value.replace(/\D/g, '');
              if (value.length > 16) {
                value = value.slice(0, 16);
              }
              value = value.replace(/(.{4})/g, '$1 ');
              if (e.target.value.replace(/\s/g, '').length <= 16) {
                onChange(value.trim(), 'num');
              }
            }}
            //TODO: Добавить проверку кол-во знаков в номере карты (когда добавят новые банки)
            placeholder={'XXXX XXXX XXXX XXXX'}
          />
          <img className={'bank-input-prefixIcon'} alt={'card'} src={CardSvg}/>
          {error === 'num' && clickSubmit && (
            <span className={'bank-input-label-error'}>
              {templatePay.num === ''
                  ? t('payments.enterBankCardNumber')
                  : t('payments.errorBankAccountNumber')}
            </span>
                    )}
                    {error === 'invalid' && clickSubmit && (
                        <span className={'bank-input-label-error'}>
              {t('payments.errorBankAccountNumber')}
            </span>
                    )}
                </div>
                <Comment
                    templatePay={templatePay}
                    error={error}
                    clickSubmit={clickSubmit}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};
export default Card;
