import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import {Tabs, Typography} from 'antd';
import {Collapse, ConfigProvider, Input} from '@pankod/refine-antd';
import {
  ArrowBack,
  CheckIcon,
  ChevronRight,
  CloseIcon,
  CustomDropdownIconSimpleColor,
  Filter,
  FilterSearchIcon,
} from '../../../../shared/components/icons';
import {GetListResponse, HttpError, QueryObserverResult, useTranslate,} from '@pankod/refine-core';
import {IBankTemp, ICurrencyTemp, IFiatTemp, IGetListAdData, IRegionTemp,} from './interface';
import {IFilterAd} from './index';
import useDebounce from '../../../../shared/hooks/use-debounce';
import * as _ from 'lodash';
import MobileDrawer from './mobileFilters';
import {styled} from 'styled-components';
import FilledButton from '../../../../shared/components/ui/button';
import './index.css';
import {useTranslation} from "react-i18next";

export interface IWantData {
    data: QueryObserverResult<GetListResponse<IGetListAdData>, HttpError>;
    regions?: IRegionTemp[];
    filter: IFilterAd;
    initFilter: IFilterAd;

    setFilter(value: IFilterAd): void;

    fiats?: IFiatTemp[];
    banks?: IBankTemp[];
    currency?: ICurrencyTemp[];
}

const Want: FC<IWantData> = ({
                                 data,
                                 regions = [],
                                 filter,
                                 setFilter,
                                 initFilter,
                                 fiats = [],
                                 banks = [],
                                 currency = [],
                             }) => {
    const {t} = useTranslation();
    const [bank, setBank] = useState<string[]>(['all']);
    const [bankBuy, setBankBuy] = useState<string[]>(['all']);
    const [summ, setSumm] = useState('');
    const debouncedValue = useDebounce(summ, 500);

    useEffect(() => {
        if (summ === '') {
            setFilter(_.omit(filter, 'summ'));
        } else {
            setFilter({
                ...filter,
                summ: {
                    key: 'sum',
                    operator: 'and',
                    value: [
                        {
                            field: 'min_sum_limit',
                            operator: 'lte',
                            value: +debouncedValue,
                        },
                        {
                            field: 'max_sum_limit',
                            operator: 'gte',
                            value: +debouncedValue,
                        },
                    ],
                },
            });
        }
    }, [debouncedValue]);
    const translate = useTranslate();

    const handleClearFilter = () => {
        setBank(['all']);
        setBankBuy(['all']);
        setSumm('');
        setFilter(initFilter);
    };

    const handleChangeSumm = (e: ChangeEvent<HTMLInputElement>) => {
        setSumm(
            e.target.value === '' ? '' : e.target.value.replace(/[^\d.-]/g, '')
        );
    };

    const handleChangeCrypto = (value: string) => {
        setFilter({
            ...filter,
            ccy: {
                field: 'ccy',
                operator: 'eq',
                value: value?.split('-')[0],
            },
        });
    };

    const [countryName, setCountryName] = useState('');
    const handleChangeRegion = (value: number, regions: any[]) => {
        const countryName = regions.find(
            (item) => item.id === filter.region_id?.value
        );
        setCountryName(countryName?.name || '');
        if (value === 1) {
            setFilter(_.omit(filter, 'region_id'));
        } else {
            setFilter({
                ...filter,
                region_id: {
                    field: 'region_id',
                    operator: 'eq',
                    value,
                },
            });
        }
    };

    const handleChangeFiat = (value: string) => {
        setFilter({
            ...filter,
            fiat: {
                field: 'fiat',
                operator: 'eq',
                value,
            },
        });
    };

    interface PaymentMethod {
        method: string;
    }

    const [paymentMethod] = useState<PaymentMethod>({
        method: '',
    });

    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const handleOpenFilters = async () => {
        setIsOpenFilters(() => !isOpenFilters);
    };

    const [isOpenFiatType, setIsOpenFiatType] = useState(false);
    const handleOpenFiatType = async () => {
        setIsOpenFiatType(() => !isOpenFiatType);
    };

    const [isOpenSummFilter, setIsOpenSummFilter] = useState(false);
    const handleOpenSummFilter = async () => {
        setIsOpenSummFilter(() => !isOpenSummFilter);
    };

    const [isOpenPaymentFilter, setIsOpenPaymentFilter] = useState(false);
    const handleOpenPaymentFilter = async () => {
        setIsOpenPaymentFilter(() => !isOpenPaymentFilter);
    };

    const [isOpenCountryFilter, setIsOpenCountryFilter] = useState(false);
    const handleOpenCountryFilter = async () => {
        setIsOpenCountryFilter(() => !isOpenCountryFilter);
    };

    const testData = [
        {
            label: 'YooMoney',
            value: 'YooMoney',
        },
        {label: 'QIWI', value: 'QIWI'},
        {label: 'Payeer', value: 'Payeer'},
        {label: 'AdvCash', value: 'AdvCash'},
    ];

    return (
        <ConfigProvider
            theme={{
                components: {
                    Tabs: {
                        colorBorderSecondary: 'inherit',
                        colorPrimary:
                            'linear-gradient(25.41deg, #6E27F8 2.1%, #20D9C0 71.42%)',
                        paddingSM: 8,
                    },
                    Input: {
                        colorBorder: '#474755',
                        colorPrimaryHover: '#FBFBFF',
                        colorError: '#E94D4D',
                        colorErrorBorderHover: '#E94D4D',
                    },
                },
            }}
        >
            <div>
                <div>
                    <div style={{position: 'relative'}}>
                        <Tabs
                            activeKey={
                                !!filter.ccy?.value ? filter.ccy?.value : initFilter.ccy?.value
                            }
                            onChange={handleChangeCrypto}
                            items={currency.map((item) => ({
                                key: item.currency,
                                label: (
                                    <span className={'currencyCrypto'}>{item.currency}</span>
                                ),
                            }))}
                        />
                    </div>
                </div>
                <FilterWrapper onClick={handleOpenFilters}>
                    <Filter/>
                </FilterWrapper>
                {isOpenFilters && (
                    <>
                        <MobileDrawer>
                            <>
                                <MobileDrawerWrapper>
                                    <div>
                                        <TitleWrapper>
                                            <div>
                                                <div onClick={handleOpenFilters}>
                                                    <ArrowBack/>
                                                </div>
                                                <TypographyStyled
                                                    $fontSize={24}
                                                    $fontWeight={700}
                                                >
                                                    {translate('dashboard.filters.title.filters')}
                                                </TypographyStyled>
                                            </div>
                                            <div>
                                                <Typography.Link
                                                    onClick={handleClearFilter}
                                                    style={{marginRight: '8px'}}
                                                >
                                                    {translate('dashboard.filters.clearMobile')}
                                                </Typography.Link>
                                            </div>
                                        </TitleWrapper>
                                        <MainContent>
                                            <>
                                                {/* FIAT TYPE */}
                                                <MenuCard onClick={handleOpenFiatType}>
                                                    <MenuLeftSide>
                                                        <MenuTitle>
                                                            {translate('dashboard.filters.title.fiatType')}
                                                        </MenuTitle>
                                                        <MenuValue>{filter.fiat?.value}</MenuValue>
                                                    </MenuLeftSide>
                                                    <ChevronRight/>
                                                </MenuCard>
                                                {isOpenFiatType && (
                                                    <MobileDrawer>
                                                        <MobileDrawerWrapper>
                                                            <div>
                                                                <TitleWrapper>
                                                                    <div>
                                                                        <div onClick={handleOpenFiatType}>
                                                                            <ArrowBack/>
                                                                        </div>
                                                                        <TypographyStyled
                                                                            $fontSize={24}
                                                                            $fontWeight={700}
                                                                        >
                                                                            {translate(
                                                                                'dashboard.filters.title.fiatType'
                                                                            )}
                                                                        </TypographyStyled>
                                                                    </div>
                                                                    <DivStyled onClick={handleOpenFiatType}>
                                                                        <CloseIcon/>
                                                                    </DivStyled>
                                                                </TitleWrapper>

                                                                {fiats.map((fiat) => (
                                                                    <FilterMenuCard
                                                                        onClick={() => handleChangeFiat(fiat.currency)}
                                                                    >
                                                                        <MenuLeftSide>
                                                                            <MenuValue>{fiat.currency}</MenuValue>
                                                                        </MenuLeftSide>
                                                                        {fiat.currency === filter.fiat?.value && (
                                                                            <CheckIcon/>
                                                                        )}
                                                                    </FilterMenuCard>
                                                                ))}
                                                            </div>
                                                            <div>
                                                                <FilledButton onClick={handleOpenFiatType}>
                                                                    {translate('dashboard.filters.buttons.completed')}
                                                                </FilledButton>
                                                            </div>
                                                        </MobileDrawerWrapper>
                                                    </MobileDrawer>
                                                )}
                                            </>
                                            <>
                                                {/* SUMM INPUT */}
                                                <MenuCard onClick={handleOpenSummFilter}>
                                                    {summ === '' ? (
                                                        <>
                                                            <MenuLeftSide>
                                                                <MenuValue>
                                                                    {translate('dashboard.filters.inputSumm')}
                                                                </MenuValue>
                                                            </MenuLeftSide>
                                                            <ChevronRight/>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <MenuLeftSide>
                                                                <MenuTitle>
                                                                    {translate('dashboard.filters.inputSumm')}
                                                                </MenuTitle>
                                                                <MenuValue>{summ}</MenuValue>
                                                            </MenuLeftSide>
                                                            <ChevronRight/>
                                                        </>
                                                    )}
                                                </MenuCard>
                                                {isOpenSummFilter && (
                                                    <MobileDrawer>
                                                        <MobileDrawerWrapper>
                                                            <div>
                                                                <TitleWrapper>
                                                                    <div>
                                                                        <div onClick={handleOpenSummFilter}>
                                                                            <ArrowBack/>
                                                                        </div>
                                                                        <TypographyStyled
                                                                            $fontSize={24}
                                                                            $fontWeight={700}
                                                                        >
                                                                            {translate('dashboard.filters.inputSumm')}
                                                                        </TypographyStyled>
                                                                    </div>
                                                                    <DivStyled onClick={handleOpenSummFilter}>
                                                                        <CloseIcon/>
                                                                    </DivStyled>
                                                                </TitleWrapper>

                                                                <InputWrapper>
                                                                    <label
                                                                        htmlFor={'withdrawSum'}
                                                                        style={{
                                                                            color: '#FBFBFF',
                                                                        }}
                                                                    >
                                                                        {translate('dashboard.labels.withdrawSum')}
                                                                    </label>
                                                                    <Input
                                                                        suffix={
                                                                            <span
                                                                                id={'withdrawSum'}
                                                                                style={{color: '#FBFBFF'}}
                                                                            >
                                        {filter.fiat?.value ??
                                            fiats[0].currency}
                                      </span>
                                                                        }
                                                                        placeholder={translate('dashboard.filters.inputSumm')}
                                                                        style={{backgroundColor: '#2B2B36'}}
                                                                        value={summ}
                                                                        onChange={handleChangeSumm}
                                                                    />
                                                                </InputWrapper>
                                                            </div>
                                                            <div>
                                                                <FilledButton onClick={handleOpenSummFilter}>
                                                                    {translate('dashboard.filters.buttons.completed')}
                                                                </FilledButton>
                                                            </div>
                                                        </MobileDrawerWrapper>
                                                    </MobileDrawer>
                                                )}
                                            </>
                                            <>
                                                {/* PAY METHOD */}
                                                <MenuCard onClick={handleOpenPaymentFilter}>
                                                    {paymentMethod.method === '' ? (
                                                        <>
                                                            <MenuLeftSide>
                                                                <MenuValue>
                                                                    {translate(
                                                                        'dashboard.filters.method.allMobile'
                                                                    )}
                                                                </MenuValue>
                                                            </MenuLeftSide>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <MenuLeftSide>
                                                                <MenuTitle>
                                                                    {translate(
                                                                        'dashboard.filters.method.allMobile'
                                                                    )}
                                                                </MenuTitle>
                                                                <MenuValue>{paymentMethod.method}</MenuValue>
                                                            </MenuLeftSide>
                                                        </>
                                                    )}
                                                    <ChevronRight/>
                                                </MenuCard>
                                                {isOpenPaymentFilter && (
                                                    <MobileDrawer>
                                                        <MobileDrawerWrapper>
                                                            <div>
                                                                <TitleWrapper>
                                                                    <div>
                                                                        <div onClick={handleOpenPaymentFilter}>
                                                                            <ArrowBack/>
                                                                        </div>
                                                                        <TypographyStyled
                                                                            $fontSize={24}
                                                                            $fontWeight={700}
                                                                        >
                                                                            {translate('dashboard.filters.title.paymentMethod')}
                                                                        </TypographyStyled>
                                                                    </div>
                                                                    <DivStyled onClick={handleOpenPaymentFilter}>
                                                                        <CloseIcon/>
                                                                    </DivStyled>
                                                                </TitleWrapper>

                                                                <SearchWrapper>
                                                                    <Input.Search
                                                                        className={'custom-search'}
                                                                        prefix={<FilterSearchIcon/>}
                                                                        placeholder={'Поиск'}
                                                                        allowClear
                                                                    />
                                                                    <div>
                                                                        <MenuCard>
                                                                            <MenuLeftSide>
                                                                                <MenuValue>
                                                                                    {translate(
                                                                                        'dashboard.filters.method.all'
                                                                                    )}
                                                                                </MenuValue>
                                                                            </MenuLeftSide>
                                                                        </MenuCard>

                                                                        <Collapse
                                                                            className={'custom-collapse'}
                                                                            expandIcon={({isActive}) => (
                                                                                <CustomDropdownIconSimpleColor
                                                                                    rotate={isActive ? 180 : 0}/>
                                                                            )}
                                                                            expandIconPosition={'right'}
                                                                        >
                                                                            <Collapse.Panel
                                                                                key={''}
                                                                                header={
                                                                                    <MenuValue>
                                                                                        {translate(
                                                                                            'dashboard.filters.method.bankCard'
                                                                                        )}
                                                                                    </MenuValue>
                                                                                }
                                                                            >
                                                                                {banks.map((bank) => (
                                                                                    <MenuCard>
                                                                                        <MenuLeftSide>
                                                                                            <MenuValue>{t(`banks.${bank.name}`)}</MenuValue>
                                                                                        </MenuLeftSide>
                                                                                    </MenuCard>
                                                                                ))}
                                                                            </Collapse.Panel>
                                                                        </Collapse>

                                                                        <Collapse
                                                                            className={'custom-collapse'}
                                                                            expandIcon={({isActive}) => (
                                                                                <CustomDropdownIconSimpleColor
                                                                                    rotate={isActive ? 180 : 0}/>
                                                                            )}
                                                                            expandIconPosition={'right'}
                                                                        >
                                                                            <Collapse.Panel
                                                                                key={''}
                                                                                header={
                                                                                    <MenuValue>
                                                                                        {translate(
                                                                                            'dashboard.filters.method.electron'
                                                                                        )}
                                                                                    </MenuValue>
                                                                                }
                                                                            >
                                                                                {testData.length &&
                                                                                    testData.map((item) => (
                                                                                        <MenuCard>
                                                                                            <MenuLeftSide>
                                                                                                <MenuValue>
                                                                                                    {item.label}
                                                                                                </MenuValue>
                                                                                            </MenuLeftSide>
                                                                                        </MenuCard>
                                                                                    ))}
                                                                            </Collapse.Panel>
                                                                        </Collapse>

                                                                        <MenuCard>
                                                                            <MenuLeftSide>
                                                                                <MenuValue>
                                                                                    {translate('dashboard.filters.method.other.methods.mobile')}
                                                                                </MenuValue>
                                                                            </MenuLeftSide>
                                                                        </MenuCard>

                                                                        <MenuCard>
                                                                            <MenuLeftSide>
                                                                                <MenuValue>
                                                                                    {translate('dashboard.filters.method.other.methods.bank')}
                                                                                </MenuValue>
                                                                            </MenuLeftSide>
                                                                        </MenuCard>

                                                                        <MenuCard>
                                                                            <MenuLeftSide>
                                                                                <MenuValue>
                                                                                    {translate('dashboard.filters.method.other.methods.fps')}
                                                                                </MenuValue>
                                                                            </MenuLeftSide>
                                                                        </MenuCard>
                                                                    </div>
                                                                </SearchWrapper>
                                                            </div>
                                                            <div>
                                                                <FilledButton onClick={handleOpenPaymentFilter}>
                                                                    {translate('dashboard.filters.buttons.completed')}
                                                                </FilledButton>
                                                            </div>
                                                        </MobileDrawerWrapper>
                                                    </MobileDrawer>
                                                )}
                                            </>
                                            <>
                                                {/* REGIONS */}
                                                <MenuCard onClick={handleOpenCountryFilter}>
                                                    {countryName === '' ? (
                                                        <>
                                                            <MenuLeftSide>
                                                                <MenuValue>
                                                                    {translate('dashboard.filters.title.regions')}
                                                                </MenuValue>
                                                            </MenuLeftSide>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <MenuLeftSide>
                                                                <MenuTitle>
                                                                    {translate('dashboard.filters.title.regions')}
                                                                </MenuTitle>
                                                                <MenuValue>{countryName}</MenuValue>
                                                            </MenuLeftSide>
                                                        </>
                                                    )}
                                                    <ChevronRight/>
                                                </MenuCard>
                                                {isOpenCountryFilter && (
                                                    <MobileDrawer>
                                                        <MobileDrawerWrapper>
                                                            <div>
                                                                <TitleWrapper>
                                                                    <div>
                                                                        <div onClick={handleOpenCountryFilter}>
                                                                            <ArrowBack/>
                                                                        </div>
                                                                        <TypographyStyled
                                                                            $fontSize={24}
                                                                            $fontWeight={700}
                                                                        >
                                                                            {translate('dashboard.filters.title.regions')}
                                                                        </TypographyStyled>
                                                                    </div>
                                                                    <DivStyled onClick={handleOpenCountryFilter}>
                                                                        <CloseIcon/>
                                                                    </DivStyled>
                                                                </TitleWrapper>
                                                                {regions.map((region) => (
                                                                    <FilterMenuCard
                                                                        onClick={() =>
                                                                            handleChangeRegion(region.id, regions)
                                                                        }
                                                                    >
                                                                        <MenuLeftSide>
                                                                            <MenuValue>{`p2p.regions.${region.name.trim()}`}</MenuValue>
                                                                        </MenuLeftSide>
                                                                        {region.id === filter.region_id?.value ? (
                                                                            <CheckIcon/>
                                                                        ) : null}
                                                                    </FilterMenuCard>
                                                                ))}
                                                            </div>
                                                            <div>
                                                                <FilledButton onClick={handleOpenCountryFilter}>
                                                                    {translate('dashboard.filters.buttons.completed')}
                                                                </FilledButton>
                                                            </div>
                                                        </MobileDrawerWrapper>
                                                    </MobileDrawer>
                                                )}
                                            </>
                                        </MainContent>
                                    </div>
                                    <div>
                                        <FilledButton onClick={handleOpenFiatType}>
                                            {translate('dashboard.filters.buttons.applyFilters')}
                                        </FilledButton>
                                    </div>
                                </MobileDrawerWrapper>
                            </>
                        </MobileDrawer>
                    </>
                )}
            </div>
        </ConfigProvider>
    );
};

export default Want;

const TitleWrapper = styled.div`
    display: flex;
    margin-bottom: 7px;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

    & h1 {
        font-size: 24px;
        transition: all 0.2s ease;
    }

    & div {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: fit-content;
        gap: 15px;
    }

    & div h1 {
        margin: 0;
    }

    & div svg {
        width: 24px;
        height: 24px;
    }
`;

const MenuCard = styled.div`
    border-bottom: 1px solid #413d5080;
    padding: 12px 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 64px;
`;

const FilterMenuCard = styled(MenuCard)`
    padding: 23px 16px;
`;

const MenuTitle = styled.div`
    font-size: 12px;
    color: #767687;
`;

const MenuValue = styled.div`
    color: #fbfbff;
`;

const MenuLeftSide = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const TypographyStyled = styled(Typography)<{
    $fontSize?: number,
    $fontWeight?: number,
}>`
    color: #FBFBFF;
    font-size: ${(props) => props.$fontSize}px;
    font-weight: ${(props) => props.$fontWeight};
`;

const MobileDrawerWrapper = styled.div`
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const SearchWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

const FilterWrapper = styled.div`
    position: absolute;
    top: 100px;
    right: 50px;
`;

const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 64px;
`;

const DivStyled = styled.div`
    width: 20px;
    height: 20px;
`;
