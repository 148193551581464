import {useEffect, useState} from 'react';
import {Tabs, Typography} from 'antd';
import './index.css';
import Want, {IWantData} from './want';
import SkeletonDashboard from './skeletonDashboard';
import {CrudFilter, HttpError, useList, useNavigation, useTranslate,} from '@pankod/refine-core';
import {IBankTemp, ICurrencyTemp, IFiatTemp, IGetListAdData, IRegionTemp,} from './interface';
import {useUserNickname} from 'shared/hooks/use-user-nickname';
import Modal from 'shared/components/ui/modal';
import CustomCollapse from './collapse';
import * as _ from 'lodash';
import VisibilityObserver from '../../../../shared/hooks/on-visible/observer';
import {ArrowRepeat} from '../../../../shared/components/icons';
import {ConfigProvider} from '@pankod/refine-antd';
import {useAuthState} from '../../../../useAuthState';
import {useNavigate} from '@pankod/refine-react-router-v6';
import {ErrorBoundary} from '../../../../app/ErrorBoundary';
import styled from "styled-components";
import {useCookiesCustom} from '../../../../shared/hooks/useCookieCustom';

export interface IFilterAd {
    region_id?: CrudFilter;
    fiat?: CrudFilter;
    type?: CrudFilter;
    ccy?: CrudFilter;
    summ?: CrudFilter;
    payment_info?: CrudFilter;
    buy_payment_info?: CrudFilter;
    status: CrudFilter;
    // ...
}

const DashboardMobile = () => {
    const navigate = useNavigate();
    const {removeCookie} = useCookiesCustom();
    const [isOpenNicknameModal, setIsOpenNicknameModal] = useState(false);
    const [validationError, setValidationError] = useState<string>();

    const [initFilter, setInitFilter] = useState<IFilterAd>({
        fiat: {
            field: 'fiat',
            operator: 'eq',
            value: 'USD',
        },
        type: {
            field: 'type',
            operator: 'eq',
            value: 'sell',
        },
        ccy: {
            field: 'ccy',
            operator: 'eq',
            value: 'USDT',
        },
        status: {
            field: 'status',
            operator: 'eq',
            value: 'created',
        },
    });

    const {push: pushRoute} = useNavigation();

    const onSuccessUpdateCallback = () => {
        setIsOpenNicknameModal(false);
        pushRoute('/p2p/create-offer');
    };

    const onServerValidationError = (errorMessage: string) => {
        setValidationError(errorMessage);
    };

    const {
        isLoading: isLoadingAccount,
        userData,
        submitUserNickname,
    } = useUserNickname({
        onSuccessUpdateCallback,
        setValidationError: onServerValidationError,
    });

    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);

    const [ads, setAds] = useState<IGetListAdData[]>([]);
    const [filter, setFilter] = useState<IFilterAd>(initFilter);
    const translate = useTranslate();
    const currencyData = useList<ICurrencyTemp, HttpError>({
        dataProviderName: 'config',
        resource: 'currency-constraint',
        config: {
            pagination: {
                current: 1,
                pageSize: 25,
            },
            filters: [
                {
                    field: 'is_available_p2p',
                    operator: 'eq',
                    value: true,
                },
            ],
        },
        queryOptions: {
            retry: false,
            onSuccess: ({data}) => {
                const tmpFilterData: IFilterAd = {
                    ...filter,
                    ccy: {
                        field: 'ccy',
                        operator: 'eq',
                        value: data[0].currency,
                    },
                };
                setInitFilter((prevState) => ({
                    ...prevState,
                    ccy: {
                        field: 'ccy',
                        operator: 'eq',
                        value: data[0].currency,
                    },
                }));
                setFilter((prevState) => ({
                    ...prevState,
                    ccy: {
                        field: 'ccy',
                        operator: 'eq',
                        value: data[0].currency,
                    },
                }));
            },
        },
    });

    const fiatData = useList<IFiatTemp, HttpError>({
        dataProviderName: 'config',
        resource: 'fiat',
        config: {
            pagination: {
                current: 1,
                pageSize: 25,
            },
            filters: [
                {
                    field: 'is_available_p2p',
                    operator: 'eq',
                    value: true,
                },
            ],
        },
        queryOptions: {
            retry: false,
            onSuccess: ({data}) => {
                setInitFilter((prevState) => ({
                    ...prevState,
                    fiat: {
                        field: 'fiat',
                        operator: 'eq',
                        value: data[0].currency,
                    },
                }));
                setFilter((prevState) => ({
                    ...prevState,
                    fiat: {
                        field: 'fiat',
                        operator: 'eq',
                        value: data[0]?.currency,
                    },
                }));
            },
        },
    });

    const regionsData = useList<IRegionTemp, HttpError>({
        dataProviderName: 'p2p',
        resource: 'regions',
        config: {
            pagination: {
                current: 1,
                pageSize: 25,
            },
        },
        queryOptions: {
            retry: false,
        },
    });

    const banksData = useList<IBankTemp, HttpError>({
        dataProviderName: 'p2p',
        resource: 'banks',
        config: {
            pagination: {
                current: 1,
                pageSize: 25,
            },
        },
        queryOptions: {
            retry: false,
        },
    });
    const data = useList<IGetListAdData, HttpError>({
        dataProviderName: `p2p`,
        resource: `offer`,
        config: {
            pagination: {
                current: page,
            },
            filters: Object.values(
                _.omit(
                    filter,
                    filter.type?.value === 'buy' ? 'payment_info' : 'buy_payment_info'
                )
            ),
        },
        queryOptions: {
            onSuccess: (res) => {
                if (page === 1) {
                    setAds([...res.data]);
                } else setAds([...ads, ...res.data]);
            },
            retry: false,
        },
    });

    const onHandleChangeType = (value: string) => {
        setFilter({
            ...filter,
            type: {
                field: 'type',
                operator: 'eq',
                value,
            },
        });
    };

    useEffect(() => {
        setPage(1);
    }, [filter]);

    useEffect(() => {
        if (
            !data.isLoading &&
            !regionsData.isLoading &&
            !banksData.isLoading &&
            !fiatData.isLoading &&
            !currencyData.isLoading
        ) {
            setTimeout(() => setIsLoading(data.isLoading), 1000);
        }
    }, [
        banksData.isLoading,
        data.isLoading,
        fiatData.isLoading,
        regionsData.isLoading,
        currencyData.isLoading,
    ]);

    const handleNextPage = (visibility: boolean) => {
        if (visibility && data.data?.total !== ads.length) {
            setPage((prevState) => prevState + 1);
        }
    };

    const handleUpdateData = async () => {
        await data.refetch();
    };


    const [tabList] = useState([
        {
            tab: translate('dashboard.tabs.buyMobile'),
            key: 'sell',
        },
        {tab: '|', key: '|', disabled: true},
        {
            tab: translate('dashboard.tabs.sellMobile'),
            key: 'buy',
        },
    ]);


    if (isLoading) {
        return <SkeletonDashboard/>;
    }
    const wantData: IWantData = {
        data,
        filter,
        regions: regionsData.data?.data,
        initFilter,
        setFilter,
        fiats: fiatData.data?.data,
        banks: banksData.data?.data,
        currency: currencyData.data?.data,
    };

    return (
        <ErrorBoundary
            logout={() => {
            }}
            navigate={navigate}
            removeCookie={removeCookie}>
            <TypographyTitleStyled>
                {translate('dashboard.title')}
            </TypographyTitleStyled>
            <div>
                <Wrapper>
                    <ConfigProvider
                        theme={{
                            components: {
                                Tabs: {
                                    colorBorderSecondary: 'rgba(0, 0, 0, 0.00)',
                                    lineWidthBold: 0,
                                    colorTextDisabled: '#474755',
                                },
                            },
                        }}
                    >
                        <Tabs
                            tabBarGutter={16}
                            activeKey={
                                !!filter.type?.value
                                    ? filter.type?.value
                                    : initFilter.type?.value
                            }
                            style={{outline: 'none'}}
                            onChange={onHandleChangeType}
                        >
                            {tabList.map((tabInfo, index) => {
                                return (
                                    <Tabs.TabPane
                                        tab={<span style={{fontSize: 18}}>{tabInfo.tab}</span>}
                                        key={tabInfo.key}
                                        disabled={tabInfo.disabled}
                                    />
                                );
                            })}
                        </Tabs>
                        <ArrowRepeatWrapper>
                            <div onClick={handleUpdateData}>
                                <ArrowRepeat/>
                            </div>
                        </ArrowRepeatWrapper>
                    </ConfigProvider>
                </Wrapper>
                {userData ? (
                    <Modal.NicknameModal
                        validationError={validationError}
                        isLoading={isLoadingAccount}
                        userData={userData}
                        onSubmit={submitUserNickname}
                        onCancel={() => setIsOpenNicknameModal(false)}
                        open={isOpenNicknameModal}
                        onNicknameChange={() => setValidationError(undefined)}
                    />
                ) : null}
            </div>
            <Want {...wantData} />
            <CollapseWrapper>
                {ads.map((item, index) =>
                    index === ads.length - 1 ? (
                        <VisibilityObserver
                            onChangeVisibility={handleNextPage}
                            options={{rootMargin: '100px'}}
                        >
                            <CustomCollapse
                                type={filter.type?.value}
                                item={item}
                                key={`dashboard-collapse-${item.id}`}
                            />
                        </VisibilityObserver>
                    ) : (
                        <CustomCollapse
                            type={filter.type?.value}
                            item={item}
                            key={`dashboard-collapse-${item.id}`}
                        />
                    )
                )}
            </CollapseWrapper>
        </ErrorBoundary>
    );
};

export default DashboardMobile;

const TypographyTitleStyled = styled(Typography.Title)`
  color: #FBFBFF;
  font-size: 24px;
  width: max-content;
  height: max-content;
  margin: 0 auto;
  position: absolute;
  top: 20px;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
`;

const ArrowRepeatWrapper = styled.div`
  display: flex;
  gap: 30px;
`;

const CollapseWrapper = styled.div`
  width: 100%;
  position: relative;
`;
