import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import Icon from '@ant-design/icons';
import {
  ChevronDown,
  CloseSymbol,
} from '../../../../../shared/components/icons';
import {
  ICurrencyOption,
  IExchangeContent,
} from '../../../../../features/cryptocurrencyExchange/cryptoCurrencyExchange.p';
import { getCoinInfoByName } from '../../../../../shared/helpers/getCoinInfoByName';
import { ICryptoCurrencyConstraintItem } from '../../../../../widgets/order/types';

interface IChoiceCurrency {
  handleOpenChoice(value?: boolean): void;

  type: 'send' | 'get';
  list: (ICurrencyOption & { description?: string })[];
  setExchangeData: Dispatch<SetStateAction<IExchangeContent>>;
  currencyConstraintList?: ICryptoCurrencyConstraintItem[];
  network?: {
    id: string;
    description: string;
    name: string;
    network: string;
  }[];
  nest?: boolean;
}

const ChoiceCurrency = ({
  handleOpenChoice,
  type,
  list,
  setExchangeData,
  currencyConstraintList = [],
  network = [],
  nest = false,
}: IChoiceCurrency) => {
  const findNetWork = (name: string) => {
    return network?.filter((net) => net.network === name) ?? [];
  };
  const [openNetwork, setOpenNetwork] = useState<string>();

  return openNetwork !== undefined ? (
    <ChoiceCurrency
      handleOpenChoice={() => {
        setOpenNetwork(undefined);
        handleOpenChoice(false);
      }}
      type={type}
      nest={true}
      list={network
        ?.filter((net) => net.network === openNetwork)
        .map((net) => ({
          label: net.name,
          value: net.network,
          description: net.description,
        }))}
      setExchangeData={setExchangeData}
      currencyConstraintList={currencyConstraintList}
    />
  ) : (
    <div>
      <div
        className={'flex justify-content-space-between align-items-center'}
        style={{ marginBottom: '32px' }}
      >
        <span className={'title'}>
          {nest
            ? 'Выбор сети'
            : type === 'send'
            ? 'Валюта отправления'
            : 'Валюта получения'}
        </span>
        <Icon
          component={() => CloseSymbol('#F3F3F8')}
          onClick={() => handleOpenChoice(false)}
        ></Icon>
      </div>
      <div style={{ marginBottom: '32px' }}>
        <span className={'card-title-mobile'}>
          {nest
            ? 'Выберите  сеть валюты для ввода средств'
            : type === 'send'
            ? 'Выберите валюту для получения'
            : 'Выберите  валюту для отправления'}
        </span>
      </div>
      <div>
        {list.map((currency) => (
          <div
            style={{ padding: '12px 0', borderBottom: '1px solid #413D5080' }}
            onClick={() => {
              if (findNetWork(currency.label).length > 0) {
                setOpenNetwork(currency.label);
              } else {
                setExchangeData((prevState) => {
                  if (type === 'send') {
                    const tmp = currencyConstraintList?.find(
                      (item) => item.currency === currency.value
                    );
                    return {
                      ...prevState,
                      inputCoin: currency.value,
                      inputAmount: currency ? tmp!.minimal_trade_amount : '0',
                    };
                  } else {
                    return {
                      ...prevState,
                      outputCoin: currency.value,
                    };
                  }
                });
                handleOpenChoice(false);
              }
            }}
          >
            <div
              className={
                'flex align-items-center justify-content-space-between'
              }
            >
              <div className={'flex align-items-center'}>
                <div style={{ padding: '0 20px' }}>
                  {getCoinInfoByName(currency.label).icon as ReactNode}
                </div>
                <div>
                  <span className={'card-title-mobile'}>{currency.label}</span>
                  {currency?.description && (
                    <div
                      style={{
                        color: '#767687',
                        fontSize: 14,
                        fontFamily: 'Inter',
                        fontWeight: '400',
                        wordWrap: 'break-word',
                        marginTop: '4px',
                      }}
                    >
                      {currency?.description}
                    </div>
                  )}
                </div>
              </div>
              {findNetWork(currency.label).length > 0 && (
                <div style={{ marginRight: '20px', rotate: '-90deg' }}>
                  <Icon component={() => ChevronDown('#C3C3CE')} />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChoiceCurrency;
