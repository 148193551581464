import React, {createContext, Suspense, useEffect, useState,} from 'react';
import {
    checkMonth,
    getChartData,
    getOrderBookBuySpot,
    getOrderBookSellSpot,
    getPairHeaderDataFromAPI,
    getPairs,
    timestampToDate,
} from '../spotResponses';
import {useLocation, useNavigate} from '@pankod/refine-react-router-v6';
import {ISpotPairInfo} from '../../../shared/interfaces/spot';
import {CentrifugeClient} from '../../../packages/centrifuge-client/centrifuge-client';
import {axios} from '../../../shared/exios';
import {API_URL} from '../../../packages/keycloak-client/constants';
import {AdaptiveFullScreenLoader} from '../../../shared/components/full-screen-loader';
import '../styte.css';
import {ErrorBoundary} from '../../../app/ErrorBoundary';
import {useCookiesCustom} from '../../../shared/hooks/useCookieCustom';
import {DashboardDesktop} from './spotDashboardDesktop';
import {DashboardMobile} from './spotDashboardMobile';

export const SpotMarketContext = createContext({
    spotMarketSaleData: {
        dataList: [],
        progressColor: '#F1606326',
        priceColor: '#F16063',
    },
    spotMarketBuyData: {
        dataList: [],
        progressColor: '#58BF921F',
        priceColor: '#58BF92',
    },
    currPair: {
        currentPair: localStorage['activePair'],
        mainPriceLast: 0,
        equivalentInFiat: 0,
        changeValuePerDay: 0,
        maxValuePerDay: 0,
        minValuePerDay: 0,
        marketVolumePerDay: 0,
    },
    tradeInfo: {
        mainCurrencyData: 0,
        currencyInFiatData: '',
        marketVolumeInMain: `${0} ${localStorage['activePair']?.split('-')[0]}`,
        marketVolumeInLast: `${0} ${localStorage['activePair']?.split('-')[1]}`,
    },
    dashboard: {
        spotOrderBookListData: {
            tradeInfo: [],

            amountCurrency: localStorage['activePair']?.split('-')[0],
            priceCurrency: localStorage['activePair']?.split('-')[1],

            selectorValue: 0,
            currentFilter: 0,
            setSelectorValue: (value: any) => {
            },
            setCurrentFilter: (value: any) => {
            },

            spotMarketSaleData: {
                dataList: [],
                progressColor: '#F1606326',
                priceColor: '#F16063',
            },
            spotMarketBuyData: {
                dataList: [],
                progressColor: '#58BF921F',
                priceColor: '#58BF92',
            },
        },
        currPair: '',
        chartData: [],
        type: 'buy',
        setType: (value: 'buy' | 'sell') => {
        },
    },
});

const SpotDashboard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {cookie, removeCookie} = useCookiesCustom();

    const [type, setType] = useState<'buy' | 'sell'>('buy');

    const [orderBookSellPage] = useState(1);
    const [orderBookBuyPage] = useState(1);
    const [_, setPairsNamesArr] = useState(['']);
    const [activePair, setActivePair] = useState(
        () => localStorage['activePair']
    );
    const [currPair, setCurrPair] = useState<ISpotPairInfo | undefined>({
        currentPair: activePair,
        mainPriceLast: 0,
        equivalentInFiat: 0,
        changeValuePerDay: 0,
        maxValuePerDay: 0,
        minValuePerDay: 0,
        marketVolumePerDay: 0,
    });
    const [tradeInfoInOrderBook, setTradeInfoInOrderBook] = useState({
        mainCurrencyData: 0,
        currencyInFiatData: '',
        marketVolumeInMain: `${0} ${localStorage['activePair']?.split('-')[0]}`,
        marketVolumeInLast: `${0} ${localStorage['activePair']?.split('-')[1]}`,
    });

    const [buyOrders, setBuyOrders] = useState([]);
    const [sellOrders, setSellOrders] = useState([]);

    const [selectorValue, setSelectorValue] = useState(0.01);
    const [currentFilter, setCurrentFilter] = useState(0);
    const [chartData, setChartData] = useState([]);

    async function subToPair() {
        const subs = CentrifugeClient.subscriptions();

        if (!!subs) {
            if (!subs[`spot.${activePair}`])
                CentrifugeClient.subscribeToChannel(`spot.${activePair}`);

            if (!subs[`spotStat.${activePair}`])
                CentrifugeClient.subscribeToChannel(`spotStat.${activePair}`);

            if (!subs[`chart:${activePair}`])
                CentrifugeClient.subscribeToChannel(`chart.${activePair}`);

            const spotStatSubscription = CentrifugeClient.getSubscription(
                `spotStat.${activePair}`
            );

            const spotChartSubscription = CentrifugeClient.getSubscription(
                `chart.${activePair}`
            );

            spotChartSubscription
                ?.on('publication', (data) => {
                    const resData = data?.data || [];
                    // @ts-ignore
                    setChartData((prevState: any) => {
                        let arr: any[];
                        if (!!prevState?.length) arr = [...prevState];
                        else arr = [];
                        if (!!resData?.length) {
                            for (const item of resData) {
                                const time = timestampToDate(item.d);
                                arr.push({
                                    time,
                                    open: +item?.o,
                                    high: +item?.h,
                                    low: +item?.l,
                                    close: +item?.c,
                                });
                            }
                            for (const item of arr) {
                                if (typeof item?.time === 'string') {
                                    const time = item?.time?.split('-');
                                    item.time = `${time[0]}-${checkMonth(time[1])}-${time[2]}`;
                                } else {
                                    const time = item.time;
                                    item.time = `${time.year}-${checkMonth(time.month)}-${
                                        time.day
                                    }`;
                                }
                            }
                        }
                        return [
                            // @ts-ignore
                            ...new Map(arr.map((item) => [item['time'], item])).values(),
                        ];
                    });
                })
                .subscribe();

            spotStatSubscription
                ?.on('publication', (data) => {
                    const locData = data?.data;

                    setCurrPair({
                        currentPair: `${locData.main}-${locData.last}`,
                        mainPriceLast: locData.last_price,
                        equivalentInFiat: locData.last_price_usd,
                        changeValuePerDay: locData.change_price_24,
                        maxValuePerDay: locData.max_price,
                        minValuePerDay: locData.min_price,
                        marketVolumePerDay: locData.volume_24,
                    });
                    setTradeInfoInOrderBook({
                        // @ts-ignore
                        mainCurrencyData: locData.last_price,
                        currencyInFiatData: locData.last_price_usd,
                        marketVolumeInMain: `${locData.asc_sum} ${
                            activePair?.split('-')[0]
                        }`,
                        marketVolumeInLast: `${locData.bid_sum} ${
                            activePair?.split('-')[1]
                        }`,
                    });

                    // @ts-ignore
                    setBuyOrders((prevState: any) => {
                        const arr = [];

                        if (!!prevState?.length) {
                            for (const item of prevState) {
                                const bid0 = 0 ?? locData?.bid[0];
                                const bid1 = 0 ?? locData?.bid[1];
                                const bid2 = 0 ?? locData?.bid[2];
                                const bid3 = 0 ?? locData?.bid[3];

                                if (item?.price === bid0) {
                                    arr.push({
                                        price: bid0,
                                        amountInCurrency: bid1,
                                        amountAll: bid2,
                                        percent: bid3,
                                    });
                                } else {
                                    arr.push(item);
                                }
                            }
                        }

                        return arr;
                    });

                    // @ts-ignore
                    setSellOrders((prevState) => {
                        const arr = [];

                        if (!!prevState?.length) {
                            for (const item of prevState) {
                                const asc0 = 0 ?? locData?.asc[0];
                                const asc1 = 0 ?? locData?.asc[1];
                                const asc2 = 0 ?? locData?.asc[2];
                                const asc3 = 0 ?? locData?.asc[3];

                                // @ts-ignore
                                if (item?.price === asc0) {
                                    arr.push({
                                        price: asc0,
                                        amountInCurrency: asc1,
                                        amountAll: asc2,
                                        percent: asc3,
                                    });
                                } else {
                                    arr.push(item);
                                }
                            }
                        }
                        return arr;
                    });
                })
                .subscribe();

            CentrifugeClient.connect();
        }
    }

    async function getTradeInOrderBook() {
        try {
            const res = await axios.get(
                `${API_URL}/${localStorage['activePair']
                    ?.replace('-', '')
                    ?.toLowerCase()}-spot/api/custom-api/book-info`,
                {
                    headers: {
                        Authorization: cookie['token'] ? `Bearer ${cookie['token']}` : '',
                    },
                }
            );
            setTradeInfoInOrderBook({
                mainCurrencyData: res.data?.last_price,
                currencyInFiatData: res.data.last_price_fiat,
                marketVolumeInMain: res?.data?.sum_buy,
                marketVolumeInLast: res?.data?.sum_sale,
            });
        } catch (e) {
            console.log('getTradeInOrderBook error', e);
        }
    }

    function getData() {
        setBuyOrders([]);
        setSellOrders([]);

        Promise.all([
            getTradeInOrderBook(),
            subToPair(),
            getOrderBookBuySpot({
                pairToLowerCase: localStorage['activePair']
                    ?.toLowerCase()
                    .replace('-', ''),
                setFunc: setBuyOrders,
                page: orderBookBuyPage,
            }),
            getOrderBookSellSpot({
                pairToLowerCase: localStorage['activePair']
                    ?.toLowerCase()
                    .replace('-', ''),
                setFunc: setSellOrders,
                page: orderBookSellPage,
            }),
            getChartData({
                pairToLowerCase: localStorage['activePair']
                    ?.toLowerCase()
                    .replace('-', ''),
                setFunc: setChartData,
            }),
            getPairHeaderDataFromAPI({
                pairToLowerCase: localStorage['activePair']
                    ?.toLowerCase()
                    .replace('-', ''),
                activePair: localStorage['activePair']?.replace('-', '/'),
                setFunc: setCurrPair,
            }),
        ]).then();
    }

    useEffect(() => {
        if (!!cookie['token']) {
            getPairs({
                setFunc: setPairsNamesArr,
            }).then();
        }
        getData();
    }, []);

    useEffect(() => {
        setActivePair(() => localStorage['activePair']);
        getData();
    }, [location.pathname]);

    return (
        <ErrorBoundary
            logout={() => {
            }}
            navigate={navigate}
            removeCookie={removeCookie}
        >
            <Suspense fallback={<AdaptiveFullScreenLoader/>}>
                <SpotMarketContext.Provider
                    value={{
                        spotMarketSaleData: {
                            dataList: sellOrders,
                            progressColor: '#F1606326',
                            priceColor: '#F16063',
                        },
                        spotMarketBuyData: {
                            dataList: buyOrders,
                            progressColor: '#58BF921F',
                            priceColor: '#58BF92',
                        },
                        currPair: {
                            currentPair: currPair?.currentPair,
                            mainPriceLast: currPair?.mainPriceLast,
                            equivalentInFiat: currPair?.equivalentInFiat,
                            changeValuePerDay: currPair?.changeValuePerDay,
                            maxValuePerDay: currPair?.maxValuePerDay,
                            minValuePerDay: currPair?.minValuePerDay,
                            marketVolumePerDay: currPair?.marketVolumePerDay,
                        },
                        tradeInfo: {
                            mainCurrencyData: tradeInfoInOrderBook?.mainCurrencyData,
                            currencyInFiatData: tradeInfoInOrderBook?.currencyInFiatData,
                            marketVolumeInMain: tradeInfoInOrderBook?.marketVolumeInMain,
                            marketVolumeInLast: tradeInfoInOrderBook?.marketVolumeInLast,
                        },
                        dashboard: {
                            spotOrderBookListData: {
                                tradeInfo: tradeInfoInOrderBook as unknown as never[],

                                amountCurrency: activePair?.split('-')[0],
                                priceCurrency: activePair?.split('-')[1],
                                // @ts-ignore
                                selectorValue,
                                // @ts-ignore
                                currentFilter,
                                setSelectorValue,
                                setCurrentFilter,

                                spotMarketSaleData: {
                                    // @ts-ignore
                                    dataList: sellOrders,
                                    progressColor: '#F1606326',
                                    priceColor: '#F16063',
                                },
                                spotMarketBuyData: {
                                    // @ts-ignore
                                    dataList: buyOrders,
                                    progressColor: '#58BF921F',
                                    priceColor: '#58BF92',
                                },
                            },
                            // @ts-ignore
                            currPair: currPair,
                            chartData: chartData,
                            type: type,
                            setType: setType,
                        },
                    }}
                >
                    <DashboardDesktop/>
                    <DashboardMobile/>
                </SpotMarketContext.Provider>
            </Suspense>
        </ErrorBoundary>
    );
};

export {SpotDashboard};
