import styled from "styled-components"
import { BasicModal } from "../basicModal"
import { Button, Typography } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";
import { P2PAdStatus } from "features/p2p/createOrder/types";

export const ConfirmSetDefaultAdPrice = ({
    marketPrice,
    onClose,
    open,
    priceCcy,
    onOk,
    status
}: {
    marketPrice: number,
    onClose: () => void,
    open: boolean,
    priceCcy: string,
    onOk: (status: P2PAdStatus, price: number) => void,
    status: P2PAdStatus
}) => {
    const t = useTranslate();
    const getButtonText = () => t(status === P2PAdStatus.TEMPLATE ? 'buttons.saveAsDraft' : 'buttons.publish')
    const getOnClick = () => {
        if(status === P2PAdStatus.TEMPLATE) return () => onOk(P2PAdStatus.TEMPLATE, +marketPrice);
        else return () => onOk(P2PAdStatus.CREATED, +marketPrice);
    }
    return (
        <StyledModal
            width={560}
            onCancel={onClose}
            open={open}
            title={t('announcements.marketPriceChange')}
            footer={
                <div className="custom-modal-footer">
                    <Button
                        type="primary"
                        onClick={getOnClick()}
                    >
                        {getButtonText()}
                    </Button>
                    <Button
                        type="dashed"
                        onClick={() => onClose()}
                    >
                        {t('announcements.changePrice')}
                    </Button>
                </div>
            }
        >
            <ModalBody>
                <ModalText>
                    {t('announcements.priceChangeModalText')}
                </ModalText>
                <ModalPrice>
                    <Typography.Text className="title">{t('p2p.chooseAdType.marketPrice')}</Typography.Text>
                    <Typography.Text className="price">{marketPrice} {priceCcy}</Typography.Text>
                </ModalPrice>
            </ModalBody>
        </StyledModal>
    )
}

const StyledModal = styled(BasicModal)`
    max-height: 370px;
`
const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`
const ModalText = styled(Typography.Text)`
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #F3F3F8;
`
const ModalPrice = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    .title{
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #767687;
    }
    .price{
        font-family: Inter;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
    }
`