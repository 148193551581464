import { Form, UploadFile } from '@pankod/refine-antd';
import { BasicModal, BasicModalProps } from '../basicModal';

import { AppealModalAlert } from './components/alert';
import { AppealModalFooter } from './components/footer';
import { AppealModalForm } from './components/form';
import { IAppealFormValues } from './components/form/interface';
import { useState } from 'react';
import { useTranslate } from "@pankod/refine-core";

type OmittedModalProps = Omit<
  BasicModalProps,
  | 'children'
  | 'title'
  | 'footer'
  | 'width'
  | 'textOk'
  | 'textCancel'
  | 'onOk'
  | 'onClose'
>;

interface IAppealModalProps extends OmittedModalProps {
  isSeller?: boolean
  onOk: (data: IAppealFormValues, callback: () => void) => void;
  onCancel: () => void;
}

const SELECT_APPEAL_REASONS_LIST = [
  {
    key: 'order_seller_ignore_conditions',
    placeholder: 'appealModal.ignore',
  },
  {
    key: 'order_seller_expired',
    placeholder: 'appealModal.madePayment',
  },
  {
    key: 'payment_excess',
    placeholder: 'appealModal.exceed',
  },
  { key: 'other', placeholder: 'appealModal.other' },
];

const SELECT_APPEAL_REASONS_LIST_SELLER = [
  {
    key: 'order_buyer_ignore_conditions',
    placeholder: 'appealModal.buyerIgnoreCondition',
  },
  {
    key: 'payment_incomplete',
    placeholder: 'appealModal.notFullPayment',
  },
  { key: 'other', placeholder: 'appealModal.other' },
];

const AppealModal = (props: IAppealModalProps) => {
  const [form] = Form.useForm<IAppealFormValues>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const { onCancel: handleOnCancel, onOk: handleOnOk, isSeller, ...modalProps } = props;

  const handleOnSuccess = async () => {
    form.setFieldValue('files', fileList);
    await form.validateFields();
    await handleOnOk(form.getFieldsValue(), handleOnClose);
  };

  const handleOnClose = () => {
    setFileList([]);
    form.resetFields(['reason', 'description', 'files']);
    handleOnCancel();
  };
  const translate = useTranslate()
  return (
    <BasicModal
      title={translate('appealModal.appeal')}
      width={560}
      footer={
        <AppealModalFooter
          onClose={handleOnClose}
          onSuccess={handleOnSuccess}
        />
      }
      onCancel={handleOnClose}
      {...modalProps}
    >
      <AppealModalAlert />
      <AppealModalForm
        form={form}
        reasonList={isSeller ? SELECT_APPEAL_REASONS_LIST_SELLER : SELECT_APPEAL_REASONS_LIST}
        fileList={fileList}
        setFileList={setFileList}
      />
    </BasicModal>
  );
};

export { AppealModal };
