import React from 'react';
import { Button, Icons, QRCode, Typography } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import GradientCard from 'shared/components/ui/gradient-card';
import { ReactComponent as CopyIcon } from '../../shared/components/ui/assets/svg/copyIcon.svg';
import { IExchangeOrderTransferCard } from './order.p';
import './index.css';

const ExchangeOrderTransferCard: React.FC<IExchangeOrderTransferCard> = ({
  currentStep,
  userBalance,
  addressWallet,
  inputCurrency,
  minimalTradeAmount,
}) => {
  const translate = useTranslate();

  return (
    <GradientCard leftBorderWidth={4} padding={'32px 24px 32px 20px'}>
      <div className="exchange-order-transfer-card">
        <Typography.Text className="text-align-center exchange-card-header exchange-transfer-card-title ">
          {translate('cryptoExchange.order.rightCard.title')}
        </Typography.Text>
        <Typography.Text className="exchange-transfer-card-subheader">
          {translate('cryptoExchange.order.rightCard.centerText')}
        </Typography.Text>
        {addressWallet ? (
          <>
            <div className="justify-content-center exchange-transfer-card-qrcode-wrapper">
              <QRCode
                style={{
                  padding: '0 !important',
                  filter:
                    currentStep >= 3 || currentStep < 0 ? 'blur(4px)' : 'unset',
                }}
                value={addressWallet}
                status={'active'}
                size={108}
              />
            </div>
            <div className="exchange-transfer-card-wallet-address-btn-wrapper justify-content-center">
              <Button
                onClick={() => {
                  if (currentStep >= 3) {
                    return;
                  }
                  navigator.clipboard.writeText(addressWallet);
                }}
                disabled={currentStep >= 3 || currentStep < 0}
                className="wallet-address-copy-btn"
                icon={<CopyIcon />}
              >
                {addressWallet}
              </Button>
            </div>
            <Typography.Text className="text-align-center bold-16px-text exchange-card-wallet-balance">
              {!!userBalance && typeof userBalance === 'string'
                ? Number(userBalance || 0)?.toFixed(8) + ' ' + inputCurrency
                : '0 ' + inputCurrency}
            </Typography.Text>
            <Typography.Text
              style={{
                fontSize: '14px',
                lineHeight: '16.94px',
                display: 'block',
                textAlign: 'center',
                marginTop: '4px',
              }}
            >
              {translate('cryptoExchange.order.rightCard.bottomBalance')}
            </Typography.Text>
            {currentStep > 1 &&
              minimalTradeAmount &&
              userBalance < +minimalTradeAmount && (
                <Typography.Text
                  className="text-align-center"
                  style={{
                    color: '#F16063',
                    display: 'block',
                    fontSize: 14,
                    lineHeight: '16.94px',
                  }}
                >
                  {translate(
                    'cryptoExchange.order.rightCard.depositSumMinimumError'
                  )}
                </Typography.Text>
              )}
          </>
        ) : (
          <div className="justify-content-center align-items-center exchange-card-loader-wrapper">
            <Icons.LoadingOutlined className="exchange-card-loader" />
          </div>
        )}
      </div>
    </GradientCard>
  );
};

export default ExchangeOrderTransferCard;
