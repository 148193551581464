import Styles from './index.module.css';
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Centrifuge} from 'centrifuge';
import {API_URL, WS_URL,} from '../../../../packages/keycloak-client/constants';
import {useParams} from '@pankod/refine-react-router-v6';
import {axios} from '../../../../shared/exios';
import {Spin} from '@pankod/refine-antd';
import {IChatNotificationMessage, IChatOrderUpdatedMessage, IUloadChatMessage,} from '../../interfaces/entity';
import {IP2POrderUser} from '../../../../pages/p2p/myDeals/cards/myTrades.p';
import styled from 'styled-components';
import {ChatListMessages} from './list-messages';
import {useAuthState} from "../../../../useAuthState";
import {useCookiesCustom} from "../../../../shared/hooks/useCookieCustom";

interface IChatMessageContainer {
    buyerInfo: IP2POrderUser;
    sellerInfo: IP2POrderUser;
    buyerId: string;
    sellerId: string;
}

interface IChatContext {
    setPrevHeight: (value: number) => void;
    containerRef: React.RefObject<HTMLDivElement> | null;
    prevHeight: number;
}

export const MessageContainerContext = React.createContext<IChatContext>({
    setPrevHeight: () => {
    },
    containerRef: null,
    prevHeight: 0,
});

const ChatMessageContainer = (props: IChatMessageContainer): JSX.Element => {
    const {cookie} = useCookiesCustom();
    const uid = useAuthState((state) => state?.userAPI?.uid) || cookie['uid'];
    const {id: orderId} = useParams();
    const containerRef = useRef<HTMLDivElement>(null);
    const [uploadedMessages, setUploadedMessages] = useState<
        (IUloadChatMessage | IChatNotificationMessage | IChatOrderUpdatedMessage)[]
    >([]);
    const [prevHeight, setPrevHeight] = useState(0);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [limit] = useState(20);
    const [fetching, setFetching] = useState(true);
    const [topMessageDate, setTopMessageDate] = useState<string>();
    const currentPage = page - 1;

    async function getMessageActual() {
        const getFilters = () => {
            let filters = [
                {
                    field: 'order_id',
                    operator: '=',
                    value: orderId,
                },
            ];
            if (topMessageDate) {
                filters.push({
                    field: 'created_at',
                    operator: '<',
                    value: topMessageDate,
                });
            }
            return filters;
        };
        try {
            const res = await axios.post(
                `${API_URL}/p2p/api/message/search?limit=${limit}`,
                {
                    filters: getFilters(),
                    sort: [
                        {
                            field: 'created_at',
                            direction: 'desc',
                        },
                    ],
                }
            );
            setPage((prevState) => prevState + 1);
            setFetching(false);
            setTotal(res?.data?.meta?.total);
            setTopMessageDate(
                res?.data?.data[res?.data?.data.length - 1]?.created_at
            );

            setUploadedMessages((prevState) => {
                const messages = [
                    ...res?.data?.data.reverse(),
                    ...prevState,
                ]
                // @ts-ignore
                return [...new Set(messages)];
            });
        } catch (e) {
            console.log('getMessage error', e);
        }
    }

    const scrollHandler = (e: any) => {
        if (e.target.scrollTop === 0) {
            setFetching(true);
        }
    };

    async function getToken() {
        const {
            data: {data},
        } = await axios.post(`${API_URL}/notification/api/socket-channels`);
        return data.centrifugo_connection_token;
    }

    const centrifuge = new Centrifuge(WS_URL, {
        getToken
    });

    const subs = centrifuge.subscriptions();
    console.log('subs', subs)
    // @ts-ignore
    const hasSubscription = Object.values(subs).some((sub: { channel: string; }) => sub.channel === '$' + uid);
    console.log('hasSubscription', hasSubscription)

    async function subscribeToMessages() {
        if (uid && uid.length > 0) {
            const sub = centrifuge.newSubscription('$' + uid);
            console.log('subscribeToMessages sub', sub)
            sub
                .on('publication', function (pubData) {
                    console.log('subscribeToMessages pubData', pubData)
                    const {data} = pubData;
                    const {data: message} = data;
                    console.log('data', data, 'message', message, 'data.action', data.action)
                    if (data.action === 'MessageCreate') {
                        if (
                            message.action === 'orderUpdated' &&
                            message.data.dirty_status !== 'respond'
                        ) {
                            if (String(message?.order_id) === String(orderId)) {
                                // @ts-ignore
                                setUploadedMessages((prev) => [...new Set([...prev, message])]);
                            }
                        } else if (message.action !== 'orderUpdated') {
                            axios
                                .get(`${API_URL}/p2p/api/message/${message.id}`)
                                .then((res) => {
                                    if (String(message?.order_id) === String(orderId)) {
                                        // @ts-ignore
                                        setUploadedMessages((prev) => [...new Set([...prev, {...res.data}])]);
                                    }
                                });
                        }
                    }
                })
                .subscribe();
        }
    }

    useLayoutEffect(() => {
        centrifuge.connect();

        const checkSocketInterval = setInterval(() => {
            const subs = centrifuge.subscriptions();
            const hasSubscription = Object.values(subs).some((sub: { channel: string; }) => sub.channel === '$' + uid);
            if (!hasSubscription) {
                subscribeToMessages().then();
            }
        }, 10000);

        return () => {
            console.log('rerender')
            centrifuge.disconnect();
            clearInterval(checkSocketInterval);
        };
    }, [uid]);

    useEffect(() => {
        if (subs) {
            if (!subs['$' + uid]) {
                subscribeToMessages().then()
            }
        }
    }, [uid, subs]);

    useEffect(() => {
        if (fetching && (limit * currentPage < total || page === 1)) {
            getMessageActual().then();
        }
    }, [fetching, currentPage, total]);

    return (
        <MessageContainerContext.Provider
            value={{setPrevHeight, containerRef, prevHeight}}
        >
            <div
                ref={containerRef}
                onScroll={scrollHandler}
                className={Styles.container}
            >
                {fetching && currentPage * limit < total && (
                    <SpinnWrapper>
                        <Spin/>
                    </SpinnWrapper>
                )}
                <ChatListMessages
                    messages={uploadedMessages}
                    buyerInfo={props.buyerInfo}
                    sellerInfo={props.sellerInfo}
                    buyerId={props.buyerId}
                    sellerId={props.sellerId}
                />
            </div>
        </MessageContainerContext.Provider>
    );
};

const SpinnWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export {ChatMessageContainer};
