/**
 * Validates a string representation of a number to ensure it meets certain criteria.
 *
 * @param {string} numberString - The string representation of the number to validate.
 * @return {boolean} Returns true if the numberString is a valid number, otherwise false.
 */
export const  validateP2PTotalSumNumber = (numberString: string): string => {
  const number = parseFloat(numberString);
  if (isNaN(number)) {
    return ''; // Если строка не является числом, возвращаем false
  }
  const [integerPart, decimalPart] = numberString.toString().split(/[,.]/)
  const integerDigits = integerPart?.length || 0;
  const decimalDigits = decimalPart?.length || 0;

  let newNumber

  if (integerDigits >= 20) {
    newNumber = integerPart?.slice(0, 20)
  } else {
    newNumber = integerPart
  }

  if (decimalPart) {
    if (decimalDigits >= 8) {
      newNumber = `${newNumber}.${decimalPart?.slice(0, 8)}`
    } else {
      newNumber = `${newNumber}.${decimalPart}`
    }
  }

  return newNumber
}
