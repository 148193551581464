import React from 'react';
import Icon from "@ant-design/icons";
import {getMoneyIcon} from "../../../helpers/getMoneyIcon";
import {Typography} from "antd";
import {getFullNameCoin} from "../../../helpers/getFullNameCoin";

interface ITwoRowCoinPriceText{
  coinName: string
  amount: string
}

const Index:React.FC<ITwoRowCoinPriceText> = ({
  coinName,
  amount
                                              }) => {
  return (
    <>
      <div className={'flex-column gap-4'}>
        <div className={'flex-row gap-4 align-items-center'}>
          <div style={{
            position: "relative",
            height: 16,
            width: 16
          }}>
            <div style={{
              position: "absolute",
              borderRadius: 40,
              opacity: 0.800000011920929,
              background: 'var(--border-colors-vertical-stroke, linear-gradient(360deg, #6E27F8 0%, #20D9C0 100%))',
              filter: 'blur(18.5px)',
              height: 16,
              width: 16
            }}/>
            <Icon
              component={getMoneyIcon({
                name: coinName
              })}
            />
          </div>
          <Typography.Text className={'text-14px'}>
            {
              getFullNameCoin(coinName)
            }
          </Typography.Text>
        </div>
        <Typography.Text style={{
          fontSize: 12,
          fontWeight: 600
        }}>
          {
            `${amount} ${coinName}`
          }
        </Typography.Text>
      </div>
    </>
  );
};

export default Index;
