import React, {useMemo} from 'react';
import styled, {css} from 'styled-components';
import {ReactComponent as User} from '../../pages/p2p/settings/images/user.svg';
import {Tooltip} from "@pankod/refine-antd";
import Icon from '../../pages/p2p/settings/icon.svg';

interface Props {
    title: string;
    content: string;
    nameButton?: string;
    navigate?: () => void;
    tooltip?: string;
    username?: string;
    helpText?: string;
    isNicknameCard?: boolean;
    isTooltip?: boolean;
}

const P2PCard = ({
                     title,
                     content,
                     navigate,
                     nameButton,
                     tooltip,
                     username,
                     helpText,
                     isNicknameCard,
                     isTooltip = false
                 }: Props) => {

    const tooltipOption = useMemo(
        () => ({
            overlayInnerStyle: {border: 'none'},
            overlayStyle: {
                borderRadius: '4px',
                background: '#2B2B36',
                border: '1px solid #767687',
                maxWidth: 'fit-content',
            },
        }),
        []
    );

    return (
        <CardWrapperStyled $isNicknameCard={isNicknameCard} onClick={navigate} className={'border-gradient1'}>
            <ContainerStyled>
                <div>
                    <HeaderStyled>
                        <ContainerHeaderStyled>
                            <HeaderTextStyled>{title}</HeaderTextStyled>
                            {isNicknameCard && <ContainerUsernameStyled>
                                <User/>
                                <TextUsernameStyled>{username}</TextUsernameStyled>
                            </ContainerUsernameStyled>}
                            {isNicknameCard && <ContainerHelpTextStyled>{helpText}</ContainerHelpTextStyled>}
                        </ContainerHeaderStyled>
                    </HeaderStyled>
                    <TextCardStyled>{content}</TextCardStyled>
                </div>
                {isTooltip ? (
                    <Tooltip
                        color={'#2B2B36'}
                        {...tooltipOption}
                        placement={'bottomLeft'}
                        title={tooltip}
                    >
                        <ButtonStyled onClick={navigate}>
                            <img alt={'icon'} src={Icon}/>{' '}
                            <TextButtonStyled>{nameButton}</TextButtonStyled>
                        </ButtonStyled>
                    </Tooltip>
                ) : (
                    <></>
                )}
            </ContainerStyled>
        </CardWrapperStyled>
    );
};

const CardWrapperStyled = styled.div<{ $isNicknameCard?: boolean; }>`
    cursor: ${(props) => (props.$isNicknameCard ? 'auto' : 'pointer')};
    max-width: 362px;

    ${({$isNicknameCard}) =>
            !$isNicknameCard &&
            css`
                &:hover {
                    background: linear-gradient(
                            0deg,
                            rgba(43, 43, 54, 1) 100%,
                            rgba(43, 43, 54, 1) 100%
                    ) padding-box padding-box,
                    linear-gradient(150deg, rgb(82, 151, 255) 0%, rgba(43, 43, 54, 1) 55%) border-box border-box;
                }

                &:active {
                    background: linear-gradient(
                            0deg,
                            rgba(24, 24, 39, 1) 100%,
                            rgba(24, 24, 39, 1) 100%
                    ) padding-box padding-box,
                    linear-gradient(150deg, rgb(82, 151, 255) 0%, rgba(24, 24, 39, 1) 55%) border-box border-box;
                }
            `}
`;

const TextUsernameStyled = styled.span`
    color: #fbfbff;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
`;

const ContainerUsernameStyled = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    min-height: 16px;
    margin-bottom: 5px;
`;

const ContainerHelpTextStyled = styled.div`
    color: rgb(255, 108, 106);
`;

const ContainerHeaderStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`;

const TextCardStyled = styled.div`
    color: #fbfbff;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
`;

const ContainerStyled = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
`;

const HeaderStyled = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 16px;
`;

const TextButtonStyled = styled.span`
    color: #fbfbff;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    line-height: 15px;
    white-space: nowrap;
`;

const ButtonStyled = styled.button`
    padding: 8px 16px;
    gap: 8px;
    width: 100%;
    max-width: 250px;
    height: 32px;
    white-space: nowrap;
    background: #474755;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: none;
    cursor: pointer;
`;

const HeaderTextStyled = styled.div`
    color: #fbfbff;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    line-height: 17px;
    font-weight: 700;
`;

export default P2PCard;
