import {useTranslation} from 'react-i18next';
import React, {FC} from 'react';
import {AdPriceType, AdTypesEnum, IFiat} from '../../types';
import {ICryptoCurrencyConstraintItem} from '../../../../../widgets/order/types';
import {ConfigProvider, Form, Icon, InputNumber, Radio, Slider, Tooltip, Typography,} from '@pankod/refine-antd';
import {ChevronDown} from '../../../../../shared/components/icons';
import styled from 'styled-components';
import {
  ReactComponent as QuestionOutlinedIcon
} from '../../../../../shared/components/ui/assets/svg/questionOutlined.svg';

interface IChooseAdTypeProps {
  adType: AdTypesEnum;

  handleChangeAdType(type: AdTypesEnum): void;

  fiatList?: Array<IFiat>;
  currencyList?: Array<ICryptoCurrencyConstraintItem>;
  chosenCurrency?: ICryptoCurrencyConstraintItem;
  chosenFiat?: IFiat;

  handleChangeCurrency(currency?: ICryptoCurrencyConstraintItem): void;

  handleChangeFiat(fiat?: IFiat): void;

  isCurrencyListLoading: boolean;
  isFiatListLoading: boolean;
  activePrice: number;

  handleChangeAdCurrencyPrice(value: number): void;

  adCurrencyPrice: number;
  isNextBtnClick?: boolean;
  existingAds?: string[];
}

const ChooseP2PAdTypeCard: FC<IChooseAdTypeProps> = ({
                                                       adCurrencyPrice,
                                                       activePrice,
                                                       handleChangeAdCurrencyPrice,
                                                       chosenCurrency,
                                                       chosenFiat,
                                                       isNextBtnClick,
                                                     }) => {
  const {t} = useTranslation();

  return (
    <div className="choose-p2p-ad-type-card">
      <div className="p2p-ad-card-content">
        <Typography className="create-p2p-ad-card-title">
          {t('p2p.chooseAdType.typeAndSizeOfPrice')}
        </Typography>
        <div className="p2p-ad-card-content">
          <ContainerPriceType className="price-type">
            <Typography>{t('announcements.params.priceType')}</Typography>
            {/*<Radio.Group value={AdPriceType.FIXED}>*/}
            {/*  <Radio style={{color: 'white'}} value={AdPriceType.FIXED}>*/}
            {/*    {t('p2p.chooseAdType.radio.fixType')}*/}
            {/*  </Radio>*/}
            {/*  <Radio*/}
            {/*    style={{color: 'white'}}*/}
            {/*    value={AdPriceType.FLOAT}*/}
            {/*    disabled*/}
            {/*  >*/}
            {/*    {t('p2p.chooseAdType.radio.floatingType')}*/}
            {/*  </Radio>*/}
            {/*</Radio.Group>*/}
            <div className='p2p-price-type'>{t('p2p.chooseAdType.radio.fixType')}</div>
          </ContainerPriceType>
          <div className="price-slider-slider-wrapper">
            <div className="price-slider-slider-wrapper">
              <Typography>
                {t('p2p.chooseAdType.costOfOneUnitInFiat')}
              </Typography>
              <div className="choose-p2p-ad-type-slider-form-wrapper">
                <Slider
                  step={Number(
                    ((activePrice * 1.3 - activePrice * 0.8) / 100)?.toFixed(2)
                  )}
                  min={Number(
                    (activePrice ? activePrice * 0.8 : 0)?.toFixed(2)
                  )}
                  max={Number(
                    (activePrice ? activePrice * 1.3 : 50)?.toFixed(2)
                  )}
                  value={activePrice ? Number(adCurrencyPrice?.toFixed(2)) : 0}
                  onChange={(value) =>
                    handleChangeAdCurrencyPrice(Number(value?.toFixed(2)))
                  }
                  disabled={
                    !(chosenCurrency && chosenFiat && activePrice !== 0)
                  }
                  tooltip={{formatter: null}}
                />
                <Form.Item>
                  <div
                    style={{position: 'relative'}}
                    className={'input-number-in-create-ad'}
                  >
                    <ConfigProvider
                      theme={{
                        components: {
                          InputNumber: {
                            colorErrorHover: '#F16063',
                          },
                        },
                      }}
                    >
                      <InputNumberStyled
                        formatter={(value) => {
                          if (value) {
                            return `${(
                              Math.round((value as number) * 100) / 100
                            )
                              .toFixed(2)
                              .toString()} ${chosenFiat?.currency ?? ''}`;
                          }
                          return '';
                        }}
                        status={
                          !(
                            chosenCurrency &&
                            chosenFiat &&
                            activePrice !== 0
                          ) && isNextBtnClick === true
                            ? 'error'
                            : ''
                        }
                        min={activePrice * 0.8}
                        max={activePrice * 1.3}
                        value={
                          chosenCurrency && chosenFiat && activePrice !== 0
                            ? adCurrencyPrice
                            : undefined
                        }
                        placeholder={'0.00'}
                        onChange={(value) =>
                          value &&
                          handleChangeAdCurrencyPrice(
                            parseFloat(parseFloat(value as string).toFixed(2))
                          )
                        }
                        parser={(value) => {
                          if (!!chosenFiat?.currency) {
                            return +value!.replace(chosenFiat.currency, '');
                          }
                          return +value!.replace('', '');
                        }}
                      />
                    </ConfigProvider>
                    <ContainerIcons
                      className={
                        'flex justify-content-space-between flex-column input-number-arrows'
                      }
                    >
                      <Icon
                        component={() => ChevronDown('#767687', 10)}
                        style={{rotate: '180deg', display: 'block'}}
                        onClick={() =>
                          handleChangeAdCurrencyPrice(adCurrencyPrice + 1)
                        }
                      />
                      <Icon
                        component={() => ChevronDown('#767687', 10)}
                        style={{display: 'block'}}
                        onClick={() =>
                          handleChangeAdCurrencyPrice(adCurrencyPrice - 1)
                        }
                      />
                    </ContainerIcons>
                  </div>
                </Form.Item>
              </div>
              <div className="choose-p2p-ad-type-price-type-wrapper">
                <div className="item">
                  <Typography.Text className="create-p2p-ad-medium-text">
                    {activePrice ? activePrice : '0.00'} {chosenFiat?.currency}
                  </Typography.Text>
                  <Tooltip
                    trigger={'hover'}
                    placement="bottom"
                    title={t('p2p.chooseAdType.recommendPrice')}
                    color={'#2B2B36'}
                    arrow={false}
                    overlayInnerStyle={{
                      color: '#FBFBFF',
                      backgroundColor: '#2B2B36',
                      padding: '6px 16px',
                      maxWidth: 238,
                      border: '1px solid #767687',
                    }}
                  >
                    <div
                      style={{cursor: 'pointer'}}
                      className="centered-block"
                    >
                      <QuestionOutlinedIcon/>

                      <Typography className="choose-p2p-ad-type-helper-text">
                        {t('p2p.chooseAdType.marketPrice')}
                      </Typography>
                    </div>
                  </Tooltip>
                </div>
                <div className="item">
                  <Typography.Text className="create-p2p-ad-medium-text">
                    {chosenCurrency && chosenFiat
                      ? adCurrencyPrice.toFixed(2)
                      : '0.00'}{' '}
                    {chosenFiat?.currency}
                  </Typography.Text>
                  <div style={{display: 'flex', gap: 8}}>
                    <Typography className="choose-p2p-ad-type-helper-text">
                      {t('p2p.chooseAdType.priceAd')}
                    </Typography>
                  </div>
                </div>
              </div>
              <Typography style={{fontSize: 12}}>
                {t('p2p.chooseAdType.description')}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="choose-p2p-ad-type-price-bottom-block">
        <Typography
          className="create-p2p-ad-medium-text"
          style={{textAlign: 'end', width: '100%'}}
        >
          {`1 ${
            chosenCurrency
              ? chosenCurrency.currency
              : t('p2p.chooseAdType.active').toUpperCase()
          } `}
        </Typography>
        <Typography
          className="create-p2p-ad-medium-text"
          style={{textAlign: 'center'}}
        >
          =
        </Typography>
        <Typography
          className="create-p2p-ad-medium-text"
          style={{textAlign: 'start', width: '100%'}}
        >
          {` ${
            chosenFiat
              ? `${adCurrencyPrice ? adCurrencyPrice.toFixed(2) : undefined} ${
                chosenFiat.currency
              }`
              : t('p2p.chooseAdType.fiat').toUpperCase()
          }`}
        </Typography>
      </div>
    </div>
  );
};

const ContainerPriceType = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InputNumberStyled = styled(InputNumber)`
  width: 100%;
  background-color: transparent;
  color: white !important;
  border-radius: 8px;
  height: 32px;
  font-size: 14px;
`;

const ContainerIcons = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 5px;
`;

export default ChooseP2PAdTypeCard;
