import {FC, useEffect, useState} from 'react';
import {IP2PUser} from 'interfaces';
import {Button, Card, Form, Input, Typography} from '@pankod/refine-antd';
import {BasicModal, BasicModalProps} from '../basicModal';
import {useTranslation} from 'react-i18next';
import Icon from "@ant-design/icons";
import {CheckIconN, XMark} from "../../../icons";
import {styled} from "styled-components";

interface ICreateNicknameModalProps extends
  Omit<BasicModalProps, 'title' | 'onOk' | 'children' | 'okText' | 'cancelText'>
{
  userData: IP2PUser;
  onSubmit(userNickname: string): void;
  isLoading: boolean;
  onNicknameChange(): void;
  validationError: string | undefined;
}

export const NicknameModal: FC<ICreateNicknameModalProps> = ({
                                                               userData,
                                                               isLoading,
                                                               validationError,
                                                               onSubmit,
                                                               onNicknameChange,
                                                               ...basicModalProps
                                                             }) => {
  const {t} = useTranslation();

  const {onCancel, width, footer, ...inheritedModalProps} = basicModalProps;

  const lengthRegex = /^[a-zA-Z0-9_-]{1,20}$/;
  const latinLetterRegex = /^[a-zA-Z0-9_-]+$/;
  const [form] = Form.useForm();
  const nickname = Form.useWatch<string | undefined>('nickname', form);
  const [openTooltip, setOpenTooltip] = useState(false);
  const values = Form.useWatch([], form);


  const [isValid, setIsValid] = useState(true);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await form.validateFields(['nickname']);
        setIsValid(true);
      } catch (error) {
        setIsValid(false);
      }
    };

    if (isClicked && !isValid && form) {
      fetchData().then(() => null);
    }

    if (!isValid && !isClicked && form) {
      fetchData().then(() => null);
    }

    if (isValid && isClicked && form) {
      fetchData().then(() => null);
    }
  }, [form, values, isValid, setIsValid, isClicked, setIsClicked]);

  useEffect(() => {
    form.resetFields();
  }, [basicModalProps.open]);

  useEffect(() => {
    onNicknameChange();
  }, [nickname]);

  useEffect(() => {
    validationError &&
    form.setFields([
      {
        name: 'nickname',
        errors: [validationError],
      },
    ]);
  }, [validationError]);

  const handleClickSubmit = async () => {
      await setIsClicked(true);
      form.submit();
  }

  const handleCancel = async () => {
    if (onCancel) {
      onCancel();
    }
    await setIsValid(() => true);
    await setIsClicked(() => false);
  }


  return (
    <StyledBasicModal
      data-qa={"NicknameModal"}
      {...inheritedModalProps}
      width={560}
      footer={
        <div className="custom-modal-footer">
          <Button
            type="primary"
            style={{width: '284px'}}
            onClick={handleClickSubmit}
            disabled={isLoading || !isValid || validationError !== undefined}
          >
            {userData.nickname ? t('buttons.change') : t('buttons.create')}
          </Button>
          <Button
            type="dashed"
            onClick={handleCancel}
          >
            {t('buttons.cancel2')}
          </Button>
        </div>
      }
      okText={t('buttons.confirm2')}
      cancelText={t('buttons.cancel')}
      onCancel={handleCancel}
      destroyOnClose={true}
      title={
        userData && userData.nickname
          ? t('userNickname.changeNickname')
          : t('userNickname.createNickname')
      }
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: 24}}>
        <Typography style={{fontSize: 16, lineHeight: '19.36px', height: '32px'}}>
          {t('userNickname.description')}
        </Typography>
        <Form
          layout="vertical"
          form={form}
          onFinish={(values: { nickname: string }) => onSubmit(values.nickname)}
        >
          <Form.Item
            id="nickname"
            name="nickname"
            required={false}
            style={{marginBottom: '32px'}}
            shouldUpdate
            rules={[
              {
                required: true,
                message: t('userNickname.inputs.errors.required').toString(),
              },
              {
                pattern: /^[a-zA-Z0-9_-]{1,20}$/,
                message: t(
                  'userNickname.inputs.errors.nicknameError'
                ).toString(),
              },
            ]}
            label={
              userData.nickname
                ? t('userNickname.inputs.enterNewNickname')
                : t('userNickname.inputs.enterNickname')
            }
          >
            <Input
              type="text"
              placeholder={t('userNickname.inputs.nickname').toString()}
              onFocus={() => setOpenTooltip(true)}
              onBlur={() => setOpenTooltip(false)}
            />
          </Form.Item>
          {
            openTooltip &&
              <StyledTooltipCard data-qa={"TooltipCard"}>
                <Typography style={{ marginBottom: '16px', fontWeight: '600', fontSize: '12px'}}>
                  {t('userNickname.inputs.errors.nicknameMust')}
                </Typography>

                <StyledCardRowText data-qa={"FirstRule"} style={{ marginBottom: '16px' }}>
                  <div>
                    {
                      lengthRegex.test(nickname ?? '')
                        ? <Icon component={() => CheckIconN(10, '#58BF92')} />
                        : <Icon component={() => XMark(10, '#F16063')} />
                    }
                  </div>
                  {t('userNickname.inputs.errors.fromTo')}
                </StyledCardRowText>

                <StyledCardRowText data-qa={"SecondRule"}>
                  <div>
                    {
                      latinLetterRegex.test(nickname ?? '')
                        ? <Icon component={() => CheckIconN(10, '#58BF92')} />
                        : <Icon component={() => XMark(10, '#F16063')} />
                    }
                  </div>
                  {t('userNickname.inputs.errors.latin')}
                </StyledCardRowText>
              </StyledTooltipCard>
          }
        </Form>
      </div>
    </StyledBasicModal>
  );
};


const StyledBasicModal = styled(BasicModal)`
  .ant-modal-footer {
    margin-top: 0px !important;
  }
`;

const StyledTooltipCard = styled(Card)`
  width: 220px;
  position: absolute;
  top: 186px;
  left: -196px;
  margin-right: 16px;
  background-color: #2B2B36;
  border: none;
  padding: 16px;

  .ant-card-body {
    padding: 0;
  }
`;

const StyledCardRowText = styled.p`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  white-space: pre-wrap;
  color: #fbfbff;
  margin-bottom: 0;
`;
