import styled from "styled-components"
import {CollapseClose} from "./collapseClose"
import {CollapseOpen} from "./collapseOpen"
import {IP2PCoolapseMainData} from "./interface"
import {FC} from "react";

export const Collapse: FC<IP2PCoolapseMainData> = ({
                                                           isOpen,
                                                           dataAd,
                                                           countDeals,
                                                           methodArray,
                                                           setFromCard,
                                                           setOpenModal,
                                                           typeText,
                                                           user,
                                                           onClickOpen,
                                                           regions,
                                                           setIsOpen,
                                                           item
                                                         }) => {
  return (
    <>
      <CollapseContainer isOpen={isOpen}>
        {
          isOpen ?
            <CollapseOpen
              dataAd={dataAd}
              countDeals={countDeals}
              methodArray={methodArray}
              regions={regions}
              setFromCard={setFromCard}
              setIsOpen={setIsOpen}
              setOpenModal={setOpenModal}
              user={user}
              typeText={typeText}
              item={item}
            />
            :
            <CollapseClose
              onClick={onClickOpen}
              dataAd={dataAd}
              methodArray={methodArray}
              setFromCard={setFromCard}
              setOpenModal={setOpenModal}
              countDeals={countDeals}
              typeText={typeText}
              user={user}
            />
        }
      </CollapseContainer>
    </>
  )
}

const CollapseContainer = styled.div<{ isOpen: boolean }>`
    display: flex;
    background-color: #1C1C27;
    border-radius: 8px;
    border: 1px solid #474755;
    flex-direction: row;
    gap: 20px;

    &:hover{
        background-color: ${({isOpen}) => isOpen ? '#1C1C27' : '#2b2b36'};
    }
`;
