import React from 'react';
import { Button, Typography } from '@pankod/refine-antd';
import { ButtonsTypes, ICustomButton } from './filledButton.p';
import '../../../fonts/index.css';
import './index.css';

const { Text } = Typography;

const Index: React.FC<ICustomButton> = ({
  children,
  className,
  textColor = '#FBFBFF',
  width = '100%',
  height = '100%',
  disabledTextColor = '#9499A3',
  isDisabled,
  disabled,
  onClick,
  type = ButtonsTypes.default,
  style,
  ...props
}) => {
  switch (type) {
    case ButtonsTypes.default: {
      return (
        <>
          <Button
            disabled={isDisabled || disabled}
            type={'primary'}
            className={className}
            style={{
              width: width,
              height: height,
              boxShadow: 'unset',
              ...style,
            }}
            onClick={(event) => {
              if (isDisabled || disabled) return;
              onClick(event);
            }}
            {...props}
          >
            <Text
              style={{
                color: isDisabled || disabled ? disabledTextColor : textColor,
                padding: 0,
              }}
            >
              {children}
            </Text>
          </Button>
        </>
      );
    }
    case ButtonsTypes.outlined: {
      return (
        <>
          <Button
            disabled={isDisabled || disabled}
            className={'buttonOutline'}
            type={'primary'}
            style={{
              minWidth: width,
              height: height,
              ...style,
            }}
            onClick={(event) => {
              if (isDisabled || disabled) return;
              onClick(event);
            }}
            {...props}
          >
            <Text
              style={{
                color: isDisabled || disabled ? disabledTextColor : '#FBFBFF',
                padding: 0,
              }}
            >
              {children}
            </Text>
          </Button>
        </>
      );
    }
  }
};

export default Index;
