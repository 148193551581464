import React, { FC, useEffect, useState } from 'react';
import {
  Input,
  Form as AntForm,
  Button,
  Space,
  Typography,
  Tooltip,
} from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';
import {
  FieldData,
  ICryptoPair,
} from '../../../../../features/cryptocurrencyExchange/cryptoCurrencyExchange.p';
import { ReactComponent as SquareQuestionIcon } from '../../../../../shared/components/ui/assets/svg/squareQuestionIcon.svg';
import './styles.css';


interface Props {
  formData: FieldData[];
  currentPair: ICryptoPair;
  onChange: (value: FieldData[]) => void;
  onBeginChange: () => void;
  userData: string | undefined;
  inputCurrency: string;
  inputPrice: number;
  isOrderCreateLoading: boolean;
  onBackStep: () => void;
}

const Form: FC<Props> = ({
  formData,
  currentPair,
  onBeginChange,
  onChange,
  userData,
  inputCurrency,
  inputPrice,
  isOrderCreateLoading,
  onBackStep,
}) => {
  const translate = useTranslate();
  const [form] = AntForm.useForm();

  if (userData) {
    form.setFieldValue('email', userData);
  }
  const makerCommissionPercent = currentPair
    ? currentPair.maker_commission
      ? +currentPair.maker_commission / inputPrice
      : 0
    : 0;
  const commissionSum = currentPair
    ? currentPair.maker_commission && currentPair.taker_commission
      ? +currentPair.maker_commission + +currentPair.taker_commission
      : 0
    : 0;

  const [disable, setDisable] = useState(true);
  const emailWatch = AntForm.useWatch('email', form);
  const walletAddressWatch = AntForm.useWatch('walletAddress', form);
  const handleCreate = () => {
    form.validateFields(['email', 'walletAddress']).then(
      () => {
        onBeginChange();
        setDisable(false);
      },
      () => setDisable(true)
    );
  };

  useEffect(() => {
    form.resetFields();
  }, []);

  return (
    <AntForm
      className="exchange-form"
      form={form}
      layout={'vertical'}
      fields={formData}
      onFieldsChange={(_, allFields) => {
        onChange(allFields);
      }}
      validateTrigger={['onChange']}
      requiredMark={false}
      autoComplete="off"
      name={'validateOnly'}
      initialValues={{ email: undefined, walletAddress: undefined }}
    >
      <AntForm.Item
        label={
          <label htmlFor="email">
            {translate('cryptoExchange.changer.formData.emailTitle')}
          </label>
        }
        name={'email'}
        rules={[
          {
            required: true,
            message: translate('cryptoExchange.changer.formData.emailTitle'),
          },
          {
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: translate(
              'cryptoExchange.changer.formData.emailCorrectTitle'
            ),
          },
        ]}
      >
        <Input
          style={{
            ...(Boolean(!!userData) && { color: '#767687' }),
            height: 56,
          }}
          placeholder={'Email'}
          disabled={!!userData}
          type={'email'}
          onChange={() => {
            form
              .validateFields(
                walletAddressWatch === ''
                  ? ['email']
                  : ['walletAddress', 'email']
              )
              .then(
                () => {
                  if (walletAddressWatch !== '') setDisable(false);
                },
                () => setDisable(true)
              );
          }}
        />
      </AntForm.Item>
      <AntForm.Item
        name={'walletAddress'}
        label={
          <label htmlFor="walletAddress">
            {translate('cryptoExchange.changer.formData.walletAddressTitle')}
          </label>
        }
        rules={[
          {
            required: true,
            message: translate(
              'cryptoExchange.changer.formData.enterWithdrawalWalletAddress'
            ),
          },
          {
            pattern: /^[a-zA-Z\d_-]+$/,
            message: translate(
              'cryptoExchange.changer.formData.enterCorrectWithdrawalWalletAddress'
            ),
          },
          {
            min: 25,
            max: 44,
            message: translate(
              'cryptoExchange.changer.formData.enterCorrectWithdrawalWalletAddress'
            ),
          },
        ]}
      >
        <Input
          placeholder={translate(
            'cryptoExchange.changer.formData.walletAddressPlaceholder'
          )}
          style={{
            height: 56,
          }}
          onChange={() => {
            form
              .validateFields(
                emailWatch === ''
                  ? ['walletAddress']
                  : ['walletAddress', 'email']
              )
              .then(
                () => {
                  if (emailWatch !== '') setDisable(false);
                },
                () => setDisable(true)
              );
          }}
        />
      </AntForm.Item>
      <AntForm.Item>
        <div className="exchange-form-description-wrapper">
          <Space style={{ marginBottom: 58 }} direction="vertical">
            <span className={'form-description'}>
              {translate('cryptoExchange.changer.formData.additionalInfo')}
            </span>
          </Space>
          <div className={'container-button'} style={{ height: 40 }}>
            <Button
              style={{
                flex: 1,
                border: '1px solid #7B59FD',
                height: '100%',
                background: 'none',
                color: '#FBFBFF',
              }}
              onClick={onBackStep}
              // disabled={stepState === 1}
            >
              {`${translate('buttons.back')}`}
            </Button>
            <Button
              style={{ flex: 2, height: '100%' }}
              type="primary"
              onClick={handleCreate}
              disabled={isOrderCreateLoading || disable}
            >
              {translate('cryptoExchange.changer.formData.buttonText')}
            </Button>
          </div>
          <div className="exchange-form-description">
            <Typography.Text>
              {translate(
                'cryptoExchange.changer.formData.helperText.serviceCom'
              )}
            </Typography.Text>
            <Typography.Text className="bold">
              {commissionSum} {inputCurrency}
            </Typography.Text>
            <Tooltip
              overlayInnerStyle={{ minWidth: 'max-content' }}
              placement="top"
              title={
                <div>
                  <div
                    className={'form-description'}
                    style={{ display: 'flex' }}
                  >
                    {translate(
                      'cryptoExchange.changer.formData.helperText.serviceCom'
                    )}{' '}
                    {`${makerCommissionPercent}%`} &#10142;{' '}
                    <b>
                      {currentPair ? currentPair.maker_commission : 0}{' '}
                      {inputCurrency}
                    </b>
                  </div>
                  <div
                    className={'form-description'}
                    style={{ display: 'flex' }}
                  >
                    {translate(
                      'cryptoExchange.changer.formData.helperText.networkCom'
                    )}{' '}
                    &#10142;{' '}
                    <b>
                      {currentPair ? currentPair.taker_commission : 0}{' '}
                      {inputCurrency}
                    </b>
                  </div>
                </div>
              }
            >
              <SquareQuestionIcon className="tooltip-icon" />
            </Tooltip>
          </div>
        </div>
      </AntForm.Item>
    </AntForm>
  );
};

export default Form;
