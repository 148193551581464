import React, {useContext} from 'react';
import {Tabs} from 'antd';
import Icon from '@ant-design/icons';
import {ConfigProvider, Icons} from '@pankod/refine-antd';
import {useGetScreenSize} from '../../shared/hooks/screen/use-get-screen-size';
import {SpotMarketContext} from '../../pages/spot/spotDashboard/spotDashboard';
import {useTranslation} from 'react-i18next';
import {TVChartContainer} from '../../features/spot/chart/TVChartContainer';
import Select from '../../shared/components/ui/select';
import {FullScreenIcon} from '../../shared/components/icons';
import {SpotMarkerDataList} from '../../features/spot/orderList/markerDataList';

const {DownOutlined} = Icons;

const SpotChart = () => {
    const {
        dashboard: {spotOrderBookListData},
    } = useContext(SpotMarketContext);
    const {t} = useTranslation();
    const {width} = useGetScreenSize();
    const {tradeInfo} = useContext(SpotMarketContext);

    const isMobile = width <= 390;

    return (
        <ConfigProvider
            theme={{
                components: {
                    Tabs: {paddingSM: 8},
                },
            }}
        >
            <div style={{width: '100%', height: '100%'}}>
                <TVChartContainer interval={'5'} symbol={''}/>
                {isMobile && (
                    <>
                        <div style={{position: 'relative', height: '100%'}}>
                            <Tabs
                                items={[
                                    {
                                        label: t('orderBookExpanded.orderBook'),
                                        key: 'orderBook',
                                    },
                                    {
                                        label: t('orderBook.tradingHistory'),
                                        key: 'tradingHistory',
                                    },
                                ]}
                                style={{marginBottom: 0}}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    display: 'flex',
                                    alignSelf: 'center',
                                    height: '100%',
                                    right: 0,
                                    top: 0,
                                    gap: '16px',
                                }}
                            >
                                <Select
                                    dropdownStyle={{
                                        minWidth: 70,
                                    }}
                                    controlHeight={32}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    bordered={false}
                                    // value={selectorValue}
                                    suffixIcon={
                                        <DownOutlined style={{color: '#767687', fontSize: 12}}/>
                                    }
                                    setValue={(e) => {
                                        // setSelectorValue(e);
                                        // fb.emit('changeOrderBookRound', e);
                                    }}
                                    data={[
                                        {label: '0.01', value: 2},
                                        {label: '0.1', value: 1},
                                        {label: '1', value: 0},
                                    ]}
                                />
                                <Icon
                                    onClick={() => {
                                        // const pair = activePair.replace(new RegExp('/', 'g'), '-');
                                        // navigate(`/spot/dashboard/order-book-expanded/${pair}`);
                                    }}
                                    style={{cursor: 'pointer'}}
                                    component={() => <FullScreenIcon size={16}/>}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: 8,
                                display: 'inline-flex',
                            }}
                        >
                            <div
                                style={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    gap: 4,
                                    display: 'inline-flex',
                                }}
                            >
                                <div
                                    style={{
                                        color: '#FBFBFF',
                                        fontSize: 16,
                                        fontFamily: 'Inter',
                                        fontWeight: '700',
                                        wordWrap: 'break-word',
                                    }}
                                >
                                    {tradeInfo?.mainCurrencyData || 0}
                                </div>
                                <div
                                    style={{
                                        color: '#FBFBFF',
                                        fontSize: 10,
                                        fontFamily: 'Inter',
                                        fontWeight: '500',
                                        wordWrap: 'break-word',
                                    }}
                                >
                                    ≈ {tradeInfo?.currencyInFiatData || 0} $ USD
                                </div>
                            </div>
                            <div
                                style={{
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'flex-end',
                                    display: 'inline-flex',
                                }}
                            >
                                <div
                                    style={{
                                        paddingBottom: 4,
                                        borderBottom:
                                            '0.50px rgba(64.69, 60.76, 79.69, 0.50) solid',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        gap: 8,
                                        display: 'inline-flex',
                                    }}
                                >
                                    <div
                                        style={{
                                            color: '#767687',
                                            fontSize: 12,
                                            fontFamily: 'Inter',
                                            fontWeight: '500',
                                            wordWrap: 'break-word',
                                        }}
                                    >
                                        Объем
                                    </div>
                                    <div
                                        style={{
                                            color: '#FBFBFF',
                                            fontSize: 12,
                                            fontFamily: 'Inter',
                                            fontWeight: '500',
                                            wordWrap: 'break-word',
                                        }}
                                    >
                                        1.98700000 BTC
                                    </div>
                                </div>
                                <div
                                    style={{
                                        paddingTop: 4,
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        gap: 8,
                                        display: 'inline-flex',
                                    }}
                                >
                                    <div
                                        style={{
                                            color: '#767687',
                                            fontSize: 12,
                                            fontFamily: 'Inter',
                                            fontWeight: '500',
                                            wordWrap: 'break-word',
                                        }}
                                    >
                                        Объем
                                    </div>
                                    <div
                                        style={{
                                            color: '#FBFBFF',
                                            fontSize: 12,
                                            fontFamily: 'Inter',
                                            fontWeight: '500',
                                            wordWrap: 'break-word',
                                        }}
                                    >
                                        2344.64 USDT
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SpotMarkerDataList
                            dataList={spotOrderBookListData?.spotMarketSaleData?.dataList}
                            progressColor={
                                spotOrderBookListData?.spotMarketSaleData?.progressColor!
                            }
                            priceColor={
                                spotOrderBookListData?.spotMarketSaleData?.priceColor!
                            }
                            amountCurrency={spotOrderBookListData?.amountCurrency}
                            priceCurrency={spotOrderBookListData?.priceCurrency}
                        />
                    </>
                )}
            </div>
        </ConfigProvider>
    );
};

export {SpotChart};
