import {
  CSSProperties,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Styles from '../index.module.css';
import { Typography } from '@pankod/refine-antd';
import { IUploadAttachment } from '../../../interfaces/entity';
import { axios } from '../../../../../shared/exios';
import { API_URL } from '../../../../../packages/keycloak-client/constants';
import { MessageModalFile } from './message-modal-file';
import { PdfFile } from '../../../../../shared/components/ui/assets/svg/pdfFile';
import styled from 'styled-components';
import { getTime } from '../../../helpers/getTime';
import { CheckAllIcon } from '../../../../../shared/components/ui/assets/svg/check-all';
import { MessageContainerContext } from '../index';

type ChatMessageColors = 'default' | 'purple';
type ChatMessageFloat = 'left' | 'right';

interface IChatMessageProps extends PropsWithChildren {
  color: ChatMessageColors;
  float?: ChatMessageFloat;

  date: Date;
  // Avatar?: JSX.Element;
  message: string;
  attachments: IUploadAttachment[];
  senderNickName: string;
}

const COLORS: Record<ChatMessageColors, string> = {
  default: '#2B2B36',
  purple: 'linear-gradient(75.86deg, #6320E6 6.84%, #7B59FD 99.53%)',
};

const POSITION: Record<ChatMessageFloat, CSSProperties> = {
  left: {},
  right: { flexDirection: 'row-reverse' },
};

const ChatMessage = ({
  message,
  date,
  float,
  attachments,
  // Avatar,
  color,
  senderNickName,
}: IChatMessageProps): JSX.Element => {
  const { containerRef, setPrevHeight } = useContext(MessageContainerContext);
  const [fileUrl, setFileUrl] = useState<string>('');
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const messageRef = useRef<HTMLDivElement>(null);
  const time = useMemo<string>(() => {
    return getTime(date);
  }, [date]);

  const handleOpenModal = () => {
    setImgModalOpen(true);
  };

  const handleLoad = () => {
    setPrevHeight(containerRef?.current?.scrollHeight || 0);
  };

  useEffect(() => {
    async function getFile() {
      if (attachments && attachments.length) {
        const res = await axios.get(
          `${API_URL}/p2p/api/attachment/${attachments[0].id}`
        );
        const downloadLink = res.data.data.download_link;
        setFileUrl(downloadLink);
      }
    }

    getFile();
  }, [attachments]);

  //TODO: реализовать прочитку сообщений

  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         // Элемент видим
  //         // console.log('Элемент видим', message);
  //       } else {
  //         // Элемент не видим
  //         // console.log('Элемент не видим', message);
  //       }
  //     });
  //   });
  //
  //   if (messageRef.current) {
  //     observer.observe(messageRef.current);
  //   }
  //
  //   return () => {
  //     if (messageRef.current) {
  //       observer.unobserve(messageRef.current);
  //     }
  //   };
  // }, []);

  return (
    <div
      className={Styles.message__container}
      style={{
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        ...POSITION[float ?? 'left'],
        marginRight: '10px'
        // ...(Avatar ? { gap: 10 } : {}),
      }}
      ref={messageRef}
    >
      {/*{props.Avatar && <div style={{ width: 32 }}>{props.Avatar}</div>}*/}

      <div
        className={Styles.message}
        style={{
          background: COLORS[color],
        }}
      >
        {!!attachments && !!attachments.length && !!attachments[0] && (
          <AttachmentWrapperStyled right={float === 'right'}>
            <div className={Styles.message__img} onClick={handleOpenModal}>
              {attachments[0].file_extension === 'pdf' ? (
                <div className={Styles.message__pdf}>
                  <div>
                    <PdfFile />
                  </div>
                  <div className={Styles.message__pdf_text}>
                    {attachments[0].file_name.length > 30
                      ? attachments[0].file_name.substring(0, 30) + '...'
                      : attachments[0].file_name}
                  </div>
                </div>
              ) : (
                <img onLoad={handleLoad} src={fileUrl} alt="Attachment" />
              )}
            </div>
          </AttachmentWrapperStyled>
        )}
        <Typography.Text className={Styles.message__content}>
          {message}
        </Typography.Text>
        <div className={Styles.message__info}>
          <Typography.Text style={{ fontSize: 10 }}>{time}</Typography.Text>
          {float === 'right' && <CheckAllIcon checked />}
        </div>
      </div>
      {attachments && attachments[0] && (
        <MessageModalFile
          senderNickname={senderNickName}
          fileDownloadLink={fileUrl}
          attachment={attachments[0]}
          open={imgModalOpen}
          onClose={() => {
            setImgModalOpen(false);
          }}
          date={date}
        />
      )}
    </div>
  );
};

const AttachmentWrapperStyled = styled.div<{ right: boolean }>`
  display: flex;
  justify-content: ${({ right }) => (right ? 'flex-end' : 'flex-start')};
`;

export { ChatMessage };
