import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';
import { PayService } from '../../../../../pages/p2p/settings/payment/payment';
import './styles.css';

interface IProps {
  templatePay: PayService;
  error: string;
  clickSubmit: boolean;
  onChange: (value: string, key: keyof PayService) => void;
}
const Comment: FC<IProps> = ({ templatePay, error, clickSubmit, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className={'bank-container-input'}>
      <span className={'bank-input-label'}>{t('payments.enterComment')}</span>
      <Input
        value={templatePay.comment}
        status={error === 'comment' && clickSubmit ? 'error' : undefined}
        onChange={(e) => onChange(e.target.value, 'comment')}
        placeholder={t('payments.comment').toString()}
      />
      {error === 'comment' && clickSubmit && (
        <span className={'bank-input-label-error'}>
          {t('payments.maxCommentLength')}
        </span>
      )}
    </div>
  );
};
export default Comment;
