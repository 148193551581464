
export enum AdTypesEnum {
  BUY = 'buy',
  SELL = 'sell',
}

export enum AdPriceType {
  FIXED = 'fixed',
  FLOAT = 'floating',
}

export interface IFiat {
  id: number;
  name: string;
  currency: string;
  dollar_exchange: number;
  is_available: boolean;
  created_at: string;
  updated_at: string;
}

export enum CreateP2PAdSteps {
  CHOOSE_AD_TYPE,
  CHOOSE_LIMITS_AND_PAYMENT_TYPE,
  SET_ADDITIONAL_PARAMS,
  CHECK_AD_DATA,
}

export interface IRegion {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface IConfigCurrencyPair {
  id: number;
  pair: string;
  currency_id: string;
  currency_pair_id: string;
  type: string;
  latest_price: string | null;
  is_available: boolean;
  auto_blocked: boolean;
  created_at: string;
  updated_at: string;
}

export enum P2PAdStatus {
  CREATED = 'created',
  DELETED = 'deleted',
  TEMPLATE = 'template',
  EXECUTED = 'executed',
}

export enum PaymentTypeEnum {
  bankCardPayment = 'bankCardPayment',
  mobileBalancePayment = 'mobileBalancePayment',
  cashPayment = 'cashPayment',
  cashinPayment = 'cashinPayment',
  bankTransferPayment = 'bankTransferPayment',
  fastPaymentSystemPayment = 'fastPaymentSystemPayment',
  webServicePayment = 'webServicePayment',
}

interface IPaymentTypeDetails {
  id: number;
  commentary: string;
  bank_account_number?: string;
  phone?: string;
  web_service_name?: string;
  web_service_account_id?: string;
  bank_id?: number;
  card_identifier?: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  account_id: string;
}

interface IPaymentTypeBase<T extends PaymentTypeEnum, D extends {}> {
  id: number;
  payment_id: number;
  payment_type: T;
  account_id: string;
  created_at: string | null;
  updated_at: string | null;
  is_deleted: boolean;
  payment_details: Array<IPaymentTypeDetails & D>;
}

export type IPaymentType =
  | IPaymentTypeBase<
      PaymentTypeEnum.bankCardPayment,
      { bank_id: number; card_identifier: string; bank_name: string | null }
    >
  | IPaymentTypeBase<PaymentTypeEnum.mobileBalancePayment, { phone: string }>
  | IPaymentTypeBase<PaymentTypeEnum.cashPayment, {}>
  | IPaymentTypeBase<
      PaymentTypeEnum.webServicePayment,
      { web_service_name: string }
    >
  | IPaymentTypeBase<PaymentTypeEnum.bankTransferPayment, { bank_name: string }>
  | IPaymentTypeBase<
      PaymentTypeEnum.fastPaymentSystemPayment,
      { web_service_account_id: number | null; web_service_name: string | null }
    >;

export type IAllPaymentTypesItem = Pick<
  IPaymentType,
  'id' | 'payment_type' | 'created_at' | 'updated_at'
>;

export interface ICreateP2PAdInfo {
  owner_id?: number;
  ccy: string;
  fiat: string;
  price: number;
  price_type: AdPriceType;
  price_spread?: string;
  min_sum_limit: number;
  max_sum_limit: number;
  total_sum: number;
  reaction_time?: string;
  transaction_time?: string;
  auto_response?: string;
  deal_terms?: string;
  minimal_registration_days?: string;
  status?: P2PAdStatus;
  payment_info?: Array<number>;
  buy_payment_info?: Array<any>;
  region_id?: number;
  deleted_at?: string;
  under_maratorium?: boolean;
  type: AdTypesEnum;
}

export interface ICoinAccountWallet {
  hash: string;
  id: number;
  owner_type: string;
  owner_id: string;
  account: string;
  amount: number;
  hold: number;
  deposit: number;
  created_at: string;
  updated_at: string;
}
