import React from 'react';
import SpotTableLoginInner from './spotTableLoginInner';
import styled from 'styled-components';

const SpotTableLogin = () => {
  return (
    <SpotTableLoginWrapper>
      <SpotTableLoginInner />
    </SpotTableLoginWrapper>
  );
};

const SpotTableLoginWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default SpotTableLogin;
