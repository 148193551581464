import React from 'react';
import { MenuProps } from 'antd/es/menu/menu';
import HeaderDropdown from './headerDropdown';
import { DropdownProps } from 'antd';

interface INormalDropdown extends DropdownProps {
  defaultValue?: string;
  currentValue?: string;
  items: MenuProps['items'];
  content?: JSX.Element;
  isSelectable?: boolean;
  itemWidth?: number;
}

const NormalDropdown: React.FC<INormalDropdown> = ({
  items,
  currentValue = '',
  defaultValue = '',
  isSelectable,
  content,
  itemWidth = 196,
  ...props
}) => {
  return (
    <>
      <HeaderDropdown
        defaultValue={currentValue}
        items={items?.map((item) => {
          if (item) {
            return {
              ...item,
              style: {
                minWidth: itemWidth,
                height: 48,
                borderBottom: '1px solid #474755',
                borderRadius: '4px',
                ...item.style,
              },
            };
          } else {
            return item;
          }
        })}
        currentValue={defaultValue}
        content={content}
        isSelectable={isSelectable}
        {...props}
      />
    </>
  );
};

export default NormalDropdown;
