import {IUseGetPaymentInfoIconData} from "../../../../shared/hooks/use-get-payment-info-icon-data";
import React from "react";
import {useTranslation} from "react-i18next";
import {Typography} from "antd";
import styled from "styled-components";
import {Icons, Tooltip} from "@pankod/refine-antd";

const {CopyOutlined} = Icons;

interface IBankTransferInfo {
  paymentData: IUseGetPaymentInfoIconData | undefined;
  hideTransferData?: boolean;
  isDealSuccess?: boolean;
  isColumnDisplay: boolean;
  isCopy: boolean;
}

const BankTransferInfo: React.FC<IBankTransferInfo> = ({
                                                         paymentData,
                                                         hideTransferData,
                                                         isDealSuccess,
                                                         isColumnDisplay,
                                                         isCopy,
                                                       }) => {
  const {t} = useTranslation();

  async function copyText(textToCopy: string) {
    try {
      if (navigator?.clipboard?.writeText) {
        await navigator.clipboard.writeText(textToCopy);
      }
    } catch (err) {
      console.error('Failed to copy!', err);
    }
  }

  const getCardRowDirection = () => {
    return isColumnDisplay ? 'column' : 'row'
  }

  return (
    <>
      <CardRowFirst
        $direction={getCardRowDirection()}
        $isColumnGap={isColumnDisplay}
        $isColumnMargin={isColumnDisplay}
      >
        <InfoBlockFirst>
          <BlockDescription>
            {paymentData?.icon}
            <Typography.Text className={'middle-bold-14px-text'}>
              {paymentData?.bankTransfer?.bank_name}
            </Typography.Text>
            {
              isCopy && (
                (hideTransferData || isDealSuccess)
                  ? <CopyOutlinedStyled/>
                  : <Tooltip
                    trigger={'click'}
                    placement="top"
                    title={t(
                      'p2p.transferInfo.copied'
                    )}
                    color="#2B2B36"
                    overlayInnerStyle={{color: '#FBFBFF', width: '124px'}}
                  >
                    <CopyBtn
                      onClick={() => copyText(paymentData?.bankTransfer?.bank_name ?? '')}
                    >
                      <CopyOutlinedStyled/>
                    </CopyBtn>
                  </Tooltip>


              )
            }
          </BlockDescription>

          <BlockTitle>
            <Typography.Text className={'light-grey-12px-text'}>
              {t('p2p.transferInfo.bankName')}
            </Typography.Text>
          </BlockTitle>
        </InfoBlockFirst>

        <InfoBlock>
          <BlockDescription>
            <Typography.Text className={'middle-bold-14px-text'}>
              {paymentData?.bankTransfer?.bank_account_number}
            </Typography.Text>
            {
              isCopy && (
                (hideTransferData || isDealSuccess)
                  ? <CopyOutlinedStyled/>
                  : <Tooltip
                    trigger={'click'}
                    placement="top"
                    title={t(
                      'p2p.transferInfo.copied'
                    )}
                    color="#2B2B36"
                    overlayInnerStyle={{color: '#FBFBFF', width: '124px'}}
                  >
                    <CopyBtn onClick={() => copyText(paymentData?.bankTransfer?.bank_account_number ?? '')}>
                      <CopyOutlinedStyled/>
                    </CopyBtn>
                  </Tooltip>
              )
            }
          </BlockDescription>
          <BlockTitle>
            <Typography.Text className={'light-grey-12px-text'}>
              {t('p2p.transferInfo.accountNumber')}
            </Typography.Text>
          </BlockTitle>
        </InfoBlock>
      </CardRowFirst>

      <CardRow
        $direction={isColumnDisplay ? 'column' : 'row'}
        $isColumnGap={isColumnDisplay}
      >
        {
          paymentData?.bankTransfer?.account_office === 'null'
            ? <></>
            : <InfoBlock style={{width: '435px'}}>
              <BlockDescription>
                <Typography.Text className={'middle-bold-14px-text'}>
                  {paymentData?.bankTransfer?.account_office}
                </Typography.Text>
                {
                  isCopy && (
                    (hideTransferData || isDealSuccess)
                      ? <CopyOutlinedStyled/>
                      : <Tooltip
                        trigger={'click'}
                        placement="top"
                        title={t(
                          'p2p.transferInfo.copied'
                        )}
                        color="#2B2B36"
                        overlayInnerStyle={{color: '#FBFBFF', width: '124px'}}
                      >
                        <CopyBtn onClick={() => copyText(paymentData?.bankTransfer?.account_office ?? '')}>
                          <CopyOutlinedStyled/>
                        </CopyBtn>
                      </Tooltip>
                  )
                }
              </BlockDescription>
              <BlockTitle>
                <Typography.Text className={'light-grey-12px-text'}>
                  {t('p2p.transferInfo.accountDepartment')}
                </Typography.Text>
              </BlockTitle>
            </InfoBlock>
        }

        <InfoBlock>
          <BlockDescription>
            <Typography.Text className={'middle-bold-14px-text'}>
              {paymentData?.bankTransfer?.bank_identification_code}
            </Typography.Text>
            {
              isCopy && (
                (hideTransferData || isDealSuccess)
                  ? <CopyOutlinedStyled/>
                  : <Tooltip
                    trigger={'click'}
                    placement="top"
                    title={t(
                      'p2p.transferInfo.copied'
                    )}
                    color="#2B2B36"
                    overlayInnerStyle={{color: '#FBFBFF', width: '124px'}}
                  >
                    <CopyBtn onClick={() => copyText(paymentData?.bankTransfer?.bank_identification_code ?? '')}>
                      <CopyOutlinedStyled/>
                    </CopyBtn>
                  </Tooltip>
              )
            }
          </BlockDescription>
          <BlockTitle>
            <Typography.Text className={'light-grey-12px-text'}>
              {t('p2p.transferInfo.bic')}
            </Typography.Text>
          </BlockTitle>
        </InfoBlock>
      </CardRow>
    </>
  );
}
export default BankTransferInfo;

const CardRowFirst = styled.div <{ $direction: string, $isColumnGap: boolean, $isColumnMargin: boolean }>`
  display: flex;
  flex-direction: ${({$direction}) => ($direction === 'column' ? 'column' : 'row')};
  gap: ${({$isColumnGap}) => ($isColumnGap ? '16px' : '32px')};
  margin-bottom: ${({$isColumnMargin}) => ($isColumnMargin ? '16px' : '12px')};
`;

const CardRow = styled.div <{ $direction: string, $isColumnGap: boolean }>`
  display: flex;
  flex-direction: ${({$direction}) => ($direction === 'column' ? 'column' : 'row')};
  gap: ${({$isColumnGap}) => ($isColumnGap ? '16px' : '32px')};
`;

const InfoBlockFirst = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  height: 36px;
  width: 435px;
`;

const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 36px;
`;

const BlockTitle = styled.div``;

const BlockDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CopyBtn = styled.button`
  width: 16px;
  height: 16px;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const CopyOutlinedStyled = styled(CopyOutlined)`
  font-size: 16px;
  color: #FBFBFF;
`;
