import {useTranslation} from 'react-i18next';
import {BasicModal} from '../../../../../shared/components/ui/modal/basicModal';
import {Button, UploadFile} from '@pankod/refine-antd';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import s from './index.module.css';
import {Plus} from '../../../../../shared/components/ui/assets/svg/Plus';
import {Trash} from '../../../../../shared/components/ui/assets/svg/Trash';
import {Upload} from '../upload';
import {RcFile} from 'antd/es/upload';
import {getBase64} from './helpers/getBase64';
import {PdfFileBlock} from './pdfFileBlock';

interface ILoadMofal {
    files: UploadFile[] | null;
    open: boolean;
    setOpen: (value: boolean) => void;

    setFiles(value: UploadFile[] | null): void;
}

const LoadModal = ({
                       open,
                       files,
                       setOpen,
                       setFiles,
                   }: ILoadMofal): JSX.Element => {
    const {t} = useTranslation();
    const [error, setError] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const fileIsPdf = !!files && /\.pdf$/.test(files[0].name as string);

    const handleDeleteFile = () => {
        setError(false);
        setFiles(null);
        setPreviewImage('');
    };

    const handleCancel = () => {
        handleDeleteFile();
        setOpen(false);
    };

    const handleOk = () => {
        if (
            files &&
            files?.length === 1 &&
            /\.jpg|pdf|png$/.test(files[0].name as string) &&
            (files[0].size as number) <= 2000000
        ) {
            setOpen(false);
            return;
        }
        setError(true);
        setFiles(null);
        return;
    };

    const handleChangeUpload = ({fileList}: { fileList: UploadFile[] }) => {
        setError(false);
        setFiles(fileList);
    };

    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
    };

    useEffect(() => {
        async function getPreview() {
            if (files && files[0]) {
                if (!files[0].url && !files[0].preview) {
                    files[0].preview = await getBase64(files[0].originFileObj as RcFile);
                }
                setPreviewImage(files[0].url || (files[0].preview as string));
            }
        }

        getPreview();
    }, [files]);

    return (
        <BasicModal
            title={'Прикрепление файлов'}
            open={open}
            onCancel={handleCancel}
            onOk={handleOk}
            width={560}
            footer={null}
        >
            <div className={s.content__container}>
                <div className={s.textContainer}>
                    <p className={s.text} style={{marginBottom: '20px'}}>
                        {t('Предупреждение личные данные')}
                    </p>
                    <ErrorText $isError={error} className={s.text}>
                        {t('В обном сообщении размер файлов')}
                    </ErrorText>
                </div>
                <div
                    className={`${s.content__file} ${fileIsPdf && s.content__filePdf}`}
                >
                    {!files ? (
                        <Upload
                            onChange={handleChangeUpload}
                            icon={<Plus/>}
                            onModal={true}
                            handlePreview={handlePreview}
                        />
                    ) : (
                        <>
                            <div className={s.filePreview}>
                                {/*{files?.map((file) => (*/}
                                {/*  <div key={file.uid}>{file.name}</div>*/}
                                {/*))}*/}
                                {fileIsPdf ? (
                                    <PdfFileBlock
                                        name={files[0].name}
                                        size={files[0].size || 0}
                                    />
                                ) : (
                                    <img
                                        alt="imgPreview"
                                        style={{width: '100%'}}
                                        src={previewImage}
                                    />
                                )}
                            </div>
                            <Button
                                style={{background: 'rgba(65, 61, 80, 0.50)'}}
                                className={`${s.deleteBtn} ${fileIsPdf && s.deleteBtnPdf}`}
                                type="text"
                                icon={<Trash/>}
                                size="large"
                                disabled={false}
                                onClick={handleDeleteFile}
                            />
                        </>
                    )}
                </div>
                <div className={s.footer}>
                    <AttachBtn disabled={!(files && files[0])} onClick={handleOk}>
                        {t('Прикрепить файл')}
                    </AttachBtn>
                    <CancelBtn onClick={handleCancel}>{t('Отменить')}</CancelBtn>
                </div>
            </div>
        </BasicModal>
    );
};

const ErrorText = styled.p<{ $isError: boolean }>`
    color: ${(props) =>
            props.$isError ? 'rgba(241, 96, 99, 1)' : 'rgba(251, 251, 255, 1)'};
`;

const AttachBtn = styled(Button)`
    width: 284px !important;
    background: ${({disabled}) =>
            disabled
                    ? '#2B2B36'
                    : 'linear-gradient(    75.86deg,    #6320e6 6.84%,    #7b59fd 99.53%  )'} !important;

    &:hover {
        color: ${({disabled}) => (disabled ? '#767687' : '#fbfbff')} !important;
    }
`;

const CancelBtn = styled(Button)`
    width: 180px !important;
    background: inherit !important;
    border: 1px solid #6320e5 !important;

    &:hover {
        color: #fbfbff !important;
    }
`;

export {LoadModal};
