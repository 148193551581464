import {Icon, Typography} from '@pankod/refine-antd';
import {FC} from 'react';
import {ReactComponent as CloseIcon} from '../../../shared/components/ui/assets/svg/modal-close-icon.svg';
import {useTranslation} from 'react-i18next';
import {AdTypesEnum} from './types';
import styled from "styled-components";
import CustomBreadcrumb from "../../../shared/components/ui/breadcrumbs";

interface IPageHeadingProps {
  onClose?(): void;

  adType: AdTypesEnum;
}

const PageHeading: FC<IPageHeadingProps> = ({onClose, adType}) => {
  const {t} = useTranslation();
  return (
    <Wrapper>
      <CustomBreadcrumb
        items={[
          {
            title: t('createAd.breadcrumbs.main'),
            link: '/p2p/dashboard'
          },
          {
            title: t('createAd.breadcrumbs.createAd'),
            link: '',
          },
        ]}
      />

      <TitleWrapper>
        <TypographyStyled>
          {`${t('p2p.createOrder')} ${
            adType === AdTypesEnum.BUY ? t('p2p.forPurchase') : t('p2p.forSale')
          }`}
        </TypographyStyled>
        <StyledIcon
          onClick={onClose}
          component={CloseIcon}
        />
      </TitleWrapper>
    </Wrapper>
  );
};

export default PageHeading;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const TypographyStyled = styled(Typography)`
  font-size: 40px;
  font-weight: 700;
`

const StyledIcon = styled(Icon)`
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
`
