import {Typography} from "@pankod/refine-antd"
import {BaseRecord, useTranslate} from "@pankod/refine-core"
import {styled} from "styled-components"

export const AdPaymentTypes = ({
                                 record, PaymentTypesBlock
                               }: {
  record: BaseRecord,
  PaymentTypesBlock: (record: BaseRecord, type: string) => any
}) => {
  const t = useTranslate();
  return (
    <Container>
      {
        <div className="flex-column gap-16">
          <div className="flex-row items-center">
            <GreyThinTitle>
              {t('announcements.params.paymentTypes') + ' '}
            </GreyThinTitle>
            <Typography.Text className="text-gray">
              (
              {record.type === 'buy'
                ? record.buy_payment_info?.length ?? '0'
                : record.payment_info?.length ?? '0'}
              /5)
            </Typography.Text>
          </div>
          <div className="flex-row gap-8 flex-wrap-wrap">
            {record && PaymentTypesBlock(record, record.type)}
          </div>
        </div>
      }
    </Container>
  )
}

const GreyThinTitle = styled(Typography.Text)`
  font-weight: 400;
  color: #767687;
  line-height: 14.52px;
`

const Container = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`
