import { axios } from '../../../shared/exios';
import { API_URL } from '../../../packages/keycloak-client/constants';

export const timeType = {
  '1D': 86400,
  '12H': 43200,
  '6H': 21600,
  '60': 3600,
  '30': 1800,
  '15': 900,
  '5': 300,
};
const getActivePair = () => {
  return localStorage['activePair']
    ?.toLowerCase()
    .replace('-', '')
    ?.replace(new RegExp('-', 'g'), '');
};

export async function makeApiRequest({
  type,
  limit = 2000,
}: {
  type: number;
  limit?: number;
}) {
  try {
    const response = await axios.post(
      `${API_URL}/${getActivePair()}-spot/api/chart/search?limit=${limit}`,
      {
        sort: [{ field: 'd', direction: 'asc' }],
        filters: [{ field: 'type', operator: '=', value: type }],
      }
    );
    return response.data;
  } catch (error: any) {
    throw new Error(`CryptoCompare request error: ${error.status}`);
  }
}
