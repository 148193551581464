import {FormInstance, Icon, Tooltip, Typography} from '@pankod/refine-antd';
import {ICryptoCurrencyConstraintItem} from 'widgets/order/types';
import {AdPriceType, AdTypesEnum, IFiat, IPaymentType, IRegion} from '../../types';
import React, {FC} from 'react';
import {ReactComponent as ArrowForwardIcon} from '../../../../../shared/components/ui/assets/svg/arrowForward.svg';
import {getMoneyIcon} from 'shared/helpers/getMoneyIcon';
import {useTranslation} from 'react-i18next';
import {IPaymentsType} from '../../../../../pages/p2p/dashboard/interface';
import styled from 'styled-components';
import {ExclamationCircle} from "../../../../../shared/components/icons";
import {BaseRecord} from "@pankod/refine-core";
import UserPaymentList from './components/userPaymentList';
import UserPaymentListBuy from './components/userPaymentListBuy';
import InfoItems from './components/infoItems';
import ItemsSecondLine from './components/itemsSecondLine';

interface ICheckAdDataProps {
    chosenCurrency?: ICryptoCurrencyConstraintItem;
    chosenFiat?: IFiat;
    adCurrencyPrice: number;
    adPriceType: AdPriceType;
    totalSum: number;
    minLimit: number;
    maxLimit: number;
    chosenRegion?: IRegion;
    additionalParamsForm: FormInstance;
    userPaymentList: IPaymentType[];
    userPaymentListBuy: any[];
    createAdType: AdTypesEnum;
    iconsData?: IPaymentsType[];
    record?: BaseRecord;
    existingAds?: string[];
}

const CheckOrderData: FC<ICheckAdDataProps> = ({
                                                   userPaymentList,
                                                   additionalParamsForm,
                                                   chosenCurrency,
                                                   chosenFiat,
                                                   adCurrencyPrice,
                                                   adPriceType,
                                                   totalSum,
                                                   minLimit,
                                                   maxLimit,
                                                   chosenRegion,
                                                   createAdType,
                                                   userPaymentListBuy,
                                                   iconsData = [],
                                                   record,
                                                   existingAds
                                               }) => {
    const {t} = useTranslation();

    const getPaymentTypesList = () => {
        return (createAdType !== AdTypesEnum.BUY
            ? <UserPaymentList userPaymentList={userPaymentList} iconsData={iconsData}/>
            : <UserPaymentListBuy userPaymentListBuy={userPaymentListBuy}/>)
    }

    const exchangeItemStyles = {
        justify: createAdType === AdTypesEnum.SELL ? 'flex-start' : 'flex-end',
        direction: createAdType === AdTypesEnum.SELL ? 'row' : 'row-reverse',
    };
    const infoItems = [
        {
            title: t('p2p.checkAdData.coinPrice'),
            subtitle: `${
                !!adCurrencyPrice ? (+adCurrencyPrice)?.toFixed(2) : 0
            }${' '}${chosenFiat?.currency}`,
        },
        {
            title: t('p2p.checkAdData.resultDealSum'),
            subtitle: `${(+totalSum)?.toFixed(8) || 0} ${chosenCurrency?.currency}`,
        },
        {
            title: t('p2p.checkAdData.paymentCountry'),
            subtitle: t(`p2p.regions.${chosenRegion?.name.trim()}`),
        },
    ];
    const secondLineItems = [
        {
            title: t('p2p.checkAdData.priceType'),
            subtitle:
                adPriceType === AdPriceType.FIXED
                    ? t('p2p.checkAdData.fix')
                    : t('p2p.checkAdData.floating'),
        },
        {
            title: t('p2p.checkAdData.dealLimit'),
            subtitle: `${minLimit} - ${maxLimit} ${chosenFiat?.currency}`,
        },
        {
            title: t('p2p.checkAdData.paymentType'),
            subtitle: `30 ${t('p2p.checkAdData.min')}`,
        },
    ]

    type TRenderBottomCard = {
        title: string,
        value: string
    }

    const renderBottomCard = ({title, value}: TRenderBottomCard) => {
        return (
            <BottomCardStyled>
                <AdText $size='14px' $color='#767687'>
                    {title}
                </AdText>
                <AdText $size='12px'>
                    {value}
                </AdText>
            </BottomCardStyled>
        )
    }

    const renderDealTerms = () => {
        const isHaveDealTerms = !!additionalParamsForm.getFieldValue('deal-terms')?.length;
        if (isHaveDealTerms) {
            return renderBottomCard({
                title: t('p2p.checkAdData.termsOfATransaction'),
                value: additionalParamsForm.getFieldValue('deal-terms')
            })
        } else {
            return <></>
        }
    }

    const renderAutoResponse = () => {
        const isHaveAutoResponse = !!additionalParamsForm.getFieldValue('auto-response')?.length;
        if (isHaveAutoResponse) {
            return renderBottomCard({
                title: t('p2p.checkAdData.autoresponderInChat'),
                value: additionalParamsForm.getFieldValue('auto-response')
            })
        } else {
            return <></>
        }
    }

    return (
        <CheckAdCard>
            <TitleWrap>
                <Typography className="create-p2p-ad-card-title">
                    {t('p2p.checkAdData.informationCheck')}
                </Typography>
            </TitleWrap>

            <div className="card-content">
                <div className="check-ad-data-card-content">
                    <DivBordered>
                        {
                            existingAds?.includes(`${chosenCurrency?.currency}${chosenFiat?.currency}`) &&
                            <AdExistsWarning>
                                <ExclamationCircle/>
                                {t('announcements.adAlreadyExistsWarning')}
                            </AdExistsWarning>
                        }
                        {
                            existingAds?.includes(`${chosenFiat?.currency}${chosenCurrency?.currency}`) &&
                            <AdExistsWarning>
                                <ExclamationCircle/>
                                {t('announcements.adAlreadyExistsWarning')}
                            </AdExistsWarning>
                        }
                        <ItemsContainer>
                            <ExchangeItem
                                $justify={exchangeItemStyles.justify}
                                $direction={exchangeItemStyles.direction}
                            >
                                <div>
                                    <FRow>
                                        <Icon
                                            component={getMoneyIcon({
                                                name: chosenCurrency
                                                    ? chosenCurrency.currency?.toLowerCase()
                                                    : '',
                                                size: 24,
                                            })}
                                        />
                                        <ChosenCurrencyTitle>
                                            {chosenCurrency?.currency}
                                        </ChosenCurrencyTitle>
                                    </FRow>
                                </div>
                                <FRow $alignItems='flex-end' $justifyContent='center'>
                                    <ArrowIconStyled/>
                                </FRow>
                                <div>
                                    <FRow>
                                        <Icon
                                            component={getMoneyIcon({
                                                name: chosenFiat
                                                    ? chosenFiat.currency?.toLowerCase()
                                                    : '',
                                                size: 24,
                                            })}
                                        />
                                        <ChosenCurrencyTitle>
                                            {chosenFiat?.currency}
                                        </ChosenCurrencyTitle>
                                    </FRow>
                                </div>
                            </ExchangeItem>

                            <InfoItems items={infoItems}/>

                            {createAdType === AdTypesEnum.BUY && (
                                <ItemContainerSecondLine style={{justifyContent: 'flex-start'}}>
                                    <Item>
                                        <TitleItem>{t('p2p.checkAdData.adCommission')}</TitleItem>
                                        <Tooltip
                                            trigger="hover"
                                            placement="bottom"
                                            title={t('p2p.checkAdData.exchangeCommission')}
                                            color="#2B2B36"
                                            overlayInnerStyle={{
                                                color: '#FBFBFF',
                                                display: 'inline',
                                                padding: '6px 16px',
                                            }}
                                        >
                                            <SubTitleItem>
                                                {(+totalSum / 1000)?.toFixed(8) || 0}{' '}
                                                {chosenCurrency?.currency}
                                            </SubTitleItem>
                                        </Tooltip>
                                    </Item>
                                </ItemContainerSecondLine>
                            )}

                            <ItemsSecondLine items={secondLineItems}/>
                        </ItemsContainer>
                    </DivBordered>
                    <PaymentsWrapper>
                        <AdText $color="#767687">
                            <span>{t('p2p.checkAdData.paymentMethod')}</span> (
                            <span>{createAdType === AdTypesEnum.BUY ? userPaymentListBuy.length : userPaymentList.length} / 5)</span>
                        </AdText>
                        <div className="payment-types-list-wrapper">
                            {getPaymentTypesList()}
                        </div>
                    </PaymentsWrapper>
                    <AdditionalInfoWrapper>
                        <AdditionalFlex>
                            {renderDealTerms()}
                            {renderAutoResponse()}
                        </AdditionalFlex>
                    </AdditionalInfoWrapper>
                </div>
            </div>
        </CheckAdCard>
    );
};

export default CheckOrderData;

const BottomCardStyled = styled.div`
    display: flex;
    flex-direction: column;
    width: 49.5%;
`;

const CheckAdCard = styled.div`
    background-color: #1C1C27;
    width: 100%;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 556px;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: none;
    }

    &::-webkit-scrollbar-thumb {
        background: #F3F3F8;
        border-radius: 4px;
    }
`

const ItemsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;
const ItemContainer = styled.div`
    width: 25%;
    height: 44px;
    display: flex;
`;
const ItemContainerSecondLine = styled(ItemContainer)`
    margin-top: 16px;
`;
const ArrowIconStyled = styled(ArrowForwardIcon)`
    width: 24px;
    height: 24px;
`;
const Item = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
const ExchangeItem = styled(ItemContainer)<{
    $justify: string;
    $direction: string;
}>`
    height: 44px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 4px;
`;
const TitleItem = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: rgba(118, 118, 135, 1);
`;

const SubTitleItem = styled.div`
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    color: rgba(250, 250, 252, 1);
`;
const DivBordered = styled.div`
    border-bottom: 1px solid #474755;
    border-top: 1px solid #474755;
    padding: 16px 24px;
`
const PaymentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 24px;
`
const ChosenCurrencyTitle = styled(Typography)`
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
`;
const TitleWrap = styled.div`
    padding: 24px 24px 16px 24px;
`

interface IDiv {
    $padding?: string;
};

interface IFlexDiv extends IDiv {
    $gap?: string;
    $justifyContent?: string;
    $alignItems?: string;
};

type TTypography = {
    $textAlign?: string;
    $width?: string;
    $size?: string;
    $color?: string;
    $lineHeight?: string;
    $fontWeight?: number;
}
const AdText = styled(Typography) <TTypography>`
    text-align: ${({$textAlign}) => $textAlign ? $textAlign : 'start'};
    width: ${({$width}) => $width ? $width : 'auto'};
    font-size: ${({$size}) => $size ? $size : '14px'};
    color: ${({$color}) => $color ? $color : '#FBFBFF'};
    font-weight: ${({$fontWeight}) => $fontWeight ? $fontWeight : 'normal'};
    line-height: ${({$lineHeight}) => $lineHeight ? $lineHeight : 'auto'};
`
const FRow = styled.div<IFlexDiv>`
    gap: ${({$gap}) => $gap ? $gap : '0'};
    justify-content: ${({$justifyContent}) => $justifyContent ? $justifyContent : 'flex-start'};
    align-items: ${({$alignItems}) => $alignItems ? $alignItems : 'flex-start'};
    display: flex;
    padding: ${({$padding}) => $padding ? $padding : 'auto'};
`

const AdExistsWarning = styled(Typography.Text)`
    font-size: 12px;
    display: flex;
    gap: 8px;
    margin-bottom: 16px;

    svg path {
        fill: #FBFBFF;
    }
`
const AdditionalInfoWrapper = styled.div`
    padding: 16px 24px 24px 24px;
    border-top: 1px solid #474755;
`
const AdditionalFlex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
    gap: 24px;
`
