const SendIcon = ({
  size = 16,
  color = '#FBFBFF',
}: {
  size?: number;
  color?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_2983_65954)">
      <path
        d="M1.3387 14L15.332 8L1.3387 2L1.33203 6.66667L11.332 8L1.33203 9.33333L1.3387 14Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_2983_65954">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { SendIcon };
