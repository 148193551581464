import React, {useState} from 'react';
import Button from '../../button';
import {Typography} from 'antd';
import {ButtonsTypes} from '../../button/filledButton.p';
import {Radio} from '@pankod/refine-antd';
import Modal from '../index';
import {useTranslation} from 'react-i18next';
import styled from "styled-components";

interface IConfirmAcceptPay {
  isOpen: boolean;
  closeModal: () => void;
  onConfirm: () => void;
  createAppeal: (status: string) => void;
}

const Index: React.FC<IConfirmAcceptPay> = ({
                                              isOpen,
                                              closeModal,
                                              onConfirm,
                                              createAppeal,
                                            }) => {
  const {t} = useTranslation();

  const [currentVariant, setCurrentVariant] = useState(0);
  return (
    <Modal.BasicModal
      title={t('payments.confirmationOfReceiptOfPayment')}
      open={isOpen}
      width={560}
      onCancel={closeModal}
      footer={
        <>
          <div className={'flex-row gap-16'}>
            <div style={{width: 284}}>
              <Button
                disabled={currentVariant === 0}
                onClick={() => {
                  switch (currentVariant) {
                    case 1:
                    case 2:
                      createAppeal('payment_incomplete');
                      break;
                    case 3:
                      onConfirm();
                      break;
                  }
                  closeModal();
                }}
                height={40}
              >
                <Typography.Text>{t('buttons.confirm2')}</Typography.Text>
              </Button>
            </div>
            <div style={{width: 180}}>
              <Button
                onClick={() => {
                  closeModal();
                }}
                type={ButtonsTypes.outlined}
                height={40}
              >
                <Typography.Text>{t('buttons.cancel2')}</Typography.Text>
              </Button>
            </div>
          </div>
        </>
      }
    >
      <div className={'flex-column gap-24'}>
        <SubTitleText>
          {t('payments.confirmationOfReceiptOfPaymentDescription')}
        </SubTitleText>
        <div className={'flex-column gap-16'}>
          <BoldTitle>
            {t('payments.advice')}
          </BoldTitle>
          <div className={'flex-row gap-8'}>
            <GradientCircle>1</GradientCircle>
            <Text>
              {t('payments.checkIfDepositGet')}
            </Text>
          </div>
          <div className={'flex-row gap-8'}>
            <GradientCircle>2</GradientCircle>
            <Text>
              {t('payments.pleaseWaitUntilPaymentProcessing')}
            </Text>
          </div>
          <div className={'flex-row gap-8'}>
            <GradientCircle>3</GradientCircle>
            <Text>
              {t('payments.dontAcceptPayments')}
            </Text>
          </div>
        </div>
        <div className={'flex-column gap-16'}>
          <BoldTitle>
            {t('payments.selectOption')}
          </BoldTitle>
          <Radio.Group defaultValue={currentVariant}>
            <div className={'flex-column gap-16'}>
              <Radio value={3} onClick={() => setCurrentVariant(3)}>
                <Text>
                  {t('payments.iGotRightAmount')}
                </Text>
              </Radio>
            </div>
          </Radio.Group>
        </div>
      </div>
    </Modal.BasicModal>
  );
};

export default Index;

const SubTitleText = styled(Typography.Text)`
  font-weight: 400;
  line-height: 19.36px;
  font-size: 16px;
`
const BoldTitle = styled(SubTitleText)`
  font-weight: 700;
`

const Text = styled(Typography.Text)`
  font-weight: 400;
  line-height: 20px;
  font-size: 14px;
`

const GradientCircle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: linear-gradient(80.91deg, #088473 0%, #12BFA8 100%);
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
`
