import React, {useContext} from 'react';
import {Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import {styled} from 'styled-components';
import {SpotMarketContext} from '../../../pages/spot/spotDashboard/spotDashboard';
import {SpotOrderBookControlPanel} from './controlPannel';
import {SpotMarkerDataList} from './markerDataList';
import {SpotOrderListMarketListData} from './marketListData';

const SpotOrderBookList: React.FC = () => {
    const {t} = useTranslation();
    const {
        spotMarketSaleData,
        spotMarketBuyData,
        dashboard: {
            spotOrderBookListData: {
                amountCurrency,
                priceCurrency,
                setCurrentFilter,
                setSelectorValue,
                selectorValue,
                currentFilter,
            },
        },
    } = useContext(SpotMarketContext);

    return (
        <>
            <div className={'flex-column gap-16 mob-content-start'}>
                <SpotOrderBookControlPanel
                    selectorValue={selectorValue}
                    setSelectorValue={(data) => setSelectorValue(data)}
                    setCurrentFilter={(data) => setCurrentFilter(data)}
                    currentFilter={currentFilter}
                />
                <div className={'flex-column gap-10'}>
                    <BookHeading>
                        <Typography.Text
                            className={'light-bold-12px-text'}
                            style={{color: '#767687', textAlign: 'left'}}
                        >
                            {`${t('spot.price')} (${priceCurrency})`}
                        </Typography.Text>
                        <Typography.Text
                            className={'light-bold-12px-text'}
                            style={{color: '#767687', textAlign: 'left'}}
                        >
                            {`${t('spot.amount')} (${amountCurrency})`}
                        </Typography.Text>
                        <Typography.Text
                            className={'light-bold-12px-text mob-display-none'}
                            style={{color: '#767687', textAlign: 'right'}}
                        >
                            {t('spot.inAll')}
                        </Typography.Text>
                    </BookHeading>
                    <div className={'flex-column gap-16'}>
                        {currentFilter === 2 && <SpotOrderListMarketListData/>}
                        {currentFilter !== 1 && (
                            <SpotMarkerDataList
                                dataList={spotMarketSaleData?.dataList}
                                progressColor={spotMarketSaleData?.progressColor}
                                priceColor={spotMarketSaleData?.priceColor}
                                amountCurrency={amountCurrency}
                                priceCurrency={priceCurrency}
                                isReverse={false}
                            />
                        )}
                        {currentFilter !== 2 && (
                            <>
                                <SpotOrderListMarketListData/>
                                <SpotMarkerDataList
                                    dataList={spotMarketBuyData?.dataList}
                                    progressColor={spotMarketBuyData?.progressColor}
                                    priceColor={spotMarketBuyData?.priceColor}
                                    amountCurrency={amountCurrency}
                                    priceCurrency={priceCurrency}
                                    isReverse={true}
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

const BookHeading = styled.div`
  display: grid;
  gap: 10px;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export {SpotOrderBookList};
