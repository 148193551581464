import React from 'react';
import { Payments } from '../../../pages/p2p/settings/payment/payment';
import Card from './card';
import { IPaymentsType } from '../../../pages/p2p/dashboard/interface';
import { AdaptiveFullScreenLoader } from '../../../shared/components/full-screen-loader';

interface Props {
  payments: Payments;
  deletePayment: (id: string, type: keyof Payments) => void;
  iconsData?: IPaymentsType[];
  isFetched?: boolean;
}

const CardContainer = ({
  payments,
  deletePayment,
  iconsData = [],
  isFetched,
}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '1%',
      }}
    >
      <Card
        deletePayment={deletePayment}
        payment={payments.cards}
        type={'cards'}
        iconsData={iconsData}
      />
      <Card
        deletePayment={deletePayment}
        payment={payments.cashes}
        type={'cashes'}
        iconsData={iconsData}
      />
      <Card
        deletePayment={deletePayment}
        payment={payments.sbp}
        type={'sbp'}
        iconsData={iconsData}
      />
      <Card
        deletePayment={deletePayment}
        payment={payments.banks}
        type={'banks'}
        iconsData={iconsData}
      />
      <Card
        deletePayment={deletePayment}
        payment={payments.mobiles}
        type={'mobiles'}
        iconsData={iconsData}
      />
      <Card
        deletePayment={deletePayment}
        payment={payments.services}
        type={'services'}
        iconsData={iconsData}
      />
      {isFetched && <AdaptiveFullScreenLoader />}
    </div>
  );
};

export default CardContainer;
