import { CSSProperties } from "react";
import { FC } from 'react'



const GradientCard: FC<{ height?: number, width?: number, children: React.ReactNode, padding?: string, enableShadow?: boolean, gradientBorder?: boolean, leftBorderWidth: number }> = ({ children, gradientBorder, leftBorderWidth, enableShadow, padding, height, width }) => {

    const cardStyle: CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        padding: padding ? padding : '40px 32px 80px',
        gap: '24px',
        border: '0px solid transparent',
        borderLeft: `${leftBorderWidth}px solid transparent`,
        background: 'linear-gradient(80.47deg, #18181F 6.62%, #2D2D3A 148.62%) padding-box, linear-gradient(25.41deg, #6E27F8 2.1%, #20D9C0 71.42%) border-box',
        borderRadius: '24px',
        borderColor: 'transparent',
        borderWidth: `0 0 0 ${leftBorderWidth}px`,
        ...(width && { width: `${width}px` }),
        ...(height && { height: `${height}px`, }),
        ...(enableShadow && { boxShadow: '-12px 13px 30px rgba(32, 217, 192, 0.15)', }),
        ...(gradientBorder && { borderWidth: `1px 1px 1px ${leftBorderWidth}px` })
    }
    return <div style={{ ...cardStyle }}>
        {children}
    </div>
}

export default GradientCard