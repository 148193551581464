import { useTranslation } from 'react-i18next';
import { MenuProps } from 'antd/es/menu/menu';
import HeaderDropdown from '../../../ui/dropdown/headerDropdown';
import { Typography } from 'antd';

const Index = () => {
  const { i18n } = useTranslation();

  const items: MenuProps['items'] = [...(i18n.languages || [])]
    .sort()
    .map((lang, index) => {
      return {
        label: (
          <>
            {lang === 'ru' && (
              <div
                style={{
                  display: 'flex',
                  gap: 8,
                  alignItems: 'center',
                }}
                translate="no"
              >
                <Typography.Text style={{ width: 18 }}>ru</Typography.Text>
                <Typography.Text>Русский</Typography.Text>
              </div>
            )}
            {lang === 'en' && (
              <div
                style={{
                  display: 'flex',
                  gap: 8,
                  height: 48,
                  alignItems: 'center',
                }}
                translate="no"
              >
                <Typography.Text style={{ width: 18 }}>en</Typography.Text>
                <Typography.Text>English</Typography.Text>
              </div>
            )}
          </>
        ),
        key: lang,
        onClick: () => {
          document.documentElement.lang = lang;
          i18n.changeLanguage(lang).then();
        },
        style: {
          padding: '8px 16px',
          minWidth: 196,
          height: 48,
          borderBottom: '1px solid #474755',
          borderRadius: '4px',
        },
      };
    });

  return (
    <>
      <HeaderDropdown
        isSelectable={true}
        defaultValue={i18n.language}
        items={items}
        currentValue={i18n.language.toUpperCase()}
      />
    </>
  );
};

export default Index;
