import React from 'react';
import {
    AdvCache,
    AkBars,
    AlfaBank,
    BCS,
    BSPD,
    CentrInvest,
    CitiBank,
    CreditEurope,
    GaspromBank,
    HomeCreditBank,
    MTS,
    OpenBank,
    OTP,
    Payeer,
    PochtaBank,
    QIWI,
    Raiffeisen,
    RenCredit,
    Rosbank,
    RSB,
    SberBank,
    SovcomBank,
    Tinkoff,
    Tochka,
    UBRR,
    UniCreditBank,
    UralSib,
    VTB,
    YooMoney,
} from '../../../shared/components/icons';
import Icon from '@ant-design/icons';

export enum IKeyIcons {
    'Т-Банк' = 'Т-Банк',
    'Росбанк' = 'Росбанк',
    'Райффайзенбанк' = 'Райффайзенбанк',
    'МТС - Банк' = 'МТС - Банк',
    'Почта Банк' = 'Почта Банк',
    'Хоум Кредит Банк' = 'Хоум Кредит Банк',
    'Альфа - банк' = 'Альфа - банк',
    'Ак Барс Банк' = 'Ак Барс Банк',
    'Уралсиб Банк' = 'Уралсиб Банк',
    'Ренессанс Банк' = 'Ренессанс Банк',
    'Русский Стандарт' = 'Русский Стандарт',
    'БКС Банк' = 'БКС Банк',
    'Банк Санкт - Петербург' = 'Банк Санкт - Петербург',
    'ОТП Банк' = 'ОТП Банк',
    'Кредит Европа Банк(Россия)' = 'Кредит Европа Банк(Россия)',
    'Ситибанк' = 'Ситибанк',
    'Сбербанк' = 'Сбербанк',
    'ВТБ' = 'ВТБ',
    'Банк Точка' = 'Банк Точка',
    'Банк Центр - инвест' = 'Банк Центр - инвест',
    'Совкомбанк' = 'Совкомбанк',
    'Уральский Банк реконструкции и развития' = 'Уральский Банк реконструкции и развития',
    'Газпромбанк' = 'Газпромбанк',
    'Банк ФК Открытие' = 'Банк ФК Открытие',
    'ЮниКредит Банк' = 'ЮниКредит Банк',
    YooMoney = 'YooMoney',
    QIWI = 'QIWI',
    Payeer = 'Payeer',
    AdvCash = 'AdvCash',
}

export const bankIcons = (key: IKeyIcons): JSX.Element => {
    const icons = {
        'Т-Банк': <Icon component={() => Tinkoff('', 16)}/>,
        'Росбанк': <Icon component={() => Rosbank('', 16)}/>,
        'Райффайзенбанк': <Icon component={() => Raiffeisen('', 16)}/>,
        'МТС - Банк': <Icon component={() => MTS('', 16)}/>,
        'Почта Банк': <Icon component={() => PochtaBank('', 16)}/>,
        'Хоум Кредит Банк': <Icon component={() => HomeCreditBank('', 16)}/>,
        'Альфа - банк': <Icon component={() => AlfaBank('', 16)}/>,
        'Ак Барс Банк': <Icon component={() => AkBars('', 16)}/>,
        'Уралсиб Банк': <Icon component={() => UralSib('', 16)}/>,
        'Ренессанс Банк': <Icon component={() => RenCredit('', 16)}/>,
        'Русский Стандарт': <Icon component={() => RSB('', 16)}/>,
        'БКС Банк': <Icon component={() => BCS('', 16)}/>,
        'Банк Санкт - Петербург': <Icon component={() => BSPD('', 16)}/>,
        'ОТП Банк': <Icon component={() => OTP('', 16)}/>,
        'Кредит Европа Банк(Россия)': (
            <Icon component={() => CreditEurope('', 16)}/>
        ),
        'Ситибанк': <Icon component={() => CitiBank('', 16)}/>,
        'Сбербанк': <Icon component={() => SberBank('', 16)}/>,
        'ВТБ': <Icon component={() => VTB('', 16)}/>,
        'Банк Точка': <Icon component={() => Tochka('', 16)}/>,
        'Банк Центр - инвест': <Icon component={() => CentrInvest('', 16)}/>,
        'Совкомбанк': <Icon component={() => SovcomBank('', 16)}/>,
        'Уральский Банк реконструкции и развития': (
            <Icon component={() => UBRR('', 16)}/>
        ),
        'Газпромбанк': <Icon component={() => GaspromBank('', 16)}/>,
        'Банк ФК Открытие': <Icon component={() => OpenBank('', 16)}/>,
        'ЮниКредит Банк': <Icon component={() => UniCreditBank('', 16)}/>,
        'YooMoney': <Icon component={() => YooMoney('', 16)}/>,
        'QIWI': <Icon component={() => QIWI('', 16)}/>,
        'Payeer': <Icon component={() => Payeer('#FBFBFF', 16)}/>,
        'AdvCash': <Icon component={() => AdvCache('', 16)}/>,
    };
    // @ts-ignore
    return icons[key];
};
