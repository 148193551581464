import {useEffect, useState} from 'react';
import {HttpError, useCustomMutation, useList,} from '@pankod/refine-core';
import {IPaymentType} from '../../../../features/p2p/createOrder/types';
import {IP2PUser} from '../../../../interfaces';
import {useTranslation} from 'react-i18next';
import {notification} from 'antd';
import {IPaymentsType} from "../../dashboard/interface";
import {useNavigate} from '@pankod/refine-react-router-v6';
import './styles.css';
import {AdaptiveFullScreenLoader} from 'shared/components/full-screen-loader';
import styled from 'styled-components';
import {TypePaymentVariant} from "../../../../features/paymentComponents/modalContent";
import {ErrorBoundary} from '../../../../app/ErrorBoundary';
import {useCookiesCustom} from '../../../../shared/hooks/useCookieCustom';
import ModalContent from '../../../../features/paymentComponents/modalContent';
import CardContainer from '../../../../features/paymentComponents/CardContainer';

export interface PayService {
    id: string;
    service?: string;
    num?: string;
    comment?: string;
    email?: string;
    bik?: string;
}

export interface Payments {
    cards: PayService[];
    services: PayService[];
    mobiles: PayService[];
    banks: PayService[];
    cashes: PayService[];
    sbp: PayService[];
}

const initialState = {
    cards: [],
    sbp: [],
    services: [],
    banks: [],
    mobiles: [],
    cashes: [],
};

const Payment = () => {
    const navigate = useNavigate();
    const {removeCookie} = useCookiesCustom();
    const [userData, setUserData] = useState<IP2PUser>();
    const [page, setPage] = useState(1);
    const pageSize = 15;
    useList<IP2PUser, HttpError>({
        resource: 'account',
        dataProviderName: 'account',
        config: {
            pagination: {
                current: 1,
                pageSize: 15,
            },
        },
        queryOptions: {
            onSuccess: ({data}) => {
                if (data[0]) {
                    setUserData(data[0]);
                }
            },
        },
    });

    const {refetch, isFetched} = useList<IPaymentType>({
        resource: 'payment',
        dataProviderName: 'p2p',
        config: {
            pagination: {
                current: page,
                pageSize,
            },
            filters: [
                {
                    field: 'account_id',
                    operator: 'eq',
                    value: userData?.uid,
                },
            ],
        },
        queryOptions: {
            enabled: !!userData,
            onSuccess: (data) => {
                let result: Payments = initialState;
                const dataWeb = data?.data.filter(
                    (item) =>
                        item.payment_type === 'webServicePayment' && !item.is_deleted
                );
                const dataCash = data?.data.filter(
                    (item) => item.payment_type === 'cashPayment' && !item.is_deleted
                );
                const dataPhone = data?.data.filter(
                    (item) =>
                        item.payment_type === 'mobileBalancePayment' && !item.is_deleted
                );
                const dataBank = data?.data.filter(
                    (item) => item.payment_type === 'bankCardPayment' && !item.is_deleted
                );
                const dataSBP = data?.data.filter(
                    (item) =>
                        item.payment_type === 'fastPaymentSystemPayment' && !item.is_deleted
                );
                const dataTransfer = data?.data.filter(
                    (item) =>
                        item.payment_type === 'bankTransferPayment' && !item.is_deleted
                );
                dataWeb &&
                dataWeb.forEach((item) => {
                    result = {
                        ...result,
                        services: result.services.concat({
                            id: item.id.toString(),
                            service: item.payment_details[0].web_service_name,
                            num: item.payment_details[0].web_service_account_id,
                            comment: item.payment_details[0].commentary,
                        }),
                    };
                });
                dataTransfer?.forEach((item) => {
                    result = {
                        ...result,
                        banks: result.banks.concat({
                            id: item.id.toString(),
                            comment: item.payment_details[0].commentary,
                            num: item.payment_details[0].bank_account_number,
                            service: (item.payment_details[0].bank_id ?? '').toString(),
                        }),
                    };
                });
                dataCash?.forEach((item) => {
                    result = {
                        ...result,
                        cashes: result.cashes.concat({
                            id: item.id.toString(),
                            comment: item.payment_details[0].commentary,
                        }),
                    };
                });
                dataSBP?.forEach((item) => {
                    result = {
                        ...result,
                        sbp: result.sbp.concat({
                            id: item.id.toString(),
                            num: item.payment_details[0].phone,
                            comment: item.payment_details[0].commentary,
                        }),
                    };
                });
                dataPhone?.forEach((item) => {
                    result = {
                        ...result,
                        mobiles: result.mobiles.concat({
                            id: item.id.toString(),
                            num: item.payment_details[0].phone,
                            comment: item.payment_details[0].commentary,
                        }),
                    };
                });
                dataBank?.forEach((item) => {
                    result = {
                        ...result,
                        cards: result.cards.concat({
                            id: item.id.toString(),
                            service: (item.payment_details[0].bank_id ?? '').toString(),
                            num: item.payment_details[0].card_identifier,
                            comment: item.payment_details[0].commentary,
                        }),
                    };
                });

                const getUniqueObjects = (arr: PayService[]) => {
                    const uniqueIds = new Set();
                    return arr.filter((obj) => {
                        if (!uniqueIds.has(obj.id)) {
                            uniqueIds.add(obj.id);
                            return true;
                        }
                        return false;
                    });
                };

                setPayments((prevState) => {
                    return {
                        ...prevState,
                        cards: getUniqueObjects([...prevState.cards, ...result.cards]),
                        banks: getUniqueObjects([...prevState.banks, ...result.banks]),
                        cashes: getUniqueObjects([...prevState.cashes, ...result.cashes]),
                        mobiles: getUniqueObjects([
                            ...prevState.mobiles,
                            ...result.mobiles,
                        ]),
                        sbp: getUniqueObjects([...prevState.sbp, ...result.sbp]),
                        services: getUniqueObjects([
                            ...prevState.services,
                            ...result.services,
                        ]),
                    };
                });
                setPaymentsLastPage(Math.ceil(data.total / pageSize));
            },
        },
    });

    const {t} = useTranslation();
    const {mutate: mutateDel} = useCustomMutation();
    const [variantPay, setVariantPay] = useState<TypePaymentVariant | null>(null);
    const [nowStep, setNowStep] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorHasPayment, setErrorHasPayment] = useState(false);
    const [payments, setPayments] = useState<Payments>(initialState);
    const [paymentsLastPage, setPaymentsLastPage] = useState(0);

    const showModal = () => {
        setNowStep(1);
        setVariantPay(null);
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setErrorHasPayment(false);
    };

    const deletePayment = (id: string, type: keyof Payments) => {
        mutateDel({
            url: `p2p/api/payment/${id}`,
            method: 'put',
            values: {
                is_deleted: true,
            },
            successNotification: () => {
                setPayments({
                    ...payments,
                    [type]: payments[type].filter((item) => item.id !== id),
                });
                if (getCountPayments() === 1) navigate('/p2p/settings');
                notification.success({
                    message: t('payments.notification.successDeleteTitle'),
                    description: t(
                        'payments.notification.successDeleteDescription'
                    ).toString(),
                });
                return {
                    message: t('payments.notification.successDeleteTitle'),
                    type: 'success',
                    description: t(
                        'payments.notification.successDeleteDescription'
                    ).toString(),
                };
            },
            // TODO: добавить логику блокирующую удаление способа оплаты, если есть открытые сделки + переписать уведомление об ошибке
            errorNotification: (error: any) => {
                notification.error({
                    message: 'Error',
                    description: error.message,
                });
                return {
                    message: 'Error',
                    type: 'error',
                    description: error.message,
                };
            },
        });
    };

    const getCountPayments = () => {
        let count = 0;
        for (let key in payments) {
            count += payments[key as keyof Payments].length;
        }
        return count;
    };

    useEffect(() => {
        const target = document.querySelector('.border-gradient1:last-child');
        let currPage = page;

        const handleObserve = (entries: IntersectionObserverEntry[]) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting && currPage < paymentsLastPage) {
                    currPage = currPage + 1;
                    setPage(currPage);
                }
            });
        };
        const observer = new IntersectionObserver(handleObserve, {
            rootMargin: '30px',
            threshold: 1,
        });
        if (target) {
            observer.observe(target);
        }
    }, [payments]);

    const iconsData = useList<IPaymentsType, HttpError>({
        dataProviderName: `p2p`,
        resource: `payment-types`,
    });
    return (
        <ErrorBoundary
            logout={() => {
            }}
            navigate={navigate}
            removeCookie={removeCookie}>
            <H3Styled>{t('payments.title')}</H3Styled>
            <PStyled>{t('payments.subtitle')}</PStyled>
            <ContainerButtonStyled>
                <ButtonStyled onClick={showModal}>
                    {t('buttons.addANewPaymentMethod')}
                </ButtonStyled>
            </ContainerButtonStyled>
            {getCountPayments() === 0 ? (
                <EmptyContainerStyled>
                    <AdaptiveFullScreenLoader/>
                </EmptyContainerStyled>
            ) : (
                <CardContainer
                    deletePayment={deletePayment}
                    payments={payments}
                    isFetched={!isFetched}
                    iconsData={iconsData.data?.data}
                />
            )}

            <ModalContent
                open={isModalOpen}
                onOk={handleOk}
                errorHasPayment={errorHasPayment}
                controlsNowStep={[nowStep, setNowStep]}
                controlsVariantPay={[variantPay, setVariantPay]}
                closeModal={handleCancel}
                refetch={refetch}
                iconsData={iconsData.data?.data}
                setErrorHasPayment={setErrorHasPayment}
            />
        </ErrorBoundary>
    );
};

const PStyled = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: white;
  font-size: 18px;
  margin-bottom: 8px;
`;

const H3Styled = styled.h3`
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: white;
  font-size: 40px;
`;

const EmptyContainerStyled = styled.div`
  width: 100%;
  gap: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonStyled = styled.button`
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: white;
  font-size: 14px;
  background: linear-gradient(75.86deg, #6320e6 6.84%, #7b59fd 99.53%);
  border: none;
  cursor: pointer;
  border-radius: 8px;
  padding: 16px;
  margin-block: 32px;
`;

const ContainerButtonStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default Payment;
