import {Typography} from "@pankod/refine-antd"
import {getIconByCoin} from "features/forms/coin-form/coinForm"
import {GreenArrow} from "shared/components/icons"
import {styled} from "styled-components"

export const ExchangePair = ({ccy, fiat}: { ccy: string, fiat: string }) => {
  return (
    <Row>
      <Row>
        <IconContainer>
          {ccy &&
            getIconByCoin(ccy?.toLowerCase())}
        </IconContainer>
        <Typography.Text style={{fontSize: '24px', fontWeight: '700'}}>
          {ccy}
        </Typography.Text>
      </Row>

      <ArrowContainer>
        <GreenArrow/>
      </ArrowContainer>

      <Row>
        <IconContainer>
          {ccy &&
            getIconByCoin(fiat?.toLowerCase())}
        </IconContainer>
        <Typography.Text style={{fontSize: '24px', fontWeight: '700'}}>
          {fiat}
        </Typography.Text>
      </Row>
    </Row>
  )
}

const Row = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`
const IconContainer = styled(Row)`
  height: 24px;
  width: 24px;
  justify-content: center;

  svg {
    height: 100%;
    width: 100%;
  }
`
const ArrowContainer = styled(IconContainer)`
  height: 20px;
  width: 20px;
`
