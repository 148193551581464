import { useEffect, useState } from 'react';
import { Form, Select, Typography } from '@pankod/refine-antd';
import { FormInstance } from 'antd/es/form/hooks/useForm';
import {
  CoinIcon,
  SelectIcon,
  WhiteBitcoinIcon,
  WhiteBnbIcon,
  WhiteEthIcon,
  WhiteEurIcon,
  WhitePolygonIcon,
  WhiteRubIcon,
  WhiteTetherIcon,
  WhiteUsdcIcon,
  WhiteUsdIcon,
} from '../../../shared/components/icons';
import { coinArr } from 'pages/coin-account/coinArr';
import { useTranslate } from '@pankod/refine-core';

type Network = {
  id: string;
  name: string;
};

type Coin = {
  id: string;
  name: string;
  networks?: Network[];
};

type Props = {
  onReady: (coin: string, network?: string) => void;
  onNotReady: () => void;
  form: FormInstance;
  hidden?: boolean;
  initialValues?: Values;
  isShown?: boolean;
  className?: string;
  coinsArr?: Coin[];
  coinValue: string;
  setCoinValue: (arg0: string) => void;
};

type Values = {
  coin: string;
  network?: string;
};

export const getIconByCoin = (coinShortName: string, size?: number) => {
  switch (coinShortName) {
    case 'btc':
      return <WhiteBitcoinIcon size={size} />;
    case 'usdt':
      return <WhiteTetherIcon />;
    case 'eth':
      return <WhiteEthIcon />;
    case 'usdc':
      return <WhiteUsdcIcon />;
    case 'matic':
      return <WhitePolygonIcon />;
    case 'eur':
      return <WhiteEurIcon />;
    case 'rub':
      return <WhiteRubIcon />;
    case 'usd':
      return <WhiteUsdIcon />;
    case 'bnb':
      return <WhiteBnbIcon />;
    default:
      return <CoinIcon />;
  }
};

export function CoinForm({
  onReady,
  onNotReady,
  form,
  hidden = false,
  initialValues,
  isShown,
  className,
  coinsArr = coinArr,
  coinValue,
  setCoinValue,
}: Props) {
  const [networks, setNetworks] = useState<Network[] | undefined>(undefined);
  const t = useTranslate();
  const [selectCoinValue, setSelectCoinValue] = useState(coinValue);
  const onValuesChange = (changedValues: any, values: any) => {
    let newNetworks = networks;

    if (changedValues.coin) {
      newNetworks =
        coinsArr.find((coin) => coin.id === form.getFieldValue('coin'))
          ?.networks ?? undefined;
      form.setFieldValue('network', undefined);
      setNetworks(newNetworks);
    }

    if (values.coin && (newNetworks === undefined || values.network)) {
      onReady(values.coin, values.network);
    } else {
      onNotReady();
    }
  };

  useEffect(() => {
    if (initialValues) {
      form.setFieldValue('coin', initialValues.coin);

      onValuesChange({ coin: initialValues.coin }, form.getFieldsValue());

      if (initialValues.network) {
        form.setFieldValue('network', initialValues.network);

        onValuesChange(
          { network: initialValues.network },
          form.getFieldsValue()
        );
      }
    }
    return () => {
      form.resetFields();
      setNetworks(undefined);
    };
  }, [isShown]);

  const handleCoinChange = (value: string) => {
    setCoinValue(value);
    setSelectCoinValue(value);
  };

  return (
    <>
      <Form
        form={form}
        name="control-hooks"
        style={{ maxWidth: 600 }}
        onValuesChange={onValuesChange}
        layout={'vertical'}
        className={className}
      >
        <div
          className={
            'flex-row gap-24 custom-wallet-form custom-dropdown-container'
          }
        >
          <Form.Item
            name="coin"
            label={t('wallet.coin.choose')}
            hidden={hidden}
            style={{ width: '100%', marginBottom: 0 }}
          >
            <Select
              defaultValue={''}
              suffixIcon={<SelectIcon />}
              onChange={(e) => handleCoinChange(e)}
              value={selectCoinValue}
            >
              {coinsArr.map((coin) => (
                <Select.Option
                  className="custom-dropdown-container"
                  key={coin.id}
                  value={coin.id}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '15px',
                    }}
                  >
                    {coin && (
                      <div className={'select-icon-white'}>
                        {getIconByCoin(coin.id)}
                      </div>
                    )}
                    <Typography.Text
                      style={{ alignItems: 'center', display: 'flex' }}
                      className={'text-14px'}
                    >
                      {coin && coin.name}
                    </Typography.Text>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="network"
            label={t('wallet.coin.chooseNetwork')}
            initialValue={initialValues?.network ?? undefined}
            hidden={hidden}
            style={{ width: '100%', marginBottom: 0 }}
          >
            <Select
              allowClear={false}
              className={'select-style'}
              suffixIcon={<SelectIcon />}
            >
              {networks?.map((network) => (
                <Select.Option key={network.id} value={network.id}>
                  <Typography.Text>{network.name}</Typography.Text>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}
