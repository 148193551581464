import { BasicModal } from '../../../shared/components/ui/modal/basicModal';
import React, { Dispatch, FC, useState } from 'react';
import Button from '../../../shared/components/ui/button';
import { Input } from 'antd';
import '../../paymentComponents/modalContent/style.css';
import { useTranslation } from 'react-i18next';

interface Props {
  controlsOpen: [boolean, Dispatch<boolean>];
  paymentProduct: (value: string) => void;
  error: string;
}

const Modal: FC<Props> = ({
  controlsOpen: [isOpen, setIsOpen],
  paymentProduct,
  error,
}) => {
  const { t } = useTranslation();

  const [value, setValue] = useState('');

  return (
    <BasicModal
      closable={false}
      footer={
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
        >
          <Button
            disabled={!value.length}
            style={{ width: '100%', maxHeight: 40, marginBottom: 32 }}
            onClick={() => {
              paymentProduct(value);
            }}
          >
            {t('buttons.confirm2')}
          </Button>
          <div style={{ display: 'flex', gap: 16 }}>
            {t('paymentLogout.forgotASecretPhrase')}
            <button
              onClick={() => setIsOpen(false)}
              style={{ border: 'none', background: 'none' }}
              className={'modal-help-button'}
            >
              {t('buttons.help')}
            </button>
          </div>
        </div>
      }
      width={560}
      open={isOpen}
      title={t('payments.securityConformation')}
    >
      <div className={'modal-text'}>{t('payments.description')}</div>
      <div className={'bank-container-input'}>
        <span className={'bank-input-label'}>
          {t('payments.enterSecretPhrase')}
        </span>
        <Input.Password
          value={value}
          onChange={(e) => setValue(e.target.value)}
          status={!!error.length ? 'error' : undefined}
          placeholder={t('payments.secretPhrase').toString()}
        />
        <label className={'bank-container-input-label-error'}>{error}</label>
      </div>
    </BasicModal>
  );
};

export default Modal;
