import React, {Dispatch, FC, useEffect, useState} from 'react';
import './styles.css';
import {enData, ruData} from './data';
import {ReactComponent as StarFill} from '../image/starFill.svg';
import {ReactComponent as StarActive} from '../image/starActive.svg';
import {StatSpot} from '../types';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from '@pankod/refine-react-router-v6';
import {CentrifugeClient} from '../../../../packages/centrifuge-client/centrifuge-client';
import {useSimpleList} from '@pankod/refine-antd';
import styled from 'styled-components';
import ItemPair from './ItemPair';

interface Props {
    controlsStars: [string[], Dispatch<string[]>];
    dataSocket: StatSpot[];
    activeSortPair: string;
    filterStar: boolean;
}

interface StatSpotExt extends StatSpot {
    tradeAmount?: string | number;
}

const sortTypes = {
    coin: 'Монета',
    price: 'Цена',
    change: 'Изменение',
    volume: 'Объем',
};
const Content: FC<Props> = ({
                                controlsStars: [stars, setStars],
                                dataSocket,
                                activeSortPair,
                                filterStar,
                            }) => {
    const {i18n} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const [pairNames, setPairNames] = useState<string[]>([]);
    const [activePair, setActivePair] = useState(
        () => localStorage['activePair']
    );

    const {listProps: currencyConstraintData} = useSimpleList({
        resource: 'currency-constraint',
        dataProviderName: 'config',
    });
    const [sort, setSort] = useState<{
        sortName: string;
        up: boolean;
        down: boolean;
    } | null>({sortName: '', up: true, down: false});

    const filterCondition = (itemFind: StatSpot) => {
        return (
            (activeSortPair.includes('/') &&
                itemFind.name.includes(activeSortPair)) ||
            itemFind.name.slice(0, itemFind.name.indexOf('/')) === activeSortPair
        );
    };
    const dataSpot = dataSocket?.filter((itemFind) => {
        return filterCondition(itemFind);
    });

    function changeActivePair(item: StatSpot) {
        const spotSubscription = CentrifugeClient.getSubscription(
            `spot.${activePair}`
        );
        const spotStatSubscription = CentrifugeClient.getSubscription(
            `spotStat.${activePair}`
        );
        const spotChartSubscription = CentrifugeClient.getSubscription(
            `chart.${activePair}`
        );

        if (!!spotSubscription) {
            spotSubscription.unsubscribe();
            spotSubscription.removeAllListeners();
        }

        if (!!spotStatSubscription) {
            spotStatSubscription.unsubscribe();
            spotStatSubscription.removeAllListeners();
        }

        if (!!spotChartSubscription) {
            spotChartSubscription.unsubscribe();
            spotChartSubscription.removeAllListeners();
        }

        const pair = item.name.replace(new RegExp('/', 'g'), '-');
        localStorage.setItem('activePair', pair);
        navigate(`/spot/dashboard/${pair}`);
    }

    const getStar = (item: StatSpot) =>
        stars.includes(item.name) ? <StarActive/> : <StarFill/>;

    useEffect(() => {
        setActivePair(() => localStorage['activePair']);
        if (i18n.language === 'ru') setPairNames(ruData);
        else setPairNames(enData);
    }, [i18n.language]);

    useEffect(() => {
        setActivePair(() => localStorage['activePair']);
    }, [location?.pathname]);

    const SpotContent = (item: StatSpot, index: number) => {
        const starIcon = () => {
            return stars.includes(item.name) ? <StarActive/> : <StarFill/>;
        };
        return (filterStar && stars.includes(item.name)) || !filterStar ? (
            <div
                key={index}
                className={
                    activePair === String(item.name).replace('/', '-')
                        ? 'pair-content-item-active'
                        : 'pair-content-item'
                }
            >
                <ContainerStyled>
                    <IconContainerStyled
                        onClick={() => {
                            setStars(
                                !stars.includes(item.name)
                                    ? stars.concat([item.name])
                                    : stars.filter((itemFilter) => itemFilter !== item.name)
                            );
                        }}
                        className={'pair-container-header-button'}
                    >
                        {getStar(item)}
                    </IconContainerStyled>
                </ContainerStyled>
                <ButtonStyled onClick={() => changeActivePair(item)}>
                    <TextButtonStyled className={'pair-content-item-text-money'}>
                        {item.name}
                    </TextButtonStyled>
                    <span className={'pair-content-item-text'} style={{width: '55px'}}>
            {+(+item?.content?.last_price)?.toFixed(4) || 0}
          </span>
                    <SecondTextButtonStyled className={'pair-content-item-text'}>
                        {+item.content.change_price_24 >= 0 && '+'}
                        {+(+item.content.change_price_24)?.toFixed(4) || 0} %
                    </SecondTextButtonStyled>
                    <ThirdTextButtonStyled className={'pair-content-item-text'}>
                        {+(+item.content.volume_24)?.toFixed(4) || 0}
                    </ThirdTextButtonStyled>
                </ButtonStyled>
            </div>
        ) : null;
    };
    const sortUp = (a: any, b: any) => {
        if (a > b) {
            return 1;
        }
        if (a < b) {
            return -1;
        }
        return 0;
    };
    const sortDown = (a: any, b: any) => {
        if (a < b) {
            return 1;
        }
        if (a > b) {
            return -1;
        }
        return 0;
    };
    const switchName = (item1: StatSpotExt, item2: StatSpotExt) => {
        switch (sort?.sortName) {
            case sortTypes.coin:
                return {a: item1.content.last, b: item2.content.last};
            case sortTypes.price:
                return {a: item1.content.last_price, b: item2.content.last_price};
            case sortTypes.change:
                return {
                    a: item1.content.change_price_24,
                    b: item2.content.change_price_24,
                };
            case sortTypes.volume:
                return {a: item1.content.volume_24, b: item2.content.volume_24};
            default:
                return {a: item1.tradeAmount, b: item2.tradeAmount};
        }
    };
    const switchOrder = (a: any, b: any) => {
        if (sort?.up) {
            return sortUp(a, b);
        }
        if (sort?.down) {
            return sortDown(a, b);
        }
        return 0;
    };
    const dataSpotExt: StatSpotExt[] = dataSpot;
    dataSpotExt.map((item) => {
        if (
            currencyConstraintData.dataSource?.find(
                (el) => el.name === item.content.last
            )
        ) {
            item.tradeAmount = currencyConstraintData?.dataSource?.find(
                (el) => el.name === item.content.last
            )?.trade_amount;
        } else {
            item.tradeAmount = currencyConstraintData?.dataSource?.find(
                (el) => el.currency === item.content.last
            )?.trade_amount;
        }
    });

    useEffect(() => {
        if (sort?.down == false && sort?.up == false) {
            setSort({...sort, sortName: '', up: true});
        }
    }, [sort?.down, sort?.up]);
    const defaultSort = () => {
        const sortedByTradeAmount = dataSpotExt
            .sort((a, b) => sortUp(a.tradeAmount, b.tradeAmount))
            .map((item, index) => index < 5 && SpotContent(item, index));
        const sortedByAlpha = dataSpotExt
            .slice(5)
            .sort((a, b) => sortUp(a?.name, b?.name))
            .map((item, index) => item && SpotContent(item, index));
        return sortedByTradeAmount.concat(sortedByAlpha);
    };
    return (
        <div className={'pair-content-container'}>
            <div className={'pair-content-name-col'}>
                {!!pairNames?.length &&
                    pairNames.map((item, index) => (
                        <ItemPair item={item} index={index} sort={sort} setSort={setSort}/>
                    ))}
            </div>
            <div className={'pair-content-list-items'}>
                {!!dataSpotExt?.length && sort?.sortName !== ''
                    ? dataSpotExt
                        .sort((a, b) =>
                            switchOrder(switchName(a, b).a, switchName(a, b).b)
                        )
                        .map((item, index) => SpotContent(item, index))
                    : defaultSort()}
            </div>
        </div>
    );
};

const ButtonStyled = styled.button`
    padding-left: 8px;
    padding-right: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: none;
    border: none;
    cursor: pointer;
`;

const TextButtonStyled = styled.span`
    display: flex;
    justify-content: flex-start;
    width: 112px;
`;

const SecondTextButtonStyled = styled.span`
    display: flex;
    justify-content: flex-end;
    width: 58px;
    color: #58bf92;
`;

const ThirdTextButtonStyled = styled.span`
    display: flex;
    justify-content: flex-end;
    width: 66px;
`;

const ContainerStyled = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`;

const IconContainerStyled = styled.button`
    width: 16px;
    height: 16px;
`;

export default Content;
