import React, {Dispatch, SetStateAction} from "react";
import { TypePaymentVariant } from "../modalContent";
import Card from "./Card/Card";
import Service from "./Service/Service";
import Transfer from "./Transfer/Transfer";
import Cash from "./Cash/Cash";
import SBP from "./SBP/SBP";
import { PayService } from "../../../../pages/p2p/settings/payment/payment";
import Phone from "./Phone/Phone";
import {IPaymentsType} from "../../../../pages/p2p/dashboard/interface";

interface Props {
  variantPayment: TypePaymentVariant;
  controlsTemplatePay: [PayService, Dispatch<PayService>];
  setIsValid: Dispatch<SetStateAction<boolean>>;
  clickSubmit:boolean;
  errorHasPayment:boolean;
  iconsData?: IPaymentsType[];
}

const phoneMask = (phone: string | undefined) => {
  return phone
    ?.replace(/[^0-9\.]/g, '')
    .replace(/\D/g, '')
    .replace(/^(\d)/, '($1')
    .replace(/^(\(\d{3})(\d)/, '$1) $2')
    .replace(/(\d{3})(\d{1,3})/, '$1-$2')
    .replace(/(\d{2})(\d{2,4})/, '$1-$2');
};

const SecondStep = (props: Props) => {
  return (
    <>
      {props.variantPayment === 'cards' && <Card {...props} />}
      {props.variantPayment === 'services' && <Service {...props} />}
      {props.variantPayment === 'mobiles' && (
        <Phone {...props} phoneMask={phoneMask} />
      )}
      {props.variantPayment === 'banks' && <Transfer {...props} /> /*not work*/}
      {props.variantPayment === 'cashes' && <Cash {...props} />}
      {props.variantPayment === 'sbp' && (
        <SBP {...props} phoneMask={phoneMask} />
      )}
    </>
  );
};

export default SecondStep;
