import {useEffect, useMemo, useState} from 'react';
import {Button, Form, Icon, Typography, notification} from '@pankod/refine-antd';
import Order from '../widgets/order';
import {
    HttpError,
    useCustom,
    useList,
    useOne,
    useRouterContext,
    useUpdate,
} from '@pankod/refine-core';
import {
    DEFAULT_ORDER_DATA,
    IOrderData,
    IOrderDataData,
    IUpdateOrder,
    OrderStatusEnum,
} from '../features/order/order.p';
import {ICryptoPair} from '../features/cryptocurrencyExchange/cryptoCurrencyExchange.p';
import {parseJwt} from '../shared/helpers/parseJWT';
import Modal from '../shared/components/ui/modal';
import {ICryptoCurrencyConstraintItem} from 'widgets/order/types';
import {AdaptiveFullScreenLoader} from '../shared/components/full-screen-loader';
import {useNavigate} from '@pankod/refine-react-router-v6';
import {useTranslation} from 'react-i18next';
import {Grid} from 'antd';
import ExchangeOrderMobile from './mobile/ExchangeOrder';
import {API_URL} from '../packages/keycloak-client/constants';
import {ErrorBoundary} from 'app/ErrorBoundary';
import styled from 'styled-components';
import {CloseNotification} from 'shared/components/icons';
import {useCookiesCustom} from '../shared/hooks/useCookieCustom';

const {useBreakpoint} = Grid;

const REFETCH_ORDER_INTERVAL = 60000;
const REFETCH_CRYPTO_COURSE_INTERVAL = 60000;

const updateOrderStatusValue = (status: string) => {
    switch (status) {
        case 'new':
            return OrderStatusEnum.NEW;
        case 'canceled':
            return OrderStatusEnum.CANCELED;
        case 'executed':
            return OrderStatusEnum.EXECUTED;
        case 'exchange_create_spot_order':
            return OrderStatusEnum.EXCHANGE_CREATE_SPOT_ORDER;
        case 'exchange_completed':
            return OrderStatusEnum.EXCHANGE_COMPLETED;
        case 'wallet_created':
            return OrderStatusEnum.WALLET_CREATED;
        case 'user_cancelled':
            return OrderStatusEnum.USER_CANCELLED;
        case 'confirmation_waiting':
            return OrderStatusEnum.CONFIRMATION_WAITING;
        case 'confirmation_completed':
            return OrderStatusEnum.CONFIRMATION_COMPLETED;
        case 'withdraw_waiting':
            return OrderStatusEnum.WITHDRAW_WAITING;
        case 'wallet_replenished':
            return OrderStatusEnum.WALLET_REPLENISHED;
        case 'exchange_processing':
            return OrderStatusEnum.EXCHANGE_PROCESSING;
        case 'transaction_waiting':
            return OrderStatusEnum.TRANSACTION_WAITING;
        case 'canceled_replenished':
            return OrderStatusEnum.CANCELED_REPLENISHED;
        case 'canceled_replenished_executed':
            return OrderStatusEnum.CANCELED_REPLENISHED_EXECUTED;
        default:
            return OrderStatusEnum.NEW;
    }
};

const ExchangeOrder = () => {
    const {cookie, removeCookie} = useCookiesCustom();
    const screens = useBreakpoint();
    const [orderStatus, setOrderStatus] = useState(OrderStatusEnum.NEW);
    const [cryptoCourse, setCryptoCourse] = useState(0);
    const {useParams} = useRouterContext();
    const params = useParams<{ id: string }>();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
    const [showReturnFundsModal, setShowReturnFundsModal] = useState(false);

    const [localData, setLocalData] =
        useState<IOrderDataData>(DEFAULT_ORDER_DATA);

    const [currentCryptoPair, setCurrentCryptoPair] = useState<
        ICryptoPair | undefined
    >(undefined);
    const [outputCommission, setOutputCommission] = useState('');
    const [networkCommission, setNetworkCommission] = useState('');
    const [minimalTradeAmount, setMinimalTradeAmount] = useState('');
    const [api, contextHolder] = notification.useNotification();
    let paramData: { order_id: string } | undefined;
    let isId = false;

    try {
        paramData = parseJwt<{ order_id: string }>(params.id);
    } catch (e) {
        paramData = {order_id: params.id};
        isId = true;
    }
    const {mutate} = useUpdate<IOrderData>();

    const getOrderData = async () => {
        await refetchOrder();
    };

    const {
        data,
        refetch: refetchOrder,
        isLoading,
    } = useOne<IOrderDataData, HttpError>({
        resource: 'orders',
        dataProviderName: 'offchain-exchange',
        id: paramData ? paramData?.order_id : '',
        metaData: {
            headers: !isId
                ? {
                    OrderToken: params?.id,
                }
                : undefined,
        },
        queryOptions: {
            enabled: !!paramData,
            refetchInterval: REFETCH_ORDER_INTERVAL,
            onSuccess: ({data}) => {
                if (!!data) {
                    if (data?.status === 'failed') {
                        updateOrderData({
                            orderId: data.id,
                            status: 'canceled_replenished',
                        });
                    } else {
                        setLocalData({
                            ...data,
                            ...(data?.balance !== 0 &&
                                currentCryptoPair && {
                                    input_sum: `${data?.balance || data?.input_sum}`,
                                    input_price: `${+currentCryptoPair?.price * +(data?.balance)}`,
                                }),
                        });
                        data.balance !== 0
                            ? setLocalData({...data, input_sum: `${data.input_sum}`})
                            : setLocalData(data);
                        setOrderStatus(updateOrderStatusValue(data?.status));
                    }
                }
            },
            onError: () => {
                navigate(-1);
            },
        },
        errorNotification: false,
        successNotification(data, values, resource) {
            const {data: orderData} = data as { data: IOrderData };
            if (orderData?.data?.status == 'executed') {
                return {
                    message: `${t(
                        'notifications.orderExecutedDescriptionPre'
                    ).toString()} #${orderData?.data?.id} ${t(
                        'notifications.orderExecutedDescriptionMiddle'
                    ).toString()} ${orderData?.data?.input_currency}-${
                        orderData?.data?.output_currency
                    } ${t('notifications.orderExecutedDescriptionPost').toString()}`,
                    type: 'success',
                    description: t('notifications.orderExecuted').toString(),
                };
            }
            if (
                (orderData?.data?.status === 'canceled' &&
                    localData?.status !== 'canceled') ||
                (orderData?.data?.status === 'canceled_replenished' &&
                    localData?.status !== 'canceled_replenished')
            )
                return {
                    message: `${t(
                        'notifications.orderCanceledDescriptionPre'
                    ).toString()} #${orderData?.data?.id} ${t(
                        'notifications.orderCanceledDescriptionMiddle'
                    ).toString()} ${orderData?.data?.input_currency}-${
                        orderData?.data?.output_currency
                    } ${t('notifications.orderCanceledDescriptionPost').toString()}`,
                    type: 'success',
                    description: t('notifications.orderCanceled').toString(),
                };
            if (orderData?.data?.balance > localData?.balance)
                return {
                    message: `${t(
                        'notifications.cashReceiptsDescriptionPre'
                    ).toString()} #${orderData?.data?.id} ${t(
                        'notifications.cashReceiptsDescriptionPost'
                    ).toString()} ${orderData?.data?.input_currency}-${
                        orderData?.data?.output_currency
                    }`,
                    type: 'success',
                    description: t('notifications.cashReceipts').toString(),
                };
            return {
                message: '',
                type: 'success',
            };
        },
    });

    const listOfCryptoPairs = useCustom<{ data: ICryptoPair[] }, HttpError>({
        url: `${API_URL}/config/api/info/offchain-pair-price`,
        method: 'get',
        queryOptions: {
            enabled: !!localData,
            refetchInterval: REFETCH_CRYPTO_COURSE_INTERVAL,
            onSuccess: ({data: requestData}) => {
                const data = requestData?.data;
                setCurrentCryptoPair(
                    data?.find(
                        (item) =>
                            item.pair ==
                            `${localData?.input_currency}/${localData?.output_currency}`
                    )
                );
            },
        },
    });

    const updateOrderData = ({
                                 status,
                                 outputValue,
                                 inputValue,
                                 orderId,
                                 cancelWallet,
                                 user_confirmation_status,
                             }: IUpdateOrder) => {
        mutate(
            {
                resource: 'orders',
                dataProviderName: 'offchain-exchange',
                values: {
                    status,
                    input_price: inputValue,
                    output_price: outputValue,
                    cancel_wallet: cancelWallet,
                    user_confirmation_status,
                },
                metaData: !cookie['token']
                    ? {
                        headers: {
                            OrderToken: params.id,
                        },
                    }
                    : undefined,
                id: orderId,
                successNotification: (data) => {
                    const {data: orderData} = data as { data: IOrderData };
                    if (
                        orderData?.data?.status == 'canceled' ||
                        orderData?.data?.status == 'canceled_replenished' ||
                        orderData?.data?.status == 'user_canceled'
                    )
                        return {
                            message: `${t(
                                'notifications.orderCanceledDescriptionPre'
                            ).toString()} #${orderData?.data?.id} ${t(
                                'notifications.orderCanceledDescriptionMiddle'
                            ).toString()} ${orderData?.data?.input_currency}-${
                                orderData?.data?.output_currency
                            } ${t('notifications.orderCanceledDescriptionPost').toString()}`,
                            type: 'success',
                            description: t('notifications.orderCanceled').toString() + 'KEK',
                            duration: 15,
                        };
                    return {
                        message: '',
                        type: 'success',
                    };
                },
            },
            {
                // TODO модалка отмены
                onSuccess: (resp) => {
                    setLocalData(resp?.data?.data);
                    setOrderStatus(updateOrderStatusValue(resp?.data?.data?.status));
                    if (resp.data.data.status === 'user_canceled') {
                        api.error({
                            message:
                                <NotificationTitle>
                                    {t('notifications.exchange.orderCanceled')}
                                </NotificationTitle>,
                            description: <DescriptionWrapper>
                <span>
                  {t('notifications.exchange.orderCanceledDescriptionPre')}{' '}
                    <BlueText>№{resp.data?.data?.id}</BlueText>{' '}
                    {t('notifications.exchange.orderCanceledDescriptionMiddle')}{' '}
                    {resp.data?.data?.input_currency}-{resp.data?.data?.output_currency}{' '}
                    {t('notifications.exchange.orderCanceledDescriptionPost')}
                </span>
                                <BtnWrapper>
                                    <StyledButton
                                        type="primary"
                                        onClick={() => {
                                        }}
                                    >
                                        {t('notifications.exchange.btnMoneyBack')}
                                    </StyledButton>
                                </BtnWrapper>
                            </DescriptionWrapper>,
                            placement: 'topRight',
                            duration: 15,
                            closeIcon: <Icon component={CloseNotification} style={{fontSize: 24}}/>,
                        });
                    }
                },
            }
        );
    };

    const {data: currencyConstraintDataList} = useList<
        ICryptoCurrencyConstraintItem,
        HttpError
    >({
        resource: 'currency-constraint',
        dataProviderName: 'config',
        config: {
            pagination: {
                current: 1,
                pageSize: 15,
            },
        },
    });

    const currentCurrencyPairId = currencyConstraintDataList?.data.find(
        (item) => data?.data?.input_currency == item?.currency
    )?.id;
    const {
        refetch: refetchCurrencyConstraintData,
    } = useOne<ICryptoCurrencyConstraintItem, HttpError>({
        resource: 'currency-constraint',
        dataProviderName: 'config',
        id: currentCurrencyPairId ? currentCurrencyPairId : '',
        metaData: {
            headers: !isId
                ? {
                    OrderToken: params.id,
                }
                : undefined,
        },
        queryOptions: {
            onSuccess: ({data}) => {
                setMinimalTradeAmount(data.minimal_trade_amount);
                setOutputCommission(data.output_commission);
                setNetworkCommission(data.node_commission_percent);
            },
        },
    });

    useEffect(() => {
        localData.order_wallet === null && refetchOrder();
    }, [localData]);

    useEffect(() => {
        setInterval(() => {
            getOrderData().then();
        }, 60000);
    }, []);

    useEffect(() => {
        setShowCancelOrderModal(false);
    }, [orderStatus]);

    // fetch crypto currencies pairs list on defined localData to get crypto pair id
    useEffect(() => {
        localData && listOfCryptoPairs.refetch();
    }, [localData?.input_sum, localData?.input_price]);

    useEffect(() => {
        refetchCurrencyConstraintData().then();
    }, [currentCurrencyPairId]);

    const commissionAmount = useMemo(
        () =>
            `${currentCryptoPair ? +currentCryptoPair?.maker_commission : 0} ${
                localData?.input_currency
            }`,
        [currentCryptoPair, localData?.input_currency]
    );

    if (isLoading) {
        return <AdaptiveFullScreenLoader/>;
    }
    if (!screens?.md!) {
        return (
            <ExchangeOrderMobile
                minimalTradeAmount={minimalTradeAmount}
                updateOrderStatus={(newStatus) => setOrderStatus(newStatus)}
                orderStatus={orderStatus}
                cancelOrder={() => setShowCancelOrderModal(true)}
                returnFunds={() => setShowReturnFundsModal(true)}
                data={localData}
                currentPair={currentCryptoPair}
                updateOrder={updateOrderData}
            />
        );
    }

    return (
        <ErrorBoundary logout={() => {
        }}
                       navigate={navigate}
                       removeCookie={removeCookie}>
            {contextHolder}
            <div className={'exchange-order-container'}>
                <Typography.Title>{t('cryptoExchange.order.title')}</Typography.Title>
                <Order
                    minimalTradeAmount={minimalTradeAmount}
                    updateOrderStatus={(newStatus) => setOrderStatus(newStatus)}
                    orderStatus={orderStatus}
                    cancelOrder={() => setShowCancelOrderModal(true)}
                    returnFunds={() => setShowReturnFundsModal(true)}
                    data={localData}
                    currentPair={currentCryptoPair}
                    updateOrder={updateOrderData}
                    exchangeCommission={outputCommission}
                    networkCommission={networkCommission}
                />
            </div>
            <Modal.CancelOrder
                width={560}
                okText={t('cryptoExchange.modal.cancelOrder.primaryButton')}
                cancelText={t('cryptoExchange.modal.cancelOrder.outlinedButton')}
                onCancel={() => setShowCancelOrderModal(false)}
                onOk={() => {
                    if (
                        orderStatus !== OrderStatusEnum.NEW &&
                        orderStatus !== OrderStatusEnum.WALLET_CREATED
                    ) {
                        updateOrderData({
                            orderId: localData?.id,
                            user_confirmation_status: 'canceled',
                        });
                    } else {
                        updateOrderData({
                            orderId: localData?.id,
                            user_confirmation_status: 'canceled',
                        });
                    }
                    setShowCancelOrderModal(false);
                }}
                open={showCancelOrderModal}
                orderId={paramData?.order_id}
                commissionAmount={`${
                    Number(networkCommission) || 0
                } ${localData?.input_currency}`}
            />

            <Modal.CancelReplenishedOrder
                onOk={() => {
                    updateOrderData({
                        orderId: localData?.id,
                        user_confirmation_status: 'canceled',
                        cancelWallet: form.getFieldValue('cancelWallet'),
                    });
                    setShowReturnFundsModal(false);
                }}
                orderId={paramData?.order_id}
                onCancel={() => setShowReturnFundsModal(false)}
                form={form}
                width={560}
                open={showReturnFundsModal}
                okText={t('cryptoExchange.modal.cancelReplenishedOrder.primaryButton')}
                cancelText={t(
                    'cryptoExchange.modal.cancelReplenishedOrder.outlinedButton'
                )}
                commissionAmount={commissionAmount}
            />
        </ErrorBoundary>
    );
};

export default ExchangeOrder;

const NotificationTitle = styled(Typography)`
  font-weight: 700;
  line-height: 19.36px;
  font-size: 16px;
`

const BlueText = styled.span`
  font-weight: 700;
  color: #5297FF;
  text-decoration: underline solid #5297FF;
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const BtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const StyledButton = styled(Button)`
  width: 127px;
  padding: 16px !important;
  margin-inline-end: -30px;
`
