import {Typography} from "@pankod/refine-antd"
import {ReactComponent as ArrowForwardIcon} from '../../../../../shared/components/ui/assets/svg/arrowForward.svg';
import {AdTypesEnum, IFiat} from "../../types";
import {useTranslate} from "@pankod/refine-core";
import {ICryptoCurrencyConstraintItem} from "widgets/order/types";
import styled from "styled-components";
import {CurrencyTagsList} from "./currencyTagsList";
import CustomSelect from "shared/components/ui/select";

type TCurrencySelects = {
    adType: AdTypesEnum;
    editAd: boolean | undefined;
    selectStatus: (currency: ICryptoCurrencyConstraintItem | IFiat | undefined) => "error" | "";
    chosenCurrency: ICryptoCurrencyConstraintItem | undefined;
    chosenFiat: IFiat | undefined;
    selectPrefixIcon: {
        currency: false | JSX.Element;
        fiat: false | JSX.Element;
    };
    setCustomSelectValue: {
        currency: (value: any) => void;
        fiat: (value: any) => void;
    };
    selectCustomSelectData: (
        isLoading: boolean,
        array: ICryptoCurrencyConstraintItem[] | IFiat[] | undefined
    ) => {
        label: JSX.Element;
        value: number;
        valueC: string;
    }[];
    customSelectPlaceholder: {
        currency: string | JSX.Element;
        fiat: string | JSX.Element;
    };
    isCurrencyListLoading: boolean;
    isFiatListLoading: boolean;
    fiatList: IFiat[] | undefined;
    currencyList: ICryptoCurrencyConstraintItem[] | undefined;
    handleChangeCurrency: (currency?: ICryptoCurrencyConstraintItem) => void;
    handleChangeFiat: (fiat?: IFiat) => void;
}

export const CurrencySelects = ({
                                    adType,
                                    editAd,
                                    selectStatus,
                                    chosenCurrency,
                                    chosenFiat,
                                    selectPrefixIcon,
                                    setCustomSelectValue,
                                    customSelectPlaceholder,
                                    selectCustomSelectData,
                                    isCurrencyListLoading,
                                    isFiatListLoading,
                                    currencyList,
                                    fiatList,
                                    handleChangeCurrency,
                                    handleChangeFiat
                                }: TCurrencySelects) => {
    const t = useTranslate();
    const selectElements = [
        <div className="choose-p2p-ad-type-dropdown-list-item" key={'currency-select'}>
            <Typography.Text>{t('p2p.chooseAdType.active')}</Typography.Text>
            <CustomSelect
                status={selectStatus(chosenCurrency)}
                disabled={editAd}
                key={'select-sell-active'}
                prefixIcon={selectPrefixIcon.currency}
                value={chosenCurrency?.id}
                showSearch={true}
                setValue={setCustomSelectValue.currency}
                data={selectCustomSelectData(isCurrencyListLoading, currencyList)}
                placeholder={customSelectPlaceholder.currency}
            />

            <div className="choose-p2p-ad-type-tag-list-wrapper">

                <CurrencyTagsList handleChangeCurrency={handleChangeCurrency} currencyList={currencyList}
                                  chosenCurrency={chosenCurrency}/>
            </div>
        </div>,
        <div className="choose-p2p-ad-type-dropdown-list-item-icon" key={'arrow-element'}>
            <Arrow/>
        </div>,
        <div className="choose-p2p-ad-type-dropdown-list-item" key={'fiat-select'}>
            <Typography.Text>{t('p2p.chooseAdType.fiat')}</Typography.Text>
            <CustomSelect
                status={selectStatus(chosenFiat)}
                key={'select-sell-fiat'}
                value={chosenFiat?.id}
                showSearch={true}
                disabled={editAd}
                setValue={setCustomSelectValue.fiat}
                prefixIcon={selectPrefixIcon.fiat}
                data={selectCustomSelectData(isFiatListLoading, fiatList)}
                placeholder={customSelectPlaceholder.fiat}
            />

            <div className="choose-p2p-ad-type-tag-list-wrapper">
                <CurrencyTagsList fiatList={fiatList} handleChangeFiat={handleChangeFiat} chosenCurrency={chosenFiat}/>
            </div>
        </div>
    ]
    return (
        <div className="choose-p2p-ad-type-dropdown-list">
            {adType === AdTypesEnum.SELL ? (
                selectElements.map((item: JSX.Element) => item)
            ) : (
                selectElements.slice(0).reverse().map((item: JSX.Element) => item)
            )}
        </div>
    )
}

const Arrow = styled(ArrowForwardIcon)`
    width: 16px;
`
