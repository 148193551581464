import React from 'react';
import { NavLink } from '@pankod/refine-react-router-v6';
import Icon from '@ant-design/icons';
import { Typography } from 'antd';
import { IDrawerMenuItem } from '../../../../../interfaces';
import { ShevronIcon } from '../../../../ui/assets/svg/infoIcons';

const Index: React.FC<IDrawerMenuItem & { closeAction: () => void }> = ({
  link,
  iconComponent,
  text,
  hasAdditionLevel,
  onClickAction,
  closeAction,
}) => {
  return (
    <>
      <div
        style={{
          padding: 16,
        }}
        onClick={() => {
          if (onClickAction) {
            onClickAction();
          } else {
            closeAction();
          }
        }}
      >
        <NavLink to={link} className={'flex-row gap-24'}>
          <Icon component={() => iconComponent} />
          <div
            className={'flex-row justify-content-space-between'}
            style={{
              width: '100%',
            }}
          >
            <Typography.Text>{text}</Typography.Text>
            {hasAdditionLevel && <Icon component={() => ShevronIcon({})} />}
          </div>
        </NavLink>
      </div>
    </>
  );
};

export default Index;
