import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Grid, Layout as AntdLayout} from 'antd';
import type {RefineLayoutLayoutProps} from '@pankod/refine-ui-types';
import {Header as DefaultHeader} from './header';
import {Sidebar} from './sidebar';
import {useLocation} from '@pankod/refine-react-router-v6';
import {ConfigProvider} from '@pankod/refine-antd';
import {useGetScreenSize} from '../../hooks/screen/use-get-screen-size';
import {mainTheme} from '../ui/themes';
import i18next from 'i18next';
import ru from 'antd/locale/ru_RU';
import en from 'antd/locale/en_US';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';
import dayjs from 'dayjs';
import FooterForExchange from '../../../shared/components/layout/footer/Footer';
import {useCookiesCustom} from '../../hooks/useCookieCustom';
import styled from "styled-components";

export const Layout: React.FC<RefineLayoutLayoutProps> = ({
                                                              children,
                                                              Header,
                                                              Footer,
                                                              OffLayoutArea,
                                                          }) => {
    const {cookie} = useCookiesCustom();
    const loc = useLocation();

    const breakpoint = Grid.useBreakpoint();
    const isSmall = typeof breakpoint.sm === 'undefined' ? true : breakpoint.sm;

    const {width} = useGetScreenSize();
    const isMobileAdaptive = width <= 768;

    const [locale, setLocale] = useState(localStorage['i18nextLng']);

    const SiderToRender = () => {
        const pathsToCheck = [
            '/cryptocurrency-exchange',
            'terms',
            'spot',
            '/main',
            'policy',
            'exchange-outputOrder',
            'blocked'
        ];

        if (pathsToCheck.some(path => loc.pathname.includes(path)) || !cookie['token']) {
            return <></>;
        } else {
            return <Sidebar/>;
        }
    };

    const HeaderToRender = Header ?? DefaultHeader;

    function setAntdLocale(locale: string) {
        if (locale && locale?.includes('ru')) return ru;
        else return en;
    }

    useEffect(() => {
        setLocale(i18next.language);
        dayjs.locale(i18next.language);
    }, [i18next.language]);


    return (
        <ConfigProvider theme={mainTheme} locale={setAntdLocale(locale)}>
          <ContainerStyled>
                <SiderToRender/>
                    <ContentContainerStyled>
                      <HeaderToRender/>
                      <AntdLayout.Content>
                        <div
                          style={{
                            minHeight: 360,
                            height: '100%',
                            padding: isSmall ? 32 : isMobileAdaptive ? 16 : 12,
                            marginTop: isMobileAdaptive ? 12 : 0,
                            paddingBottom: 0,
                          }}
                          translate="no"
                        >
                          {children}
                        </div>
                        {OffLayoutArea && <OffLayoutArea/>}
                      </AntdLayout.Content>
                      {Footer && <Footer/>}
                      {loc.pathname.includes('exchange') && <FooterForExchange/>}
                    </ContentContainerStyled>
          </ContainerStyled>
        </ConfigProvider>
    );
};

const ContainerStyled = styled.div`
  display: flex;
`
const ContentContainerStyled = styled.div`
  width: 100%
`
