import React, { useContext, useEffect, useState } from 'react';
import { notification, Typography } from 'antd';
import {
  ConfigProvider,
  Input,
  InputNumber,
  Select,
  Slider,
} from '@pankod/refine-antd';
import Button from '../../shared/components/ui/button';
import { useCustomMutation } from '@pankod/refine-core';
import { API_URL } from '../../packages/keycloak-client/constants';
import { useTranslation } from 'react-i18next';
import { axios } from '../../shared/exios';
import { styled } from 'styled-components';
import { useLocation } from '@pankod/refine-react-router-v6';
import { useGetScreenSize } from '../../shared/hooks/screen/use-get-screen-size';
import SpotTableLoginInner from './spotTableLoginInner';
import i18n from 'i18next';
import { SpotMarketContext } from '../../pages/spot/spotDashboard/spotDashboard';
import { InputWithSuffix } from './InputWithSuffix';
import { useCookiesCustom } from '../../shared/hooks/useCookieCustom';

const SpotPart = () => {
  const {cookie} = useCookiesCustom();
  const {
    dashboard: { type, setType },
  } = useContext(SpotMarketContext);
  const { width } = useGetScreenSize();
  const isMobileAdaptive = width <= 768;
  const location = useLocation();
  const { t } = useTranslation();
  const [market, setMarket] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [marketMobile, setMarketMobile] = useState('false');

  const [activePair, setActivePair] = useState(
    () => localStorage['activePair']
  );
  const [ccy0, setCcy0] = useState({
    all: 0,
    count: 0,
    price: 0,
    balance: 0,
  });
  const [ccy1, setCcy1] = useState({
    all: 0,
    count: 0,
    price: 0,
    balance: 0,
  });
  const { mutate } = useCustomMutation<{
    market: boolean;
    price: number;
    type: string;
    deposit: number;
    hidden: boolean;
  }>();

  const handleChangeMarket = (value: boolean) => {
    setMarket(value);
  };

  const priceType = () => {
    if (type === 'buy') return +ccy1?.price || 0;
    else return +ccy0?.price || 0;
  };

  const balanceType = () => {
    if (type === 'buy') return +ccy1?.balance || 0;
    else return +ccy0?.balance || 0;
  };

  const coinType = () => {
    if (type === 'buy') return activePair?.split('-')[1];
    else return activePair?.split('-')[0];
  };

  const countType = () => {
    if (type === 'buy') return ccy1?.count;
    else return ccy0?.count;
  };

  const allType = () => {
    if (type === 'buy') return ccy1?.all?.toFixed(8);
    else return ccy0?.all?.toFixed(8);
  };

  const depositType = () => (type === 'buy' ? allType() : countType());

  const sliderPercentType = () => {
    if (type === 'buy') {
      if (!!sliderValue && ccy1?.balance > 0)
        return (sliderValue / (ccy1?.balance / 100))?.toFixed() || 0;
      else return 0;
    } else {
      if (!!sliderValue && ccy0?.balance > 0)
        return (sliderValue / (ccy0?.balance / 100))?.toFixed() || 0;
      else return 0;
    }
  };

  const sliderStep = () => +balanceType() / 100;

  function setSliderCcy(value: any) {
    setSliderValue(value || 0);
    const count = +value / priceType() || 0;
    type === 'buy'
      ? setCcy1((prevState) => {
          return {
            ...prevState,
            count: +count,
            all: +(count * prevState?.price)?.toFixed(8) || 0,
          };
        })
      : setCcy0((prevState) => {
          return {
            ...prevState,
            count: +count,
            all: +(count * prevState?.price)?.toFixed(8) || 0,
          };
        });
  }

  function setTotalCcy(e: any) {
    if (e) {
      const value =
        Number(e.target.value) > balanceType()
          ? balanceType()
          : Number(e.target.value);

      type === 'buy'
        ? setCcy1((prevState) => {
            return {
              ...prevState,
              all: +value?.toFixed(8) || 0,
              count: +(value / prevState?.price)?.toFixed(8) || 0,
            };
          })
        : setCcy0((prevState) => {
            return {
              ...prevState,
              all: +value?.toFixed(8) || 0,
              count: +(value / prevState?.price)?.toFixed(8) || 0,
            };
          });
      setSliderValue(value || 0);
    }
  }

  function setPriceAndCountCcy(e: any) {
    if (e) {
      const value = +e;
      type === 'buy'
        ? setCcy1((prevState) => {
            return {
              ...prevState,
              price: value,
              count: sliderValue / value,
            };
          })
        : setCcy0((prevState) => {
            return {
              ...prevState,
              price: value,
              count: sliderValue / value,
            };
          });
    }
  }

  function setCountCcy(e: any) {
    if (e) {
      const value = +e;
      const all = value * priceType();
      type === 'buy'
        ? setCcy1((prevState) => {
            return {
              ...prevState,
              count: value,
              all,
            };
          })
        : setCcy0((prevState) => {
            return {
              ...prevState,
              count: value,
              all,
            };
          });
      setSliderValue(all || 0);
    }
  }

  function translateBackErrors(message: string) {
    if (
      message.includes('The price must be between 0.00000001 and 9999999999')
    ) {
      if (i18n.language === 'ru')
        return 'Цена должна быть от 0,00000001 до 999999999.';
      else return message;
    }
    if (
      message.includes('The deposit must be between 0.00000001 and 999999999')
    ) {
      if (i18n.language === 'ru')
        return 'Депозит должен быть от 0,00000001 до 999999999.';
      else return message;
    } else return message;
  }

  function actionBuySell() {
    notification.info({
      message:
        type === 'buy' ? t('messages.info.buy') : t('messages.info.sell'),
    });
    mutate(
      {
        url: `${API_URL}/${localStorage['activePair']
          ?.replace('-', '')
          ?.toLowerCase()}-spot/api/orders`,
        dataProviderName: 'spot',
        values: {
          market: isMobileAdaptive ? stringToBoolean(marketMobile) : market,
          price: priceType(),
          type: type,
          deposit: depositType(), // TODO: Добавить оповещение о недостаточном балансе
          hidden: true,
        },
        method: 'post',
      },
      {
        onSuccess: () => {
          notification.success({
            message:
              type === 'buy'
                ? t('messages.success.buy')
                : t('messages.success.sale'),
          });
        },
        onError: (e) => {
          notification.error({
            message: t('messages.error.unexpectedError'),
            description: translateBackErrors(e.message),
          });
        },
      }
    );
  }

  function stringToBoolean(stringVal: string) {
    return marketMobile !== 'false';
  }

  async function setSpotPartBalance() {
    if (!!cookie['token']) {
      const coins = activePair?.split('-');
      const ccy0 = coins[0]?.toLowerCase();
      const ccy1 = coins[1]?.toLowerCase();
      const ccyArr = [ccy0, ccy1];

      for (const ccy of ccyArr) {
        try {
          const res = await axios.get(`${API_URL}/${ccy}-coin/api/account`, {
            headers: {
              Authorization: cookie['token'] ? `Bearer ${cookie['token']}`: '',
            },
          });
          const amount = res?.data?.data[0]?.amount || 0;
          localStorage.setItem(`balance-${ccy}`, amount);
        } catch (e) {
          console.log(`getBalance ${ccy} error`, e);
          localStorage.setItem(`balance-${ccy}`, String(0));
        }
      }

      setCcy0((prevState) => {
        return {
          ...prevState,
          balance: localStorage[`balance-${ccy0}`],
        };
      });

      setCcy1((prevState) => {
        return {
          ...prevState,
          balance: localStorage[`balance-${ccy1}`],
        };
      });
    }
  }

  useEffect(() => {
    setSpotPartBalance().then();

    const updateDataFastInterval = setInterval(() => {
      setSpotPartBalance().then();
    }, 35000);

    return () => {
      clearInterval(updateDataFastInterval);
    };
  }, [location.pathname]);

  useEffect(() => {
    setActivePair(() => localStorage['activePair']);
  }, []);

  useEffect(() => {
    setActivePair(() => localStorage['activePair']);
  }, [location?.pathname]);

  const handleChangeMarketMobile = (value: string) => {
    setMarketMobile(true + '');
  };

  function renderButton() {
    if (!!cookie['token']) {
      return (
        <Button
          onClick={() => actionBuySell()}
          height={'40px'}
          disabled={type === 'buy' ? ccy1.balance === 0 : ccy0.balance === 0}
          className={type === 'buy' ? 'spot-button-buy' : 'spot-button-sell'}
        >
          {type === 'buy' ? t('buttons.buy') : t('buttons.sell')}
        </Button>
      );
    } else {
      return (
        <NotAuthButton>
          <SpotTableLoginInner />
        </NotAuthButton>
      );
    }
  }

  function determineMarket() {
    return isMobileAdaptive ? stringToBoolean(marketMobile) : market;
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorTextPlaceholder: '#FBFBFF',
          },
          Button: {},
        },
      }}
    >
      <div>
        {isMobileAdaptive ? (
          <></>
        ) : (
          <div style={{ marginBottom: '16px' }}>
            <Typography.Text>{t('spotPart.spot')}</Typography.Text>
          </div>
        )}
        <div style={{ marginBottom: '20px' }}>
          <div
            style={{
              width: '100%',
              backgroundColor: '#2B2B36',
              borderRadius: '6px',
            }}
          >
            <div
              style={{
                padding: '8px 0',
                width: '50%',
                display: 'inline-block',
                textAlign: 'center',
                borderRadius: '6px',
                cursor: 'pointer',
                backgroundColor: type === 'buy' ? '#58BF92' : '',
              }}
              onClick={() => setType('buy')}
            >
              <Typography.Text>{t('spotPart.buy')}</Typography.Text>
            </div>
            <div
              style={{
                padding: '8px 0',
                width: '50%',
                display: 'inline-block',
                textAlign: 'center',
                borderRadius: '6px',
                cursor: 'pointer',
                backgroundColor: type === 'sell' ? '#F16063' : '',
              }}
              onClick={() => setType('sell')}
            >
              <Typography.Text>{t('spotPart.sell')}</Typography.Text>
            </div>
          </div>
        </div>

        {isMobileAdaptive ? (
          <div style={{ marginBottom: '20px' }}>
            <Select
              defaultValue="false"
              style={{ width: 120 }}
              onChange={handleChangeMarketMobile}
              options={[
                { value: 'false', label: `${t('spotPart.limit')}` },
                { value: 'true', label: `${t('spotPart.market')}` },
              ]}
            />
          </div>
        ) : (
          <ChangeMarketRow style={{ marginBottom: '16px' }}>
            <div
              className={
                'flex-row justify-content-space-between spot-market-type'
              }
            >
              <div
                className={
                  'flex-row justify-content-space-between gap-16 align-items-center'
                }
              >
                <div onClick={() => handleChangeMarket(false)}>
                  <Typography.Text
                    style={{
                      color: market ? '#767687' : '#FBFBFF',
                      cursor: 'pointer',
                    }}
                  >
                    {t('spotPart.limit')}
                  </Typography.Text>
                </div>
                <div onClick={() => handleChangeMarket(true)}>
                  <Typography.Text
                    style={{
                      color: market ? '#FBFBFF' : '#767687',
                      cursor: 'pointer',
                    }}
                  >
                    {t('spotPart.market')}
                  </Typography.Text>
                </div>
              </div>
              <div className={'flex-row gap-4 align-items-center'}>
                <Typography.Text>
                  {t('spotPart.balance')} {balanceType()} {coinType()}
                </Typography.Text>
                {/* TODO: вернуть кнопку пополнения баланса */}
                {/*<PlusCircle color={'#FBFBFF'} size={12} />*/}
              </div>
            </div>
          </ChangeMarketRow>
        )}

        <div className={'spot-body'}>
          <div
            style={{
              width: '100%',
              backgroundColor: '#2B2B36',
              borderRadius: '6px',
              marginBottom: '27px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '50%',
                // display: 'inline-block',
                paddingLeft: '16px',
                // height: '30px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                style={{
                  color: determineMarket() ? '#474755' : 'rgb(251, 251, 255)',
                }}
              >
                {t('spotPart.price')}
              </Typography>
            </div>
            {/*<div*/}
            {/*  style={{*/}
            {/*    width: '50%',*/}
            {/*    display: 'flex',*/}
            {/*    alignItems: 'center',*/}
            {/*    justifyContent: 'flex-end',*/}
            {/*  }}*/}
            {/*>*/}
            <InputWithSuffix
              onChange={(e: any) => setPriceAndCountCcy(e)}
              value={determineMarket() ? '' : priceType()}
              bordered={false}
              placeholder={
                determineMarket() ? '' : t('spotPart.price').toString()
              }
              disabled={
                determineMarket()
                  ? true
                  : type === 'buy'
                  ? ccy1.balance === 0
                  : ccy0.balance === 0
              }
              suffix={coinType()}
              // TODO: Добавить suffix, вынести в базовый компонент
              // suffix={
              //   <Typography.Text>
              //     {/*{test.price}*/}
              //     USDT
              //   </Typography.Text>
              // }
            />
            {/*</div>*/}
          </div>
          <div
            style={{
              width: '100%',
              backgroundColor: '#2B2B36',
              borderRadius: '6px',
              marginBottom: '27px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '50%',
                // display: 'inline-block',
                paddingLeft: '16px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography className="mob-display-none">
                {t('spotPart.quantity')}
              </Typography>
              <Typography className="desktop-display-none">
                {t('spotPart.quantityShortcut')}
              </Typography>
            </div>
            <div
              style={{
                width: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <CustomInputNumber
                onChange={(e: any) => setCountCcy(e)}
                value={countType()}
                bordered={false}
                placeholder={t('spotPart.quantity').toString()}
                disabled={
                  type === 'buy' ? ccy1.balance === 0 : ccy0.balance === 0
                }
                // suffix={
                //   <Typography.Text>
                //     {/*{test.count}*/}
                //     BTC
                //   </Typography.Text>
                // }
                style={{ backgroundColor: '#2B2B36' }}
              />
            </div>
          </div>

          <div
            style={{ width: '100%', marginBottom: '16px' }}
            className={'flex-row align-items-center'}
          >
            <Typography.Text
              style={{ width: '11%' }}
              className={'spot-percent'}
            >
              {sliderPercentType()}%
            </Typography.Text>
            <Slider
              disabled={
                type === 'buy' ? ccy1.balance === 0 : ccy0.balance === 0
              }
              style={{ width: '90%' }}
              value={!sliderValue ? 0 : sliderValue}
              onChange={(value) => setSliderCcy(value)}
              tooltip={{ formatter: null }}
              min={0}
              max={balanceType()}
              step={sliderStep()}
            />
          </div>
          <div
            style={{
              width: '100%',
              backgroundColor: '#2B2B36',
              borderRadius: '6px',
              marginBottom: '27px',
            }}
          >
            <div
              style={{
                width: '50%',
                display: 'inline-block',
                paddingLeft: '16px',
              }}
            >
              <Typography>{t('spotPart.total')}</Typography>
            </div>
            <div style={{ width: '50%', display: 'inline-block' }}>
              <Input
                onChange={(e) => setTotalCcy(e)}
                value={allType()}
                bordered={false}
                placeholder={t('spotPart.total').toString()}
                // TODO: Добавить suffix, вынести в базовый компонент, добавить заголовок
                // suffix={
                //   <Typography.Text>
                //     {/*{test.all} */}
                //     USDT
                //   </Typography.Text>
                // }
                style={{ backgroundColor: '#2B2B36' }}
              />
            </div>
          </div>

          <div
            className={'flex gap-4 align-items-center'}
            style={{ marginBottom: '16px' }}
          >
            <Typography.Text className={'commission-spot'}>
              {/*TODO: Уточнить по поводу валюты комиссии, хардкод*/}
              {t('spotPart.commission')} 0.005 {activePair?.split('/')[1]}
            </Typography.Text>
            {/* TODO: Добавить текст подсказки по комиссии*/}
            {/*<Popover*/}
            {/*  content={*/}
            {/*    <div>*/}
            {/*      <p>Content</p>*/}
            {/*      <p>Content</p>*/}
            {/*    </div>*/}
            {/*  }*/}
            {/*  title="Title"*/}
            {/*  trigger="hover"*/}
            {/*>*/}
            {/*  <div>*/}
            {/*    <QuestionCircle size={12} />*/}
            {/*  </div>*/}
            {/*</Popover>*/}
          </div>
          <div style={{ marginBottom: '16px' }}>{renderButton()}</div>
        </div>
      </div>
    </ConfigProvider>
  );
};

const NotAuthButton = styled.div`
  width: 100%;
  height: 40px;
  background-color: rgb(43, 43, 54);
  border-radius: 6px;
  margin-bottom: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomInputNumber = styled(InputNumber)`
  padding: 0 !important;
  width: 120px;

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number-input-wrap input:focus-visible {
    border: none !important;
  }
`;

const ChangeMarketRow = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`;

export default SpotPart;
