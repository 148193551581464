import React from 'react';
import { Icon, Typography } from '@pankod/refine-antd';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

interface IFullscreenRingWithTextAndIcon {
  centerIcon:
    | React.ComponentType<
        CustomIconComponentProps | React.SVGProps<SVGSVGElement>
      >
    | React.ForwardRefExoticComponent<CustomIconComponentProps>;
  text: string;
}

const FullscreenRingWithTextAndIcon: React.FC<
  IFullscreenRingWithTextAndIcon
> = ({ centerIcon, text }) => {
  return (
    <>
      <div
        style={{
          height: '75vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 48,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Icon
            className={'icon'}
            component={centerIcon}
            style={{
              zIndex: 2,
              position: 'absolute',
              width: 64,
              height: 64,
            }}
          />
          <div className={'blur-circle'} />
        </div>
        <Typography.Text className={'bold-24px-text'}>{text}</Typography.Text>
      </div>
    </>
  );
};

export default FullscreenRingWithTextAndIcon;
