import {Typography} from 'antd';
import {
    ACCOUNT_URL,
} from '../../packages/keycloak-client/constants';
import React from 'react';
import styled from 'styled-components';
import './spotTableLink.scss';
import {useGetScreenSize} from '../../shared/hooks/screen/use-get-screen-size';
import {Button} from '@pankod/refine-antd';
import {useTranslation} from 'react-i18next';
import {useAuthState} from '../../useAuthState';

const SpotTableLoginInner = () => {
    const {t} = useTranslation();
    const login = useAuthState((state) => state.login);
    const {width} = useGetScreenSize();
    const isMobileAdaptive = width <= 768;

    function openLoginScreen() {
        let y = 15;

        function calcX() {
            return width - 40;
        }

        let el = document.elementFromPoint(calcX(), y);
        if (!!el) {
            // @ts-ignore
            el?.click();
        }
    }

    if (!isMobileAdaptive) {
        return (
            <SpotTableLoginInnerWrapper>
                <Button
                    type="link"
                    className={'spot-table-link'}
                    onClick={() => login()}
                >
                    {t(`spotOrders.notAuthorized.signIn`)}
                </Button>
                <Typography.Text>{t(`spotOrders.notAuthorized.or`)}</Typography.Text>
                <Button
                    type="link"
                    className={'spot-table-link'}
                    href={`${ACCOUNT_URL}/register`}
                >
                    {t(`spotOrders.notAuthorized.singUp`)}
                </Button>
            </SpotTableLoginInnerWrapper>
        );
    } else {
        return (
            <SpotTableLoginInnerWrapper>
                <Button
                    type="link"
                    className={'spot-table-link_mob'}
                    onClick={() => openLoginScreen()}
                >
                    {t(`spotOrders.notAuthorized.signIn`)}
                </Button>
            </SpotTableLoginInnerWrapper>
        );
    }
};

const SpotTableLoginInnerWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
`;

export default SpotTableLoginInner;
