import React, { FC } from 'react';
import './styles.css';
import {
  FieldData,
  ICryptoPair,
  IExchangeContent,
} from '../../../../features/cryptocurrencyExchange/cryptoCurrencyExchange.p';
import CardPart from './CardPart';
import { getCoinInfoByName } from '../../../../shared/helpers/getCoinInfoByName';
import Divider from '../image/Divider.svg';
import Form from './Form';

interface Props {
  exchangeData: IExchangeContent;
  currentPair: ICryptoPair;
  email: string | undefined;
  transferData: FieldData[];
  onFormChange: (value: FieldData[]) => void;
  onChangeStart: () => void;
  isOrderCreateLoading: boolean;
  onBackStep:() => void;
}

const SecondStep: FC<Props> = ({
  exchangeData,
  currentPair,
  email,
  transferData,
  onChangeStart,
  onFormChange,
  isOrderCreateLoading,
  onBackStep
}) => {

  return (
    <div style={{ display: 'flex', gap: 24, flexDirection: 'column' }}>
      <div className={'card'}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <CardPart
            inputIconCoin={
              getCoinInfoByName(exchangeData.inputCoin.toLowerCase(), true)
                .icon as string
            }
            coinName={exchangeData.inputCoin}
            inputAmount={exchangeData.inputAmount}
          />
          <img
            alt={'divider'}
            src={Divider}
            style={{ width: 16, height: 16, transform: 'rotate(90deg)' }}
          />
          <CardPart
            rightPart={true}
            inputIconCoin={
              getCoinInfoByName(exchangeData.outputCoin.toLowerCase(), true)
                .icon as string
            }
            coinName={exchangeData.outputCoin}
            inputAmount={exchangeData.outputAmount}
          />
        </div>
      </div>
      <Form
        onBackStep={onBackStep}
        isOrderCreateLoading={isOrderCreateLoading}
        inputPrice={+exchangeData.inputAmount}
        currentPair={currentPair}
        inputCurrency={exchangeData.inputCoin}
        userData={email}
        formData={transferData}
        onChange={onFormChange}
        onBeginChange={onChangeStart}
      />
    </div>
  );
};

export default SecondStep;
