import React from 'react';
import { Steps, Typography } from '@pankod/refine-antd';
import { IStepper } from './stepper.p';
import { ReactComponent as StepperFinishedIcon } from '../assets/svg/stepperFinishedStep.svg'
import { ReactComponent as StepperProgressIcon } from '../assets/svg/stepperProgressIcon.svg'

const Index: React.FC<IStepper> = ({
  current,
  initial,
  labelPlacement,
  steps,
  ...props
}) => {
  const currentStepNumber = current ? current : 0;
  const initNumber = initial ? initial : 0;

  return (
    <Steps
      {...props}
      initial={initial}
      current={current}
      labelPlacement={labelPlacement}
      items={steps.map(
        (item, index) => {
          const stepNumber = initNumber + index;
          const isFirstOrLast =
            stepNumber === initial ||
            stepNumber === initNumber + steps.length - 1;
          const width =
            typeof item.width === 'number' ? `${item.width}px` : item.width;
          const marginLeft =
            typeof item.marginLeft === 'number'
              ? `${item.marginLeft}px`
              : item.marginLeft;
          return {
            description:
              <div
                style={{
                  marginTop: -10,
                  textAlign: isFirstOrLast ? 'left' : 'center',
                  marginLeft: marginLeft,
                  width: width,
                }}
              >
                <Typography.Text
                  style={{
                    fontSize: 14,
                    lineHeight: '120%',
                    color:
                      currentStepNumber < stepNumber ? '#767687' : '#FBFBFF',
                    fontWeight: 700,
                    marginTop: 8,
                    marginLeft: index === steps.length - 1 ? -70 : 0,
                    display: 'block',
                    textAlign:
                      stepNumber === initial
                        ? 'left'
                        : stepNumber === initNumber + steps.length - 1
                          ? 'right'
                          : 'center',
                  }}
                >
                  {item.stepUnderText}
                </Typography.Text>
              </div>
            ,
            icon: currentStepNumber == 4 || stepNumber < currentStepNumber
              ? <StepperFinishedIcon />
              : <>
                {stepNumber == currentStepNumber && <StepperProgressIcon className="progress-icon" />}
                <div className={`${currentStepNumber == stepNumber ? 'custom-stepper-icon' : ''} ${stepNumber <= currentStepNumber ? 'custom-stepper-icon-light' : 'custom-stepper-icon-dark'}`}>
                  {stepNumber}
                </div >
              </>
          }
        }
      )}
    />
  );
};
export default Index;
