import React, { Dispatch, useEffect, useState } from 'react';
import '../Card/styles.css';
import { Input } from 'antd';
import { PayService } from '../../../../../pages/p2p/settings/payment/payment';
import { useTranslation } from 'react-i18next';
import Comment from '../Comment';

interface Props {
  controlsTemplatePay: [PayService, Dispatch<PayService>];
  setIsValid: Dispatch<boolean>;
  clickSubmit: boolean;
  errorHasPayment: boolean;
}

const Cash = ({
  controlsTemplatePay: [templatePay, setTemplatePay],
  setIsValid,
  clickSubmit,
  errorHasPayment,
}: Props) => {
  const { t } = useTranslation();

  const [error, setError] = useState('num');
  const onChange = (value: string, key: keyof PayService) => {
    setTemplatePay({
      ...templatePay,
      [key]: value,
    });
  };

  useEffect(() => {
    setError(
      !templatePay.num ||
        (templatePay.num &&
          (templatePay.num?.length < 1 || templatePay.num.length > 400))
        ? 'num'
        : templatePay.comment && templatePay.comment.length > 40
        ? 'comment'
        : ''
    );
  }, [templatePay, clickSubmit]);

  useEffect(() => {
    setIsValid(error === '');
  }, [error]);

  return (
    <div className={'bank-container'}>
      <span className={'bank-title'}>{t('payments.cashes')}</span>
      {errorHasPayment && (
        <span style={{ marginTop: 4 }} className={'bank-input-label-error'}>
          {t('payments.errorAddPayment')}
        </span>
      )}
      <div className={'bank-form'}>
        <div className={'bank-container-input'}>
          <span className={'bank-input-label'}>{}</span>
          <Input.TextArea
            status={error === 'num' && clickSubmit ? 'error' : undefined}
            value={templatePay.num}
            onChange={(e) => onChange(e.target.value, 'num')}
            placeholder={t('payments.paymentDetails')!}
          />
          {error === 'num' && clickSubmit && (
            <span className={'bank-input-label-error'}>
              {t('payments.enterPaymentDetails')}
            </span>
          )}
        </div>
        <Comment
          templatePay={templatePay}
          error={error}
          clickSubmit={clickSubmit}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default Cash;
