import {Button, Upload as AntdUpload, UploadFile as AntdUploadFile, UploadProps,} from '@pankod/refine-antd';
import {CSSProperties, PropsWithChildren} from 'react';
import {UploadFile} from 'antd/es/upload/interface';

export interface UploadChangeParam<T = AntdUploadFile> {
    file: T;
    fileList: T[];
    event?: {
        percent: number;
    };
}

interface IUploadFile extends PropsWithChildren {
    onChange:
        | ((info: UploadChangeParam<AntdUploadFile<any>>) => void)
        | undefined;
    icon: JSX.Element;
    disabled?: boolean;
    onModal: boolean;
    handlePreview?: (file: UploadFile) => void;
}

const Upload = ({
                    icon,
                    onChange,
                    disabled,
                    onModal,
                    handlePreview,
                }: IUploadFile): JSX.Element => {

    const uploadFilesProps: UploadProps = {
        accept: '.png,.jpg,.pdf',
        fileList: [],
        name: 'file',
        headers: {
            authorization: `authorization-text`,
        },
        customRequest: () => undefined,
        listType: 'text',
        showUploadList: false,
        multiple: false,
        onChange,
    };

    if (handlePreview) {
        uploadFilesProps.onPreview = handlePreview;
    }

    const buttonCSSProperties: CSSProperties = onModal
        ? {
            background: 'rgba(65, 61, 80, 0.50)',
            width: 56,
            height: 56,
        }
        : {};

    return (
        <AntdUpload
            style={{padding: onModal ? '16px' : '0px'}}
            {...uploadFilesProps}
            disabled={disabled}
        >
            <Button
                style={buttonCSSProperties}
                type="text"
                icon={icon}
                size="large"
                disabled={disabled}
            />
        </AntdUpload>
    );
};

export {Upload};
