import React from 'react';
import {DatePicker} from '@pankod/refine-antd';
import Icon from '@ant-design/icons';
import {CaretIcon, DashCircle,} from '../../../../shared/components/ui/assets/svg/svgIcons';
import dayjs from 'dayjs';
import {RangePickerProps} from 'antd/es/date-picker';
import {LogicalFilter} from '@pankod/refine-core';
import {RangeValue} from '../cards/myTrades.p';

const dateFormat = 'YYYY/MM/DD';

interface IMyOrdersFilters {
    isLoading?: boolean;
    startDate: Date | null;
    endDate: Date | null;
    setFilter: (filter: LogicalFilter | null) => void;
    dates?: RangeValue;
    setDates: (arg: RangeValue) => void;
}

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days after today
    return current && current > dayjs().endOf('day');
};

const MyDealsDateFilter: React.FC<IMyOrdersFilters> = ({
                                                           setFilter,
                                                           dates,
                                                           setDates,
                                                       }) => {
    const pickerStyles = {
        minWidth: '290px',
        height: '40px',
    }

    const onChange = (val: RangeValue) => {
        setDates(val);
        if (val !== null) {
            setFilter({
                field: 'created_at',
                operator: 'between',
                value: val,
            });
        } else {
            setFilter(null);
        }
    }
    const getIcon = (
        el: (color?: string | undefined) => JSX.Element
    ) => <Icon component={() => el()}/>
    return (
        <>
            <DatePicker.RangePicker
                style={pickerStyles}
                className={'cursor-pointer'}
                format={dateFormat}
                suffixIcon={getIcon(CaretIcon)}
                clearIcon={getIcon(DashCircle)}
                separator={<>-</>}
                disabledDate={disabledDate}
                value={dates || undefined}
                onCalendarChange={(val) => {
                    onChange(val)
                }}
                onChange={(val) => {
                    onChange(val)
                }}
            />
        </>
    );
};

export default MyDealsDateFilter;
