import React, {FC} from 'react';
import {AdTypesEnum, IFiat} from '../../types';
import {ICryptoCurrencyConstraintItem} from '../../../../../widgets/order/types';
import {Icon, Tag, Typography} from '@pankod/refine-antd';
import {ReactComponent as ArrowForwardIcon} from '../../../../../shared/components/ui/assets/svg/arrowForward.svg';
import CustomSelect from '../../../../../shared/components/ui/select';
import {AccountBalanceWallet, CashIcon,} from '../../../../../shared/components/icons';
import {getMoneyIcon} from '../../../../../shared/helpers/getMoneyIcon';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

interface IChooseAdTypeProps {
  adType: AdTypesEnum;

  handleChangeAdType(type: AdTypesEnum): void;

  fiatList?: Array<IFiat>;
  currencyList?: Array<ICryptoCurrencyConstraintItem>;
  chosenCurrency?: ICryptoCurrencyConstraintItem;
  chosenFiat?: IFiat;

  handleChangeCurrency(currency?: ICryptoCurrencyConstraintItem): void;

  handleChangeFiat(fiat?: IFiat): void;

  isCurrencyListLoading: boolean;
  isFiatListLoading: boolean;
  activePrice: number;

  handleChangeAdCurrencyPrice(value: number): void;

  adCurrencyPrice: number;
  isNextBtnClick?: boolean;
  existingAds?: string[];
}

const SellVariant: FC<IChooseAdTypeProps> = ({
                                               fiatList,
                                               currencyList,
                                               handleChangeCurrency,
                                               isCurrencyListLoading,
                                               chosenCurrency,
                                               isFiatListLoading,
                                               handleChangeFiat,
                                               chosenFiat,
                                               isNextBtnClick,
                                             }) => {
  const {t} = useTranslation();

  return (
    <div className="choose-p2p-ad-type-dropdown-list">
      <div className="choose-p2p-ad-type-dropdown-list-item">
        <Typography.Text>{t('p2p.chooseAdType.active')}</Typography.Text>
        <CustomSelect
          status={isNextBtnClick === false || chosenCurrency ? '' : 'error'}
          key={'select-sell-active'}
          prefixIcon={
            chosenCurrency?.currency === undefined && (
              <ContainerCashIconStyled>
                <CashIcon color={'#C3C3CE'}/>
              </ContainerCashIconStyled>
            )
          }
          value={chosenCurrency?.id}
          showSearch={true}
          setValue={(value) => {
            currencyList &&
            handleChangeCurrency(
              currencyList.find((item) => item.id === +value)
            );
          }}
          data={
            isCurrencyListLoading || !currencyList
              ? []
              : currencyList.map((item) => ({
                label: (
                  <LabelStyled>
                    <Icon
                      style={{marginRight: '8px'}}
                      component={getMoneyIcon({
                        name: item.currency,
                        size: 16,
                      })}
                    />
                    {item.currency}
                  </LabelStyled>
                ),
                value: item.id,
                valueC: item.currency,
              }))
          }
          placeholder={
            chosenCurrency ? (
              chosenCurrency.currency
            ) : (
              <ContainerChooseActive>
                {t('p2p.chooseAdType.chooseActive').toString()}
              </ContainerChooseActive>
            )
          }
        />

        <div className="choose-p2p-ad-type-tag-list-wrapper">
          {currencyList &&
            currencyList.slice(0, 3).map((listItem) => (
              <TagStyled
                key={listItem.id}
                onClick={() => handleChangeCurrency(listItem)}
                backgroundColor={
                  chosenCurrency?.id === listItem.id ? '#767687' : '#2B2B36'
                }
                className={'cursor-pointer'}
              >
                {listItem.currency}
              </TagStyled>
            ))}
        </div>
      </div>
      <div className="choose-p2p-ad-type-dropdown-list-item-icon">
        <ArrowForwardIcon style={{width: 16}}/>
      </div>

      <div className="choose-p2p-ad-type-dropdown-list-item">
        <Typography.Text>{t('p2p.chooseAdType.fiat')}</Typography.Text>
        <CustomSelect
          status={isNextBtnClick === false || chosenFiat ? '' : 'error'}
          key={'select-sell-fiat'}
          value={chosenFiat?.id}
          showSearch={true}
          setValue={(value) => {
            fiatList &&
            handleChangeFiat(fiatList.find((item) => item.id === +value));
          }}
          prefixIcon={
            chosenFiat?.currency === undefined && (
              <ContainerCashIconStyled>
                <AccountBalanceWallet color={'#C3C3CE'}/>
              </ContainerCashIconStyled>
            )
          }
          data={
            isFiatListLoading || !fiatList
              ? []
              : fiatList.map((item) => ({
                label: (
                  <LabelStyled>
                    <Icon
                      style={{marginRight: '8px'}}
                      component={getMoneyIcon({
                        name: item.currency,
                        size: 16,
                      })}
                    />
                    {item.currency}
                  </LabelStyled>
                ),
                value: item.id,
                valueC: item.currency,
              }))
          }
          placeholder={
            chosenFiat ? (
              chosenFiat.currency
            ) : (
              <ContainerChooseActive>
                {t('p2p.chooseAdType.chooseFiat').toString()}
              </ContainerChooseActive>
            )
          }
        />

        <div className="choose-p2p-ad-type-tag-list-wrapper">
          {fiatList &&
            fiatList.slice(0, 3).map((listItem) => (
              <TagStyled
                key={listItem.id}
                onClick={() => handleChangeFiat(listItem)}
                backgroundColor={
                  listItem.id === chosenFiat?.id ? '#767687' : '#2B2B36'
                }
                className={'cursor-pointer'}
              >
                {listItem.currency}
              </TagStyled>
            ))}
        </div>
      </div>
    </div>
  );
};

const ContainerCashIconStyled = styled.div`
  margin: 0 8px 0 16px;
`;

const ContainerChooseActive = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TagStyled = styled(Tag)<{ backgroundColor: string }>`
  background-color: ${({backgroundColor}) => backgroundColor};
  color: white;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 12px;
`;

const LabelStyled = styled.div`
  display: flex;
  align-items: center;
`;

export default SellVariant;
