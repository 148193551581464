import React, {FC, useEffect, useMemo} from 'react';
import NormalDropdown from '../../../ui/dropdown/normalDropdown';
import {CommonProfileControlIcon, CommonSettingsIcon, LogoutIcon, P2pOrdersIcon,} from '../../../icons';
import {useTranslate} from '@pankod/refine-core';
import DropdownRowWithHoverIcon from '../../../ui/dropdown/dropdownRowWithHoverIcon';
import styled, {css} from 'styled-components';
import {ACCOUNT_URL} from '../../../../../packages/keycloak-client/constants';
import {useAuthState} from '../../../../../useAuthState';
import {useCookiesCustom} from '../../../../hooks/useCookieCustom';

const UserDropdown: FC = (): JSX.Element => {
    const t = useTranslate();
    const userAPI = useAuthState((state) => state.userAPI);
    const getUserProfileFromAPI = useAuthState((state) => state.getUserProfileFromAPI);
    const logout = useAuthState((state) => state.logout);
    const {cookie} = useCookiesCustom();
    const username = userAPI?.email || cookie['email'];
    const status = userAPI?.status;

    const avatarText = useMemo(() => {
        if (!!username?.length) {
            return username?.toUpperCase()?.slice(0, 2)
        } else return '';
    }, [username])

    const secureEmail = useMemo(() => {
        if (!!username?.length && username?.indexOf('@') > -1) {
            const splitEmail = username.split('@');
            return splitEmail[0]?.substring(0, 2) + '***@' + splitEmail[1];
        } else return '';
    }, [username])

    const userStatus = useMemo(() => {
        if (status === 'active') return 'header.userMenu.active';
        else if (status === 'banned') return 'header.userMenu.banned';
        else return 'header.userMenu.moratorium';
    }, [status])

    useEffect(() => {
        if (!!cookie['token']) {
            getUserProfileFromAPI().then()
        }
    }, [])

    return (
        <>
            <NormalDropdown
                isSelectable={false}
                itemWidth={236}
                items={[
                    // DropdownRowWithHoverIcon({
                    //   key: 'wallet',
                    //   isOutside: false,
                    //   // onClickAction: () => {window.location.href = ACCOUNT_URL_REDIRECT + "/coin-accounts"},
                    //   link: () => {window.location.href = ACCOUNT_URL_REDIRECT + "/coin-accounts"},
                    //   // link: '/coin-accounts',
                    //   iconComp: () =>
                    //     WalletIcon(
                    //       status !== 'banned' ? '#FAFAFC' : 'rgba(71, 71, 85, 1)'
                    //     ),
                    //   text: t('header.userMenu.wallet'),
                    //   disabled: status === 'banned',
                    // }),
                    DropdownRowWithHoverIcon({
                        key: 'orders',
                        isOutside: false,
                        link: '/p2p/my-deals',
                        iconComp: () =>
                            P2pOrdersIcon(
                                status !== 'banned' ? '#FAFAFC' : 'rgba(71, 71, 85, 1)'
                            ),
                        text: t('header.userMenu.orders'),
                        disabled: status === 'banned',
                    }),
                    DropdownRowWithHoverIcon({
                      key: 'settings',
                      isOutside: false,
                      link: '/p2p/settings',
                      iconComp: () =>
                        CommonSettingsIcon(
                          status !== 'banned' ? '#FAFAFC' : 'rgba(71, 71, 85, 1)'
                        ),
                      text: t('header.userMenu.settings'),
                      disabled: userAPI?.status === 'banned',
                    }),
                    DropdownRowWithHoverIcon({
                        key: 'profile',
                        isOutside: true,
                        link: `${ACCOUNT_URL}/server/account/settings?fromMenu=true`,
                        iconComp: () =>
                            CommonProfileControlIcon(
                                status !== 'banned' ? '#FAFAFC' : 'rgba(71, 71, 85, 1)'
                            ),
                        text: t('header.userMenu.profile'),
                        disabled: status === 'banned',
                    }),
                    DropdownRowWithHoverIcon({
                        key: 'exit',
                        isOutside: false,
                        link: '',
                        onClickAction: () => {
                            sessionStorage.removeItem('offer-object');
                            logout();
                        },
                        iconComp: () => LogoutIcon('#FAFAFC'),
                        text: t('header.userMenu.logout'),
                    }),
                ]}
                content={
                    <UserInfoWrapperStyled>
                        <UserInfoContainerStyled>
                            <EmailStyled>{secureEmail}</EmailStyled>
                            <StatusStyled $mode={status}>{t(userStatus)}</StatusStyled>
                        </UserInfoContainerStyled>
                        <AvatarStyled $mode={status}>{avatarText}</AvatarStyled>
                    </UserInfoWrapperStyled>
                }
            />
        </>
    );
};

export {UserDropdown};

const UserInfoContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
`;

const AvatarStyled = styled.div<{ $mode?: string }>`
    font-size: 10px;
    font-weight: 700;
    height: 32px;
    width: 32px;
    background: #9499a3;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: #ffffff;

    ${(props) =>
            props.$mode === 'active'
                    ? css`
                        background: #703df2;
                    `
                    : props.$mode === 'moratorium'
                            ? css`
                                background: #e8e264;
                                color: #14141f;
                            `
                            : css`
                                background: rgba(241, 96, 99, 1);
                                color: #14141f;
                            `}
`;

const StatusStyled = styled.div<{ $mode?: string }>`
    font-size: 10px;
    line-height: 12px;
    color: #767687;

  ${(props) =>
    props.$mode === 'active'
      ? css`
        color: #58bf92;
      `
      : props.$mode === 'moratorium'
        ? css`
          color: #e8e264;
        `
        : css`
          color: rgba(241, 96, 99, 1);
        `}
}
`;

const EmailStyled = styled.div`
    font-size: 12px;
    font-style: normal;
    line-height: 15px;
    font-weight: 500;
    color: #fbfbff;
`;

const UserInfoWrapperStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
`;
