import { Form, FormInstance, Input, Typography } from "@pankod/refine-antd"
import { useTranslate } from "@pankod/refine-core"
import { ReactNode } from "react"
import styled from "styled-components"

type TCard = {
    watch: any, 
    initValue: string, 
    handleChange: (val: string) => void,
    placeholder: string,
    extra: ReactNode,
    title: string,
    name: string
}

export const AdditionalParamsInputCard = ({watch, initValue, handleChange, placeholder, extra, title, name}: TCard) => {
    const t = useTranslate();
    return (
        <InputCard>
            <Row>
                <BoldText>
                    {title}
                </BoldText>
                <Typography>
                    {watch ? watch.length : 0}
                    /1000
                </Typography>
            </Row>
            <Row>
                <Form.Item
                    initialValue={initValue}
                    shouldUpdate
                    name={name}
                    extra={extra}
                    style={{ width: '100%' }}
                >
                    <Input.TextArea
                        autoSize={{ minRows: 3 }}
                        maxLength={1000}
                        placeholder={placeholder.toString()}
                        style={{ maxHeight: '100%' }}
                        onChange={e => handleChange(e.target.value)}
                    />
                </Form.Item>
            </Row>
        </InputCard>
    )
}
const InputCard = styled.div`
    display: flex;
    width: 100%;
    border-radius: 16px;
    background: #1C1C27;
    height: 464px;
    padding: 24px;
    gap: 24px;
    flex-direction: column;
`
const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`
const LightText = styled(Typography.Text)`
    font-weight: 400;
    font-size: 12px;
`
const BoldText = styled(Typography)`
    font-weight: 700;
    font-size: 24px;
`