import React from 'react';
import { NavLink } from '@pankod/refine-react-router-v6';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { IGetListData } from '../../../../../pages/p2p/myDeals/cards/myTrades.p';
import styled from 'styled-components';

interface IProps {
  currentOrder: IGetListData;
}

const CancelNotificationDescription: React.FC<IProps> = ({ currentOrder }) => {
  const { t } = useTranslation();

  return (
    <>
      <CancelNotificationDescriptionBodyTitleStyled>
        <div>
          {t('p2p.orderDeals.deal')}{' '}
          <Link to={`/p2p/order/${currentOrder.id}`}>
            <TypographyStyled>№{currentOrder.id}</TypographyStyled>
          </Link>{' '}
          <span>{t('p2p.orderDeals.dealToBuy')}</span>{' '}
          <span>
            {currentOrder.ccy} {t('p2p.orderDeals.dealFor')} {currentOrder.fiat}{' '}
            {t('p2p.orderDeals.dealWasCanceled')}
          </span>
        </div>
      </CancelNotificationDescriptionBodyTitleStyled>

      <Typography.Text>{t('p2p.orderDeals.notificationInfo')}</Typography.Text>
    </>
  );
};

export default CancelNotificationDescription;

const CancelNotificationDescriptionBodyTitleStyled = styled.div`
  margin-bottom: 8px;
`;

const TypographyStyled = styled(Typography.Text)`
  color: #5297ff;
`;

const Link = styled(NavLink)`
  border-bottom: 1px solid #5297ff;
  cursor: pointer;
`;
