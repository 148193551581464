import {FC, PropsWithChildren, useState} from 'react'
import {Button as IconButton, Popover} from '@pankod/refine-antd'
import {Typography} from 'antd'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import Button from '../../../shared/components/ui/button'
import {ButtonsTypes} from '../../../shared/components/ui/button/filledButton.p'
import {CancelIcon} from "../../../shared/components/icons";

interface IProps extends PropsWithChildren {
  onDealCancel: () => void
  isDisabled?: boolean
}

const CancelDealPopover: FC<IProps> = ({children, onDealCancel, isDisabled = false}) => {
  const {t} = useTranslation()

  const [open, setOpen] = useState(false)

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    if (!isDisabled) {
      setOpen(newOpen);
    }
  };

  const handleCancel = () => {
    onDealCancel()
    hide()
  }

  return <Popover
    trigger="click"
    overlayInnerStyle={{
      minWidth: "360px"
    }}
    placement="topRight"
    arrow={{pointAtCenter: true}}
    content={
      <>
        <CancelDealTextBlock>
          <ExclamationCircleOutlinedStyled/>
          <CancelDealText>
            {t('p2p.orderDeals.popconfirmInfo')}
          </CancelDealText>
          <IconButtonStyled
            icon={CancelIcon(16, '#C3C3CE')}
            onClick={hide}
            type='text'
          />
        </CancelDealTextBlock>
        <CancelDealButtonsBlock>
          <ConfirmButtonLeft
            onClick={hide}
            type={ButtonsTypes.outlined}
            style={{minWidth: '80px'}}
          >
            {t('payments.popConfirm.No')}
          </ConfirmButtonLeft>
          <ConfirmButton onClick={handleCancel}>
            {t('payments.popConfirm.Yes')}
          </ConfirmButton>
        </CancelDealButtonsBlock>
      </>
    }
    open={open}
    onOpenChange={handleOpenChange}
  >
    {children}
  </Popover>
}

export default CancelDealPopover;

const CancelDealTextBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const CancelDealButtonsBlock = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 16px;
  height: 32px;
`;

const CancelDealText = styled(Typography.Text)`
  width: 264px;
  color: #FBFBFF;
  font-size: 14px;
`;

const ExclamationCircleOutlinedStyled = styled(ExclamationCircleOutlined)`
  color: #E8E264;
  padding: 6;
`;

const IconButtonStyled = styled(IconButton)`
  height: 16px;
  width: 16px !important;
  padding: 6px;

  &:not(:disabled):hover {
    background-color: transparent;
  }
`;

const ConfirmButtonLeft = styled(Button)`
  min-width: 80px !important;
  width: 80px;
  height: 32px;
`;

const ConfirmButton = styled(Button)`
  min-width: 80px;
  width: 80px !important;
  height: 32px !important;
`;
