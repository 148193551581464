import {styled} from 'styled-components';
import {SpotChart} from 'widgets/spot/chart';
import {SpotOrderBook} from 'widgets/spot/orderBook';
import {SpotOrdersTable} from 'widgets/spot/ordersTable';
import {SpotPairInfo} from 'widgets/spot/pairInfo';
import {SpotPairSelector} from 'widgets/spot/pairSelector';

export const DashboardDesktop = () => {
    return (
        <DashboardContainer>
            <div style={{height: 70}}>
                <SpotPairInfo/>
            </div>
            <div>
                <OutlinedContainer className="outer">
                    <div className={'flex-row'} style={{height: 667}}>
                        <OutlinedContainer
                            style={{minWidth: 386, padding: '0 16px 16px'}}
                        >
                            <SpotPairSelector/>
                        </OutlinedContainer>
                        <OutlinedContainer style={{minWidth: 326}}>
                            <SpotOrderBook/>
                        </OutlinedContainer>
                        <div style={{width: '100%', height: '100%'}}>
                            <SpotChart/>
                        </div>
                    </div>
                    <OutlinedContainer style={{height: 295, overflow: 'hidden'}}>
                        <SpotOrdersTable/>
                    </OutlinedContainer>
                </OutlinedContainer>
            </div>
        </DashboardContainer>
    );
};

const DashboardContainer = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
  @media (min-width: 768px) {
    display: block;
    .desktop-display-none {
      display: none;
    }
  }
`;

export const OutlinedContainer = styled.div`
  border-right: 1px solid rgba(65, 61, 80, 0.5);
  &.outer {
    border: 1px solid rgba(65, 61, 80, 0.5);
  }
`;
