import {IUseGetPaymentInfoIconData} from "../../../../shared/hooks/use-get-payment-info-icon-data";
import React from "react";
import {useTranslation} from "react-i18next";
import {Typography} from "antd";
import styled from "styled-components";
import {Icons, Tooltip} from "@pankod/refine-antd";

const {CopyOutlined} = Icons;

interface IMobileInfo {
  paymentData: IUseGetPaymentInfoIconData | undefined;
  hideTransferData?: boolean;
  isDealSuccess?: boolean;
  isCopy: boolean;
}

const MobileInfo: React.FC<IMobileInfo> = ({
                                             paymentData,
                                             hideTransferData,
                                             isDealSuccess,
                                             isCopy,
                                           }) => {
  const {t} = useTranslation();

  async function copyText(textToCopy: string) {
    try {
      if (navigator?.clipboard?.writeText) {
        await navigator.clipboard.writeText(textToCopy);
      }
    } catch (err) {
      console.error('Failed to copy!', err);
    }
  }

  return (
    <>
      <CardRow>
        <InfoBlockFirst>
          <BlockDescription>
            {paymentData?.icon}
            <Typography.Text className={'middle-bold-14px-text'}>
              {paymentData?.phone}
            </Typography.Text>
            {
              isCopy && (
                (hideTransferData || isDealSuccess)
                  ? <CopyOutlinedStyled/>
                  : <Tooltip
                    trigger={'click'}
                    placement="top"
                    title={t(
                      'p2p.transferInfo.copied'
                    )}
                    color="#2B2B36"
                    overlayInnerStyle={{color: '#FBFBFF', width: '124px'}}
                  >
                    <CopyBtn onClick={() => copyText(paymentData?.phone ?? '')}>
                      <CopyOutlinedStyled/>
                    </CopyBtn>
                  </Tooltip>
              )
            }
          </BlockDescription>

          <BlockTitle>
            <Typography.Text className={'light-grey-12px-text'}>
              {t('p2p.transferInfo.phoneNumber')}
            </Typography.Text>
          </BlockTitle>
        </InfoBlockFirst>
      </CardRow>
    </>
  );
}
export default MobileInfo;

const CardRow = styled.div`
  display: flex;
  gap: 32px;
`;

const InfoBlockFirst = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  height: 36px;
  width: 435px;
`;

const BlockTitle = styled.div``;

const BlockDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CopyBtn = styled.button`
  width: 16px;
  height: 16px;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const CopyOutlinedStyled = styled(CopyOutlined)`
  font-size: 16px;
  color: #FBFBFF;
`;
