import React from 'react';
import {
    FieldData,
    ICryptoPair,
    IExchangeContent,
} from '../../features/cryptocurrencyExchange/cryptoCurrencyExchange.p';
import {ICryptoCurrencyConstraintItem} from '../order/types';
import ExchangeCardsContainer from '../../features/cryptocurrencyExchange/exchangeCardsContainer';
import ExchangeFormContainer from '../../features/cryptocurrencyExchange/exchangeFormContainer';

interface IExchangeContentComponent {
    changePrice: (input: string, output: string, type: 'send' | 'give') => void;
    currentPair: ICryptoPair;
    exchangeData: IExchangeContent;
    email: string | undefined;
    transferData: FieldData[];
    minimumExchangeAmount: number;
    onFormChange: (value: FieldData[]) => void;
    onChangeStart: () => void;
    onClickInputCoins: () => void;
    onClickOutputCoins: () => void;
    onReverseClick: () => void;
    isOrderCreateLoading: boolean;
    commission: string;
    listCurrencies?: ICryptoCurrencyConstraintItem[];
    pairData: ICryptoPair[];
}

const ExchangeContent: React.FC<IExchangeContentComponent> = ({
                                                                  changePrice,
                                                                  currentPair,
                                                                  exchangeData,
                                                                  email,
                                                                  transferData,
                                                                  minimumExchangeAmount,
                                                                  onFormChange,
                                                                  onChangeStart,
                                                                  onClickInputCoins,
                                                                  onClickOutputCoins,
                                                                  onReverseClick,
                                                                  isOrderCreateLoading,
                                                                  commission,
                                                                  listCurrencies,
                                                                  pairData,
                                                              }) => {
    return (
        <>
            <div className={'exchange-content-container'}>
                <ExchangeCardsContainer
                    minimumExchangeAmount={minimumExchangeAmount}
                    onReverseClick={onReverseClick}
                    changePrice={changePrice}
                    currentPair={currentPair}
                    inputCoin={exchangeData.inputCoin}
                    outputCoin={exchangeData.outputCoin}
                    inputAmount={exchangeData?.inputAmount}
                    outputAmount={exchangeData?.outputAmount}
                    onClickInputCoins={onClickInputCoins}
                    onClickOutputCoins={onClickOutputCoins}
                    listCurrencies={listCurrencies}
                    pairData={pairData}
                />
                <ExchangeFormContainer
                    isOrderCreateLoading={isOrderCreateLoading}
                    inputPrice={+exchangeData.inputAmount}
                    currentPair={currentPair}
                    inputCurrency={exchangeData.inputCoin}
                    userData={email}
                    formData={transferData}
                    onChange={onFormChange}
                    onBeginChange={onChangeStart}
                    commission={commission}
                />
            </div>
        </>
    );
};

export default ExchangeContent;
