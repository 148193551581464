import {message, Typography, Upload, UploadFile, UploadProps} from '@pankod/refine-antd';
import Styles from './index.module.css';
import {PlusIcon} from 'shared/components/ui/assets/svg/plusIcon';
import {useTranslation} from "react-i18next";

interface IUploadFilesAppealProps {
    value?: UploadFile[];
    onChange?: (value: UploadFile[]) => void;
    fileList: UploadFile[] | undefined;
    setFileList: (value: UploadFile[]) => void;
}

const UploadFilesAppeal = (props: IUploadFilesAppealProps): JSX.Element => {
    const {fileList, setFileList} = props;
    const {t} = useTranslation();

    const handleChange: UploadProps['onChange'] = ({fileList: newFileList}) => {
        setFileList(newFileList);
        if (props.onChange) props.onChange(newFileList);
    };

    const uploadButton = (
        <div>
            <PlusIcon/>
            <div style={{marginTop: 8}}>
                <Typography.Text>{t('Загрузить')}</Typography.Text>
            </div>
        </div>
    );

    // Поддерживаемые MIME-типы файлов
    const imgTypes = ['image/png', 'image/jpeg'];

    return (
        <div className={Styles.files_container}>
            <div className={Styles.files_container__header}>
                <Typography.Text>{t('Загрузите доказательства')}</Typography.Text>
                <Typography.Text>
                    {fileList ? fileList.length : 0}/
                    <span className={Styles.files_container__max}>5</span>
                </Typography.Text>
            </div>
            <Typography.Text className={Styles.files_container__description}>
                {t('Общий размер файлов не должен превышать 50 Мб')}
                {t('Поддерживаемые форматы: .png, .jpg')}
            </Typography.Text>
            <Upload
                listType="picture-card"
                onChange={handleChange}
                className={Styles.files_upload}
                fileList={fileList}
                beforeUpload={(file) => {
                    // Проверяем тип файла по MIME-типу
                    if (!imgTypes.includes(file.type)) {
                        message.error(`${file.name} is not a supported format!`).then();
                        return Upload.LIST_IGNORE;  // Отменить загрузку
                    } else {
                        if (fileList) {
                            setFileList([...fileList, file]);
                        }
                        return false;  // Отменить автоматическую загрузку, так как мы управляем вручную
                    }
                }}
                onRemove={(file) => {
                    if (fileList) {
                        const index = fileList.indexOf(file);
                        const newFileList = fileList.slice();
                        newFileList.splice(index, 1);
                        setFileList(newFileList);
                    }
                }}
            >
                {fileList ? (fileList.length >= 5 ? null : uploadButton) : uploadButton}
            </Upload>
        </div>
    );
};

export {UploadFilesAppeal};
