import React, { useEffect, useState } from 'react';
import { ISpotMarkerDataList } from '../../../shared/interfaces/spot';
import BackgroundProgress from '../../../shared/components/ui/progressBar/backgroundProgress';
import { Typography } from 'antd';
import { Tooltip } from '@pankod/refine-antd';
import Icon from '@ant-design/icons';
import { ArrowBold } from '../../../shared/components/icons';
import { useTranslation } from 'react-i18next';
import fb from '../../../packages/fb/fb';

const SpotMarkerDataList: React.FC<ISpotMarkerDataList> = ({
  dataList,
  progressColor,
  priceColor,
  amountCurrency,
  priceCurrency,
  isReverse,
}) => {
  const { t } = useTranslation();
  const [round, setRound] = useState(8);

  const [currentRowHovered, setCurrentRowHovered] = useState(-1);

  const [tooltipData, setTooltipData] = useState({
    price: 0,
    amountInCurrency: 0,
    amountAll: 0,
  });

  function setTooltip() {
    const arr = [];
    let commonPrice = 0;
    const obj = {
      price: 0,
      amountInCurrency: 0,
      amountAll: 0,
    };
    if (currentRowHovered !== -1 && !!dataList?.length) {
      if (isReverse) {
        for (let i = 0; i <= currentRowHovered; i += 1) {
          arr.push(dataList[i]);
        }
      } else {
        for (let i = dataList.length - 1; i >= currentRowHovered; i -= 1) {
          arr.push(dataList[i]);
        }
      }
    }
    for (const item of arr) {
      commonPrice += +item.price;
      obj.price = commonPrice / arr?.length;
      obj.amountInCurrency += +item.amountInCurrency;
      obj.amountAll += +item.amountAll;
    }
    setTooltipData(obj);
  }

  useEffect(() => {
    const changeOrderBookRoundListener = fb.addListener(
      'changeOrderBookRound',
      (round: number) => {
        setRound(round);
      }
    );

    return () => {
      changeOrderBookRoundListener.remove();
    };
  }, []);

  useEffect(() => {
    setTooltip();
  }, [dataList, currentRowHovered]);

  return (
    <div>
      {!!dataList?.length &&
        dataList.map((item, index) => {
          return (
            <Tooltip
              key={index}
              placement={'right'}
              style={{ minWidth: '330px' }}
              title={
                <div style={{ width: '100%' }}>
                  <div className={'flex-row gap-10 align-items-center'}>
                    <Typography.Text style={{ minWidth: 95 }}>
                      {t('spot.markerDataList.averagePrice')}
                    </Typography.Text>
                    <Icon component={() => <ArrowBold size={12} />} />
                    <Typography.Text
                      className={'bold-14px-text'}
                    >{`${tooltipData?.price?.toFixed(
                      round
                    )} ${priceCurrency}`}</Typography.Text>
                  </div>
                  <div className={'flex-row gap-10 align-items-center'}>
                    <Typography.Text style={{ minWidth: 95 }}>{`${t(
                      'spot.markerDataList.sum'
                    )} ${amountCurrency}`}</Typography.Text>
                    <Icon component={() => <ArrowBold size={12} />} />
                    <Typography.Text
                      className={'bold-14px-text'}
                    >{`${tooltipData?.amountInCurrency?.toFixed(
                      round
                    )} ${amountCurrency}`}</Typography.Text>
                  </div>
                  <div className={'flex-row gap-10 align-items-center'}>
                    <Typography.Text style={{ minWidth: 95 }}>{`${t(
                      'spot.markerDataList.sum'
                    )} ${priceCurrency}`}</Typography.Text>
                    <Icon component={() => <ArrowBold size={12} />} />
                    <Typography.Text
                      className={'bold-14px-text'}
                      style={{
                        minWidth: '200px',
                      }}
                    >{`${tooltipData?.amountAll?.toFixed(
                      round
                    )} ${priceCurrency}`}</Typography.Text>
                  </div>
                </div>
              }
            >
              <div
                key={index}
                onMouseEnter={() => {
                  setCurrentRowHovered(index);
                }}
                onMouseLeave={() => {
                  setCurrentRowHovered(-1);
                }}
                style={{
                  cursor: 'pointer',
                  borderTop: isReverse
                    ? 'none'
                    : index === currentRowHovered
                    ? '1px dashed #474755'
                    : 'none',
                  borderBottom: isReverse
                    ? index === currentRowHovered
                      ? '1px dashed #474755'
                      : 'none'
                    : 'none',
                  backgroundColor: isReverse
                    ? index <= currentRowHovered
                      ? 'rgba(255, 255, 255, 0.05)'
                      : 'initial'
                    : index >= currentRowHovered && currentRowHovered !== -1
                    ? 'rgba(255, 255, 255, 0.05)'
                    : 'initial',
                  marginRight: -16,
                  marginLeft: -16,
                }}
              >
                <BackgroundProgress
                  bgcolor={progressColor}
                  percent={item?.percent || 0}
                  styleContainer={{
                    height: 24,
                  }}
                  styleChildrenContainer={{
                    paddingLeft: 16,
                    paddingRight: 16,
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'grid',
                      gap: 10,
                      gridTemplateColumns: '1fr 1fr 1fr',
                    }}
                  >
                    <Typography.Text
                      className={'light-bold-12px-text'}
                      style={{
                        color: priceColor,
                        textAlign: 'left',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {!!item?.price ? (+item?.price)?.toFixed(round) : 0}
                    </Typography.Text>
                    <Typography.Text
                      className={'light-bold-12px-text'}
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {!!item?.amountInCurrency
                        ? (+item?.amountInCurrency)?.toFixed(round)
                        : 0}
                    </Typography.Text>
                    <Typography.Text
                      className={'light-bold-12px-text mob-display-none'}
                      style={{
                        textAlign: 'right',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {!!item?.amountAll
                        ? (+item?.amountAll)?.toFixed(round)
                        : 0}
                    </Typography.Text>
                  </div>
                </BackgroundProgress>
              </div>
            </Tooltip>
          );
        })}
    </div>
  );
};

export { SpotMarkerDataList };
