export const CaretIcon = (color?: string) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.24873 11.1399L2.45249 5.6585C1.88673 5.01192 2.34591 4 3.20507 4H12.7975C13.6567 4 14.1159 5.01192 13.5501 5.6585L8.75388 11.1399C8.35547 11.5952 7.64714 11.5952 7.24873 11.1399Z"
      fill={color ? color : '#767687'}
    />
  </svg>
);

export const GradientColorCaret = (
  gradientStartColor?: string,
  gradientEndColor?: string
) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.24873 11.1399L2.45249 5.6585C1.88673 5.01192 2.34591 4 3.20507 4H12.7975C13.6567 4 14.1159 5.01192 13.5501 5.6585L8.75388 11.1399C8.35547 11.5952 7.64714 11.5952 7.24873 11.1399Z"
      fill="url(#paint0_linear_549_47121)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_549_47121"
        x1="2.20313"
        y1="11.4814"
        x2="14.8755"
        y2="8.33862"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor={gradientStartColor ? gradientStartColor : '#088473'} />
        <stop
          offset="1"
          stopColor={gradientEndColor ? gradientEndColor : '#12BFA8'}
        />
      </linearGradient>
    </defs>
  </svg>
);

export const DividerIcon = () => (
  <svg
    width="16"
    height="2"
    viewBox="0 0 16 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="1"
      y1="1"
      x2="15"
      y2="1"
      stroke="#767687"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const DashCircle = (color?: string) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#FBFBFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 8C4 7.72386 4.22386 7.5 4.5 7.5H11.5C11.7761 7.5 12 7.72386 12 8C12 8.27614 11.7761 8.5 11.5 8.5H4.5C4.22386 8.5 4 8.27614 4 8Z"
      fill={color ? color : '#FBFBFF'}
    />
  </svg>
);
