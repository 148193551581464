import React from 'react';
import { Typography } from '@pankod/refine-antd';
import { BasicModal } from './basicModal';
import { useTranslation } from 'react-i18next';

/* extends BasicModalProps{

}*/

interface IConfirmModal {
  isShow: boolean;
  setIsShow: (isShow: boolean) => void;
  email: string;
  outputPrice: string;
  outputCurrency: string;
  outputWallet: string;
}

const ConfirmWithdrawModal: React.FC<IConfirmModal> = ({
  isShow,
  setIsShow,
  email,
  outputCurrency,
  outputPrice,
  outputWallet,
}) => {
  const { t } = useTranslation();

  const splitEmail = email?.split('@');
  return (
    <BasicModal
      title={t('wallet.coin.withdraw.confirmWithdraw')}
      open={isShow}
      onCancel={() => setIsShow(false)}
      width={657}
      footer={null}
    >
      <>
        <div className={'flex-column gap-24'}>
          <Typography className={'text-14px'} style={{ lineHeight: '17px' }}>
            {t('wallet.coin.withdraw.onEmail')}{' '}
            <span className={'bold-14px-text'}>
              {!!splitEmail?.length &&
                splitEmail[0]?.substring(0, 2) + '***@' + splitEmail[1]}
            </span>{' '}
            {t('wallet.coin.withdraw.linkSent')}{' '}
            <span className={'bold-14px-text'}>
              {t('wallet.coin.withdraw.inOneHour')}
            </span>{' '}
            {t('wallet.coin.withdraw.forConfirmLogin')}
          </Typography>
          <div className={'flex-column gap-8'}>
            <div className={'flex-row gap-36'}>
              <Typography.Text
                className={'text-14px'}
                style={{ fontWeight: 500, color: '#6B6E75', width: 104 }}
              >
                {t('wallet.coin.withdraw.inputs.labels.sum')}
              </Typography.Text>
              <Typography.Text
                className={'text-14px'}
                style={{ fontWeight: 500 }}
              >
                {`${outputPrice} ${outputCurrency}`}
              </Typography.Text>
            </div>
            <div className={'flex-row gap-36'}>
              <Typography.Text
                className={'text-14px'}
                style={{ fontWeight: 500, color: '#6B6E75', width: 104 }}
              >
                {t('wallet.coin.withdraw.inputs.labels.withdrawalAddress')}
              </Typography.Text>
              <Typography.Text
                className={'text-14px'}
                style={{ fontWeight: 500 }}
              >
                {outputWallet}
              </Typography.Text>
            </div>
          </div>
        </div>
      </>
    </BasicModal>
  );
};

export default ConfirmWithdrawModal;
