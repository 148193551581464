export const DropdownIconSimpleColor = (color?: string) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.24775 11.1399L2.45152 5.6585C1.88576 5.01192 2.34494 4 3.20409 4H12.7966C13.6557 4 14.1149 5.01192 13.5491 5.6585L8.7529 11.1399C8.35449 11.5952 7.64616 11.5952 7.24775 11.1399Z"
      fill={color ? color : '#767687'}
    />
  </svg>
);

export const Logo = (color?: string, width?:number, height?: number) => {
  return (
    <svg width={width? width: 570} height={height? height: 137} viewBox="0 0 570 137" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M188.155 63.4488C188.155 68.1538 191.96 71.9594 196.595 71.9594H234.156V69.4685H196.595C193.274
      69.4685 190.576 66.77 190.576 63.4488V47.8806H231.528V45.3897H190.576V29.7523C190.576 26.4311 193.274
      23.6634 196.595 23.6634H234.156V21.2417H196.595C191.96 21.2417 188.155 25.0473 188.155 29.7523V63.4488ZM291.98
      71.9594C299.243 71.9594 305.123 66.0781 305.123 58.8129V34.4574C305.123 27.1922 299.243 21.2417 291.98
      21.2417H250.959V71.9594H291.98ZM253.38 69.4685V23.7326H291.98C297.86 23.7326 302.702 28.576 302.702
      34.4574V58.8129C302.702 64.6942 297.86 69.4685 291.98 69.4685H253.38ZM323.421 63.4488C323.421 68.1538
      327.226 71.9594 331.86 71.9594H369.422V69.4685H331.86C328.54 69.4685 325.842 66.77 325.842
      63.4488V47.8806H366.794V45.3897H325.842V29.7523C325.842 26.4311 328.54 23.6634 331.86
      23.6634H369.422V21.2417H331.86C327.226 21.2417 323.421 25.0473 323.421 29.7523V63.4488ZM388.715
      26.1543C388.715 24.9781 389.199 24.1478 390.099 23.871C390.929 23.5942 392.312 23.871 393.142
      25.1164H393.211L429.528 69.8836C430.22 70.8523 431.188 71.5442 432.226 71.8902C433.264 72.3053
      434.37 72.3053 435.408 72.0286C436.307 71.7518 437.068 71.1291 437.622 70.368C438.175 69.5377
      438.521 68.4998 438.521 67.3235V21.2417H436.031V67.3235C436.031 68.569 435.546 69.3993 434.647
      69.676C433.748 69.9528 432.433 69.676 431.534 68.4306V68.3614L395.148 23.6634C394.526 22.6947
      393.557 21.9336 392.52 21.5877C391.482 21.1725 390.306 21.1725 389.338 21.5185C388.438 21.7952
      387.608 22.3488 387.124 23.1099C386.501 23.9402 386.225 24.9781 386.225 26.1543V71.9594H388.715V26.1543ZM458.552
      63.4488C458.552 68.1538 462.357 71.9594 466.992 71.9594H504.554V69.4685H466.992C463.671 69.4685 460.974 66.77
      460.974 63.4488V47.8806H501.925V45.3897H460.974V29.7523C460.974 26.4311 463.671 23.6634 466.992
      23.6634H504.554V21.2417H466.992C462.357 21.2417 458.552 25.0473 458.552 29.7523V63.4488ZM564.59
      71.9594L543.216 46.7735L564.867 21.2417H561.478L541.555 44.8361L521.633 21.2417H518.451L539.964
      46.7043L518.589 71.9594H521.91L541.624 48.6417L561.408 71.9594H564.59Z"
        fill={color ? color : '#FAFAFC'}
      />
      <path
        d="M234.156 71.9594V73.3433H235.54V71.9594H234.156ZM234.156 69.4685H235.54V68.0847H234.156V69.4685ZM190.576
      47.8806V46.4968H189.193V47.8806H190.576ZM231.528 47.8806V49.2645H232.911V47.8806H231.528ZM231.528
      45.3897H232.911V44.0059H231.528V45.3897ZM190.576 45.3897H189.193V46.7736H190.576V45.3897ZM234.156
      23.6635V25.0473H235.54V23.6635H234.156ZM234.156 21.2417H235.54V19.8579H234.156V21.2417ZM186.771
      63.4488C186.771 68.9108 191.188 73.3433 196.594 73.3433V70.5756C192.732 70.5756 189.538 67.3969
      189.538 63.4488H186.771ZM196.594 73.3433H234.156V70.5756H196.594V73.3433ZM235.54
      71.9594V69.4685H232.773V71.9594H235.54ZM234.156 68.0847H196.594V70.8524H234.156V68.0847ZM196.594
      68.0847C194.038 68.0847 191.96 66.0057 191.96 63.4488H189.193C189.193 67.5343 192.51 70.8524 196.594
      70.8524V68.0847ZM191.96 63.4488V47.8806H189.193V63.4488H191.96ZM190.576
      49.2645H231.528V46.4968H190.576V49.2645ZM232.911 47.8806V45.3897H230.144V47.8806H232.911ZM231.528
      44.0059H190.576V46.7736H231.528V44.0059ZM191.96 45.3897V29.7524H189.193V45.3897H191.96ZM191.96
      29.7524C191.96 27.1748 194.058 25.0473 196.594 25.0473V22.2796C192.489 22.2796 189.193 25.6875
      189.193 29.7524H191.96ZM196.594 25.0473H234.156V22.2796H196.594V25.0473ZM235.54
      23.6635V21.2417H232.773V23.6635H235.54ZM234.156 19.8579H196.594V22.6256H234.156V19.8579ZM196.594
      19.8579C191.188 19.8579 186.771 24.2903 186.771 29.7524H189.538C189.538 25.8043 192.732 22.6256
      196.594 22.6256V19.8579ZM186.771 29.7524V63.4488H189.538V29.7524H186.771ZM250.959
      21.2417V19.8579H249.575V21.2417H250.959ZM250.959 71.9594H249.575V73.3433H250.959V71.9594ZM253.38
      69.4685H251.996V70.8524H253.38V69.4685ZM253.38 23.7327V22.3488H251.996V23.7327H253.38ZM291.979
      73.3433C300.006 73.3433 306.506 66.8424 306.506 58.813H303.739C303.739 65.3138 298.478 70.5756
      291.979 70.5756V73.3433ZM306.506 58.813V34.4574H303.739V58.813H306.506ZM306.506 34.4574C306.506
      26.4375 300.016 19.8579 291.979 19.8579V22.6256C298.468 22.6256 303.739 27.947 303.739
      34.4574H306.506ZM291.979 19.8579H250.959V22.6256H291.979V19.8579ZM249.575
      21.2417V71.9594H252.342V21.2417H249.575ZM250.959 73.3433H291.979V70.5756H250.959V73.3433ZM254.763
      69.4685V23.7327H251.996V69.4685H254.763ZM253.38 25.1165H291.979V22.3488H253.38V25.1165ZM291.979
      25.1165C297.094 25.1165 301.318 29.3404 301.318 34.4574H304.085C304.085 27.8118 298.623 22.3488
      291.979 22.3488V25.1165ZM301.318 34.4574V58.813H304.085V34.4574H301.318ZM301.318 58.813C301.318
      63.9184 297.107 68.0847 291.979 68.0847V70.8524C298.61 70.8524 304.085 65.4702 304.085
      58.813H301.318ZM291.979 68.0847H253.38V70.8524H291.979V68.0847ZM369.422
      71.9594V73.3433H370.805V71.9594H369.422ZM369.422 69.4685H370.805V68.0847H369.422V69.4685ZM325.842
      47.8806V46.4968H324.458V47.8806H325.842ZM366.793 47.8806V49.2645H368.177V47.8806H366.793ZM366.793
      45.3897H368.177V44.0059H366.793V45.3897ZM325.842 45.3897H324.458V46.7736H325.842V45.3897ZM369.422
      23.6635V25.0473H370.805V23.6635H369.422ZM369.422 21.2417H370.805V19.8579H369.422V21.2417ZM322.037
      63.4488C322.037 68.9108 326.455 73.3433 331.86 73.3433V70.5756C327.997 70.5756 324.804 67.3969
      324.804 63.4488H322.037ZM331.86 73.3433H369.422V70.5756H331.86V73.3433ZM370.805
      71.9594V69.4685H368.038V71.9594H370.805ZM369.422 68.0847H331.86V70.8524H369.422V68.0847ZM331.86
      68.0847C329.305 68.0847 327.225 66.0057 327.225 63.4488H324.458C324.458 67.5343 327.776 70.8524
      331.86 70.8524V68.0847ZM327.225 63.4488V47.8806H324.458V63.4488H327.225ZM325.842
      49.2645H366.793V46.4968H325.842V49.2645ZM368.177 47.8806V45.3897H365.41V47.8806H368.177ZM366.793
      44.0059H325.842V46.7736H366.793V44.0059ZM327.225 45.3897V29.7524H324.458V45.3897H327.225ZM327.225
      29.7524C327.225 27.1748 329.325 25.0473 331.86 25.0473V22.2796C327.755 22.2796 324.458 25.6875
      324.458 29.7524H327.225ZM331.86 25.0473H369.422V22.2796H331.86V25.0473ZM370.805
      23.6635V21.2417H368.038V23.6635H370.805ZM369.422 19.8579H331.86V22.6256H369.422V19.8579ZM331.86
      19.8579C326.455 19.8579 322.037 24.2903 322.037 29.7524H324.804C324.804 25.8043 327.997 22.6256
      331.86 22.6256V19.8579ZM322.037 29.7524V63.4488H324.804V29.7524H322.037ZM390.098 23.871L390.505
      25.1937L390.52 25.189L390.535 25.1839L390.098 23.871ZM393.142 25.1165L391.991 25.8841L392.402
      26.5003H393.142V25.1165ZM393.211 25.1165L394.286 24.2445L393.87 23.7327H393.211V25.1165ZM429.528
      69.8837L430.654 69.0794L430.629 69.0448L430.603 69.0117L429.528 69.8837ZM432.226 71.8902L432.739
      70.6053L432.702 70.5903L432.663 70.5774L432.226 71.8902ZM435.408 72.0286L435.765 73.3657L435.79
      73.359L435.814 73.3513L435.408 72.0286ZM437.621 70.368L438.741 71.182L438.757 71.1592L438.772
      71.1356L437.621 70.368ZM438.521 21.2417H439.904V19.8579H438.521V21.2417ZM436.03
      21.2417V19.8579H434.647V21.2417H436.03ZM431.534 68.4306H430.15V68.8782L430.412 69.2409L431.534
      68.4306ZM431.534 68.3615H432.917V67.8694L432.606 67.4877L431.534 68.3615ZM395.148 23.6635L393.984
      24.4118L394.026 24.477L394.076 24.5372L395.148 23.6635ZM392.519 21.5877L392.006 22.8726L392.043
      22.8877L392.082 22.9006L392.519 21.5877ZM389.337 21.5185L389.744 22.8412L389.773 22.8321L389.802
      22.8217L389.337 21.5185ZM387.124 23.1099L388.23 23.9402L388.262 23.8977L388.291 23.8529L387.124
      23.1099ZM386.224 71.9594H384.841V73.3433H386.224V71.9594ZM388.715 71.9594V73.3433H390.098V71.9594H388.715ZM390.098
      26.1544C390.098 25.7468 390.183 25.5084 390.256 25.3873C390.315 25.2878 390.385 25.2307 390.505 25.1937L389.691
      22.5484C388.016 23.0641 387.331 24.6041 387.331 26.1544H390.098ZM390.535 25.1839C390.773 25.1049 391.507 25.1568
      391.991 25.8841L394.293 24.3489C393.117 22.5853 391.085 22.0837 389.661 22.5582L390.535 25.1839ZM393.142
      26.5003H393.211V23.7327H393.142V26.5003ZM392.136 25.9885L428.453 70.7556L430.603 69.0117L394.286 24.2445L392.136
      25.9885ZM428.402 70.688C429.266 71.8974 430.477 72.7656 431.788 73.2031L432.663 70.5774C431.899 70.3229 431.173
      69.8073 430.654 69.0794L428.402 70.688ZM431.712 73.1751C433.074 73.7202 434.493 73.7049 435.765 73.3657L435.051
      70.6916C434.247 70.9059 433.453 70.8906 432.739 70.6053L431.712 73.1751ZM435.814 73.3513C437.061 72.9678 438.052
      72.1284 438.741 71.182L436.502 69.554C436.084 70.1298 435.553 70.5359 435.001 70.7059L435.814 73.3513ZM438.772
      71.1356C439.475 70.0814 439.904 68.7772 439.904 67.3236H437.137C437.137 68.2225 436.874 68.994 436.47
      69.6004L438.772 71.1356ZM439.904 67.3236V21.2417H437.137V67.3236H439.904ZM438.521
      19.8579H436.03V22.6256H438.521V19.8579ZM434.647 21.2417V67.3236H437.414V21.2417H434.647ZM434.647 67.3236C434.647
      67.7783 434.557 68.0358 434.482 68.1626C434.424 68.2635 434.358 68.3173 434.24 68.3534L435.053 70.9988C436.747
      70.4775 437.414 68.918 437.414 67.3236H434.647ZM434.24 68.3534C433.923 68.4507 433.218 68.3994 432.656
      67.6204L430.412 69.2409C431.649 70.9528 433.572 71.455 435.053 70.9988L434.24 68.3534ZM432.917
      68.4306V68.3615H430.15V68.4306H432.917ZM432.606 67.4877L396.22 22.7897L394.076 24.5372L430.462
      69.2352L432.606 67.4877ZM396.312 22.9151C395.526 21.6927 394.307 20.725 392.957 20.2749L392.082
      22.9006C392.807 23.1423 393.525 23.6968 393.984 24.4118L396.312 22.9151ZM393.033 20.3028C391.677
      19.7602 390.152 19.7577 388.872 20.2154L389.802 22.8217C390.459 22.5874 391.287 22.5848 392.006
      22.8726L393.033 20.3028ZM388.931 20.1958C387.791 20.5465 386.655 21.2694 385.956 22.3669L388.291 23.8529C388.561
      23.4282 389.085 23.0441 389.744 22.8412L388.931 20.1958ZM386.017 22.2796C385.17 23.4097 384.841 24.7638 384.841
      26.1544H387.608C387.608 25.1925 387.832 24.4708 388.23 23.9402L386.017 22.2796ZM384.841
      26.1544V71.9594H387.608V26.1544H384.841ZM386.224 73.3433H388.715V70.5756H386.224V73.3433ZM390.098
      71.9594V26.1544H387.331V71.9594H390.098ZM504.553 71.9594V73.3433H505.937V71.9594H504.553ZM504.553
      69.4685H505.937V68.0847H504.553V69.4685ZM460.973 47.8806V46.4968H459.59V47.8806H460.973ZM501.925
      47.8806V49.2645H503.308V47.8806H501.925ZM501.925 45.3897H503.308V44.0059H501.925V45.3897ZM460.973
      45.3897H459.59V46.7736H460.973V45.3897ZM504.553 23.6635V25.0473H505.937V23.6635H504.553ZM504.553
      21.2417H505.937V19.8579H504.553V21.2417ZM457.169 63.4488C457.169 68.9108 461.586 73.3433 466.992
      73.3433V70.5756C463.129 70.5756 459.936 67.3969 459.936 63.4488H457.169ZM466.992
      73.3433H504.553V70.5756H466.992V73.3433ZM505.937 71.9594V69.4685H503.17V71.9594H505.937ZM504.553
      68.0847H466.992V70.8524H504.553V68.0847ZM466.992 68.0847C464.436 68.0847 462.357 66.0057 462.357
      63.4488H459.59C459.59 67.5343 462.907 70.8524 466.992 70.8524V68.0847ZM462.357
      63.4488V47.8806H459.59V63.4488H462.357ZM460.973 49.2645H501.925V46.4968H460.973V49.2645ZM503.308
      47.8806V45.3897H500.541V47.8806H503.308ZM501.925 44.0059H460.973V46.7736H501.925V44.0059ZM462.357
      45.3897V29.7524H459.59V45.3897H462.357ZM462.357 29.7524C462.357 27.1748 464.456 25.0473 466.992
      25.0473V22.2796C462.887 22.2796 459.59 25.6875 459.59 29.7524H462.357ZM466.992
      25.0473H504.553V22.2796H466.992V25.0473ZM505.937 23.6635V21.2417H503.17V23.6635H505.937ZM504.553
      19.8579H466.992V22.6256H504.553V19.8579ZM466.992 19.8579C461.586 19.8579 457.169 24.2903 457.169
      29.7524H459.936C459.936 25.8043 463.129 22.6256 466.992 22.6256V19.8579ZM457.169
      29.7524V63.4488H459.936V29.7524H457.169ZM564.59 71.9594V73.3433H567.579L565.644 71.0638L564.59 71.9594ZM543.215
      46.7736L542.16 45.8784L541.4 46.774L542.16 47.6692L543.215 46.7736ZM564.867 21.2417L565.921 22.137L567.854
      19.8579H564.867V21.2417ZM561.477 21.2417V19.8579H560.834L560.42 20.3489L561.477 21.2417ZM541.555 44.8362L540.498
      45.729L541.555 46.9809L542.612 45.729L541.555 44.8362ZM521.633 21.2417L522.69 20.3489L522.275
      19.8579H521.633V21.2417ZM518.451 21.2417V19.8579H515.469L517.394 22.135L518.451 21.2417ZM539.964 46.7044L541.02
      47.5985L541.776 46.7052L541.021 45.8111L539.964 46.7044ZM518.589 71.9594L517.533 71.0653L515.605
      73.3433H518.589V71.9594ZM521.909 71.9594V73.3433H522.551L522.965 72.853L521.909 71.9594ZM541.624 48.6418L542.678
      47.7463L541.623 46.501L540.567 47.7482L541.624 48.6418ZM561.408 71.9594L560.353 72.8549L560.768
      73.3433H561.408V71.9594ZM565.644 71.0638L544.269 45.8779L542.16 47.6692L563.535 72.855L565.644 71.0638ZM544.269
      47.6688L565.921 22.137L563.811 20.3465L542.16 45.8784L544.269 47.6688ZM564.867
      19.8579H561.477V22.6256H564.867V19.8579ZM560.42 20.3489L540.498 43.9433L542.612 45.729L562.534 22.1346L560.42
      20.3489ZM542.612 43.9433L522.69 20.3489L520.576 22.1346L540.498 45.729L542.612 43.9433ZM521.633
      19.8579H518.451V22.6256H521.633V19.8579ZM517.394 22.135L538.907 47.5976L541.021 45.8111L519.508 20.3485L517.394 22.135ZM538.908
      45.8103L517.533 71.0653L519.645 72.8535L541.02 47.5985L538.908 45.8103ZM518.589 73.3433H521.909V70.5756H518.589V73.3433ZM522.965
      72.853L542.68 49.5353L540.567 47.7482L520.852 71.0659L522.965 72.853ZM540.569 49.5372L560.353 72.8549L562.462 71.0639L542.678
      47.7463L540.569 49.5372ZM561.408 73.3433H564.59V70.5756H561.408V73.3433Z"
        fill={color ? color : '#FAFAFC'}
      />
      <path
        d="M203.637 96.3022H191.093V103.823H203.273V108.262H191.093V118.722H203.637V123.161H186.371V91.8628H203.637V96.3022ZM219.29
      106.803L210.678 91.8628H216.048L222.027 102.465L228.309 91.8628H233.72L224.701 106.803L234.165 123.161H228.876L221.945 111.181L214.812
      123.161H209.462L219.29 106.803ZM263.62 93.5453V99.1402C260.89 96.8563 258.067 95.7144 255.149 95.7144C251.932
      95.7144 249.223 96.8699 247.022 99.1807C244.806 101.478 243.698 104.289 243.698 107.613C243.698 110.897 244.806 113.668
      247.022 115.925C249.237 118.182 251.953 119.31 255.168 119.31C256.83 119.31 258.242 119.04 259.404 118.499C260.053
      118.229 260.722 117.864 261.41 117.404C262.113 116.945 262.849 116.391 263.62 115.742V121.438C260.918 122.965 258.08
      123.729 255.107 123.729C250.636 123.729 246.819 122.168 243.657 119.046C240.51 115.898 238.936 112.1 238.936 107.654C238.936
      103.667 240.253 100.113 242.887 96.9915C246.13 93.167 250.325 91.2548 255.473 91.2548C258.282 91.2548 260.998 92.0183
      263.62 93.5453ZM278.118 104.107H291.614V91.8628H296.337V123.161H291.614V108.546H278.118V123.161H273.396V91.8628H278.118V104.107ZM325.122
      115.56H311.725L308.239 123.161H303.153L318.636 89.8966L333.572 123.161H328.405L325.122 115.56ZM323.196 111.12L318.556
      100.478L313.691 111.12H323.196ZM340.409 123.161V89.7344L363.229 113.614V91.8628H367.951V125.067L345.131 101.248V123.161H340.409ZM394.79
      106.864H407.721V107.897C407.721 110.249 407.443 112.33 406.889 114.141C406.348 115.817 405.436 117.384 404.153 118.844C401.249
      122.114 397.553 123.749 393.068 123.749C388.691 123.749 384.941 122.168 381.82 119.006C378.699 115.83 377.138 112.019 377.138
      107.573C377.138 103.032 378.727 99.1807 381.9 96.0184C385.076 92.8426 388.94 91.2548 393.493 91.2548C395.939 91.2548 398.222
      91.7548 400.344 92.7547C402.369 93.7548 404.363 95.3766 406.322 97.6199L402.957 100.843C400.391 97.4239 397.263 95.7144 393.574
      95.7144C390.264 95.7144 387.488 96.8563 385.244 99.1402C383.002 101.384 381.881 104.194 381.881 107.573C381.881 111.06
      383.13 113.931 385.63 116.188C387.967 118.283 390.5 119.33 393.23 119.33C395.554 119.33 397.64 118.546 399.492
      116.979C401.343 115.398 402.376 113.506 402.592 111.303H394.79V106.864ZM433.851
      96.3022H421.305V103.823H433.485V108.262H421.305V118.722H433.851V123.161H416.583V91.8628H433.851V96.3022Z"
        fill={color ? color : '#FAFAFC'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M74.9816 8.35956C71.9251 8.35956 69.4476 10.8378 69.4476 13.8948V54.0156C69.4476 60.6017 72.071 66.9165
      76.7375 71.5629L90.0704 84.8382C94.7012 89.449 102.297 89.0647 106.439 84.0098L115.736 72.6638H115.62L125.723
      60.3744C127.519 58.3354 128.607 55.6596 128.607 52.7295V19.9343C128.607 13.5417 123.425 8.35956 117.032
      8.35956H74.9816ZM55.7964 72.6638C62.3998 72.6638 68.7327 75.2872 73.4027 79.9569L86.2941 92.8477C90.3187
      96.872 90.6364 103.293 87.0286 107.695L86.4949 108.347C75.9836 121.173 60.2769 128.609 43.6967
      128.609H19.9341C13.5414 128.609 8.35942 123.426 8.35942 117.034V78.1992C8.35942 75.1422 10.8371
      72.6638 13.8933 72.6638H55.7964ZM132.558 65.3244L107.957 95.3437C106.079 97.6352 106.245 100.977 108.34
      103.072L133.4 128.133C135.032 129.765 135.032 132.412 133.4 134.045C131.768 135.677 129.122 135.677 127.49
      134.045L103.631 110.184C101.325 107.878 97.5334 108.065 95.4656 110.587L90.4398 116.717C79.9285 129.536
      64.2257 136.968 47.6501 136.968H19.9341C8.92459 136.968 0 128.043 0 117.034V72.6638V64.3043V19.9343C0 8.92489
      8.92459 0 19.9341 0H61.0881H69.4476H117.032C128.041 0 136.966 8.92489 136.966 19.9343V52.7295C136.966 57.4896
      135.298 61.8602 132.513 65.288L132.558 65.3244ZM54.1706 64.3043C57.9911 64.3043 61.0881 61.2065 61.0881
      57.3852V13.8948C61.0881 10.8378 58.6105 8.35956 55.5541 8.35956H19.9341C13.5414 8.35956 8.35942 13.5417
      8.35942 19.9343V58.769C8.35942 61.826 10.8371 64.3043 13.8935 64.3043H54.1706Z"
        fill={color ? color : '#FAFAFC'}
      />
    </svg>
  );
}

