import styled from 'styled-components';
import {ReactComponent as CircleDots} from '../../shared/components/ui/assets/svg/CircleDots.svg';
import {ReactComponent as BlockedLight} from '../../shared/components/ui/assets/svg/BlockedLight.svg';
import {useTranslation} from 'react-i18next';
import {FC} from 'react';
import {useNavigate} from '@pankod/refine-react-router-v6';
import {ErrorBoundary} from '../../app/ErrorBoundary';
import {useCookiesCustom} from '../../shared/hooks/useCookieCustom';

const Blocked: FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {removeCookie} = useCookiesCustom();

    return (
        <ErrorBoundary
            logout={() => {
            }}
            navigate={navigate}
            removeCookie={removeCookie}
        >
            <BlockedWrapper>
                <BlockedTitle>{t('blocked.title')}</BlockedTitle>
                <BlockedSubTitle>
                    {t('blocked.subTitle1')}
                    <BoldText>{t('blocked.supportService')}</BoldText>
                    {t('blocked.subTitle2')}
                </BlockedSubTitle>
                <CircleDotsStyled/>
                <BlockedLightStyled/>
            </BlockedWrapper>
        </ErrorBoundary>
    );
};

const BlockedWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  height: calc(95vh - 80px);
  width: 100%;
  color: rgba(251, 251, 255, 1);
  text-align: center;
  position: relative;
`;

const BlockedTitle = styled.div`
  font-size: 72px;
  font-weight: 700;
  text-align: center;
`;

const BlockedSubTitle = styled.div`
  position: relative;
  z-index: 100;
  font-size: 16px;
  width: 990px;
`;

const CircleDotsStyled = styled(CircleDots)`
  position: absolute;
  left: 20%;
  top: 50%;
  z-index: 0;
`;

const BlockedLightStyled = styled(BlockedLight)`
  position: absolute;
  right: -32px;
  top: -20%;
  z-index: 0;
`;

const BoldText = styled.span`
  font-weight: 700;
`;

export default Blocked;
