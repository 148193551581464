import React, { Children } from 'react';

interface IBackgroundProgress {
  bgcolor: string;
  percent: number;
  children: React.ReactNode;
  styleContainer?: React.CSSProperties;
  styleChildrenContainer?: React.CSSProperties;
  styleFiller?: React.CSSProperties;
}

const BackgroundProgress: React.FC<IBackgroundProgress> = ({
  bgcolor,
  percent,
  children,
  styleContainer,
  styleChildrenContainer,
  styleFiller,
}) => {
  const savePercent = percent > 100 ? 100 : percent < 0 ? 0 : percent;

  const containerStyles: React.CSSProperties = {
    position: 'relative',
    height: 20,
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    ...styleContainer,
    //backgroundColor: '#e0e0de',
    //borderRadius: 50,
    //margin: 50,
  };

  const fillerStyles: React.CSSProperties = {
    height: '100%',
    width: `${savePercent}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'left',
    ...styleFiller,
  };

  const labelStyles: React.CSSProperties = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyles}>
      <div
        style={{
          position: 'absolute',
          top: 4.5,
          width: '100%',
          ...styleChildrenContainer,
        }}
      >
        {children}
      </div>
      <div style={fillerStyles} />
    </div>
  );
};

export default BackgroundProgress;
