import React from 'react';
import {Link} from '@pankod/refine-react-router-v6';
import {Typography} from 'antd';
import Icon from '@ant-design/icons';
import {ChevronDown} from '../../icons';
import {IBreadCrumb} from '../../../interfaces';
import styled from "styled-components";

interface IBreadCrumbs {
  items: IBreadCrumb[];
  separator?: React.ReactNode;
  gap?: number;
}

const CustomBreadcrumb: React.FC<IBreadCrumbs> = ({items, gap, separator}) => {
  return (
    <>
      <div
        className={'flex-row'}
        style={{
          gap: gap ? gap : 18,
        }}
      >
        {items.map((item, index) => {
          return (
            <>
              <Link to={item.link}>
                <StyledTypography
                  $colorText={index === items.length - 1 ? '#FBFBFF' : '#767687'}
                >
                  {item.title}
                </StyledTypography>
              </Link>
              {index !== items.length - 1 &&
                (separator ? (
                  separator
                ) : (
                  <Icon
                    style={{
                      rotate: '270deg',
                    }}
                    component={() => ChevronDown('#767687', 20)}
                  />
                ))}
            </>
          );
        })}
      </div>
    </>
  );
};

export default CustomBreadcrumb;

const StyledTypography = styled(Typography.Text)<{ $colorText: string }>`
  color: ${props => props.$colorText};
  font-size: 16px;
  line-height: 19px;
  height: 20px;
  cursor: pointer;

  &:hover, &:active {
    color: #FBFBFF !important;
  }
`
