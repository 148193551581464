import React, {Dispatch, FC, SetStateAction, useCallback, useMemo, useState,} from 'react';
import {BankCredention, FPS, MobileMethod,} from '../../../shared/components/icons';
import './index.css';
import {HttpError, useCreate, useList, useTranslate,} from '@pankod/refine-core';
import {IGetListAdData, IPaymentInfo, IPaymentsType, IRegionTemp} from './interface';
import * as _ from 'lodash';
import {isNumber} from 'lodash';
import {PaymentTypeEnum} from '../../../features/p2p/createOrder/types';
import IconOld from '@ant-design/icons';
import {IP2PUser} from '../../../interfaces';
import {toFixed} from '../../../shared/helpers/toFixed';
import {IP2PCollapseArrayElement} from 'widgets/p2p/buyOrder/collapse/interface';
import {Collapse as CollapseCustom} from 'widgets/p2p/buyOrder/collapse/index';
import {useAuthState} from "../../../useAuthState";
import styled from "styled-components";
import {transformPhone} from "../../../shared/helpers/phones";
import {useCookiesCustom} from "../../../shared/hooks/useCookieCustom";

type IconType = { payment_type: PaymentTypeEnum, name: string }

interface ICustomCollapse {
    type: 'buy' | 'sell';
    item: IGetListAdData;
    setOpenModal: Dispatch<SetStateAction<boolean>>,
    setFromCard: Dispatch<SetStateAction<boolean>>,
    user?: IP2PUser;
    iconsData?: IPaymentsType[],
    regions?: IRegionTemp[];
}

const CustomCollapse: FC<ICustomCollapse> = ({
                                                 type = 'buy',
                                                 item,
                                                 user,
                                                 setOpenModal,
                                                 setFromCard,
                                                 iconsData,
                                                 regions
                                             }) => {
    const t = useTranslate();
    const {cookie} = useCookiesCustom();
    const uid = useAuthState((state) => state?.userAPI?.uid) || cookie['uid'];
    const userAPI = useAuthState((state) => state?.userAPI);
    const isOwner = item?.owner_id === uid;
    const [isOpen, setIsOpen] = useState(false);

    const translate = useTranslate();
    const [paymentData, setPaymentData] = useState<IPaymentInfo[]>();

    useCreate<{ data: { id: string } }>({
        mutationOptions: {retry: false},
    });

    const {refetch: paymentRefetch} = useList<IPaymentInfo, HttpError>({
        dataProviderName: 'p2p',
        resource: 'payment',
        config: {
            filters: [
                {
                    field: 'account_id',
                    operator: 'eq',
                    value: uid,
                },
            ],
            pagination: {
                current: 1,
                pageSize: 100,
            },
        },
        queryOptions: {
            enabled: false,
            onSuccess: (res) => {
                setPaymentData(res.data);
            },
            retry: false,
        },
    });

    const handleOpen = async () => {
        setIsOpen(() => !isOpen);
        if (!isOpen && type === 'buy') {
            await paymentRefetch();
        }
    };

    function countDecimalPlaces(number: number) {
        const numberString = number.toString();
        const decimalIndex = numberString.indexOf('.');
        if (decimalIndex === -1) {
            return 0;
        } else {
            return numberString.length - decimalIndex - 1;
        }
    }

    const applyLimit = (
        limit: string,
        decimalPlaces: number,
        direction: 'min' | 'max' | ''
    ): string => {
        const result = +limit / +item?.price;
        const decimalIndex = countDecimalPlaces(result);

        if (decimalIndex <= decimalPlaces) {
            return result.toString();
        } else {
            const splitIndex = decimalPlaces + 1;
            if (direction === 'min') {
                const minCeil = (result + 0.00000001).toFixed(splitIndex);
                return minCeil.slice(0, minCeil.length - 1);
            }
            if (direction === 'max') {
                const maxFloor = result.toFixed(splitIndex);
                return maxFloor.slice(0, maxFloor.length - 1);
            } else return result.toFixed(decimalPlaces).toString();
        }
    };


    const typeText = useMemo(() => {
        return {
            text: translate(`dashboard.collapseHeader.button.${type}`),
            input: [
                translate(`dashboard.collapseBody.${type}.first`),
                translate(`dashboard.collapseBody.${type}.last`),
            ],
            placeholder: `${applyLimit(
                item?.min_sum_limit,
                item?.ccy === 'USDT' ? 2 : 8,
                'min'
            )} - ${applyLimit(
                item?.max_sum_limit,
                item?.ccy === 'USDT' ? 2 : 8,
                'max'
            )}`,
            sellMin: +applyLimit(
                item?.min_sum_limit,
                item?.ccy === 'USDT' ? 2 : 8,
                'min'
            ),
            sellMax: +applyLimit(
                item?.max_sum_limit,
                item?.ccy === 'USDT' ? 2 : 8,
                'max'
            ),
        };
    }, [
        item.max_sum_limit,
        item.min_sum_limit,
        item.price,
        item.ccy,
        translate,
        type,
    ]);

    const totalOrdersAmount = isOwner ? userAPI?.p2p_total_orders_amount : isNumber(item?.owner_info?.total_orders_amount) ? item?.owner_info?.total_orders_amount : 0;
    const successOrdersAmount = isOwner ? userAPI?.p2p_success_orders_amount : isNumber(item?.owner_info?.success_orders_amount) ? item?.owner_info?.success_orders_amount : 0;

    const countDeals = () => {
        return `${totalOrdersAmount} ${translate(
            'dashboard.collapseHeader.countDeals'
        )} | ${+toFixed(
            ((successOrdersAmount || 0) /
                (totalOrdersAmount || 1)) *
            100
        )}% ${translate('dashboard.collapseHeader.countCompleted')}`;
    };

    const Icon = useCallback(({payment_type, name}: IconType) => (
        <img
            alt={'paymentIcon'}
            src={`data:image/svg+xml;base64,${iconsData
                ?.find(type => type.payment_type === payment_type)
                ?.items
                ?.find(icon => icon.name === name)
                ?.icon
            }`}
            style={{
                height: '16px'
            }}
        />
    ), [iconsData])

    const tmpBuy = useCallback(({
                                    data = [],
                                    icon = false,
                                }: {
        data?: IGetListAdData['buy_payment_info'];
        icon?: boolean;
    }) => {
        return data?.map((item): IP2PCollapseArrayElement => {
            switch (item.payment_type) {
                case PaymentTypeEnum.bankCardPayment:
                    return {
                        value: item.id || item?.bank_id,
                        label: icon ? (
                            <LabelStyled key={item.payment_type + '_' + item.id}>
                                <Icon payment_type={PaymentTypeEnum.bankCardPayment} name={item.bank_name!}/>
                                {/*@ts-ignore*/}
                                {t(`banks.${item.bank_name || item?.title}`)}
                            </LabelStyled>
                        ) : (
                            <React.Fragment key={item.payment_type + '_' + item.id}>
                                {/*@ts-ignore*/}
                                {t(`banks.${item.bank_name || item?.title}`)}
                            </React.Fragment>
                        ),
                        // @ts-ignore
                        title: t(`banks.${item.bank_name || item?.title}`),
                        type: translate('payments.types.banksCard')
                    };
                case PaymentTypeEnum.mobileBalancePayment:
                    return {
                        value: item.id,
                        label: icon ? (
                            <LabelStyled key={item.payment_type + '_' + item.id}>
                                <IconOld component={() => MobileMethod('', 16)}/>
                                {translate('dashboard.filters.method.other.methods.mobile')}
                            </LabelStyled>
                        ) : (
                            <React.Fragment key={item.payment_type + '_' + item.id}>
                                {translate('dashboard.filters.method.other.methods.mobile')}
                            </React.Fragment>
                        ),
                        title: transformPhone(item?.phone ? item?.phone : '') || translate('payments.types.mobiles'),
                        type: translate('payments.types.mobiles')
                    };
                case PaymentTypeEnum.cashPayment:
                    return {
                        value: item.id,
                        label: 'Cash Payment',
                        type: translate('payments.types.cashes')
                    };
                case PaymentTypeEnum.bankTransferPayment:
                    return {
                        value: item.id,
                        label: icon ? (
                            <LabelStyled key={item.payment_type + '_' + item.id}>
                                <IconOld component={() => BankCredention('#FBFBFF', 16)}/>
                                {translate('dashboard.filters.method.other.methods.bank')}
                            </LabelStyled>
                        ) : (
                            <React.Fragment key={item.payment_type + '_' + item.id}>
                                {translate('dashboard.filters.method.other.methods.bank')}
                            </React.Fragment>
                        ),
                      //@ts-ignore
                        title: t(`banks.${item.bank_name ?? item?.title}`),
                        type: t('dashboard.filters.method.other.methods.bank'),
                    };
                case PaymentTypeEnum.fastPaymentSystemPayment:
                    return {
                        value: item.id,
                        label: icon ? (
                            <LabelStyled key={item.payment_type + '_' + item.id}>
                                <IconOld component={() => FPS('', 16)}/>
                                {translate('dashboard.filters.method.other.methods.fps')}
                            </LabelStyled>
                        ) : (
                            <React.Fragment key={item.payment_type + '_' + item.id}>
                                {translate('dashboard.filters.method.other.methods.fps')}
                            </React.Fragment>
                        ),
                        type: translate('payments.types.sbp')
                    };
                case PaymentTypeEnum.webServicePayment:
                    return {
                        value: item.id,
                        label: icon ? (
                            <LabelStyled key={item.payment_type + '_' + item.id}>
                                <Icon payment_type={PaymentTypeEnum.webServicePayment} name={item.web_service_name!}/>
                                {item.web_service_name}
                            </LabelStyled>
                        ) : (
                            <React.Fragment key={item.payment_type + '_' + item.id}>
                                {item.web_service_name}
                            </React.Fragment>
                        ),
                        title: item.web_service_name,
                        type: translate('payments.electronService')
                    };
                default:
                    return {
                        value: item.id,
                        label: '',
                        type: ''
                    }
            }
        })
    }, [Icon, translate])

    const tmp = useCallback(({
                                 data = [],
                                 icon = false,
                             }: {
        data?: IPaymentInfo[];
        icon?: boolean;
    }) => {
        return (
            data
                ?.map((item) => {
                    switch (item.payment_type) {
                        case PaymentTypeEnum.bankCardPayment:
                            return (
                                item.payment_details?.map((bank) => {
                                    return {
                                        value: icon ? item.id : undefined,
                                        label: icon ? (
                                            <LabelStyled key={item.payment_type + '_' + item.id}>
                                                <Icon payment_type={PaymentTypeEnum.bankCardPayment}
                                                      name={bank.bank_name!}/>
                                                {t(`banks.${bank.bank_name}`)}
                                            </LabelStyled>
                                        ) : (
                                            <React.Fragment key={item.payment_type + '_' + item.id}>
                                                {t(`banks.${bank.bank_name}`)}
                                            </React.Fragment>
                                        ),
                                        title: t(`banks.${bank.bank_name}`),
                                        type: translate('payments.types.banks'),
                                    };
                                }) ?? []
                            );
                        case PaymentTypeEnum.mobileBalancePayment:
                            return (
                                item.payment_details?.map(() => {
                                    return {
                                        value: item.id,
                                        label: icon ? (
                                            <LabelStyled key={item.payment_type + '_' + item.id}>
                                                <IconOld component={() => MobileMethod('', 16)}/>
                                                {translate(
                                                    'dashboard.filters.method.other.methods.mobile'
                                                )}
                                            </LabelStyled>
                                        ) : (
                                            <React.Fragment key={item.payment_type + '_' + item.id}>
                                                {translate('dashboard.filters.method.other.methods.mobile')}
                                            </React.Fragment>
                                        ),
                                        // @ts-ignore
                                        title: transformPhone(item?.phone),
                                        type: translate('payments.types.mobiles'),
                                    };
                                }) ?? []
                            );
                        case PaymentTypeEnum.cashPayment:
                            return (
                                item.payment_details?.map(() => {
                                    return {
                                        value: item.id,
                                        label: 'Cash Payment',
                                        type: translate('payments.types.cashes'),
                                    };
                                }) ?? []
                            );
                        case PaymentTypeEnum.bankTransferPayment:
                            return (
                                item.payment_details?.map((transfer) => {
                                    return {
                                        value: item.id,
                                        label: icon ? (
                                            <LabelStyled key={item.payment_type + '_' + item.id}>
                                                <IconOld
                                                    component={() => BankCredention('#FBFBFF', 16)}
                                                />
                                                {t(`banks.${transfer?.bank_name}`)}
                                            </LabelStyled>
                                        ) : (
                                            <React.Fragment key={item.payment_type + '_' + item.id}>
                                                {t(`banks.${transfer?.bank_name}`)}
                                            </React.Fragment>
                                        ),
                                        // @ts-ignore
                                        title: t(`banks.${transfer?.bank_name || transfer?.payment_details[0]?.bank_name}`),
                                        type: translate(
                                            'dashboard.filters.method.other.methods.bank'
                                        ),
                                    };
                                }) ?? []
                            );
                        case PaymentTypeEnum.fastPaymentSystemPayment:
                            return (
                                item.payment_details?.map(() => {
                                    return {
                                        value: item.id,
                                        label: icon ? (
                                            <LabelStyled key={item.payment_type + '_' + item.id}>
                                                <IconOld component={() => FPS('', 16)}/>
                                                {translate(
                                                    'dashboard.filters.method.other.methods.fps'
                                                )}
                                            </LabelStyled>
                                        ) : (
                                            <React.Fragment key={item.payment_type + '_' + item.id}>
                                                {translate('dashboard.filters.method.other.methods.fps')}
                                            </React.Fragment>
                                        ),
                                        type: translate('payments.types.sbp'),
                                    };
                                }) ?? []
                            );
                        case PaymentTypeEnum.webServicePayment:
                            return (
                                item.payment_details?.map((web) => {
                                    return {
                                        value: item.id,
                                        label: icon ? (
                                            <LabelStyled key={item.payment_type + '_' + item.id}>
                                                <Icon
                                                    payment_type={PaymentTypeEnum.webServicePayment}
                                                    name={web.web_service_name!}
                                                />
                                                {web.web_service_name}
                                            </LabelStyled>
                                        ) : (
                                            <React.Fragment key={item.payment_type + '_' + item.id}>
                                                {web.web_service_name}
                                            </React.Fragment>
                                        ),
                                        title: web.web_service_name,
                                        type: translate('payments.electronService'),
                                    };
                                }) ?? []
                            );
                    }

                    return [];
                })
                .flat() ?? []
        );
    }, [Icon, translate])

    const getPaymentData = (item: any) => {
        const paymentDetails = item.payment_details || [];
        const bankName = paymentDetails ? paymentDetails?.bank_name : item.bank_name;
        return {
            id: item.id,
            title: bankName,
            payment_type: item.payment_type
        };
    };



    const methodArray = useCallback(
        (icon: boolean = false, method = false) => {
            switch (item.type) {
                case 'buy':
                    const paymentDataMod = paymentData?.map((el) => {
                        return {
                            ...el,
                            payment_details: el.payment_details[0],
                        }
                    })
                  console.log(paymentDataMod,'PAYMENT DATA MOD')
                    // @ts-ignore
                    const filteredData = (paymentDataMod ?? item.buy_payment_info)
                        // @ts-ignore
                        .filter((itemFilter) => {
                            return (item.buy_payment_info ?? []).some((itemFind) => {
                                return itemFind.payment_type === itemFilter.payment_type &&
                                    (!itemFilter.bank_name || !itemFind.bank_name || itemFind.bank_name === itemFilter.bank_name) &&
                                    (!itemFilter.web_service_name || !itemFind.web_service_name || itemFind.web_service_name === itemFilter.web_service_name)
                            });
                        })
                        .map(getPaymentData);

                    return method && isOpen && paymentDataMod?.length !== undefined
                        // @ts-ignore
                        ? tmpBuy({data: filteredData, icon})
                        : tmpBuy({data: item?.buy_payment_info ?? [], icon});
                case 'sell':
                    return tmp({
                        data: item?.payment_info ?? [],
                        icon,
                    });
            }
        },
        [isOpen, paymentData, item, tmp, tmpBuy]
    );

    return (
        <CollapseCustom
            regions={regions ? regions : []}
            isOpen={isOpen}
            user={user}
            setOpenModal={setOpenModal}
            setFromCard={setFromCard}
            typeText={typeText}
            dataAd={item}
            countDeals={countDeals()}
            methodArray={methodArray}
            onClickOpen={handleOpen}
            setIsOpen={setIsOpen}
            item={item}
        />
    );
};

const LabelStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export default CustomCollapse;
