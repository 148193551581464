import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import Icon from '@ant-design/icons';
import { EtherCoinIcon, SideBarLogo } from '../../../icons';
import {
  InfoCircle,
  ShevronIcon,
  SupportIcon,
} from '../../../ui/assets/svg/infoIcons';
import { Icons } from '@pankod/refine-antd';
import { IDrawerMenuItem } from '../../../../interfaces';
import DrawerMenuItem from './drawer-menu-item';
import InnerDrawer from './innerDrawer';
import styled from 'styled-components';
import appstore from '../../../ui/assets/png/appstore.png';
import googlePlay from '../../../ui/assets/png/googleplay.png';
import devices from '../../../ui/assets/png/Devices.png';
import { useGetScreenSize } from '../../../../hooks/screen/use-get-screen-size';

const { UserOutlined } = Icons;

interface IDrawerHeaderContent {
  pairWithDash: () => string;
  closeAction: () => void;
  setIsLoginScreen: (isShow: boolean) => void;
  isLoginScreen: () => boolean;
}

interface IIsMobile {
  isMobile?: boolean;
}

const DrawerHeaderContent: React.FC<IDrawerHeaderContent> = ({
  pairWithDash,
  closeAction,
  setIsLoginScreen,
  isLoginScreen,
}) => {
  const { width } = useGetScreenSize();
  const isMobileAdaptive = width <= 368;

  const MainMenuItems: IDrawerMenuItem[] = [
    // {
    //   link: '/cryptocurrency-exchange',
    //   text: 'Обмен криптовалют',
    //   iconComponent: EtherCoinIcon(undefined, 24),
    // },
    // {
    //   link: '',
    //   text: 'Рынки',
    //   iconComponent: EtherCoinIcon(undefined, 24),
    // },
    {
      link: '',
      text: 'Торговля',
      iconComponent: EtherCoinIcon(undefined, 24),
      hasAdditionLevel: true,
      onClickAction: () => setInnerTradeDrawer(true),
    },
  ];

  const TradeMenuItems: IDrawerMenuItem[] = [
    {
      link: '/p2p/dashboard',
      text: 'Р2Р торговля',
      iconComponent: EtherCoinIcon(undefined, 24),
    },
    {
      link: `/spot/dashboard/${pairWithDash()}`,
      text: 'Спотовая торговля',
      iconComponent: EtherCoinIcon(undefined, 24),
      onClickAction: () => {
        setInnerTradeDrawer(false);
        closeAction();
      },
    },
  ];

  const [innerTradeDrawer, setInnerTradeDrawer] = useState(false);

  const closeDrawers = () => {
    setInnerTradeDrawer(false);
    setIsLoginScreen(false);
    closeAction();
  };

  useEffect(() => {
    setIsLoginScreen(false);
  }, [innerTradeDrawer]);

  const LoginScreen = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
  `;

  const LoginScreenAvailableSoon = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;

  const LoginScreenAvailableSoonTitle = styled.div`
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    display: flex;
    justify-content: center;
    text-align: center;
  `;

  const LoginScreenAvailableSoonButtons = styled.div<IIsMobile>`
    display: flex;
    flex-direction: ${({ isMobile }) => (!isMobile ? 'row' : 'column')};
    justify-content: space-between;
    padding-top: 30px;
    gap: 10px;
  `;

  const LoginScreenAvailableSoonImage = styled.img`
    max-width: 302px;
    max-height: 606px;
    align-self: center;
    position: relative;
    bottom: -106px;
    z-index: 0;
    -webkit-filter: drop-shadow(1px -5px 28px rgba(110, 39, 248, 0.2));
  `;

  const LoginScreenAvailableSoonButtonAppStore = styled.img`
    max-width: 172px;
    max-height: 50px;
    width: 100%;
    cursor: pointer;
  `;
  const LoginScreenAvailableSoonButtonGooglePlay = styled.img`
    max-width: 172px;
    max-height: 50px;
    width: 100%;
    cursor: pointer;
  `;

  return (
    <>
      {!isLoginScreen() && (
        <>
          <InnerDrawer
            isOpen={innerTradeDrawer}
            closeThisDrawer={() => {
              setInnerTradeDrawer(false);
              setIsLoginScreen(false);
            }}
            closeAll={closeDrawers}
            title={'Торговля'}
            drawerItems={TradeMenuItems}
          />
          <div
            style={{ paddingTop: 8, height: '100%' }}
            className={'flex-column gap-16'}
          >
            <div style={{ padding: 16, marginBottom: 16 }}>
              <div
                style={{ width: 'fit-content', cursor: 'pointer' }}
                className={'flex-row gap-16 align-items-center'}
                onClick={() => setIsLoginScreen(true)}
              >
                <div
                  style={{
                    background: '#703DF2',
                    height: 32,
                    width: 32,
                    borderRadius: 4,
                  }}
                  className={
                    'flex-row align-items-center justify-content-center'
                  }
                >
                  <UserOutlined style={{ fontSize: 18, color: '#FBFBFF' }} />
                </div>
                <Typography.Text
                  style={{
                    fontSize: 16,
                    fontWeight: 500,
                    lineHeight: '19.36px',
                  }}
                >
                  Войти в систему
                </Typography.Text>
              </div>
            </div>
            <div
              style={{
                borderTop:
                  '1px solid var(--surface-colors-add, rgba(65, 61, 80, 0.50))',
                borderBottom:
                  '1px solid var(--surface-colors-add, rgba(65, 61, 80, 0.50))',
                padding: '24px 0',
              }}
              className={'flex-column gap-8'}
            >
              {MainMenuItems.map((item, index) => {
                return (
                  <>
                    <DrawerMenuItem {...item} closeAction={closeDrawers} />
                  </>
                );
              })}
            </div>
            <div
              style={{
                height: '100%',
                paddingBottom: 16,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
            >
              <div
                className={'flex-row gap-24 align-items-flex-start'}
                style={{
                  padding: 16,
                }}
              >
                <Icon component={() => InfoCircle({ color: '#767687' })} />
                <Typography.Text
                  style={{
                    color: '#767687',
                    fontSize: 16,
                    fontWeight: 500,
                    lineHeight: '19.36px',
                  }}
                >
                  Справка
                </Typography.Text>
              </div>
              <div
                className={'flex-row gap-24'}
                style={{
                  padding: 16,
                }}
              >
                <Icon component={() => SupportIcon({ color: '#767687' })} />
                <Typography.Text
                  style={{
                    color: '#767687',
                    fontSize: 16,
                    fontWeight: 500,
                    lineHeight: '19.36px',
                  }}
                >
                  Поддержка
                </Typography.Text>
              </div>
            </div>
          </div>
        </>
      )}
      {isLoginScreen() && (
        <>
          <>
            <div
              style={{
                marginTop: '-20px',
                marginBottom: '30px',
              }}
              className={'flex-row justify-content-space-between'}
            >
              <Icon
                style={{
                  transform: 'rotate(180deg)',
                }}
                component={() => ShevronIcon({})}
                onClick={() => {
                  setIsLoginScreen(false);
                }}
              />
              <Icon component={SideBarLogo} style={{ width: 132 }} />
              <div />
            </div>
          </>
          <LoginScreen>
            <LoginScreenAvailableSoon>
              <LoginScreenAvailableSoonTitle>
                App will be available soon for download
              </LoginScreenAvailableSoonTitle>
              <LoginScreenAvailableSoonButtons isMobile={isMobileAdaptive}>
                <LoginScreenAvailableSoonButtonGooglePlay src={googlePlay} />
                <LoginScreenAvailableSoonButtonAppStore src={appstore} />
              </LoginScreenAvailableSoonButtons>
            </LoginScreenAvailableSoon>
            <LoginScreenAvailableSoonImage src={devices} />
          </LoginScreen>
        </>
      )}
    </>
  );
};

export { DrawerHeaderContent };
