import { axios } from '../../shared/exios';
import { API_URL } from '../../packages/keycloak-client/constants';

type TResPropsWithPair = {
  pairToLowerCase: string;
  page?: number;
  setFunc?: any;
  activePair?: string;
  limit?: number;
  direction?: 'desc' | 'asc';
};

type TResProps = {
  setFunc: any;
};

type TActivePair = {
  activePair: string;
};

export function timestampToDate(ts: number) {
  return ('' + new Date(ts).toISOString())?.replace(/^([^T]+)T(.+)$/, '$1');
}

export function checkMonth(month: string | any[]) {
  if (month?.length === 1) return `0${month}`;
  else return month;
}

export async function getOrderBookBuySpot({
  pairToLowerCase,
  setFunc,
  page,
  limit,
  direction,
}: TResPropsWithPair) {
  try {
    const res = await axios.post(
      `${API_URL}/${pairToLowerCase}-spot/api/order-book-buy/search?limit=${
        limit || 10
      }&page=${page}`,
      {
        sort: [{ field: 'price', direction: direction || 'desc' }],
      }
    );
    if (!!res?.data?.data?.length) {
      setFunc([]);
      const arr: {
        price: number;
        amountInCurrency: number;
        amountAll: number;
        percent: number;
      }[] = [];

      for (const buyOrder of res?.data?.data) {
        arr.push({
          price: buyOrder.price,
          amountInCurrency: buyOrder.sum,
          amountAll: buyOrder.sum_of_sum,
          percent: buyOrder.percent,
        });
      }
      // @ts-ignore
      setFunc(() => {
        return [
          // @ts-ignore
          ...new Map(arr.map((item) => [item['price'], item])).values(),
        ];
      });
    }
  } catch (e) {
    setFunc([]);
    console.log('getOrderBookBuySpot error', e);
  }
}

export async function getOrderBookSellSpot({
  pairToLowerCase,
  setFunc,
  page,
  limit,
  direction,
}: TResPropsWithPair) {
  try {
    const res = await axios.post(
      `${API_URL}/${pairToLowerCase}-spot/api/order-book-sale/search?limit=${
        limit || 10
      }&page=${page}`,
      {
        sort: [{ field: 'price', direction: direction || 'desc' }],
      }
    );

    if (!!res?.data?.data?.length) {
      setFunc([]);
      const arr: {
        price: number;
        amountInCurrency: number;
        amountAll: number;
        percent: number;
      }[] = [];

      for (const buyOrder of res?.data?.data.reverse()) {
        arr.push({
          price: buyOrder.price,
          amountInCurrency: buyOrder.sum,
          amountAll: buyOrder.sum_of_sum,
          percent: buyOrder.percent,
        });
      }

      // @ts-ignore
      setFunc(() => {
        return [
          // @ts-ignore
          ...new Map(arr.map((item) => [item['price'], item])).values(),
        ];
      });
    }
  } catch (e) {
    setFunc([]);
    console.log('getOrderBookSellSpot error', e);
  }
}

export async function getPairs({ setFunc }: TResProps) {
  try {
    const res = await axios.get(`${API_URL}/config/api/pair`);
    const pairsArr: any[] = [];
    for (const pair of res?.data?.data) {
      const pairWithDash = pair?.pair?.replace(new RegExp('/', 'g'), '-');
      pairsArr.push(pairWithDash);
    }
    setFunc((prevState: any) => [...prevState, ...pairsArr]);
    return res.data;
  } catch (e) {
    console.log('getPairs error', e);
  }
}

export async function getPairHeaderDataFromAPI({
  pairToLowerCase,
  activePair,
  setFunc,
}: TResPropsWithPair) {
  try {
    const res = await axios.get(
      `${API_URL}/${pairToLowerCase}-spot/api/custom-api/spot-stat`
    );
    if (!!activePair) {
      setFunc({
        currentPair:
          '' ?? `${res.data[activePair]?.main}/${res.data[activePair]?.last}`,
        mainPriceLast: res.data[activePair]?.last_price || 0,
        equivalentInFiat: res.data[activePair]?.last_price_usd || 0,
        changeValuePerDay: res.data[activePair]?.change_price_24 || 0,
        maxValuePerDay: res.data[activePair]?.max_price || 0,
        minValuePerDay: res.data[activePair]?.min_price || 0,
        marketVolumePerDay: res.data[activePair]?.volume_24 || 0,
        lastPriceUsd: res.data[activePair]?.last_price_usd || 0,
      });
    }
  } catch (e) {
    console.log('getPairHeaderDataFromAPI error', e);
  }
}

export async function getChartData({
  pairToLowerCase,
  setFunc,
  direction,
}: TResPropsWithPair) {
  try {
    const res = await axios.post(
      `${API_URL}/${pairToLowerCase?.replace(
        new RegExp('-', 'g'),
        ''
      )}-spot/api/chart/search`,
      {
        sort: [{ field: 'd', direction: direction || 'desc' }],
        filter: [{ field: 'type', operator: 'eq', value: 300 }],
      }
    );
    const resData = res?.data?.data || [];
    setFunc([]);
    // @ts-ignore
    setFunc((prevState: any) => {
      let arr: any[];
      if (!!prevState?.length) arr = [...prevState];
      else arr = [];
      if (!!resData?.length) {
        for (const item of resData) {
          const time = timestampToDate(item.d);
          arr.push({
            time,
            open: +item?.o,
            high: +item?.h,
            low: +item?.l,
            close: +item?.c,
          });
          for (const item of arr) {
            if (typeof item?.time === 'string') {
              const time = item?.time?.split('-');
              item.time = `${time[0]}-${checkMonth(time[1])}-${time[2]}`;
            } else {
              const time = item.time;
              item.time = `${time.year}-${checkMonth(time.month)}-${time.day}`;
            }
          }
        }
      }
      return [
        // @ts-ignore
        ...new Map(arr.map((item) => [item['time'], item])).values(),
      ];
    });
  } catch (e) {
    console.log('getChartData error', e);
  }
}

export async function getBalance({ activePair }: TActivePair) {
  try {
    const coins = activePair?.split('-');
    if (!!coins.length) {
      const ccy0 = coins[0]?.toLowerCase();
      const ccy1 = coins[1]?.toLowerCase();
      const ccyArr = [ccy0, ccy1];

      for (const ccy of ccyArr) {
        try {
          const res = await axios.get(`${API_URL}/${ccy}-coin/api/account`);
          localStorage.setItem(`balance-${ccy}`, '0' ?? res?.data?.amount);
        } catch (e) {
          console.log('getBalance error', e);
        }
      }
    }
  } catch (e) {
    console.log('getBalance error', e);
  }
}
