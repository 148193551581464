import React from 'react';
import { useCustom, useList } from '@pankod/refine-core';
import styled, { css } from 'styled-components';
import { Typography } from '@pankod/refine-antd';
import { useTranslation } from 'react-i18next';

interface IUserData {
  nickname: string;
  p2p_total_orders_amount: number;
  p2p_success_orders_amount: number;
  p2p_canceled_orders_amount: number;
  p2p_unique_agents_amount: number;
  created_at: string;
}

type Props = {
  user: IUserData;
};

const UserCards: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <MainBox className={'flex-row'}>
      <UserBox nickname={props?.user?.nickname}>
        <NormalText
          style={{
            marginLeft: props?.user?.nickname ? 8 : 0,
            fontSize: props?.user?.nickname ? 24 : 14,
          }}
        >
          {props?.user?.nickname || t('myDeals.rating.nullNickname')}
        </NormalText>
        <NormalText style={{ marginLeft: props?.user?.nickname ? 8 : 0 }}>
          {t('myDeals.rating.joined') +
            ' ' +
            props?.user?.created_at.split('T')[0]}
        </NormalText>
      </UserBox>
      <RatingBox>
        <NormalText>{t('myDeals.rating.dealsNum')}</NormalText>
        <CenterBox className={'flex-column'}>
          <LargeText>{props?.user?.p2p_total_orders_amount}</LargeText>
          <CenterBox className={'flex-row'}>
            <RoundShape color="#58BF92" />
            <SmallText>
              {t('myDeals.rating.success') +
                ' ' +
                props?.user?.p2p_success_orders_amount}
            </SmallText>
            <SmallText style={{ margin: '0 4px' }}>/</SmallText>
            <RoundShape color="#F16063" />
            <SmallText>
              {t('myDeals.rating.canceled') +
                ' ' +
                props?.user?.p2p_canceled_orders_amount}
            </SmallText>
          </CenterBox>
        </CenterBox>
      </RatingBox>
      <RatingBox>
        <NormalText>{t('myDeals.rating.percentage')}</NormalText>
        <LargeText>
          {props?.user?.p2p_total_orders_amount !== 0
            ? (
                (props?.user?.p2p_success_orders_amount * 100) /
                props?.user?.p2p_total_orders_amount
              ).toFixed(2) + '%'
            : '0.00%'}
        </LargeText>
      </RatingBox>
      <RatingBox>
        <NormalText>{t('myDeals.rating.agents')}</NormalText>
        <LargeText>{props?.user?.p2p_unique_agents_amount}</LargeText>
      </RatingBox>
    </MainBox>
  );
};

const Box = css`
  background-color: #1c1c27;
  border-radius: 8px;
  border: 1px solid #474755;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const Text = css`
  margin: 0;
  line-height: normal;
  font-weight: 500;
`;

const MainBox = styled.div`
  gap: 24px;
  height: 110px;
`;
const CenterBox = styled.div`
  align-items: center;
  gap: 4px;
`;
const UserBox = styled.div<{ nickname?: string }>`
  ${Box}
  justify-content: ${(props) => (props.nickname ? 'center' : 'space-between')};
  gap: 4px;
  padding-bottom: ${(props) => (props.nickname ? '16px' : '34px')};
  align-items: ${(props) => (props.nickname ? 'left' : 'center')};
`;
const RatingBox = styled.div`
  ${Box}
  align-items: center;
  gap: 16px;
`;
const RoundShape = styled.span<{ color: string }>`
  background: ${(props) => props.color};
  border-radius: 50%;
  height: 6px;
  width: 6px;
  display: inline-block;
`;
const SmallText = styled(Typography.Text)`
  ${Text}
  font-size: 12px;
`;
const NormalText = styled(Typography.Text)`
  ${Text}
`;
const LargeText = styled(Typography.Text)`
  ${Text}
  font-size: 24px;
`;

export { UserCards, RoundShape, SmallText, CenterBox };
