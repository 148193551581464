import React from 'react';
import { ReactComponent as CloseIcon } from '../../shared/components/ui/assets/svg/modal-close-icon.svg';
import Output from './index';
import { useTranslation } from 'react-i18next';

const OnCanceled = () => {
  const { t } = useTranslation();

  return (
    <>
      <Output.FullscreenRingWithTextAndIcon
        centerIcon={CloseIcon}
        text={t('outputOrder.withdrawCanceled')}
      />
    </>
  );
};

export default OnCanceled;
