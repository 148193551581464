import React, {FC} from 'react';
import {GetListResponse, HttpError, LogicalFilter, QueryObserverResult,} from '@pankod/refine-core';
import {IGetListData} from '../cards/myTrades.p';
import CustomSelect from '../../../../shared/components/ui/select';
import {Typography} from 'antd';
import {Badge} from '@pankod/refine-antd';
import MyDealsDateFilter from '../filters/myDealsDateFilter';
import {RangeValue} from '../../../../features/myOrders/myOrders.p';
import {IFilterDeals, initFilter} from '../index';
import * as _ from 'lodash';
import {ICurrencyTemp} from '../../dashboard/interface';
import {
    AllCoin,
    BitcoinIconTemp,
    BnbCoinIcon,
    EtherCoinIcon,
    LtcCoinIcon,
    MaticCoinIcon,
    UsdcCoinIcon,
    UsdtCoinIcon,
} from '../../../../shared/components/icons';
import {useTranslation} from 'react-i18next';
import {getFullNameCoin} from '../../../../shared/helpers/getFullNameCoin';
import styled from "styled-components";
import dayjs from "dayjs";

interface IDealsData {
    data: QueryObserverResult<GetListResponse<IGetListData>, HttpError>;
    currency: QueryObserverResult<GetListResponse<ICurrencyTemp>, HttpError>;
    type?: 'buy' | 'sell' | 'all';
    deals?: IGetListData[];
    filter: IFilterDeals;
    status: string;
    coin: string;
    setCoin: (value: string) => void;
    setStatus: (value: string) => void;
    dates: RangeValue;
    setDates: (value: RangeValue) => void;

    setFilter(value: IFilterDeals): void;
}

const Deals: FC<IDealsData> = ({
                                   filter,
                                   setFilter,
                                   currency,
                                   status,
                                   setStatus,
                                   coin,
                                   setCoin,
                                   dates,
                                   setDates
                               }) => {
    const {t} = useTranslation();
    const currentDate = null;
    const startMonthDate = null;


    const setFilterDate = (filterDate: LogicalFilter | null) => {
        const date = filterDate
        let date0;
        let date1;
        if (date === null) {
            setFilter(_.omit(filter, 'date'));
        } else {
            if (date?.value[0] !== null) {
                date0 = dayjs(date?.value[0]).startOf('day');
            }
            if (date?.value[1] !== null) {
                date1 = dayjs(date?.value[0]).startOf('day');
            }
            setFilter({
                ...filter,
                // @ts-ignore
                date: {
                    ...filterDate,
                    value: [date0, date1],
                },
            });
        }
    };

    console.log(filter, 'FILTER')

    const handleChangeStatus = (value: string) => {
        setStatus(value);
        if (value === 'all') {
            setFilter(_.omit(filter, 'status'));
        } else {
            setFilter({
                ...filter,
                status: {
                    field: 'status',
                    operator: 'eq',
                    value,
                },
            });
        }
    };
    const handleChangeCoin = (value: string) => {
        setCoin(value);
        if (value === 'all') {
            setFilter(_.omit(filter, 'coin'));
        } else {
            setFilter({
                ...filter,
                coin: {
                    field: 'ccy',
                    operator: 'eq',
                    value,
                },
            });
        }
    };

    const handleClearFilter = () => {
        setCoin('all');
        setStatus('all');
        setDates([null, null]);
        if (filter.deal) {
            const newInitFilter = {
                deal: filter.deal,
                id: filter.id
            }
            setFilter(newInitFilter)
        } else {
            setFilter(initFilter)
        }
    };

    const coinIcon = (value: string) => {
        switch (value) {
            case 'ETH':
                return EtherCoinIcon('#FBFBFF', 24);
            case 'BTC':
                return BitcoinIconTemp('#FBFBFF', 24);
            case 'USDT':
                return UsdtCoinIcon('#FBFBFF', 24);
            case 'BNB':
                return BnbCoinIcon('#FBFBFF', 24);
            case 'MATIC':
                return MaticCoinIcon('#FBFBFF', 24);
            case 'USDC':
                return UsdcCoinIcon('#FBFBFF', 24);
            case 'LTC':
                return LtcCoinIcon('#FBFBFF', 24);
        }
    };

    const countForFilter = () => {
        return filter.deal ? Object.keys(filter).length - 2 : Object.keys(filter).length;
    };

    return (
        <Wrapper>
            <div style={{width: '260px'}}>
                <CustomSelect
                    colorBgContainer={'#2B2B36'}
                    style={{width: '260px'}}
                    value={coin}
                    setValue={handleChangeCoin}
                    dropdownRender={(menu) => (
                        <div className={'my-deals-dd'}>{menu}</div>
                    )}
                    data={[
                        {
                            label: (
                                <div
                                    style={{
                                        fontSize: 14,
                                        fontWeight: '400',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '32px',
                                        }}
                                    >
                                        {AllCoin('#FBFBFF', 24)}
                                    </div>
                                    <p style={{margin: 0}}>{t('deals.allCoins')}</p>
                                </div>
                            ),
                            value: 'all',
                        },
                        ...(currency.data?.data.map((item) => ({
                            label: (
                                <div
                                    style={{
                                        fontSize: 14,
                                        fontWeight: '400',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px',
                                        width: '100%',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            height: '32px',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {coinIcon(item.currency)}
                                    </div>
                                    <div>
                                        <p style={{margin: 0}}>
                                            {getFullNameCoin(item.currency.toLowerCase())}
                                        </p>
                                        <p
                                            style={{
                                                margin: 0,
                                                color: '#767687',
                                            }}
                                            className={'deals-select-desc'}
                                        >
                                            {item.currency}
                                        </p>
                                    </div>
                                </div>
                            ),
                            value: item.currency,
                        })) ?? []),
                    ]}
                />
            </div>
            <div style={{width: '260px'}}>
                <CustomSelect
                    style={{width: '260px'}}
                    colorBgContainer={'#2B2B36'}
                    value={status}
                    setValue={handleChangeStatus}
                    data={[
                        {
                            label: t('myDeals.filters.status.all'),
                            value: 'all',
                        },
                        {
                            label: t('myDeals.filters.status.respond'),
                            value: 'respond',
                        },
                        {
                            label: t('myDeals.filters.status.respond_accepted'),
                            value: 'respond_accepted',
                        },
                        {
                            label: t('myDeals.filters.status.refused'),
                            value: 'refused',
                        },
                        {
                            label: t('myDeals.filters.status.fiat_transferred'),
                            value: 'fiat_transferred',
                        },
                        {
                            label: t('myDeals.filters.status.success'),
                            value: 'success',
                        },
                        {
                            label: t('myDeals.filters.status.fiat_transferred_no_2fa'),
                            value: 'appealed',
                        },
                    ]}
                />
            </div>
            <MyDealsDateFilter
                startDate={startMonthDate}
                endDate={currentDate}
                setFilter={setFilterDate}
                dates={dates}
                setDates={setDates}
            />
            <div
                style={{
                    display: 'flex',
                    marginLeft: 'auto',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        flex: 1.5,
                        textAlign: 'end',
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                    }}
                >
                    <Typography.Link
                        onClick={handleClearFilter}
                        style={{marginRight: '8px'}}
                    >
                        {t('myDeals.button.clearFilter')}
                    </Typography.Link>
                    {countForFilter() === 0
                        ? <></>
                        : <Badge
                            count={countForFilter()}
                            showZero
                            color="linear-gradient(75.86deg, #088473 6.84%, #0EC6AD 107.11%)"
                        />
                    }
                </div>
            </div>
        </Wrapper>
    );
};

export default Deals;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-top: 24px;
    margin-bottom: 16px;
`;
