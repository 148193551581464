import React, { useState } from 'react';
import { NavLink, useNavigate } from '@pankod/refine-react-router-v6';
import Icon from '@ant-design/icons';
import { Typography } from '@pankod/refine-antd';
import { ArrowDropdownIcon } from '../../icons';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import styled from 'styled-components';

interface IDropdownRow {
  key: string;
  isOutside: boolean;
  link: any;
  iconComp: () => JSX.Element;
  text: string;
  onClickAction?: () => void;
  disabled?: boolean;
}

const DropdownRowWithHoverIcon = ({
  isOutside,
  link,
  iconComp,
  text,
  key,
  onClickAction,
  disabled = false,
}: IDropdownRow): ItemType => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  function addLinks(link: string, key: string) {
    if (isOutside) {
      return (
        <a href={link} target="_blank" rel="noreferrer">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              gap: 8,
            }}
          >
            <Icon component={iconComp} />
            <TypographyTextStyled $disabled={disabled}>
              {text}
            </TypographyTextStyled>
          </div>
        </a>
      );
    } else {
      return (
        <NavLink to={link}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 8,
            }}
          >
            <Icon component={iconComp} />
            <TypographyTextStyled $disabled={disabled}>
              {text}
            </TypographyTextStyled>
          </div>
        </NavLink>
      );
    }
  }

  return {
    key,
    disabled: disabled,
    onClick: () => {
      if (onClickAction) {
        onClickAction();
      }
      if (link) {
        if (isOutside) {
          window.open(link);
        } else {
          navigate(link);
        }
      }
    },
    label: (
      <>
        <div
          className={'flex-row gap-8 justify-content-space-between'}
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'linear-gradient(#18181F, #2D2D3A)',
          }}
        >
          {addLinks(link, key)}
          {isHovered && <Icon component={() => ArrowDropdownIcon()} />}
        </div>
      </>
    ),
    onMouseLeave: () => setIsHovered(false),
    onMouseEnter: () => setIsHovered(true),
  };
};

export default DropdownRowWithHoverIcon;

const TypographyTextStyled = styled(Typography.Text)<{ $disabled: boolean }>`
  color: ${({ $disabled }) =>
    $disabled ? 'rgba(71, 71, 85, 1)' : '#rgba(251, 251, 255, 1)'};
}
`;
