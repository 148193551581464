import React, {useEffect, useState} from 'react';
import {useTranslate} from '@pankod/refine-core';
import Icon from '@ant-design/icons';
import {QRIcon, QuestionSquare} from '../../shared/components/icons';
import Button from '../../shared/components/ui/button';
import {ButtonsTypes} from '../../shared/components/ui/button/filledButton.p';
import {IExchangeOrderContent} from '../../widgets/order/types';
import {OrderStatusEnum} from '../../features/order/order.p';
import {Form as AntForm, Typography} from 'antd';
import styled from 'styled-components';
import Timer from '../../shared/components/ui/timer';
import Drawer from '../../shared/components/ui/Drawer';
import {Form, Input, QRCode} from '@pankod/refine-antd';
import {ReactComponent as CopyIcon} from '../../shared/components/ui/assets/svg/copyIcon.svg';
import {CopyCustomIcon, TradeChangeIcon,} from '../../shared/components/ui/assets/svg/infoIcons';
import TwoRowCoinPriceText from '../../shared/components/ui/two-row-coin-price-text';
import AdaptiveDotStepper from '../../shared/components/ui/stepper/adaptiveDotStepper';
import {useTranslation} from "react-i18next";

// const CustomDotStepper = styled(DotStepper)`
//   &
//   .ant-steps-item-finish
//   .ant-steps-item-icon
//   > .ant-steps-icon
//   .ant-steps-icon-dot {
//     background: linear-gradient(
//       270.4deg,
//       #6e27f8 -85.22%,
//       #20d9c0 164.99%
//     ) !important;
//   }
//
//   .ant-steps
//   .ant-steps-item-process
//   .ant-steps-item-icon
//   > .ant-steps-icon
//   .ant-steps-icon-dot {
//     background: linear-gradient(
//       270.4deg,
//       #6e27f8 -85.22%,
//       #20d9c0 164.99%
//     ) !important;
//   }
// `;

const ExchangeOrderMobile: React.FC<IExchangeOrderContent> = ({
                                                                data,
                                                                currentPair,
                                                                updateOrder,
                                                                orderStatus,
                                                                minimalTradeAmount,
                                                              }) => {
  const {
    created_at,
    id,
    input_sum,
    input_price,
    input_currency,
    output_currency,
    balance,
    recipient_wallet,
    order_wallet,
  } = data;

  const [form] = AntForm.useForm();
  const {t} = useTranslation();

  const [currentStep, setCurrentStep] = useState(1);
  const [openDrawerInput, setOpenDrawerInput] = useState(false);
  const [openDrawerRefund, setOpenDrawerRefund] = useState(false);
  const [openDrawerCancel, setOpenDrawerCancel] = useState(false);

  const [refundAddress] = useState('');

  const calcValue = currentPair
    ? +input_sum * +currentPair.price
    : +input_price;

  const translate = useTranslate();
  const tenMin = new Date(0).setHours(13);
  const expDate = +new Date(created_at) + tenMin;

  const timeToCloseOrder =
    +new Date(expDate - +Date.now()) < 0
      ? new Date(0)
      : new Date(expDate - +Date.now());

  // update order status on timer finish based on current order status
  const onTimerFinish = () => {
    switch (orderStatus) {
      case OrderStatusEnum.NEW:
      case OrderStatusEnum.WALLET_CREATED:
        updateOrder({orderId: id, status: 'canceled'});
        break;
      case OrderStatusEnum.WALLET_REPLENISHED:
        updateOrder({orderId: id, status: 'canceled_replenished'});
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    switch (orderStatus) {
      case OrderStatusEnum.NEW:
      case OrderStatusEnum.WALLET_CREATED:
        setCurrentStep(1);
        break;
      case OrderStatusEnum.CONFIRMATION_WAITING:
        setCurrentStep(2);
        break;
      case OrderStatusEnum.CONFIRMATION_COMPLETED:
      case OrderStatusEnum.TRANSACTION_WAITING:
      case OrderStatusEnum.EXCHANGE_PROCESSING:
      case OrderStatusEnum.WITHDRAW_WAITING:
        setCurrentStep(3);
        break;
      case OrderStatusEnum.EXECUTED:
        setCurrentStep(4);
        break;
      case OrderStatusEnum.CANCELED:
      case OrderStatusEnum.CANCELED_REPLENISHED:
      case OrderStatusEnum.CANCELED_REPLENISHED_EXECUTED:
        setCurrentStep(-1);
        break;
    }
  }, [orderStatus]);

  const isTimerDisabled =
    orderStatus === OrderStatusEnum.CANCELED ||
    orderStatus === OrderStatusEnum.CANCELED_REPLENISHED ||
    orderStatus === OrderStatusEnum.CANCELED_REPLENISHED_EXECUTED;
  const handleCopy = async () => {
    try {
      if (currentStep >= 3) {
        return;
      }
      await navigator.clipboard.writeText(order_wallet);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={'flex-column gap-24'}>
      <Typography.Text
        className={'bold-24px-text'}
      >{`${t('cryptoExchange.order.title')} №${id}`}</Typography.Text>
      <div className={'flex-column gap-16'}>
        <AdaptiveDotStepper
          currentStep={1}
          items={[
            {
              text: t('cryptoExchange.getCurrency'),
              width: 70,
            },
            {
              text: t('cryptoExchange.submitNetwork'),
              width: 90,
              marginLeft: -25,
            },
            {
              text: t('cryptoExchange.doingExchange'),
              width: 80,
              marginLeft: -5,
            },
            {
              text: t('cryptoExchange.doingExchange'),
              width: 50,
            },
          ]}
        />

        <TradeContainer>
          <div>
            <div
              className={
                'flex-row justify-content-space-between align-items-center'
              }
            >
              <TwoRowCoinPriceText
                coinName={input_currency}
                amount={input_sum}
              />
              <div className={'trade-p-4'}>
                <Icon component={() => TradeChangeIcon({})}/>
              </div>
              <TwoRowCoinPriceText
                coinName={output_currency}
                amount={input_price}
              />
            </div>
          </div>
        </TradeContainer>

        <TimerContainer
          $isTimerDisabled={isTimerDisabled}
          $currentStep={currentStep}
          className={'flex justify-content-space-between gap-16'}
        >
          <div>
            <div>
              <Typography.Text
                className={`flex-column justify-content-center ${
                  isTimerDisabled ? 'timer-disabled' : ''
                }`}
              >
                {isTimerDisabled ? (
                  '00:00:00'
                ) : (
                  <>
                    {+timeToCloseOrder / 1000 > 0 ? (
                      <Timer
                        initSeconds={+timeToCloseOrder / 1000}
                        handleFinish={() => onTimerFinish()}
                        digits={7}
                      />
                    ) : (
                      '00:00:00'
                    )}
                  </>
                )}
              </Typography.Text>
            </div>
          </div>
          <div
            onClick={() => {
              if (!isTimerDisabled) {
                setOpenDrawerInput(true);
              }
            }}
          >
            <div>{t('cryptoExchange.inputAddress')}</div>
            <Icon component={QRIcon}/>
          </div>
        </TimerContainer>
        <BalanceContainer>
          <div>
            <div>{t('cryptoExchange.balance')}</div>
            <div>{`${Number(balance)?.toFixed(9)} ${input_currency}`}</div>
          </div>
          <div>
            <div>{t('cryptoExchange.commission')}</div>
            <div>
              <Icon component={QuestionSquare}/>
              <div>
                {`${
                  currentPair?.maker_commission
                    ? +currentPair.maker_commission / +input_price
                    : '0'
                } ${input_currency}`}
              </div>
            </div>
          </div>
          <CourseContainerStyled>
            <CourseTitleContainerStyled>
              {t('cryptoExchange.changer.rightCard.bottomText')}
            </CourseTitleContainerStyled>
            <CourseSecondContainerStyled>
              <CourseSecondTitleContainerStyled>
                {`1 ${input_currency} = ${currentPair?.price} ${output_currency}`}
              </CourseSecondTitleContainerStyled>
            </CourseSecondContainerStyled>
          </CourseContainerStyled>
          <CourseContainerStyled>
            <ContainerTitleMinCountStyled>
              {t('cryptoExchange.changer.leftCard.bottomText')}
            </ContainerTitleMinCountStyled>
            <MinimalTradeAmountStyled>
              {`${minimalTradeAmount} ${input_currency}`}
            </MinimalTradeAmountStyled>
          </CourseContainerStyled>
          <ContainerAddressStyled>
            <ContainerTitleMinCountStyled>
              {t('cryptoExchange.outputAddress')}
            </ContainerTitleMinCountStyled>
            <MinimalTradeAmountStyled>
              {recipient_wallet}
            </MinimalTradeAmountStyled>
          </ContainerAddressStyled>
        </BalanceContainer>
        <div className={'flex justify-content-space-between gap-16'}>
          <Button
            width={'60%'}
            onClick={() => {
              switch (orderStatus) {
                case OrderStatusEnum.CANCELED_REPLENISHED:
                case OrderStatusEnum.WALLET_REPLENISHED:
                  return updateOrder({
                    orderId: id,
                    status: 'exchange_waiting',
                    inputValue: input_price,
                    outputValue: calcValue + '',
                  });

                default:
                  return;
              }
            }}
            disabled={balance === 0 || currentStep !== 2}
          >
            {t('cryptoExchange.order.centerCard.leftButton.firstState')}
          </Button>
          <Button
            width={'40%'}
            onClick={() => {
              setOpenDrawerCancel(true);
            }}
            type={ButtonsTypes.outlined}
          >
            {t('cryptoExchange.order.centerCard.rightButton')}
          </Button>
        </div>
      </div>
      <Drawer
        open={openDrawerInput}
        onClose={() => setOpenDrawerInput(false)}
        width={'100%'}
        title={
          <>
            <Typography.Text className={'bold-24px-text'}>
              {t('cryptoExchange.outputAddressCurrency')}
            </Typography.Text>
          </>
        }
      >
        <>
          <div className={'flex-column gap-24'}>
            <div
              className={
                'flex-column gap-32 justify-content-center align-items-center'
              }
            >
              <Typography.Text
                className={'text-16px'}
                style={{
                  fontWeight: 500,
                }}
              >
                {t('cryptoExchange.order.rightCard.centerText')}
              </Typography.Text>
              <QRCode value={order_wallet} size={172} bordered={false}/>
              <div className="exchange-transfer-card-wallet-address-btn-wrapper justify-content-center">
                <CopyButton
                  onClick={handleCopy}
                  className="wallet-address-copy-btn"
                  icon={<CopyIcon/>}
                >
                  {order_wallet}
                </CopyButton>
              </div>
            </div>
            <div>
              <BalanceContainer>
                <div>
                  <div>{t('cryptoExchange.balance')}</div>
                  <div>{`${Number(balance)?.toFixed(9)} ${input_currency}`}</div>
                </div>
                <div>
                  <div>{t('cryptoExchange.commission')}</div>
                  <div>
                    <Icon component={QuestionSquare}/>
                    <div>
                      {`${
                        currentPair?.maker_commission
                          ? +currentPair.maker_commission / +input_price
                          : '0'
                      } ${input_currency}`}
                    </div>
                  </div>
                </div>
                <CourseContainerStyled>
                  <CourseTitleContainerStyled>
                    {t('cryptoExchange.changer.rightCard.bottomText')}
                  </CourseTitleContainerStyled>
                  <CourseSecondContainerStyled>
                    <CourseSecondTitleContainerStyled>
                      {`1 ${input_currency} = ${currentPair?.price} ${output_currency}`}
                    </CourseSecondTitleContainerStyled>
                  </CourseSecondContainerStyled>
                </CourseContainerStyled>
                <CourseContainerStyled>
                  <ContainerTitleMinCountStyled>
                    {t('cryptoExchange.changer.leftCard.bottomText')}
                  </ContainerTitleMinCountStyled>
                  <MinimalTradeAmountStyled>
                    {`${minimalTradeAmount} ${input_currency}`}
                  </MinimalTradeAmountStyled>
                </CourseContainerStyled>
                <ContainerAddressStyled>
                  <TypographyStyled>
                    {t('wallet.coin.deposit.modalDescription')}
                  </TypographyStyled>
                </ContainerAddressStyled>
              </BalanceContainer>
            </div>
          </div>
        </>
      </Drawer>
      <DrawerStyled
        open={openDrawerRefund}
        placement={'bottom'}
        height={'60%'}
        title={
          <>
            <Typography.Text className={'bold-24px-text'}>
              {t('myOrders.filters.status.refund')}
            </Typography.Text>
          </>
        }
      >
        <>
          <div className={'flex-column gap-32'}>
            <div className={'flex-column gap-24'}>
              <Form
                form={form}
                fields={[
                  {
                    name: 'walletAddress',
                    value: '',
                  },
                ]}
                requiredMark={false}
              >
                <div className={'flex-column gap-32'}>
                  <Form.Item
                    requiredMark={false}
                    name={'walletAddress'}
                    label={t('cryptoExchange.order.placeholderAddress')}
                    rules={[
                      {
                        required: true,
                        message: translate(
                          'cryptoExchange.changer.formData.enterWithdrawalWalletAddress'
                        ),
                      },
                      {
                        pattern: /^[a-zA-Z\d_-]+$/,
                        message: translate(
                          'cryptoExchange.changer.formData.enterCorrectWithdrawalWalletAddress'
                        ),
                      },
                      {
                        min: 25,
                        max: 44,
                        message: translate(
                          'cryptoExchange.changer.formData.enterCorrectWithdrawalWalletAddress'
                        ),
                      },
                    ]}
                  >
                    <Input value={refundAddress} placeholder={t('cryptoExchange.order.address') ?? ''}/>
                  </Form.Item>
                  <FormItemStyled>
                    <Typography.Text className={'text-16px'}>
                      {t('cryptoExchange.helperTextCancelOrder')}
                    </Typography.Text>
                  </FormItemStyled>
                </div>
              </Form>
              <div>
                <Typography.Text>
                  {t('cryptoExchange.formData.helperText.networkCom')}:{' '}
                  {`${currentPair?.maker_commission} ${input_currency}`}
                </Typography.Text>
              </div>
            </div>
            <div className={'flex-row gap-16'}>
              <div style={{width: '60%'}}>
                <Button
                  height={'48px'}
                  onClick={() => {
                    form.validateFields().then();
                  }}
                >
                  {t('cryptoExchange.order.centerCard.leftButton.secondState')}
                </Button>
              </div>
              <div style={{width: '40%'}}>
                <Button
                  height={'48px'}
                  onClick={() => {
                    setOpenDrawerRefund(false);
                  }}
                  type={ButtonsTypes.outlined}
                >
                  {t('buttons.back')}
                </Button>
              </div>
            </div>
          </div>
        </>
      </DrawerStyled>
      <DrawerStyled
        open={openDrawerCancel}
        placement={'bottom'}
        height={'45%'}
        title={
          <>
            <Typography.Text className={'bold-24px-text'}>
              {t('cryptoExchange.cancelOrder')}
            </Typography.Text>
          </>
        }
      >
        <>
          <div className={'flex-column gap-32'}>
            <div className={'flex-column gap-24'}>
              <OrderStyled className={'flex-column gap-16'}>
                <div className={'flex-row gap-8'}>
                  <Icon component={() => CopyCustomIcon({})}/>
                  <Typography.Text className={'bold-16px-text'}>
                    {`${t('cryptoExchange.deal')} №${id}`}
                  </Typography.Text>
                </div>
                <Typography.Text className={'text-16px'}>
                  {t('cryptoExchange.textCancelOrder')}
                </Typography.Text>
              </OrderStyled>
              <div>
                <Typography.Text>
                  {t('cryptoExchange.formData.helperText.networkCom')}:{' '}
                  <span>{`${currentPair?.maker_commission} ${input_currency}`}</span>
                </Typography.Text>
              </div>
            </div>
            <div className={'flex-row gap-16'}>
              <div style={{width: '60%'}}>
                <Button
                  height={'48px'}
                  onClick={() => {
                    if (
                      orderStatus !== OrderStatusEnum.NEW &&
                      orderStatus !== OrderStatusEnum.WALLET_CREATED
                    ) {
                      updateOrder({
                        orderId: id,
                        status: 'canceled_replenished',
                      });
                    } else {
                      updateOrder({orderId: id, status: 'canceled'});
                    }
                  }}
                >
                  {t('p2p.getCurrentStep.cancelDeal')}
                </Button>
              </div>
              <div style={{width: '40%'}}>
                <Button
                  height={'48px'}
                  onClick={() => {
                    setOpenDrawerCancel(false);
                  }}
                  type={ButtonsTypes.outlined}
                >
                  {t('buttons.back')}
                </Button>
              </div>
            </div>
          </div>
        </>
      </DrawerStyled>
    </div>
  );
};

const CopyButton = styled(Button)`
  background: linear-gradient(75.86deg,
  #6320e6 6.84%,
  #7b59fd 99.53%) !important;
`;

const TradeContainer = styled.div`
  border-radius: 8px;
  border: 1px solid rgba(65, 61, 80, 0.5);
  padding: 24px 16px;
  background: #1c1c27;

  & > div > div > .trade-p-4 {
    padding: 4px;
  }
`;

const TimerContainer = styled.div<{
  $isTimerDisabled: boolean;
  $currentStep: number;
}>`
  & > div:nth-child(1) {
    padding: 0 0;
    background: ${(props) =>
      props.$isTimerDisabled
        ? '#767687'
        : 'linear-gradient(270deg, #6E27F8 0%, #20D9C0 100%)'};
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: inline-flex;
    width: 100%;
    height: 48px;

    & > div {
      color: #FBFBFF;
      font-size: 14px;

      & > span {
        color: ${(props) => (props.$isTimerDisabled ? '#C3C3CE' : 'initial')}
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  & > div:nth-child(2) {
    padding: 16px 0;
    width: 100%;
    background: #1c1c27;
    border-radius: 6px;
    border-left: 1px rgba(64.69, 60.76, 79.69, 0.5) solid;
    border-top: 1px rgba(64.69, 60.76, 79.69, 0.5) solid;
    border-right: 1px rgba(64.69, 60.76, 79.69, 0.5) solid;
    border-bottom: 1px rgba(64.69, 60.76, 79.69, 0.5) solid;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: inline-flex;
    height: 48px;

    & > div {
      color: ${(props) =>
        props.$isTimerDisabled || props.$currentStep <= 2
          ? '#767687'
          : '#FBFBFF'};
      font-size: 14px;
      font-family: 'Inter', sans-serif;
      font-weight: 700;
      word-wrap: break-word;
    }
  }
`;

const BalanceContainer = styled.div`
  width: 100%;
  background: #1c1c27;
  border-radius: 8px;
  border-left: 1px rgba(64.69, 60.76, 79.69, 0.5) solid;
  border-top: 1px rgba(64.69, 60.76, 79.69, 0.5) solid;
  border-right: 1px rgba(64.69, 60.76, 79.69, 0.5) solid;
  border-bottom: 1px rgba(64.69, 60.76, 79.69, 0.5) solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
  padding: 24px 16px 16px;
  margin-bottom: 24px;

  & > div:nth-child(1) {
    align-self: stretch;
    height: 32px;
    padding-bottom: 12px;
    border-bottom: 1px rgba(64.69, 60.76, 79.69, 0.5) solid;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: inline-flex;

    & > div:nth-child(1) {
      text-align: center;
      color: #fbfbff;
      font-size: 14px;
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      word-wrap: break-word;
    }

    & > div:nth-child(2) {
      flex: 1 1 0;
      text-align: right;
      color: #fbfbff;
      font-size: 14px;
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      word-wrap: break-word;
    }
  }

  & > div:nth-child(2) {
    align-self: stretch;
    padding-bottom: 12px;
    border-bottom: 1px rgba(64.69, 60.76, 79.69, 0.5) solid;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: inline-flex;

    & > div:nth-child(1) {
      flex: 1 1 0;
      color: #fbfbff;
      font-size: 14px;
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      word-wrap: break-word;
    }

    & > div:nth-child(2) {
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      display: flex;

      & > div {
        text-align: right;
        color: #fbfbff;
        font-size: 14px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        word-wrap: break-word;
      }
    }
  }
`;

const CourseContainerStyled = styled.div`
  align-self: stretch;
  padding-bottom: 12px;
  border-bottom: 1px rgba(64.69, 60.76, 79.69, 0.5) solid;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: inline-flex;
`;

const CourseTitleContainerStyled = styled.div`
  flex: 1 1 0;
  color: #fbfbff;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  word-wrap: break-word;
`;

const CourseSecondContainerStyled = styled.div`
  justify-content: flex-end;
  display: flex;
  gap: 8px;
  align-items: center;
`;

const CourseSecondTitleContainerStyled = styled.div`
  color: #fbfbff;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  word-wrap: break-word;
`;

const ContainerTitleMinCountStyled = styled.div`
  text-align: center;
  color: #fbfbff;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  word-wrap: break-word;
`;

const MinimalTradeAmountStyled = styled.div`
  flex: 1 1 0;
  color: #fbfbff;
  font-size: 14px;
  text-align: right;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  word-wrap: break-word;
`;

const ContainerAddressStyled = styled.div`
  align-self: stretch;
  padding-bottom: 8px;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: inline-flex;
`;

const TypographyStyled = styled(Typography.Text)`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  white-space: pre-line;
`;

const DrawerStyled = styled(Drawer)`
  border-top: 2px solid #413d5080;
  border-radius: 24px 24px 0 0;
`;

const FormItemStyled = styled(Form.Item)`
  padding-bottom: 16px;
  font-weight: 400;
  border-bottom: 1px solid rgba(65, 61, 80, 0.5);
`;

const OrderStyled = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(65, 61, 80, 0.5);
`;

export default ExchangeOrderMobile;
