import * as React from 'react';
import { Space, Spin } from '@pankod/refine-antd';

type Props = {
  //
};

export function FullScreenLoader(props: Props) {
  return (
    <Space
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
        backgroundColor: '#14141F'
      }}
    >
      <Spin />
    </Space>
  );
}

export const AdaptiveFullScreenLoader = () => {
  return (
    <Space
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        backgroundColor: '#14141F'
      }}
    >
      <Spin />
    </Space>
  );
};
