import {IPaymentsType} from "../../../../../pages/p2p/dashboard/interface";
import {PaymentTypeEnum} from "../../../../p2p/createOrder/types";

export const GetIcon = (id: number, iconsData: IPaymentsType[] = []) => {
  return `data:image/svg+xml;base64,${iconsData
    .find(item => item.payment_type === PaymentTypeEnum.bankCardPayment)
    ?.items
    ?.find(payment => payment.id === id)
    ?.icon}`;
};

