import {TableProps} from '@pankod/refine-antd';
import {BaseRecord} from '@pankod/refine-core';
import Card from './ui/card/Card';
import './ui/style.css';
import {P2PAnnouncement} from './types';
import styled from 'styled-components';

type Props = {
  tableProps?: TableProps<BaseRecord>;
};

const Announcements = (props: Props) => {
  return (
    <Wrap>
      {props.tableProps?.dataSource?.map((item) => (
        <Card key={item.id} announcement={item as P2PAnnouncement}/>
      ))}
    </Wrap>
  );
};
const Wrap = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`
export default Announcements;
