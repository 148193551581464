import {Typography} from "antd";
import Timer from '../../../shared/components/ui/timer';
import styled from "styled-components";
import {IGetListData} from '../../../pages/p2p/myDeals/cards/myTrades.p';

type TTimer = {
  currentOrder: IGetListData;
  handleFinish: (val: boolean) => void;
  isTimerActive: boolean;
  initSeconds: number | undefined;
}

export const OrderTimer = ({currentOrder, handleFinish, isTimerActive, initSeconds}: TTimer) => {

  const timerBg = isTimerActive && currentOrder.status !== 'success'
    ? 'linear-gradient(270.4deg, #6E27F8 -85.22%, #20D9C0 164.99%)'
    : '#767687';

  return (
    <>
      {currentOrder.status !== 'appealed' && (
        <TimerWrapper $background={timerBg}>
          <Typography.Text className={'bold-18px-text'}>
            {isTimerActive && currentOrder.status !== 'success' ? (
              <Timer
                handleFinish={() => {
                  handleFinish(false)
                }}
                initSeconds={initSeconds}
              />
            ) : (
              <>00:00</>
            )}
          </Typography.Text>
        </TimerWrapper>
      )}
    </>
  )
}
type TTimerWrapper = {
  $background: string;
}
const TimerWrapper = styled.div<TTimerWrapper>`
  padding: 13px;
  background: ${({$background}) => $background};
  border-radius: 6px;
`
