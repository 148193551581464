import { ICurrencyOption } from '../../features/cryptocurrencyExchange/cryptoCurrencyExchange.p';

export const getUniqOptionList = <T extends { pair: string }>(list: T[]) => {
  const pairs = list.map((item): ICurrencyOption[] => {
    const pairItem = item.pair?.split('/').map(
      (item): ICurrencyOption => ({
        label: item,
        value: item,
      })
    );
    return pairItem;
  });
  const currencies = pairs.reduce((a, b) => a.concat(b));

  return getUniqList(currencies, 'value');
};

export const getUniqList = <T>(list: T[], uniqKey: keyof T): T[] => {
  const uniqCurrencies: T[] = [];

  for (let i in list) {
    if (!uniqCurrencies.find((item) => item[uniqKey] === list[i][uniqKey])) {
      uniqCurrencies.push(list[i]);
    }
  }

  return uniqCurrencies;
};
