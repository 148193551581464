import React, {useState} from 'react';
import {Typography} from 'antd';
import {IGetListData} from '../../../pages/p2p/myDeals/cards/myTrades.p';
import Modal from '../../../shared/components/ui/modal';
import {IAppealFormValues} from '../../../shared/components/ui/modal/appealModal/components/form/interface';
import {IPaymentInfo} from '../../../pages/p2p/dashboard/interface';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from '@pankod/refine-react-router-v6';
import styled from 'styled-components';
import CurrentP2POrder from "../../../features/p2p/buyOrder/currentP2POrder";

interface IP2PMainBuyContent {
    isSeller: boolean;
    currentOrder: IGetListData;
    currTimerValue: number;
    isTimerActive: boolean;
    currCommission: number;
    isMyAppeal: boolean;
    updateP2POrderStatus: (status: string, useNotification?: boolean) => void;
    handleOnOpenAppealModal: () => void;
    handleOnCloseAppealModal: () => void;
    handleOnOpenAppealCloseModal: () => void;
    handleOnCloseAppealCloseModal: () => void;
    handleOnSuccessAppealModal: (
        data: IAppealFormValues,
        callback: () => void
    ) => void;
    openAppealModal: boolean;
    openAppealCloseModal: boolean;
    paymentInfo: IPaymentInfo | undefined;
    updateP2PAppealStatus: (status: string) => void;
    createAppeal: (status: string) => void;
}

const ActiveTabStyle: React.CSSProperties = {
    borderBottom: '1px solid #DEDEE4',
    paddingBottom: 20,
};
const NonActiveTabStyle: React.CSSProperties = {
    color: '#767687',
};

const P2PMainBuyContent: React.FC<IP2PMainBuyContent> = ({
                                                             isSeller,
                                                             updateP2POrderStatus,
                                                             handleOnCloseAppealModal,
                                                             handleOnSuccessAppealModal,
                                                             openAppealModal,
                                                             openAppealCloseModal,
                                                             handleOnCloseAppealCloseModal,
                                                             handleOnOpenAppealCloseModal,
                                                             ...rest
                                                         }) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    // const params = useParams();

    const [openConfirmModalSeller, setOpenConfirmModalSellerSeller] =
        useState(false);
    const [openConfirmModalBuyer, setOpenConfirmModalBuyer] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [deal, setDeal] = useState<IGetListData[]>([]);
    // const [dealsCount, setDealsCount] = useState(0);
    // const dealData = useList<IGetListData, HttpError>({
    //   dataProviderName: `p2p`,
    //   resource: `order`,
    //   config: {
    //     filters: [{field: 'id', operator: 'eq', value: `${params.id}`}],
    //   },
    //   queryOptions: {
    //     onSuccess: (res) => {
    //       setDeal([...res.data])
    //     },
    //   },
    // });

    const loc = useLocation();
    return (
        <>
            {rest.paymentInfo && (
                <Modal.ConfirmPay
                    paymentInfo={rest.paymentInfo}
                    isOpen={openConfirmModalBuyer}
                    onConfirm={() => {
                        updateP2POrderStatus('fiat_transferred');
                        setOpenConfirmModalBuyer(false);
                    }}
                    closeModal={() => setOpenConfirmModalBuyer(false)}
                />
            )}
            <Modal.ConfirmAcceptPay
                createAppeal={rest.createAppeal}
                isOpen={openConfirmModalSeller}
                closeModal={() => setOpenConfirmModalSellerSeller(false)}
                onConfirm={() => {
                    updateP2POrderStatus('success', true);
                }}
            />
            <Modal.AppealModal
                isSeller={isSeller}
                open={openAppealModal}
                onCancel={handleOnCloseAppealModal}
                onOk={handleOnSuccessAppealModal}
            />
            <Modal.ConfirmCloseAppealModal open={openAppealCloseModal} onCancel={handleOnCloseAppealCloseModal}
                                           onOk={() => {
                                               // TODO: Костыль с NOT allowed status transaction
                                               rest.updateP2PAppealStatus('closed')
                                               handleOnCloseAppealCloseModal()
                                           }}/>
            <div>
                <Container>
                    {!loc.pathname.includes('my-announcements') ? (
                            <>
                                {isSeller ? (
                                    <DealParameters className={'flex-row'}>
                                        <Typography.Text
                                            className={'bold-18px-text cursor-pointer'}
                                            style={activeTab === 0 ? ActiveTabStyle : NonActiveTabStyle}
                                            onClick={() => {
                                                setActiveTab(0)
                                            }}
                                        >
                                            {t('p2p.buyOrder.dealParameters')}
                                        </Typography.Text>
                                    </DealParameters>
                                ) : (
                                    <TypographyStyled className={'bold-18px-text'}>
                                        {t('p2p.buyOrder.dealParameters')}
                                    </TypographyStyled>
                                )}
                            </>)
                        : (
                            <Row className={'flex-row'}>
                                <Typography.Text
                                    className={'bold-16px-text cursor-pointer'}
                                    style={activeTab === 1 ? ActiveTabStyle : NonActiveTabStyle}
                                    onClick={() => {
                                        navigate(`/p2p/my-announcements/${deal[0].ad_id}`);
                                    }}
                                >
                                    {t('announcements.adParams')}
                                </Typography.Text>
                                <Typography.Text
                                    className={'bold-16px-text cursor-pointer'}
                                    style={activeTab === 0 ? ActiveTabStyle : NonActiveTabStyle}
                                    onClick={() => {
                                        setActiveTab(0);
                                    }}
                                >
                                    {t('announcements.adDeals')}
                                </Typography.Text>
                            </Row>
                        )}
                </Container>
                <P2POrderWrapper className={'flex-column gap-24'}>
                    <CurrentP2POrder
                        adId={deal[0] && deal[0].ad_id}
                        activeTab={activeTab}
                        isSeller={isSeller}
                        openConfirmModalBuyer={() => setOpenConfirmModalBuyer(true)}
                        handleOnOpenAppealCloseModal={handleOnOpenAppealCloseModal}
                        openConfirmModalSellerSeller={() =>
                            setOpenConfirmModalSellerSeller(true)
                        }
                        updateP2POrderStatus={updateP2POrderStatus}
                        {...rest}
                    />
                </P2POrderWrapper>
            </div>
        </>
    );
};

export default P2PMainBuyContent;

const Container = styled.div`
    border-bottom: 1px solid #474755;
    padding-bottom: 20px;
`
const Row = styled.div`
    gap: 40px;
    margin-bottom: -20px;
`

const P2POrderWrapper = styled.div`
    margin-top: 20px;
    background: #1C1C27;
    border-radius: 16px;
    padding: 24px;
`;

const DealParameters = styled.div`
    gap: 40px;
    margin-bottom: -20px;
`;

const TypographyStyled = styled(Typography.Text)`
    border-bottom: 1px solid #DEDEE4;
    padding-bottom: 20px;
`;
