import create from "zustand";
import {axios} from "../../../../shared/exios";
import {API_URL} from "../../../../packages/keycloak-client/constants";

interface IUseAddPaymentState {
  addPayment: (type: string, values: Record<string,string | undefined>) => Promise<void>;
}

const endpoints : Record<string,string> = {
  spb: 'p2p/api/fast-payment-system-payment',
  banks: 'p2p/api/bank-transfer-payment',
  mobiles: 'p2p/api/mobile-balance-payment',
  cashes: 'p2p/api/cash-payment',
  cards: 'p2p/api/bank-card-payment',
  services: 'p2p/api/web-service-payment'
}

export const useAddPaymentState = create<IUseAddPaymentState>((set,get) => ({
  addPayment: async (type,values) => {
    try {
      const response = await axios.post(`${API_URL}/${endpoints[type]}`,values);
    }
    catch (e) {
      console.log(e);
    }
  }
}))
