import s from './index.module.css';
import {Modal} from '@pankod/refine-antd';
import styled from 'styled-components';
import {IUploadAttachment} from '../../../../interfaces/entity';
import {PdfFileBlock} from '../../../form/loadModal/pdfFileBlock';
import {TimeMark} from '../../time-mark';
import {Download} from '../../../../../../shared/components/ui/assets/svg/Download';

interface IModalImage {
    attachment: IUploadAttachment;
    fileDownloadLink: string;
    onClose: () => void;
    open: boolean;
    date: Date;
    senderNickname: string | undefined;
}

const MessageModalFile = ({
                              date,
                              fileDownloadLink,
                              open,
                              onClose,
                              attachment,
                              senderNickname,
                          }: IModalImage) => {
    return (
        <ModalStyled open={open} onCancel={onClose}>
            <div className={s.imgWrapper}>
                {attachment.file_extension === 'pdf' ? (
                    <PdfFileBlock name={attachment.file_name} size={attachment.id}/>
                ) : (
                    <img src={fileDownloadLink} alt="attachment"/>
                )}
            </div>
            <div className={s.img__date}>
                <div>{senderNickname}</div>
                <TimeMark date={date}/>
            </div>
            <LinkStyled
                target={'_blank'}
                href={fileDownloadLink}
                className={s.img__download}
                download={attachment.file_name}
            >
                <Download/>
                <div>Скачать</div>
            </LinkStyled>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
    min-height: 100vh !important;

    & .ant-modal-content {
        height: 100vh !important;
        background: none !important;
    }

    & .ant-modal-body {
        height: 100% !important;
    }

    & .ant-modal-footer {
        display: none !important;
    }

    & .ant-modal-close {
        width: 16px !important;
        height: 16px !important;
        position: absolute !important;
        top: 96px !important;
        right: 80px !important;
        color: white !important;
    }
`;

const LinkStyled = styled.a`
    color: white;
`;

export {MessageModalFile};
