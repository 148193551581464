import React from 'react';
import { InputNumber, Typography } from '@pankod/refine-antd';
import styled from 'styled-components';

type IInputWithSuffix = {
  onChange: (e: any) => void;
  value: number | "";
  bordered: boolean;
  placeholder: string;
  disabled: boolean;
  suffix: string;
  width?: string;
}

export const InputWithSuffix = ({suffix, ...rest}: IInputWithSuffix) => {

  return (
    <Wrapper $width={rest.width ?? '50%'}>
      <CustomInputNumber
        {...rest}
      />
      <CustomTypographyText
        disabled={rest.disabled}
      >
        {suffix}
      </CustomTypographyText>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ $width?: string; }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: ${props => props.$width};
  white-space: nowrap;
  height: 40px;
  padding-right: 16px;
`

const CustomInputNumber = styled(InputNumber)`
  padding: 0 !important;
  width: 96px;
  background-color: #2B2B36;

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number-input-wrap input:focus-visible {
    border: none !important;
  }

  .ant-input-number-input {
    padding-right: 4px !important;
  }
`;

const CustomTypographyText = styled(Typography.Text)`
  color: ${({
              disabled
            }) => {
    return disabled ? '#474755 !important' : ''
  }
  };
  cursor: auto !important;
`
