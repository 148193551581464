import React from 'react';
import {Tabs} from 'antd';
import {ConfigProvider} from '@pankod/refine-antd';
import {useTranslation} from 'react-i18next';
import {
    SpotMyTradeDeals,
    SpotOrderBookList,
    SpotTradeHistory,
} from '../../features/spot';
import {useNavigate} from '@pankod/refine-react-router-v6';
import {ErrorBoundary} from '../../app/ErrorBoundary';
import {useCookiesCustom} from '../../shared/hooks/useCookieCustom';

const SpotOrderBook: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {removeCookie} = useCookiesCustom();

    return (
        <ErrorBoundary
            logout={() => {
            }}
            navigate={navigate}
            removeCookie={removeCookie}
        >
            <ConfigProvider
                theme={{
                    components: {
                        Tabs: {
                            paddingSM: 0,
                            lineWidth: 0,
                            padding: 12,
                            fontSize: 12,
                            colorBgContainer: '',
                            colorInfoActive: '',
                            colorBgTextActive: '',
                            controlItemBgActive: '',
                            margin: 0,
                        },
                        Select: {
                            fontSize: 12,
                        },
                    },
                }}
            >
                <Tabs
                    type={'card'}
                    centered
                    tabBarStyle={{
                        border: 'none',
                        background: 'transparent',
                    }}
                    items={[
                        {
                            key: '0',
                            label: (
                                <div style={{marginLeft: -4}}>
                                    {t('orderBookExpanded.orderBook')}
                                </div>
                            ),
                            children: <SpotOrderBookList/>,
                            style: {paddingLeft: 20, paddingRight: 20},
                        },
                        {
                            key: '1',
                            label: t('orderBook.tradingHistory'),
                            children: <SpotTradeHistory/>,
                            style: {paddingLeft: 20, paddingRight: 20},
                        },
                        {
                            key: '2',
                            label: t('orderBook.myDeals'),
                            children: <SpotMyTradeDeals/>,
                            style: {paddingLeft: 20, paddingRight: 20},
                        },
                    ]}
                />
            </ConfigProvider>
        </ErrorBoundary>
    );
};

export {SpotOrderBook};
