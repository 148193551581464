import create from "zustand";
import {produce} from "immer";

interface IUseTimerState {
  seconds: number;
  setSeconds: (seconds: number) => void;
  decrementSeconds: () => void;
}

export const useTimerState = create<IUseTimerState>((set,get) => ({
  seconds: 0,
  setSeconds: seconds => set({seconds}),
  decrementSeconds: () => {
    set(produce((draft:IUseTimerState) => {
      draft.seconds -= 1
    }))
  }
}))
