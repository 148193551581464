import React, {useCallback, useEffect, useState} from 'react';
import {Button, Typography} from '@pankod/refine-antd';
import ExchangeDataCardList from './exchangeDataCardList';
import {defaultData, IExchangeOrderDataCard, IOrderDataCard, OrderStatusEnum,} from './order.p';
import {useTranslate} from '@pankod/refine-core';
import GradientCard from 'shared/components/ui/gradient-card';
import {getCoinInfoByName} from 'shared/helpers/getCoinInfoByName';
import {ReactComponent as CourseUpIcon} from '../../shared/components/ui/assets/svg/courseUp.svg';
import {ReactComponent as CourseDownIcon} from '../../shared/components/ui/assets/svg/courseDown.svg';
import './index.css';

const PERCENT_AMOUNT_TO_DISPLAY_COURSE_CHANGE_NOTIFICATION = 3;

const getPercentageChange = (oldNumber: number, newNumber: number) => {
  const decreaseValue = oldNumber - newNumber;
  return (decreaseValue / oldNumber) * 100;
};

const ExchangeOrderDataCard: React.FC<IExchangeOrderDataCard> = ({
                                                                   currentOrderState,
                                                                   onFilledButtonClick,
                                                                   onOutlinedButtonClick,
                                                                   orderNumber,
                                                                   inputPrice,
                                                                   inputCurrency,
                                                                   outputCurrency,
                                                                   outputPrice,
                                                                   cryptoCourse,
                                                                   recipientWallet,
                                                                   minimalTradeAmount,
                                                                   ...commissionData
                                                                 }) => {
  const translate = useTranslate();

  const courseChangePercent = getPercentageChange(
    outputPrice / inputPrice,
    cryptoCourse
  );

  const getCardStateByOrderStatus = useCallback(
    (orderStatus: OrderStatusEnum) => {
      let data: IOrderDataCard = defaultData;
      switch (orderStatus) {
        case OrderStatusEnum.WALLET_REPLENISHED: {
          data.filledButton.text = translate(
            'cryptoExchange.order.centerCard.leftButton.firstState'
          );
          data.outlinedButton.disable = false;
          data.outlinedButton.hidden = false;
          data.filledButton.disable = true;

          break;
        }
        case OrderStatusEnum.CANCELED_REPLENISHED:
          data.filledButton.text = translate(
            'cryptoExchange.order.centerCard.leftButton.secondState'
          );
          data.filledButton.disable = false;
          data.outlinedButton.disable = true;
          data.outlinedButton.hidden = true;
          break;
        case OrderStatusEnum.CANCELED_REPLENISHED_EXECUTED:
          data.filledButton.text = translate(
            'cryptoExchange.order.centerCard.leftButton.secondState'
          );
          data.filledButton.disable = true;
          data.outlinedButton.disable = true;
          data.outlinedButton.hidden = true;
          break;
        case OrderStatusEnum.CONFIRMATION_WAITING:
          data.filledButton.text = translate(
            'cryptoExchange.order.centerCard.leftButton.firstState'
          );
          data.filledButton.disable = false;
          data.outlinedButton.disable = false;
          data.outlinedButton.hidden = false;

          break;
        case OrderStatusEnum.CONFIRMATION_COMPLETED:
        case OrderStatusEnum.EXCHANGE_COMPLETED:
        case OrderStatusEnum.EXCHANGE_CREATE_SPOT_ORDER:
        case OrderStatusEnum.WITHDRAW_WAITING:
        case OrderStatusEnum.EXCHANGE_PROCESSING:
        case OrderStatusEnum.TRANSACTION_WAITING:
          data.filledButton.text = translate(
            'cryptoExchange.order.centerCard.leftButton.firstState'
          );
          data.outlinedButton.disable = true;
          data.filledButton.disable = true;
          data.outlinedButton.hidden = false;
          break;
        case OrderStatusEnum.CANCELED:
          data.filledButton.text = translate(
            'cryptoExchange.order.centerCard.leftButton.firstState'
          );
          data.filledButton.disable = true;
          data.outlinedButton.disable = true;
          data.outlinedButton.hidden = false;
          break;
        default:
          data.filledButton.text = translate(
            'cryptoExchange.order.centerCard.leftButton.firstState'
          );
          data.filledButton.disable = true;
          data.outlinedButton.disable = false;
          data.outlinedButton.hidden = false;
          break;
      }
      data.outlinedButton.text = translate(
        'cryptoExchange.order.centerCard.rightButton'
      );

      return data;
    },
    []
  );

  const [currentState, setCurrentState] = useState(
    getCardStateByOrderStatus(currentOrderState)
  );

  const commissionSum =
    commissionData.outputCommission && commissionData.networkCommission
      ? +commissionData.outputCommission + +commissionData.networkCommission
      : 0;

  useEffect(() => {
    setCurrentState(getCardStateByOrderStatus(currentOrderState));
  }, [currentOrderState]);

  return (
    <GradientCard padding={'32px 24px 32px 20px'} leftBorderWidth={4}>
      <div className="exchange-order-data-card">
        <Typography.Text className="exchange-card-header">
          {`${translate(
            'cryptoExchange.order.centerCard.title'
          )} #${orderNumber}`}
        </Typography.Text>
        <div
          style={{flexDirection: 'column'}}
          className="flex height-100-percent width-100-percent exchange-data-card-header-space"
        >
          <div
            className="flex-row justify-content-space-between align-items-center exchange-data-card-currency-container">
            <div className="flex-row align-items-center justify-content-center">
              <div className="coin-wrapper-secondary">
                {
                  getCoinInfoByName(inputCurrency?.toLowerCase())
                    .icon as JSX.Element
                }
              </div>
              <div className="flex-column">
                <Typography.Text style={{fontSize: 18, fontWeight: 700}}>
                  {translate('cryptoExchange.order.centerCard.send')}
                </Typography.Text>
                <Typography.Text className="bold-18px-text">{`${Number(
                  inputPrice || 0
                )?.toFixed(8)} ${inputCurrency}`}</Typography.Text>
              </div>
            </div>
            <div className="flex-row  align-items-center">
              <div className="coin-wrapper-primary">
                {
                  getCoinInfoByName(outputCurrency?.toLowerCase())
                    .icon as JSX.Element
                }
              </div>
              <div className="flex-column">
                <Typography.Text className="bold-18px-text">
                  {translate('cryptoExchange.order.centerCard.get')}
                </Typography.Text>
                <Typography.Text className="bold-18px-text">{`${Number(
                  outputPrice || 0
                )?.toFixed(8)} ${outputCurrency}`}</Typography.Text>
              </div>
            </div>
          </div>
          <div className="flex-column exchange-data-card-list-wrapper">
            <ExchangeDataCardList
              inputPrice={inputPrice}
              commissionData={commissionData}
              inputCurrency={inputCurrency}
              items={[
                {
                  title: translate('cryptoExchange.order.centerCard.changeFee'),
                  value: `${commissionSum} ${inputCurrency}`,
                },
                {
                  title: translate(
                    'cryptoExchange.order.centerCard.changeAddress'
                  ),
                  value: recipientWallet!,
                },
                {
                  title: translate(
                    'cryptoExchange.order.centerCard.minimumExchangeValue'
                  ),
                  value: `${
                    minimalTradeAmount ? minimalTradeAmount : 0
                  } ${inputCurrency}`,
                },
              ]}
            />
            <Typography.Text
              style={{
                marginTop: '27.5px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                marginBottom: !(Math.abs(courseChangePercent) >= 3)
                  ? '55px'
                  : undefined,
              }}
              className="bold-16px-text"
            >
              {Math.abs(courseChangePercent) >=
              PERCENT_AMOUNT_TO_DISPLAY_COURSE_CHANGE_NOTIFICATION &&
              courseChangePercent >=
              PERCENT_AMOUNT_TO_DISPLAY_COURSE_CHANGE_NOTIFICATION ? (
                <CourseUpIcon/>
              ) : null}
              {Math.abs(courseChangePercent) >=
              PERCENT_AMOUNT_TO_DISPLAY_COURSE_CHANGE_NOTIFICATION &&
              courseChangePercent <=
              PERCENT_AMOUNT_TO_DISPLAY_COURSE_CHANGE_NOTIFICATION ? (
                <CourseDownIcon/>
              ) : null}
              {`1 ${inputCurrency} = ${cryptoCourse} ${outputCurrency}`}
            </Typography.Text>
            {Math.abs(courseChangePercent) >=
              PERCENT_AMOUNT_TO_DISPLAY_COURSE_CHANGE_NOTIFICATION && (
                <Typography.Text
                  style={{fontSize: 14, margin: '4px 0 27.5px 0'}}
                >
                  {translate('cryptoExchange.order.centerCard.courseChanged')}{' '}
                  {PERCENT_AMOUNT_TO_DISPLAY_COURSE_CHANGE_NOTIFICATION}%.
                </Typography.Text>
              )}
            <div className={'flex-row gap-16'}>
              <div>
                {!currentState.filledButton.hidden && (
                  <Button
                    type="primary"
                    onClick={onFilledButtonClick}
                    style={{
                      height: 40,
                      minWidth: 180,
                    }}
                    disabled={currentState.filledButton.disable}
                  >
                    {currentState.filledButton.text}
                  </Button>
                )}
              </div>
              <div>
                {!currentState.outlinedButton.hidden && (
                  <Button
                    style={{
                      height: 40,
                      minWidth: 180,
                      backgroundColor: currentState.outlinedButton.disable
                        ? 'rgba(118, 118, 135, 1) !important'
                        : '',
                    }}
                    onClick={onOutlinedButtonClick}
                    type="dashed"
                    disabled={currentState.outlinedButton.disable}
                  >
                    {currentState.outlinedButton.text}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </GradientCard>
  );
};

export default ExchangeOrderDataCard;
