const Download = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <g clip-path="url(#clip0_14038_154778)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 9.90002C1.27614 9.90002 1.5 10.1239 1.5 10.4V12.9C1.5 13.4523 1.94772 13.9 2.5 13.9H14.5C15.0523 13.9 15.5 13.4523 15.5 12.9V10.4C15.5 10.1239 15.7239 9.90002 16 9.90002C16.2761 9.90002 16.5 10.1239 16.5 10.4V12.9C16.5 14.0046 15.6046 14.9 14.5 14.9H2.5C1.39543 14.9 0.5 14.0046 0.5 12.9V10.4C0.5 10.1239 0.723858 9.90002 1 9.90002Z"
        fill="#FBFBFF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.14645 11.8536C8.34171 12.0488 8.65829 12.0488 8.85355 11.8536L11.8536 8.85355C12.0488 8.65829 12.0488 8.34171 11.8536 8.14645C11.6583 7.95118 11.3417 7.95118 11.1464 8.14645L9 10.2929V1.5C9 1.22386 8.77614 1 8.5 1C8.22386 1 8 1.22386 8 1.5V10.2929L5.85355 8.14645C5.65829 7.95118 5.34171 7.95118 5.14645 8.14645C4.95118 8.34171 4.95118 8.65829 5.14645 8.85355L8.14645 11.8536Z"
        fill="#FBFBFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_14038_154778">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export { Download };
