import { ButtonProps } from '@pankod/refine-antd';
import React from 'react';

export enum ButtonsTypes {
  default = 'default',
  outlined = 'outlined',
}

export interface ICustomButton extends Omit<ButtonProps, 'type'> {
  width?: string | number;
  height?: string | number;
  textColor?: string;
  children?: React.ReactNode;
  isDisabled?: boolean;
  disabledTextColor?: string;
  onClick: (
    event:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  type?: ButtonsTypes;
}
