import React, { FC, PropsWithChildren } from 'react';
import Icon from '@ant-design/icons';
import {
  bankIcons,
  IKeyIcons,
} from '../../../../pages/p2p/dashboard/methodsPayment';
import Phone from '../../../../features/paymentComponents/modalContent/secondStep/Service/image/phone.svg';
import { GetIcon } from '../../../../features/paymentComponents/modalContent/secondStep/Card/getIcon';
import SBP from '../../../../features/paymentComponents/modalContent/secondStep/Service/image/sbp.svg';

const Banks: FC<{ name: string; icon: IKeyIcons }> = ({ name, icon }) => (
  <div className={'bank-selector-item'}>
    <Icon component={() => bankIcons(icon)} />
    {name}
  </div>
);

const Webs: FC<{ name: string; icon: string; alt: string }> = ({
  name,
  icon,
  alt,
}) => (
  <div className={'bank-selector-item'}>
    <img alt={alt} src={icon} />
    {name}
  </div>
);

const Phones: FC<PropsWithChildren> = ({ children }) => (
  <div className={'bank-selector-item'}>
    <img alt={'mobile balance'} src={Phone} />
    {children}
  </div>
);

const Transfer: FC<{ name: string; icon: number }> = ({ name, icon }) => (
  <div className={'bank-selector-item'}>
    <img alt={'bank'} style={{ width: 16, height: 16 }} src={GetIcon(icon)} />
    {name}
  </div>
);

const FPS: FC<PropsWithChildren> = ({ children }) => (
  <div className={'bank-selector-item'}>
    <img alt={'sbp'} src={SBP} />
    {children}
  </div>
);

export { Banks, Webs, Phones, Transfer, FPS };
