import React from 'react';
import OutputMainBloc from './outputMainBloc';
import OnConfirm from './onConfirm';
import OnCanceled from './onCanceled';
import './output.css';
import FullscreenRingWithTextAndIcon from './fullscreenRingWithTextAndIcon';

const Index = () => {
  return <></>;
};

export default Object.assign(Index, {
  OutputMainBloc,
  OnConfirm,
  OnCanceled,
  FullscreenRingWithTextAndIcon,
});
