import React from 'react';
import {IGetListAdData, IPaymentInfo} from './interface';
import {PaymentTypeEnum} from '../../../../features/p2p/createOrder/types';
import {DefaultOptionType} from 'rc-select/lib/Select';
import {
    AdvCache,
    AkBars,
    AlfaBank,
    BankCredention,
    BCS,
    BSPD,
    CentrInvest,
    CitiBank,
    CreditEurope,
    FPS,
    GaspromBank,
    HomeCreditBank,
    MobileMethod,
    MTS,
    OpenBank,
    OTP,
    Payeer,
    PochtaBank,
    QIWI,
    Raiffeisen,
    RenCredit,
    Rosbank,
    RSB,
    SberBank,
    SovcomBank,
    Tinkoff,
    Tochka,
    UBRR,
    UniCreditBank,
    UralSib,
    VTB,
    YooMoney,
} from '../../../../shared/components/icons';
import Icon from '@ant-design/icons';
import {useTranslate} from '@pankod/refine-core';

enum IKeyIcons {
    'Т-Банк' = 'Т-Банк',
    'Росбанк' = 'Росбанк',
    'Райффайзенбанк' = 'Райффайзенбанк',
    'МТС - Банк' = 'МТС - Банк',
    'Почта Банк' = 'Почта Банк',
    'Хоум Кредит Банк' = 'Хоум Кредит Банк',
    'Альфа - банк' = 'Альфа - банк',
    'Ак Барс Банк' = 'Ак Барс Банк',
    'Уралсиб Банк' = 'Уралсиб Банк',
    'Ренессанс Банк' = 'Ренессанс Банк',
    'Русский Стандарт' = 'Русский Стандарт',
    'БКС Банк' = 'БКС Банк',
    'Банк Санкт - Петербург' = 'Банк Санкт - Петербург',
    'ОТП Банк' = 'ОТП Банк',
    'Кредит Европа Банк(Россия)' = 'Кредит Европа Банк(Россия)',
    'Ситибанк' = 'Ситибанк',
    'Сбербанк' = 'Сбербанк',
    'ВТБ' = 'ВТБ',
    'Банк Точка' = 'Банк Точка',
    'Банк Центр - инвест' = 'Банк Центр - инвест',
    'Совкомбанк' = 'Совкомбанк',
    'Уральский Банк реконструкции и развития' = 'Уральский Банк реконструкции и развития',
    'Газпромбанк' = 'Газпромбанк',
    'Банк ФК Открытие' = 'Банк ФК Открытие',
    'ЮниКредит Банк' = 'ЮниКредит Банк',
    YooMoney = 'YooMoney',
    QIWI = 'QIWI',
    Payeer = 'Payeer',
    AdvCash = 'AdvCash',
}

const bankIcons = (key: IKeyIcons) => {
    const icons = {
        'Т-Банк': <Icon component={() => Tinkoff('', 16)}/>,
        'Росбанк': <Icon component={() => Rosbank('', 16)}/>,
        'Райффайзенбанк': <Icon component={() => Raiffeisen('', 16)}/>,
        'МТС - Банк': <Icon component={() => MTS('', 16)}/>,
        'Почта Банк': <Icon component={() => PochtaBank('', 16)}/>,
        'Хоум Кредит Банк': <Icon component={() => HomeCreditBank('', 16)}/>,
        'Альфа - банк': <Icon component={() => AlfaBank('', 16)}/>,
        'Ак Барс Банк': <Icon component={() => AkBars('', 16)}/>,
        'Уралсиб Банк': <Icon component={() => UralSib('', 16)}/>,
        'Ренессанс Банк': <Icon component={() => RenCredit('', 16)}/>,
        'Русский Стандарт': <Icon component={() => RSB('', 16)}/>,
        'БКС Банк': <Icon component={() => BCS('', 16)}/>,
        'Банк Санкт - Петербург': <Icon component={() => BSPD('', 16)}/>,
        'ОТП Банк': <Icon component={() => OTP('', 16)}/>,
        'Кредит Европа Банк(Россия)': (
            <Icon component={() => CreditEurope('', 16)}/>
        ),
        'Ситибанк': <Icon component={() => CitiBank('', 16)}/>,
        'Сбербанк': <Icon component={() => SberBank('', 16)}/>,
        'ВТБ': <Icon component={() => VTB('', 16)}/>,
        'Банк Точка': <Icon component={() => Tochka('', 16)}/>,
        'Банк Центр - инвест': <Icon component={() => CentrInvest('', 16)}/>,
        'Совкомбанк': <Icon component={() => SovcomBank('', 16)}/>,
        'Уральский Банк реконструкции и развития': (
            <Icon component={() => UBRR('', 16)}/>
        ),
        'Газпромбанк': <Icon component={() => GaspromBank('', 16)}/>,
        'Банк ФК Открытие': <Icon component={() => OpenBank('', 16)}/>,
        'ЮниКредит Банк': <Icon component={() => UniCreditBank('', 16)}/>,
        YooMoney: <Icon component={() => YooMoney('', 16)}/>,
        QIWI: <Icon component={() => QIWI('', 16)}/>,
        Payeer: <Icon component={() => Payeer('#FBFBFF', 16)}/>,
        AdvCash: <Icon component={() => AdvCache('', 16)}/>,
    };
    return icons[key];
};
export const MethodsPayment = ({
                                   data = [],
                                   icon = false,
                               }: {
    data?: IPaymentInfo[];
    icon?: boolean;
}): DefaultOptionType[] => {
    const translate = useTranslate();
    return (
        data
            ?.map((item) => {
                switch (item.payment_type) {
                    case PaymentTypeEnum.bankCardPayment:
                        return (
                            item.payment_details?.map((bank) => {
                                return {
                                    value: icon ? item.id : undefined,
                                    label: icon ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                            }}
                                        >
                                            {bankIcons(bank.bank_name as IKeyIcons)}
                                            {bank.bank_name}
                                        </div>
                                    ) : (
                                        bank.bank_name
                                    ),
                                };
                            }) ?? []
                        );
                    case PaymentTypeEnum.mobileBalancePayment:
                        return (
                            item.payment_details?.map((mobile) => {
                                return {
                                    value: mobile.id,
                                    label: icon ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                            }}
                                        >
                                            <Icon component={() => MobileMethod('', 16)}/>
                                            {translate(
                                                'dashboard.filters.method.other.methods.mobile'
                                            )}
                                        </div>
                                    ) : (
                                        translate('dashboard.filters.method.other.methods.mobile')
                                    ),
                                };
                            }) ?? []
                        );
                    case PaymentTypeEnum.cashPayment:
                        return (
                            item.payment_details?.map((cash) => {
                                return {
                                    value: cash.id,
                                    label: 'Cash Payment',
                                };
                            }) ?? []
                        );
                    case PaymentTypeEnum.bankTransferPayment:
                        return (
                            item.payment_details?.map((transfer) => {
                                return {
                                    value: transfer.id,
                                    label: icon ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                            }}
                                        >
                                            <Icon component={() => BankCredention('#FBFBFF', 16)}/>
                                            {translate('dashboard.filters.method.other.methods.bank')}
                                        </div>
                                    ) : (
                                        translate('dashboard.filters.method.other.methods.bank')
                                    ),
                                };
                            }) ?? []
                        );
                    case PaymentTypeEnum.fastPaymentSystemPayment:
                        return (
                            item.payment_details?.map((fps) => {
                                return {
                                    value: item.id,
                                    label: icon ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                            }}
                                        >
                                            <Icon component={() => FPS('', 16)}/>
                                            {translate('dashboard.filters.method.other.methods.fps')}
                                        </div>
                                    ) : (
                                        translate('dashboard.filters.method.other.methods.fps')
                                    ),
                                };
                            }) ?? []
                        );
                    case PaymentTypeEnum.webServicePayment:
                        return (
                            item.payment_details?.map((web) => {
                                return {
                                    value: item.id,
                                    label: icon ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                            }}
                                        >
                                            {bankIcons(web.web_service_name as IKeyIcons)}
                                            {web.web_service_name}
                                        </div>
                                    ) : (
                                        web.web_service_name
                                    ),
                                };
                            }) ?? []
                        );
                }

                return [];
            })
            .flat() ?? []
    );
};

export const MethodsPaymentBuy = ({
                                      data = [],
                                      icon = false,
                                  }: {
    data?: IGetListAdData['buy_payment_info'];
    icon?: boolean;
}): DefaultOptionType[] => {
    const translate = useTranslate();
    // @ts-ignore
    return (
        data?.map((item) => {
            switch (item.payment_type) {
                case PaymentTypeEnum.bankCardPayment:
                    return {
                        value: item.id,
                        label: icon ? (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}
                                key={item.payment_type + '_' + item.id}
                            >
                                {bankIcons(item.bank_name as IKeyIcons)}
                                {item.bank_name}
                            </div>
                        ) : (
                            item.bank_name
                        ),
                    };
                case PaymentTypeEnum.mobileBalancePayment:
                    return {
                        value: item.id,
                        label: icon ? (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}
                                key={item.payment_type + '_' + item.id}
                            >
                                <Icon component={() => MobileMethod('', 16)}/>
                                {translate('dashboard.filters.method.other.methods.mobile')}
                            </div>
                        ) : (
                            translate('dashboard.filters.method.other.methods.mobile')
                        ),
                    };
                case PaymentTypeEnum.cashPayment:
                    return {
                        value: item.id,
                        label: 'Cash Payment',
                    };
                case PaymentTypeEnum.bankTransferPayment:
                    return {
                        value: item.id,
                        label: icon ? (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}
                                key={item.payment_type + '_' + item.id}
                            >
                                <Icon component={() => BankCredention('#FBFBFF', 16)}/>
                                {translate('dashboard.filters.method.other.methods.bank')}
                            </div>
                        ) : (
                            translate('dashboard.filters.method.other.methods.bank')
                        ),
                    };
                case PaymentTypeEnum.fastPaymentSystemPayment:
                    return {
                        value: item.id,
                        label: icon ? (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}
                                key={item.payment_type + '_' + item.id}
                            >
                                <Icon component={() => FPS('', 16)}/>
                                {translate('dashboard.filters.method.other.methods.fps')}
                            </div>
                        ) : (
                            translate('dashboard.filters.method.other.methods.fps')
                        ),
                    };
                case PaymentTypeEnum.webServicePayment:
                    return {
                        value: item.id,
                        label: icon ? (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}
                                key={item.payment_type + '_' + item.id}
                            >
                                {bankIcons(item.web_service_name as IKeyIcons)}
                                {item.web_service_name}
                            </div>
                        ) : (
                            item.web_service_name
                        ),
                    };
            }
        }) ?? []
    );
};
