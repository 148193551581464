import React from 'react';
import {ConfigProvider, Steps} from '@pankod/refine-antd';
import {Typography} from 'antd';
import {IDotStepper} from './stepper.p';

const DotStepper: React.FC<IDotStepper> = ({dotItems, current, textSize, ...props}) => {
  const currentStep = current ? current : 0;

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Steps: {
              descriptionWidth: 15,
              controlHeightLG: 32,
            },
          },
        }}
      >
        <Steps
          {...props}
          labelPlacement={'vertical'}
          progressDot={true}
          initial={0}
          current={currentStep}
          items={dotItems.map((item, index) => {
            return {
              subTitle: (
                <Typography
                  className={'text-14px'}
                  style={{
                    textAlign:
                      index === 0
                        ? 'left'
                        : index === dotItems.length - 1
                          ? 'right'
                          : 'center',
                    width: 'max-content',
                    whiteSpace: 'pre-wrap',
                    position: 'relative',
                    left: item.marginLeft,
                    color: currentStep >= index ? '' : '#767687',
                    fontSize: textSize ? `${textSize}px` : 14
                  }}
                >
                  {`${item.firstRow}\n${item.secondRow}`}
                </Typography>
              ),
            };
          })}
        />
      </ConfigProvider>
    </>
  );
};

export default DotStepper;
