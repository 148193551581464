import React, {useEffect, useState} from 'react';
import Stepper from '../../shared/components/ui/stepper';
import {IStepInStepper} from '../../shared/components/ui/stepper/stepper.p';
import ExchangeOrderTimeCard from '../../features/order/exchangeOrderTimeCard';
import ExchangeOrderDataCard from '../../features/order/exchangeOrderDataCard';
import ExchangeOrderTransferCard from '../../features/order/exchangeOrderTransferCard';
import {OrderStatusEnum} from '../../features/order/order.p';
import {useTranslate} from '@pankod/refine-core';
import {IExchangeOrderContent} from './types';
import './index.css';

const Index: React.FC<IExchangeOrderContent> = ({
                                                  data,
                                                  currentPair,
                                                  updateOrder,
                                                  cancelOrder,
                                                  orderStatus,
                                                  returnFunds,
                                                  minimalTradeAmount,
                                                  exchangeCommission,
                                                  networkCommission,
                                                }) => {
  const {
    created_at,
    id,
    input_price,
    input_sum,
    input_currency,
    output_currency,
    balance,
    output_wallet,
    order_wallet,
  } = data;

  const [currentStep, setCurrentStep] = useState(1);

  const translate = useTranslate();

  const Steps: IStepInStepper[] = [
    {
      stepUnderText: translate('cryptoExchange.order.stepper.firstStep'),
      width: 160,
      marginLeft: 0,
    },
    {
      stepUnderText: translate('cryptoExchange.order.stepper.secondStep'),
      width: 110,
      marginLeft: -40,
    },
    {
      stepUnderText: translate('cryptoExchange.order.stepper.thirdStep'),
      width: 110,
      marginLeft: -40,
    },
    {
      stepUnderText: translate('cryptoExchange.order.stepper.fourthStep'),
      width: 40,
      marginLeft: -10,
    },
  ];

  const tenMin = new Date(0).setHours(13);
  const expDate = +new Date(created_at) + tenMin;

  const timeToCloseOrder =
    +new Date(expDate - +Date.now()) < 0
      ? new Date(0)
      : new Date(expDate - +Date.now());

  // update order status on timer finish based on current order status
  const onTimerFinish = () => {
    switch (orderStatus) {
      case OrderStatusEnum.NEW:
      case OrderStatusEnum.WALLET_CREATED:
        updateOrder({orderId: id, status: 'canceled'});
        break;
      case OrderStatusEnum.WALLET_REPLENISHED:
        updateOrder({orderId: id, status: 'canceled_replenished'});
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    switch (orderStatus) {
      case OrderStatusEnum.NEW:
      case OrderStatusEnum.WALLET_CREATED:
        setCurrentStep(1);
        break;
      case OrderStatusEnum.CONFIRMATION_WAITING:
        setCurrentStep(2);
        break;
      case OrderStatusEnum.CONFIRMATION_COMPLETED:
      case OrderStatusEnum.TRANSACTION_WAITING:
      case OrderStatusEnum.EXCHANGE_PROCESSING:
      case OrderStatusEnum.EXCHANGE_COMPLETED:
      case OrderStatusEnum.EXCHANGE_CREATE_SPOT_ORDER:
      case OrderStatusEnum.WITHDRAW_WAITING:
        setCurrentStep(3);
        break;
      case OrderStatusEnum.EXECUTED:
        setCurrentStep(4);
        break;
      case OrderStatusEnum.CANCELED:
      case OrderStatusEnum.CANCELED_REPLENISHED:
      case OrderStatusEnum.CANCELED_REPLENISHED_EXECUTED:
        setCurrentStep(-1);
        break;
    }
  }, [orderStatus]);

  return (
    <div className={'exchange-order-main-container'}>
      <div>
        <Stepper
          steps={Steps}
          initial={1}
          current={currentStep}
          labelPlacement={'vertical'}
        />
      </div>
      <div className="exchange-cards-wrapper">
        <ExchangeOrderTimeCard
          orderNumber={id}
          createData={created_at}
          startTimeInSeconds={+timeToCloseOrder / 1000}
          handleTimerFinish={() => onTimerFinish()}
          orderStatus={orderStatus}
        />
        <ExchangeOrderDataCard
          minimalTradeAmount={minimalTradeAmount}
          recipientWallet={output_wallet}
          cryptoCourse={currentPair ? +currentPair.price : 0}
          outputCommission={exchangeCommission}
          networkCommission={networkCommission}
          inputPrice={+input_sum}
          inputCurrency={input_currency}
          outputCurrency={output_currency}
          outputPrice={+input_price}
          orderNumber={id}
          currentOrderState={orderStatus}
          onFilledButtonClick={() => {
            switch (orderStatus) {
              case OrderStatusEnum.CANCELED_REPLENISHED:
                return returnFunds();
              case OrderStatusEnum.CONFIRMATION_WAITING:
                return updateOrder({
                  orderId: id,
                  user_confirmation_status: 'confirmed',
                });
              // TODO: Иногда срабатывает не с первого раза!
              default:
                return;
            }
          }}
          onOutlinedButtonClick={cancelOrder}
        />
        <ExchangeOrderTransferCard
          minimalTradeAmount={minimalTradeAmount}
          inputCurrency={input_currency}
          addressWallet={order_wallet}
          currentStep={currentStep}
          userBalance={parseInt(balance.toString() ?? '0', 10)}
        />
      </div>
    </div>
  );
};

export default Index;
