import { IP2PUser } from 'interfaces';
import { useState } from 'react';
import {
  useList,
  useNotification,
  HttpError,
  useUpdate,
  useTranslate,
} from '@pankod/refine-core';

interface IUseUserNicknameProps {
  onSuccessUpdateCallback(): void;
  setValidationError(error: string): void;
}

export const useUserNickname = ({
  onSuccessUpdateCallback,
  setValidationError,
}: IUseUserNicknameProps) => {
  const translate = useTranslate();
  const [userData, setUserData] = useState<IP2PUser>();
  const notificationContext = useNotification();

  const dateNow = new Date();
  const dateUpdated = userData?.nickname_updated
    ? new Date(userData.nickname_updated)
    : new Date(0);
  const isYearPast =
    Math.abs(
      Math.floor(
        (dateNow.getTime() - dateUpdated.getTime()) / (1000 * 60 * 60 * 24)
      )
    ) >= 365;

  useList<IP2PUser, HttpError>({
    resource: 'account',
    dataProviderName: 'account',
    config: {
      pagination: {
        current: 1,
        pageSize: 1,
      },
    },
    queryOptions: {
      onSuccess: ({ data }) => {
        if (!!data && data[0]) {
          setUserData(data[0]);
        }
      },
    },
  });

  const { mutate: updateUserNickname, isLoading } = useUpdate<IP2PUser>();

  const submitUserNickname = (nickname: string) =>
    updateUserNickname(
      {
        dataProviderName: 'account',
        resource: 'account',
        values: {
          nickname: nickname,
        },
        id: userData ? userData.uid : '',
      },
      {
        onSuccess: ({ data }) => {
          setUserData(data);
          onSuccessUpdateCallback();
          if (notificationContext.open !== undefined)
            notificationContext.open({
              message: translate('userNickname.onSuccess.message'),
              description: translate('userNickname.onSuccess.description'),
              type: 'success',
            });
        },
        onError: ({ message }) => {
          if (message == 'The nickname has already been taken.') {
            setValidationError(translate('userNickname.onError.message'));
          }
        },
      }
    );

  return { userData, isLoading, submitUserNickname, isYearPast, dateUpdated };
};
