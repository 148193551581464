import {
  ConfigProvider,
  InputNumber,
  InputNumberProps,
} from '@pankod/refine-antd';
import React from 'react';
import './index.css';

const CardInput: React.FC<
  InputNumberProps & { step?: string; useBlack?: boolean; smallText?: boolean }
> = (props) => {
  const { useBlack, smallText, step } = props;
  return (
    <ConfigProvider
      theme={{
        components: {
          InputNumber: {
            colorTextPlaceholder: useBlack ? '#202124' : 'white',
            colorText: useBlack ? '#202124' : 'white',
            colorBgContainerDisabled: 'transparent',
            borderRadius: 6,
          },
        },
      }}
    >
      <InputNumber
        {...props}
        controls={false}
        size={smallText ? 'small' : 'large'}
        style={{
          ...props.style,
          width: '100%',
          color: useBlack ? '#202124' : 'white',
          fontSize: smallText ? 16 : 28,
          paddingLeft: smallText ? 0 : 'revert',
          backgroundColor: '#2B2B36',
          borderRadius: 6,
        }}
        bordered={false}
        step={step ? step : '0.00000001'}
        className={'input-number-in-card'}
        stringMode
      />
    </ConfigProvider>
  );
};

export default CardInput;
