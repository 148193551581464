import {BaseRecord, useTranslate} from "@pankod/refine-core"
import {Typography} from "antd";
import styled from "styled-components"

type TComission = {
  record: BaseRecord;
  data: BaseRecord | undefined;
  comissionData: BaseRecord | undefined;
}

export const Comission = ({record, data, comissionData}: TComission) => {
  const t = useTranslate();
  return (
    <>
      {record.type !== "buy" &&
        <ItemContainer>
          <GreyText>
            {t('announcements.commissionAnnouncements')}
          </GreyText>
          {data?.dataSource && (
            <WhiteText>
              {comissionData?.dataSource &&
                parseFloat(
                  comissionData?.dataSource[0]
                    .exchange_commission_percent
                ) * parseInt(data?.dataSource[0]?.total_sum)}{' '}
              {record?.ccy}
            </WhiteText>
          )}
        </ItemContainer>
      }
    </>
  )
}
const ItemContainer = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: ${(props) => props.width}px;
`
const GreyText = styled(Typography.Text)`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #767687;
`
const WhiteText = styled(Typography.Text)`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #FBFBFF
`
