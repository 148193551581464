import { useList } from '@pankod/refine-core';
import { CONFIG_DATA_PROVIDER } from 'packages/keycloak-client/constants';

interface IUseGetConfigCommission {
  configKey:
    | 'commission'
    | 'spot_commission'
    | 'allowed_percent'
    | 'p2p_commission'
    | 'arbitrage_commission'
    | 'offchange_exchange_commission'
    | 'offchain_exchange_order_minimal_sum'
    | 'order_expired_time'
    | 'offchain_exchange_order_expired_time'
    | 'respond_reaction_time'
    | 'respond_accepted_reaction_time'
    | 'fiat_transferred_reaction_time';
}

interface ICommission {
  created_at: string;
  id: number;
  key: string;
  updated_at: string;
  value: string;
}

export const useGetConfigCommision = ({
  configKey,
}: IUseGetConfigCommission) => {
  const { data } = useList<ICommission>({
    resource: 'config',
    dataProviderName: CONFIG_DATA_PROVIDER,
    config: {
      filters: [
        {
          field: 'key',
          operator: 'eq',
          value: configKey,
        },
      ],
    },
  });

  return {
    configData: data?.data[0],
  };
};
