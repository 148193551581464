import {CSSProperties, useEffect, useMemo, useState} from 'react';
import {useNavigate} from '@pankod/refine-react-router-v6';
import {useUserNickname} from 'shared/hooks/use-user-nickname';
import ModalContent, {TypePaymentVariant,} from '../../../features/paymentComponents/modalContent';
import {IPaymentsType} from '../dashboard/interface';
import {HttpError, useList} from '@pankod/refine-core';
import {IPaymentType} from "../../../features/p2p/createOrder/types";
import {useTranslation} from 'react-i18next';
import {notification} from 'antd';
import {axios} from '../../../shared/exios';
import {API_URL} from '../../../packages/keycloak-client/constants';
// import {SecretCard} from './secretCard';
import {ErrorBoundary} from '../../../app/ErrorBoundary';
import {useCookiesCustom} from '../../../shared/hooks/useCookieCustom';
import P2PCard from '../../../features/p2pCard';

const Index = () => {
    const {removeCookie} = useCookiesCustom();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [isOpenNicknameModal, setIsOpenNicknameModal] = useState(false);
    const [validationError, setValidationError] = useState<string>();

    const onSuccessUpdateCallback = () => {
        setIsOpenNicknameModal(false);
        notification.success({
            message: userData?.nickname
                ? t('userNickname.changed')
                : t('userNickname.onSuccess.message'),
            description: userData?.nickname
                ? t('userNickname.changedDesc')
                : t('userNickname.onSuccess.description'),
        });
    };

    const onServerValidationError = (errorMessage: string) => {
        setValidationError(errorMessage);
    };

    const {
        isLoading: isLoadingAccount,
        userData,
        isYearPast,
        dateUpdated,
    } = useUserNickname({
        onSuccessUpdateCallback,
        setValidationError: onServerValidationError,
    });
    const [secretIsConnected, setSecretIsConnected] = useState<
        boolean | undefined
    >(undefined);

  useEffect(() => {
    axios
      .get(`${API_URL}/account/api/account`)
      .then((res) => {
        setSecretIsConnected(
          res.data.data.filter((phrase: any) => phrase.passphrase_isset)
            .length > 0
        );
      });
  }, []);

  const handleCancel = () => {
    setErrorHasPayment(false);
    setIsOpenPaymentModal(false);
  };

  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const [errorHasPayment, setErrorHasPayment] = useState(false);
  const [nowStep, setNowStep] = useState(1);
  const [paymentNum, setPaymentNum] = useState(0);
  const [variantPay, setVariantPay] = useState<TypePaymentVariant | null>(null);
  const iconsData = useList<IPaymentsType, HttpError>({
    dataProviderName: `p2p`,
    resource: `payment-types`,
  });

  const {refetch} = useList<IPaymentType>({
    resource: 'payment',
    dataProviderName: 'p2p',
    config: {
      filters: [
        {
          field: 'account_id',
          operator: 'eq',
          value: userData?.uid,
        },
      ],
    },
    queryOptions: {
      enabled: !!userData,
      onSuccess: (data) => {
        setPaymentNum(data?.total);
      },
    },
  });

  const p2pCardOption = useMemo(
    () => ({
      nameButton:
        t('p2p.settings.card1.nicknameCardDescription'),
      tooltip:
        userData && userData.nickname == null
          ? t('p2p.settings.card1.tooltip.needCreateNickname').toString()
          : userData && userData.nickname && !isYearPast
            ? `${t(
              'p2p.settings.card1.tooltip.nicknameEditAvailable'
            )} ${new Date(
              dateUpdated.setDate(dateUpdated.getDate() + 365)
            ).toLocaleDateString('ru-RU')}`
            : undefined,
      navigate: () =>
        ((userData && userData.nickname == null) ||
          (userData && userData.nickname && isYearPast)) &&
        setIsOpenNicknameModal(true),
    }),
    [dateUpdated, isYearPast, t, userData]
  );
  const p2pCardOption2 = useMemo(
    () => ({
      nameButton:
        paymentNum === 0
          ? t('p2p.settings.card2.buttonAddPayment')
          : t('p2p.settings.card2.PaymentNum') + ' ' + paymentNum,
      tooltip:
        paymentNum === 0
          ? t('p2p.settings.card2.tooltip').toString()
          : undefined,
      navigate: () => {
        paymentNum
          ? navigate('/p2p/settings/payment')
          : setIsOpenPaymentModal(true);
      },
    }),
    [navigate, paymentNum, t]
  );

  return (
      <ErrorBoundary
          logout={() => {}}
          navigate={navigate}
          removeCookie={removeCookie}
      >
      <h3 style={h3Style}>{t('p2p.settings.title')}</h3>
      <p style={pStyle}>{t('p2p.settings.subtitle')}</p>
      <div style={{display: 'flex', flexDirection: 'row', gap: '2%'}}>
        {/*<P2PCard*/}
        {/*  isNicknameCard={true}*/}
        {/*  username={userData?.nickname ?? ''}*/}
        {/*  title={t('p2p.settings.card1.title')}*/}
        {/*  content={t('p2p.settings.card1.content')}*/}
        {/*  helpText={t('p2p.settings.card1.helpText')}*/}
        {/*  {...p2pCardOption}*/}
        {/*/>*/}
        {/*<SecretCard*/}
        {/*  setSecretIsConnected={setSecretIsConnected}*/}
        {/*  isConnect={secretIsConnected}*/}
        {/*  isMenu={false}*/}
        {/*  isExtendedMenu={true}*/}
        {/*/>*/}
        <P2PCard
          isNicknameCard={false}
          title={t('p2p.settings.card2.title')}
          content={t('p2p.settings.card2.content')}
          helpText={String(t(''))}
          nameButton={p2pCardOption2.nameButton}
          navigate={p2pCardOption2.navigate}
          tooltip={p2pCardOption2.tooltip}
          isTooltip={true}
        />
      </div>
      {userData && (
          <ModalContent
            open={isOpenPaymentModal}
            onOk={() => {
              setIsOpenPaymentModal(false);
            }}
            errorHasPayment={errorHasPayment}
            controlsNowStep={[nowStep, setNowStep]}
            controlsVariantPay={[variantPay, setVariantPay]}
            closeModal={handleCancel}
            refetch={refetch}
            iconsData={iconsData.data?.data}
            setErrorHasPayment={() => setErrorHasPayment(false)}
          />
      )}
      </ErrorBoundary>
  );
};

const pStyle: CSSProperties = {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    color: 'white',
    fontSize: 18,
};

const h3Style: CSSProperties = {
    fontFamily: 'Inter, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 40,
    color: 'white',
};
export default Index;
