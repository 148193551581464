import React, { useCallback } from 'react';
import { Divider, Typography } from '@pankod/refine-antd';
import Timer from '../../shared/components/ui/timer';
import { useGetLocale, useTranslate } from '@pankod/refine-core';
import GradientCard from 'shared/components/ui/gradient-card';
import { IExchangeOrderTimeCard, OrderStatusEnum } from './order.p';
import './index.css';

const ExchangeOrderTimeCard: React.FC<IExchangeOrderTimeCard> = ({
  orderStatus,
  handleTimerFinish,
  startTimeInSeconds,
  createData,
  orderNumber,
}) => {
  const locale = useGetLocale();
  const translate = useTranslate();

  const currentLocale = locale();
  const date = new Date(createData);

  const getTitleFromCondition = useCallback(
    (status: OrderStatusEnum) => {
      if (startTimeInSeconds <= 0) {
        return translate('cryptoExchange.order.leftCard.titles.timeOut');
      }

      switch (status) {
        case OrderStatusEnum.NEW:
        case OrderStatusEnum.WALLET_CREATED:
        case OrderStatusEnum.WITHDRAW_WAITING:
        case OrderStatusEnum.EXCHANGE_PROCESSING:
        case OrderStatusEnum.TRANSACTION_WAITING:
        case OrderStatusEnum.CONFIRMATION_WAITING:
        case OrderStatusEnum.WALLET_REPLENISHED:
          return translate('cryptoExchange.order.leftCard.titles.timeHave');
        case OrderStatusEnum.CANCELED:
        case OrderStatusEnum.CANCELED_REPLENISHED:
        case OrderStatusEnum.CANCELED_REPLENISHED_EXECUTED:
          return translate('cryptoExchange.order.leftCard.titles.cancel');
        case OrderStatusEnum.EXECUTED:
          return translate('cryptoExchange.order.leftCard.titles.success');
      }
    },
    [currentLocale, startTimeInSeconds]
  );

  const isTimerDisabled =
    orderStatus === OrderStatusEnum.CANCELED ||
    orderStatus === OrderStatusEnum.CANCELED_REPLENISHED ||
    orderStatus === OrderStatusEnum.CANCELED_REPLENISHED_EXECUTED;

  return (
    <>
      <GradientCard padding={'32px 24px 32px 20px'} leftBorderWidth={4}>
        <div className="exchange-timer-card">
          <Typography.Text className="exchange-card-header justify-content-center text-align-center">
            {getTitleFromCondition(orderStatus)}
          </Typography.Text>
          <Typography.Text
            className={`exchange-card-timer justify-content-center ${
              isTimerDisabled ? 'timer-disabled' : ''
            }`}
          >
            {isTimerDisabled ? (
              '00:00:00'
            ) : (
              <>
                {startTimeInSeconds > 0 ? (
                  <Timer
                    initSeconds={startTimeInSeconds}
                    handleFinish={handleTimerFinish}
                    digits={7}
                  />
                ) : (
                  '00:00:00'
                )}
              </>
            )}
          </Typography.Text>
          <Divider className="exchange-card-divider" />
          <Typography.Text className="exchange-card-subheader justify-content-center">
            {translate('cryptoExchange.order.leftCard.dateInfo').toUpperCase()}
          </Typography.Text>
          <div className="exchange-card-order-create-time-wrapper gap-16 justify-content-center">
            <Typography.Text className={'bold-16px-text gray'}>
              {date.toLocaleDateString(currentLocale)}
            </Typography.Text>
            <Typography.Text className={'bold-16px-text gray'}>
              {date.toLocaleTimeString(currentLocale)}
            </Typography.Text>
          </div>

          <Typography.Text className="bold-16px-text exchange-card-order-number justify-content-center">
            {`#${orderNumber}`}
          </Typography.Text>
          <Typography.Text className=" .exchange-card-order-number justify-content-center exchange-card-order-number-description">
            {`${translate('cryptoExchange.order.leftCard.orderNumber')}`}
          </Typography.Text>
        </div>
      </GradientCard>
    </>
  );
};

export default ExchangeOrderTimeCard;
