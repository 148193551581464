import {Typography} from "@pankod/refine-antd"
import {IPaymentType} from "../../../types"
import {getPaymentInfoData} from "../../../../../../shared/helpers/getPaymentInfoData"
import {useTranslate} from "@pankod/refine-core"
import {IPaymentsType} from "../../../../../../pages/p2p/dashboard/interface"
import Phone from '../../phone.svg';
import SBP from '../../SBP.svg';
import styled from "styled-components"

type TPaymentInfoDataItem = {
  title: string,
  subtext: string | undefined,
  value: string | undefined,
  icon: string
}
const UserPaymentList = ({userPaymentList, iconsData}: {
  userPaymentList: IPaymentType[],
  iconsData: IPaymentsType[]
}) => {
  const t = useTranslate();
  const getPaymentTypeTitle = (item: IPaymentType) => {
    const obj = {
      'bankCardPayment': t('payments.types.banksCard'),
      'mobileBalancePayment': t('payments.types.mobiles'),
      'bankTransferPayment': t('payments.types.banks'),
      'fastPaymentSystemPayment': t('payments.types.sbp'),
      'webServicePayment': t('payments.types.webService'),
      'cashPayment': t('payments.types.cashPayment')
    }
    return (
      <span>{obj[item.payment_type]}</span>
    )
  }
  const getPaymentTitle = (
    listItem: IPaymentType,
    itemInfo: TPaymentInfoDataItem
  ) =>
    (listItem.payment_type === 'mobileBalancePayment' ||
      listItem.payment_type === 'fastPaymentSystemPayment')
      ? itemInfo.value
      : itemInfo.title;
  const getPaymentLightText = (
    listItem: IPaymentType,
    itemInfo: TPaymentInfoDataItem
  ) =>
    !(listItem.payment_type === 'mobileBalancePayment' ||
      listItem.payment_type === 'fastPaymentSystemPayment') && itemInfo.value;

  const getPaymentImg = (item: any) => {
    return iconsData
      ?.filter((i) => !!i.items)
      ?.map((i) => i.items)
      ?.flat()
      ?.find((i) => i?.name === item.title)?.icon
  }
  return (
    <>
      {userPaymentList.map((listItem) =>
        getPaymentInfoData(listItem, t, iconsData).map((item) => (
          <PaymentCard>
            <HeaderCardWrapper>
              <TitleText>
                {getPaymentTypeTitle(listItem)}
              </TitleText>
            </HeaderCardWrapper>

            <CardInfo>
              <IconWrapper>
                {listItem.payment_type ===
                'mobileBalancePayment' ? (
                  <PaymentIco
                    alt={`mobile_icon`}
                    src={Phone}
                  />
                ) : listItem.payment_type ===
                'fastPaymentSystemPayment' ? (
                  <PaymentIco
                    alt={`sbp_icon`}
                    src={SBP}
                  />
                ) : (
                  <PaymentIco
                    alt={`${listItem.id}`}
                    src={`data:image/svg+xml;base64,${getPaymentImg(item)}`}
                  />
                )}
              </IconWrapper>

              <TextWrapper>
                <StandartText>
                  {getPaymentTitle(listItem, item)}
                </StandartText>
                <LightGreyText>
                  {getPaymentLightText(listItem, item)}
                </LightGreyText>
              </TextWrapper>
            </CardInfo>

            <SubtitleWrapper>
              <LightGreyText>{item.subtext}</LightGreyText>
            </SubtitleWrapper>
          </PaymentCard>
        ))
      )}
    </>
  )
}

export default UserPaymentList;

const PaymentIco = styled.img`
  width: 24px;
  height: 24px;
`
const PaymentCard = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 356px;
  background: #2B2B36;
  border-radius: 8px;
  gap: 4px;
  padding: 8px 16px;
  position: relative;
  height: 86px;
`
const HeaderCardWrapper = styled.div`
  display: flex;
`

const CardInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
const IconWrapper = styled.div``

const StandartText = styled(Typography)`
  font-size: 14px;
  line-weight: 400;
  line-height: 16.94px;
`

const TitleText = styled(Typography)`
  color: #767687;
  font-size: 12px;
  line-height: 14.52px;
`

const LightGreyText = styled(TitleText)`
  color: rgba(195, 195, 206, 1);
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 32px;
`

const SubtitleWrapper = styled.div``;
