import React, {useState} from 'react';
import {useLocation, useNavigate} from '@pankod/refine-react-router-v6';
import {Grid, notification} from 'antd';
import {useDataProvider} from '@pankod/refine-core';
import {P2P_DATA_PROVIDER} from '../../packages/keycloak-client/constants';
import {AdaptiveFullScreenLoader} from '../../shared/components/full-screen-loader';
import {IAppealFormValues} from 'shared/components/ui/modal/appealModal/components/form/interface';
import {Chat} from 'features/chat-module';
import P2PMainBuyContent from '../../widgets/p2p/buyOrder/P2PMainBuyContent';
import {useGetP2POrdersData} from '../../shared/hooks/p2p/use-get-P2P-orders-data';
import {IAppealResponse, IDataWrapper, IProveResponse,} from '../../shared/components/ui/modal/appealModal/types.p';
import {RcFile, Typography} from '@pankod/refine-antd';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import CustomBreadcrumb from "../../shared/components/ui/breadcrumbs";
import {ErrorBoundary} from 'app/ErrorBoundary';
import {useCookiesCustom} from '../../shared/hooks/useCookieCustom';
import {useAuthState} from "../../useAuthState";

interface IBuyDeal {
    id?: string;
}

const BuyDeal: React.FC<IBuyDeal> = ({id}) => {
    const navigate = useNavigate();
    const {removeCookie, cookie} = useCookiesCustom();
    const uid = useAuthState((state) => state?.userAPI?.uid) || cookie['uid'];
    const {t} = useTranslation();
    const loc = useLocation();

    const [api, contextHolder] = notification.useNotification();

    const dataProvider = useDataProvider();
    const p2pProvider = dataProvider(P2P_DATA_PROVIDER);

    const breakpoint = Grid.useBreakpoint();
    const isSmall = typeof breakpoint.sm === 'undefined' ? true : breakpoint.sm;

    const [openAppealModal, setOpenAppealModal] = useState(false);
    const [openAppealCloseModal, setOpenAppealCloseModal] = useState(false);

    const {
        currCommission,
        currTimerValue,
        currentOrder,
        isTimerActive,
        updateP2POrderStatus,
        isMyAppeal,
        paymentInfo,
        updateP2PAppealStatus,
        createAppeal,
    } = useGetP2POrdersData({
        id: id ? id : loc?.pathname?.split('/')[3],
        intervalTimeInMS: 10000,
    });


    const handleOnOpenAppealModal = () => {
        setOpenAppealModal(true);
    };

    const handleOnCloseAppealModal = () => {
        setOpenAppealModal(false);
    };

    const handleOnOpenAppealCloseModal = () => {
        setOpenAppealCloseModal(true);
    };

    const handleOnCloseAppealCloseModal = () => {
        setOpenAppealCloseModal(false);
    };

    const handleOnSuccessAppealModal = (
        data: IAppealFormValues,
        callback: () => void
    ) => {
        if (!currentOrder) return;
        p2pProvider
            .create({
                resource: 'appeal',
                variables: {
                    order_id: currentOrder?.id,
                    description: data.description,
                    reason: data.reason,
                },
            })
            .then((res) => {
                const appealRes = (res.data as IDataWrapper<IAppealResponse>).data;

                (data?.files ?? []).map(async (item) => {
                    const res = await p2pProvider
                        .create({
                            resource: 'prove',
                            variables: {
                                description: appealRes.description,
                                appeal_id: appealRes.id,
                                file_name: 'prove',
                                file_extension: item.type?.split('/')[1],
                            },
                        });
                    const createProveRes = (
                        res.data as IDataWrapper<
                            IProveResponse & { upload_link: string; }
                        >
                    ).data;
                    const formData = new FormData();
                    formData.append(
                        'prove' + item.type?.split('/')[1],
                        item as RcFile
                    );
                    fetch(createProveRes.upload_link, {
                        method: 'PUT',
                        body: formData,
                    }).then(() => {
                        p2pProvider.update({
                            id: createProveRes.id,
                            resource: 'prove',
                            variables: {
                                is_uploaded: true,
                            },
                        });
                    });
                });

                callback();
                api.success({
                    message: t('buyDeal.appealSuccessfullyCreated'),
                    placement: 'topRight',
                });
            })
            .catch(() => {
                api.error({
                    message: t('buyDeal.errorWhenCreatingAnAppeal'),
                    placement: 'topRight',
                });
            });
    };

    if (!currentOrder) {
        return <AdaptiveFullScreenLoader/>;
    }

    const sellerId = currentOrder.seller_id;

    const isSeller = () => uid === sellerId;

    const getCurrentTitle = () => {

        if (currentOrder.type === 'sell') {
            if (isSeller()) {
                return t('buyDeal.dealForSale');
            } else {
                return t('buyDeal.dealForPurchase');
            }
        } else {
            if (isSeller()) {
                return t('buyDeal.dealForSale');
            } else {
                return t('buyDeal.dealForPurchase');
            }
        }
    };

    return (
        <ErrorBoundary
            logout={() => {
            }}
            navigate={navigate}
            removeCookie={removeCookie}
        >
            {contextHolder}
            <MainWrapper $marginTop={isSmall ? -32 : -12}>
                <ContentWrapper>
                    <CustomBreadcrumb
                        items={[
                            {
                                title: t('myDeals.title'),
                                link: '/p2p/my-deals'
                            },
                            {
                                title: `${getCurrentTitle()} ${currentOrder.ccy?.toUpperCase()}`,
                                link: '',
                            },
                        ]}
                    />
                    <Typography.Title>{`${getCurrentTitle()} ${currentOrder.ccy?.toUpperCase()}`}</Typography.Title>
                    <P2PMainBuyContent
                        updateP2PAppealStatus={updateP2PAppealStatus}
                        paymentInfo={paymentInfo}
                        currCommission={currCommission}
                        currentOrder={currentOrder}
                        isSeller={isSeller()}
                        currTimerValue={currTimerValue}
                        handleOnOpenAppealModal={handleOnOpenAppealModal}
                        handleOnCloseAppealModal={handleOnCloseAppealModal}
                        handleOnSuccessAppealModal={handleOnSuccessAppealModal}
                        handleOnOpenAppealCloseModal={handleOnOpenAppealCloseModal}
                        handleOnCloseAppealCloseModal={handleOnCloseAppealCloseModal}
                        isMyAppeal={isMyAppeal}
                        openAppealModal={openAppealModal}
                        openAppealCloseModal={openAppealCloseModal}
                        updateP2POrderStatus={updateP2POrderStatus}
                        isTimerActive={isTimerActive}
                        createAppeal={createAppeal}
                    />
                </ContentWrapper>
                <OrderChatWrapper>
                    <Chat
                        buyerId={currentOrder.buyer_id}
                        sellerId={currentOrder.seller_id}
                        buyerInfo={currentOrder.buyer_info}
                        sellerInfo={currentOrder.seller_info}
                        orderStatus={currentOrder.status}
                        isSeller={isSeller()}
                    />
                </OrderChatWrapper>
            </MainWrapper>
        </ErrorBoundary>
    );
};
export default BuyDeal;


const MainWrapper = styled.div<{ $marginTop?: number }>`
    margin-top: ${(props) => props.$marginTop}px;
    height: 100%;
    display: flex;
    flex-direction: row;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    padding: 32px 32px 32px 0;
    height: 100%;
`;

const OrderChatWrapper = styled.div`
    min-width: 386px;
    max-width: 460px;
    padding-top: 16px;
    border-width: 0 0 1px 1px;
    border-style: solid;
    border-color: rgba(65, 61, 80, 0.5);
    border-radius: 0;
`;
