import { CONFIG_DATA_PROVIDER } from '../../packages/keycloak-client/constants';
import { useDataProvider } from '@pankod/refine-core';
import { useState } from 'react';

interface IUseGetTimeFromConfig {
  configKey: string;
  actionAfterGetConfigValue?: (value: string) => void;
}

export const useGetTimeFromConfig = () => {
  const dataProvider = useDataProvider();
  const configProvider = dataProvider(CONFIG_DATA_PROVIDER);

  const [configValue, setConfigValue] = useState('');

  const updateConfigValue = ({
    configKey,
    actionAfterGetConfigValue,
  }: IUseGetTimeFromConfig) => {
    configProvider
      .getList({
        resource: 'config',
        filters: configKey
          ? [
              {
                field: 'key',
                operator: 'eq',
                value: configKey,
              },
            ]
          : [],
      })
      .then((res) => {
        const value = res?.data[0]?.value;
        setConfigValue(value);
        if (actionAfterGetConfigValue) {
          actionAfterGetConfigValue(value);
        }
      });
  };

  return {
    configValue,
    updateConfigValue,
  };
};
