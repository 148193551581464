import { Radio, RadioChangeEvent, Typography } from "@pankod/refine-antd"
import { AdTypesEnum } from "../../types"
import { useTranslate } from "@pankod/refine-core";

type TChooseAdType = {
    adType: AdTypesEnum, 
    handleChangeAdTypeButtonClick: () => (e: RadioChangeEvent) => void,
    getChangeAdTypeStyles: (type: AdTypesEnum.SELL | AdTypesEnum.BUY) => {
        width: string;
        backgroundColor: string;
        border: string;
    }
}

export const ChooseAdTypeRadio = ({adType, handleChangeAdTypeButtonClick, getChangeAdTypeStyles}: TChooseAdType) => {
    const t = useTranslate();
    return (
        <Radio.Group
            className="choose-p2p-ad-type-radio-group-dark"
            value={adType}
            size="large"
            onChange={handleChangeAdTypeButtonClick()}
        >
            <Radio.Button
                style={getChangeAdTypeStyles(AdTypesEnum.BUY)}
                value={AdTypesEnum.BUY}
            >
                <Typography className="choose-p2p-ad-type-radio-group-dark-button-text">
                    {t('p2p.chooseAdType.radio.buy')}
                </Typography>
            </Radio.Button>
            <Radio.Button
                style={getChangeAdTypeStyles(AdTypesEnum.SELL)}
                value={AdTypesEnum.SELL}
            >
                <Typography className="choose-p2p-ad-type-radio-group-dark-button-text">
                    {t('p2p.chooseAdType.radio.sell')}
                </Typography>
            </Radio.Button>
        </Radio.Group>
    )
} 