import { Dispatch, useLayoutEffect, useState } from 'react';
import { dataProvider as orionDataProvider } from '@egal/refine-laravel-orion/dist/esm/provider';
import {
  ACCOUNT_DATA_PROVIDER,
  API_URL,
  BTC_COIN_DATA_PROVIDER,
  BTC_USDT_SPOT_DATA_PROVIDER,
  CONFIG_DATA_PROVIDER,
  ETH_COIN_DATA_PROVIDER,
  EXCHANGE_DATA_PROVIDER,
  LTC_COIN_DATA_PROVIDER,
  OFFCHAIN_EXCHANGE_DATA_PROVIDER,
  P2P_DATA_PROVIDER,
  SPOT_DATA_PROVIDER,
  USDC_COIN_DATA_PROVIDER,
  USDT_COIN_DATA_PROVIDER,
} from '../packages/keycloak-client/constants';
import { axios } from '../shared/exios';

interface Props {
  controlsCoinsList: [any, Dispatch<any>];
}

const UseDataProvider = ({
  controlsCoinsList: [coinsList, setCoinsList],
}: Props) => {
  const [pairsNamesArr, setPairsNamesArr] = useState<any>([]);

  const [dataProvider, setDataProvider] = useState({
    default: orionDataProvider(API_URL, axios),
    [EXCHANGE_DATA_PROVIDER]: orionDataProvider(
      `${API_URL}/exchange/api`,
      axios
    ),
    [OFFCHAIN_EXCHANGE_DATA_PROVIDER]: orionDataProvider(
      `${API_URL}/offchain-exchange/api`,
      axios
    ),
    [ETH_COIN_DATA_PROVIDER]: orionDataProvider(
      `${API_URL}/eth-coin/api`,
      axios
    ),
    [BTC_COIN_DATA_PROVIDER]: orionDataProvider(
      `${API_URL}/btc-coin/api`,
      axios
    ),
    [USDT_COIN_DATA_PROVIDER]: orionDataProvider(
      `${API_URL}/usdt-coin/api`,
      axios
    ),
    [USDC_COIN_DATA_PROVIDER]: orionDataProvider(
      `${API_URL}/usdc-coin/api`,
      axios
    ),
    [LTC_COIN_DATA_PROVIDER]: orionDataProvider(
      `${API_URL}/ltc-coin/api`,
      axios
    ),
    [ACCOUNT_DATA_PROVIDER]: orionDataProvider(`${API_URL}/account/api`, axios),
    [CONFIG_DATA_PROVIDER]: orionDataProvider(`${API_URL}/config/api`, axios),
    [P2P_DATA_PROVIDER]: orionDataProvider(`${API_URL}/p2p/api`, axios),
    [SPOT_DATA_PROVIDER]: orionDataProvider(
      `${API_URL}/btcusdt-spot/api`,
      axios
    ),
    [BTC_USDT_SPOT_DATA_PROVIDER]: orionDataProvider(
      `${API_URL}/btcusdt-spot/api`,
      axios
    ),
  });

  async function getPairs() {
    try {
      const res = await axios.get(`${API_URL}/config/api/pair`);
      const pairsArr: any[] = [];
      for (const pair of res?.data?.data) {
        const pairWithDash = pair?.pair?.replace(new RegExp('/', 'g'), '-');
        pairsArr.push(pairWithDash);
      }
      setPairsNamesArr((prevState: any) => prevState.concat(pairsArr));
      return res.data;
    } catch (e) {
      console.log('getPairs error', e);
    }
  }

  async function getCoins() {
    const res = await fetch(
      `${API_URL}/config/api/currency-constraint/search?limit=10&page=1`,
      {
        method: 'POST',
      }
    );
    if (res.ok) {
      const json = await res.json();
      setCoinsList(json.data);
    }
  }

  async function generateDataProviders() {
    const providerCoin = Object.assign(
      {},
      ...coinsList.map((n: { currency: string }) => ({
        [`${n?.currency?.toLowerCase()}-coin`]: orionDataProvider(
          `${API_URL}/${n?.currency?.toLowerCase()}-coin/api`,
          axios
        ),
      }))
    );
    const providerSpot = Object.assign(
      {},
      ...pairsNamesArr.map((n: any) => ({
        [`${n?.replace(new RegExp('/', 'g'), '')?.toLowerCase()}-spot`]:
          orionDataProvider(
            `${API_URL}/${n
              ?.replace(new RegExp('/', 'g'), '')
              ?.toLowerCase()}-spot/api`,
            axios
          ),
      }))
    );
    setDataProvider((prevState: any) => {
      return { ...prevState, ...providerCoin, ...providerSpot };
    });
  }

  useLayoutEffect(() => {
    Promise.all([getPairs(), getCoins(), generateDataProviders()]).then();
  }, []);

  return dataProvider;
};

export default UseDataProvider;
