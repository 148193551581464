import { Tag } from "@pankod/refine-antd"
import { ICryptoCurrencyConstraintItem } from "widgets/order/types"
import { IFiat } from "../../types";

export const CurrencyTagsList = ({ currencyList, fiatList, handleChangeCurrency, chosenCurrency, handleChangeFiat }:
    {
        currencyList?: ICryptoCurrencyConstraintItem[],
        fiatList?: IFiat[],
        handleChangeCurrency?: (currency?: ICryptoCurrencyConstraintItem) => void,
        handleChangeFiat?: (fiat?: IFiat) => void;
        chosenCurrency: ICryptoCurrencyConstraintItem | undefined | IFiat
    }) => {
    const getBgColor = (listItem: ICryptoCurrencyConstraintItem | IFiat) => chosenCurrency?.id === listItem.id ? '#767687' : '#2B2B36';
    /* Do not turn to styled-component, turning to styled may cause memory-leak */
    const getTagStyles = (listItem: ICryptoCurrencyConstraintItem | IFiat) => {
        return ({
            color: 'white',
            backgroundColor: getBgColor(listItem),
            borderRadius: 8,
            padding: '8px 16px',
            fontSize: 12,
        })
    }
    const CustomTag = ({ listItem, onClick }: {
        listItem: ICryptoCurrencyConstraintItem | IFiat,
        onClick: () => void,
    }) => {
        return (
            <Tag
                key={listItem.id}
                onClick={onClick}
                style={getTagStyles(listItem)}
                className={'cursor-pointer'}
            >
                {listItem.currency}
            </Tag >
        )
    }
    return (
        <>
            {currencyList
                ? currencyList.slice(0, 3).map((listItem) => (
                    handleChangeCurrency && <CustomTag listItem={listItem} onClick={() => handleChangeCurrency(listItem)}/>
                ))
                : fiatList && fiatList.slice(0, 3).map((listItem) => (
                    handleChangeFiat && <CustomTag listItem={listItem} onClick={() => handleChangeFiat(listItem)}/>
                ))
            }
        </>
    )
}