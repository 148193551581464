import React, {FC, useMemo} from 'react'
import styled from 'styled-components'
import {Tooltip, Typography} from '@pankod/refine-antd'
import {useTranslate} from "@pankod/refine-core";
import {PaymentTypeEnum} from "../../../p2p/createOrder/types";
import {transformPhone} from "../../../../shared/helpers/phones";

const {Text} = Typography;

interface IProps {
    paymentTypes: any[];
}

const PaymentTypes: FC<IProps> = ({paymentTypes = []}) => {
    const t = useTranslate();

    const getPayments = useMemo(() => {
        const TooltipC = ({title, desc, children}: {
            title: string;
            desc: string;
            children: JSX.Element;
        }): JSX.Element => {
            return (
                <Tooltip
                    key={title}
                    color={"#2B2B36"}
                    title={
                        <div style={{padding: '4px'}}>
                            <Typography>{title}</Typography>
                            <Typography style={{color: '#767687'}}>{desc}</Typography>
                        </div>
                    }
                >
                    {children}
                </Tooltip>
            );
        };

        const arr = (obj: any) => {
            switch (obj.payment_type) {
                case PaymentTypeEnum.bankCardPayment:
                    return {
                        label: t(`banks.${obj.bank_name}`),
                        type: t('payments.types.banks')
                    };
                case PaymentTypeEnum.mobileBalancePayment:
                    return {
                        label: transformPhone(obj.phone) || t('payments.mobileBalanceReplenishment'),
                        type: t('payments.types.mobiles')
                    };
                case PaymentTypeEnum.cashPayment:
                    return {
                        label: 'Cash Payment',
                        type: t('payments.types.cashes')
                    };
                case PaymentTypeEnum.bankTransferPayment:
                    return {
                        label: t(`banks.${obj.bank_name}`),
                        type: t('dashboard.filters.method.other.methods.bank')
                    };
                case PaymentTypeEnum.fastPaymentSystemPayment:
                    return {
                        label: t('dashboard.filters.method.other.methods.fps'),
                        type: t('payments.types.sbp')
                    };
                case PaymentTypeEnum.webServicePayment:
                    return {
                        label: obj.web_service_name,
                        type: t('payments.electronService')
                    };
                default:
                    return {
                        label: '',
                        type: ''
                    };
            }
        };

        return paymentTypes.map((payment, index) => (
            <TooltipC
                key={index}
                title={arr(payment).label}
                desc={arr(payment).type}
            >
                <Payment>
                    <PaymentText>{arr(payment).label}</PaymentText>
                </Payment>
            </TooltipC>
        ));
    }, [paymentTypes, t]);

    return <Payments>{getPayments}</Payments>;
};

export default PaymentTypes;

const Payments = styled.div`
    max-width: 220px;
    min-height: 50px;
    display: flex;
    align-items: flex-start;
    gap: 4px;
    flex-wrap: wrap;
`;

const Payment = styled.div`
    max-width: 220px;
    height: 23px;
    overflow: hidden;
    color: #FBFBFF;
    white-space: nowrap;
    padding: 4px;
    border-radius: 3px;
    background: #474755;
    z-index: 999;
`;

const PaymentText = styled(Text)`
    font-size: 12px;
    font-weight: 500;
    color: #FBFBFF;
    line-height: 0;
`;
