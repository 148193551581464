import {useTable} from '@pankod/refine-antd';
import {useAuthState} from '../../useAuthState';

export const useGetAnnTableNofilter = () => {
    const userAPI = useAuthState((state) => state.userAPI);

    const {
        tableProps: tableRes,
        filters,
        setFilters,
    } = useTable({
        resource: `offer`,
        dataProviderName: `p2p`,
        initialPageSize: 15,
        permanentSorter: [{field: 'id', order: 'desc'}],
        syncWithLocation: false,
        initialFilter: [
            {field: 'status', operator: 'ne', value: `deleted`},
            {
                field: 'owner_id',
                operator: 'eq',
                value: userAPI?.uid,
            },
        ],
    });
    return {tableRes, filters, setFilters};
};

export const useGetAnnTables = () => {
    const allTableProps = useGetAnnTableNofilter();
    return {allTable: allTableProps};
};
