import React, { FC } from 'react';
import { getFullNameCoin } from '../../../../../shared/helpers/getFullNameCoin';

interface Props {
  inputIconCoin: string;
  coinName: string;
  inputAmount: string;
  rightPart?: boolean;
}

const CardPart: FC<Props> = ({
  inputAmount,
  coinName,
  inputIconCoin,
  rightPart,
}) => {
  return (
    <div className={rightPart ? 'card-right-part' : 'card-left-part'}>
      <div className={'card-left-part-row'}>
        <img
          src={inputIconCoin}
          style={{ width: 16, height: 16 }}
          alt={'coinInput'}
        />
        <span className={'card-text-large'}>
          {getFullNameCoin(coinName)} ({coinName})
        </span>
      </div>
      <div className={'card-left-part-row'}>
        <span className={'card-text-small'}>{inputAmount}</span>
      </div>
    </div>
  );
};

export default CardPart;
