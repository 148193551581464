import {Typography} from "@pankod/refine-antd"
import {styled} from "styled-components"
import {BaseRecord, useTranslate} from "@pankod/refine-core";
import dayjs from "dayjs";

export const AdHeading = ({record}: { record: BaseRecord }) => {
  const t = useTranslate();
  return (
    <AdHeader>
      <div className="flex-row flex-between">
        <Typography.Text className="text-gray">
          {t('announcements.params.createdAt')}{' '}
          {dayjs(record.created_at).format('DD.MM.YYYY HH:mm')}
        </Typography.Text>
        <Typography.Text className="text-gray">
          {t('announcements.params.adNumber')}
          {record.id}
        </Typography.Text>
      </div>
      <Divider/>
    </AdHeader>
  )
}


const AdHeader = styled.div`
  padding: 16px 24px 0px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const Divider = styled.div`
  display: flex;
  height: 0;
  border-bottom: 1px solid #474755;
  left: -24px;
  position: relative;
  width: calc(100% + 48px);
`
