import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Space,
  Tooltip,
  Typography,
} from '@pankod/refine-antd';
import { ReactComponent as SquareQuestionIcon } from '../../shared/components/ui/assets/svg/squareQuestionIcon.svg';
import { FieldData, ICryptoPair } from './cryptoCurrencyExchange.p';
import { useTranslate } from '@pankod/refine-core';

const { Text } = Typography;

interface IExchangeForm {
  formData: FieldData[];
  currentPair: ICryptoPair;
  onChange: (value: FieldData[]) => void;
  onBeginChange: () => void;
  userData: string | undefined;
  inputCurrency: string;
  inputPrice: number;
  isOrderCreateLoading: boolean;
  commission: string;
}

const ExchangeFormContainer: React.FC<IExchangeForm> = ({
  formData,
  onChange,
  onBeginChange,
  userData,
  inputCurrency,
  currentPair,
  inputPrice,
  commission,
}) => {
  const [form] = Form.useForm();

  const translate = useTranslate();

  const commissionSum = currentPair
    ? commission && currentPair.taker_commission
      ? (inputPrice / 100) * +commission
      : 0
    : 0;

  const [_, setDisable] = useState(true);
  const emailWatch = Form.useWatch('email', form);
  const walletAddressWatch = Form.useWatch('walletAddress', form);
  const handleCreate = () => {
    form.validateFields(['email', 'walletAddress']).then(
      () => {
        onBeginChange();
        setDisable(false);
      },
      () => setDisable(true)
    );
  };

  useEffect(() => {
    form.setFieldValue('email', userData);
  }, []);

  return (
    <div className={'exchange-form-container'}>
      <Form
        className="exchange-form"
        form={form}
        layout={'vertical'}
        fields={formData}
        onFieldsChange={(_, allFields) => {
          onChange(allFields);
        }}
        validateTrigger={['onChange']}
        requiredMark={false}
        autoComplete="off"
        name={'validateOnly'}
        initialValues={{ email: userData, walletAddress: undefined }}
      >
        <Form.Item>
          <Text className="form-title">
            {translate('cryptoExchange.changer.formData.title')}
          </Text>
        </Form.Item>
        <Form.Item
          label={
            <label htmlFor="email">
              {translate('cryptoExchange.changer.formData.emailTitle')}
            </label>
          }
          shouldUpdate={true}
          name={'email'}
          initialValue={userData}
          rules={[
            {
              required: true,
              message: translate('cryptoExchange.changer.formData.emailTitle'),
            },
            {
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: translate(
                'cryptoExchange.changer.formData.emailCorrectTitle'
              ),
            },
          ]}
        >
          <Input
            // style={{ ...(Boolean(!!userData) && { color: '#767687' }) }}
            placeholder={'Email'}
            defaultValue={userData}
            value={userData}
            type={'email'}
            onChange={(e) => {
              form.setFieldValue('email', e?.target?.value);
              form
                .validateFields(
                  walletAddressWatch === ''
                    ? ['email']
                    : ['walletAddress', 'email']
                )
                .then(
                  () => {
                    if (walletAddressWatch !== '') setDisable(false);
                  },
                  () => setDisable(true)
                );
            }}
          />
        </Form.Item>
        <Form.Item
          name={'walletAddress'}
          label={
            <label htmlFor="walletAddress">
              {translate('cryptoExchange.changer.formData.walletAddressTitle')}
            </label>
          }
          rules={[
            {
              required: true,
              message: translate(
                'cryptoExchange.changer.formData.enterWithdrawalWalletAddress'
              ),
            },
            {
              pattern: /^[a-zA-Z\d_-]+$/,
              validator: (rule, value, callback) => {
                const length = +value?.length;
                if (length > 44) {
                  return Promise.reject(
                    translate(
                      'cryptoExchange.changer.formData.enterCorrectWithdrawalWalletAddress'
                    )
                  );
                } else if (length < 25 && length !== 0) {
                  return Promise.reject(
                    translate(
                      'cryptoExchange.changer.formData.enterCorrectWithdrawalWalletAddress'
                    )
                  );
                } else callback();
              },
            },
          ]}
        >
          <Input
            placeholder={translate(
              'cryptoExchange.changer.formData.walletAddressPlaceholder'
            )}
            onChange={() => {
              form
                .validateFields(
                  emailWatch === ''
                    ? ['walletAddress']
                    : ['walletAddress', 'email']
                )
                .then(
                  () => {
                    if (emailWatch !== '') setDisable(false);
                  },
                  () => setDisable(true)
                );
            }}
          />
        </Form.Item>
        <Form.Item>
          <div className="exchange-form-description-wrapper">
            <Button
              type="primary"
              onClick={handleCreate}
              // TODO: Временно убрано в соответствии с дизайном
              // disabled={isOrderCreateLoading || disable}
            >
              {translate('cryptoExchange.changer.formData.buttonText')}
            </Button>
            <Space direction="vertical">
              <div className="exchange-form-description">
                <Typography.Text>
                  {translate(
                    'cryptoExchange.changer.formData.helperText.serviceCom'
                  )}
                </Typography.Text>
                <Typography.Text className="bold">
                  {+(+commissionSum)?.toFixed(8) || 0} {inputCurrency}
                </Typography.Text>
                <Tooltip
                  overlayInnerStyle={{ minWidth: 'max-content' }}
                  placement="bottomRight"
                  title={
                    <div>
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginRight: '10px' }}>
                          {translate(
                            'cryptoExchange.changer.formData.helperText.serviceCom'
                          )}{' '}
                        </span>
                        {`${commission}%`} &#10142;{' '}
                        <b style={{ marginLeft: '10px' }}>
                          {currentPair ? commissionSum?.toFixed(8) : 0}{' '}
                          {inputCurrency}
                        </b>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <span style={{ marginRight: '10px' }}>
                          {translate(
                            'cryptoExchange.changer.formData.helperText.networkCom'
                          )}{' '}
                        </span>
                        &#10142;{' '}
                        <b style={{ marginLeft: '10px' }}>
                          {currentPair?.taker_commission
                            ? Number(currentPair.taker_commission).toFixed(8)
                            : 0}{' '}
                          {inputCurrency}
                        </b>
                      </div>
                    </div>
                  }
                >
                  <SquareQuestionIcon className="tooltip-icon" />
                </Tooltip>
              </div>
              <Typography.Text>
                {translate('cryptoExchange.changer.formData.additionalInfo')}
              </Typography.Text>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ExchangeFormContainer;
