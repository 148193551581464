import React, {Dispatch, ReactNode, SetStateAction, useEffect, useState} from 'react';
import {Input} from 'antd';
import '../Card/styles.css';
import {PayService} from '../../../../../pages/p2p/settings/payment/payment';
import CustomSelect from '../../../../../shared/components/ui/select';
import Caret from '../Card/image/caret.svg';
import {useCustom} from '@pankod/refine-core';
import {BanksResponse} from '../../../../../pages/p2p/settings/payment/types';
import {useTranslation} from 'react-i18next';
import Comment from '../Comment';
import {IPaymentsType} from '../../../../../pages/p2p/dashboard/interface';
import {PaymentTypeEnum} from '../../../../p2p/createOrder/types';
import fb from "../../../../../packages/fb/fb";

interface Props {
    controlsTemplatePay: [PayService, Dispatch<PayService>];
    setIsValid: Dispatch<SetStateAction<boolean>>;
    clickSubmit: boolean;
    errorHasPayment: boolean;
    iconsData?: IPaymentsType[];
}

const Transfer = ({
                      controlsTemplatePay: [templatePay, setTemplatePay],
                      setIsValid,
                      clickSubmit,
                      errorHasPayment,
                      iconsData = [],
                  }: Props) => {
    const {t} = useTranslation();

    const {data} = useCustom<BanksResponse>({
        url: '/p2p/api/banks/search?limit=25',
        method: 'post',
        config: {
            payload: {
                sort: [
                    {
                        field: 'name',
                        order: 'asc',
                    },
                ],
            },
        },
    });
    const [dataItems, setDataItems] = useState<
        { value: string; label: ReactNode }[]
    >([]);
    const [openDrop, setOpenDrop] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const selectListener = fb.addListener('selectTransferBank', (data: any) => {
        })
        return () => {
            selectListener.remove()
        }
    }, []);

    const onChange = (value: string, key: keyof PayService) => {
        setTemplatePay({
            ...templatePay,
            [key]: value,
        });
        setIsValid(true);
    };

    useEffect(() => {
        let result: { value: string; label: ReactNode }[] = [];
        data?.data.data.forEach((item) => {
            result.push({
                value: item.id.toString(),
                label: (
                    <div className={'bank-selector-item'}>
                        <img
                            style={{width: 16, height: 16}}
                            src={`data:image/svg+xml;base64,${
                                iconsData
                                    .find(
                                        (item) =>
                                            item.payment_type === PaymentTypeEnum.bankTransferPayment
                                    )
                                    ?.items?.find((payment) => payment.name === item.name)?.icon
                            }`}
                        />
                        {t(`banks.${item.name}`)}
                    </div>
                ),
            });
        });
        setDataItems(result);
    }, [data]);

    useEffect(() => {
        let value = templatePay.service === ''
            ? 'bank'
            : !templatePay.num ||
            (templatePay.num &&
                (templatePay.num.length > 20 ||
                    isNaN(parseInt(templatePay.num, 10))))
                ? 'num'
                : !templatePay.bik ||
                (templatePay.bik &&
                    (templatePay.bik.length !== 9 ||
                        isNaN(parseInt(templatePay.bik, 10))))
                    ? 'bik'
                    : templatePay.email && templatePay.email.length > 20
                        ? 'email'
                        : templatePay.comment && templatePay.comment.length > 40
                            ? 'comment'
                            : ''
        if (templatePay.num && templatePay.num.length < 19) value = 'invalid'
        if (templatePay.bik && templatePay.bik.length !== 9) value = 'bik'
        setError(() => value);
        setIsValid(() => value === '');
    }, [templatePay, clickSubmit]);


    return (
        <div
            style={{maxHeight: '40vh', overflowY: 'auto', marginBottom: 30}}
            className={'bank-container'}
        >
            <span className={'bank-title'}>{t('payments.bankTransfer')}</span>
            {errorHasPayment && (
                <span style={{marginTop: 4}} className={'bank-input-label-error'}>
          {t('payments.errorAddPayment')}
        </span>
            )}
            <div style={{marginBottom: 10}} className={'bank-form'}>
                <div className={'bank-container-input'}>
                    <span className={'bank-input-label'}>{t('payments.chooseBank')}</span>
                    <CustomSelect
                        data={dataItems}
                        status={error === 'bank' && clickSubmit ? 'error' : undefined}
                        onSelect={(value: any) => onChange(value, 'service')}
                        onDropdownVisibleChange={() => setOpenDrop(!openDrop)}
                        placeholder={t('payments.bank').toString()}
                        eventName={'selectTransferBank'}
                        suffixIcon={
                            <img
                                style={{
                                    transform: `rotate(${openDrop ? 0 : 180}deg)`,
                                    transition: '0.2s',
                                }}
                                alt={'caret'}
                                src={Caret}
                            />
                        }
                        dropdownStyle={{
                            background: '#474755',
                            border: '1px solid #474755',
                            borderRadius: 8,
                        }}
                    />
                    {error === 'bank' && clickSubmit && (
                        <span className={'bank-input-label-error'}>
              {t('payments.chooseBank')}
            </span>
                    )}
                </div>
                <div className={'bank-container-input'}>
          <span className={'bank-input-label'}>
            {t('payments.enterBankAccountNumber')}
          </span>
                    <Input
                        type={'num'}
                        maxLength={20}
                        status={error === 'num' && clickSubmit ? 'error' : undefined}
                        value={templatePay.num}
                        onChange={(e) => {
                            e.target.value.length <= 20 &&
                            onChange(e.target.value.match(/\d/g)?.join('') ?? '', 'num');
                        }}
                        placeholder={t('payments.bankAccountNumber').toString()}
                    />
                    {error === 'num' && clickSubmit && (
                        <span className={'bank-input-label-error'}>
              {t('payments.incorrectBankAccountNumber')}
            </span>
                    )}
                    {error === 'invalid' && clickSubmit && (
                        <span className={'bank-input-label-error'}>
              {t('payments.enterBankAccountNumber')}
            </span>
                    )}
                </div>
                <div className={'bank-container-input'}>
                    <span className={'bank-input-label'}>{t('payments.enterBIK')}</span>
                    <Input
                        value={templatePay.bik}
                        maxLength={9}
                        status={error === 'bik' && clickSubmit ? 'error' : undefined}
                        onChange={(e) => onChange(e.target.value, 'bik')}
                        placeholder={t('payments.BIK').toString()}
                    />
                    {error === 'bik' && clickSubmit && (
                        <span className={'bank-input-label-error'}>
              {t('payments.incorrectBIK')}
            </span>
                    )}
                </div>
                <div className={'bank-container-input'}>
          <span className={'bank-input-label'}>
            {t('payments.enterBankBranch')}
          </span>
                    <Input
                        value={templatePay.email}
                        status={error === 'email' && clickSubmit ? 'error' : undefined}
                        onChange={(e) => onChange(e.target.value, 'email')}
                        placeholder={t('payments.bankBranch').toString()}
                    />
                    {error === 'email' && clickSubmit && (
                        <span className={'bank-input-label-error'}>
              {t('payments.incorrectBankBranch')}
            </span>
                    )}
                </div>
                <Comment
                    templatePay={templatePay}
                    error={error}
                    clickSubmit={clickSubmit}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};

export default Transfer;
