import React from 'react';
import styled from 'styled-components';
import { Drawer, DrawerProps } from '@pankod/refine-antd';
import { Icons } from '@pankod/refine-antd';

const { CloseOutlined } = Icons;

interface ICustomDrawer extends DrawerProps {}

const Index: React.FC<ICustomDrawer> = ({ ...rest }) => {
  return (
    <HeaderDrawer
      drawerStyle={{
        background: '#14141F',
        color: '#FBFBFF',
      }}
      closeIcon={
        <div style={{ height: 32 }} className={'flex-row align-items-center'}>
          <CloseOutlined style={{ color: '#FBFBFF', fontSize: 16 }} />
        </div>
      }
      {...rest}
    />
  );
};

export default Index;

const HeaderDrawer = styled(Drawer)`
  & .ant-drawer-header-title {
    flex-direction: row-reverse;
    height: 32px;
    align-items: center;
  }
`;
