import {Button, Form, Input, InputProps, Popover, Typography, UploadFile,} from '@pankod/refine-antd';
import {Upload} from './upload';
import Styles from './index.module.css';
import {SendIcon} from 'shared/components/ui/assets/svg/send';
import {useState} from 'react';
import {CloseOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {useCreate, useCustomMutation} from '@pankod/refine-core';
import {P2P_DATA_PROVIDER} from '../../../../packages/keycloak-client/constants';
import {useParams} from '@pankod/refine-react-router-v6';
import styled from 'styled-components';
import {LoadModal} from './loadModal';
import {Plus} from '../../../../shared/components/ui/assets/svg/Plus';
import {RcFile} from 'antd/es/upload';

interface IChatForm {
    orderStatus: string;
}

interface IChatFormValues {
    message: string;
}

const ChatForm = ({orderStatus}: IChatForm): JSX.Element => {
    const {t} = useTranslation();

    const [form] = Form.useForm<IChatFormValues>();
    const [messageText, setMessageText] = useState<string>('');
    const [files, setFiles] = useState<UploadFile[] | null>(null);
    const {id: orderId} = useParams();
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const message = Form.useWatch('message', form);

    const allowSendingMessage = () => {
        return ['respond_accepted', 'fiat_transferred', 'appealed'].includes(orderStatus);
    };

    const isButtonDisabled = () => {
        if (!!files?.length) {
            return false;
        }
        return !message;
    };

    const {mutate} = useCreate();
    const {mutate: customMutate} = useCustomMutation();

    const handleOnSubmit = () => {
        form.resetFields();
        handleDeleteFile();
        setMessageText('');
        mutate(
            {
                dataProviderName: P2P_DATA_PROVIDER,
                resource: 'message',
                values: {
                    text: message,
                    order_id: orderId,
                },
            },
            {
                onError: (error, variables, context) => {
                    console.log('Error:');
                },
                onSuccess: (data, variables, context) => {
                    if (files) {
                        const {id} = data.data;
                        const index = files[0].name.lastIndexOf('.');
                        const fileName = files[0].name.substring(0, index);
                        mutate(
                            {
                                dataProviderName: P2P_DATA_PROVIDER,
                                resource: 'attachment',
                                values: {
                                    message_id: id,
                                    file_name: fileName,
                                    file_extension: files[0].type?.split('/')[1],
                                },
                            },
                            {
                                onSuccess: async (data) => {
                                    const {upload_link, id: attachment_id} = data.data.data;

                                    fetch(upload_link, {
                                        method: 'put',
                                        body: files[0].originFileObj as RcFile,
                                    }).then((res) => {
                                        customMutate({
                                            url: `/p2p/api/attachment/${attachment_id}`,
                                            method: 'put',
                                            values: {
                                                is_uploaded: true,
                                            },
                                        });
                                    });
                                },
                            }
                        );
                    }
                },
            }
        );
    };

    const handleDeleteFile = () => {
        setFiles(null);
    };

    const contentAttachPopover = (
        <div className={Styles.popoverContent}>
            <p>
                {t('chatModule.oneFile')}
            </p>
            <p>{t('chatModule.maxFileSize2mb')}</p>
        </div>
    );

    return (
        <div className={Styles.container}>
            <Form
                form={form}
                className={Styles.form}
                onFinish={handleOnSubmit}
                autoComplete="off"
            >
                <AttachPopover content={contentAttachPopover}>
                    <Form.Item className={Styles.form__item}>
                        <Upload
                            icon={
                                <Plus
                                    size={16}
                                    color={
                                        isButtonDisabled() || !allowSendingMessage()
                                            ? '#767687'
                                            : '#FBFBFF'
                                    }
                                />
                            }
                            onModal={false}
                            onChange={({fileList}) => {
                                setFiles(fileList);
                                setModalIsOpen(true);
                            }}
                            disabled={!allowSendingMessage()}
                        />
                    </Form.Item>
                </AttachPopover>
                <div className={Styles.form__item_input_container}>
                    <div className={Styles.form__item_input_file_preview}>
                        <Typography.Text>{files && files[0].name}</Typography.Text>
                        {files && (
                            <Button
                                type="text"
                                size="small"
                                icon={<CloseOutlined className={Styles.file_preview__icon}/>}
                                disabled={false}
                                onClick={handleDeleteFile}
                            />
                        )}
                    </div>
                    <Form.Item
                        name={'message'}
                        className={`${Styles.form__item}`}
                        normalize={(value, prevValue) => {
                            return value.length > 250 ? value.slice(0, 250) : value;
                        }}
                    >
                        <TextAreaWrapper
                            onPressEnter={() => {
                                handleOnSubmit();
                            }}
                            onChange={(e) => {
                                setMessageText(e.target.value);
                            }}
                            placeholder={t('chatModule.inputs.enterMessage').toString()}
                            autoSize={{maxRows: 6}}
                            disabled={!allowSendingMessage()}
                            showCount={{
                                formatter: (info) => {
                                    return `${info.count} / 250`;
                                },
                            }}
                            $messageIsEmpty={!messageText.length}
                        />
                    </Form.Item>
                </div>
                <Form.Item className={Styles.form__item}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        icon={
                            <SendIcon
                                color={
                                    isButtonDisabled() || !allowSendingMessage()
                                        ? '#767687'
                                        : '#FBFBFF'
                                }
                            />
                        }
                        size="large"
                        disabled={isButtonDisabled() || !allowSendingMessage()}
                    />
                </Form.Item>
            </Form>
            <LoadModal
                open={modalIsOpen}
                setOpen={setModalIsOpen}
                files={files}
                setFiles={setFiles}
            />
        </div>
    );
};

interface ITextAreWrapper extends InputProps {
    $messageIsEmpty: boolean;
}

const TextAreaWrapper = styled(Input.TextArea)<ITextAreWrapper>`
    position: relative;

    & textarea {
        padding-bottom: ${({$messageIsEmpty}) =>
                $messageIsEmpty ? '0' : '25px'} !important;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(243, 243, 248, 1);
            border-radius: 0.5rem !important;
        }
    }

    & .ant-input-data-count {
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: 0;
        transform: translate(-10px, -30px);
        color: rgba(118, 118, 135, 1);
        //TODO
    }
`;

const AttachPopover = styled(Popover)`
    .ant-popover .ant-popover-inner {
        padding: 6px 16px !important;
        max-width: 312px !important;
        background-color: red !important;
    }
`;

export {ChatForm};
