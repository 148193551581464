const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3.125C7.30203 3.125 7.54688 3.36984 7.54688 3.67188V6.95312H10.8281C11.1302 6.95312 11.375 7.19797 11.375 7.5C11.375 7.80203 11.1302 8.04688 10.8281 8.04688H7.54688V11.3281C7.54688 11.6302 7.30203 11.875 7 11.875C6.69797 11.875 6.45312 11.6302 6.45312 11.3281V8.04688H3.17188C2.86984 8.04688 2.625 7.80203 2.625 7.5C2.625 7.19797 2.86984 6.95312 3.17188 6.95312H6.45312V3.67188C6.45312 3.36984 6.69797 3.125 7 3.125Z"
      fill="#FBFBFF"
    />
  </svg>
);

export { PlusIcon };
