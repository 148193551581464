import {Form, FormInstance, Input, Typography} from '@pankod/refine-antd';
import {FC, Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import {FullScreenLoader} from '../../../../shared/components/full-screen-loader';
import styled from "styled-components";

interface ISetAdditionalParamsProps {
  form: FormInstance;
  deal: string;
  handleChangeDeal: (value: string) => void;
  response: string;
  handleChangeResponse: (value: string) => void;
}

const SetAdditionalParams: FC<ISetAdditionalParamsProps> = ({
                                                              form,
                                                              handleChangeDeal,
                                                              handleChangeResponse,
                                                              deal,
                                                              response,
                                                            }) => {
  const {t} = useTranslation();
  const dealTermsWatch = Form.useWatch('deal-terms', form);
  const autoResponseWatch = Form.useWatch('auto-response', form);
  return (
    <Suspense fallback={<FullScreenLoader/>}>
      <div className="create-ad-cards-wrapper">
        <div className="set-p2p-ad-additional-params-left-card">
          <div className="additional-param-form-item">
            <div className="item">
              <ItemTypography>
                {t('p2p.setAdditionallyParams.conditionsDeals')}
              </ItemTypography>
              <Typography>
                {dealTermsWatch ? dealTermsWatch.length : 0}/
                <span className="grey-digits">1000</span>
              </Typography>
            </div>
            <Form.Item
              initialValue={deal}
              shouldUpdate
              name="deal-terms"
              extra={t('p2p.setAdditionallyParams.inputs.notRequiredInput')}
            >
              <StyledTextArea
                autoSize
                maxLength={1000}
                placeholder={t(
                  'p2p.setAdditionallyParams.inputs.enterConditionsDeals'
                ).toString()}
                style={{maxHeight: '320px'}}
                onChange={e => handleChangeDeal(e.target.value)}
              />
            </Form.Item>
          </div>
        </div>

        <div className="set-p2p-ad-additional-params-right-card">
          <div className="additional-param-form-item">
            <div className="item">
              <ItemTypography>
                {t('p2p.setAdditionallyParams.inputs.autoAnswer')}
              </ItemTypography>
              <Typography>
                {autoResponseWatch ? autoResponseWatch.length : 0}/
                <span className="grey-digits">1000</span>
              </Typography>
            </div>
            <Form.Item
              initialValue={response}
              shouldUpdate
              name="auto-response"
              extra={t('p2p.setAdditionallyParams.inputs.notRequiredInput')}
            >
              <StyledTextArea
                autoSize
                maxLength={1000}
                placeholder={t(
                  'p2p.setAdditionallyParams.inputs.enterAutoAnswer'
                ).toString()}
                onChange={e => handleChangeResponse(e.target.value)}
                style={{maxHeight: '320px'}}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </Suspense>
  );
};
const ItemTypography = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
`;
export default SetAdditionalParams;

const StyledTextArea = styled(Input.TextArea)`
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background: #F3F3F8;
    border-radius: 4px;
  }
`
