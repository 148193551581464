import React, { useEffect, useState } from 'react';

import { Col, Row, Typography } from '@pankod/refine-antd';
import dayjs from 'dayjs';
import { IGetHistoryOrder } from '../../../shared/interfaces/spot';
import { useTranslate } from '@pankod/refine-core';
import { useLocation } from '@pankod/refine-react-router-v6';

const OrdersTableHistoryRow = ({
  history,
}: {
  history: IGetHistoryOrder;
}): JSX.Element => {
  const translate = useTranslate();
  const location = useLocation();

  const [activePair, setActivePair] = useState(
    () => localStorage['activePair']
  );

  useEffect(() => {
    setActivePair(() => localStorage['activePair']);
  }, []);

  useEffect(() => {
    setActivePair(() => localStorage['activePair']);
  }, [location?.pathname]);

  return (
    <Row
      key={'order-table-' + history.id}
      justify={'space-between'}
      style={{ padding: '4.5px 0' }}
      className={'history-orders-row'}
    >
      <Col span={3}>
        <Typography.Text
          {...(history.status === 'canceled'
            ? { style: { color: '#767687' } }
            : {})}
        >
          {dayjs(new Date(history.created_at)).format('DD.MM.YYYY hh:mm')}
        </Typography.Text>
      </Col>
      <Col span={2}>
        <Typography.Text
          {...(history.status === 'canceled'
            ? { style: { color: '#767687' } }
            : {})}
        >
          {activePair?.replace(new RegExp('-', 'g'), '/')}
        </Typography.Text>
      </Col>
      <Col span={2}>
        {history.type === 'buy' ? (
          <Typography.Text
            {...(history.status === 'canceled'
              ? { style: { color: '#767687' } }
              : {})}
            type="success"
          >
            {translate(`spotOrders.columns.header.type.buy`)}
          </Typography.Text>
        ) : (
          <Typography.Text
            {...(history.status === 'canceled'
              ? { style: { color: '#767687' } }
              : {})}
            type="danger"
          >
            {translate(`spotOrders.columns.header.type.sell`)}
          </Typography.Text>
        )}
      </Col>
      <Col span={2}>
        {history.market ? (
          <Typography.Text
            {...(history.status === 'canceled'
              ? { style: { color: '#767687' } }
              : {})}
          >
            {translate(`spotOrders.columns.header.market.true`)}
          </Typography.Text>
        ) : (
          <Typography.Text
            {...(history.status === 'canceled'
              ? { style: { color: '#767687' } }
              : {})}
          >
            {translate(`spotOrders.columns.header.market.false`)}
          </Typography.Text>
        )}
      </Col>
      <Col span={3}>
        <Typography.Text
          {...(history.status === 'canceled'
            ? { style: { color: '#767687' } }
            : {})}
        >
          {history.price}{' '}
          {history.type === 'buy'
            ? activePair?.split('-')[1]
            : activePair?.split('-')[0]}
        </Typography.Text>
      </Col>
      <Col span={2}>
        <Typography.Text
          {...(history.status === 'canceled'
            ? { style: { color: '#767687' } }
            : {})}
        >
          {history.percent}%
        </Typography.Text>
      </Col>
      <Col span={3}>
        <Typography.Text
          {...(history.status === 'canceled'
            ? { style: { color: '#767687' } }
            : {})}
        >
          {(+history?.sum)?.toFixed(8)}
        </Typography.Text>
      </Col>
      <Col span={4}>
        <Typography.Text
          {...(history.status === 'canceled'
            ? { style: { color: '#767687' } }
            : {})}
        >
          {(+history?.balance)?.toFixed(8)} {activePair?.split('-')[0]}
        </Typography.Text>
      </Col>
      <Col span={3}>
        <Typography.Text
          {...(history.status === 'canceled'
            ? { style: { color: '#767687' } }
            : {})}
        >
          {translate(`spotOrders.columns.header.statusType.${history.status}`)}
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default OrdersTableHistoryRow;
