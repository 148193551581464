import React, { Dispatch, FC } from 'react';
import Card from './Card';
import { getCoinInfoByName } from '../../../../shared/helpers/getCoinInfoByName';
import { removeExponenta } from '../../../../shared/helpers/removeExponenta';
import { ReactComponent as Divider } from '../image/Divider.svg';
import { useTranslate } from '@pankod/refine-core';
import './styles.css';
import {
  ICryptoPair,
  IExchangeContent,
} from '../../../../features/cryptocurrencyExchange/cryptoCurrencyExchange.p';

interface Props {
  exchangeData: IExchangeContent;
  changePrice: (input: string, output: string) => void;
  currentPair: ICryptoPair;
  minimumExchangeAmount: number;
  handleOpenChoice(value?: boolean): void;
  setType: Dispatch<React.SetStateAction<'send' | 'get' | undefined>>;
}

const FirstStep: FC<Props> = ({
  exchangeData,
  currentPair,
  changePrice,
  minimumExchangeAmount,
  handleOpenChoice,
  setType,
}) => {
  const translate = useTranslate();

  return (
    <div className={'container-card'}>
      <Card
        isGetting={false}
        coinName={exchangeData.inputCoin}
        changePrice={changePrice}
        currentPair={currentPair}
        inputCoin={exchangeData.inputCoin}
        inputAmount={exchangeData.inputAmount}
        coinIcon={
          getCoinInfoByName(exchangeData.inputCoin.toLowerCase(), true)
            .icon as string
        }
        minimumExchangeAmount={minimumExchangeAmount}
        handleOpenChoice={() => {
          handleOpenChoice();
          setType('send');
        }}
      />
      <Divider />
      <Card
        isGetting={true}
        coinName={exchangeData.outputCoin}
        changePrice={changePrice}
        currentPair={currentPair}
        inputCoin={exchangeData.outputCoin}
        inputAmount={exchangeData.outputAmount}
        coinIcon={
          getCoinInfoByName(exchangeData.outputCoin.toLowerCase(), true)
            .icon as string
        }
        minimumExchangeAmount={minimumExchangeAmount}
        handleOpenChoice={() => {
          handleOpenChoice();
          setType('get');
        }}
      />
      <div className={'container-card-text'}>
        <div className={'container-card-text-footer'}>
          <span className={'container-card-text-footer-small'}>
            {`${translate('cryptoExchange.changer.rightCard.bottomText')}:`}
          </span>
          <span className={'container-card-text-footer-large'}>{`1 ${
            exchangeData.inputCoin
          } = ${removeExponenta(
            currentPair ? +(+currentPair?.price)?.toFixed(9) : 0
          )} ${exchangeData.outputCoin}`}</span>
        </div>
        <div className={'container-card-text-footer'}>
          <span className={'container-card-text-footer-small'}>
            {`${translate(
              'cryptoExchange.order.centerCard.minimumExchangeValue'
            )}:`}
          </span>
          <span
            className={'container-card-text-footer-large'}
          >{`${minimumExchangeAmount} ${exchangeData.inputCoin}`}</span>
        </div>
      </div>
    </div>
  );
};

export default FirstStep;
