interface ICreatePairs<T extends { currency: string }, E extends { pair: string }> {
  inputData: T[]
  pairsData: E[]
}

export const createFilteredAvailablePairs = <T extends { currency: string }, E extends { pair: string }>({
                                                                                                           inputData,
                                                                                                           pairsData
                                                                                                         }: ICreatePairs<T, E>) => {
  let pairs: string[][] = []
  for (let i = 0; i < inputData.length - 1; i++) {
    for (let j = 1; j < inputData.length; j++) {
      pairs.push([inputData[i].currency, inputData[j].currency])
    }
  }

  let firstFilteredPairs: E[] = []
  for (let i = 0; i < pairs.length; i++) {
    firstFilteredPairs = [...firstFilteredPairs, ...pairsData.filter(item => {
      if (`${pairs[i][0]}/${pairs[i][1]}` === item.pair) return item
      else if (`${pairs[i][1]}/${pairs[i][0]}` === item.pair) return item
    })]
  }

  return firstFilteredPairs
}
