import React, {Dispatch, SetStateAction} from 'react';
import { TypePaymentVariant } from '../modalContent';
import { useTranslation } from 'react-i18next';
import './style.css';
import { ButtonsTypes } from '../../../../shared/components/ui/button/filledButton.p';
import Button from '../../../../shared/components/ui/button';

interface Props {
  controlsVariantPay: [
    TypePaymentVariant | null,
    Dispatch<TypePaymentVariant | null>
  ];
  closeModal: () => void;
  controlsNowStep: [number, Dispatch<number>];
  addPayment: () => void;
  setClickSubmit: Dispatch<boolean>;
  setErrorHasPayment: Dispatch<SetStateAction<boolean>>
}

const Footer = ({
  controlsVariantPay: [variantPay, setVariantPay],
  closeModal,
  controlsNowStep: [nowStep, setNowStep],
  addPayment,
  setClickSubmit,
  setErrorHasPayment
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className="footer-container">
      <Button
        onClick={() => {
          if (nowStep === 1) {
            setNowStep(2);
          } else {
            setClickSubmit(true);
            addPayment();
          }
          setErrorHasPayment(false)
        }}
        className="footer-ok-button"
        disabled={!variantPay}
      >
        {nowStep === 1 ? t('buttons.next') : t('buttons.add')}
      </Button>
      <div style={{width:'65%',height:40}}>
        <Button
          type={ButtonsTypes.outlined}
          onClick={() => {
            if (nowStep === 1) {
              closeModal();
              setVariantPay(null);
            } else setNowStep(1);
          }}
          className="footer-cancel-button"
        >
          {nowStep === 1 ? t('buttons.cancel2') : t('buttons.back')}
        </Button>
      </div>
    </div>
  );
};

export default Footer;
