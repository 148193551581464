import { Typography } from '@pankod/refine-antd';
import STYLES from './index.module.css';
import { ExclamationCircleIcon } from 'shared/components/ui/assets/svg/exclamation-circle';
import {useTranslation} from "react-i18next";

const AppealModalAlert = () => {

    const { t } = useTranslation();

  return (
    <div className={STYLES.container}>
      <div>
        <ExclamationCircleIcon />
      </div>
      <div className={STYLES.text_container}>
        <Typography.Text>
            {t('Причины апелляции и доказательства')}
        </Typography.Text>
        <Typography.Text>
            {t('Предупреждение апеляции')}
        </Typography.Text>
      </div>
    </div>
  );
};

export { AppealModalAlert };
