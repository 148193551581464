import { Skeleton } from '@pankod/refine-antd';

const AnnSkeleton = ({ loading }: { loading: boolean }) => {
  return (
    <Skeleton
      active={loading}
      loading={loading}
      style={{ marginTop: '120px' }}
      title={{
        style: { display: 'none' },
      }}
      paragraph={{
        rows: 5,
        className: 'skeleton-paragraph',
      }}
    ></Skeleton>
  );
};

export default AnnSkeleton
