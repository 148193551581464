import React, { useState } from 'react';
import { Dropdown, DropdownProps } from 'antd';
import { MenuProps } from 'antd/es/menu/menu';
import styles from './index.module.css';
import { Button, ConfigProvider, Typography } from '@pankod/refine-antd';

import Icon from '@ant-design/icons';
import './styles.css';
import { DropdownIconSimpleColor } from '../../../../pages/paymentLogout/icon';

interface IDropdown extends DropdownProps {
  defaultValue: string;
  items: MenuProps['items'];
  currentValue: string;
  isActive?: boolean;
  content?: JSX.Element;
  isSelectable?: boolean;
}

const HeaderDropdown: React.FC<IDropdown> = ({
  defaultValue,
  items,
  currentValue,
  isActive,
  content,
  isSelectable,
  ...rest
}) => {
  const [isClosed, setIsClosed] = useState(false);

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#202124',
            fontFamily: 'Inter, sans-serif',
          },
          components: {
            Menu: {
              radiusItem: 0,
              colorItemBg: '',
              colorItemBgHover: '#383844',
              colorItemText: '#767687',
              colorItemTextHover: '#FBFBFF',
              colorItemTextSelected: '#FBFBFF',
              marginXXS: 0,
              marginXS: 0,
            },
            Typography: {
              colorLink: '#404348', //TODO Поменять как будет
              colorLinkHover: '#404348',
              colorLinkActive: '#404348',
              colorPrimary: '#FBFBFF',
              colorText: '#FBFBFF',
              colorTextHeading: '#FBFBFF',
            },
            Dropdown: {
              borderRadiusLG: 4,
              borderRadiusSM: 4,
              colorBgElevated: 'transparent',
              colorText: '#FBFBFF',
              controlHeight: 48,
              boxShadowSecondary: 'transparent',
              paddingXXS: 0,
              paddingXS: 0,
              colorBgBase: '#2B2B36',
              controlItemBgHover: '#20202E',
              controlItemBgActive: '#20202E',
              controlItemBgActiveHover: '#20202E',
            },
          },
        }}
      >
        <div className={isActive ? styles.activeDropdown : ''}>
          <Dropdown
            {...rest}
            menu={{
              items,
              selectable: isSelectable,
              defaultSelectedKeys: [defaultValue],
            }}
            onOpenChange={() => {
              setIsClosed(!isClosed);
            }}
          >
            {content ? (
              content
            ) : (
              <Button
                type="link"
                style={{ color: '#404348', padding: '0px', height: 32 }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: 3,
                  }}
                >
                  {content ? (
                    content
                  ) : (
                    <Typography.Text
                      style={{
                        fontWeight: 700,
                        fontSize: '16px',
                        lineHeight: '120%',
                      }}
                    >
                      {currentValue}
                    </Typography.Text>
                  )}
                  <Icon
                    component={() =>
                      DropdownIconSimpleColor(isClosed ? '#FBFBFF' : undefined)
                    }
                    style={{
                      transform: isClosed
                        ? 'matrix(1, 0, 0, -1, 0, 0)'
                        : undefined,
                      transition: 'transform 0.6s ease-out',
                    }}
                  />
                </div>
              </Button>
            )}
          </Dropdown>
        </div>
      </ConfigProvider>
    </>
  );
};

export default HeaderDropdown;
