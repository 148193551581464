import { LibrarySymbolInfo, ResolutionString } from '../../../charting_library';
import { makeApiRequest, timeType } from './helpers';
import { CentrifugeClient } from '../../../packages/centrifuge-client/centrifuge-client';

const configurationData: TradingView.DatafeedConfiguration = {
  supports_marks: true,
  supports_timescale_marks: true,
  supports_time: true,
  exchanges: [],
  symbols_types: [],
  supported_resolutions: [
    '5',
    '15',
    '30',
    '60',
    '12H',
    'D',
  ] as ResolutionString[], //['D', '2D', '3D', 'W', '3W', 'M', '6M']
};

const url = new URL(window.location.href);
const isMobile = url.searchParams.get('isMobile') === 'true';
const lastPair = isMobile
  ? url.searchParams.get('pair')?.toUpperCase()
  : localStorage['activePair'];

export default {
  onReady: (callback: TradingView.OnReadyCallback) => {
    console.log('[onReady]: Method call');
    setTimeout(() => callback(configurationData));
  },
  searchSymbols: (
    userInput: string,
    exchange: string,
    symbolType: string,
    onResult: TradingView.SearchSymbolsCallback
  ) => {
    console.log('[searchSymbols]: Method call');
  },
  resolveSymbol: (
    symbolName: string,
    onSymbolResolvedCallback: TradingView.ResolveCallback,
    onResolveErrorCallback: TradingView.ErrorCallback
    // extension
  ) => {
    console.log('[resolveSymbol]: Method call', symbolName);

    const [ccy1 = '', ccy2 = ''] = lastPair?.split('-') as [];

    const symbolInfo: LibrarySymbolInfo = {
      full_name: '',
      format: 'volume',
      listed_exchange: 'EDENEX',
      name: ccy2,
      ticker: ccy2,
      description: '',
      type: 'crypto',
      session: '24x7',
      timezone: 'Europe/Moscow',
      exchange: ccy1,
      minmov: 1,
      pricescale: 100000000,
      has_intraday: true,
      intraday_multipliers: ['1', '5', '15', '30', '60'],
      has_weekly_and_monthly: true,
      supported_resolutions:
        configurationData.supported_resolutions as ResolutionString[],
      volume_precision: 8,
      data_status: 'streaming',
    };

    setTimeout(() => onSymbolResolvedCallback(symbolInfo));
  },
  getBars: async (
    symbolInfo: TradingView.LibrarySymbolInfo,
    resolution: TradingView.ResolutionString,
    periodParams: TradingView.PeriodParams,
    onHistoryCallback: TradingView.HistoryCallback,
    onError: TradingView.ErrorCallback
  ) => {
    const size =
      resolution === '1D' || resolution === 'D'
        ? 86400
        : +resolution >= 60
        ? 3600
        : 300;
    console.log(timeType[resolution as keyof typeof timeType]);
    const { data } = await makeApiRequest({
      type: timeType[resolution as keyof typeof timeType],
      // count: periodParams.countBack,
    });

    console.log('[getBars]: Method call', symbolInfo);
    console.log(data);
    console.log(
      periodParams.from,
      periodParams.to,
      periodParams.countBack,
      resolution
    );

    const bars: TradingView.Bar[] = data.map(
      ({ o, h, l, c, d, v }: any, index: any) => ({
        open: +o,
        high: +h,
        low: +l,
        close: +c,
        time: d * 1000,
        volume: parseFloat(v) || 0,
      })
    );

    if (bars.length < periodParams.countBack) {
      if (bars.length === 0) {
        onHistoryCallback([], { noData: true });
      } else {
        return;
      }
    } else {
      onHistoryCallback(bars, { noData: false });
    }

    //   Fake data
    //   let time = new Date(periodParams.to * 1000);
    //   time.setUTCHours(0);
    //   time.setUTCMinutes(0);
    //   time.setUTCMilliseconds(0);
    // const bars = new Array(periodParams.countBack + 1);
    //
    // let price = 100;
    //
    // for (let i = periodParams.countBack; i > -1; i--) {
    //   // Working out a random value for changing the fake price
    //   const volatility = 0.1;
    //   const x = Math.random() - 0.5;
    //   const changePercent = 2 * volatility * x;
    //   const changeAmount = price * changePercent;
    //   let new_price = price + changeAmount;
    //
    //   bars[i] = {
    //     open: price,
    //     high: price + Math.random() * 10,
    //     low: price - Math.random() * 10,
    //     close: new_price,
    //     time: time.getTime(),
    //   };
    //
    //   price = new_price;
    //
    //   time = new Date(+time - 300 * 1000);
    // }
    //
    // // Once all the bars (usually countBack is around 300 bars) the array of candles is returned to the library
    // onResult(bars);
  },
  subscribeBars: (
    symbolInfo: TradingView.LibrarySymbolInfo,
    resolution: string,
    onRealtimeCallback: TradingView.SubscribeBarsCallback,
    subscriberUID: string,
    onResetCacheNeededCallback: () => void
  ) => {
    console.log(
      '[subscribeBars]: Method call with subscriberUID:',
      subscriberUID
    );

    let time = resolution === '1D' ? 1440 : +resolution;
    let secondsInResolution = time * 60;

    const spotChartSubscription = CentrifugeClient.getSubscription(
      `chart.${lastPair}`
    );

    spotChartSubscription?.on('publication', (data) => {
      const resData = data?.data || {};

      if (resData && +resData.type === +secondsInResolution) {
        let newBar: TradingView.Bar = {} as TradingView.Bar;
        console.log(resData, 'ura');
        newBar = {
          open: +resData.o,
          high: +resData.h,
          low: +resData.l,
          close: +resData.c,
          time: resData.d * 1000,
          volume: parseFloat(resData.v) || 0,
        };

        console.log(newBar);

        onRealtimeCallback(newBar);
      }
    });

    // subscribeOnStream(
    //   symbolInfo,
    //   resolution,
    //   onRealtimeCallback,
    //   subscriberUID,
    //   onResetCacheNeededCallback,
    //   lastBarsCache.get(symbolInfo.full_name)
    // );
  },
  unsubscribeBars: (subscriberUID: string) => {
    console.log(
      '[unsubscribeBars]: Method call with subscriberUID:',
      subscriberUID
    );
    console.log(lastPair);
    const spotChartSubscription = CentrifugeClient.getSubscription(
      `chart.${lastPair}`
    );

    spotChartSubscription?.unsubscribe();
  },
};
