import React from 'react';
import { Typography } from '@pankod/refine-antd';
import { useTranslate } from '@pankod/refine-core';

const { Title, Text } = Typography;
const ExchangeHeading = () => {
  const translate = useTranslate();
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
        <Title
          style={{
            marginBottom: 0,
            color: '#FBFBFF',
          }}
        >
          {translate('cryptoExchange.changer.heading')}
        </Title>
        <Text style={{ color: '#FBFBFF', fontSize: 18, fontWeight: 500 }}>
          {translate('cryptoExchange.changer.subHeading')}
        </Text>
      </div>
    </>
  );
};

export default ExchangeHeading;
