import React, {useEffect, useState} from 'react';
import {ConfigProvider, Typography} from '@pankod/refine-antd';
import {CrudSort} from '@pankod/refine-core';
import {useTranslation} from 'react-i18next';
import OrdersTableActual from './actual/ordersTableActual';
import OrdersTableHistory from './history/ordersTableHistory';

type Operators =
    | '>'
    | '<'
    | '='
    | '<='
    | '>='
    | 'neq'
    | 'lte'
    | 'gte'
    | 'eq'
    | 'ne'
    | 'lt'
    | 'gt'
    | 'in'
    | 'nin'
    | 'contains'
    | 'ncontains'
    | 'containss'
    | 'ncontainss'
    | 'between'
    | 'nbetween'
    | 'null'
    | 'nnull'
    | 'startswith'
    | 'nstartswith'
    | 'startswiths'
    | 'nstartswiths'
    | 'endswith'
    | 'nendswith'
    | 'endswiths'
    | 'nendswiths'
    | 'or'
    | 'and';

interface ISearchFilter {
    field: string;
    operator: Operators;
    value: any;
}

export type IFilterHistory = Record<string, ISearchFilter>;

export interface ISortHistory {
    date: CrudSort;
}

const SpotOrdersTable = () => {
    const {t} = useTranslation();

    const [tab, setTab] = useState<'actual' | 'history'>('actual');
    const [openOrderCount, setOpenOrderCount] = useState<number>(0);

    const handleSetActual = () => {
        setTab('actual');
    };
    const handleSetHistory = () => {
        setTab('history');
    };

    function renderTable() {
        if (tab === 'actual')
            return (
                <OrdersTableActual
                    tab={tab}
                    setOpenCount={(e) => setOpenOrderCount(e)}
                />
            );
        else return <OrdersTableHistory tab={tab}/>;
    }

    useEffect(() => {
    }, [setOpenOrderCount]);

    return (
        <ConfigProvider
            theme={{
                components: {
                    Typography: {
                        colorSuccess: '#58BF92',
                        colorError: '#F16063',
                    },
                    Tag: {
                        colorPrimary: '#FBFBFF',
                        colorPrimaryHover: 'inherit',
                        colorText: '#767687',
                    },
                    DatePicker: {
                        lineWidth: 0,
                    },
                },
            }}
        >
            <div className={'order-table-spot'} style={{position: 'relative'}}>
                <div
                    className={'flex-row justify-content-space-between'}
                    style={{marginBottom: '50px'}}
                >
                    <div
                        className={'flex-row gap-24'}
                        style={{
                            margin: '15px 16px -15px',
                        }}
                    >
                        <Typography.Text
                            style={{
                                color: tab === 'actual' ? '#FBFBFF' : '#767687',
                                cursor: 'pointer',
                            }}
                            onClick={handleSetActual}
                        >
                            {t('spotOrders.tabs.actual')} {openOrderCount ? `(${openOrderCount})` : ''}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                color: tab === 'history' ? '#FBFBFF' : '#767687',
                                cursor: 'pointer',
                            }}
                            onClick={handleSetHistory}
                        >
                            {t('spotOrders.tabs.history')}
                        </Typography.Text>
                    </div>
                </div>
                <div
                    style={{
                        marginLeft: '15px',
                    }}
                >
                    {renderTable()}
                </div>
            </div>
        </ConfigProvider>
    );
};

export {SpotOrdersTable};
