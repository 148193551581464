import React, { Dispatch, FC, SetStateAction } from 'react';
import { ReactComponent as Sort } from './image/Sort.svg';
import { ReactComponent as SortUp } from './image/SortUp.svg';
import { ReactComponent as SortDown } from './image/SortDown.svg';

interface Props {
  item: string;
  index: number;
  sort: { sortName: string; up: boolean; down: boolean } | null;
  setSort: Dispatch<
    SetStateAction<{ sortName: string; up: boolean; down: boolean } | null>
  >;
}

const ItemPair: FC<Props> = ({ item, index, sort, setSort }) => {
  const getWidth = () => (index === 0 ? { width: '40%' } : undefined);

  return (
    <button
      style={getWidth()}
      key={item}
      onClick={() => {
        item === sort?.sortName
          ? setSort({
              ...sort,
              up: !sort?.down ? false : !sort?.up || false,
              down: !!sort?.up ? false : !sort?.down,
            })
          : setSort({
              sortName: item,
              up: false,
              down: true,
            });
      }}
      className={'pair-content-name-container-text'}
    >
      <span className={'pair-content-name-text'}>{item}</span>
      {sort?.sortName === item && sort.down ? (
        <SortUp />
      ) : sort?.sortName === item && sort.up ? (
        <SortDown />
      ) : (
        <Sort />
      )}
    </button>
  );
};

export default ItemPair;
