import React, { useEffect, useState } from 'react';

import { Col, Row, Typography } from '@pankod/refine-antd';
import dayjs from 'dayjs';
import { IGetActualOrder } from '../../../shared/interfaces/spot';
import { useLocation } from '@pankod/refine-react-router-v6';
import { API_URL } from '../../../packages/keycloak-client/constants';
import { axios } from '../../../shared/exios';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';

const OrdersTableActualRow = ({
  actual,
  getActuals,
}: {
  actual: IGetActualOrder;
  getActuals: Function;
}): JSX.Element => {
  const location = useLocation();
  const { t } = useTranslation();

  const [activePair, setActivePair] = useState(
    () => localStorage['activePair']
  );

  const handleCanceled = async () => {
    try {
      await axios
        .patch(
          `${API_URL}/${activePair
            ?.replace('-', '')
            ?.toLowerCase()}-spot/api/orders/${actual.id}`,
          {
            status: 'canceled',
          })
        .then(() => {
          notification.success({
            message: 'Ордер успешно отменен',
          });
          if (!!getActuals) getActuals();
        });
    } catch (e: any) {
      console.log('handleCanceled order error', e);
      notification.error({
        message: 'Ошибка отмены ордера',
        description: e?.message || e,
      });
    }
  };

  useEffect(() => {
    setActivePair(() => localStorage['activePair']);
  }, []);

  useEffect(() => {
    setActivePair(() => localStorage['activePair']);
  }, [location?.pathname]);

  return (
    <Row
      key={'order-table-' + actual.id}
      justify={'space-between'}
      style={{ padding: '4.5px 0' }}
      className={'actual-orders-row'}
    >
      <Col span={3}>
        <Typography.Text>
          {dayjs(new Date(actual.created_at)).format('DD.MM.YYYY hh:mm')}
        </Typography.Text>
      </Col>
      <Col span={3}>
        <Typography.Text>
          {activePair?.replace(new RegExp('-', 'g'), '/')}
        </Typography.Text>
      </Col>
      <Col span={2}>
        {actual.type === 'buy' ? (
          <Typography.Text type="success">
            {t(`spotOrders.columns.header.type.buy`)}
          </Typography.Text>
        ) : (
          <Typography.Text type="danger">
            {t(`spotOrders.columns.header.type.sell`)}
          </Typography.Text>
        )}
      </Col>
      <Col span={2}>
        {actual.market ? (
          <Typography.Text>
            {t(`spotOrders.columns.header.market.true`)}
          </Typography.Text>
        ) : (
          <Typography.Text>
            {t(`spotOrders.columns.header.market.false`)}
          </Typography.Text>
        )}
      </Col>
      <Col span={3}>
        <Typography.Text>{(+actual?.price)?.toFixed(8)}</Typography.Text>
      </Col>
      <Col span={2}>
        <Typography.Text>{actual?.percent}%</Typography.Text>
      </Col>
      <Col span={3}>
        <Typography.Text
          style={{
            width: '90%',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {(+actual?.sum)?.toFixed(8)}
        </Typography.Text>
      </Col>
      <Col span={3}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography.Text
            style={{
              width: '75%',
              display: 'inline-block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {(+actual?.balance)?.toFixed(8)}
          </Typography.Text>
          <Typography.Text>{' ' + activePair?.split('-')[0]}</Typography.Text>
        </div>
      </Col>
      <Col span={3}>
        <Typography.Text className={'cursor-pointer'} onClick={handleCanceled}>
          {t(`spotOrders.columns.header.cancel`)}
        </Typography.Text>
      </Col>
    </Row>
  );
};

export default OrdersTableActualRow;
