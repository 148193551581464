import {ChatContainer} from '../components/container';
import {ChatContent} from '../components/content';
import {ChatForm} from '../components/form';
import {ChatHeader} from '../components/header';
import {ChatMessageContainer} from '../components/messages-container';
import {IP2POrderUser} from '../../../pages/p2p/myDeals/cards/myTrades.p';

interface IChat {
  buyerInfo: IP2POrderUser;
  sellerInfo: IP2POrderUser;
  buyerId: string;
  sellerId: string;
  orderStatus: string;
  isSeller: boolean;
}

const Chat = ({
                buyerInfo,
                sellerInfo,
                buyerId,
                sellerId,
                orderStatus,
                isSeller,
              }: IChat): JSX.Element => {
  return (
    <ChatContainer>
      <ChatHeader
        nickname={isSeller ? buyerInfo.nickname : sellerInfo.nickname}
        buyerInfo={buyerInfo}
        sellerInfo={sellerInfo}
        isSeller={isSeller}
      />
      <ChatContent>
        {/*{alertIsShow && (*/}
        {/*  <ChatMessageAlert*/}
        {/*    onClose={() => {*/}
        {/*      setAlertIsShow(false);*/}
        {/*    }}*/}
        {/*    header={t('chat.warningTitle')}*/}
        {/*  >*/}
        {/*    {t('chat.warningText')}*/}
        {/*  </ChatMessageAlert>*/}
        {/*)}*/}
        <ChatMessageContainer
          buyerInfo={buyerInfo}
          sellerInfo={sellerInfo}
          buyerId={buyerId}
          sellerId={sellerId}
        />
        <ChatForm orderStatus={orderStatus}/>
      </ChatContent>
    </ChatContainer>
  );
};

export {Chat};
