import { Avatar, AvatarProps } from '@pankod/refine-antd';
import { IUser } from 'interfaces';

type AvatarColors = 'default' | 'green' | 'blue'

interface IUserAvatarProps {
  user: IUser
  
  color?: AvatarColors
  size?: AvatarProps['size']
}

const COLORS: Record<AvatarColors, string> = {
  default: 'linear-gradient(75.86deg, #6320E6 6.84%, #7B59FD 99.53%)',
  green: 'linear-gradient(80.91deg, #088473 0%, #12BFA8 100%)',
  blue: 'linear-gradient(270.4deg, #6E27F8 -85.22%, #20D9C0 164.99%)',
}

const UserAvatar = (props: IUserAvatarProps) => {
  return (
    <Avatar
      shape="square"
      size={props.size}
      style={{
        background: COLORS[props.color ?? 'default'],
      }}
    >
      {props.user.email ? (
        <>
          {props.user.email[0].toUpperCase()}
          {props.user.email[1].toUpperCase()}
        </>
      ) : null}
    </Avatar>
  );
};

export { UserAvatar };
